import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from './ButtonGroup';
import TextField from './TextField';
import DatePicker from './datePicker';
import TimePicker from './timePicker';
import common from '../../common';
import moment from 'moment';
import PhoneNumberField from './PhoneNumberField';
import Form from 'react-bootstrap/cjs/Form';
import SelectField from './SelectField';

const today = new Date() 
export class SMS extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            sms:'',
            mobile:'',
            date:moment(today).format(common.dateTimeFormat),
            requestedTime:moment(today).format(common.timeFormat),
            initial:true,
            id:0,
            isScheduled: false,
            smsTemplate: '',
            window: window.location.hash 
        };

    };

    static getDerivedStateFromProps(prevProps,prevState) {
        if(prevProps.data && prevProps.data.id && prevState.initial){
            return {
                mobile: prevProps.data.mobilePhone ? prevProps.data.mobilePhone : prevProps.data.customerPhone ? prevProps.data.customerPhone : '',
                id: prevProps.data.customerId ? prevProps.data.customerId : prevProps.data.id ? prevProps.data.id : 0,
                initial:false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            mobile:e
        })
    }

    handleDateChange = (date, id) => {
        if(date === null){
            this.setState({
                [id]: moment(today).format(common.dateTimeFormat),
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateTimeFormat),
            })
        }
    }

    handleTimeChange = (seconds, id) => {
        var date = new Date(null);
        date.setSeconds(seconds);
        var hhmmssFormat = date.toISOString().substr(11, 8);
        this.setState({
            [id]:hhmmssFormat
        })
    }

    handleCheck = (e) => {
        if(e.target.checked){
            this.setState({
                isScheduled: e.target.checked
            })
        }else{
            this.setState({
                isScheduled: e.target.checked,
                date:moment(today).format(common.dateTimeFormat),
                requestedTime:moment(today).format(common.timeFormat),
            })
        }
        
    }

    handleSMSTemplate = (value) =>{
        const { smsTemplateList } = this.props;
        if(value === ''){
            this.setState({
                sms: '',
                smsTemplate:value
            })
        }else{
            const element = smsTemplateList.find(e => e.id === value)
            this.setState({
                smsTemplate:value,
                sms : element.body,
            })
        }
        
    }
    
  
    render() {
        const {
            isModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            isSelectedSMS,
            data,
            smsTemplateList
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isModalOpen} 
                    onHide={handleModalClose}
                    title={isSelectedSMS ? 'SMS' :
                        this.state.window === '#/customer' ? "SMS - " + data.displayName : 
                        this.state.window === '#/jobCard' ? "SMS - Job Number " + data.jobNumber : 'SMS'}
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"5px"}}>
                                <div className='row'>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Select sms template"
                                            controlId="smsTemplate"
                                            onChange={this.handleSMSTemplate}
                                            options={smsTemplateList && smsTemplateList.length > 0 ? smsTemplateList : []}
                                            selectedValue={this.state.smsTemplate}
                                            display = "name"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                {!isSelectedSMS && <div className='row'>
                                    <div className="col-sm-6">
                                        {/* <TextField
                                            text="Mobile number"
                                            onBlur={this.handleChange}
                                            controlId="mobile"
                                            type="text"
                                            defaultValue={this.state.mobile}
                                            maxLength={common.maxLengths.mobile}
                                            required
                                        /> */}
                                        <PhoneNumberField
                                            controlId="mobile"
                                            text="Mobile Number"
                                            type="text"
                                            // onBlur={this.mobileNumChange}
                                            hint="Please enter valid mobile number (eg:- +xxxxxxxxxx)"
                                            // required={this.state.isMobileValidate}
                                            onChange={this.mobileNumChange}
                                            maxLength={common.maxLengths.customerMobile}
                                            value={this.state.mobile}
                                        />
                                    </div>
                                </div>}
                                <div className='row'>
                                    <div className="col-sm">
                                        <TextField
                                            as="textarea"
                                            text="SMS"
                                            style={{ height: 100 }}
                                            onBlur={this.handleChange}
                                            controlId="sms"
                                            placeholder="SMS"
                                            type="text"
                                            defaultValue={this.state.sms}
                                            maxLength={300}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <Form.Group controlId="addressCheckBox">
                                            <Form.Check type="checkbox" style={{fontSize:"14px"}} onChange={(e) => this.handleCheck(e)} checked={this.state.isScheduled} label ="Schedule"/>
                                        </Form.Group>
                                    </div>
                                </div>
                                {this.state.isScheduled && 
                                    <div className='row'>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Scheduled date"
                                                controlId="date"
                                                onChange={(e) => this.handleDateChange(e,"date")}
                                                value={this.state.date === '' ? '' :new Date(this.state.date)}
                                                required
                                                // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TimePicker
                                                text="Scheduled time"
                                                controlId="requestedTime"
                                                onChange={(e) => this.handleTimeChange(e,"requestedTime")}
                                                value={this.state.requestedTime}
                                            />
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Send'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

SMS.propTypes = {
    isAddNewTitleModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default SMS;