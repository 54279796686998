import { fromJS } from 'immutable';
import {
  LOAD_PRODUCT_CATEGORY_LIST_ERROR,
  LOAD_PRODUCT_CATEGORY_LIST_LOADING,
  LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
  SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS,
  SEND_PRODUCT_CATEGORY_SUCCESS,
} from '../constant/productCategoryConstant';

export const initialState = fromJS({
    isAddNewProductCategoryModalOpen:false,
    productCategoryList:[],
    isProductCategoryListLoading:false,
    productCategoryListError:'',
    productCategorySuccess:''
});

function productCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS:
      return state.set('isAddNewProductCategoryModalOpen', action.open)
    case LOAD_PRODUCT_CATEGORY_LIST_SUCCESS:
      return state.set('productCategoryList', action.payload)
    case LOAD_PRODUCT_CATEGORY_LIST_LOADING:
      return state.set('isProductCategoryListLoading', action.payload)
    case LOAD_PRODUCT_CATEGORY_LIST_ERROR:
      return state.set('productCategoryListError', action.payload)
    case SEND_PRODUCT_CATEGORY_SUCCESS:
      return state.set('productCategorySuccess', action.payload)
    default:
      return state;
  }
}

export default productCategoryReducer;
