import { createSelector } from 'reselect';

const selectDashboardDomain = state => state.dashboard;

const makeSelectDashboardDetails = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('dashboadDetails') : ''
  );

const makeSelectDashboardLoading = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('isDashboardLoading') : false
  );

const makeSelectDashboardError = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('dashboardError') : ''
  );

const makeSelectSalesSummaryDetails = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('salesSummary') : []
  );
  
const makeSelectRatingSummaryDetails = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('ratingSummary') : []
  );

const makeSelectPurchaseOrderSummaryDetails = () =>
  createSelector(
    selectDashboardDomain,
    dashboardState => 
      dashboardState ? dashboardState.get('purchaseOrderSummary') : ''
  );

export {
    makeSelectDashboardDetails,
    makeSelectDashboardLoading,
    makeSelectDashboardError,
    makeSelectSalesSummaryDetails,
    makeSelectRatingSummaryDetails,
    makeSelectPurchaseOrderSummaryDetails
}
