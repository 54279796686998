import { 
    LOAD_DASHBOARD_ERROR,
    LOAD_DASHBOARD_LOADING,
    LOAD_DASHBOARD_REQUEST,
    LOAD_DASHBOARD_SUCCESS,
    LOAD_PURCHASING_SUMMARY_REQUEST,
    LOAD_PURCHASING_SUMMARY_SUCCESS,
    LOAD_RATING_SUMMARY_REQUEST,
    LOAD_RATING_SUMMARY_SUCCESS,
    LOAD_SALES_SUMMARY_REQUEST,
    LOAD_SALES_SUMMARY_SUCCESS,
    } from '../constant/dashboardConstant';


    export function loadDashboardRequest() {
      return {
        type: LOAD_DASHBOARD_REQUEST,
      }
    };
    
    export function loadDashboardLoading(data) {
      return {
        type: LOAD_DASHBOARD_LOADING,
        payload: data,
      }
    };
      
    export function loadDashboardSuccess(data) {
      return {
        type: LOAD_DASHBOARD_SUCCESS,
        payload: data,
      }
    };
    
    export function loadDashboardError(data) {
      return {
        type: LOAD_DASHBOARD_ERROR,
        payload: data,
      }
    };

    export function loadSalesSummaryRequest() {
      return {
        type: LOAD_SALES_SUMMARY_REQUEST,
      }
    };

    export function loadSalesSummarySuccess(data) {
      return {
        type: LOAD_SALES_SUMMARY_SUCCESS,
        payload: data,
      }
    };

    export function loadRatingSummaryRequest() {
      return {
        type: LOAD_RATING_SUMMARY_REQUEST,
      }
    };

    export function loadRatingSummarySuccess(data) {
      return {
        type: LOAD_RATING_SUMMARY_SUCCESS,
        payload: data,
      }
    };

    export function loadPurchaseOrderSummaryRequest() {
      return {
        type: LOAD_PURCHASING_SUMMARY_REQUEST,
      }
    };

    export function loadPurchaseOrderSummarySuccess(data) {
      return {
        type: LOAD_PURCHASING_SUMMARY_SUCCESS,
        payload: data,
      }
    };
    
    