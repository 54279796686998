import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductInformation from './ProductInformation';
import Alternatives from './Alternatives';


export class AddProduct extends Component {

    render() {
        const {
            isAddNewProductModalOpen,
            handleModalClose,
            handleSaveAddNew,
            productCategoryList,
            objectStatusList,
            uomList,
            selectedRow,
            isUpdate,
            productTypeList,
            selectedProduct,
            productActiveTab,
            redirectTab,
            alternativeProductList,
            allProducts,
            handleSaveAlternativeProducts
        } = this.props;

        return (
            <div>
                {
                    productActiveTab === 'productInformation' ?
                        <ProductInformation
                            isAddNewProductModalOpen={isAddNewProductModalOpen}
                            handleModalClose={handleModalClose}
                            handleSaveAddNew={handleSaveAddNew}
                            productCategoryList={productCategoryList}
                            objectStatusList={objectStatusList}
                            uomList={uomList}
                            selectedRow={selectedRow}
                            isUpdate={isUpdate}
                            productTypeList={productTypeList}
                            selectedProduct={selectedProduct}
                            redirectTab={redirectTab}
                        /> :
                        productActiveTab === 'alternatives' ?
                            <Alternatives
                                handleModalClose={handleModalClose}
                                handleSaveAddNew={handleSaveAlternativeProducts}
                                selectedProduct={selectedProduct}
                                redirectTab={redirectTab}
                                isUpdate={isUpdate}
                                alternativeProductList={alternativeProductList}
                                allProducts={allProducts}
                            /> : ''
                }

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapDispatchToProps,
);

export default (compose(withConnect)(AddProduct));