import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import '../../pages.css'
import LineChart from '../../../layout/form/LineChart'
import * as selectors from '../../../../store/selector/analyticsSelector';
// import * as actions from '../../store/action/dashboardAction';
import Loader from '../../../layout/form/Loader';
import common, { numberWithCommas } from '../../../common';
import TextField from '../../../layout/form/TextField';
import { loadCustomerSummaryRequest } from '../../../../store/action/analyticsAction';
// import NavigationBar from '../layout/toolBar/NavBar';
// import Customer from './Customer';

const today = new Date()
const currentYear = today.getFullYear()
const currentMonth = today.getMonth() + 1

function setMonthDataList(list, year) {
    var monthData = []
    if (year === currentYear && !list) {
        monthData = []
    }
    else if (year === currentYear) {
        for (let i = 1; i <= currentMonth; i++) {
            const searchMonth = list.data.find(element => element.month === i)
            if (searchMonth) {
                monthData.push(searchMonth.value)
            } else
                monthData.push(0)
        }
    } else if (list) {
        for (let i = 1; i <= 12; i++) {
            const searchMonth = list.data.find(element => element.month === i)
            if (searchMonth) {
                monthData.push(searchMonth.value)
            } else
                monthData.push(0)
        }
    }
    return monthData
}

function setQuarter(list) {
    let quarter = list.reduce((acc, curr, i) => {
        let index = Math.floor(i / 3)
        acc[index] = acc[index] + curr;
        return acc
    }, Array(4).fill(0));

    var thisQuarter = 0
    const value = currentMonth
    if (value === 3) {
        thisQuarter = quarter[0]
    } else if (value === 6) {
        thisQuarter = quarter[1]
    } else if (value === 9) {
        thisQuarter = quarter[2]
    } else if (value === 12) {
        thisQuarter = quarter[3]
    } else if (parseInt(value / 3) === 0) {
        thisQuarter = quarter[0]
    } else if (parseInt(value / 3) === 1) {
        thisQuarter = quarter[1]
    } else if (parseInt(value / 3) === 2) {
        thisQuarter = quarter[2]
    } else if (parseInt(value / 3) === 3) {
        thisQuarter = quarter[3]
    }
    return thisQuarter
}

class CustomerAcquisition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: common.lineChartDetails.labels,
                datasets: []
            },
            yearPeriod: 1,
            isYearPeriodChange: false,
            customerAcquisitionThisMonth: 0,
            customerAcquisitionThisYear: 0,
            customerAcquisitionThisQuarter: 0,
            initial: true
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { customerAcquisitionSummary } = prevProps;
        const { initial } = prevState;
        if (
            customerAcquisitionSummary && customerAcquisitionSummary.length && customerAcquisitionSummary.length > 0 && initial
        ) {
            var totalCustomerThisYear = 0
            const currentYearData = customerAcquisitionSummary.find(element => (element.year === currentYear))
            const previousYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 2))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear - 1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear - 2)

            var totalCustomerThisMonth = {}
            var totalCustomerThisQuarter = 0
            if (currentYearSummary && currentYearSummary.length > 0) {
                for (let i = 0; i < currentYearSummary.length; i++) {
                    totalCustomerThisYear = totalCustomerThisYear + currentYearSummary[i]
                }
                totalCustomerThisMonth = currentYearData.data.find(element => element.month === currentMonth)
                totalCustomerThisQuarter = setQuarter(currentYearSummary)
            }

            return {
                initial: false,
                data: {
                    labels: common.lineChartDetails.labels,
                    datasets: [
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        }, {
                            label: currentYear - 1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        }, {
                            label: currentYear - 2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        },
                    ]
                },
                customerAcquisitionThisYear: numberWithCommas(parseFloat(totalCustomerThisYear ? totalCustomerThisYear : 0).toFixed(2)),
                customerAcquisitionThisMonth: numberWithCommas(parseFloat(totalCustomerThisMonth && totalCustomerThisMonth.value ? totalCustomerThisMonth.value : 0).toFixed(2)),
                customerAcquisitionThisQuarter: numberWithCommas(parseFloat(totalCustomerThisQuarter ? totalCustomerThisQuarter : 0).toFixed(2)),
            }
        }
        return null
    }

    handleChange = (e) => {
        if (e.target.value === '') {
            this.setState({
                [e.target.id]: 1,
                isYearPeriodChange: true
            })
        } else {
            this.setState({
                [e.target.id]: parseInt(e.target.value),
                isYearPeriodChange: true
            })
        }
    }

    componentDidMount() {
        this.props.dispatch(loadCustomerSummaryRequest())
    }

    componentDidUpdate(prevProps, prevState) {
        const { customerAcquisitionSummary } = this.props;
        if (customerAcquisitionSummary && customerAcquisitionSummary.length && customerAcquisitionSummary.length > 0 && this.state.isYearPeriodChange && this.state.yearPeriod === 2) {
            const currentYearData = customerAcquisitionSummary.find(element => (element.year === currentYear))
            const previousYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 2))
            const lastThreeYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 3))
            const lastFourYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 4))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear - 1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear - 2)
            const lastThreeYearSummary = setMonthDataList(lastThreeYearData, currentYear - 3)
            const lastFourYearSummary = setMonthDataList(lastFourYearData, currentYear - 4)


            this.setState({
                isYearPeriodChange: false,
                data: {
                    labels: common.lineChartDetails.labels,
                    datasets: [
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        }, {
                            label: currentYear - 1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        }, {
                            label: currentYear - 2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        }, {
                            label: currentYear - 3,
                            data: lastThreeYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_4,
                            borderColor: common.lineChartDetails.chartColor_4,
                        }, {
                            label: currentYear - 4,
                            data: lastFourYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_5,
                            borderColor: common.lineChartDetails.chartColor_5,
                        },
                    ]
                },
            })
        }
        else if (customerAcquisitionSummary && customerAcquisitionSummary.length && customerAcquisitionSummary.length > 0 && this.state.isYearPeriodChange && this.state.yearPeriod === 1) {
            const currentYearData = customerAcquisitionSummary.find(element => (element.year === currentYear))
            const previousYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = customerAcquisitionSummary.find(element => (element.year === currentYear - 2))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear - 1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear - 2)
            this.setState({
                isYearPeriodChange: false,
                data: {
                    labels: common.lineChartDetails.labels,
                    datasets: [
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        }, {
                            label: currentYear - 1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        }, {
                            label: currentYear - 2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        },
                    ]
                }
            })
        } else if (this.state.isYearPeriodChange) {
            this.setState({
                isYearPeriodChange: false,
            })
        }
    }

    render() {
        const {
            isCustomerSummaryLoading,
        } = this.props;
        return (
            <div>
                {isCustomerSummaryLoading ? <Loader show={isCustomerSummaryLoading} /> : ''}
                <div className="content-header">
                    <div className="header-pages">
                        <span><Link to="./analytics"><ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Customer Feedback</Link></span>
                        <span><Link to='./customerAcquisition'><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Customer Acquisition</Link></span>
                    </div>
                </div>
                <div className="customer-analytics-chart">
                    <div>
                        <span className="aligncenter"><h5>Customer Acquisition</h5></span>
                        <span className="alignright">
                            <TextField
                                controlId="yearPeriod"
                                type="text"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.yearPeriod}
                                options={common.lineChartDetails.viewOption}
                            />
                        </span>
                        <LineChart
                            data={this.state.data}
                            height="100px"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

CustomerAcquisition.propTypes = {
    isCustomerSummaryLoading: PropTypes.any,
    customerAcquisitionSummary: PropTypes.any,

}

const mapStateToProps = createStructuredSelector({
    isCustomerSummaryLoading: selectors.makeSelectCustomerSummaryLoading(),
    customerAcquisitionSummary: selectors.makeSelectCustomerAcquisitionSummaryDetails(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(CustomerAcquisition));
