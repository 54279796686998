import { 
  LOAD_REMINDER_LIST_ERROR,
  LOAD_REMINDER_LIST_LOADING,
  LOAD_REMINDER_LIST_REQUEST,
  LOAD_REMINDER_LIST_SUCCESS,
  LOAD_SEARCHED_VEHICLE_REQUEST,
  SAVE_REMINDER_DETAILS_SUCCESS,
  SAVE_IS_REMINDER_MODAL_OPEN_STATUS, 
  SAVE_IS_NEW_REMINDER_USING_VEHICLE, 
  SAVE_NEW_REMINDER_VEHICLE_DETAILS, 
  SEND_ADD_NEW_REMINDER_REQUEST,
  SEND_REMINDER_DELETE_REQUEST,
  SEND_REMINDER_REMINDER_REQUEST,
  SEND_REMINDER_SUCCESS,
  SEND_REMINDER_UPDATE_REQUEST,
  LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS,
  LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST,
} from '../constant/reminderConstant';
    
export function saveIsReminderModalOpenStatus(open){
  return {
    type: SAVE_IS_REMINDER_MODAL_OPEN_STATUS,
    open,
  }
};

export function sendAddNewReminderRequest(data){
  return {
    type: SEND_ADD_NEW_REMINDER_REQUEST,
    payload: data,
  }
};

export function loadReminderListRequest(data){
  return {
    type: LOAD_REMINDER_LIST_REQUEST,
    payload: data,
  }
};

export function loadReminderListLoading(data){
  return {
    type: LOAD_REMINDER_LIST_LOADING,
    payload: data,
  }
};

export function loadReminderListError(data){
  return {
    type: LOAD_REMINDER_LIST_ERROR,
    payload: data,
  }
};

export function loadReminderListSuccess(data){
  return {
    type: LOAD_REMINDER_LIST_SUCCESS,
    payload: data,
  }
};

export function sendReminderSuccess(data){
  return {
    type: SEND_REMINDER_SUCCESS,
    payload: data,
  }
};

export function sendReminderDeleteRequest(data){
  return {
    type: SEND_REMINDER_DELETE_REQUEST,
    payload: data,
  }
};

export function sendReminderUpdateRequest(data){
  return {
    type: SEND_REMINDER_UPDATE_REQUEST,
    payload: data,
  }
};

export function loadSearchedVehicleRequest(data){
  return {
    type: LOAD_SEARCHED_VEHICLE_REQUEST,
    payload: data,
  }
};

export function saveSelectedReminderSuccess(data){
  return {
    type: SAVE_REMINDER_DETAILS_SUCCESS,
    payload: data,
  }
};

export function saveIsReminderUsingVehicle(data){
  return {
    type: SAVE_IS_NEW_REMINDER_USING_VEHICLE,
    payload: data,
  }
};

export function saveReminderVehicleDetails(data){
  return {
    type: SAVE_NEW_REMINDER_VEHICLE_DETAILS,
    payload: data,
  }
};


    
export function sendReminderReminderRequest(data) {
  return {
    type: SEND_REMINDER_REMINDER_REQUEST,
    payload: data,
  }
};

export function loadUnconfirmedReminderListSuccess(data){
  return {
    type: LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS,
    payload: data,
  }
};

export function loadUnconfirmedReminderListRequest(){
  return {
    type: LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST,
  }
};