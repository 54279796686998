import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../../../layout/form/form.css'
import '../../../auth/auth.css'
// import ActivationDetails from '../../../auth/activate/ActivationDetails';
// import BillingInformation from '../../../auth/activate/BillingInformation';
// import Success from '../../../auth/activate/Success';
import { makeSelectPaymentSetupSuccess } from '../../../../store/selector/appBillingSelector';
import { loadPaymentSetupRequest, loadPaymentSetupSuccess } from '../../../../store/action/appBillingAction';
import { loadTenantBasicRequest, loadTenantPackageRequest, sendTenantActivateRequest } from '../../../../store/action/tenantAction';
import { makeSelectTenantActivateError, makeSelectTenantActivateLoading, makeSelectTenantActivateSuccess, makeSelectTenantBasic, makeSelectTenantPackage } from '../../../../store/selector/tenantSelector';
import Loader from '../../../layout/form/Loader';
// import Default from '../../../auth/activate/Default';
import { loadAppPackageRequest } from '../../../../store/action/action';
import { makeSelectAppBillingTermsList, makeSelectAppPackagesList } from '../../../../store/selector/selector';
import VemasLogo from '../../../../images/VEMAS.png';
import { numberWithCommas } from '../../../common';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import { Link } from 'react-router-dom';
// import SelectField from '../layout/form/SelectField';

function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}


class UpdatePackage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_item: 'secondStep',
            trailPeriod: 30,
            trialExpiryDate: new Date(),
            initial: true,
            appPrice: 20,
            isChangePackage: false,
            isChangeBillingTerm: false,
            selectedPackage: 0,
            selectedBillingTerm: 0,
            packageDescription: '',
            billingTermDescription: ''
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { tenantPackage, appPackageList, billingTermsList } = prevProps;
        const { initial } = prevState;
        if (initial && tenantPackage && tenantPackage.id > 0 && appPackageList && appPackageList.length > 0 && billingTermsList && billingTermsList.list && billingTermsList.list.length > 0) {
            const billingTermId = tenantPackage.billingTerm ? tenantPackage.billingTerm : billingTermsList.default
            const package_ = appPackageList.find(e => e.id === tenantPackage.id)
            const billingTerm = billingTermsList.list.find(e => e.id === billingTermId)
            return {
                trailPeriod: tenantPackage.trailPeriod,
                trialExpiryDate: tenantPackage.trialExpiryDate,
                appPrice: tenantPackage.price,
                selectedPackage: tenantPackage.id,
                selectedBillingTerm: billingTermId,
                packageDescription: package_.description,
                billingTermDescription: billingTerm.description,
                initial: false
            }
        }
        return null
    }

    handleChangePackage = () => {
        this.setState({
            isChangePackage: true
        })
    }

    handleChangePackageClose = () => {
        const { tenantPackage, appPackageList } = this.props;
        const package_ = appPackageList.find(e => e.id === tenantPackage.id)
        this.setState({
            isChangePackage: false,
            selectedPackage: tenantPackage.id,
            packageDescription: package_.description,
        })
    }

    clickPackage = (id) => {
        const { appPackageList } = this.props;
        const package_ = appPackageList.find(e => e.id === id)
        this.setState({
            selectedPackage: id,
            appPrice: package_.price,
            packageDescription: package_.description,
        })
    }

    handlePackage = () => {
        this.setState({
            isChangePackage: false
        })
    }

    handleChangeBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: true
        })
    }

    handleChangeBillingTermClose = () => {
        const { billingTermsList } = this.props
        const billingTerm = billingTermsList.list.find(e => e.id === billingTermsList.default)
        this.setState({
            isChangeBillingTerm: false,
            selectedBillingTerm: billingTermsList.default,
            billingTermDescription: billingTerm.description
        })
    }

    clickBillingTerm = (id) => {
        const { billingTermsList } = this.props;
        const billingTerm = billingTermsList.list.find(e => e.id === id)
        this.setState({
            selectedBillingTerm: id,
            billingTermDescription: billingTerm.description
        })
    }

    handleBillingTerm = () => {
        this.setState({
            isChangeBillingTerm: false,
        })
    }

    handleChangeStep = (e) => {
        this.setState({
            current_item: e
        })
        if (e === "thirdStep") {
            this.props.dispatch(loadPaymentSetupRequest())
        }
        if (e === "secondStep") {
            this.props.dispatch(loadPaymentSetupSuccess(null))
        }
    }

    handleSubmitPaymentMethod = async (event, stripe, elements) => {
        event.preventDefault();

        const { paymentSetup } = this.props;

        if (elements == null) {
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            return;
        }

        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret: paymentSetup.setupIntentClientSecret,
            redirect: 'if_required'
        })

        if (error) {
            this.setState({ isValidationError: true, errorMassage: error.message })
        } else {
            this.props.dispatch(loadPaymentSetupSuccess(null))
            const parms = {
                setupIntent: paymentSetup.setupIntent,
                paymentSetupId: paymentSetup.id,
                billingTermId: this.state.selectedBillingTerm,
                packageId: this.state.selectedPackage
            }
            this.props.dispatch(sendTenantActivateRequest(parms))
            this.setState({
                current_item: "fourthStep"
            })
        }
    };


    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(loadAppPackageRequest())
        this.props.dispatch(loadTenantPackageRequest())
    }

    render() {
        const {
            show,
            tenantActivateLoading,
            handleChangePackageClose,
            appPackageList,
            billingTermsList,
            handlePackageUpdate
        } = this.props;

        const {
            appPrice,
            isChangePackage,
            isChangeBillingTerm,
            selectedPackage,
            selectedBillingTerm,
            packageDescription,
            billingTermDescription
        } = this.state;

        return (
            <dev>
                {tenantActivateLoading ?
                    <Loader show={tenantActivateLoading} /> : ''}
                <BootstrapModal
                    show={show}
                    animation={false}
                    // size="md"
                    dialogClassName="active_modal_width"
                    centered
                >
                    <BootstrapModal.Body>

                        <div className="vemas_logo">
                            <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
                        </div>
                        <div style={{ margin: '0 5% 2% 5%' }}>
                            {isChangePackage ?
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        {appPackageList && appPackageList.map(i => (
                                            <div onClick={() => this.clickPackage(i.id)} style={{ cursor: 'pointer', textAlign: 'center', border: '1px solid #ffffff', borderRadius: '10px', padding: '10px', width: '200px', backgroundColor: selectedPackage === i.id ? '#c6c2c2' : '' }}>
                                                <h4>{i.description}</h4>
                                                <div>$ {numberWithCommas(parseFloat(i.price).toFixed(2))}</div>
                                                <div>Per Month</div>
                                                <h6 style={{ padding: '10px 5px', margin: '10px 2px', border: '1px solid #6b6a6a', borderRadius: '10px', backgroundColor: selectedPackage === i.id ? '#04942f' : '', color: selectedPackage === i.id ? '#ffffff' : '' }}>SELECT</h6>
                                            </div>))}
                                    </div>
                                    <div className="filter-btns" style={{ margin: '20px 0' }}>
                                        <ButtonGroup
                                            primaryBtnClick={() => this.handleChangePackageClose()}
                                            primaryBtnVariant="outline-primary"
                                            primaryBtnText='Reset'
                                            secondaryBtnClick={() => this.handlePackage()}
                                            secondaryBtnText='Change'
                                            secondaryBtnVariant="outline-primary"
                                        />
                                    </div>
                                </div> :
                                isChangeBillingTerm ?
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            {billingTermsList && billingTermsList.list && billingTermsList.list.map(i => (
                                                <div onClick={() => this.clickBillingTerm(i.id)} style={{ cursor: 'pointer', textAlign: 'center', border: '1px solid #ffffff', borderRadius: '10px', padding: '10px', width: '200px', backgroundColor: selectedBillingTerm === i.id ? '#c6c2c2' : '' }}>
                                                    <h4>{i.description}</h4>
                                                    <div>Discount -  {numberWithCommas(parseFloat(i.discount).toFixed(2))} %</div>
                                                    <h6 style={{ padding: '10px 5px', margin: '10px 2px', border: '1px solid #6b6a6a', borderRadius: '10px', backgroundColor: selectedBillingTerm === i.id ? '#04942f' : '', color: selectedBillingTerm === i.id ? '#ffffff' : '' }}>SELECT</h6>
                                                </div>))}
                                        </div>
                                        <div className="filter-btns" style={{ margin: '20px 0' }}>
                                            <ButtonGroup
                                                primaryBtnClick={() => this.handleChangeBillingTermClose()}
                                                primaryBtnVariant="outline-primary"
                                                primaryBtnText='Reset'
                                                secondaryBtnClick={() => this.handleBillingTerm()}
                                                secondaryBtnText='Change'
                                                secondaryBtnVariant="outline-primary"
                                            />
                                        </div>
                                    </div> :
                                    <div>
                                        <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '10px', fontSize: "18px", color: "orange", margin: '2%' }}>
                                            Your monthly subscription fee for the {packageDescription} is ${numberWithCommas(parseFloat(appPrice).toFixed(2))} per month, and your current payment term is {billingTermDescription}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px', marginBottom: '25px', }}>
                                            <ButtonGroup
                                                primaryBtnClick={() => this.handleChangePackage()}
                                                primaryBtnVariant="outline-primary"
                                                primaryBtnText='Change package'
                                                secondaryBtnClick={() => this.handleChangeBillingTerm()}
                                                secondaryBtnText='Change billing term'
                                                secondaryBtnVariant="outline-primary"
                                            />
                                        </div>
                                        <div>
                                            We trust VEMAS will help you run your workshop simple and more efficiently. By clicking Agree, you agree to our Terms and Conditions and have read and acknowledge our Privacy Statement.(<Link onClick={() => { openInNewTab('https://vemas.com.au/privacy/') }}>Privacy policy</Link> and <Link>Terms of use</Link>)
                                        </div>
                                        <div className="filter-btns" style={{ margin: '20px 0' }}>
                                            <ButtonGroup
                                                primaryBtnClick={() => handleChangePackageClose()}
                                                primaryBtnVariant="outline-primary"
                                                primaryBtnText='Back'
                                                secondaryBtnClick={() => handlePackageUpdate(this.state)}
                                                secondaryBtnText='Save'
                                                secondaryBtnVariant="outline-primary"
                                            />
                                        </div>
                                    </div>}
                        </div>

                    </BootstrapModal.Body>
                </BootstrapModal>
            </dev>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    paymentSetup: makeSelectPaymentSetupSuccess(),
    tenantActivateLoading: makeSelectTenantActivateLoading(),
    tenantActivateError: makeSelectTenantActivateError(),
    tenantActivateSuccess: makeSelectTenantActivateSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    appPackageList: makeSelectAppPackagesList(),
    tenantPackage: makeSelectTenantPackage(),
    billingTermsList: makeSelectAppBillingTermsList()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default withRouter(compose(withConnect)(UpdatePackage));