import { takeLatest, put, call } from 'redux-saga/effects';
import * as customer from './customerSaga';
import * as invoice from './invoiceSaga';
import * as tenant from './tenantSaga';
import * as report from './reportSaga';
import * as booking from './bookingSaga';
import * as dashboard from './dashboardSaga';
import * as quotation from './quotationSaga';
import * as supplier from './supplierSaga';
import * as purchasing from './purchasingSaga';
import * as user from './usersSaga';
import * as items from './itemSaga';
import * as stockDocument from './stockDocumentSaga';
import * as emailTemplate from './emailTempSaga';
import * as smsTemplate from './smsTempSaga';
import * as notification from './notificationSaga';
import * as title from './titleSaga';
import * as product from './productSaga';
import * as productCategory from './productCategorySaga';
import * as accountProductCategory from './accountProductCategorySaga';
import * as a2aMapping from './a2aMappingSaga';
import * as accounting from './accountSaga';
import * as remainder from './reminderSaga';
import * as analytics from './analyticsSaga';
import * as appBilling from './appBilling';
import * as userRole from './usersRoleSaga';
import { 
  LOAD_CUSTOMER_LIST_REQUEST, 
  LOAD_SELECTED_CUSTOMER_REQUEST, 
  SEND_ADD_NEW_CUSTOMER_REQUEST, 
  SEND_CUSTOMER_NOTIFICATION_REQUEST, 
  SEND_DELETE_CUSTOMER_REQUEST, 
  SEND_UPDATE_CUSTOMER_REQUEST 
} from '../../store/constant/customerConstant';
import { 
  LOAD_OBJECT_STATUS_REQUEST,
  LOAD_UOM_REQUEST,
  LOAD_COUNTRY_REQUEST,
  LOAD_TENANT_REQUEST,
  LOAD_TENANT_USER_REQUEST,
  LOAD_APP_BILLING_TERMS_REQUEST,
  LOAD_APP_PACKAGE_REQUEST
} from '../../store/constant/constant';
import {
  LOAD_INVOICE_ADD_PRINT_REQUEST, 
  LOAD_INVOICE_EMAIL_REQUEST, 
  LOAD_INVOICE_LIST_REQUEST, 
  LOAD_INVOICE_PRINT_BY_ID_REQUEST, 
  LOAD_INVOICE_PRINT_REQUEST, 
  LOAD_PAYMENT_METHOD_REQUEST, 
  // LOAD_INVOICE_SEND_BY_ID_REQUEST, 
  // LOAD_INVOICE_SEND_REQUEST, 
  LOAD_PAYMENT_TERM_REQUEST, 
  LOAD_SELECTED_INVOICE_REQUEST, 
  SEND_ADD_NEW_INVOICE_REQUEST, 
  SEND_DELETE_INVOICE_REQUEST, 
  SEND_INVOICE_EMAIL_REQUEST, 
  SEND_INVOICE_NOTIFICATION_REQUEST, 
  SEND_UPDATE_INVOICE_REQUEST 
} from '../../store/constant/invoiceConstant';
import { 
  LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST, 
  LOAD_TENANT_BASIC_REQUEST, 
  LOAD_TENANT_CORRESPONDENCE_REQUEST, 
  LOAD_TENANT_DEFAULT_REQUEST, 
  LOAD_TENANT_FINANCE_REQUEST, 
  LOAD_TENANT_PACKAGE_REQUEST, 
  LOAD_TENANT_PAYMENT_METHOD_REQUEST, 
  LOAD_TENANT_SERVICE_AREA_REQUEST, 
  LOAD_TENANT_SERVICE_REQUEST, 
  SEND_TENANT_ACTIVATE_REQUEST, 
  SEND_TENANT_IMAGE_REQUEST, 
  SEND_UPDATE_TENANT_DETAILS_REQUEST 
} from '../../store/constant/tenantConstant';
import { 
  LOAD_REPORT_CSV_REQUEST, 
  LOAD_REPORT_PERIOD_REQUEST, 
  LOAD_REPORT_PRINT_REQUEST, 
  LOAD_REPORT_REQUEST 
} from '../../store/constant/reportConstant';
import { 
  LOAD_BOOKING_LIST_REQUEST, 
  // LOAD_SEARCHED_VEHICLE_REQUEST, 
  SEND_ADD_NEW_BOOKING_REQUEST, 
  SEND_BOOKING_DELETE_REQUEST, 
  SEND_BOOKING_REMINDER_REQUEST, 
  SEND_BOOKING_UPDATE_REQUEST, 
  SEND_NEW_CUSTOMER_REQUEST
} from '../../store/constant/bookingConstant';
import { 
  LOAD_DASHBOARD_REQUEST, 
  LOAD_PURCHASING_SUMMARY_REQUEST, 
  LOAD_RATING_SUMMARY_REQUEST, 
  LOAD_SALES_SUMMARY_REQUEST 
} from '../../store/constant/dashboardConstant';
import { 
  LOAD_QUOTATION_LIST_REQUEST, 
  LOAD_SELECTED_QUOTATION_REQUEST, 
  SEND_ADD_AND_PRINT_QUOTATION_REQUEST, 
  SEND_ADD_NEW_QUOTATION_REQUEST, 
  SEND_DELETE_QUOTATION_REQUEST, 
  SEND_EMAIL_QUOTATION_REQUEST, 
  SEND_PRINT_QUOTATION_REQUEST, 
  SEND_QUOTATION_IMAGE_EMAIL_REQUEST, 
  SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST, 
  SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST, 
  SEND_UPDATE_QUOTATION_REQUEST 
} from '../../store/constant/quotationConstant';
import { 
  LOAD_SELECTED_SUPPLIER_REQUEST, 
  LOAD_SUPPLIER_LIST_REQUEST, 
  SEND_ADD_NEW_SUPPLIER_REQUEST, 
  SEND_DELETE_SUPPLIER_REQUEST, 
  SEND_UPDATE_SUPPLIER_REQUEST 
} from '../../store/constant/supplierConstant';
import { 
  LOAD_PURCHASING_LIST_REQUEST, 
  LOAD_PURCHASING_PRINT_BY_ID_REQUEST, 
  LOAD_PURCHASING_SEND_BY_ID_REQUEST, 
  LOAD_SELECTED_PURCHASING_REQUEST, 
  SEND_ADD_NEW_PURCHASING_REQUEST, 
  SEND_DELETE_PURCHASING_REQUEST, 
  SEND_PURCHASING_ADD_PRINT_REQUEST, 
  SEND_PURCHASING_ADD_SEND_REQUEST, 
  SEND_PURCHASING_PRINT_REQUEST, 
  SEND_PURCHASING_SEND_REQUEST, 
  SEND_UPDATE_PURCHASING_REQUEST 
} from '../../store/constant/purchasingConstant';
import { 
  LOAD_OBJECT_LIST_REQUEST, 
  LOAD_OBJECT_OPERATION_LIST_REQUEST, 
  LOAD_USER_ROLE_LIST_REQUEST, 
  LOAD_USER_ROLE_OBJECT_LIST_REQUEST, 
  SEND_ADD_NEW_USER_ROLE_REQUEST, 
  SEND_DELETE_USER_ROLE_REQUEST, 
  SEND_UPDATE_USER_ROLE_REQUEST 
} from '../../store/constant/userRolesConstant';
import { 
  LOAD_SELECTED_USER_REQUEST,
  LOAD_USER_ACCESS_REQUEST,
  LOAD_USER_LIST_REQUEST,
  SEND_ADD_NEW_USER_REQUEST,
  SEND_USER_STATUS_UPDATE_REQUEST,
  SEND_USER_UPDATE_REQUEST
} from '../../store/constant/usersConstant';
import { 
  LOAD_ITEM_CATEGORY_LIST_REQUEST,
  SEND_ADD_NEW_ITEM_REQUEST,
  LOAD_ITEM_LIST_REQUEST,
  SEND_UPDATE_ITEM_REQUEST,
  SEND_DELETE_ITEM_REQUEST
} from '../../store/constant/itemConstant';
import { 
  loadAppBillingTermsSuccess,
  loadAppPackageSuccess,
  loadCountrySuccess, 
  loadObjectStatusSuccess, 
  loadTenantSuccess, 
  loadTenantUserSuccess, 
  loadUOMSuccess, 
  saveTenantUserExpireStatus
} from '../../store/action/action';
import { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import {
  getAppPackages,
  getBillingTerms,
  getCountryList,
  getObjectStatusList,
  getTenantCommon,
  getUOMList,
} from '../service/service';
import { getSelectedUserRequest } from '../service/usersService';
import { 
  LOAD_SELECTED_STOCK_DOCUMENT_REQUEST, 
  LOAD_STOCK_DOCUMENT_LIST_REQUEST, 
  LOAD_STOCK_DOCUMENT_PRINT_REQUEST, 
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST, 
  LOAD_STOCK_DOCUMENT_TYPE_REQUEST, 
  SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST, 
  SEND_STOCK_DOCUMENT_PRINT_REQUEST 
} from '../../store/constant/stockDocumentConstant';
import { 
  LOAD_EMAIL_TEMPLATE_LIST_REQUEST, 
  SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST, 
  SEND_DELETE_EMAIL_TEMPLATE_REQUEST, 
  SEND_UPDATE_EMAIL_TEMPLATE_REQUEST 
} from '../../store/constant/emailTempConstant';
import { 
  LOAD_SMS_TEMPLATE_LIST_REQUEST, 
  SEND_ADD_NEW_SMS_TEMPLATE_REQUEST, 
  SEND_DELETE_SMS_TEMPLATE_REQUEST, 
  SEND_UPDATE_SMS_TEMPLATE_REQUEST 
} from '../../store/constant/smsTempConstant';
import { 
  LOAD_NOTIFICATION_LIST_REQUEST, 
  LOAD_SMS_NOTIFICATION_LIST_REQUEST 
} from '../../store/constant/notificationConstant';
import { LOAD_TITLE_LIST_REQUEST } from '../../store/constant/titleConstant';
import { LOAD_ALL_PRODUCTS_REQUEST, LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST, LOAD_BALANCE_STOCK_PRINT_REQUEST, LOAD_PRODUCT_LIST_REQUEST, LOAD_PRODUCT_TYPE_LIST_REQUEST, LOAD_SELECTED_PRODUCT_REQUEST, SEND_ADD_NEW_PRODUCT_REQUEST, SEND_ALTERNATIVE_PRODUCT_REQUEST, SEND_DELETE_PRODUCT_REQUEST, SEND_UPDATE_PRODUCT_REQUEST } from '../../store/constant/productConstant';
import { LOAD_PRODUCT_CATEGORY_LIST_REQUEST, SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST, SEND_DELETE_PRODUCT_CATEGORY_REQUEST, SEND_UPDATE_PRODUCT_CATEGORY_REQUEST } from '../../store/constant/productCategoryConstant';
import {
  LOAD_A2A_ACCOUNT_REQUEST,
  LOAD_A2A_CUSTOMER_REQUEST,
  // LOAD_A2A_PRODUCT_CATEGORY_REQUEST, 
  LOAD_ACCOUNT_CUSTOMER_REQUEST,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST,
  SEND_A2A_ACCOUNT_REQUEST, SEND_A2A_CUSTOMER_REQUEST,
  // SEND_A2A_PRODUCT_CATEGORY_REQUEST 
} from '../../store/constant/accountProductCategoryConstant';
import { LOAD_ACCOUNT_SYSTEM_REQUEST, LOAD_QUICKBOOKS_CONNECTION_REQUEST, SEND_CONNECTION_REQUEST, SEND_CONNECTION_UPDATE_REQUEST, SEND_DELETE_CONNECTION_REQUEST } from '../../store/constant/accountConstant';
import { LOAD_ACCOUNT_COMPANY_FILE_REQUEST, LOAD_ACCOUNT_TAX_CODE_REQUEST, LOAD_INVOICE_RECONCILIATION_REPORT_REQUEST, SEND_ACCOUNT_COMPANY_FILE_REQUEST, SEND_ACCOUNT_TAX_CODE_REQUEST } from '../../store/constant/a2aMappingConstant';
import { LOAD_REMINDER_LIST_REQUEST, LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST, SEND_ADD_NEW_REMINDER_REQUEST, SEND_REMINDER_DELETE_REQUEST, SEND_REMINDER_UPDATE_REQUEST } from '../../store/constant/reminderConstant';
import { LOAD_CUSTOMER_SUMMARY_REQUEST, LOAD_RATING_REQUEST } from '../../store/constant/analyticsConstant';
import { LOAD_APP_INVOICE_LIST_REQUEST, LOAD_APP_INVOICE_PRINT_REQUEST, LOAD_APP_PAYMENT_METHOD_REQUEST, LOAD_PAYMENT_SETUP_REQUEST, LOAD_SELECTED_APP_INVOICE_REQUEST, SEND_APP_BILLING_REQUEST, SEND_DEFAULT_PAYMENT_METHOD_REQUEST, SEND_PAYMENT_METHOD_REQUEST, SEND_UPDATE_PACKAGE_REQUEST, SEND_UPDATE_PAYMENT_METHOD_REQUEST } from '../../store/constant/appBillingConstant';
import { expireStatusRequest } from '../service/authService';
import { SAVE_EXPIRE_STATUS_REQUEST } from '../../store/constant/authConstant';


export function* loadObjectStatusRequest(data){
  try{
    const response = yield call(getObjectStatusList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      yield put(loadObjectStatusSuccess(response.data.result.result))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
  }
}

export function* loadUOMRequest(){
  try{
    const response = yield call(getUOMList);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      yield put(loadUOMSuccess(response.data.result.result))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
  }
}

export function* loadCountryRequest(){
  try{
    const response = yield call(getCountryList);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      yield put(loadCountrySuccess(response.data.result.result))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
  }
}

export function* loadTenantCommonRequest(data){
  try{
    const response = yield call(getTenantCommon,data.payload);
    if(response && response.status && response.status === 200 ){
      sessionStorage.setItem('dateFormat', response.data.dateFormat);
      const tenant = response.data
      yield put(loadTenantSuccess(tenant))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data  && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    } 
  }catch(error){
  }
}

export function* loadTenantUserRequest(data){
  try{
    const response = yield call(getSelectedUserRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const User = response.data
      yield put(loadTenantUserSuccess(User))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
  }catch(error){
  }
}


export function* loadBillingTermsRequest() {
  try {
    const response = yield call(getBillingTerms);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result;
      const default_ = list.find(e => e.default === true)
      const result = {
        list: list,
        default: default_.id
      }
      yield put(loadAppBillingTermsSuccess(result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* loadAppPackagesRequest() {
  try {
    const response = yield call(getAppPackages);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const list = response.data.result.result;
      yield put(loadAppPackageSuccess(list))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* loadExpireStatusRequest() {
  try {
    const response = yield call(expireStatusRequest);
    if (response && response.status && response.status === 200) {
      yield put(saveTenantUserExpireStatus(response.data.expireStatus))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* rootSaga(){
  yield takeLatest(LOAD_TENANT_USER_REQUEST, loadTenantUserRequest);
  yield takeLatest(LOAD_OBJECT_STATUS_REQUEST,loadObjectStatusRequest);
  yield takeLatest(LOAD_UOM_REQUEST,loadUOMRequest);
  yield takeLatest(LOAD_TENANT_REQUEST, loadTenantCommonRequest);
  yield takeLatest(LOAD_COUNTRY_REQUEST, loadCountryRequest);
  yield takeLatest(LOAD_APP_BILLING_TERMS_REQUEST, loadBillingTermsRequest);
  yield takeLatest(LOAD_APP_PACKAGE_REQUEST, loadAppPackagesRequest);
  yield takeLatest(SAVE_EXPIRE_STATUS_REQUEST, loadExpireStatusRequest);
  yield takeLatest(LOAD_CUSTOMER_LIST_REQUEST, customer.loadCustomerListRequest);  
  yield takeLatest(SEND_ADD_NEW_CUSTOMER_REQUEST, customer.sendAddNewCustomerRequest);
  yield takeLatest(LOAD_SELECTED_CUSTOMER_REQUEST, customer.loadSelectedCustomerRequest);
  yield takeLatest(SEND_UPDATE_CUSTOMER_REQUEST, customer.sendUpdateCustomerRequest);
  yield takeLatest(SEND_DELETE_CUSTOMER_REQUEST, customer.sendDeleteCustomerRequest);
  yield takeLatest(SEND_CUSTOMER_NOTIFICATION_REQUEST, customer.customerNotificationRequest);
  yield takeLatest(LOAD_INVOICE_LIST_REQUEST, invoice.loadInvoiceListRequest);
  yield takeLatest(SEND_ADD_NEW_INVOICE_REQUEST, invoice.sendAddNewInvoiceRequest);
  yield takeLatest(LOAD_SELECTED_INVOICE_REQUEST, invoice.loadSelectedInvoiceRequest);
  yield takeLatest(SEND_DELETE_INVOICE_REQUEST, invoice.sendDeleteInvoiceRequest);
  yield takeLatest(SEND_UPDATE_INVOICE_REQUEST, invoice.sendUpdateInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_PRINT_REQUEST, invoice.sendPrintInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_ADD_PRINT_REQUEST, invoice.sendAddAndPrintInvoiceRequest);
  yield takeLatest(LOAD_INVOICE_PRINT_BY_ID_REQUEST, invoice.loadPrintbyIdRequest);
  yield takeLatest(SEND_INVOICE_NOTIFICATION_REQUEST, invoice.invoiceNotificationRequest);
  yield takeLatest(LOAD_PAYMENT_TERM_REQUEST, invoice.loadPaymentTermRequest);
  yield takeLatest(LOAD_PAYMENT_METHOD_REQUEST, invoice.loadPaymentMethodRequest);
  yield takeLatest(LOAD_INVOICE_EMAIL_REQUEST, invoice.loadInvoiceEmailRequest);
  yield takeLatest(SEND_INVOICE_EMAIL_REQUEST, invoice.sendInvoiceEmailRequest);
  yield takeLatest(LOAD_TENANT_BASIC_REQUEST, tenant.loadTenantBasicRequest);
  yield takeLatest(LOAD_TENANT_FINANCE_REQUEST, tenant.loadTenantFinanceRequest);
  yield takeLatest(SEND_UPDATE_TENANT_DETAILS_REQUEST, tenant.sendUpdateTenantRequest);
  yield takeLatest(LOAD_TENANT_CORRESPONDENCE_REQUEST, tenant.loadTenantCorrespondanceRequest);
  yield takeLatest(LOAD_TENANT_DEFAULT_REQUEST, tenant.loadTenantDefaultRequest);
  yield takeLatest(LOAD_TENANT_ACCOUNT_SYSTEM_REQUEST, tenant.loadTenantAccountSystemRequest);
  yield takeLatest(LOAD_TENANT_SERVICE_REQUEST, tenant.loadTenantServicesRequest);
  yield takeLatest(LOAD_TENANT_SERVICE_AREA_REQUEST, tenant.loadTenantServicesAreaRequest);
  yield takeLatest(LOAD_TENANT_PAYMENT_METHOD_REQUEST, tenant.loadTenantPaymentMethodRequest);
  yield takeLatest(SEND_TENANT_ACTIVATE_REQUEST, tenant.sendTenantActivateRequest);
  yield takeLatest(LOAD_TENANT_PACKAGE_REQUEST, tenant.loadTenantPackageRequest);
  yield takeLatest(SEND_TENANT_IMAGE_REQUEST, tenant.sendUpdateTenantImageRequest);
  yield takeLatest(LOAD_REPORT_PERIOD_REQUEST, report.loadReportPeriodRequest);
  yield takeLatest(LOAD_REPORT_REQUEST, report.loadReportListRequest);
  yield takeLatest(LOAD_REPORT_PRINT_REQUEST, report.loadReportPrintRequest);
  yield takeLatest(LOAD_REPORT_CSV_REQUEST, report.loadReportCSVRequest);
  yield takeLatest(SEND_ADD_NEW_BOOKING_REQUEST, booking.sendAddNewBookingRequest);
  yield takeLatest(LOAD_BOOKING_LIST_REQUEST, booking.loadBookingListRequest);
  yield takeLatest(SEND_BOOKING_DELETE_REQUEST, booking.sendDeleteBookingRequest);
  yield takeLatest(SEND_BOOKING_UPDATE_REQUEST, booking.sendUpdateBookingRequest);
  // yield takeLatest(LOAD_SEARCHED_VEHICLE_REQUEST, booking.loadSearchedVehicleRequest);
  yield takeLatest(SEND_BOOKING_REMINDER_REQUEST, booking.sendBookingReminderRequest);
  yield takeLatest(SEND_NEW_CUSTOMER_REQUEST, booking.sendAddNewCustomerRequest);
  yield takeLatest(LOAD_DASHBOARD_REQUEST, dashboard.loadDashboardDetailsRequest);
  yield takeLatest(LOAD_SALES_SUMMARY_REQUEST, dashboard.loadSalesSummaryRequest);
  yield takeLatest(LOAD_RATING_SUMMARY_REQUEST, dashboard.loadRatingSummaryRequest);
  yield takeLatest(LOAD_PURCHASING_SUMMARY_REQUEST, dashboard.loadPurchaseOrderSummaryRequest);
  yield takeLatest(LOAD_QUOTATION_LIST_REQUEST, quotation.loadQuotationListRequest);
  yield takeLatest(SEND_ADD_NEW_QUOTATION_REQUEST, quotation.sendAddNewQuotationRequest);
  yield takeLatest(LOAD_SELECTED_QUOTATION_REQUEST, quotation.loadSelectedQuotationRequest);
  yield takeLatest(SEND_UPDATE_QUOTATION_REQUEST, quotation.sendUpdateQuotationRequest);
  yield takeLatest(SEND_DELETE_QUOTATION_REQUEST, quotation.sendDeleteQuotationRequest);
  yield takeLatest(SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST, quotation.sendPrintQuotationRequest);
  yield takeLatest(SEND_ADD_AND_PRINT_QUOTATION_REQUEST, quotation.sendAddAndPrintQuotationRequest);
  yield takeLatest(SEND_PRINT_QUOTATION_REQUEST, quotation.loadPrintbyIdRequest);
  yield takeLatest(SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST, quotation.sendEmailQuotationRequest);
  yield takeLatest(SEND_EMAIL_QUOTATION_REQUEST, quotation.sendQuotationSendByIdRequest);
  yield takeLatest(SEND_QUOTATION_IMAGE_EMAIL_REQUEST, quotation.sendQuotationImageEmailRequest);
  yield takeLatest(LOAD_SUPPLIER_LIST_REQUEST, supplier.loadSupplierListRequest);
  yield takeLatest(LOAD_SELECTED_SUPPLIER_REQUEST, supplier.loadSelectedSupplierRequest);
  yield takeLatest(SEND_ADD_NEW_SUPPLIER_REQUEST, supplier.sendAddNewSupplierRequest);
  yield takeLatest(SEND_UPDATE_SUPPLIER_REQUEST, supplier.sendUpdateSupplierRequest);
  yield takeLatest(SEND_DELETE_SUPPLIER_REQUEST, supplier.sendDeleteSupplierRequest);
  yield takeLatest(LOAD_PURCHASING_LIST_REQUEST, purchasing.loadPurchasingListRequest);
  yield takeLatest(SEND_ADD_NEW_PURCHASING_REQUEST, purchasing.sendAddNewPurchasingRequest);
  yield takeLatest(LOAD_SELECTED_PURCHASING_REQUEST, purchasing.loadSelectedPurchasingRequest);
  yield takeLatest(SEND_UPDATE_PURCHASING_REQUEST, purchasing.sendUpdatePurchasingRequest);
  yield takeLatest(SEND_DELETE_PURCHASING_REQUEST, purchasing.sendDeletePurchasingRequest);
  yield takeLatest(LOAD_PURCHASING_PRINT_BY_ID_REQUEST, purchasing.loadPrintbyIdRequest);
  yield takeLatest(LOAD_PURCHASING_SEND_BY_ID_REQUEST, purchasing.loadPurchasingSendByIdRequest);
  yield takeLatest(SEND_PURCHASING_ADD_PRINT_REQUEST, purchasing.sendAddAndPrintPurchasingRequest);
  yield takeLatest(SEND_PURCHASING_PRINT_REQUEST, purchasing.sendPrintPurchasingRequest);
  yield takeLatest(SEND_PURCHASING_ADD_SEND_REQUEST, purchasing.sendAddAndEmailPurchasingRequest);
  yield takeLatest(SEND_PURCHASING_SEND_REQUEST, purchasing.sendUpdateAndEmailPurchasingRequest);
  yield takeLatest(SEND_ADD_NEW_USER_REQUEST, user.sendAddNewUserRequest);
  yield takeLatest(LOAD_USER_LIST_REQUEST, user.loadUserListRequest);
  yield takeLatest(SEND_USER_STATUS_UPDATE_REQUEST, user.sendUpdateUserStatusRequest);
  yield takeLatest(LOAD_SELECTED_USER_REQUEST, user.loadSelectedUserRequest);
  yield takeLatest(SEND_USER_UPDATE_REQUEST, user.sendUpdateUserRequest);
  yield takeLatest(LOAD_USER_ACCESS_REQUEST, user.loadUserAccessRequest);
  yield takeLatest(SEND_ADD_NEW_ITEM_REQUEST,items.sendAddNewItemRequest);
  yield takeLatest(LOAD_ITEM_LIST_REQUEST,items.loadItemListRequest);
  yield takeLatest(SEND_UPDATE_ITEM_REQUEST, items.sendUpdateItemRequest);
  yield takeLatest(SEND_DELETE_ITEM_REQUEST, items.sendDeleteItemRequest);
  yield takeLatest(LOAD_ITEM_CATEGORY_LIST_REQUEST,items.loadItemCategoryListRequest);
  yield takeLatest(LOAD_STOCK_DOCUMENT_TYPE_REQUEST, stockDocument.loadStockDocumentTypeRequest);
  yield takeLatest(LOAD_STOCK_DOCUMENT_LIST_REQUEST, stockDocument.loadStockDocumentListRequest);
  yield takeLatest(LOAD_SELECTED_STOCK_DOCUMENT_REQUEST, stockDocument.loadSelectedStockDocumentRequest);
  yield takeLatest(SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST, stockDocument.sendAddStockDocumentRequest);
  yield takeLatest(SEND_STOCK_DOCUMENT_PRINT_REQUEST, stockDocument.sendAddAndPrintStockDocumentRequest);
  yield takeLatest(LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST, stockDocument.loadStockDocumentProductReportRequest);
  yield takeLatest(LOAD_STOCK_DOCUMENT_PRINT_REQUEST, stockDocument.loadPrintStockDocumentRequest);
  yield takeLatest(LOAD_EMAIL_TEMPLATE_LIST_REQUEST, emailTemplate.loadEmailTemplateListRequest);
  yield takeLatest(SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST, emailTemplate.sendAddNewEmailTemplateRequest);
  yield takeLatest(SEND_UPDATE_EMAIL_TEMPLATE_REQUEST, emailTemplate.sendUpdateEmailTemplateRequest);
  yield takeLatest(SEND_DELETE_EMAIL_TEMPLATE_REQUEST, emailTemplate.sendDeleteEmailTemplateRequest);
  yield takeLatest(LOAD_SMS_TEMPLATE_LIST_REQUEST, smsTemplate.loadSMSTemplateListRequest);
  yield takeLatest(SEND_ADD_NEW_SMS_TEMPLATE_REQUEST, smsTemplate.sendAddNewSMSTemplateRequest);
  yield takeLatest(SEND_UPDATE_SMS_TEMPLATE_REQUEST, smsTemplate.sendUpdateSMSTemplateRequest);
  yield takeLatest(SEND_DELETE_SMS_TEMPLATE_REQUEST, smsTemplate.sendDeleteSMSTemplateRequest);
  yield takeLatest(LOAD_NOTIFICATION_LIST_REQUEST, notification.loadNotificationListRequest);
  yield takeLatest(LOAD_SMS_NOTIFICATION_LIST_REQUEST, notification.loadSMSNotificationListRequest);
  yield takeLatest(LOAD_TITLE_LIST_REQUEST, title.loadTitleListRequest);
  yield takeLatest(SEND_ADD_NEW_PRODUCT_REQUEST, product.sendAddNewProductRequest);
  yield takeLatest(LOAD_PRODUCT_LIST_REQUEST, product.loadProductListRequest);
  yield takeLatest(SEND_UPDATE_PRODUCT_REQUEST, product.sendUpdateProductRequest);
  yield takeLatest(SEND_DELETE_PRODUCT_REQUEST, product.sendDeleteProductRequest);
  yield takeLatest(LOAD_PRODUCT_TYPE_LIST_REQUEST, product.loadProductTypeRequest);
  yield takeLatest(LOAD_SELECTED_PRODUCT_REQUEST, product.loadSelectedProductRequest);
  yield takeLatest(LOAD_BALANCE_STOCK_PRINT_REQUEST, product.loadPrintBalanceStockRequest);
  yield takeLatest(LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST, product.loadSelectedProductAlternativeRequest);
  yield takeLatest(LOAD_ALL_PRODUCTS_REQUEST, product.loadAllProductsRequest);
  yield takeLatest(SEND_ALTERNATIVE_PRODUCT_REQUEST, product.sendUpdateAlternativeProductRequest);
  yield takeLatest(LOAD_PRODUCT_CATEGORY_LIST_REQUEST, productCategory.loadProductCategoryListRequest);
  yield takeLatest(SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST, productCategory.sendAddNewProductCategoryRequest);
  yield takeLatest(SEND_UPDATE_PRODUCT_CATEGORY_REQUEST, productCategory.sendUpdateProductCategoryRequest);
  yield takeLatest(SEND_DELETE_PRODUCT_CATEGORY_REQUEST, productCategory.sendDeleteProductCategoryRequest);
  yield takeLatest(LOAD_QUICKBOOKS_CONNECTION_REQUEST, accounting.loadQuickBooksConnectionRequest);
  yield takeLatest(LOAD_ACCOUNT_SYSTEM_REQUEST, accounting.loadAccountSystemRequest);
  yield takeLatest(SEND_DELETE_CONNECTION_REQUEST, accounting.sendDeleteConnectionRequest);
  yield takeLatest(SEND_CONNECTION_REQUEST, accounting.addConnectionRequest);
  yield takeLatest(SEND_CONNECTION_UPDATE_REQUEST, accounting.updateConnectionDateRequest);
  yield takeLatest(LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST, accountProductCategory.loadAccountProductCategoryRequest);
  // yield takeLatest(LOAD_A2A_PRODUCT_CATEGORY_REQUEST, accountProductCategory.loadMappingProductCategoryRequest);
  // yield takeLatest(SEND_A2A_PRODUCT_CATEGORY_REQUEST, accountProductCategory.sendUpdateMappingProductCategoryRequest);
  yield takeLatest(LOAD_ACCOUNT_CUSTOMER_REQUEST, accountProductCategory.loadAccountCustomerRequest);
  yield takeLatest(LOAD_A2A_CUSTOMER_REQUEST, accountProductCategory.loadMappingCustomerRequest);
  yield takeLatest(SEND_A2A_CUSTOMER_REQUEST, accountProductCategory.sendUpdateMappingCustomerRequest);
  yield takeLatest(LOAD_A2A_ACCOUNT_REQUEST, accountProductCategory.loadMappingAccountRequest);
  yield takeLatest(SEND_A2A_ACCOUNT_REQUEST, accountProductCategory.sendUpdateMappingAccountRequest);
  yield takeLatest(LOAD_ACCOUNT_COMPANY_FILE_REQUEST, a2aMapping.loadAccountCompanyFileRequest);
  yield takeLatest(SEND_ACCOUNT_COMPANY_FILE_REQUEST, a2aMapping.sendCompanyFileRequest);
  yield takeLatest(LOAD_ACCOUNT_TAX_CODE_REQUEST, a2aMapping.loadAccountTaxCodeRequest);
  yield takeLatest(SEND_ACCOUNT_TAX_CODE_REQUEST, a2aMapping.sendTaxCodeRequest);
  yield takeLatest(LOAD_INVOICE_RECONCILIATION_REPORT_REQUEST, a2aMapping.loadInvoiceReconciliationReportRequest);
  yield takeLatest(LOAD_REMINDER_LIST_REQUEST, remainder.loadReminderListRequest);
  yield takeLatest(SEND_REMINDER_DELETE_REQUEST, remainder.sendDeleteReminderRequest);
  yield takeLatest(SEND_REMINDER_UPDATE_REQUEST, remainder.sendUpdateReminderRequest);
  yield takeLatest(SEND_ADD_NEW_REMINDER_REQUEST, remainder.sendAddNewReminderRequest);
  yield takeLatest(LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST, remainder.loadUnconfirmedReminderListRequest);
  yield takeLatest(LOAD_RATING_REQUEST, analytics.loadRatingDetailsRequest);
  yield takeLatest(LOAD_CUSTOMER_SUMMARY_REQUEST, analytics.loadCustomerSummaryRequest);
  yield takeLatest(SEND_APP_BILLING_REQUEST, appBilling.sendUpdateBillingRequest);
  yield takeLatest(LOAD_PAYMENT_SETUP_REQUEST, appBilling.loadPaymentSetupRequest);
  yield takeLatest(SEND_PAYMENT_METHOD_REQUEST, appBilling.sendUpdatePaymentMethodRequest);
  yield takeLatest(LOAD_APP_INVOICE_LIST_REQUEST, appBilling.loadAppInvoiceListRequest);
  yield takeLatest(LOAD_SELECTED_APP_INVOICE_REQUEST, appBilling.loadSelectedAppInvoiceRequest);
  yield takeLatest(LOAD_APP_INVOICE_PRINT_REQUEST, appBilling.loadPrintbyIdRequest);
  yield takeLatest(LOAD_APP_PAYMENT_METHOD_REQUEST, appBilling.loadAppPaymentMethodRequest);
  yield takeLatest(SEND_DEFAULT_PAYMENT_METHOD_REQUEST, appBilling.sendDefaultPaymentMethodRequest);
  yield takeLatest(SEND_UPDATE_PAYMENT_METHOD_REQUEST, appBilling.sendUpdatePaymentMethodDetailsRequest);
  yield takeLatest(SEND_UPDATE_PACKAGE_REQUEST, appBilling.sendUpdatePackageRequest);
  yield takeLatest(LOAD_USER_ROLE_LIST_REQUEST, userRole.loadUserRoleListRequest);
  yield takeLatest(LOAD_USER_ROLE_OBJECT_LIST_REQUEST, userRole.loadUserRoleObjectListRequest);
  yield takeLatest(LOAD_OBJECT_LIST_REQUEST, userRole.loadObjectRequest);
  yield takeLatest(LOAD_OBJECT_OPERATION_LIST_REQUEST, userRole.loadObjectOperationRequest);
  yield takeLatest(SEND_ADD_NEW_USER_ROLE_REQUEST, userRole.sendAddNewUserRoleRequest);
  yield takeLatest(SEND_UPDATE_USER_ROLE_REQUEST, userRole.sendUpdateUserRoleRequest);
  yield takeLatest(SEND_DELETE_USER_ROLE_REQUEST, userRole.sendDeleteUserRoleRequest);
}