import React from "react";
import { Link } from 'react-router-dom';
import '../../layout/form/form.css'
import ButtonGroup from '../../layout/form/ButtonGroup';
import VemasLogo from '../../../images/VEMAS.png';
import { numberWithCommas } from "../../common";

function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

function ActivationDetails({
    handleChangeStep,
    appPrice,
    handleFirstStep,
    handleChangePackage,
    handleChangePackageClose,
    isChangePackage,
    handleChangeBillingTerm,
    handlePackage,
    isChangeBillingTerm,
    appPackageList,
    handleChangeBillingTermClose,
    handleBillingTerm,
    billingTermsList,
    clickPackage,
    selectedPackage,
    clickBillingTerm,
    selectedBillingTerm,
    packageDescription,
    billingTermDescription
}) {
    return (
        <div>
            <div className="vemas_logo">
                <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
            </div>
            <div style={{ margin: '0 5% 2% 5%' }}>
                {isChangePackage ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {appPackageList && appPackageList.map(i => (
                                <div onClick={() => clickPackage(i.id)} style={{ cursor: 'pointer', textAlign: 'center', border: '1px solid #ffffff', borderRadius: '10px', padding: '10px', width: '200px', backgroundColor: selectedPackage === i.id ? '#c6c2c2' : '' }}>
                                    <h4>{i.description}</h4>
                                    <div>$ {numberWithCommas(parseFloat(i.price).toFixed(2))}</div>
                                    <div>Per Month</div>
                                    <h6 style={{ padding: '10px 5px', margin: '10px 2px', border: '1px solid #6b6a6a', borderRadius: '10px', backgroundColor: selectedPackage === i.id ? '#04942f' : '', color: selectedPackage === i.id ? '#ffffff' : '' }}>SELECT</h6>
                                </div>))}
                        </div>
                        <div className="filter-btns" style={{ margin: '20px 0' }}>
                            <ButtonGroup
                                primaryBtnClick={() => handleChangePackageClose()}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Reset'
                                secondaryBtnClick={() => handlePackage()}
                                secondaryBtnText='Change'
                                secondaryBtnVariant="outline-primary"
                            />
                        </div>
                    </div> :
                    isChangeBillingTerm ?
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {billingTermsList && billingTermsList.list && billingTermsList.list.map(i => (
                                    <div onClick={() => clickBillingTerm(i.id)} style={{ cursor: 'pointer', textAlign: 'center', border: '1px solid #ffffff', borderRadius: '10px', padding: '10px', width: '200px', backgroundColor: selectedBillingTerm === i.id ? '#c6c2c2' : '' }}>
                                        <h4>{i.description}</h4>
                                        <div>Discount -  {numberWithCommas(parseFloat(i.discount).toFixed(2))} %</div>
                                        <h6 style={{ padding: '10px 5px', margin: '10px 2px', border: '1px solid #6b6a6a', borderRadius: '10px', backgroundColor: selectedBillingTerm === i.id ? '#04942f' : '', color: selectedBillingTerm === i.id ? '#ffffff' : '' }}>SELECT</h6>
                                    </div>))}
                            </div>
                            <div className="filter-btns" style={{ margin: '20px 0' }}>
                                <ButtonGroup
                                    primaryBtnClick={() => handleChangeBillingTermClose()}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Reset'
                                    secondaryBtnClick={() => handleBillingTerm()}
                                    secondaryBtnText='Change'
                                    secondaryBtnVariant="outline-primary"
                                />
                            </div>
                        </div> :
                        <div>
                            <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '10px', fontSize: "18px", color: "orange", margin: '2%' }}>
                                Your monthly subscription fee for the {packageDescription} is ${numberWithCommas(parseFloat(appPrice).toFixed(2))} per month, and your current payment term is {billingTermDescription}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px', marginBottom: '25px', }}>
                                <ButtonGroup
                                    primaryBtnClick={() => handleChangePackage()}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Change package'
                                    secondaryBtnClick={() => handleChangeBillingTerm()}
                                    secondaryBtnText='Change billing term'
                                    secondaryBtnVariant="outline-primary"
                                />
                            </div>
                            <div>
                                We trust VEMAS will help you run your workshop simple and more efficiently. By clicking Agree, you agree to our Terms and Conditions and have read and acknowledge our Privacy Statement.(<Link onClick={() => { openInNewTab('https://vemas.com.au/privacy/') }}>Privacy policy</Link> and <Link>Terms of use</Link>)
                            </div>
                            <div className="filter-btns" style={{ margin: '20px 0' }}>
                                <ButtonGroup
                                    primaryBtnClick={() => handleFirstStep("firstStep")}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Back'
                                    secondaryBtnClick={() => handleChangeStep("thirdStep")}
                                    secondaryBtnText='Agree'
                                    secondaryBtnVariant="outline-primary"
                                />
                            </div>
                        </div>}
            </div>
        </div>
    )
}

export default ActivationDetails;