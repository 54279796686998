import { fromJS } from 'immutable';
import {
  LOAD_EMAIL_TEMPLATE_LIST_ERROR,
  LOAD_EMAIL_TEMPLATE_LIST_LOADING,
  LOAD_EMAIL_TEMPLATE_LIST_SUCCESS,
  LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS,
  SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS,
  SEND_EMAIL_TEMPLATE_SUCCESS,
} from '../constant/emailTempConstant';

export const initialState = fromJS({
    isAddNewEmailTemplateOpen:false,
    emailTemplateList:[],
    isEmailTemplateListLoading: false,
    emailTemplateListError: null,
    emailTemplateSuccess: null,
    selectedEmailTemplate:{}
});

function emailTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS:
      return state.set('isAddNewEmailTemplateOpen', action.open)
    case LOAD_EMAIL_TEMPLATE_LIST_SUCCESS:
      return state.set('emailTemplateList', action.payload)
    case LOAD_EMAIL_TEMPLATE_LIST_LOADING:
      return state.set('isEmailTemplateListLoading', action.payload)
    case LOAD_EMAIL_TEMPLATE_LIST_ERROR:
      return state.set('emailTemplateListError', action.payload)
    case SEND_EMAIL_TEMPLATE_SUCCESS:
      return state.set('emailTemplateSuccess', action.payload)
    case LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS:
      return state.set('selectedEmailTemplate', action.payload)
    default:
      return state;
  }
}

export default emailTemplateReducer;
