import { createSelector } from 'reselect';

const selectSMSTemplateDomain = state => state.smsTemplate;

const makeSelectSMSTemplatePopupOpen =() =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('isAddNewSMSTemplateOpen') : false
  );


const makeSelectSMSTemplateList = () =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('smsTemplateList') : []
  );

const makeSelectSMSTemplateListLoading = () =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('isSMSTemplateListLoading') : false
  );

const makeSelectSMSTemplateListError = () =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('smsTemplateListError') : null
  );

const makeSelectSMSTemplateSuccess = () =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('smsTemplateSuccess') : null
  );

const makeSelectSelectedSMSTemplateSuccess = () =>
  createSelector(
    selectSMSTemplateDomain,
    smsTemplateState => 
      smsTemplateState ? smsTemplateState.get('selectedSMSTemplate') : {}
  );

export {
  makeSelectSMSTemplatePopupOpen,
  makeSelectSMSTemplateList,
  makeSelectSMSTemplateListLoading,
  makeSelectSMSTemplateListError,
  makeSelectSMSTemplateSuccess,
  makeSelectSelectedSMSTemplateSuccess
}
