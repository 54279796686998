import { fromJS } from 'immutable';
import {
  LOAD_REMINDER_LIST_ERROR,
  LOAD_REMINDER_LIST_LOADING,
  LOAD_REMINDER_LIST_SUCCESS,
  SAVE_REMINDER_DETAILS_SUCCESS,
  SAVE_IS_REMINDER_MODAL_OPEN_STATUS, 
  SAVE_IS_NEW_REMINDER_USING_VEHICLE, 
  SAVE_NEW_REMINDER_VEHICLE_DETAILS, 
  SEND_REMINDER_SUCCESS,
  LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS,
} from '../constant/reminderConstant';

export const initialState = fromJS({
    isAddNewReminderModalOpen:false,
    reminderList:[],
    isReminderListLoading:false,
    reminderListError:'',
    reminderSuccess:'',
    selectedReminder:'',
    reminderVehicleDetails:'',
    isReminderUsingVehicle:false
});

function reminderReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_REMINDER_MODAL_OPEN_STATUS:
      return state.set('isAddNewReminderModalOpen', action.open)
    case SEND_REMINDER_SUCCESS:
      return state.set('reminderSuccess', action.payload)
    case LOAD_REMINDER_LIST_SUCCESS:
      return state.set('reminderList', action.payload)
    case LOAD_REMINDER_LIST_LOADING:
      return state.set('isReminderListLoading', action.payload)
    case LOAD_REMINDER_LIST_ERROR:
      return state.set('reminderListError', action.payload)
    case SAVE_REMINDER_DETAILS_SUCCESS:
      return state.set('selectedReminder', action.payload)
    case SAVE_IS_NEW_REMINDER_USING_VEHICLE:
      return state.set('isReminderUsingVehicle', action.payload)
    case SAVE_NEW_REMINDER_VEHICLE_DETAILS:
      return state.set('reminderVehicleDetails', action.payload)
    case LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS:
      return state.set('unConfirmedReminder', action.payload)
      default:
      return state;
  }
}

export default reminderReducer;
