import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getAppServiceTenant(id) {
  return axios.get(baseURL + 'appServiceTenant/' + id, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updateBillingRequest(data) {
  const parameters = JSON.stringify(data)
  return axios.patch(baseURL + 'appServiceTenant', parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}


export function getPaymentSetupRequest() {
  return axios.get(baseURL + 'appPayment/method', {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updatePaymentMethodRequest(data) {
  const parameters = JSON.stringify(data)
  return axios.patch(baseURL + 'appPayment/method/' + data.id, parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function getAppInvoiceList(parameters) {
  return axios.get(baseURL + 'appInvoices?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&InvoiceNo=' + parameters.invoiceNo + '&InvoiceDate=' + parameters.invoiceDate + '&DueDate=' + parameters.dueDate + '&statusId=' + parameters.status + '&commonSearch=' + parameters.commonSearch, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function getSelectedAppInvoiceRequest(id) {
  return axios.get(baseURL + 'appInvoices/' + id, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function printAppInvoiceRequest(id) {
  return axios.patch(baseURL + 'appInvoices/print/' + id, null, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function getAppPaymentMethodRequest() {
  return axios.get(baseURL + 'appPaymentMethod', {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updateDefaultPaymentMethodRequest(id) {
  return axios.patch(baseURL + 'appPayment/defaultMethod/' + id, null, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updatePaymentMethodDetailsRequest(data) {
  const parameters = JSON.stringify(data)
  return axios.patch(baseURL + 'appPayment/methodDetails/' + data.id, parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}


export function updateTenantPackage(data) {
  data.appId = "01"
  const parameters = JSON.stringify(data)
  return axios.patch(baseURL + 'tenants/package', parameters, {
      headers: {
          'Content-Type': 'application/json',
          'authorization': sessionStorage.getItem("accessToken")
      }
  })
}