import {
  SAVE_IS_USERS_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_USER_REQUEST,
  SEND_ADD_NEW_USER_LOADING,
  SEND_ADD_NEW_USER_SUCCESS,
  SEND_ADD_NEW_USER_ERROR,
  LOAD_USER_LIST_REQUEST,
  LOAD_USER_LIST_SUCCESS,
  SEND_USER_STATUS_UPDATE_REQUEST,
  SEND_USER_UPDATE_REQUEST,
  LOAD_SELECTED_USER_REQUEST,
  LOAD_SELECTED_USER_SUCCESS,
  LOAD_USER_ROLE_LIST_REQUEST,
  LOAD_USER_ROLE_LIST_SUCCESS,
  LOAD_USER_ACCESS_REQUEST,
  LOAD_USER_ACCESS_SUCCESS
} from '../constant/usersConstant';

export function saveIsUsersModalOpenStatus(open) {
  return {
    type: SAVE_IS_USERS_MODAL_OPEN_STATUS,
    open,
  }
};

export function sendAddNewUserRequest(data) {
  return {
    type: SEND_ADD_NEW_USER_REQUEST,
    payload: data,
  }
}

export function sendAddNewUserLoading(data) {
  return {
    type: SEND_ADD_NEW_USER_LOADING,
    payload: data,
  }
}

export function sendAddNewUserSuccess(data) {
  return {
    type: SEND_ADD_NEW_USER_SUCCESS,
    payload: data,
  }
}

export function sendAddNewUserError(data) {
  return {
    type: SEND_ADD_NEW_USER_ERROR,
    payload: data,
  }
}

export function loadUserListRequest(data) {
  return {
    type: LOAD_USER_LIST_REQUEST,
    payload: data,
  }
}

export function loadUserListSuccess(data) {
  return {
    type: LOAD_USER_LIST_SUCCESS,
    payload: data,
  }
}

export function sendUserStatusUpdateRequest(data) {
  return {
    type: SEND_USER_STATUS_UPDATE_REQUEST,
    payload: data,
  }
}

export function sendUserUpdateRequest(data) {
  return {
    type: SEND_USER_UPDATE_REQUEST,
    payload: data,
  }
}

export function loadSelectedUserRequest(data) {
  return {
    type: LOAD_SELECTED_USER_REQUEST,
    payload: data,
  }
}

export function loadSelectedUserSuccess(data) {
  return {
    type: LOAD_SELECTED_USER_SUCCESS,
    payload: data,
  }
}

export function loadUserRoleListRequest() {
  return {
    type: LOAD_USER_ROLE_LIST_REQUEST
  }
}

export function loadUserRoleListSuccess(data) {
  return {
    type: LOAD_USER_ROLE_LIST_SUCCESS,
    payload: data,
  }
}

export function loadUserAccessRequest() {
  return {
    type: LOAD_USER_ACCESS_REQUEST
  }
}

export function loadUserAccessSuccess(data) {
  return {
    type: LOAD_USER_ACCESS_SUCCESS,
    payload: data,
  }
}
