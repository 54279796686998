import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import File from 'react-bootstrap/cjs/FormFile';
import Label from 'react-bootstrap/cjs/FormLabel';
import Modal from '../../../layout/form/Modal';
import '../settingMenu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import User from '../../../../images/user.png';
import ButtonGroup from '../../../layout/form/ButtonGroup'
import common, { resizeFile } from '../../../common';
import SelectField from '../../../layout/form/SelectField';

const convertBase64 = (file) =>{
    return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () =>{
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) =>{
            reject(error)
        }
    })
}

export class AddNewUser extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            status: '',
            userId:'',
            firstName:'',
            phoneNumber:'',
            email:'',
            lastName:'',
            userRole:'',
            notes:'',
            logo:'',
            initial: true,
            fileList: [],
            isChangeProfileImage: false
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedUser, isUpdate, selectedRow, objectStatusList } = prevProps;
        if(selectedUser && selectedUser.id && prevState.initial && isUpdate && selectedUser.id === selectedRow.id){
            return{
                status:selectedUser.status ,
                userId:selectedUser.userId,
                firstName:selectedUser.firstName,
                phoneNumber:selectedUser.mobileNo,
                email:selectedUser.email,
                lastName:selectedUser.lastName,
                userRole:selectedUser.userRole,
                notes:selectedUser.notes,
                logo:selectedUser.logo,
                initial:false
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            return {
                status:defaultStatus[0].id,
                initial:false,
            }
        }
        return null
    }
           
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
          [id]: value
        })
    }

    handleChangeLogo = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        var files = [];
        const image = await resizeFile(file);
        files.push(image)
        this.setState({ logo: base64,
            fileList: files, isChangeProfileImage: true })
    }
    
  
    render() {
        const {
            isAddNewUsersModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            objectStatusList,
            selectedUser,
            isUpdate,
            selectedRow
        } = this.props;

        return (
            <div>
                {((isUpdate && isUpdate && selectedUser.id === selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewUsersModalOpen} 
                    onHide={handleModalClose}
                    title="Users Details"
                    body={
                        <div>
                            <form>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="User Id"
                                            controlId="userId"
                                            placeholder="User Id"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.userId}
                                            required
                                            maxLength={common.maxLengths.userId}
                                            disabled={isUpdate}
                                        />
                                        <TextField
                                            text="First Name"
                                            controlId="firstName"
                                            placeholder="First Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.firstName}
                                            required
                                            maxLength={common.maxLengths.userFirstName}
                                        />
                                        <TextField
                                            text="Phone Number"
                                            controlId="phoneNumber"
                                            placeholder="Phone Number"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.phoneNumber}
                                            maxLength={common.maxLengths.userMobile}
                                            required
                                        />
                                        <Label>Avatar</Label>
                                        <div className="userPhoto">
                                            <Image className="imageView" src={this.state.logo ? this.state.logo : User} />
                                            <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={this.handleChangeLogo}/>
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <Button 
                                                    variant="outline-primary" 
                                                    text ='Change'
                                                    size='sm'
                                                    onClick={() => this.photoUpload.click()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Email Address"
                                            controlId="email"
                                            placeholder="Email Address"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                            required
                                            disabled={isUpdate}
                                            maxLength={common.maxLengths.userEmail}
                                        />
                                        <TextField
                                            text="Last Name"
                                            controlId="lastName"
                                            placeholder="Last Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.lastName}
                                            required
                                            maxLength={common.maxLengths.userLastName}
                                        />
                                        {/* <TextField
                                            text="User Role"
                                            controlId="userRole"
                                            as='select'
                                            onChange={this.handleChange}
                                            options= {common.userRole}
                                        /> */}
                                        <SelectField
                                            text="User Role"
                                            controlId="userRole"
                                            onChange={(e)=>this.handleChangeSelect(e,'userRole')}
                                            options= {common.userRole}
                                            selectedValue={this.state.userRole}
                                            isClearable
                                            required
                                        />
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={(e)=>this.handleChangeSelect(e,'status')}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            isClearable
                                            required
                                            disabled={!isUpdate}
                                        />
                                        <TextField
                                            text="Notes"
                                            controlId="notes"
                                            placeholder="Notes"
                                            type="text"
                                            as="textarea" 
                                            rows={5}
                                            onChange={this.handleChange}
                                            defaultValue={this.state.notes}
                                        />
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />}
        </div>
        )
    }
}

AddNewUser.propTypes = {
    isAddNewUsersModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.any,
    objectStatusList: PropTypes.any,
    selectedUser: PropTypes.any,
    isUpdate: PropTypes.any
}

export default AddNewUser;