import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import { loadAlternativeProductListRequest, loadAlternativeProductListSuccess } from '../../../../store/action/productAction';
import Table from '../../../layout/form/Table';
import SelectField from '../../../layout/form/SelectField';

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

export class Alternatives extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            sku: '',
            initial: true,
            alternativeList: [],
            productList: [],
            tempProductList: [],
            isDelete: false, 
            isChange: false
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {

        const { isUpdate, selectedProduct, alternativeProductList, allProducts } = prevProps;
        if (selectedProduct && selectedProduct.id && prevState.initial && isUpdate && alternativeProductList && alternativeProductList.tenantId > 0 && allProducts && allProducts.length > 0) {
            var list = []
            var products = []
            if (alternativeProductList.alternatives && alternativeProductList.alternatives.length > 0) {
                for (let i = 0; i < alternativeProductList.alternatives.length; i++) {
                    const element = alternativeProductList.alternatives[i]
                    const product = allProducts.find(e => e.id === element.alternativeId)
                    element.price = product.unitPrice
                    element.quantity = product.defaultIssueQuantity
                    element.operation = "U"
                    element.id = element.alternativeId
                    list.push(element)
                }
            }
            products = allProducts.filter(e => e.id !== selectedProduct.id)
            return {
                id: selectedProduct.id,
                sku: selectedProduct.sku,
                description: selectedProduct.description,
                alternativeList: list,
                productList: products,
                initial: false
            }
        }
        return null
    }

    addNewRow = () => {
        var { alternativeList } = this.state;
        var newRow = { operation: "C", id: 0 }
        alternativeList.push(newRow)
        this.setState({ alternativeList, isChange: true })
    }

    handleRowData = (id, rowData) => {
        const { alternativeList, productList } = this.state;
        const selectedRow = productList.find(row => row.id === id)
        const newRow = rowData.original
        newRow.operation = "U"
        newRow.alternativeId = id
        newRow.price = selectedRow.unitPrice
        newRow.quantity = selectedRow.defaultIssueQuantity
        alternativeList[rowData.index] = newRow
        this.setState({ alternativeList, isChange: true })
    }

    handleRowNote = (e, rowData) => {
        const { alternativeList } = this.state;
        const selectedRow = alternativeList[rowData.index]
        const newRow = selectedRow
        newRow.note = e.target.value
        setTimeout(
            alternativeList[rowData.index] = newRow,
            () => this.setState({ alternativeList, isChange: true }),
            10
        );
    }

    handleDelete = (rowData) => {
        var { alternativeList } = this.state;
        const newRow = rowData.original
        newRow.operation = "D"
        alternativeList[rowData.index] = newRow
        this.setState({ alternativeList, isDelete: true, isChange: true })
    }

    componentDidMount() {
        const { selectedProduct } = this.props;
        this.props.dispatch(loadAlternativeProductListSuccess({}))
        this.props.dispatch(loadAlternativeProductListRequest(selectedProduct.id))
    }

    componentDidUpdate(prevProps, prevState) {
        const { alternativeList, isDelete, tempProductList, } = this.state;
        if (isDelete) {
            const list = alternativeList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = alternativeList.filter(data => (data.id !== 0))
            this.setState({ alternativeList: list, isDelete: false, tempProductList: tempProductList.concat(tempItemList) })
        }
    }


    render() {
        const {
            handleModalClose,
            handleSaveAddNew,
            redirectTab,
        } = this.props;

        const columns = [{
            Header: 'Item No',
            // accessor: 'no',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="index"
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            // width:1000,
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="description"
                        onChange={(e) => this.handleRowData(e, rowData.cell.row)}
                        options={this.state.productList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.alternativeId}
                    />

                </div>
            ),
        }, {
            Header: 'Unit Price',
            // accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="price"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.price).toFixed(2)}
                        size="sm"
                        style={{ textAlign: 'right' }}
                        disabled
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Quantity',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="quantity"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.quantity).toFixed(2)}
                        size="sm"
                        style={{ textAlign: 'right' }}
                        disabled
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Note',
            accessor: 'note',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="Note"
                        type="text"
                        defaultValue={rowData.cell.row.original.note}
                        size="sm"
                        onBlur={(e) => this.handleRowNote(e, rowData.cell.row)}
                    />
                </div>
            ),
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={() => this.handleDelete(rowData.cell.row)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                <div className="filter-header">
                    <h5>
                        {this.state.sku !== '' ? "Product Information - " + this.state.description + " / " + this.state.sku : "Product Information"}
                    </h5>
                    <div className="btn-close" onClick={handleModalClose} >
                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                </div>
                <div className="content-header" style={{ borderBottom: '1px solid #dbd8d8' }}>
                    <div className="header-pages">
                        <span onClick={() => redirectTab('productInformation', this.state)}><ui5-icon class="samples-margin" name="action-settings" id="tab-icon"></ui5-icon> Product Details</span>
                        <span onClick={() => redirectTab('alternatives', this.state)} ><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Alternatives</span>
                    </div>
                </div>
                <div>
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={this.state.alternativeList}
                        />
                        <div className="add-icon" >
                            <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={() => this.addNewRow()}></ui5-icon>
                        </div>

                    </div>

                    <div className="filter-btns">
                        <ButtonGroup
                            primaryBtnClick={handleModalClose}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Go Back'
                            primaryBtnIcon='undo'
                            secondaryBtnClick={() => handleSaveAddNew(this.state)}
                            secondaryBtnText='Save'
                            secondaryBtnVariant="outline-primary"
                            secondaryBtnIcon='save'
                        />
                    </div>
                </div>

            </div>
        )
    }
}

Alternatives.propTypes = {
    isAddNewProductModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    saveDetails: PropTypes.any,
    productCategoryList: PropTypes.any,
    objectStatusList: PropTypes.any,
    uomList: PropTypes.any,
    selectedRow: PropTypes.any
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapDispatchToProps,
);

export default (compose(withConnect)(Alternatives));