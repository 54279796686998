import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/emailTempSelector';
import * as actions from '../../../../store/action/emailTempAction';
import PropTypes from 'prop-types';
import '../settingMenu.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddEmailTemplate from './AddNew';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';

class EmailTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            name:'',
            isValidationError:false,
            isDeleteModalOpen:false,
            selectedRow:{},
            errorMassage:'',
            pageSize: 20,
            pageNumber: 1
        }
    }

    handleModal = (data) => {
        if(data && data.id){
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsEmailTemplateModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsEmailTemplateModalOpenStatus(false));
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadEmailTemplateListError(null))
    }

    successModalClose = () => {
        this.getEmailTemplateListData()
        this.props.dispatch(actions.sendEmailTemplateSuccess(null))
        this.handleModalClose()
        this.props.dispatch(actions.loadEmailTemplateListLoading(false))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            name: this.state.name
        })
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleFilter = () =>{
        this.getEmailTemplateListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen:false,
            name:''
        })

        const parameters= {
            pageSize: 20,
            pageNumber: 1,
            name:'',
          }
          this.props.dispatch(actions.loadEmailTemplateListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        const { userAccess } = this.props
        if(this.state.isUpdate && userAccess && userAccess.modifyEmailTemplate){
          this.updateEmailTemplate(details)
        }else if(userAccess && userAccess.createEmailTemplate){
          this.addNewEmailTemplate(details)
        }
    }

    updateEmailTemplate = (details) =>{
        if( details.name === '' ){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
        }
        const parameters = {
            body: encodeURIComponent(details.email),
            name: details.emailSubject,
            id:this.state.selectedRow.id
        }
        this.props.dispatch(actions.sendUpdateEmailTemplateRequest(parameters))
      }

    addNewEmailTemplate = (details) => {
        if(details.emailSubject === ''){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
        }
        const parameters = {
            body: encodeURIComponent(details.email),
            name: details.emailSubject
        }
        this.props.dispatch(actions.sendAddNewEmailTemplateRequest(parameters))
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteEmailTemplate = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteEmailTemplateRequest(this.state.selectedRow.id))
    }

    setEmailBody = (details) => {
        var string = decodeURIComponent(details.body)
        if(string.length > 50 ){
            return(
                <div>
                    {string.substring(0, 45)+" ....."}
                </div>
            )
        }
        else 
            return(
                <div>
                    {string}
                </div>
            )
    }

    getEmailTemplateListData = () => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 :this.state.pageNumber,
            name: this.state.name,
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.props.dispatch(actions.loadEmailTemplateListRequest(parameters))
    }

    componentDidMount (){
        this.getEmailTemplateListData()
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    componentDidUpdate (prevProps, prevState){
        // if(this.state.isValidationError || this.props.emailTemplateListError !== ''){
        //     setTimeout(
        //         () => this.errorModalClose(), 
        //         500
        //       );
        // }else 
        if (this.props.emailTemplateSuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                500
            );
        }
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getEmailTemplateListData()
        }
    }

    render() {
        
        const {
            isAddNewEmailTemplateOpen,
            emailTemplateList,
            isEmailTemplateListLoading,
            emailTemplateListError,
            emailTemplateSuccess,
            userAccess
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow
         } = this.state;

        const columns = [{
            Header: 'Email subject',
            accessor: 'name',
            // width: "20%",
        //   },{
        //     Header: 'Email',
        //     accessor: 'body',
        //     Cell:((row) => (
        //         <div>
        //             {this.setEmailBody(row.row.original)}
        //         </div>
        //     ))
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>  
                      <span onClick={userAccess && userAccess.deleteEmailTemplate ? ()=>this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>  
                      {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(isEmailTemplateListLoading || 
                        (emailTemplateList && emailTemplateList.length && emailTemplateList.length === 0)) ? 
                        <Loader show={isEmailTemplateListLoading}/> :''}

                {(isValidationError || emailTemplateListError !== null ) && 
                        <ErrorModal
                            show={true} 
                            massage={errorMassage ? errorMassage : emailTemplateListError}
                            handleClose={this.errorModalClose}
                        />}

                {(emailTemplateSuccess !== null) && 
                        <SuccessModal
                            show={true} 
                            massage={emailTemplateSuccess}
                            handleClose={this.successModalClose}
                        />}
                {isDeleteModalOpen && 
                    <DeleteModal 
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteEmailTemplate}								
                        title={selectedRow.name}
                    />}
                <div className="page-header">
                    <div className="btn-group">
                        <ButtonGroup
                            primaryBtnClick={this.handleModal}
                            secondaryBtnClick={this.handleFilterForm}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Add New'
                            primaryBtnIcon='sys-add'
                            primaryBtnDisabled={userAccess && !userAccess.createEmailTemplate}
                            secondaryBtnText='Filter'
                            secondaryBtnVariant="outline-primary" 
                            secondaryBtnIcon='filter'
                        />
                    </div>
                </div>
                <div className="body-content">
                    <h5>Email Template List</h5>
                    {isFilterFormOpen && 
                            <div className="filter-form">
                                <div className="filter-header">
                                    <h5>Filter Form</h5>
                                    <div className="btn-close" onClick={this.handleFilterFormClose} >
                                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField
                                        text="Name"
                                        controlId="name"
                                        placeholder="Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                </div>
                                {/* <div className="col-sm">
                                    <TextField
                                        text="Make"
                                        controlId="make"
                                        placeholder="Make"
                                        type="text"
                                        as="select"
                                        // onChange={this.handleChange}
                                    />
                                </div> */}
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                        </div>}
                        <div className="table-content">
                            <Table
                                columns={columns}
                                data={emailTemplateList.result && emailTemplateList.result.length ? emailTemplateList.result : [] }                   
                            />
                        </div>
                </div>
                <Pagination
                    currentPage={emailTemplateList.page ? emailTemplateList.page : this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {emailTemplateList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewEmailTemplateOpen && 
                    <AddEmailTemplate
                        isAddNewEmailTemplateOpen={isAddNewEmailTemplateOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedRow={selectedRow}
                        userAccess={userAccess}
                    />
                }
            </div>
        )
    }
}

EmailTemplate.propTypes = {
    isAddNewEmailTemplateOpen: PropTypes.bool,
    emailTemplateList: PropTypes.any,
    isEmailTemplateListLoading: PropTypes.bool,
    emailTemplateListError: PropTypes.any,
    emailTemplateSuccess: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    isAddNewEmailTemplateOpen: selectors.makeSelectEmailTemplatePopupOpen(),
    emailTemplateList: selectors.makeSelectEmailTemplateList(),
    isEmailTemplateListLoading: selectors.makeSelectEmailTemplateListLoading(),
    emailTemplateListError: selectors.makeSelectEmailTemplateListError(),
    emailTemplateSuccess: selectors.makeSelectEmailTemplateSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(EmailTemplate));