import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// import BarChart from '../layout/form/BarChart';
// import LineChart from '../../layout/form/LineChart';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../../store/action/analyticsAction';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/analyticsSelector';
import common from '../../../common';
// import TextField from '../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
import moment from 'moment';
import Loader from '../../../layout/form/Loader';
import DatePicker from '../../../layout/form/datePicker';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Table from '../../../layout/form/Table';
import { loadCustomerListRequest } from '../../../../store/action/customerAction';
import { makeSelectCustomerList } from '../../../../store/selector/customerSelector';
import GraphicalRating from './GraphicalRating';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const dateFormat = sessionStorage.getItem("dateFormat")
const today = new Date()
var previousYear = new Date()
previousYear.setFullYear(previousYear.getFullYear() - 1);
previousYear.setMonth(previousYear.getMonth() + 1)
previousYear.setDate(1)

function setYearDataList(list, toDate, fromDate) {
  const d1 = new Date(fromDate);
  const d2 = new Date(toDate);
  const yearCount = (d2.getFullYear() - d1.getFullYear())
  const labels = []
  const star1 = []
  const star2 = []
  const star3 = []
  const star4 = []
  const star5 = []
  const date = new Date(toDate)
  for (let i = 0; i <= yearCount; i++) {
    const year = date.getFullYear()
    labels.push(year)
    const element = list.find(e => e.year === year)
    if (element) {
      star1.push(element.oneStar)
      star2.push(element.twoStar)
      star3.push(element.threeStar)
      star4.push(element.fourStar)
      star5.push(element.fiveStar)
    } else {
      star1.push(0)
      star2.push(0)
      star3.push(0)
      star4.push(0)
      star5.push(0)
    }
    date.setFullYear(date.getFullYear() - 1);
  }
  star1.reverse()
  star2.reverse()
  star3.reverse()
  star4.reverse()
  star5.reverse()
  labels.reverse()

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: '5 Star',
          data: star5,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_4,
          borderColor: common.lineChartDetails.chartColor_4,
        }, {
          label: '4 Star',
          data: star4,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_2,
          borderColor: common.lineChartDetails.chartColor_2,
        }, {
          label: '3 Star',
          data: star3,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_3,
          borderColor: common.lineChartDetails.chartColor_3,
        }, {
          label: '2 Star',
          data: star2,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_1,
          borderColor: common.lineChartDetails.chartColor_1,
        }, {
          label: '1 Star',
          data: star1,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_5,
          borderColor: common.lineChartDetails.chartColor_5,
        },
      ]
    }
  }
}

function setMonthDataList(list, toDate, fromDate) {
  const d1 = new Date(fromDate);
  const d2 = new Date(toDate);
  var months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  const labels = []
  const star1 = []
  const star2 = []
  const star3 = []
  const star4 = []
  const star5 = []
  const date = new Date(toDate)
  date.setMonth(date.getMonth() - 1)
  for (let i = 0; i <= months; i++) {
    const month = moment(date).format('MMMM-YYYY');
    const element = list.find(e => moment(e.month).format('MMMM-YYYY') === month)
    labels.push(month)
    if (element) {
      star1.push(element.oneStar)
      star2.push(element.twoStar)
      star3.push(element.threeStar)
      star4.push(element.fourStar)
      star5.push(element.fiveStar)
    } else {
      star1.push(0)
      star2.push(0)
      star3.push(0)
      star4.push(0)
      star5.push(0)
    }
    date.setMonth(date.getMonth() - 1);
  }
  star1.reverse()
  star2.reverse()
  star3.reverse()
  star4.reverse()
  star5.reverse()
  labels.reverse()

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: '5 Star',
          data: star5,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_4,
          borderColor: common.lineChartDetails.chartColor_4,
        }, {
          label: '4 Star',
          data: star4,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_2,
          borderColor: common.lineChartDetails.chartColor_2,
        }, {
          label: '3 Star',
          data: star3,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_3,
          borderColor: common.lineChartDetails.chartColor_3,
        }, {
          label: '2 Star',
          data: star2,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_1,
          borderColor: common.lineChartDetails.chartColor_1,
        }, {
          label: '1 Star',
          data: star1,
          fill: false,
          backgroundColor: common.lineChartDetails.chartColor_5,
          borderColor: common.lineChartDetails.chartColor_5,
        },
      ]
    }
  }
}

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
      toDate: moment(today).format(common.dateFormat),
      fromDate: moment(previousYear).format(common.dateFormat),
      groupBy: 1,
      rating: 0,
      customer: '',
      isChangeParms: false,
      isGraphicalView: false,
      isShowGraphicalView: false,
      isOpenGraphs: false,
      chartData: {
        labels: common.lineChartDetails.labels,
        datasets: [],
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isChangeParms, toDate, fromDate, groupBy, isGraphicalView } = this.state;
    const { ratingDetails } = this.props;
    if (ratingDetails.length > 0 && isGraphicalView) {
      var data = []
      if (groupBy === 2) {
        data = setYearDataList(ratingDetails, toDate, fromDate)
      } else if (groupBy === 3) {
        data = setMonthDataList(ratingDetails, toDate, fromDate)
      }
      this.setState({
        isGraphicalView: false,
        isShowGraphicalView: true,
        chartData: data
      })
    }
    if (isChangeParms) {
      const d1 = new Date(fromDate);
      const d2 = new Date(toDate);
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      const years = (d2.getFullYear() - d1.getFullYear())
      if (groupBy === 2 && years > 0) {
        this.setState({
          isChangeParms: false,
          isGraphicalView: true
        })
      }
      else if (groupBy === 3 && months > 3) {
        this.setState({
          isChangeParms: false,
          isGraphicalView: true
        })
      }
      return;
    }
  }

  setDateFormat = (value) => {
    const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
    return date
  }

  setMonthFormat = (value) => {
    const date = moment(value).format('MMMM-YYYY');
    return date
  }

  handleDateChange = (date, id) => {
    const { groupBy } = this.state;
    this.props.dispatch(actions.loadRatingSuccess([]))
    var day = date
    if (date === null) {
      this.setState({
        [id]: '',
        isChangeParms: true,
        isShowGraphicalView: false,
      })
      return
    }
    if (id === "fromDate" && groupBy === 2) {
      day = new Date(day.getFullYear(), 0, 1)
      this.setState({
        fromDate: moment(day).format(common.dateFormat),
        isChangeParms: true,
        isShowGraphicalView: false,
      })
    }
    else if (id === "fromDate" && groupBy === 3) {
      day = new Date(day.getFullYear(), day.getMonth(), 1)
      this.setState({
        fromDate: moment(day).format(common.dateFormat),
        isChangeParms: true,
        isShowGraphicalView: false,
      })
    }
    else if (id === "toDate" && groupBy === 2) {
      day = new Date(day.getFullYear(), 11, 31)
      this.setState({
        toDate: moment(day).format(common.dateFormat),
        isChangeParms: true,
        isShowGraphicalView: false,
      })
    }
    else if (id === "toDate" && groupBy === 3) {
      day = new Date(day.getFullYear(), day.getMonth() + 1, 0);
      this.setState({
        toDate: moment(day).format(common.dateFormat),
        isChangeParms: true,
        isShowGraphicalView: false,
      })
    } else {
      this.setState({
        [id]: moment(day).format(common.dateFormat),
        isChangeParms: true,
        isShowGraphicalView: false,
      })
    }
  }

  handleChangeSelect = (value, id) => {
    const { toDate, fromDate } = this.state
    var fromDate_ = new Date(fromDate)
    var toDate_ = new Date(toDate)
    this.props.dispatch(actions.loadRatingSuccess([]))
    if (id === "groupBy" && value === '') {
      this.setState({
        groupBy: 1,
        isChangeParms: true,
        isShowGraphicalView: false,
      })
      return
    } else if (id === "groupBy" && value === 2) {
      fromDate_ = new Date(fromDate_.getFullYear(), 0, 1)
      toDate_ = new Date(toDate_.getFullYear(), 11, 31)
      this.setState({
        groupBy: value,
        isChangeParms: true,
        isShowGraphicalView: false,
        fromDate: moment(fromDate_).format(common.dateFormat),
        toDate: moment(toDate_).format(common.dateFormat),
      })
      return
    } else if (id === "groupBy" && value === 3) {
      fromDate_ = new Date(fromDate_.getFullYear(), fromDate_.getMonth(), 1)
      toDate_ = new Date(toDate_.getFullYear(), toDate_.getMonth() + 1, 0);
      this.setState({
        groupBy: value,
        isChangeParms: true,
        isShowGraphicalView: false,
        fromDate: moment(fromDate_).format(common.dateFormat),
        toDate: moment(toDate_).format(common.dateFormat),
      })
      return
    }

    this.setState({
      [id]: value,
      isChangeParms: true,
      isShowGraphicalView: false,
    })
  }

  handleRunReport = () => {
    const parms = {
      toDate: this.state.toDate,
      fromDate: this.state.fromDate,
      timePeriod: this.state.periodType,
      groupBy: this.state.groupBy,
      rating: this.state.rating,
      customer: this.state.customer,
      pageSize: 9999,
      pageNumber: ''
    }
    this.props.dispatch(actions.loadRatingRequest(parms))
  }

  handleClose = () => {
    this.setState({
      toDate: moment(today).format(common.dateFormat),
      fromDate: moment(previousYear).format(common.dateFormat),
      groupBy: 1,
      rating: 0,
      customer: '',
      isChangeParms: false,
      isGraphicalView: false,
      isShowGraphicalView: false,
      isOpenGraphs: false,
      chartData: {
        labels: common.lineChartDetails.labels,
        datasets: []
      }
    })
  }

  sendTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Graphical view
    </Tooltip>
  );

  openGraphs = () => {
    this.setState({
      isOpenGraphs: true
    })
  }

  openGraphsClose = () => {
    this.setState({
      isOpenGraphs: false
    })
  }

  componentDidMount() {
    const parms = {
      toDate: this.state.toDate,
      fromDate: this.state.fromDate,
      timePeriod: this.state.periodType,
      groupBy: this.state.groupBy,
      rating: this.state.rating,
      customer: this.state.customer,
      pageSize: 9999,
      pageNumber: '',
      description: '',
      name: '',
      email: '',
      mobile: '',
      city: '',
      makeId: '',
      commonSearch: ''
    }
    this.props.dispatch(actions.loadRatingRequest(parms))
    this.props.dispatch(loadCustomerListRequest(parms))
  }

  render() {
    const {
      ratingDetails,
      isRatingLoading,
      customerList
    } = this.props;

    const columnCustomer = [{
      Header: 'Date',
      Cell: ((row) => (
        <div >
          {row.row.original.date === '1/1/0001' ? '' :
            (row.row.original.date === 'Total' ? "Total" : this.setDateFormat(row.row.original.date))}
        </div>
      )),
      width: "15%",
    }, {
      Header: 'Customer Name',
      accessor: 'customerName',
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>One Stars</div>),
      accessor: 'oneStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.oneStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Two Stars</div>),
      accessor: 'twoStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.twoStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Three Stars</div>),
      accessor: 'threeStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.threeStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Four Stars</div>),
      accessor: 'fourStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fourStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Five Stars</div>),
      accessor: 'fiveStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fiveStar}
        </div>
      ))
    }
    ]

    const columnYear = [{
      Header: 'Year',
      Cell: ((row) => (
        <div >
          {row.row.original.year}
        </div>
      )),
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>One Stars</div>),
      accessor: 'oneStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.oneStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Two Stars</div>),
      accessor: 'twoStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.twoStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Three Stars</div>),
      accessor: 'threeStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.threeStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Four Stars</div>),
      accessor: 'fourStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fourStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Five Stars</div>),
      accessor: 'fiveStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fiveStar}
        </div>
      ))
    }
    ]

    const columnMonth = [{
      Header: 'Month',
      Cell: ((row) => (
        <div >
          {this.setMonthFormat(row.row.original.month)}
        </div>
      )),
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>One Stars</div>),
      accessor: 'oneStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.oneStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Two Stars</div>),
      accessor: 'twoStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.twoStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Three Stars</div>),
      accessor: 'threeStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.threeStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Four Stars</div>),
      accessor: 'fourStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fourStar}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Five Stars</div>),
      accessor: 'fiveStar',
      width: "10%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>{row.row.original.fiveStar}
        </div>
      ))
    }
    ]

    return (
      <div>
        {(isRatingLoading ||
          (ratingDetails && ratingDetails.length && ratingDetails.length < 0)) ?
          <Loader show={isRatingLoading} /> : ''}
        {this.state.isOpenGraphs ?
          <div className="body-content">
            <GraphicalRating handleClose={this.openGraphsClose} data={this.state.chartData} />
          </div> :
          <div>
            <div className="content-header" style={{ justifyContent: 'space-between' }}>
              <div className="header-pages">
                <span><Link ><ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Customer Feedback</Link></span>
                <span><Link to='./customerAcquisition'><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Customer Acquisition</Link></span>
              </div>
              {<div style={{ display: 'flex' }}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.sendTooltip}
                >
                  {this.state.isShowGraphicalView ?
                    <div onClick={() => this.openGraphs()} style={{ cursor: 'pointer', marginRight: '10px' }}> <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> </div> :
                    <div className="mb-2 text-muted" style={{ marginRight: '10px' }}> <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> </div>}
                </OverlayTrigger>
              </div>}
            </div>
            <div className="content-body">
              <div className="filter-form">
                <div className="row ">
                  <div className="col-sm">
                    <DatePicker
                      text="From date"
                      controlId="fromDate"
                      onChange={(e) => this.handleDateChange(e, "fromDate")}
                      value={this.state.fromDate === '' ? '' : new Date(this.state.fromDate)}
                      required
                    // format={dateFormat}
                    />
                  </div>
                  <div className="col-sm">
                    <DatePicker
                      text="To date"
                      controlId="toDate"
                      onChange={(e) => this.handleDateChange(e, "toDate")}
                      value={this.state.toDate === '' ? '' : new Date(this.state.toDate)}
                      required
                    // format={dateFormat}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <SelectField
                      text="Group By"
                      controlId="groupBy"
                      onChange={(e) => this.handleChangeSelect(e, 'groupBy')}
                      options={common.groupBySurvey}
                      selectedValue={this.state.groupBy}
                      isClearable
                      required
                    />
                  </div>
                  <div className="col-sm">
                    <SelectField
                      text="Customer"
                      controlId="customer"
                      onChange={(e) => this.handleChangeSelect(e, 'customer')}
                      options={
                        customerList && customerList.result && customerList.result.length && customerList.result.length > 0 ?
                          customerList.result.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                            1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                      selectedValue={this.state.customer}
                      display='displayName'
                      isClearable
                    />
                  </div>
                  <div className="col-sm">
                    <SelectField
                      text="Rating"
                      controlId="rating"
                      onChange={(e) => this.handleChangeSelect(e, 'rating')}
                      options={common.rating}
                      selectedValue={this.state.rating}
                      isClearable
                      required
                    />
                  </div>
                </div>
                <div className="filter-btns">
                  <ButtonGroup
                    primaryBtnClick={() => this.handleRunReport()}
                    primaryBtnIcon='accept'
                    primaryBtnText='Run Report'
                    primaryBtnVariant="outline-primary"
                    secondaryBtnClick={() => this.handleClose()}
                    secondaryBtnIcon='decline'
                    secondaryBtnText='Cancel'
                    secondaryBtnVariant="outline-primary"
                  />
                </div>

              </div>
            </div>
            <div className="table-content">
              <Table
                columns={this.state.groupBy === 1 ? columnCustomer :
                  (this.state.groupBy === 2 ? columnYear : columnMonth)}
                data={ratingDetails ? ratingDetails : []}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  ratingDetails: selectors.makeSelectRatingDetails(),
  isRatingLoading: selectors.makeSelectRatingLoading(),
  customerList: makeSelectCustomerList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

// export default Analytics
export default (compose(withConnect)(Analytics));
