import { 
  LOAD_ACCOUNT_ERROR,
  LOAD_ACCOUNT_LOADING,
  LOAD_ACCOUNT_SYSTEM_REQUEST,
  LOAD_ACCOUNT_SYSTEM_SUCCESS,
  LOAD_QUICKBOOKS_CONNECTION_REQUEST,
  LOAD_QUICKBOOKS_CONNECTION_SUCCESS,
  SEND_CONNECTION_REQUEST,
  SEND_CONNECTION_SUCCESS,
  SEND_CONNECTION_UPDATE_REQUEST,
  SEND_CONNECTION_UPDATE_SUCCESS,
  SEND_DELETE_CONNECTION_REQUEST,
  SEND_DELETE_CONNECTION_SUCCESS,
  } from '../constant/accountConstant';


  export function loadAccountSystemRequest(data) {
    return {
      type: LOAD_ACCOUNT_SYSTEM_REQUEST,
      payload: data,
    }
  };
  
  export function loadAccountLoading(data) {
    return {
      type: LOAD_ACCOUNT_LOADING,
      payload: data,
    }
  };
    
  export function loadAccountSystemSuccess(data) {
    return {
      type: LOAD_ACCOUNT_SYSTEM_SUCCESS,
      payload: data,
    }
  };
  
  export function loadAccountError(data) {
    return {
      type: LOAD_ACCOUNT_ERROR,
      payload: data,
    }
  };

  export function loadQuickBooksConnectionRequest() {
    return {
      type: LOAD_QUICKBOOKS_CONNECTION_REQUEST,
    }
  };

  export function loadQuickBooksConnectionSuccess(data) {
    return {
      type: LOAD_QUICKBOOKS_CONNECTION_SUCCESS,
      payload: data,
    }
  };

  export function sendDeleteConnectionRequest(data) {
    return {
      type: SEND_DELETE_CONNECTION_REQUEST,
      payload: data,
    }
  };
    
  export function sendDeleteConnectionSuccess(data) {
    return {
      type: SEND_DELETE_CONNECTION_SUCCESS,
      payload: data,
    }
  };

  export function sendConnectionRequest(data) {
    return {
      type: SEND_CONNECTION_REQUEST,
      payload: data,
    }
  };

  export function sendConnectionSuccess(data) {
    return {
      type: SEND_CONNECTION_SUCCESS,
      payload: data,
    }
  };

  export function sendConnectionUpdateRequest(data) {
    return {
      type: SEND_CONNECTION_UPDATE_REQUEST,
      payload: data,
    }
  };

  export function sendConnectionUpdateSuccess(data) {
    return {
      type: SEND_CONNECTION_UPDATE_SUCCESS,
      payload: data,
    }
  };
  