const PATH = 'app/Dashboard';

export const LOAD_DASHBOARD_REQUEST = `${PATH}LOAD_DASHBOARD_REQUEST`;
export const LOAD_DASHBOARD_ERROR = `${PATH}LOAD_DASHBOARD_ERROR`;
export const LOAD_DASHBOARD_LOADING = `${PATH}LOAD_DASHBOARD_LOADING`;
export const LOAD_DASHBOARD_SUCCESS = `${PATH}LOAD_DASHBOARD_SUCCESS`;
export const LOAD_SALES_SUMMARY_REQUEST = `${PATH}LOAD_SALES_SUMMARY_REQUEST`;
export const LOAD_SALES_SUMMARY_SUCCESS = `${PATH}LOAD_SALES_SUMMARY_SUCCESS`;
export const LOAD_RATING_SUMMARY_REQUEST = `${PATH}LOAD_RATING_SUMMARY_REQUEST`;
export const LOAD_RATING_SUMMARY_SUCCESS = `${PATH}LOAD_RATING_SUMMARY_SUCCESS`;
export const LOAD_PURCHASING_SUMMARY_REQUEST = `${PATH}LOAD_PURCHASING_SUMMARY_REQUEST`;
export const LOAD_PURCHASING_SUMMARY_SUCCESS = `${PATH}LOAD_PURCHASING_SUMMARY_SUCCESS`;