import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/notificationSelector';
import * as actions from '../../../store/action/notificationAction';
import PropTypes from 'prop-types';
import Loader from '../../layout/form/Loader';
import ErrorModal from '../../layout/form/errorModal';
import Button from '../../layout/form/Button';
import moment from 'moment';
import common from '../../common';
import Table from '../../layout/form/Table';
import ButtonGroup from '../../layout/form/ButtonGroup';
// import { Card } from 'react-bootstrap';
// import Avatar from 'react-avatar';
import Modal from '../../layout/form/Modal'
import DatePicker from '../../layout/form/datePicker';
import SelectField from '../../layout/form/SelectField';
import TextField from '../../layout/form/TextField';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class SMSNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectedView: false,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      RegNo: '',
      mobile: '',
      customerName: '',
      ObjectType: '',
      isFilterFormOpen: false,
      initial: true,
      isOpenModal: false,
      selectedSMS: ''
    }
  }

  getNotificationListData = () => {
    this.props.dispatch(actions.loadSMSNotificationListSuccess([]))
    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      ToDate: this.state.ToDate,
      FromDate: this.state.FromDate,
      RegNo: this.state.RegNo,
      ObjectType: this.state.ObjectType,
      mobile: this.state.mobile,
      customerName: this.state.customerName,
    }
    this.props.dispatch(actions.loadSMSNotificationListRequest(parameters))
    this.setState({
      initial: true
    })
  }

  setDateFormat = (value) => {
    const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
    const time = moment(value).format('HH:mm');
    return date + " - " + time
  }

  errorModalClose = () => {
    this.props.dispatch(actions.loadSMSNotificationListError(''))
  }

  handleView = () => {
    this.setState({
      isSelectedView: true
    })
  }

  handleDateChange = (date, id) => {
    if (date === null && id === "FromDate") {
      this.setState({
        [id]: moment(prevDate).format(common.dateFormat),
      })
    }
    else if (date === null && id === "ToDate") {
      this.setState({
        [id]: moment(today).format(common.dateFormat),
      })
    } else {
      this.setState({
        [id]: moment(date).format(common.dateFormat),
      })
    }
  }

  handleChangeSelect = (value, id) => {
    this.setState({
      [id]: value
    })
  }


  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleFilterForm = () => {
    this.setState({
      isFilterFormOpen: !this.state.isFilterFormOpen,
      ToDate: this.state.ToDate,
      FromDate: this.state.FromDate,
      RegNo: this.state.RegNo,
      ObjectType: this.state.ObjectType,
      mobile: this.state.mobile,
      customerName: this.state.customerName,
    })
  }

  handleFilter = () => {
    this.getNotificationListData()
    this.handleFilterForm()
  }

  handleFilterFormClose = () => {
    this.setState({
      isFilterFormOpen: false,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      RegNo: '',
      ObjectType: '',
      pageSize: 20,
      pageNumber: 1,
      initial: true,
      mobile: '',
      customerName: '',
    })

    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      RegNo: '',
      ObjectType: '',
      mobile: '',
      customerName: '',
    }
    this.props.dispatch(actions.loadSMSNotificationListRequest(parameters))
  }

  handleModal = (details) => {
    this.setState({
      isOpenModal: true,
      selectedSMS: details
    })
  }

  handleModalClose = () => {
    this.setState({
      isOpenModal: false,
      selectedSMS: ''
    })
  }

  componentDidMount() {
    this.getNotificationListData()
  }

  render() {
    const {
      isSMSNotificationListLoading,
      smsList,
      smsListError
    } = this.props;

    const {
      isFilterFormOpen,
      isOpenModal,
      selectedSMS
    } = this.state;

    const columns = [{
      Header: 'Customer name',
      Cell: ((row) =>
        <div>
          <div style={{ display: 'flex' }}>
            {/* <div style={{marginRight:'10px'}}>
                          <Avatar name={row.row.original.customerDetails.displayName} size={30} round="50%" maxInitials={2}/>
                      </div> */}
            <div style={{ marginTop: '5px' }}>{row.row.original.customerDetails.displayName}</div>
          </div>
        </div>
      )
    }, {
      Header: 'Registration No',
      Cell: ((row) =>
        <div style={{ marginTop: '5px' }}>{row.row.original.vehicleDetials.vehicleRegistrationNumber}</div>
      ),
      width: "10%",
    }, {
      Header: 'Mobile',
      Cell: ((row) =>
        <div style={{ marginTop: '5px' }}>{row.row.original.customerDetails.mobilePhone}</div>
      ),
      width: "10%",
    }, {
      Header: 'Date',
      Cell: ((row) =>
        <div style={{ marginTop: '5px' }}>{row.row.original.sentDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.sentDate)}</div>
      ),
      width: "15%",
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Quantity</div>),
      accessor: 'Quantity',
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>
          {numberWithCommas(parseFloat(row.row.original.quantity))}
        </div>
      )),
      width: "8%",
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Price</div>),
      accessor: 'Price',
      Cell: ((row) =>
        <div style={{
          textAlign: "right"
        }}>
          {numberWithCommas(parseFloat(row.row.original.price).toFixed(2))}
        </div>
      ),
      width: "8%",
    }, {
      Header: 'Status',
      Cell: ((row) => (
        <div style={{ padding: '2px' }}>
          {row.row.original.statusId === "02" ?
            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>Sent</div> :
            row.row.original.quantity === 0 ?
              <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>Canceled</div> :
              <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>Pending</div>
          }
        </div>
      )),
      width: "10%",
    }, {
      Header: 'Action',
      width: "8%",
      Cell: ((row) => (
        <div>
          {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
          </span>}
        </div>
      )),
      accessor: 'action'
    }]

    return (
      <div>
        {(isSMSNotificationListLoading ||
          (smsList && smsList.length && smsList.length === 0)) ?
          <Loader show={isSMSNotificationListLoading} /> : ''}

        {smsListError !== '' &&
          <ErrorModal
            show={true}
            massage={smsListError}
            handleClose={this.errorModalClose}
          />}
        <div>
          <div className="content-header" style={{ marginTop: '-50px' }}>
            <div className="header-pages">

            </div>
            <div className="header-btn">
              <Button
                onClick={this.handleFilterForm}
                text='Filter'
                variant="outline-primary"
                icon='filter'
              />
            </div>
          </div>
          {
            isFilterFormOpen ?
              <div className="filter-form-notification">
                <div>
                  <div className="row">
                    <div className="col-sm">
                      <TextField
                        type="text"
                        onChange={this.handleChange}
                        defaultValue={this.state.customerName}
                        text="Customer Name"
                        controlId="customerName"
                      />
                    </div><div className="col-sm">
                      <TextField
                        type="text"
                        onChange={this.handleChange}
                        defaultValue={this.state.mobile}
                        text="Mobile"
                        controlId="mobile"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      <TextField
                        type="text"
                        onChange={this.handleChange}
                        defaultValue={this.state.RegNo}
                        text="Vehicle registration no"
                        controlId="RegNo"
                      />
                    </div>
                    <div className="col-sm">
                      <SelectField
                        text="Type"
                        controlId="ObjectType"
                        options={common.smsObjectType}
                        selectedValue={this.state.ObjectType}
                        onChange={(e) => this.handleChangeSelect(e, "ObjectType")}
                        isClearable
                      />
                    </div>
                    <div className="col-sm">
                      <DatePicker
                        text="From Date"
                        controlId="FromDate"
                        onChange={(e) => this.handleDateChange(e, "FromDate")}
                        value={new Date(this.state.FromDate)}
                      // format={dateFormat}
                      />
                    </div>
                    <div className="col-sm">
                      <DatePicker
                        text="To Date"
                        controlId="ToDate"
                        onChange={(e) => this.handleDateChange(e, "ToDate")}
                        value={new Date(this.state.ToDate)}
                      // format={dateFormat}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-btns">
                  <ButtonGroup
                    primaryBtnClick={this.handleFilter}
                    primaryBtnIcon='search'
                    primaryBtnText='Search'
                    primaryBtnVariant="outline-primary"
                    secondaryBtnClick={this.handleFilterFormClose}
                    secondaryBtnIcon='clear-filter'
                    secondaryBtnText='Clear'
                    secondaryBtnVariant="outline-primary"
                  />
                </div>
              </div> : ''}
          <div className="notification-header">
            <ui5-icon class="samples-margin" name="email" id="tab-icon"></ui5-icon>
            <h6>SMS List</h6>
          </div>
          <div className="table-content">
            <Table
              columns={columns}
              data={smsList.result && smsList.result.length ? smsList.result : []}
            />
          </div>
        </div>
        {isOpenModal &&
          <Modal
            show={isOpenModal}
            onHide={this.handleModalClose}
            title={<div>{selectedSMS.vehicleDetials.vehicleRegistrationNumber} - <span className="mb-2 text-muted"> {`( ${selectedSMS.customerDetails.mobilePhone} )`}</span></div>}
            width='40%'
            body={
              <div>
                <div>
                  {selectedSMS.smsBody}
                </div>
              </div>
            }
            footer={
              <div>
                <Button
                  variant="outline-primary"
                  text='Close'
                  type="submit"
                  onClick={this.handleModalClose}
                  icon="undo"
                />
              </div>
            }
          />}
      </div>
    )
  }
}

SMSNotification.propTypes = {
  smsListError: PropTypes.any,
  isSMSNotificationListLoading: PropTypes.any,
  smsList: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  smsListError: selectors.makeSelectSMSNotificationListError(),
  isSMSNotificationListLoading: selectors.makeSelectSMSNotificationListLoading(),
  smsList: selectors.makeSelectSMSNotificationList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default (compose(withConnect)(SMSNotification));
