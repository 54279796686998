const PATH = 'app/StockDocument';

export const SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS = `${PATH}SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS`;
export const SAVE_STOCK_DOCUMENT_DETAILS = `${PATH}SAVE_STOCK_DOCUMENT_DETAILS`;
export const LOAD_STOCK_DOCUMENT_TYPE_REQUEST = `${PATH}LOAD_STOCK_DOCUMENT_TYPE_REQUEST`;
export const LOAD_STOCK_DOCUMENT_TYPE_SUCCESS = `${PATH}LOAD_STOCK_DOCUMENT_TYPE_SUCCESS`;
export const LOAD_STOCK_DOCUMENT_LIST_REQUEST = `${PATH}LOAD_STOCK_DOCUMENT_LIST_REQUEST`;
export const LOAD_STOCK_DOCUMENT_LIST_ERROR = `${PATH}LOAD_STOCK_DOCUMENT_LIST_ERROR`;
export const LOAD_STOCK_DOCUMENT_LIST_LOADING = `${PATH}LOAD_STOCK_DOCUMENT_LIST_LOADING`;
export const LOAD_STOCK_DOCUMENT_LIST_SUCCESS = `${PATH}LOAD_STOCK_DOCUMENT_LIST_SUCCESS`;
export const LOAD_SELECTED_STOCK_DOCUMENT_REQUEST = `${PATH}LOAD_SELECTED_STOCK_DOCUMENT_REQUEST`;
export const LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS = `${PATH}LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS`;
export const SEND_STOCK_DOCUMENT_SUCCESS = `${PATH}SEND_STOCK_DOCUMENT_SUCCESS`;
export const SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST = `${PATH}SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST`;
export const SEND_STOCK_DOCUMENT_PRINT_REQUEST = `${PATH}SEND_STOCK_DOCUMENT_PRINT_REQUEST`;
export const LOAD_STOCK_DOCUMENT_PRINT_SUCCESS = `${PATH}LOAD_STOCK_DOCUMENT_PRINT_SUCCESS`;
export const LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST = `${PATH}LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST`;
export const LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR = `${PATH}LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR`;
export const LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING = `${PATH}LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING`;
export const LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS = `${PATH}LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS`;
export const SAVE_IS_STOCK_REPORT_OPEN_STATUS = `${PATH}SAVE_IS_STOCK_REPORT_OPEN_STATUS`;
export const LOAD_STOCK_DOCUMENT_PRINT_REQUEST = `${PATH}LOAD_STOCK_DOCUMENT_PRINT_REQUEST`;