import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewQuotationRequest(data){
    const parameters = JSON.stringify(data)
    const appId = "03" // invoice app ai = 03
      return axios.post(baseURL+'quotations?appId=' + appId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
      })
}

export function getQuotationList(parameters){
    return axios.get(baseURL+'quotations?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&QuotationNo='+parameters.quotationNo+'&VehicleId='+parameters.vehicleId+'&CustomerId='+parameters.customerId+'&StatusId='+parameters.statusId, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateQuotationRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.patch(baseURL+'quotations/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteQuotationRequest(id){
    return axios.delete(baseURL+'quotations/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedQuotationRequest(id){
    return axios.get(baseURL+'quotations/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateAndPrintQuotationRequest(data){
    const parameters = JSON.stringify(data)
    const appId = "03" // invoice app ai = 03
    return axios.patch(baseURL+'quotations/printAndUpdate/'+data.quotationDetails.id+ '?appId=' + appId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function printQuotationByIdRequest(id){
    const appId = "03" // invoice app ai = 03
    return axios.patch(baseURL+'quotations/printAction/'+id+ '?appId=' + appId,null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateAndEmailQuotationRequest(data){
    const parameters = JSON.stringify(data)
    const appId = "03" // invoice app ai = 03
    return axios.patch(baseURL+'quotations/emailAndUpdate/'+data.quotationDetails.id+ '?appId=' + appId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function emailQuotationByIdRequest(id){
    const appId = "03" // invoice app ai = 03
    return axios.patch(baseURL+'quotations/emailAction/'+id+ '?appId=' + appId,null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function addQuotationImageRequest(data){
    return axios.post(baseURL+'quotations/imageUpload', data, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function addQuotationImageEmailRequest(data){
  const parameters = JSON.stringify(data)
  // //console.log(parameters)
  return axios.post(baseURL+'quotations/sendImages/'+data.id, parameters, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}