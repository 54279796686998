import { createSelector } from 'reselect';

const selectItemDomain = state => state.item;

const makeSelectItemPopupOpen = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('isAddNewItemModalOpen') : false
  );

const makeSelectAddNewItemSuccess = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('addNewItemSuccess') : ''
  );

const makeSelectAddNewItemLoading = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('isAddNewItemLoading') : false
  );

const makeSelectAddNewItemError = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('addNewItemError') : ''
  );

const makeSelectItemList = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('itemList') : []
  );

const makeSelectItemListLoading = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('isItemListLoading') : false
  );

const makeSelectItemListError = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('getItemListError') : ''
  );

const makeSelectItemCategoryList = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('itemCategoryList') : []
  );

const makeSelectUpdateItemSuccess = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('updateItemSuccess') : ''
  );

const makeSelectUpdateItemLoading = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('isUpdateItemLoading') : false
  );

const makeSelectUpdateItemError = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('updateItemError') : ''
  );

const makeSelectDeleteItemSuccess = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('deleteItemSuccess') : ''
  );

const makeSelectDeleteItemLoading = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('isDeleteItemLoading') : false
  );

const makeSelectDeleteItemError = () =>
  createSelector(
    selectItemDomain,
    itemState => 
      itemState ? itemState.get('deleteItemError') : ''
  );

export {
    makeSelectItemPopupOpen,
    makeSelectAddNewItemSuccess,
    makeSelectAddNewItemLoading,
    makeSelectAddNewItemError,
    makeSelectItemList,
    makeSelectItemListLoading,
    makeSelectItemListError,
    makeSelectItemCategoryList,
    makeSelectUpdateItemSuccess,
    makeSelectUpdateItemLoading,
    makeSelectUpdateItemError,
    makeSelectDeleteItemSuccess,
    makeSelectDeleteItemLoading,
    makeSelectDeleteItemError
}
