import { fromJS } from 'immutable';
import {
  LOAD_SUPPLIER_LIST_ERROR,
  LOAD_SUPPLIER_LIST_LOADING,
  LOAD_SUPPLIER_LIST_SUCCESS,
  LOAD_SELECTED_SUPPLIER_SUCCESS,
  SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS,
  SEND_SUPPLIER_SUCCESS,
} from '../constant/supplierConstant';

export const initialState = fromJS({
    isAddNewSupplierModalOpen:false,
    supplierList:[],
    isSupplierListLoading:false,
    supplierListError:'',
    supplierSuccess:'',
    selectedSupplier:''
});

function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS:
      return state.set('isAddNewSupplierModalOpen', action.open)
    case LOAD_SUPPLIER_LIST_SUCCESS:
      return state.set('supplierList', action.payload)
    case LOAD_SUPPLIER_LIST_LOADING:
      return state.set('isSupplierListLoading', action.payload)
    case LOAD_SUPPLIER_LIST_ERROR:
      return state.set('supplierListError', action.payload)
    case SEND_SUPPLIER_SUCCESS:
      return state.set('supplierSuccess', action.payload)
    case LOAD_SELECTED_SUPPLIER_SUCCESS:
      return state.set('selectedSupplier', action.payload)
    default:
      return state;
  }
}

export default supplierReducer;
