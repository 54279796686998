import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import PropTypes from 'prop-types';
// import Editor from '../../../layout/form/Editor';
import TextEditor from '../../../layout/form/TextEditor';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { Link } from 'react-router-dom';
import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import Switch from "react-switch";

class Correspondence extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            initial:true,
            invoiceEditorState:'',
            quotationEditorState:'',
            bookingEditorState:'',
            bookingSmsBody:'',
            isBookingSms: false,
            isBookingEmail: true,
            errorMassage:'',
            isValidationError:false,
            isChangesCorrespondence: false
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { initial } = prevState;
        const { tenantCorrespondence } = prevProps; 
        if( initial && tenantCorrespondence && tenantCorrespondence.id){
            return {
                invoiceEditorState: decodeURIComponent(tenantCorrespondence.invoice),
                quotationEditorState: decodeURIComponent(tenantCorrespondence.quotation),
                bookingEditorState: decodeURIComponent(tenantCorrespondence.bookingReminder),
                bookingSmsBody: tenantCorrespondence.bookingSMSBody,
                isBookingSms: tenantCorrespondence.bookingSMS,
                isBookingEmail: tenantCorrespondence.bookingEmail,
                initial:false,
            }
        }
        return null
    }

    componentDidMount (){
        this.getTenantDetails()
    }

    getTenantDetails =()=>{
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(actions.loadTenantCorrespondenceSuccess({}))
        this.props.dispatch(actions.loadTenantCorrespondenceRequest(id))
        this.setState({
            isChangesCorrespondence: false
        })
    }

    oninvoiceEditorChange  = (invoiceEditorState) => {
        this.setState({
            invoiceEditorState,
            isChangesCorrespondence: true
        });
    };

    onBookingEditorChange  = (bookingEditorState) => {
        this.setState({
            bookingEditorState,
            isChangesCorrespondence: true
        });
    };

    emailHandleCheck = (e) => {
        this.setState({
            isBookingEmail: e
        })
    }

    smsHandleCheck= (e) => {
        this.setState({
            isBookingSms: e
        })
    }

    handleOnBlurChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    onQuotationEditorChange  = (quotationEditorState) => {
        this.setState({
            quotationEditorState,
            isChangesCorrespondence: true
        });
    };

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendTenantDetailsSuccess(''))
        this.getTenantDetails()
        this.setState({
            initial:true
        })
    }

    componentDidUpdate (){
        if (this.props.tenantDetailsSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
    }

    render() {
        const { 
            tenantDetailsError,
            tenantDetailsSuccess,
            isTenantDetailsLoading,
            handleTab,
            handleSubmit,
            userAccess
        } =this.props; 

        return (
            <div>
                {(isTenantDetailsLoading  ) ? 
                    <Loader show={isTenantDetailsLoading}/> :''}

                {(this.state.isValidationError || tenantDetailsError !== '' ) && 
                        <ErrorModal
                            show={true} 
                            massage={this.state.errorMassage ? this.state.errorMassage : tenantDetailsError}
                            handleClose={this.errorModalClose}
                        />}

                {(tenantDetailsSuccess) && 
                        <SuccessModal
                            show={true} 
                            massage={tenantDetailsSuccess}
                            handleClose={this.successModalClose}
                        />}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData',this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance',this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Finance</span>
                        <span onClick={() => handleTab('correspondence',this.state)}><ui5-icon class="samples-margin" name="letter" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Correspondence</span>
                        <span onClick={() => handleTab('openingHours',this.state)}><ui5-icon class="samples-margin" name="date-time" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Opening Hours</span>
                        <span onClick={() => handleTab('marketing',this.state)}><ui5-icon class="samples-margin" name="official-service" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Marketing</span>
                    </div>
                </div>
            <div className="profile-content">
                <h5>Correspondence</h5>
                <Tabs defaultActiveKey="invoice" id="correspondenceTab">
                    <Tab eventKey="invoice" title="Invoice">
                        <div className="correspondenceTab">
                            <TextEditor
                                editorState={this.state.invoiceEditorState}
                                onEditorStateChange={(e) => this.oninvoiceEditorChange(e)}
                                readonly={userAccess && !userAccess.modifyTenant}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="quotation" title="Quotation">
                        <div className="correspondenceTab">
                            <TextEditor
                                editorState={this.state.quotationEditorState}
                                onEditorStateChange={(e) => this.onQuotationEditorChange(e)}
                                readonly={userAccess && !userAccess.modifyTenant}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="booking" title="Booking">
                    {/* <div style={{margin:'20px 20px 0 20px',display:'flex'}}>
                            <span className="address-title" >SMS</span>
                            <Form.Group controlId="addressCheckBox">
                                <Form.Check type="checkbox" style={{fontSize:"14px"}} onChange={(e) => this.smsHandleCheck(e)} checked={this.state.isBookingSms}/>
                            </Form.Group>
                        </div> */}
                        <div style={{display:'flex',margin:'15px 5px 5px 5px'}}>
                            <div style={{margin:'5px 10px'}}>
                                <spn>SMS</spn>
                            </div>
                            <div>
                                <Switch 
                                    checked={this.state.isBookingSms}
                                    onChange={(e) =>this.smsHandleCheck(e)} 
                                    checkedIcon={false} 
                                    uncheckedIcon={false}
                                    disabled={userAccess && !userAccess.modifyTenant}
                                />
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <div style={{width:'70%', margin:'0 20px'}}>
                                <TextField
                                    as="textarea"
                                    // row={50}
                                    style={{ height: 100 }}
                                    onBlur={(e) => this.handleOnBlurChange(e)}
                                    controlId="bookingSmsBody"
                                    type="text"
                                    defaultValue={this.state.bookingSmsBody}
                                    maxLength={300}
                                    disabled={userAccess && !userAccess.modifyTenant}
                                />
                            </div>
                            <div style={{width:'30%', marginTop:'20px'}}>
                                <span style={{marginBottom:'2px'}}>Place following tags in the template to display information</span>
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                    <div>
                                        <div>{'[vehicleNo]'}</div>
                                        <div>{'[bookingDate]'}</div>
                                    </div>
                                    <div>
                                        <div>Vehicle registration no</div>
                                        <div>Booking date</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{margin:'20px 20px 0 20px',display:'flex'}}>
                            <span className="address-title" >Email</span>
                            <Form.Group controlId="addressCheckBox">
                                <Form.Check type="checkbox" style={{fontSize:"14px"}} onChange={(e) => this.emailHandleCheck(e)} checked={this.state.isBookingEmail}/>
                            </Form.Group>
                        </div> */}
                        <div style={{display:'flex',margin:'5px '}}>
                            <div style={{margin:'5px 10px '}}>
                                <spn>Email</spn>
                            </div>
                            <div>
                                <Switch 
                                    checked={this.state.isBookingEmail}
                                    onChange={(e) =>this.emailHandleCheck(e)} 
                                    checkedIcon={false} 
                                    uncheckedIcon={false}
                                    disabled={userAccess && !userAccess.modifyTenant}
                                />
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <div className="correspondenceTab">
                                <TextEditor
                                    editorState={this.state.bookingEditorState}
                                    onEditorStateChange={(e) => this.onBookingEditorChange(e)}
                                    readonly={userAccess && !userAccess.modifyTenant}
                                />
                            </div>
                            <div style={{width:'30%', marginTop:'20px'}}>
                                <span style={{marginBottom:'2px'}}>Place following tags in the template to display information</span>
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                    <div>
                                        <div>{'[customerName]'}</div>
                                        <div>{'[vehicleNo]'}</div>
                                        <div>{'[bookingDate]'}</div>
                                        <div>{'[customerPhone]'}</div>
                                        <div>{'[notes]'}</div>
                                        <div>{'[status]'}</div>
                                        <div>{'[tenantPhone]'}</div>
                                        <div>{'[tenantName]'}</div>
                                        <div>{'[streetNo]'}</div>
                                        <div>{'[streetName]'}</div>
                                        <div>{'[cityName]'}</div>
                                        <div>{'[tenantEmail]'}</div>
                                    </div>
                                    <div>
                                        <div>Customer name</div>
                                        <div>Vehicle registration no</div>
                                        <div>Booking date</div>
                                        <div>Customer phone</div>
                                        <div>Customer notes</div>
                                        <div>Booking status</div>
                                        <div>Your phone</div>
                                        <div>Your business name</div>
                                        <div>Your street No</div>
                                        <div>Your street name</div>
                                        <div>Your city name</div>
                                        <div>Your email</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
                <div className="btnSave">
                    <Button
                        variant="outline-primary" 
                        text ='Save'
                        onClick={() => handleSubmit(this.state)}
                        disabled={userAccess && !userAccess.modifyTenant}
                        icon="save"
                    />
                </div>
            </div>
        )
    }
}

Correspondence.propTypes = {
    invoiceEditorState: PropTypes.any,
    oninvoiceEditorChange: PropTypes.any,
    quotationEditorState: PropTypes.any,
    onQuotationEditorChange: PropTypes.any,
    // bookingEditorState: PropTypes.any,
    onBookingEditorChange: PropTypes.any,
    serviceEditorState: PropTypes.any,
    onServiceEditorChange: PropTypes.any,
    // registrationEditorState: PropTypes.any,
    onRegistrationEditorChange: PropTypes.any,
}


const mapStateToProps = createStructuredSelector({
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
    tenantCorrespondence: selectors.makeSelectTenantCorrespondenceSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Correspondence));