import { put, call } from 'redux-saga/effects';
import {
  addNewPurchasingRequest,
  getPurchasingList,
  updatePurchasingRequest,
  deletePurchasingRequest,
  getSelectedPurchasingRequest,
  printPurchasingByIdRequest,
  sendPurchasingByIdRequest,
} from '../service/purchasingService';
import * as actions from '../../store/action/purchasingAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewPurchasingRequest(data){
  //console.log('sendAddNewPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    // yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(addNewPurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendPurchasingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.message
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewPurchasingLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewPurchasingLoading(false));
    //console.log(error.response.data.detail)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* loadPurchasingListRequest(data){
  //console.log('loadPurchasingListRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(getPurchasingList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const purchasingList = response.data.result
      yield put(actions.loadPurchasingListSuccess(purchasingList))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadPurchasingListLoading(false));
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendUpdatePurchasingRequest(data){
  //console.log('sendUpdatePurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    // yield put(actions.sendUpdatePurchasingLoading(true));
    const response = yield call(updatePurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendPurchasingSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdatePurchasingLoading(false));
  }catch(error){
    // yield put(actions.sendUpdatePurchasingLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendDeletePurchasingRequest(data){
  //console.log('sendDeletePurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(deletePurchasingRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendPurchasingSuccess(massage)) 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadPurchasingListLoading(false));
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendPrintPurchasingRequest(data){
  //console.log('sendPrintPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(updatePurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.id)
      const orderId = response.data.result.id
      const printResponse = yield call(printPurchasingByIdRequest,orderId)
      if(printResponse && printResponse.data && printResponse.data.statusCode && printResponse.data.statusCode === 200 ){
        //console.log(printResponse.data.result)
        const pdf = printResponse.data.result.byteCode
        yield put(actions.loadPurchasingPrintSuccess(pdf))
      }else{
        const massage = common.purchasingAddAndPrintError
        yield put(actions.loadPurchasingListError(massage))
      } 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* loadSelectedPurchasingRequest(data){
  //console.log('loadSelectedPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(getSelectedPurchasingRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const purchasing = response.data
      yield put(actions.loadSelectedPurchasingSuccess(purchasing))
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendAddAndPrintPurchasingRequest(data){
  //console.log('sendAddAndPrintPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(addNewPurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.id)
      const orderId = response.data.result.id
      const printResponse = yield call(printPurchasingByIdRequest,orderId)
      if(printResponse && printResponse.data && printResponse.data.statusCode && printResponse.data.statusCode === 200 ){
        //console.log(printResponse.data.result)
        const pdf = printResponse.data.result.byteCode
        yield put(actions.loadPurchasingPrintSuccess(pdf))
      }else{
        const massage = common.purchasingAddAndPrintError
        yield put(actions.loadPurchasingListError(massage))
      } 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendAddAndEmailPurchasingRequest(data){
  //console.log('sendAddAndEmailPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(addNewPurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.id)
      const orderId = response.data.result.id
      const emailResponse = yield call(sendPurchasingByIdRequest,orderId)
      if(emailResponse && emailResponse.data && emailResponse.data.statusCode && emailResponse.data.statusCode === 200 ){
        //console.log(emailResponse.data.result)
        const massage = common.purchasingEmailMessage
        yield put(actions.sendPurchasingSuccess(massage)) 
      } else{
        const massage = common.purchasingAddAndEmailError
        yield put(actions.loadPurchasingListError(massage))
      }
      yield put(actions.loadPurchasingListLoading(false));
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
    // yield put(actions.sendUpdatePurchasingLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* sendUpdateAndEmailPurchasingRequest(data){
  //console.log('sendUpdateAndEmailPurchasingRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(updatePurchasingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.id)
      const orderId = response.data.result.id
      const emailResponse = yield call(sendPurchasingByIdRequest,orderId)
      if(emailResponse && emailResponse.data && emailResponse.data.statusCode && emailResponse.data.statusCode === 200 ){
        //console.log(emailResponse.data.result)
        const massage = common.purchasingEmailMessage
        yield put(actions.sendPurchasingSuccess(massage)) 
      } else{
        const massage = common.purchasingAddAndEmailError
        yield put(actions.loadPurchasingListError(massage))
      }
      yield put(actions.loadPurchasingListLoading(false));
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}


export function* loadPrintbyIdRequest(data){
  //console.log('loadPrintbyIdRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(printPurchasingByIdRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.loadPurchasingPrintSuccess(pdf))
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

export function* loadPurchasingSendByIdRequest(data){
  //console.log('loadPurchasingSendByIdRequest')
  yield put(actions.loadPurchasingListError(''))
  try{
    yield put(actions.loadPurchasingListLoading(true));
    const response = yield call(sendPurchasingByIdRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = common.purchasingEmailMessage
      yield put(actions.sendPurchasingSuccess(massage)) 
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadPurchasingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadPurchasingListLoading(false));
  }catch(error){
    yield put(actions.loadPurchasingListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadPurchasingListError(massage))
  }
}

