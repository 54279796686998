import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addNewProductCategoryRequest(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
      return axios.post(baseURL+'productCategory', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function getProductCategoryList(parameters){
    return axios.get(baseURL+'productCategory?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&description='+parameters.description+'&typeId='+parameters.typeId , {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateProductCategoryRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'productCategory/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteProductCategoryRequest(id){
    return axios.delete(baseURL+'productCategory/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}