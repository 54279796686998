import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getNotificationList(parameters){
    return axios.get(baseURL+'emailNotifications?RegNo='+parameters.RegNo+'&pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&ToDate='+parameters.ToDate+'&FromDate='+parameters.FromDate+'&ObjectType='+parameters.ObjectType,{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSMSNotificationList(parameters){
    return axios.get(baseURL+'smsNotifications?RegNo='+parameters.RegNo+'&pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&ToDate='+parameters.ToDate+'&FromDate='+parameters.FromDate+'&ObjectType='+parameters.ObjectType+'&CustomerName='+parameters.customerName+'&Mobile='+parameters.mobile,{
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}