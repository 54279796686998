import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Modal from '../../../layout/form/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
// import SelectField from '../../layout/form/SelectField';
import TextField from '../../../layout/form/TextField';
// import DatePicker from '../../layout/form/datePicker';
import common from '../../../common';
import moment from 'moment';
import { loadSearchedVehicleRequest, saveIsBookingUsingVehicle } from '../../../../store/action/bookingAction';
import { loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
import DateTimePicker from '../../../layout/form/dateTimePicker';

const today = new Date() 
export class AddReminder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName:'',
            date:moment(today).format(common.dateTimeFormat),
            notes:'',
            initial:true,
            isChangeRegNo:false,
            description:''
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { isUpdate, selectedRow,isSelectedSlot,slots,tenantUser } = prevProps;
        const { initial } = prevState
        if(isUpdate && selectedRow && selectedRow.id && initial){
            return{
                userName:selectedRow.userName || (selectedRow.firstName +' '+ selectedRow.lastName),
                description: selectedRow.description,
                date:moment(selectedRow.requestedDate).format(common.dateTimeFormat),
                notes:selectedRow.notes,
                initial:false,
                id:selectedRow.id,
            }
        }
        else if(isSelectedSlot && slots !== '' && initial){
            const timeSlot = slots[0]
            return{
                initial:false,
                date:moment(timeSlot).format(common.dateTimeFormat),
                userName:tenantUser && tenantUser.firstName +' '+ tenantUser.lastName
            }
        }
        else if(!isUpdate){
            return {
                userName:tenantUser && tenantUser.firstName +' '+ tenantUser.lastName
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleDateChange = (date, id) => {
        if(date === null){
            this.setState({
                [id]: moment(today).format(common.dateTimeFormat),
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateTimeFormat),
            })
        }
    }

    componentDidUpdate (prevProps, prevState){
        const { selectedCustomer, vehicleList } = this.props
        if(
            this.state.isChangeRegNo && 
            vehicleList && vehicleList.result &&  vehicleList.result.length > 0  && 
            selectedCustomer && selectedCustomer.id){
            this.setState({
                isChangeRegNo:false,
                customer:selectedCustomer.displayName,
                email: selectedCustomer.emailAddress,
                phoneNumber:selectedCustomer.mobilePhone,
                customerId:vehicleList.result[0].customerId,
                vehicleId:vehicleList.result[0].id
            })
        }else if (
            this.state.isChangeRegNo && 
            vehicleList && vehicleList.result && vehicleList.result.isSearchedVehicle === false){
            this.setState({
                isChangeRegNo:false,
                customer:'',
                email:'',
                phoneNumber:'',
                customerId:0,
                vehicleId:0
            })
        }else if(this.props.isBookingUsingVehicle){
            this.props.dispatch(saveIsBookingUsingVehicle(false))
            const parameters= {
                pageSize:9999,
                pageNumber:1,
                Customer:'',
                Make:'',
                Model:'',
                Search:this.props.bookingVehicleDetails.vehicleRegistrationNumber,
                Status:'',
                commonSearch:''
            }
            this.props.dispatch(loadSelectedCustomerSuccess({}))
            this.props.dispatch(loadSearchedVehicleRequest(parameters))
            this.props.dispatch(saveIsBookingUsingVehicle(false))
            this.setState({
                isChangeRegNo:true,
                customerId:0,
                vehicleId:0,
                vehicleRegistrationNo:this.props.bookingVehicleDetails.vehicleRegistrationNumber,
            })
        }
    }

    render() {
        const {
            isAddNewReminderModalOpen,
            handleModalClose,
            // handleConfirm,
            selectedRow,
            isUpdate,
            handleSaveAddNew,
            // handleCreateJobcard,
            handleDeleteConfirm,
            // handleBookingReminder
        } = this.props;

        return (
            <div>
                {((isUpdate && selectedRow && selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewReminderModalOpen} 
                    onHide={handleModalClose}
                    title="Reminder Information"
                    width='40%'
                    body={
                        <div>
                            <div className="col-sm">
                                <TextField
                                    text="Description"
                                    controlId="description"
                                    // placeholder="Email"
                                    type="text"
                                    onBlur={this.handleChange}
                                    required
                                    defaultValue={this.state.description}
                                    maxLength={20}
                                />
                            </div>
                            <div className="col-sm">
                                {/* <SelectField
                                    text="Customer"
                                    controlId="customer"
                                    // options={customerList.result}
                                    // onChange={(e) => this.handleChange(e,"customer")}
                                    selectedValue={this.state.customer}
                                    display='displayName'
                                    isClearable
                                /> */}
                                <TextField
                                    text="User"
                                    // controlId="customer"
                                    // placeholder="Email"
                                    type="text"
                                    defaultValue={this.state.userName}
                                    disabled
                                />
                            </div>
                            {/* <div className="col-sm">
                                <TextField
                                    text="Email"
                                    controlId="email"
                                    // placeholder="Email"
                                    type="email"
                                    onBlur={this.handleChange}
                                    defaultValue={this.state.email}
                                    maxLength={common.maxLengths.customerEmail}
                                />
                            </div> */}
                            {/* <div className="col-sm">
                                <TextField
                                    text="Phone Number"
                                    controlId="phoneNumber"
                                    // placeholder="Phone"
                                    type="text"
                                    onBlur={this.handleChange}
                                    defaultValue={this.state.phoneNumber}
                                    maxLength={common.maxLengths.customerPhone}
                                />
                            </div> */}
                            <div className="col-sm">
                                <DateTimePicker
                                    text="Date"
                                    controlId="date"
                                    onChange={(e) => this.handleDateChange(e,"date")}
                                    value={this.state.date === '' ? '' :new Date(this.state.date)}
                                    required
                                    // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <TextField
                                    text="Notes"
                                    as="textarea"
                                    row={50}
                                    controlId="notes"
                                    defaultValue={this.state.notes}
                                    style={{ height: 200 }}
                                    onBlur={this.handleChange}
                                    // placeholder="Notes"
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div>
                            <div className="filter-btns" style={{marginRight:'15px'}}>
                               {!isUpdate &&  <ButtonGroup
                                    primaryBtnClick={handleModalClose}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Go Back'
                                    primaryBtnIcon='undo'
                                    secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                    secondaryBtnText='Save'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='save'
                                />}
                                {isUpdate && 
                                <div style={{display:'flex'}}>
                                <ButtonGroup
                                    primaryBtnClick={()=>handleDeleteConfirm(selectedRow)}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Delete'
                                    primaryBtnIcon='delete'
                                    secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                    secondaryBtnText='Save'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='save'
                                />
                                </div>}
                            </div>
                        </div>
                    }
                />
                }
            </div>
        )
    }
}

AddReminder.propTypes = {
    isAddNewReminderModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    selectedRow: PropTypes.any,
    handleConfirm: PropTypes.any,
    isUpdate: PropTypes.bool,
    handleSaveAddNew: PropTypes.any,
    vehicleList: PropTypes.any,
    selectedCustomer: PropTypes.any,
    handleCreateJobcard: PropTypes.any,
    bookingVehicleDetails: PropTypes.any,
    isBookingUsingVehicle: PropTypes.any,
    handleDeleteConfirm: PropTypes.any,
    handleBookingReminder: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(AddReminder));
