const PATH = 'app/Booking';

export const SAVE_IS_BOOKING_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_BOOKING_MODAL_OPEN_STATUS`;
export const SEND_ADD_NEW_BOOKING_REQUEST = `${PATH}SEND_ADD_NEW_BOOKING_REQUEST`;
export const LOAD_BOOKING_LIST_REQUEST = `${PATH}LOAD_BOOKING_LIST_REQUEST`;
export const LOAD_BOOKING_LIST_ERROR = `${PATH}LOAD_BOOKING_LIST_ERROR`;
export const LOAD_BOOKING_LIST_LOADING = `${PATH}LOAD_BOOKING_LIST_LOADING`;
export const LOAD_BOOKING_LIST_SUCCESS = `${PATH}LOAD_BOOKING_LIST_SUCCESS`;
export const SEND_BOOKING_SUCCESS = `${PATH}SEND_BOOKING_SUCCESS`;
export const SEND_BOOKING_DELETE_REQUEST = `${PATH}SEND_BOOKING_DELETE_REQUEST`;
export const SEND_BOOKING_UPDATE_REQUEST = `${PATH}SEND_BOOKING_UPDATE_REQUEST`;
export const LOAD_SEARCHED_VEHICLE_REQUEST = `${PATH}LOAD_SEARCHED_VEHICLE_REQUEST`;
export const SAVE_BOOKING_DETAILS_SUCCESS = `${PATH}SAVE_BOOKING_DETAILS_SUCCESS`;
export const SAVE_IS_NEW_BOOKING_USING_VEHICLE =  `${PATH}SAVE_IS_NEW_BOOKING_USING_VEHICLE`;
export const SAVE_NEW_BOOKING_VEHICLE_DETAILS =  `${PATH}SAVE_NEW_BOOKING_VEHICLE_DETAILS`;
export const SEND_BOOKING_REMINDER_REQUEST = `${PATH}SEND_BOOKING_REMINDER_REQUEST`;
export const SEND_NEW_CUSTOMER_REQUEST = `${PATH}SEND_NEW_CUSTOMER_REQUEST`;
export const SEND_NEW_CUSTOMER_SUCCESS = `${PATH}SEND_NEW_CUSTOMER_SUCCESS`;
export const SAVE_CUSTOMER_MODAL_OPEN_STATUS = `${PATH}SAVE_CUSTOMER_MODAL_OPEN_STATUS`;