import { createSelector } from 'reselect';

const selectTenantDomain = state => state.tenant;

const makeSelectTenantBasic = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantDetails') : {}
  );


const makeSelectTenantFinance = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantFinance') : {}
  );

const makeSelectTenantLoading = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('isTenantDetailsLoading') : false
  );

const makeSelectTenantError = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantDetailsError') : ''
  );

const makeSelectTenantUpdateSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantDetailsSuccess') : ''
  );

const makeSelectTenantCorrespondenceSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantCorrespondence') : {}
  );

const makeSelectTenantDefaultSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantDefault') : []
  );

const makeSelectTenantAccountSystemSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantAccountSystem') : ''
  );

const makeSelectTenantServicesSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantServices') : []
  );

const makeSelectTenantServiceAreasSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantAreas') : []
  );

const makeSelectTenantIdentityStatus = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('newUserIdentitiyStatus') : false
  );


const makeSelectTenanReactivateSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantReactivateStatus') : {}
  );

const makeSelectTenantServicesSuccessStatus = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('isTenantServices') : false
  );

const makeSelectTenantServiceAreasSuccessStatus = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('isTenantAreas') : false
  );

const makeSelectTenantMarketingSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantMarketing') : {}
  );

const makeSelectTenantOpenHoursSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantOpenHours') : {}
  );


const makeSelectTenantActiveStatus = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('isTenantActiveStatus') : false
  );

const makeSelectTenantPaymentMethod = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantPaymentMethod') : []
  );

const makeSelectTenantPackage = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantPackage') : null
  );


const makeSelectTenantActivateLoading = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantActivateLoading') : false
  );

const makeSelectTenantActivateError = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantActivateError') : null
  );

const makeSelectTenantActivateSuccess = () =>
  createSelector(
    selectTenantDomain,
    tenantDetailsState =>
      tenantDetailsState ? tenantDetailsState.get('tenantActivateSuccess') : null
  );

export {
  makeSelectTenantBasic,
  makeSelectTenantFinance,
  makeSelectTenantLoading,
  makeSelectTenantError,
  makeSelectTenantUpdateSuccess,
  makeSelectTenantCorrespondenceSuccess,
  makeSelectTenantDefaultSuccess,
  makeSelectTenantAccountSystemSuccess,
  makeSelectTenantServicesSuccess,
  makeSelectTenantServiceAreasSuccess,
  makeSelectTenantIdentityStatus,
  makeSelectTenanReactivateSuccess,
  makeSelectTenantServicesSuccessStatus,
  makeSelectTenantServiceAreasSuccessStatus,
  makeSelectTenantMarketingSuccess,
  makeSelectTenantOpenHoursSuccess,
  makeSelectTenantActiveStatus,
  makeSelectTenantPaymentMethod,
  makeSelectTenantPackage,
  makeSelectTenantActivateLoading,
  makeSelectTenantActivateError,
  makeSelectTenantActivateSuccess,
}
