import { createSelector } from 'reselect';

const selectUsersDomain = state => state.users;

const makeSelectUsersPopupOpen = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('isAddNewUsersModalOpen') : false
  );

const makeSelectAddNewUserError = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('error') : ''
  );

const makeSelectAddNewUserSuccess = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('success') : ''
  );

const makeSelectUserListSuccess = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('userList') : []
  );

const makeSelectUserListLoading = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('loading') : false
  );

const makeSelectSelectedUserSuccess = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('selectedUser') : {}
  );

const makeUserRoleListrSuccess = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('roleList') : []
  );

const makeSelectUserAccessSuccess = () =>
  createSelector(
    selectUsersDomain,
    usersState =>
      usersState ? usersState.get('userAccess') : {}
  );


export {
  makeSelectUsersPopupOpen,
  makeSelectAddNewUserError,
  makeSelectAddNewUserSuccess,
  makeSelectUserListSuccess,
  makeSelectUserListLoading,
  makeSelectSelectedUserSuccess,
  makeUserRoleListrSuccess,
  makeSelectUserAccessSuccess
}
