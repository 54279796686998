import { put, call } from 'redux-saga/effects';
import {
  // getProductCategoryList,
  addNewProductRequest,
  getProductList,
  updateProductRequest,
  deleteProductRequest,
  getProductTypeList,
  getSelectedProduct,
  printBalanceStockRequest,
  getAlternativeProduct,
  updateAlternativeProductRequest
} from '../service/productService';
import {
  // loadProductCategoryListSuccess,
  sendAddNewProductError,
  sendAddNewProductLoading,
  // sendAddNewProductSuccess,
  loadProductListError,
  loadProductListLoading,
  loadProductListSuccess,
  sendUpdateProductLoading,
  sendUpdateProductSuccess,
  sendDeleteProductError,
  loadSelectedProductSuccess,
  loadProductTypeListSuccess,
  loadBalanceStockPrintSuccess,
  loadAllProductsSuccess,
  loadAlternativeProductListSuccess,
  // sendDeleteProductLoading
} from '../../store/action/productAction';
import { loadPartListSuccess } from '../../store/action/partAction';
import { loadRepairListSuccess } from '../../store/action/repairAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';

export function* sendAddNewProductRequest(data) {
  // console.log('sendAddNewProductRequest')
  yield put(sendAddNewProductError(''))
  try {
    yield put(sendAddNewProductLoading(true));
    const response = yield call(addNewProductRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      // console.log(response.data.message)
      const id = response.data.result.id
      yield put(loadSelectedProductRequest(id))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(sendAddNewProductError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(sendAddNewProductLoading(false));
  } catch (error) {
    yield put(sendAddNewProductLoading(false));
    // console.log(error.response.data.detail)
    const massage = common.error
    yield put(sendAddNewProductError(massage))
  }
}

export function* loadProductListRequest(data) {
  // console.log('loadProductListRequest')
  yield put(loadProductListError(''))
  try {
    yield put(loadProductListLoading(true));
    const response = yield call(getProductList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const productList = response.data.result
      const part = productList.result.filter(data => (data.typeId === common.productType.product))
      const repair = productList.result.filter(data => (data.typeId === common.productType.service))
      yield put(loadPartListSuccess(part))
      yield put(loadRepairListSuccess(repair))
      yield put(loadProductListSuccess(productList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(loadProductListLoading(false));
      const massage = common.error
      yield put(sendDeleteProductError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(loadProductListLoading(false));
  } catch (error) {
    yield put(loadProductListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(sendDeleteProductError(massage))
    // const massage = error.response.data.detail
    // yield put(loadProductListError(massage))
  }
}

export function* sendUpdateProductRequest(data) {
  // console.log('sendUpdateProductRequest')
  yield put(loadProductListError(''))
  try {
    yield put(sendUpdateProductLoading(true));
    const response = yield call(updateProductRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(sendUpdateProductSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(loadProductListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(sendUpdateProductLoading(false));
  } catch (error) {
    yield put(sendUpdateProductLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(loadProductListError(massage))
  }
}

export function* sendDeleteProductRequest(data) {
  // console.log('sendDeleteProductRequest')
  yield put(sendDeleteProductError(''))
  try {
    yield put(loadProductListLoading(true));
    const response = yield call(deleteProductRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(sendUpdateProductSuccess(massage)) // TODO
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(loadProductListLoading(false));
      const massage = common.error
      yield put(sendDeleteProductError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(loadProductListLoading(false));
  } catch (error) {
    yield put(loadProductListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(sendDeleteProductError(massage))
  }
}

// export function* loadProductCategoryListRequest(){
//   // console.log('loadProductCategoryListRequest')
// //   yield put(sendAddNewUserError(''))
//   try{
//     // yield put(sendAddNewUserLoading(true));
//     const response = yield call(getProductCategoryList);
//     if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
//       // console.log(response.data.result.result)
//     //   const massage = response.data
//       yield put(loadProductCategoryListSuccess(response.data.result.result))
//     } 
//     else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
//       yield put(saveLoginUser(false))
//       invalidToken()
//     }
//     if(response && response.data && response.data.result && response.data.result.newAccessToken){
//       refeshTokenset(response.data.result.newAccessToken)
//     } 
//     // yield put(sendAddNewUserLoading(false));
//   }catch(error){
//     // yield put(sendAddNewUserLoading(false));
//     // console.log(error)
//   }
// }

export function* loadProductTypeRequest() {
  try {
    const response = yield call(getProductTypeList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(loadProductTypeListSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* loadSelectedProductRequest(data) {
  // console.log('loadSelectedProductRequest')
  yield put(loadProductListError(''))
  try {
    yield put(loadProductListLoading(true));
    const response = yield call(getSelectedProduct, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const product = response.data
      yield put(loadSelectedProductSuccess(product))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(loadProductListLoading(false));
      const massage = common.error
      yield put(loadProductListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(loadProductListLoading(false));
  } catch (error) {
    yield put(loadProductListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(loadProductListError(massage))
  }
}


export function* loadPrintBalanceStockRequest(data) {
  // console.log('loadPrintbyIdRequest')
  yield put(loadProductListError(''))
  try {
    yield put(loadProductListLoading(true));
    const response = yield call(printBalanceStockRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(loadBalanceStockPrintSuccess(pdf))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(loadProductListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(loadProductListLoading(false));
  } catch (error) {
    yield put(loadProductListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(loadProductListError(massage))
  }
}

export function* loadAllProductsRequest(data) {
  try {
    const response = yield call(getProductList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const productList = response.data.result
      const product = productList.result.filter(data => (data.typeId === common.productType.product))
      yield put(loadAllProductsSuccess(product))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* loadSelectedProductAlternativeRequest(data) {
  yield put(loadProductListError(''))
  try {
    yield put(loadProductListLoading(true));
    const response = yield call(getAlternativeProduct, data.payload);
    if (response && response.status && response.status === 200) {
      const product = response.data
      yield put(loadAlternativeProductListSuccess(product))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(loadProductListLoading(false));
      const massage = common.error
      yield put(loadProductListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(loadProductListLoading(false));
  } catch (error) {
    yield put(loadProductListLoading(false));
    const massage = common.error
    yield put(loadProductListError(massage))
  }
}


export function* sendUpdateAlternativeProductRequest(data) {
  yield put(loadProductListError(''))
  try {
    yield put(sendUpdateProductLoading(true));
    const response = yield call(updateAlternativeProductRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // const massage = response.data.message
      // yield put(sendUpdateProductSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(loadProductListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(sendUpdateProductLoading(false));
  } catch (error) {
    yield put(sendUpdateProductLoading(false));
    const massage = common.error
    yield put(loadProductListError(massage))
  }
}