import { put, call } from 'redux-saga/effects';
import {
  getDashboadDetails, getPurchaseOrderSummary, getRatingSummary, getSalesSummary,
} from '../service/dashboardService';
import * as actions from '../../store/action/dashboardAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadDashboardDetailsRequest(){
    // console.log('loadDashboardDetailsRequest')
    yield put(actions.loadDashboardError(''))
    try{
      yield put(actions.loadDashboardLoading(true));
      const response = yield call(getDashboadDetails);
      if(response && response.status && response.status === 200 && response.data  && response.data.id===0){
        // console.log(response.data)
        const customerList = response.data
        yield put(actions.loadDashboardSuccess(customerList))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadDashboardLoading(false));
        const massage = common.error
        yield put(actions.loadDashboardError(massage))
      }
      if(response && response.data && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadDashboardLoading(false));
    }catch(error){
      yield put(actions.loadDashboardLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadDashboardError(massage))
    }
  }

  export function* loadSalesSummaryRequest(){
    // console.log('loadSalesSummaryRequest')
    yield put(actions.loadDashboardError(''))
    try{
      yield put(actions.loadDashboardLoading(true));
      const response = yield call(getSalesSummary);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
        const summary = response.data.result.result
        yield put(actions.loadSalesSummarySuccess(summary))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadDashboardLoading(false));
        const massage = common.error
        yield put(actions.loadDashboardError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(actions.loadDashboardLoading(false));
    }catch(error){
      yield put(actions.loadDashboardLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadDashboardError(massage))
    }
  }

  export function* loadRatingSummaryRequest(){
    // console.log('loadRatingSummaryRequest')
    yield put(actions.loadDashboardError(''))
    try{
      yield put(actions.loadDashboardLoading(true));
      const response = yield call(getRatingSummary);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
        const summary = response.data.result.result
        yield put(actions.loadRatingSummarySuccess(summary))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadDashboardLoading(false));
        const massage = common.error
        yield put(actions.loadDashboardError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(actions.loadDashboardLoading(false));
    }catch(error){
      yield put(actions.loadDashboardLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadDashboardError(massage))
    }
  }

  export function* loadPurchaseOrderSummaryRequest(){
    // console.log('loadPurchaseOrderSummaryRequest')
    yield put(actions.loadDashboardError(''))
    try{
      yield put(actions.loadDashboardLoading(true));
      const response = yield call(getPurchaseOrderSummary);
      if(response && response.status && response.status === 200 ){
        // console.log(response.data)
        const summary = response.data
        yield put(actions.loadPurchaseOrderSummarySuccess(summary))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadDashboardLoading(false));
        const massage = common.error
        yield put(actions.loadDashboardError(massage))
      }
      if(response && response.data  && response.data.newAccessToken){
        refeshTokenset(response.data.newAccessToken)
      }
      yield put(actions.loadDashboardLoading(false));
    }catch(error){
      yield put(actions.loadDashboardLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadDashboardError(massage))
    }
  }