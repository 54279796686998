import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewReminderRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
      return axios.post(baseURL+'reminders', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
      })
}

export function getReminderList(parameters){
  return axios.get(baseURL+'reminders?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&FromDate='+parameters.fromDate+'&ToDate='+parameters.toDate, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function deleteReminderRequest(id){
  return axios.delete(baseURL+'reminders/'+id, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function updateReminderRequest(data){
  const parameters = JSON.stringify(data)
  console.log(parameters)
  return axios.patch(baseURL+'reminders/'+data.id, parameters, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function getUnconfirmedReminderRequest(){
  return axios.get(baseURL+'reminders/UnconfirmedReminder', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}