import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
// import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';

export class ServiceAreaModal extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            postalCode: '',
            cityName: '',
            id:'',
            initial: true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedRow,isUpdateArea } = prevProps;
        if(isUpdateArea && selectedRow && selectedRow.id >= 0 && prevState.initial){
            return {
                postalCode: selectedRow.postalCode,
                cityName: selectedRow.cityName,
                index: selectedRow.index,
                id:selectedRow.id,
                initial:false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnBlur = (e) => {
        this.setState({
            [e.target.id]: parseFloat(e.target.value).toFixed(2)
        })
    }

    // handleChangeUOM = (value) =>{
    //     this.setState({unitOfMeasure:value})
    //   }
    
  
    render() {
        const {
            isAddNewServiceAreaModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            // repairCategoryList,
            // objectStatusList,
            // uomList,
            // selectedRow
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewServiceAreaModalOpen} 
                    onHide={handleModalClose}
                    title="Add Service Area"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"25px"}}>
                                <div>
                                    <TextField
                                        text="Postal Code"
                                        controlId="postalCode"
                                        placeholder="Postal Code"
                                        type="number"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.postalCode}
                                        maxLength={common.maxLengths.postCode}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        text="City"
                                        controlId="cityName"
                                        placeholder="City"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.cityName}
                                        maxLength={common.maxLengths.cityName}
                                    /> 
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Add'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

ServiceAreaModal.propTypes = {
    isAddNewServiceAreaModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    saveDetails: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    repairCategoryList: PropTypes.any,
    uomList: PropTypes.any,
    objectStatusList: PropTypes.any,
    selectedRow: PropTypes.any
}

export default ServiceAreaModal;