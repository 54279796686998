import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/notificationSelector';
import * as actions from '../../../store/action/notificationAction';
import PropTypes from 'prop-types';
import Loader from '../../layout/form/Loader';
import ErrorModal from '../../layout/form/errorModal';
import Button from '../../layout/form/Button';
import moment from 'moment';
import common from '../../common';
import parse from 'html-react-parser';
import ButtonGroup from '../../layout/form/ButtonGroup';
// import { Card } from 'react-bootstrap';
import Avatar from 'react-avatar';
import DatePicker from '../../layout/form/datePicker';
import SelectField from '../../layout/form/SelectField';
import TextField from '../../layout/form/TextField';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)
class EmailNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectedView: false,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      selectedEmail: '',
      RegNo: '',
      ObjectType: '',
      isFilterFormOpen: false,
      selectedEmailSubject: '',
      selectedReceiver: '',
      selectedReceivedDate: '',
      selectedReceiverEmail: '',
      selectedReceiverRegNo: '',
      initial: true
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const { notificationList } = prevProps;
    const { initial } = prevState;

    if (notificationList && notificationList.result && notificationList.result.length && notificationList.result.length > 0 && initial) {
      const notification = notificationList.result[0]
      return {
        selectedEmail: decodeURIComponent(notification.emailBody),
        selectedEmailSubject: notification.emailSubject,
        selectedReceiver: notification.customerDetails.displayName,
        selectedReceivedDate: notification.sentDate,
        selectedReceiverEmail: notification.customerDetails.emailAddress,
        selectedReceiverRegNo: notification.vehicleDetials.vehicleRegistrationNumber,
        initial: false
      }
    }
    return null
  }

  getNotificationListData = () => {
    this.props.dispatch(actions.loadNotificationListSuccess([]))
    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      ToDate: this.state.ToDate,
      FromDate: this.state.FromDate,
      RegNo: this.state.RegNo,
      ObjectType: this.state.ObjectType,
    }
    this.props.dispatch(actions.loadNotificationListRequest(parameters))
    this.setState({
      initial: true
    })
  }

  setDateFormat = (value) => {
    const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
    const time = moment(value).format('HH:mm');
    return date + " - " + time
  }

  selectEmail = (data) => {
    this.setState({
      selectedEmail: decodeURIComponent(data.emailBody),
      selectedEmailSubject: data.emailSubject,
      selectedReceiver: data.customerDetails.displayName,
      selectedReceivedDate: data.sentDate,
      selectedReceiverEmail: data.customerDetails.emailAddress,
      selectedReceiverRegNo: data.vehicleDetials.vehicleRegistrationNumber,
    })

  }

  errorModalClose = () => {
    this.props.dispatch(actions.loadNotificationListError(''))
  }

  handleView = () => {
    this.setState({
      isSelectedView: true
    })
  }

  handleDateChange = (date, id) => {
    if (date === null && id === "FromDate") {
      this.setState({
        [id]: moment(prevDate).format(common.dateFormat),
      })
    }
    else if (date === null && id === "ToDate") {
      this.setState({
        [id]: moment(today).format(common.dateFormat),
      })
    } else {
      this.setState({
        [id]: moment(date).format(common.dateFormat),
      })
    }
  }

  handleChangeSelect = (value, id) => {
    this.setState({
      [id]: value
    })
  }


  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleFilterForm = () => {
    this.setState({
      isFilterFormOpen: !this.state.isFilterFormOpen,
      ToDate: this.state.ToDate,
      FromDate: this.state.FromDate,
      RegNo: this.state.RegNo,
      ObjectType: this.state.ObjectType,
    })
  }

  handleFilter = () => {
    this.getNotificationListData()
    this.handleFilterForm()
  }

  handleFilterFormClose = () => {
    this.setState({
      isFilterFormOpen: false,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      selectedEmail: '',
      RegNo: '',
      ObjectType: '',
      pageSize: 20,
      pageNumber: 1,
      selectedEmailSubject: '',
      selectedReceiver: '',
      selectedReceivedDate: '',
      selectedReceiverEmail: '',
      selectedReceiverRegNo: '',
      initial: true
    })

    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      FromDate: moment(prevDate).format(common.dateFormat),
      ToDate: moment(today).format(common.dateFormat),
      selectedEmail: '',
      RegNo: '',
      ObjectType: '',
    }
    this.props.dispatch(actions.loadNotificationListRequest(parameters))
  }

  componentDidMount() {
    this.getNotificationListData()
  }

  render() {
    const {
      isNotificationListLoading,
      notificationList,
      notificationListError
    } = this.props;

    const {
      selectedEmail,
      selectedEmailSubject,
      selectedReceiver,
      selectedReceivedDate,
      selectedReceiverEmail,
      selectedReceiverRegNo,
      isFilterFormOpen
    } = this.state;


    return (
      <div>
        {(isNotificationListLoading ||
          (notificationList && notificationList.length && notificationList.length === 0)) ?
          <Loader show={isNotificationListLoading} /> : ''}

        {notificationListError !== '' &&
          <ErrorModal
            show={true}
            massage={notificationListError}
            handleClose={this.errorModalClose}
          />}
        <div>
          <div className="content-header" style={{ marginTop: '-50px' }}>
            <div className="header-pages">

            </div>
            <div className="header-btn">
              <Button
                onClick={() => this.handleFilterForm()}
                text='Filter'
                variant="outline-primary"
                icon='filter'
              />
            </div>
          </div>
          {isFilterFormOpen ?
            <div className="filter-form-notification">
              <div>
                <div className="row">
                  <div className="col-sm">
                    <TextField
                      type="text"
                      onChange={this.handleChange}
                      defaultValue={this.state.RegNo}
                      text="Vehicle registration no"
                      controlId="RegNo"
                    />
                  </div>
                  <div className="col-sm">
                    <SelectField
                      text="Type"
                      controlId="ObjectType"
                      options={common.emailObjectType}
                      selectedValue={this.state.ObjectType}
                      onChange={(e) => this.handleChangeSelect(e, "ObjectType")}
                      isClearable
                    />
                  </div>
                  <div className="col-sm">
                    <DatePicker
                      text="From Date"
                      controlId="FromDate"
                      onChange={(e) => this.handleDateChange(e, "FromDate")}
                      value={new Date(this.state.FromDate)}
                    // format={dateFormat}
                    />
                  </div>
                  <div className="col-sm">
                    <DatePicker
                      text="To Date"
                      controlId="ToDate"
                      onChange={(e) => this.handleDateChange(e, "ToDate")}
                      value={new Date(this.state.ToDate)}
                    // format={dateFormat}
                    />
                  </div>
                </div>
              </div>
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleFilter}
                  primaryBtnIcon='search'
                  primaryBtnText='Search'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleFilterFormClose}
                  secondaryBtnIcon='clear-filter'
                  secondaryBtnText='Clear'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </div> : ''}
          <div style={{ margin: '2px', marginTop: '5px', display: 'flex' }}>
            <div className="notification-list">
              <div className="notification-header">
                <ui5-icon class="samples-margin" name="email" id="tab-icon"></ui5-icon>
                <h6>Notification List</h6>
              </div>
              {notificationList.result && notificationList.result.length ? notificationList.result.map(notification => (
                <div className="notification-card" onClick={() => this.selectEmail(notification)}>
                  <div className="notification-card-avatar">
                    <Avatar name={notification.customerDetails.displayName} size={40} round="50%" maxInitials={2} />
                  </div>
                  <div className="notification-card-content">
                    <div style={{ fontWeight: 600 }}>
                      {notification.emailSubject && notification.emailSubject.length > 20 ? notification.emailSubject.substring(0, 15) + " ....." : notification.emailSubject}
                    </div>
                    <div>{notification.customerDetails.displayName}</div>
                    <small className="mb-2 text-muted">{this.setDateFormat(notification.sentDate)}</small>
                  </div>
                  <div className="status-dot" style={notification.status === "Successful" ? { backgroundColor: 'green' } : { backgroundColor: 'red' }}>
                  </div>
                </div>
              )) : ''}
            </div>
            {selectedEmailSubject ?
              <div className="notification-view">
                <div style={{ borderBottom: '1px solid #969696' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', margin: '5px' }}>
                      <div style={{ marginRight: '10px' }}>
                        <Avatar name={selectedReceiver} size={40} round="50%" maxInitials={2} />
                      </div>
                      <div>
                        <div>
                          <span style={{ fontWeight: 600 }}>To :  </span> {selectedReceiver}
                          {selectedReceiverRegNo ? ` (Vehicle Registration No : ${selectedReceiverRegNo} )` : ''}
                        </div>
                        <div className="mb-2 text-muted"> {`<${selectedReceiverEmail}>`}</div>
                      </div>
                    </div>
                    <div className="mb-2 text-muted">{selectedReceivedDate ? this.setDateFormat(selectedReceivedDate) : ''}</div>
                  </div>
                  <div style={{ fontWeight: 600 }}>{selectedEmailSubject}</div>
                </div>
                {parse(selectedEmail)}
              </div> : ''}
          </div>
        </div>
      </div>
    )
  }
}

EmailNotification.propTypes = {
  notificationListError: PropTypes.any,
  isNotificationListLoading: PropTypes.any,
  notificationList: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  notificationListError: selectors.makeSelectNotificationListError(),
  isNotificationListLoading: selectors.makeSelectNotificationListLoading(),
  notificationList: selectors.makeSelectNotificationList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default (compose(withConnect)(EmailNotification));
