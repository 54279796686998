import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import './NavBar.css';
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import * as selectors from '../../../store/selector/navBarSelector';
import * as actions from '../../../store/action/navBarAction';
import { saveLoginUser } from '../../../store/action/authAction';
import SideBarFull from './SideBarFull';
import SideBarIcon from './SideBarIcon';
import CustomMenu from './CustomMenu';
import VemasLogo from '../../../images/VEMASLOGO.png';
import UserMenu from './UserMenu';
import Cookies from 'js-cookie';
// import { loadCustomerListSuccess } from '../../../store/action/customerAction';
// import { loadSelectedVehicleSuccess, loadVehicleListSuccess } from '../../../store/action/vehicleAction';
// import { loadPartListSuccess } from '../../../store/action/partAction';
// import { loadMakeListSuccess } from '../../../store/action/makeAction';
// import { loadModelListSuccess } from '../../../store/action/modelAction';
// import { loadRepairListSuccess } from '../../../store/action/repairAction';
// import { loadInvoiceListSuccess } from '../../../store/action/invoiceAction';
// import { loadJobCardListSuccess } from '../../../store/action/jobCardAction';
// // import { makeSelectTenantBasic } from '../../../store/selector/tenantSelector';
// import { loadSelectedQuotationSuccess } from '../../../store/action/quotationAction';
// import { makeSelectTenantUser } from '../../../store/selector/selector';
import User from '../../../images/user.png';
import Image from 'react-bootstrap/Image';
import { loadPartListSuccess } from '../../../store/action/partAction';
import { loadProductListSuccess } from '../../../store/action/productAction';
import { makeSelectTenantUser } from '../../../store/selector/selector';
import { makeSelectUserAccessSuccess } from '../../../store/selector/usersSelector';

// var settingMenuClose = false;
//  window.addEventListener('click', function(e){   
//     if (document.getElementById('settingMenu').contains(e.target)){
//       settingMenuClose = false;
//     } else{
//       settingMenuClose = true;
//     }
//   });

class Navbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      settingMenu: false,
      userMenu: false,
      // activeRoute: window.location.hash
    };
    this.redirectCustomer = this.redirectCustomer.bind(this);
  };

  static getDerivedStateFromProps(prevProps, prevState) {
    const { isSideBarStatus } = prevProps;
    return {
      sidebar: isSideBarStatus,
    }
  }

  showSidebar = () => {
    const sidebarStaus = !this.state.sidebar;
    this.setState({ sidebar: sidebarStaus });
    this.props.dispatch(actions.saveIsSideBarStatus(sidebarStaus));
  }

  showSettingMenu = () => {
    const settingMenuStatus = this.state.settingMenu;
    this.setState({ settingMenu: !settingMenuStatus })
  }

  showUserMenu = () => {
    const userMenuStatus = this.state.userMenu;
    this.setState({ userMenu: !userMenuStatus })
  }

  handleSignOut = () => {
    Cookies.remove("user")
    this.props.dispatch(saveLoginUser(false))
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }

  settingMenuClose = () => {
    this.setState({
      settingMenu: false
    })
  }

  userMenuClose = () => {
    this.setState({
      userMenu: false
    })
  }

  componentDidMount() {
    this.props.dispatch(actions.saveActiveRoute(window.location.hash))
  }

  redirectCustomer = () => {
    if (window.location.hash === '#/customer') {
      return
    }
    // this.setState({
    //   activeRoute:'#/customer'
    // })
    this.props.dispatch(actions.saveActiveRoute('#/customer'))
    // this.props.dispatch(loadCustomerListSuccess([]))
    // this.props.dispatch(loadJobCardListSuccess([]))
    // this.props.dispatch(loadInvoiceListSuccess([]))
    // this.props.dispatch(loadVehicleListSuccess([]))
  }

  redirectVehicle = () => {
    if (window.location.hash === '#/vehicle') {
      return
    }
    // this.setState({
    //   activeRoute:'#/vehicle'
    // })
    this.props.dispatch(actions.saveActiveRoute('#/vehicle'))
    // this.props.dispatch(loadVehicleListSuccess([]))
    // this.props.dispatch(loadCustomerListSuccess([]))
    // this.props.dispatch(loadJobCardListSuccess([]))
    // this.props.dispatch(loadInvoiceListSuccess([]))
    // this.props.dispatch(loadSelectedQuotationSuccess(''))
    // this.props.dispatch(loadSelectedVehicleSuccess(''))
  }

  redirectStock = () => {
    if (window.location.hash === '#/stock') {
      return
    }
    this.props.dispatch(loadPartListSuccess([]))
    this.props.dispatch(loadProductListSuccess([]))
    this.props.dispatch(actions.saveActiveRoute('#/stock'))
  }

  redirectPart = () => {
    if (window.location.hash === '#/parts') {
      return
    }
    // this.props.dispatch(loadPartListSuccess([]))
    // // this.setState({
    // //   activeRoute:''
    // // })
    this.props.dispatch(actions.saveActiveRoute(''))
  }

  redirectMake = () => {
    if (window.location.hash === '#/make') {
      return
    }
    // this.props.dispatch(loadMakeListSuccess([]))
    // // this.setState({
    // //   activeRoute:''
    // // })
    this.props.dispatch(actions.saveActiveRoute(''))
  }

  redirectModel = () => {
    if (window.location.hash === '#/model') {
      return
    }
    // this.props.dispatch(loadModelListSuccess([]))
    // // this.setState({
    // //   activeRoute:''
    // // })
    this.props.dispatch(actions.saveActiveRoute(''))
  }

  redirectRepair = () => {
    if (window.location.hash === '#/repair') {
      return
    }
    // this.props.dispatch(loadRepairListSuccess([]))
    // // this.setState({
    // //   activeRoute:''
    // // })
    this.props.dispatch(actions.saveActiveRoute(''))
  }

  redirectDashboard = () => {
    // this.setState({
    //   activeRoute:'#/dashboard'
    // })
    this.props.dispatch(actions.saveActiveRoute('#/dashboard'))
  }

  redirectCalendar = () => {
    // this.setState({
    //   activeRoute:'#/calendar'
    // })
    this.props.dispatch(actions.saveActiveRoute('#/calendar'))
  }

  redirectSupplier = () => {
    // this.setState({
    //   activeRoute:'#/supplier'
    // })
    this.props.dispatch(actions.saveActiveRoute('#/supplier'))
  }

  redirectNotification = () => {
    this.props.dispatch(actions.saveActiveRoute('#/notification'))
  }

  redirectReport = () => {
    this.props.dispatch(actions.saveActiveRoute('#/SalesReports'))
  }

  redirectAnalytics = () => {
    this.props.dispatch(actions.saveActiveRoute('#/analytics'))
  }

  // redirectNotification = () => {
  //   this.props.dispatch(actions.saveActiveRoute('#/analytics'))
  // }

  render() {
    const { tenantUser, activeRoute,userAccess } = this.props;
    // const { activeRoute } = this.state;
    return (
      <div>
        <header>
          <div className="nav_left" >
            <div className="navlogo">
              <img alt="vemaslogo" id="navlogo" style={this.state.sidebar ? { width: '100%', marginLeft: '30%' } : { width: '50px' }} src={VemasLogo} />
            </div>
            <div className="sidebar-menu" onClick={this.showSidebar}>
              <ui5-icon class="samples-margin" name="menu2" style={this.state.sidebar ? { margin: '2% 2% 2% 500%' } : { margin: '20px' }} id="icon-menu"></ui5-icon>
            </div>
          </div>
          <div className="nav_right">
            <ui5-icon class="samples-margin" name="bell" id="icon"></ui5-icon>
            <div onClick={this.showSettingMenu} id="settingMenu">
              <ui5-icon class="samples-margin" name="settings" id="icon"></ui5-icon>
              {this.state.settingMenu &&
                <CustomMenu
                  settingMenuClose={this.settingMenuClose}
                  redirectPart={this.redirectPart}
                  redirectMake={this.redirectMake}
                  redirectModel={this.redirectModel}
                  redirectRepair={this.redirectRepair}
                  userDisplayAccess={userAccess && userAccess.displayUser}
                />
              }
            </div>
            <ui5-icon class="samples-margin" name="sys-help" id="icon"></ui5-icon>
            <div onClick={this.showUserMenu}>
              {/* <ui5-icon class="samples-margin" name="customer" id="icon"></ui5-icon> */}
              <Image roundedCircle className="userImage" src={tenantUser && tenantUser.id && tenantUser.logo ? tenantUser.logo : User} />
              {this.state.userMenu &&
                <UserMenu
                  handleSignOut={this.handleSignOut}
                  userMenuClose={this.userMenuClose}
                  tenantImage={tenantUser && tenantUser.id ? tenantUser.logo : ''}
                  tenantName={tenantUser && tenantUser.id ? tenantUser.firstName + " " + tenantUser.lastName : ''}
                />}
            </div>
          </div>
        </header>
        {this.state.sidebar ?
          <SideBarFull
            redirectCustomer={this.redirectCustomer}
            redirectVehicle={this.redirectVehicle}
            activeRoute={activeRoute}
            redirectDashboard={this.redirectDashboard}
            redirectCalendar={this.redirectCalendar}
            redirectSupplier={this.redirectSupplier}
            redirectNotification={this.redirectNotification}
            redirectStock={this.redirectStock}
            redirectReport={this.redirectReport}
            redirectAnalytics={this.redirectAnalytics}
          /> :
          <SideBarIcon
            redirectCustomer={this.redirectCustomer}
            redirectVehicle={this.redirectVehicle}
            activeRoute={activeRoute}
            redirectDashboard={this.redirectDashboard}
            redirectCalendar={this.redirectCalendar}
            redirectSupplier={this.redirectSupplier}
            redirectNotification={this.redirectNotification}
            redirectStock={this.redirectStock}
            redirectReport={this.redirectReport}
            redirectAnalytics={this.redirectAnalytics}
          />}
      </div>
    )
  }
}

Navbar.propTypes = {
  isSideBarStatus: PropTypes.bool,
  tenantUser: PropTypes.any,
  activeRoute: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
  isSideBarStatus: selectors.makeSelectSideBarStatus(),
  tenantUser: makeSelectTenantUser(),
  activeRoute: selectors.makeSelectActiveRoute(),
  userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Navbar));