import { put, call } from 'redux-saga/effects';
import {
  getAccountCustomerRequest,
  getAccountProductCategoryRequest, getMappingAccountRequest, getMappingCustomerRequest, getMappingProductCategoryRequest, sendMappingAccountRequest, sendMappingCustomerRequest, sendMappingProductCategoryRequest
} from '../service/accountProductCategoryService';
import * as actions from '../../store/action/accountProductCategoryAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadAccountProductCategoryRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    // yield put(actions.loadAccountProductCategoryLoading(true));
    const response = yield call(getAccountProductCategoryRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadAccountProductCategorySuccess(system))
      //yield put(actions.saveIsAccountProductCategory(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      // yield put(actions.loadAccountProductCategoryLoading(false));
      const massage = common.error
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadAccountProductCategoryLoading(false));
  } catch (error) {
    // yield put(actions.loadAccountProductCategoryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}


export function* loadMappingProductCategoryRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    yield put(actions.loadAccountProductCategoryLoading(true));
    const response = yield call(getMappingProductCategoryRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadMappingProductCategorySuccess(system))
      yield put(actions.saveIsAccountProductCategory(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAccountProductCategoryLoading(false));
      const massage = common.error
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountProductCategoryLoading(false));
  } catch (error) {
    yield put(actions.loadAccountProductCategoryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}

export function* sendUpdateMappingProductCategoryRequest(data) {
  // console.log('sendUpdateCustomerRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    // yield put(actions.sendUpdateCustomerLoading(true));
    const response = yield call(sendMappingProductCategoryRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendMappingProductCategorySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateCustomerLoading(false));
  } catch (error) {
    // yield put(actions.sendUpdateCustomerLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}


export function* loadAccountCustomerRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    // yield put(actions.loadAccountProductCategoryLoading(true));
    const response = yield call(getAccountCustomerRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadAccountCustomerSuccess(system))
      //yield put(actions.saveIsAccountProductCategory(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      // yield put(actions.loadAccountProductCategoryLoading(false));
      const massage = common.error
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadAccountProductCategoryLoading(false));
  } catch (error) {
    // yield put(actions.loadAccountProductCategoryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}


export function* loadMappingCustomerRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    yield put(actions.loadAccountProductCategoryLoading(true));
    const response = yield call(getMappingCustomerRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result
      yield put(actions.loadMappingCustomerSuccess(system))
      yield put(actions.saveIsAccountCustomerStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAccountProductCategoryLoading(false));
      const massage = common.error
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountProductCategoryLoading(false));
  } catch (error) {
    yield put(actions.loadAccountProductCategoryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}

export function* sendUpdateMappingCustomerRequest(data) {
  // console.log('sendUpdateCustomerRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    // yield put(actions.sendUpdateCustomerLoading(true));
    const response = yield call(sendMappingCustomerRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendMappingProductCategorySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateCustomerLoading(false));
  } catch (error) {
    // yield put(actions.sendUpdateCustomerLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}

export function* loadMappingAccountRequest(data) {
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    yield put(actions.loadAccountProductCategoryLoading(true));
    const response = yield call(getMappingAccountRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadMappingAccountSuccess(system))
      yield put(actions.saveIsAccountProductCategory(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAccountProductCategoryLoading(false));
      const massage = common.error
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountProductCategoryLoading(false));
  } catch (error) {
    yield put(actions.loadAccountProductCategoryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}

export function* sendUpdateMappingAccountRequest(data) {
  // console.log('sendUpdateCustomerRequest')
  yield put(actions.loadAccountProductCategoryError(''))
  try {
    // yield put(actions.sendUpdateCustomerLoading(true));
    const response = yield call(sendMappingAccountRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendMappingProductCategorySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateCustomerLoading(false));
  } catch (error) {
    // yield put(actions.sendUpdateCustomerLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadAccountProductCategoryError(massage))
  }
}
