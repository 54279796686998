import { fromJS } from 'immutable';
import {
  LOAD_NOTIFICATION_LIST_ERROR,
  LOAD_NOTIFICATION_LIST_LOADING,
  LOAD_NOTIFICATION_LIST_SUCCESS,
  LOAD_SMS_NOTIFICATION_LIST_ERROR,
  LOAD_SMS_NOTIFICATION_LIST_LOADING,
  LOAD_SMS_NOTIFICATION_LIST_SUCCESS,
} from '../constant/notificationConstant';

export const initialState = fromJS({
    isAddNewNotificationModalOpen:false,
    notificationList:[],
    isNotificationListLoading:false,
    notificationListError:'',
    smsList:[],
    isSMSNotificationListLoading: false,
    smsListError:''
});

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_NOTIFICATION_LIST_SUCCESS:
      return state.set('notificationList', action.payload)
    case LOAD_NOTIFICATION_LIST_LOADING:
      return state.set('isNotificationListLoading', action.payload)
    case LOAD_NOTIFICATION_LIST_ERROR:
      return state.set('notificationListError', action.payload)
    case LOAD_SMS_NOTIFICATION_LIST_SUCCESS:
      return state.set('smsList', action.payload)
    case LOAD_SMS_NOTIFICATION_LIST_LOADING:
      return state.set('isSMSNotificationListLoading', action.payload)
    case LOAD_SMS_NOTIFICATION_LIST_ERROR:
      return state.set('smsListError', action.payload)
    default:
      return state;
  }
}

export default notificationReducer;
