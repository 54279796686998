import { createSelector } from 'reselect';

const selectEmailTemplateDomain = state => state.emailTemplate;

const makeSelectEmailTemplatePopupOpen =() =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('isAddNewEmailTemplateOpen') : false
  );


const makeSelectEmailTemplateList = () =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('emailTemplateList') : []
  );

const makeSelectEmailTemplateListLoading = () =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('isEmailTemplateListLoading') : false
  );

const makeSelectEmailTemplateListError = () =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('emailTemplateListError') : null
  );

const makeSelectEmailTemplateSuccess = () =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('emailTemplateSuccess') : null
  );

const makeSelectSelectedEmailTemplateSuccess = () =>
  createSelector(
    selectEmailTemplateDomain,
    emailTemplateState => 
      emailTemplateState ? emailTemplateState.get('selectedEmailTemplate') : {}
  );

export {
  makeSelectEmailTemplatePopupOpen,
  makeSelectEmailTemplateList,
  makeSelectEmailTemplateListLoading,
  makeSelectEmailTemplateListError,
  makeSelectEmailTemplateSuccess,
  makeSelectSelectedEmailTemplateSuccess
}
