import { put, call } from 'redux-saga/effects';
import {
  addNewSMSTemplatesRequest,
  getSMSTemplatesList,
  updateSMSTemplatesRequest,
  deleteSMSTemplatesRequest,
  // getSelectedSMSTemplateRequest
} from '../service/smsTempService';
import * as actions from '../../store/action/smsTempAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewSMSTemplateRequest(data){
  yield put(actions.loadSMSTemplateListError(null))
  try{
    const response = yield call(addNewSMSTemplatesRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const massage = response.data.message
      yield put(actions.sendSMSTemplateSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.result
      yield put(actions.loadSMSTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage  =common.error
    yield put(actions.loadSMSTemplateListError(massage))
  }
}

export function* loadSMSTemplateListRequest(data){
  yield put(actions.loadSMSTemplateListError(null))
  try{
    yield put(actions.loadSMSTemplateListLoading(true));
    const response = yield call(getSMSTemplatesList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const smsTemplateList = response.data.result
      yield put(actions.loadSMSTemplateListSuccess(smsTemplateList))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadSMSTemplateListLoading(false));
      const massage =common.error
      yield put(actions.loadSMSTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadSMSTemplateListLoading(false));
  }catch(error){
    yield put(actions.loadSMSTemplateListLoading(false));
    const massage =common.error
    yield put(actions.loadSMSTemplateListError(massage))
  }
}

export function* sendUpdateSMSTemplateRequest(data){
  yield put(actions.loadSMSTemplateListError(null))
  try{
    const response = yield call(updateSMSTemplatesRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const massage = response.data.message
      yield put(actions.sendSMSTemplateSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadSMSTemplateListLoading(false));
      const massage = response.data.message
      yield put(actions.loadSMSTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage  =common.error
    yield put(actions.loadSMSTemplateListError(massage))
  }
}

export function* sendDeleteSMSTemplateRequest(data){
  yield put(actions.loadSMSTemplateListError(null))
  try{
    yield put(actions.loadSMSTemplateListLoading(true));
    const response = yield call(deleteSMSTemplatesRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const massage = response.data.message
      yield put(actions.sendSMSTemplateSuccess(massage)) 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadSMSTemplateListLoading(false));
      const massage =common.error
      yield put(actions.loadSMSTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadSMSTemplateListLoading(false));
  }catch(error){
    yield put(actions.loadSMSTemplateListLoading(false));
    const massage  =common.error
    yield put(actions.loadSMSTemplateListError(massage))
  }
}
