import { createSelector } from 'reselect';

const selectPurchasingDomain = state => state.purchasing;

const makeSelectPurchasingPopupOpen =() =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('isAddNewPurchasingModalOpen') : false
  );

const makeSelectPurchasingList = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('purchasingList') : []
  );

const makeSelectPurchasingListLoading = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('isPurchasingListLoading') : false
  );

const makeSelectPurchasingListError = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('purchasingListError') : ''
  );

const makeSelectPurchasingSuccess = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('purchasingSuccess') : ''
  );

const makeSelectPurchasingPrintSuccess = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
      purchasingState ? purchasingState.get('purchasingPrint') : ''
  );

const makeSelectSelectedPurchasingSuccess = () =>
  createSelector(
    selectPurchasingDomain,
    purchasingState => 
    purchasingState ? purchasingState.get('selectedPurchasing') : {}
  );

export {
    makeSelectPurchasingPopupOpen,
    makeSelectPurchasingList,
    makeSelectPurchasingListLoading,
    makeSelectPurchasingListError,
    makeSelectPurchasingSuccess,
    makeSelectPurchasingPrintSuccess,
    makeSelectSelectedPurchasingSuccess,
}
