import { put, call } from 'redux-saga/effects';
import {
  getItemCategoryList,
  addNewItemRequest,
  getItemList,
  updateItemRequest,
  deleteItemRequest
} from '../service/itemService';
import { 
  loadItemCategoryListSuccess,
  sendAddNewItemError,
  sendAddNewItemLoading,
  sendAddNewItemSuccess,
  loadItemListError,
  loadItemListLoading,
  loadItemListSuccess,
  sendUpdateItemError,
  sendUpdateItemLoading,
  sendUpdateItemSuccess,
  sendDeleteItemError,
  // sendDeleteItemLoading
} from '../../store/action/itemAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';

export function* sendAddNewItemRequest(data){
    // console.log('sendAddNewItemRequest')
    yield put(sendAddNewItemError(''))
    try{
      yield put(sendAddNewItemLoading(true));
      const response = yield call(addNewItemRequest,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
        // console.log(response.data.message)
        const massage = response.data.message
        yield put(sendAddNewItemSuccess(massage))
      } 
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else {
        const massage = response.data.message
        yield put(sendAddNewItemError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(sendAddNewItemLoading(false));
    }catch(error){
      yield put(sendAddNewItemLoading(false));
      // console.log(error.response.data.detail)
      const massage  =common.error
      yield put(sendAddNewItemError(massage))
    }
  }
  
  export function* loadItemListRequest(data){
    // console.log('loadItemListRequest')
    yield put(loadItemListError(''))
    try{
      yield put(loadItemListLoading(true));
      const response = yield call(getItemList,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
        const itemList = response.data.result
        yield put(loadItemListSuccess(itemList))
      } 
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(loadItemListLoading(false));
        const massage  =common.error
        yield put(sendDeleteItemError(massage))
      } 
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(loadItemListLoading(false));
    }catch(error){
      yield put(loadItemListLoading(false));
      // console.log(error)
      const massage  =common.error
        yield put(sendDeleteItemError(massage))
      // const massage = error.response.data.detail
      // yield put(loadItemListError(massage))
    }
  }
  
  export function* sendUpdateItemRequest(data){
    // console.log('sendUpdateItemRequest')
    yield put(sendUpdateItemError(''))
    try{
      yield put(sendUpdateItemLoading(true));
      const response = yield call(updateItemRequest,data.payload);
      if(response && response.status && response.status === 200 ){
        // console.log(response.data.result)
        const massage = response.data.message
        yield put(sendUpdateItemSuccess(massage))
      } 
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        const massage = response.data.message
        yield put(sendUpdateItemError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(sendUpdateItemLoading(false));
    }catch(error){
      yield put(sendUpdateItemLoading(false));
      // console.log(error.response)
      const massage  =common.error
      yield put(sendUpdateItemError(massage))
    }
  }
  
  export function* sendDeleteItemRequest(data){
    // console.log('sendDeleteItemRequest')
    yield put(sendDeleteItemError(''))
    try{
      yield put(loadItemListLoading(true));
      const response = yield call(deleteItemRequest,data.payload);
      if(response && response.status && response.status === 200 ){
        // console.log(response.data.result)
        const massage = response.data.message
        yield put(sendUpdateItemSuccess(massage)) // TODO
      } 
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      } 
      else{
        yield put(loadItemListLoading(false));
        const massage  =common.error
        yield put(sendDeleteItemError(massage))
      } 
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(loadItemListLoading(false));
    }catch(error){
      yield put(loadItemListLoading(false));
      // console.log(error.response)
      const massage  =common.error
      yield put(sendDeleteItemError(massage))
    }
  }

  export function* loadItemCategoryListRequest(){
    // console.log('loadItemCategoryListRequest')
  //   yield put(actions.sendAddNewUserError(''))
    try{
      // yield put(actions.sendAddNewUserLoading(true));
      const response = yield call(getItemCategoryList);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
      //   const massage = response.data
        yield put(loadItemCategoryListSuccess(response.data.result.result))
      } 
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      } 
      // yield put(actions.sendAddNewUserLoading(false));
    }catch(error){
      // yield put(actions.sendAddNewUserLoading(false));
      // console.log(error)
    }
  }