import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getAccountProductCategoryRequest(id) {
    return axios.get(baseURL + 'accountIntegration/salesCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingProductCategoryRequest(id) {
    return axios.get(baseURL + 'a2aProductCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingProductCategoryRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'a2aProductCategory', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getAccountCustomerRequest(id) {
    return axios.get(baseURL + 'accountIntegration/customers', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingCustomerRequest(parameters) {
    return axios.get(baseURL + 'a2aCustomer?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingCustomerRequest(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'a2aCustomer', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getMappingAccountRequest(parameters) {
    return axios.get(baseURL + 'a2aAccount', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingAccountRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'a2aAccount', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}