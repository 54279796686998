import { createSelector } from 'reselect';

const selectQuotationDomain = state => state.quotation;

const makeSelectQuotationPopupOpen =() =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
      quotationState ? quotationState.get('isAddNewQuotationModalOpen') : false
  );

const makeSelectQuotationList = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
      quotationState ? quotationState.get('quotationList') : []
  );

const makeSelectQuotationListLoading = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
      quotationState ? quotationState.get('isQuotationListLoading') : false
  );

const makeSelectQuotationListError = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
      quotationState ? quotationState.get('quotationListError') : ''
  );

const makeSelectQuotationSuccess = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
      quotationState ? quotationState.get('quotationSuccess') : ''
  );

const makeSelectSelectedQuotationSuccess = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
    quotationState ? quotationState.get('selectedQuotation') : ''
  );

const makeSelectPrintQuotationSuccess = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
    quotationState ? quotationState.get('quotationPrint') : {}
  );

  const makeSelectEmailQuotationSuccess = () =>
  createSelector(
    selectQuotationDomain,
    quotationState => 
    quotationState ? quotationState.get('quotationEmail') : {}
  );

  const makeSelectQuotationImageEmailError = () =>
    createSelector(
      selectQuotationDomain,
      quotationState => 
        quotationState ? quotationState.get('quotationImageError') : ''
    );
  
  const makeSelectQuotationImageEmailSuccess = () =>
    createSelector(
      selectQuotationDomain,
      quotationState => 
        quotationState ? quotationState.get('quotationImageSuccess') : ''
    );
    
  const makeSelectQuotationImageEmailOpenStatus = () =>
    createSelector(
      selectQuotationDomain,
      quotationState => 
        quotationState ? quotationState.get('isSendQuotationImageEmailModalOpen') : ''
    );

export {
    makeSelectQuotationPopupOpen,
    makeSelectQuotationList,
    makeSelectQuotationListLoading,
    makeSelectQuotationListError,
    makeSelectQuotationSuccess,
    makeSelectSelectedQuotationSuccess,
    makeSelectPrintQuotationSuccess,
    makeSelectEmailQuotationSuccess,
    makeSelectQuotationImageEmailError,
    makeSelectQuotationImageEmailSuccess,
    makeSelectQuotationImageEmailOpenStatus
}
