import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { Link } from 'react-router-dom';
import { makeSelectPaymentTermSuccess } from '../../../../store/selector/invoiceSelector';
import Button from '../../../layout/form/Button';

class Finance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currencyCode: '',
            taxRate: '',
            bankAccountName: '',
            nameOfTheBank: '',
            bSB: '',
            account: '',
            dateFormat: '',
            priceIncludeTax: '',
            currencySymbol: '',
            defaultPaymentTerm: '',
            initial: true,
            errorMassage: '',
            isValidationError: false,
            isChangesFinance: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { tenantFinance } = prevProps;
        if (initial && tenantFinance && tenantFinance.id) {
            return {
                currencyCode: tenantFinance.currencyCode,
                taxRate: tenantFinance.taxRate,
                bankAccountName: tenantFinance.bankAccountName,
                nameOfTheBank: tenantFinance.bankName,
                bSB: tenantFinance.bankStateBranch,
                currencySymbol: tenantFinance.currencySymbol,
                account: tenantFinance.accountNumber,
                defaultPaymentTerm: tenantFinance.defaultPaymentTerm,
                priceIncludeTax: tenantFinance.priceInclusiveOfTax === true ? 'Yes' : 'No',
                initial: false
            }
        }
        return null
    }

    componentDidMount() {
        this.getTenantDetails()
    }

    getTenantDetails = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(actions.loadTenantFinanceSuccess({}))
        this.props.dispatch(actions.loadTenantFinanceRequest(id))
        this.setState({
            isChangesFinance: false
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            isChangesFinance: true
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value,
            isChangesFinance: true
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.getTenantDetails()
        this.props.dispatch(actions.sendTenantDetailsSuccess(''))
        this.setState({
            initial: true
        })
    }

    componentDidUpdate() {
        if (this.props.tenantDetailsSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            paymentTermList,
            tenantDetailsError,
            tenantDetailsSuccess,
            isTenantDetailsLoading,
            handleTab,
            handleSubmit,
            userAccess
        } = this.props;
        return (
            <div>
                {(isTenantDetailsLoading) ?
                    <Loader show={isTenantDetailsLoading} /> : ''}

                {(this.state.isValidationError || tenantDetailsError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage ? this.state.errorMassage : tenantDetailsError}
                        handleClose={this.errorModalClose}
                    />}

                {(tenantDetailsSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={tenantDetailsSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData', this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance', this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Finance</span>
                        <span onClick={() => handleTab('correspondence', this.state)}><ui5-icon class="samples-margin" name="letter" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Correspondence</span>
                        <span onClick={() => handleTab('openingHours', this.state)}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Opening Hours</span>
                        <span onClick={() => handleTab('marketing', this.state)}><ui5-icon class="samples-margin" name="official-service" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Marketing</span>
                    </div>
                </div>
                <div className="profile-content">
                    <div className="row" style={{ margin: '1px' }}>
                        <div className="col-sm page_header">
                            <div className="page_header-title">
                                <span>Control setting</span>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField
                                        text="Date Format"
                                        controlId="dateFormat"
                                        placeholder="Date Format"
                                        type="text"
                                        onChange={this.handleChange}
                                        disabled
                                        defaultValue={this.state.dateFormat}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <TextField
                                        text="Currency code"
                                        controlId="currencyCode"
                                        placeholder="Currency Code"
                                        type="text"
                                        onChange={this.handleChange}
                                        required={true}
                                        defaultValue={this.state.currencyCode}
                                        maxLength={common.maxLengths.currencyCode}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <TextField
                                        text="Currency symbol"
                                        controlId="currencySymbol"
                                        placeholder="Currency symbol"
                                        type="text"
                                        onChange={this.handleChange}
                                        required={true}
                                        defaultValue={this.state.currencySymbol}
                                        maxLength={common.maxLengths.currencySymbol}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">

                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Tax rate (%)"
                                        controlId="taxRate"
                                        placeholder="Tax Rate"
                                        type="number"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.taxRate}
                                        maxLength={common.maxLengths.taxRate}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Price Include Tax"
                                        controlId="priceIncludeTax"
                                        placeholder="Price Include Tax"
                                        type="text"
                                        onChange={this.handleChange}
                                        disabled
                                        defaultValue={this.state.priceIncludeTax}
                                    />
                                </div>
                                <div className="col-sm">
                                    {/* <TextField
                                    text="Default payment term"
                                    controlId="defaultPaymentTerm"
                                    placeholder="Default payment term"
                                    type="select"
                                    onChange={this.handleChange}
                                    options={paymentTermList}
                                    defaultValue={this.state.defaultPaymentTerm}
                                /> */}
                                    <SelectField
                                        text="Default payment term"
                                        controlId="defaultPaymentTerm"
                                        options={paymentTermList}
                                        selectedValue={this.state.defaultPaymentTerm}
                                        onChange={(e) => this.handleSelect(e, 'defaultPaymentTerm')}
                                        required
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Bank Account Name"
                                        controlId="bankAccountName"
                                        placeholder="Bank Account Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.bankAccountName}
                                        required={this.state.nameOfTheBank !== ''}
                                        maxLength={common.maxLengths.bankAccountName}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Name of The Bank"
                                        controlId="nameOfTheBank"
                                        placeholder="Name of The Bank"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.nameOfTheBank}
                                        maxLength={common.maxLengths.nameOfTheBank}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm'>
                                    <TextField
                                        text="BSB"
                                        controlId="bSB"
                                        placeholder="BSB"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.bSB}
                                        required={this.state.nameOfTheBank !== ''}
                                        maxLength={common.maxLengths.bSB}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                                <div className='col-sm'>
                                    <TextField
                                        text="Account"
                                        controlId="account"
                                        placeholder="Account"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.account}
                                        required={this.state.nameOfTheBank !== ''}
                                        maxLength={common.maxLengths.account}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">

                        </div>
                    </div>
                    <div className="btnSave">
                        <Button
                            variant="outline-primary"
                            text='Save'
                            onClick={() => handleSubmit(this.state)}
                            disabled={userAccess && !userAccess.modifyTenant}
                            icon="save"
                        />
                    </div>
                </div>
            </div>
        )
    }
}


Finance.propTypes = {
    tenantDetails: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
    tenantFinance: selectors.makeSelectTenantFinance(),
    paymentTermList: makeSelectPaymentTermSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Finance));
