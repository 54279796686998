import { fromJS } from 'immutable';
import {
  LOAD_A2A_ACCOUNT_SUCCESS,
  LOAD_A2A_CUSTOMER_SUCCESS,
  LOAD_A2A_PRODUCT_CATEGORY_SUCCESS,
  LOAD_ACCOUNT_CUSTOMER_SUCCESS,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS,
  SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
  SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY,
  SEND_A2A_PRODUCT_CATEGORY_SUCCESS,
} from '../constant/accountProductCategoryConstant';

export const initialState = fromJS({
  accountProductCategoryList: [],
  isAccountProductCategoryLoading: false,
  accountProductCategoryError: '',
  isAccountProductCategoryList: false,
  mappingProductCategoryList: [],
  mappingProductCategory: '',
  accountCustomerList: [],
  isAccountCustomerList: false,
  mappingCustomerList: [],
  mappingAccountList: [],
});

function accountProductCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS:
      return state.set('accountProductCategoryList', action.payload)
    case LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING:
      return state.set('isAccountProductCategoryLoading', action.payload)
    case LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR:
      return state.set('accountProductCategoryError', action.payload)
    case SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY:
      return state.set('isAccountProductCategoryList', action.payload)
    case LOAD_A2A_PRODUCT_CATEGORY_SUCCESS:
      return state.set('mappingProductCategoryList', action.payload)
    case SEND_A2A_PRODUCT_CATEGORY_SUCCESS:
      return state.set('mappingProductCategory', action.payload)
    case LOAD_ACCOUNT_CUSTOMER_SUCCESS:
      return state.set('accountCustomerList', action.payload)
    case SAVE_IS_LOAD_ACCOUNT_CUSTOMER:
      return state.set('isAccountCustomerList', action.payload)
    case LOAD_A2A_CUSTOMER_SUCCESS:
      return state.set('mappingCustomerList', action.payload)
    case LOAD_A2A_ACCOUNT_SUCCESS:
      return state.set('mappingAccountList', action.payload)
    default:
      return state;
  }
}

export default accountProductCategoryReducer;
