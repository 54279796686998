import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Button from '../../../layout/form/Button';
// import Image from 'react-bootstrap/Image';
// import File from 'react-bootstrap/cjs/FormFile';
import Vemas from '../../../../images/findamachanic_banner.jpeg';
import User from '../../../../images/user.png';
import ServiceModal from './ServiceModal';
import ServiceAreaModal from './ServiceAreaModal';
import BannerModal from './BannerModal';
import Switch from "react-switch";
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { Link } from 'react-router-dom';

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

class Marketing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            servicesList: [],
            areaList: [],
            seletedBanner: '',
            isAddNewServiceModalOpen: false,
            isAddNewServiceAreaModalOpen: false,
            isUpdateService: false,
            selectedService: {},
            selectedArea: {},
            isUpdateArea: false,
            isDeleteArea: false,
            isDeleteServices: false,
            tempAreaList: [],
            tempServiceList: [],
            isAddNewBannerModalOpen: false,
            isOnline: false,
            initial: true,
            errorMassage: '',
            isValidationError: false,
            isChangesMarketing: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { tenantMarketing } = prevProps;
        if (tenantMarketing && tenantMarketing.tenantId && initial) {
            return {
                servicesList: tenantMarketing.tenantServices,
                areaList: tenantMarketing.tenantAreas,
                seletedBanner: tenantMarketing.bannerImage,
                isOnline: tenantMarketing.online === '1' ? true : false,
                initial: false
            }
        }
        return null
    }

    componentDidMount() {
        this.getTenantDetails()
    }

    getTenantDetails = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(actions.loadTenantMarketingSuccess({}))
        this.props.dispatch(actions.loadTenantMarketingRequest(id))
        this.setState({
            isChangesMarketing: false
        })
    }

    handleSaveAddNewService = (details) => {
        var list = this.state.servicesList
        if (list.length === 10) {
            return
        }
        const { isUpdateService } = this.state;
        if (isUpdateService) {
            const index = list.findIndex(select => select.index === details.index)
            const element = {
                description: details.description,
                title: details.title,
                id: details.id,
                index: details.index,
                operation: 'U'
            }
            list[index] = element
        } else {
            const element = {
                description: details.description,
                title: details.title,
                id: 0,
                index: list.length + 1,
                operation: 'C'
            }
            list.push(element)
        }
        this.setState({
            servicesList: list,
            isChangesMarketing: true
        })
        this.serviceModalClose()

    }

    handleSaveAddNewArea = (details) => {
        const { isUpdateArea } = this.state;
        var list = this.state.areaList
        if (list.length === 10) {
            return
        }
        if (isUpdateArea) {
            const index = list.findIndex(select => select.index === details.index)
            const element = {
                cityName: details.cityName,
                postalCode: details.postalCode,
                id: details.id,
                index: details.index,
                operation: 'U'
            }
            list[index] = element
        }
        else {
            const element = {
                cityName: details.cityName,
                postalCode: details.postalCode,
                id: 0,
                index: list.length + 1,
                operation: 'C'
            }
            list.push(element)
        }
        this.setState({
            areaList: list,
            isChangesMarketing: true
        })
        this.serviceAreaModalClose()
    }

    addServices = () => {
        this.setState({
            isAddNewServiceModalOpen: true
        })
    }

    serviceModalClose = () => {
        this.setState({
            isAddNewServiceModalOpen: false,
            isUpdateService: false,
            selectedService: {}
        })
    }

    addServiceArea = () => {
        this.setState({
            isAddNewServiceAreaModalOpen: true
        })
    }

    serviceAreaModalClose = () => {
        this.setState({
            isAddNewServiceAreaModalOpen: false,
            isUpdateArea: false,
            selectedArea: {}
        })
    }

    updateService = (element) => {
        this.setState({
            isUpdateService: true,
            isAddNewServiceModalOpen: true,
            selectedService: element
        })
    }

    updateArea = (element) => {
        this.setState({
            isUpdateArea: true,
            isAddNewServiceAreaModalOpen: true,
            selectedArea: element
        })
    }

    deleteArea = (details) => {
        var list = this.state.areaList
        const index = list.findIndex(select => select.index === details.index)
        const element = {
            cityName: details.cityName,
            postalCode: details.postalCode,
            id: details.id,
            index: details.index,
            operation: 'D'
        }
        list[index] = element
        this.setState({
            areaList: list,
            isDeleteArea: true,
            isChangesMarketing: true
        })
    }

    deleteService = (details) => {
        var list = this.state.servicesList
        const index = list.findIndex(select => select.index === details.index)
        const element = {
            description: details.description,
            title: details.title,
            id: details.id,
            index: details.index,
            operation: 'D'
        }
        list[index] = element
        this.setState({
            servicesList: list,
            isDeleteServices: true,
            isChangesMarketing: true
        })
    }

    serviceUp = (details) => {
        var list = this.state.servicesList
        if (details.index === 1) {
            return
        } else if (details.index > 1) {
            var prevElement = list[details.index - 2]
            var element = list[details.index - 1]
            prevElement = {
                description: prevElement.description,
                title: prevElement.title,
                id: prevElement.id,
                index: details.index,
                operation: 'U'
            }
            element = {
                description: element.description,
                title: element.title,
                id: element.id,
                index: details.index - 1,
                operation: 'U'
            }
            list[details.index - 2] = element
            list[details.index - 1] = prevElement
            this.setState({
                servicesList: list,
                isChangesMarketing: true
            })
        }
    }

    serviceDown = (details) => {
        var list = this.state.servicesList
        if (details.index === list.length) {
            return
        } else if (details.index < list.length) {
            var nextElement = list[details.index]
            var element = list[details.index - 1]
            nextElement = {
                description: nextElement.description,
                title: nextElement.title,
                id: nextElement.id,
                index: details.index,
                operation: 'U'
            }
            element = {
                description: element.description,
                title: element.title,
                id: element.id,
                index: details.index + 1,
                operation: 'U'
            }
            list[details.index] = element
            list[details.index - 1] = nextElement
            this.setState({
                servicesList: list,
                isChangesMarketing: true
            })
        }
    }

    areaUp = (details) => {
        var list = this.state.areaList
        if (details.index === 1) {
            return
        } else if (details.index > 1) {
            var prevElement = list[details.index - 2]
            var element = list[details.index - 1]
            prevElement = {
                id: prevElement.id,
                index: details.index,
                operation: 'U',
                cityName: prevElement.cityName,
                postalCode: prevElement.postalCode
            }
            element = {
                cityName: element.cityName,
                postalCode: element.postalCode,
                id: element.id,
                index: details.index - 1,
                operation: 'U'
            }
            list[details.index - 2] = element
            list[details.index - 1] = prevElement
            this.setState({
                areaList: list,
                isChangesMarketing: true
            })
        }
    }


    areaDown = (details) => {
        var list = this.state.areaList
        if (details.index === list.length) {
            return
        } else if (details.index < list.length) {
            var nextElement = list[details.index]
            var element = list[details.index - 1]
            nextElement = {
                index: details.index,
                operation: 'U',
                cityName: nextElement.cityName,
                postalCode: nextElement.postalCode
            }
            element = {
                cityName: element.cityName,
                postalCode: element.postalCode,
                id: element.id,
                index: details.index + 1,
                operation: 'U'
            }
            list[details.index] = element
            list[details.index - 1] = nextElement
            this.setState({
                areaList: list,
                isChangesMarketing: true
            })
        }
    }

    handleChangeBanner = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        this.setState({
            seletedBanner: base64,
            isChangesMarketing: true
        })
        this.openBannerModalClose();
    }

    openBannerModal = () => {
        this.setState({
            isAddNewBannerModalOpen: true,
        })
    }

    openBannerModalClose = () => {
        this.setState({
            isAddNewBannerModalOpen: false,
        })
    }

    handleChangeSwitch = (value) => {
        this.setState({
            isOnline: value,
            isChangesMarketing: true
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.getTenantDetails()
        this.props.dispatch(actions.sendTenantDetailsSuccess(''))
        this.setState({
            initial: true
        })
    }

    componentDidUpdate() {
        const {
            isDeleteArea,
            isDeleteServices,
            areaList,
            servicesList,
            tempAreaList,
            tempServiceList
        } = this.state;
        if (this.props.tenantDetailsSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }

        if (isDeleteArea) {
            const areaListFilterd = areaList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = areaList.filter(data => (data.id !== 0))
            this.setState({
                areaList: areaListFilterd,
                isDeleteArea: false,
                tempAreaList: tempAreaList.concat(tempItemList)
            })
        }

        if (isDeleteServices) {
            const serviceListFilterd = servicesList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = servicesList.filter(data => (data.id !== 0))
            this.setState({
                servicesList: serviceListFilterd,
                isDeleteServices: false,
                tempServiceList: tempServiceList.concat(tempItemList)
            })
        }
    }


    render() {
        const {
            tenantDetails,
            isTenantDetailsLoading,
            tenantDetailsError,
            tenantDetailsSuccess,
            handleTab,
            handleSubmit,
            userAccess
        } = this.props;

        const {
            servicesList,
            areaList,
            isAddNewServiceModalOpen,
            isAddNewServiceAreaModalOpen,
            isAddNewBannerModalOpen
        } = this.state;

        return (
            <div>
                {(isTenantDetailsLoading) ?
                    <Loader show={isTenantDetailsLoading} /> : ''}

                {(this.state.isValidationError || tenantDetailsError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage ? this.state.errorMassage : tenantDetailsError}
                        handleClose={this.errorModalClose}
                    />}

                {(tenantDetailsSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={tenantDetailsSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData', this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance', this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Finance</span>
                        <span onClick={() => handleTab('correspondence', this.state)}><ui5-icon class="samples-margin" name="letter" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Correspondence</span>
                        <span onClick={() => handleTab('openingHours', this.state)}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Opening Hours</span>
                        <span onClick={() => handleTab('marketing', this.state)}><ui5-icon class="samples-margin" name="official-service" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Marketing</span>
                    </div>
                </div>
                <div className="profile-content">
                    <div style={{ margin: '1px' }}>
                        <div className="page_header">
                            <div className="page_header-title">
                                <span>Marketing</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', borderBottom: '2px solid #dbd8d8', paddingBottom: '10px' }}>
                                <div style={{ margin: '2px' }}>
                                    <Button
                                        variant="outline-primary"
                                        text='Change Banner'
                                        size='sm'
                                        onClick={this.openBannerModal}
                                        disabled={userAccess && !userAccess.modifyTenant}
                                    />
                                    {/* <Button 
                                    variant="outline-primary" 
                                    text ='Change Banner'
                                    size='sm'
                                    onClick={() => this.photoUpload.click()}
                                /> */}
                                </div>
                                <div style={{ margin: '2px' }}>
                                    <Button
                                        variant="outline-primary"
                                        text='Add Services'
                                        size='sm'
                                        onClick={this.addServices}
                                        disabled={(servicesList && servicesList.length && servicesList.length >= 10) || (userAccess && !userAccess.modifyTenant)}
                                    />
                                </div>
                                <div style={{ margin: '2px' }}>
                                    <Button
                                        variant="outline-primary"
                                        text='Add Area'
                                        size='sm'
                                        onClick={this.addServiceArea}
                                        disabled={(areaList && areaList.length && areaList.length >= 10) || (userAccess && !userAccess.modifyTenant)}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                {/* <Image className="imageView" src={data && data.seletedImage ? data.seletedImage : User} /> */}
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '20%' }}>
                                        <img alt="vemaslogo" id="profile_logo" src={tenantDetails && tenantDetails.logo ? tenantDetails.logo : User} />
                                    </div>
                                    <div style={{ width: '50%', margin: '3%' }}>
                                        <h3 style={{ color: '#2B76A8' }}>{tenantDetails.businessName}</h3>
                                        <div>
                                            <span>{tenantDetails.billStreetNumber ? tenantDetails.billStreetNumber : ''}, {tenantDetails.billStreetName ? tenantDetails.billStreetName : ''}</span> <br />
                                            <span>{tenantDetails.billCity ? tenantDetails.billCity : ''}, {tenantDetails.billPostalCode ? tenantDetails.billPostalCode : ''}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%', marginTop: '5%' }} >
                                        {/* <Form.Check 
                                        type="switch"
                                        id="isOnline"
                                        label="Publish at findamechanic.com.au"
                                        checked={data.isOnline}
                                        onChange={handleChangeSwitch}
                                    /> */}
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <div>
                                                <Switch
                                                    checked={this.state.isOnline}
                                                    onChange={this.handleChangeSwitch}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </div>
                                            <div style={{ margin: '5px 0 5px 5px' }}>
                                                <spn>Publish at findamechanic.com.au</spn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img alt="banner" id="banner" src={this.state.seletedBanner ? this.state.seletedBanner : Vemas} />
                                {/* <Image className="imageView" src={data && data.seletedBanner ? data.seletedBanner : Vemas} /> */}
                                {/* <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={handleChangeBanner}/> */}
                            </div>
                            {servicesList && servicesList.length && servicesList.length > 0 ?
                                <h3 style={{ color: '#155077', marginTop: '50px' }}>Our services</h3> : ''}
                            <div className="service-list">
                                {servicesList && servicesList.map(service => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                                        <div className="card-content">
                                            <h4>{service.title}</h4>
                                            <span>
                                                {service.description}
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.updateService(service) : ''}>
                                                <ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.deleteService(service) : ''}>
                                                <ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.serviceUp(service) : ''}>
                                                <ui5-icon class="samples-margin" name="arrow-top" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.serviceDown(service) : ''}>
                                                <ui5-icon class="samples-margin" name="arrow-bottom" id="table-icon"></ui5-icon>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {areaList && areaList.length && areaList.length > 0 ?
                                <h3 style={{ color: '#155077', marginTop: '50px' }}>Servicing areas</h3> : ''}
                            <div className="area-list">
                                {areaList && areaList.map(area => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                                        <div className="card-content location">
                                            <div>
                                                <ui5-icon class="samples-margin" name="map" id="location-icon"></ui5-icon>
                                            </div>
                                            <div>
                                                {/* <span>{data.streetNo}, {data.streetName}</span>  */}
                                                <span>{area.cityName}, {area.postalCode}</span>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.updateArea(area) : ''}>
                                                <ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.deleteArea(area) : ''}>
                                                <ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.areaUp(area) : ''}>
                                                <ui5-icon class="samples-margin" name="arrow-top" id="table-icon"></ui5-icon>
                                            </div>
                                            <div onClick={userAccess && userAccess.modifyTenant ? () => this.areaDown(area) : ''}>
                                                <ui5-icon class="samples-margin" name="arrow-bottom" id="table-icon"></ui5-icon>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="btnSave">
                        <Button
                            variant="outline-primary"
                            text='Save'
                            onClick={() => handleSubmit(this.state)}
                            disabled={userAccess && !userAccess.modifyTenant}
                            icon="save"
                        />
                    </div>
                    {
                        isAddNewServiceModalOpen &&
                        <ServiceModal
                            isAddNewServiceModalOpen={isAddNewServiceModalOpen}
                            handleModalClose={this.serviceModalClose}
                            handleSaveAddNew={this.handleSaveAddNewService}
                            isUpdateService={this.state.isUpdateService}
                            selectedRow={this.state.selectedService}
                        />
                    }
                    {
                        isAddNewServiceAreaModalOpen &&
                        <ServiceAreaModal
                            isAddNewServiceAreaModalOpen={isAddNewServiceAreaModalOpen}
                            handleModalClose={this.serviceAreaModalClose}
                            handleSaveAddNew={this.handleSaveAddNewArea}
                            isUpdateArea={this.state.isUpdateArea}
                            selectedRow={this.state.selectedArea}
                        />
                    }
                    {
                        isAddNewBannerModalOpen &&
                        <BannerModal
                            isAddNewBannerModalOpen={isAddNewBannerModalOpen}
                            handleChangeBanner={this.handleChangeBanner}
                            openBannerModalClose={this.openBannerModalClose}
                        />
                    }
                </div>
            </div>
        )
    }
}

Marketing.propTypes = {
    handleChange: PropTypes.any,
    data: PropTypes.any,
    paymentTermList: PropTypes.any,
    handleSelect: PropTypes.any,
    handleChangeBanner: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
    tenantDetails: selectors.makeSelectTenantBasic(),
    tenantMarketing: selectors.makeSelectTenantMarketingSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Marketing));
