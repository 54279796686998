import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getTenantFinance(id) {
    return axios.get(baseURL + 'tenants/finance/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantBasic(id) {
    return axios.get(baseURL + 'tenants/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
};

export function getTenantCorrespondence(id) {
    return axios.get(baseURL + 'tenants/correspondance/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
};

export function updateTenantDetails(data) {
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL + 'tenants/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantDefault(id) {
    return axios.get(baseURL + 'tenants/default/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantAccountSystem(id) {
    return axios.get(baseURL + 'accountIntegration/connectionStatus', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantServices() {
    return axios.get(baseURL + 'tenants/services', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
};

export function getTenantServiceArea() {
    return axios.get(baseURL + 'tenants/serviceAreas', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
};



export function getTenantPaymentMethod() {
    return axios.get(baseURL + 'appPayment', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function tenantActivateRequest(data) {
    data.appId = "03"
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'tenants/active/', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantPackage() {
    return axios.get(baseURL + 'tenants/package?AppId=03', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateImageRequest(data) {
    return axios.post(baseURL + 'image/'+ data.type,data.formData, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}
