import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import File from 'react-bootstrap/cjs/FormFile';
// import SelectField from '../../../layout/form/SelectField';
// import common from '../../../common';

export class BannerModal extends Component {

    render() {
        const {
            isAddNewBannerModalOpen, 
            openBannerModalClose,
            handleChangeBanner,
            // repairCategoryList,
            // objectStatusList,
            // uomList,
            // selectedRow
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewBannerModalOpen} 
                    onHide={openBannerModalClose}
                    title="Change Banner"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <span>If you use this width and height (1280 * 375) you can get a better banner.</span>
                            <form style={{marginTop:"25px"}}>
                            <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={handleChangeBanner}/>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={openBannerModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Close'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => this.photoUpload.click()}
                                secondaryBtnText='Add'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

BannerModal.propTypes = {
    isAddNewBannerModalOpen: PropTypes.bool,
    openBannerModalClose: PropTypes.any,
    handleChangeBanner: PropTypes.any,
}

export default BannerModal;