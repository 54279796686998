import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../layout/form/TextField';
import Button from '../layout/form/Button';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import VemasLogo from '../../images/VEMAS.png';
import "./auth.css";


// const user = localStorage.getItem('user')

const ChangeTempPassword = ({
    handleSubmit,
    handleChange,
    isError,
    errorMassage,
    user
}) => {
  return (
    <div className="auth-form">
    <img alt="vemaslogo" id="vemaslogo" src={VemasLogo}/>
    <form>
        <h5>Change Password</h5>
        <div>You are required to Change the password.</div>
            <TextField
                controlId="email"
                // text="Email"
                placeholder="Email"
                type="text"
                onChange={handleChange}
                defaultValue={user ? user : ''}
            />
            <TextField
                controlId="newPassword"
                // text="New Password"
                placeholder="New Password"
                type="password"
                onChange={handleChange}
            />
            <small style={{color:'red', textAlign:"left"}}>
                <li>Minimum length 8 </li>
                <li>Require numbers </li>
                <li>Require special character</li>
                <li>Require uppercase letters</li>
                <li>Require lowercase letters</li>
            </small>
            <TextField
                controlId="confirmNewPassword"
                // text="Confirm New Password"
                placeholder="Confirm New Password"
                type="password"
                onChange={handleChange}
            />
            {isError ?
                <div className="authErrorMassage">
                    <span><ui5-icon class="samples-margin" name="message-error" id="messageError"></ui5-icon></span>
                    {errorMassage}
                </div> : 
            <div style={{display:'flex', justifyContent:'center'}}>
                <Button 
                    variant="primary" 
                    text ='Change Password'
                    onClick={handleSubmit}
                />
            </div>}
    </form>    
</div>
    )
}

ChangeTempPassword.propTypes = {
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isError: PropTypes.bool,
    errorMassage:PropTypes.any
}

export default ChangeTempPassword;