import { put, call } from 'redux-saga/effects';
import {
  addNewEmailTemplateRequest,
  getEmailTemplateList,
  updateEmailTemplateRequest,
  deleteEmailTemplateRequest,
  // getSelectedEmailTemplateRequest
} from '../service/emailTempService';
import * as actions from '../../store/action/emailTempAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewEmailTemplateRequest(data){
  yield put(actions.loadEmailTemplateListError(null))
  try{
    const response = yield call(addNewEmailTemplateRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const massage = response.data.message
      yield put(actions.sendEmailTemplateSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.result
      yield put(actions.loadEmailTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage  =common.error
    yield put(actions.loadEmailTemplateListError(massage))
  }
}

export function* loadEmailTemplateListRequest(data){
  yield put(actions.loadEmailTemplateListError(null))
  try{
    yield put(actions.loadEmailTemplateListLoading(true));
    const response = yield call(getEmailTemplateList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const emailTemplateList = response.data.result
      yield put(actions.loadEmailTemplateListSuccess(emailTemplateList))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadEmailTemplateListLoading(false));
      const massage =common.error
      yield put(actions.loadEmailTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailTemplateListLoading(false));
  }catch(error){
    yield put(actions.loadEmailTemplateListLoading(false));
    const massage =common.error
    yield put(actions.loadEmailTemplateListError(massage))
  }
}

export function* sendUpdateEmailTemplateRequest(data){
  yield put(actions.loadEmailTemplateListError(null))
  try{
    const response = yield call(updateEmailTemplateRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const massage = response.data.message
      yield put(actions.sendEmailTemplateSuccess(massage))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadEmailTemplateListLoading(false));
      const massage = response.data.message
      yield put(actions.loadEmailTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    const massage  =common.error
    yield put(actions.loadEmailTemplateListError(massage))
  }
}

export function* sendDeleteEmailTemplateRequest(data){
  yield put(actions.loadEmailTemplateListError(null))
  try{
    yield put(actions.loadEmailTemplateListLoading(true));
    const response = yield call(deleteEmailTemplateRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      const massage = response.data.message
      yield put(actions.sendEmailTemplateSuccess(massage)) 
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadEmailTemplateListLoading(false));
      const massage =common.error
      yield put(actions.loadEmailTemplateListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadEmailTemplateListLoading(false));
  }catch(error){
    yield put(actions.loadEmailTemplateListLoading(false));
    const massage  =common.error
    yield put(actions.loadEmailTemplateListError(massage))
  }
}
