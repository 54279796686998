import { 
    SAVE_IS_ITEM_MODAL_OPEN_STATUS,
    LOAD_ITEM_LIST_REQUEST,
    LOAD_ITEM_LIST_LOADING,
    LOAD_ITEM_LIST_SUCCESS,
    LOAD_ITEM_LIST_ERROR,
    SEND_ADD_NEW_ITEM_REQUEST,
    SEND_ADD_NEW_ITEM_LOADING,
    SEND_ADD_NEW_ITEM_SUCCESS,
    SEND_ADD_NEW_ITEM_ERROR,
    LOAD_ITEM_CATEGORY_LIST_REQUEST,
    LOAD_ITEM_CATEGORY_LIST_SUCCESS,
    SEND_UPDATE_ITEM_REQUEST,
    SEND_UPDATE_ITEM_LOADING,
    SEND_UPDATE_ITEM_SUCCESS,
    SEND_UPDATE_ITEM_ERROR,
    SEND_DELETE_ITEM_REQUEST,
    SEND_DELETE_ITEM_LOADING,
    SEND_DELETE_ITEM_SUCCESS,
    SEND_DELETE_ITEM_ERROR
  } from '../constant/itemConstant';
  
export function saveIsItemModalOpenStatus(open){
  return {
    type: SAVE_IS_ITEM_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadItemListRequest(data) {
  return {
    type: LOAD_ITEM_LIST_REQUEST,
    payload: data,
  }
};

export function loadItemListLoading(data) {
  return {
    type: LOAD_ITEM_LIST_LOADING,
    payload: data,
  }
};
  
export function loadItemListSuccess(data) {
  return {
    type: LOAD_ITEM_LIST_SUCCESS,
    payload: data,
  }
};

export function loadItemListError(data) {
  return {
    type: LOAD_ITEM_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewItemRequest(data) {
  return {
    type: SEND_ADD_NEW_ITEM_REQUEST,
    payload: data,
  }
};

export function sendAddNewItemLoading(data) {
  return {
    type: SEND_ADD_NEW_ITEM_LOADING,
    payload: data,
  }
};

export function sendAddNewItemSuccess(data) {
  return {
    type: SEND_ADD_NEW_ITEM_SUCCESS,
    payload: data,
  }
};

export function sendAddNewItemError(data) {
  return {
    type: SEND_ADD_NEW_ITEM_ERROR,
    payload: data,
  }
};

export function loadItemCategoryListRequest() {
  return {
    type: LOAD_ITEM_CATEGORY_LIST_REQUEST
  }
};

export function loadItemCategoryListSuccess(data) {
  return {
    type: LOAD_ITEM_CATEGORY_LIST_SUCCESS,
    payload: data,
  }
};

export function sendUpdateItemRequest(data) {
  return {
    type: SEND_UPDATE_ITEM_REQUEST,
    payload: data,
  }
};

export function sendUpdateItemLoading(data) {
  return {
    type: SEND_UPDATE_ITEM_LOADING,
    payload: data,
  }
};
  
export function sendUpdateItemSuccess(data) {
  return {
    type: SEND_UPDATE_ITEM_SUCCESS,
    payload: data,
  }
};

export function sendUpdateItemError(data) {
  return {
    type: SEND_UPDATE_ITEM_ERROR,
    payload: data,
  }
};


export function sendDeleteItemRequest(data) {
  return {
    type: SEND_DELETE_ITEM_REQUEST,
    payload: data,
  }
};

export function sendDeleteItemLoading(data) {
  return {
    type: SEND_DELETE_ITEM_LOADING,
    payload: data,
  }
};
  
export function sendDeleteItemSuccess(data) {
  return {
    type: SEND_DELETE_ITEM_SUCCESS,
    payload: data,
  }
};

export function sendDeleteItemError(data) {
  return {
    type: SEND_DELETE_ITEM_ERROR,
    payload: data,
  }
};