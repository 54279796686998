import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './NavBar.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import "@ui5/webcomponents-icons/dist/AllIcons.js";

const SideBarFull = ({
    redirectCustomer,
    redirectStock,
    activeRoute,
    redirectDashboard,
    redirectCalendar,
    redirectSupplier,
    redirectNotification,
    tenantUserExpireStatus,
    isTenantActiveStatus,
    clickUnderExpired,
    redirectReport,
    redirectAnalytics
}) => {

    return (
        <div className='side-bar'>
            <li className='text-box'>
                <Link onClick={redirectDashboard} to='./dashboard' className={activeRoute === '#/dashboard' ? 'nav_text_active' : 'nav_text'}><ui5-icon class="samples-margin" name="performance" id="side-icon"></ui5-icon><span>Dashboard</span></Link>
            </li>
            <li className='text-box'>
                <Link onClick={redirectCalendar} to='./calendar' className={activeRoute === '#/calendar' ? 'nav_text_active' : 'nav_text'}><ui5-icon class="samples-margin" name="appointment-2" id="side-icon"></ui5-icon><span>Calendar</span></Link>
            </li>
            <li className='text-box'>
                <Link
                    onClick={redirectCustomer} to='./customer'
                    className={activeRoute === '#/customer' ? 'nav_text_active' :
                        activeRoute === '#/quotation' ? 'nav_text_active' :
                            activeRoute === '#/reports' ? 'nav_text_active' :
                                activeRoute === '#/invoice' ? 'nav_text_active' : 'nav_text'}>
                    <ui5-icon class="samples-margin" name="business-by-design" id="side-icon"></ui5-icon><span>Sales</span>
                </Link>
            </li>
            {/* <li className='text-box'>
            <Link 
                onClick={redirectVehicle} to="./vehicle" 
                className={activeRoute === '#/vehicle'? 'nav_text_active':
                        activeRoute === '#/jobCard'? 'nav_text_active':
                        activeRoute === '#/vehiclehistory'? 'nav_text_active':
                        'nav_text'}>
                    <ui5-icon class="samples-margin" name="inventory" id="side-icon"></ui5-icon><span>Vehicle</span>
            </Link>
        </li> */}
            {/* <li className='text-box'>
            <Link className='nav_text'><ui5-icon class="samples-margin" name="sales-quote" id="side-icon"></ui5-icon><span>Quotation</span></Link>
        </li> */}
            <li className='text-box'>
                <Link
                    onClick={redirectSupplier} to="./supplier"
                    className={activeRoute === '#/supplier' ? 'nav_text_active' :
                        activeRoute === '#/purchasing' ? 'nav_text_active' :
                            'nav_text'}>
                    <ui5-icon class="samples-margin" name="supplier" id="side-icon"></ui5-icon><span>Purchasing</span>
                </Link>
            </li>
            <li className='text-box'>
                {!tenantUserExpireStatus ?
                    <Link onClick={redirectStock} to='./stock'
                        className={isTenantActiveStatus ? "disabled-link" : activeRoute === '#/stock' ? 'nav_text_active' : 'nav_text'}><ui5-icon class="samples-margin" name="shelf" id="side-icon"></ui5-icon><span>Stock</span>
                    </Link> :
                    <Link
                        onClick={clickUnderExpired}
                        className={isTenantActiveStatus ? "disabled-link" : activeRoute === '#/stock' ? 'nav_text_active' : 'nav_text'}><ui5-icon class="samples-margin" name="shelf" id="side-icon"></ui5-icon><span>Stock</span>
                    </Link>}
            </li>
            <li className='text-box'>
                <Link onClick={redirectReport} to='./SalesReports' className={activeRoute === '#/SalesReports' ? 'nav_text_active' : 'nav_text'}><ui5-icon class="samples-margin" name="manager-insight" id="side-icon"></ui5-icon><span>Reports</span></Link>
            </li>
            <li className='text-box'>
                <Link
                    onClick={redirectNotification} to="./notification"
                    className={activeRoute === '#/notification' ? 'nav_text_active' :
                        'nav_text'}>
                    <ui5-icon class="samples-margin" name="marketing-campaign" id="side-icon"></ui5-icon><span>Notification</span></Link>
            </li>
            <li className='text-box'>
                <Link
                    onClick={redirectAnalytics} to='./analytics'
                    className={activeRoute === '#/analytics' ? 'nav_text_active' :
                        'nav_text'}>
                    <ui5-icon class="samples-margin" name="business-objects-experience" id="side-icon"></ui5-icon><span>Analytics</span></Link>
            </li>
        </div>
    )
}


SideBarFull.propTypes = {
    isSideBarStatus: PropTypes.bool,
    redirectVehicle: PropTypes.func,
    redirectCustomer: PropTypes.func,
    activeRoute: PropTypes.any,
    redirectDashboard: PropTypes.func,
    redirectCalendar: PropTypes.func,
    redirectSupplier: PropTypes.func,
    redirectNotification: PropTypes.func,
    // redirectAnalytics: PropTypes.func,
}

export default SideBarFull;