import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css'

const Modal = ({
    show,
    onHide,
    title,
    body,
    footer,
    width
}) => (
      <BootstrapModal 
        show={show} 
        onHide={onHide}
        animation={false}
        // dialogClassName="modal_width"
        // centered
        >
        <div style={{top:'60px', right:10,left:'auto', bottom:'auto', position:"fixed", backgroundColor:'#fff', width:width ? width: '70%'}}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>{title}</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body style={{maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}}>{body}</BootstrapModal.Body>
          <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>
        </div>
      </BootstrapModal>
);

Modal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    title: PropTypes.any,
    body: PropTypes.any,
    footer: PropTypes.any,
    width: PropTypes.any
}

export default Modal;