import React from 'react';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import TextField from '../../../layout/form/TextField';
import '../../pages.css';
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';

const Address = ({
    data,
    handleChange,
    countryList,
    handleSelect,
}) => (
    <div>
        <div className="row" style={{margin:'5px'}}>
            <div className="col-sm-6">
                <div className="row" style={{marginTop:'15px'}}>
                    <div className="col-sm">
                        <TextField
                            text="Street No"
                            controlId="streetNo"
                            // placeholder="Street No"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetNo}
                            maxLength={common.maxLengths.streetNo}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="Street Name"
                            controlId="streetName"
                            // placeholder="Street Name"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.streetName}
                            maxLength={common.maxLengths.streetName}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="City"
                            controlId="city"
                            // placeholder="City"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.city}
                            maxLength={common.maxLengths.cityName}
                            // required
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            text="State"
                            controlId="state"
                            // placeholder="state"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.state}
                            maxLength={common.maxLengths.region}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            text="Post Code"
                            controlId="postCode"
                            // placeholder="Post Code"
                            type="text"
                            onChange={handleChange}
                            defaultValue={data.postCode}
                            maxLength={common.maxLengths.postCode}
                        />
                    </div>
                    <div className="col-sm">
                        <SelectField
                            text="Country"
                            controlId="country"
                            onChange={(e) =>handleSelect(e,'country')}
                            options={countryList}
                            selectedValue={data.country}
                        />
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )

Address.propTypes = {
    data: PropTypes.any,
    handleChange: PropTypes.any,
    countryList: PropTypes.any,
    handleSelect: PropTypes.any,
}

export default Address;