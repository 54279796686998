import { fromJS } from 'immutable';
import {
  LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS,
  LOAD_TENANT_BASIC_SUCCESS,
  LOAD_TENANT_CORRESPONDENCE_SUCCESS,
  LOAD_TENANT_DEFAULT_SUCCESS,
  LOAD_TENANT_DETAILS_ERROR,
  LOAD_TENANT_DETAILS_LOADING,
  LOAD_TENANT_FINANCE_SUCCESS,
  LOAD_TENANT_SERVICE_AREA_SUCCESS,
  LOAD_TENANT_SERVICE_SUCCESS,
  SEND_TENANT_DETAILS_SUCCESS,
  SAVE_TENANT_IDENTITY_STATUS,
  SEND_UPDATE_TENANT_ACTIVATE_SUCCESS,
  LOAD_TENANT_SERVICE_SUCCESS_STATUS,
  LOAD_TENANT_SERVICE_AREA_SUCCESS_STATUS,
  LOAD_TENANT_MARKETING_SUCCESS,
  LOAD_TENANT_OPEN_HOURS_SUCCESS,
  SAVE_TENANT_ACTIVE_STATUS,
  LOAD_TENANT_PAYMENT_METHOD_SUCCESS,
  SEND_TENANT_ACTIVATE_LOADING,
  SEND_TENANT_ACTIVATE_ERROR,
  SEND_TENANT_ACTIVATE_SUCCESS,
  LOAD_TENANT_PACKAGE_SUCCESS
} from '../constant/tenantConstant';

export const initialState = fromJS({
  tenantFinance: {},
  isTenantDetailsLoading: false,
  tenantDetailsError: '',
  tenantDetailsSuccess: '',
  tenantDetails: {},
  tenantCorrespondence: {},
  tenantDefault: [],
  tenantAccountSystem: '',
  tenantServices: [],
  tenantAreas: [],
  newUserIdentitiyStatus: false,
  tenantReactivateStatus: {},
  isTenantServices: false,
  isTenantAreas: false,
  tenantMarketing: {},
  tenantOpenHours: {},
  isTenantActiveStatus: false,
  tenantPaymentMethod: [],
  tenantActivateSuccess: null,
  tenantActivateError: null,
  tenantActivateLoading: false,
  tenantPackage: null,
});

function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TENANT_BASIC_SUCCESS:
      return state.set('tenantDetails', action.payload)
    case LOAD_TENANT_FINANCE_SUCCESS:
      return state.set('tenantFinance', action.payload)
    case LOAD_TENANT_DETAILS_LOADING:
      return state.set('isTenantDetailsLoading', action.payload)
    case LOAD_TENANT_DETAILS_ERROR:
      return state.set('tenantDetailsError', action.payload)
    case SEND_TENANT_DETAILS_SUCCESS:
      return state.set('tenantDetailsSuccess', action.payload)
    case LOAD_TENANT_CORRESPONDENCE_SUCCESS:
      return state.set('tenantCorrespondence', action.payload)
    case LOAD_TENANT_DEFAULT_SUCCESS:
      return state.set('tenantDefault', action.payload)
    case LOAD_TENANT_ACCOUNT_SYSTEM_SUCCESS:
      return state.set('tenantAccountSystem', action.payload)
    case LOAD_TENANT_SERVICE_SUCCESS:
      return state.set('tenantServices', action.payload)
    case LOAD_TENANT_SERVICE_AREA_SUCCESS:
      return state.set('tenantAreas', action.payload)
    case SAVE_TENANT_IDENTITY_STATUS:
      return state.set('newUserIdentitiyStatus', action.payload)
    case SEND_UPDATE_TENANT_ACTIVATE_SUCCESS:
      return state.set('tenantReactivateStatus', action.payload)
    case LOAD_TENANT_SERVICE_SUCCESS_STATUS:
      return state.set('isTenantServices', action.payload)
    case LOAD_TENANT_SERVICE_AREA_SUCCESS_STATUS:
      return state.set('isTenantAreas', action.payload)
    case LOAD_TENANT_MARKETING_SUCCESS:
      return state.set('tenantMarketing', action.payload)
    case LOAD_TENANT_OPEN_HOURS_SUCCESS:
      return state.set('tenantOpenHours', action.payload)
    case SAVE_TENANT_ACTIVE_STATUS:
      return state.set('isTenantActiveStatus', action.payload)
    case LOAD_TENANT_PAYMENT_METHOD_SUCCESS:
      return state.set('tenantPaymentMethod', action.payload)
    case SEND_TENANT_ACTIVATE_LOADING:
      return state.set('tenantActivateLoading', action.payload)
    case SEND_TENANT_ACTIVATE_ERROR:
      return state.set('tenantActivateError', action.payload)
    case SEND_TENANT_ACTIVATE_SUCCESS:
      return state.set('tenantActivateSuccess', action.payload)
    case LOAD_TENANT_PACKAGE_SUCCESS:
      return state.set('tenantPackage', action.payload)
    default:
      return state;
  }
}

export default tenantReducer;
