import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import Address from './Address'
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';

export class AddSupplier extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            name:'',
            contactName:'',
            email:'',
            phone:'',
            mobile:'',
            fax:'',
            customField1:'',
            customField2:'',
            status:'',
            streetName:'',
            streetNo:'',
            state:'', 
            city:'', 
            postCode:'', 
            country:'',
            initial:true,
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedSupplier, isUpdate, selectedRow,objectStatusList } = prevProps;
        if(selectedSupplier && selectedSupplier.id && prevState.initial && isUpdate && selectedSupplier.id === selectedRow.id){
           
            return {
                name:selectedSupplier.name,
                contactName:selectedSupplier.contactName,
                email:selectedSupplier.email,
                phone:selectedSupplier.phone,
                mobile:selectedSupplier.mobile,
                fax:selectedSupplier.fax,
                customField1:selectedSupplier.customField1,
                customField2:selectedSupplier.customField2,
                streetName:selectedSupplier.streetName,
                streetNo:selectedSupplier.streetNo,
                state:selectedSupplier.region, 
                city:selectedSupplier.cityName, 
                postCode:selectedSupplier.postalCode, 
                country:selectedSupplier.country ? selectedSupplier.country.code :'',
                status: selectedSupplier.statusId,
                initial:false
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            return {
                status:defaultStatus[0].id,
                initial:false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }
  
    render() {
        const {
            isAddNewSupplierModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            selectedRow,
            selectedSupplier,
            isUpdate,
            objectStatusList,
            countryList
        } = this.props;

        return (
            <div>
                {((isUpdate && isUpdate && selectedSupplier.id === selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewSupplierModalOpen} 
                    onHide={handleModalClose}
                    title={"Supplier Information" + (isUpdate && selectedRow && selectedRow.id ? " - "+selectedRow.name : '')}
                    width='60%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form >
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Name"
                                            controlId="name"
                                            // placeholder="First Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.name}
                                            maxLength={common.maxLengths.supplierName}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Contact person"
                                            controlId="contactName"
                                            // placeholder="Last Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.contactName}
                                            maxLength={common.maxLengths.supplierContactName}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextField
                                            text="Email"
                                            controlId="email"
                                            // placeholder="Email"
                                            type="email"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.email}
                                            maxLength={common.maxLengths.supplierEmail}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Phone"
                                            controlId="phone"
                                            // placeholder="Phone"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.phone}
                                            maxLength={common.maxLengths.supplierPhone}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Mobile"
                                            controlId="mobile"
                                            // placeholder="Mobile"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.mobile}
                                            maxLength={common.maxLengths.supplierMobile}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Fax"
                                            controlId="fax"
                                            // placeholder="Fax"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.fax}
                                            maxLength={common.maxLengths.supplierFax}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <TextField
                                            text="Custom Field 1"
                                            controlId="customField1"
                                            // placeholder="Custom Field 1"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.customField1}
                                            maxLength={common.maxLengths.supplierCustomField1}
                                        /> 
                                    </div>
                                    <div className="col-sm-3">
                                        <TextField
                                            text="Custom Field 2"
                                            controlId="customField2"
                                            // placeholder="Custom Field 2"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.customField2}
                                            maxLength={common.maxLengths.supplierCustomField2}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={(e) =>this.handleSelect(e,'status')}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* <div className="row"> */}
                                <div style={{marginTop:'20px'}}>
                                    <Tabs defaultActiveKey="address" id="masterDataMappingTab" style={{fontSize:'14px'}}>
                                        <Tab 
                                            eventKey="address" 
                                            title={<span><ui5-icon class="samples-margin" name="addresses" id="tab-icon"></ui5-icon> Address</span>}>
                                            <Address
                                                data={this.state}
                                                handleChange={this.handleChange}
                                                countryList={countryList}
                                                handleSelect={this.handleSelect}
                                            />
                                        </Tab>
                                    </Tabs>
                                </div>
                                {/* </div> */}
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />}
        </div>
        )
    }
}

AddSupplier.propTypes = {
    isAddNewSupplierModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedSupplier: PropTypes.any,
    isUpdate: PropTypes.bool
}

export default AddSupplier;