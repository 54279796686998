import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewPurchasingRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'purchaseorders', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
      })
}

export function getPurchasingList(parameters){ 
    return axios.get(baseURL+'purchaseorders?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&supplierId='+parameters.supplier+'&purchaseOrderNo='+parameters.orderNo+'&jobCardId='+parameters.jobCardId+'&vehicleId='+parameters.vehicleId+'&statusId='+parameters.status+'&commonSearch='+parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updatePurchasingRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.patch(baseURL+'purchaseorders/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deletePurchasingRequest(id){
    return axios.delete(baseURL+'purchaseorders/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

// export function printPurchasingRequest(data){
//     const parameters = JSON.stringify(data)
//     return axios.patch(baseURL+'purchaseorders/printAndUpdate/'+data.invoiceDetails.id, parameters, {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization' : sessionStorage.getItem("accessToken")
//         }
//     })
// }

export function getSelectedPurchasingRequest(id){
    return axios.get(baseURL+'purchaseorders/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

// export function sendPurchasingRequest(data){
//     const parameters = JSON.stringify(data)
//     //console.log(parameters)
//     return axios.patch(baseURL+'purchaseorders/emailAndUpdate/'+data.invoiceDetails.id, parameters, {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization' : sessionStorage.getItem("accessToken")
//         }
//     })
// }

export function sendPurchasingByIdRequest(id){
    return axios.patch(baseURL+'purchaseorders/email/'+id,null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function printPurchasingByIdRequest(id){
    return axios.patch(baseURL+'purchaseorders/print/'+id,null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}


