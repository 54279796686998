import { createSelector } from 'reselect';

const selectSupplierDomain = state => state.supplier;

const makeSelectSupplierPopupOpen =() =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
      supplierState ? supplierState.get('isAddNewSupplierModalOpen') : false
  );

const makeSelectSupplierList = () =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
      supplierState ? supplierState.get('supplierList') : []
  );

const makeSelectSupplierListLoading = () =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
      supplierState ? supplierState.get('isSupplierListLoading') : false
  );

const makeSelectSupplierListError = () =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
      supplierState ? supplierState.get('supplierListError') : ''
  );

const makeSelectSupplierSuccess = () =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
      supplierState ? supplierState.get('supplierSuccess') : ''
  );

const makeSelectSelectedSupplierSuccess = () =>
  createSelector(
    selectSupplierDomain,
    supplierState => 
    supplierState ? supplierState.get('selectedSupplier') : ''
  );

export {
    makeSelectSupplierPopupOpen,
    makeSelectSupplierList,
    makeSelectSupplierListLoading,
    makeSelectSupplierListError,
    makeSelectSupplierSuccess,
    makeSelectSelectedSupplierSuccess
}
