import { put, call } from 'redux-saga/effects';
import {
  addNewQuotationRequest,
  getQuotationList,
  updateQuotationRequest,
  deleteQuotationRequest,
  getSelectedQuotationRequest,
  updateAndPrintQuotationRequest,
  printQuotationByIdRequest,
  updateAndEmailQuotationRequest,
  emailQuotationByIdRequest,
  addQuotationImageRequest,
  addQuotationImageEmailRequest
} from '../service/quotationService';
import * as actions from '../../store/action/quotationAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewQuotationRequest(data){
  //console.log('sendAddNewQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    // yield put(actions.loadQuotationListLoading(true));
    const response = yield call(addNewQuotationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      const quotationId = response.data.result.id;
      if(data.payload.isAddImages){
        const files = data.payload.fileList;
        const formData = new FormData();
        formData.append(`${quotationId}`,"quotationId")
        for (let i = 0; i < files.length; i++) {
          formData.append(`${quotationId}_Image${i}`, files[i])
        }
        yield call(addQuotationImageRequest,formData);
        // console.log(response.data.message)
      }
      const massage = response.data.message
      yield put(actions.sendQuotationSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      var massage = response.data.message
      if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      }
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewQuotationLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewQuotationLoading(false));
    //console.log(error.response.data.detail)
    const massage = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* loadQuotationListRequest(data){
  //console.log('loadQuotationListRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(getQuotationList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const quotationList = response.data.result
      yield put(actions.loadQuotationListSuccess(quotationList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadQuotationListLoading(false));
      const massage = common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendUpdateQuotationRequest(data){
  //console.log('sendUpdateQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    // yield put(actions.sendUpdateQuotationLoading(true));
    const response = yield call(updateQuotationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      if(data.payload.isAddImages){
        yield call(addQuotationImageRequest,data.payload.fileList);
        // console.log(response.data.result)
      }
      const massage = response.data.message
      yield put(actions.sendQuotationSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      var massage = response.data.message
      if(response && response.data && response.data.statusCode && response.data.statusCode === 422 ){
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      }
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateQuotationLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateQuotationLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendDeleteQuotationRequest(data){
  //console.log('sendDeleteQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(deleteQuotationRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendQuotationSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* loadSelectedQuotationRequest(data){
  //console.log('loadSelectedQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(getSelectedQuotationRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const quotation = response.data
      yield put(actions.loadSelectedQuotationSuccess(quotation))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      yield put(actions.loadQuotationListLoading(false));
      const massage = common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendPrintQuotationRequest(data){
  //console.log('sendPrintQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(updateAndPrintQuotationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.sendPrintQuotationSuccess(pdf))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 422 ){
      var massage = response.data.message
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      yield put(actions.loadQuotationListError(massage))
    }
    else{
      const massage  = common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendAddAndPrintQuotationRequest(data){
  //console.log('sendAddAndPrintQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(addNewQuotationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.sendPrintQuotationSuccess(pdf))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 422 ){
      var massage = response.data.message
        const errorMassage = response.data.result.errorMessage
        const errors = errorMassage.split('|');
         var error_ = errors[0]
        //  for(let i = 0; i < errors.length; i++){
        //     error_ = error_ + errors[i] + '\n'
        //  }
         massage = error_
      yield put(actions.loadQuotationListError(massage))
    }
    else{
      const massage  = common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* loadPrintbyIdRequest(data){
  //console.log('loadPrintbyIdRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(printQuotationByIdRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.sendPrintQuotationSuccess(pdf))
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendEmailQuotationRequest(data){
  //console.log('sendEmailQuotationRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    // yield put(actions.sendUpdateQuotationLoading(true));
    const response = yield call(updateAndEmailQuotationRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = common.invoiceEmailMessage
      yield put(actions.sendQuotationSuccess(massage)) 
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  = common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateQuotationLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateQuotationLoading(false));
    //console.log(error.response)
    const massage  = common.error
    yield put(actions.loadQuotationListError(massage))
  }
}

export function* sendQuotationSendByIdRequest(data){
  //console.log('sendQuotationSendByIdRequest')
  yield put(actions.loadQuotationListError(''))
  try{
    yield put(actions.loadQuotationListLoading(true));
    const response = yield call(emailQuotationByIdRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = common.invoiceEmailMessage
      yield put(actions.sendQuotationSuccess(massage)) 
    }   
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage  =common.error
      yield put(actions.loadQuotationListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadQuotationListLoading(false));
  }catch(error){
    yield put(actions.loadQuotationListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadQuotationListError(massage))
  }
}



export function* sendQuotationImageEmailRequest(data){
  // console.log('sendQuotationImageEmailRequest')
  yield put(actions.sendQuotationImageEmailError(''))
  try{
    // yield put(actions.sendUpdateQuotationLoading(true));
    const response = yield call(addQuotationImageEmailRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      const massage = response.data.message
      yield put(actions.sendQuotationImageEmailSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else{
      const massage = response.data.message
      yield put(actions.sendQuotationImageEmailError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateQuotationLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateQuotationLoading(false));
    // console.log(error.response)
    const massage  =common.error
    yield put(actions.sendQuotationImageEmailError(massage))
  }
}