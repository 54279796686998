import React from 'react'
import '../../../App.css'
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Booking from './booking/Calendar';
import Reminder from './reminder/Reminder';

const CalendarView = () => (
            <div >
                <Tabs defaultActiveKey="booking" id="masterDataMappingTab">
                    <Tab 
                        eventKey="booking" 
                        title={<span><ui5-icon class="samples-margin" name="customer" id="tab-icon"></ui5-icon>Bookings</span>}>
                        <Booking/>
                    </Tab>
                    <Tab 
                        eventKey="reminder" 
                        title={<span><ui5-icon class="samples-margin" name="monitor-payments" id="tab-icon"></ui5-icon> Reminders</span>}>
                        <Reminder/>
                    </Tab>
                </Tabs>
            </div>
        )
        
export default CalendarView;
