import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
// import SuccessModal from '../../../layout/form/successModal';
import { Link } from 'react-router-dom';
import Button from '../../../layout/form/Button';
import TimePicker from '../../../layout/form/timePicker';
import Switch from "react-switch";
import SuccessModal from '../../../layout/form/successModal';

class OpeningHours extends Component {
    constructor(props) {
        super(props);

        this.state = {
            monStart: '',
            monEnd: '',
            monStatus: false,
            tueStart: '',
            tueEnd: '',
            tueStatus: false,
            wedStart: '',
            wedEnd: '',
            wedStatus: false,
            thuStart: '',
            thuEnd: '',
            thuStatus: false,
            friStart: '',
            friEnd: '',
            friStatus: false,
            satStart: '',
            satEnd: '',
            satStatus: false,
            sunStart: '',
            sunEnd: '',
            sunStatus: false,
            initial: true,
            errorMassage: '',
            isValidationError: false,
            isChangesOpeningHours: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { tenantOpenHours } = prevProps;
        if (initial && tenantOpenHours && tenantOpenHours.id) {
            return {
                initial: false,
                monStart: tenantOpenHours.monStart,
                monEnd: tenantOpenHours.monEnd,
                monStatus: tenantOpenHours.monStatus,
                tueStart: tenantOpenHours.tueStart,
                tueEnd: tenantOpenHours.tueEnd,
                tueStatus: tenantOpenHours.tueStatus,
                wedStart: tenantOpenHours.wedStart,
                wedEnd: tenantOpenHours.wedEnd,
                wedStatus: tenantOpenHours.wedStatus,
                thuStart: tenantOpenHours.thuStart,
                thuEnd: tenantOpenHours.thuEnd,
                thuStatus: tenantOpenHours.thuStatus,
                friStart: tenantOpenHours.friStart,
                friEnd: tenantOpenHours.friEnd,
                friStatus: tenantOpenHours.friStatus,
                satStart: tenantOpenHours.satStart,
                satEnd: tenantOpenHours.satEnd,
                satStatus: tenantOpenHours.satStatus,
                sunStart: tenantOpenHours.sunStart,
                sunEnd: tenantOpenHours.sunEnd,
                sunStatus: tenantOpenHours.sunStatus,
            }
        }
        return null
    }

    componentDidMount() {
        this.getTenantDetails()
    }

    getTenantDetails = () => {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(actions.loadTenantOpenHoursSuccess({}))
        this.props.dispatch(actions.loadTenantOpenHoursRequest(id))
        this.setState({
            isChangesOpeningHours: false
        })
    }

    handleTimeChange = (seconds, id) => {
        var date = new Date(null);
        date.setSeconds(seconds);
        var hhmmssFormat = date.toISOString().substr(11, 8);
        this.setState({
            [id]: hhmmssFormat,
            isChangesOpeningHours: true
        })
    }

    handleChangeSwitch = (value, id) => {
        this.setState({
            [id]: value,
            isChangesOpeningHours: true
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.getTenantDetails()
        this.props.dispatch(actions.sendTenantDetailsSuccess(''))
        this.setState({
            initial: true
        })
    }

    componentDidUpdate() {
        if (this.props.tenantDetailsSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    render() {
        const {
            tenantDetailsError,
            isTenantDetailsLoading,
            tenantDetailsSuccess,
            handleTab,
            handleSubmit,
            userAccess
        } = this.props;
        return (
            <div>
                {(isTenantDetailsLoading) ?
                    <Loader show={isTenantDetailsLoading} /> : ''}

                {(this.state.isValidationError || tenantDetailsError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage ? this.state.errorMassage : tenantDetailsError}
                        handleClose={this.errorModalClose}
                    />}

                {(tenantDetailsSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={tenantDetailsSuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData', this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance', this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Finance</span>
                        <span onClick={() => handleTab('correspondence', this.state)}><ui5-icon class="samples-margin" name="letter" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Correspondence</span>
                        <span onClick={() => handleTab('openingHours', this.state)}><ui5-icon class="samples-margin" name="date-time" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Opening Hours</span>
                        <span onClick={() => handleTab('marketing', this.state)}><ui5-icon class="samples-margin" name="official-service" style={{ color: '#000000', width: '1.5rem', padding: '2px' }}></ui5-icon> Marketing</span>
                    </div>
                </div>
                <div className="profile-content">
                    <div className="row" style={{ margin: '1px' }}>
                        <div className="col-sm page_header">
                            <div className="page_header-title">
                                <span>Opening Hours</span>
                            </div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Day
                                            </th>
                                            <th>
                                                Status
                                            </th>
                                            <th>
                                                Start Time
                                            </th>
                                            <th>
                                                End Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Monday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.monStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'monStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="monStart"
                                                    onChange={(e) => this.handleTimeChange(e, "monStart")}
                                                    value={this.state.monStart}
                                                    disabled={!this.state.monStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="monEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "monEnd")}
                                                    value={this.state.monEnd}
                                                    disabled={!this.state.monStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tuesday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.tueStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'tueStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="tueStart"
                                                    onChange={(e) => this.handleTimeChange(e, "tueStart")}
                                                    value={this.state.tueStart}
                                                    disabled={!this.state.tueStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="tueEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "tueEnd")}
                                                    value={this.state.tueEnd}
                                                    disabled={!this.state.tueStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Wednesday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.wedStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'wedStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="wedStart"
                                                    onChange={(e) => this.handleTimeChange(e, "wedStart")}
                                                    value={this.state.wedStart}
                                                    disabled={!this.state.wedStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="wedEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "wedEnd")}
                                                    value={this.state.wedEnd}
                                                    disabled={!this.state.wedStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Thursday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.thuStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'thuStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="thuStart"
                                                    onChange={(e) => this.handleTimeChange(e, "thuStart")}
                                                    value={this.state.thuStart}
                                                    disabled={!this.state.thuStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="thuEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "thuEnd")}
                                                    value={this.state.thuEnd}
                                                    disabled={!this.state.thuStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Friday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.friStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'friStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="friStart"
                                                    onChange={(e) => this.handleTimeChange(e, "friStart")}
                                                    value={this.state.friStart}
                                                    disabled={!this.state.friStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="friEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "friEnd")}
                                                    value={this.state.friEnd}
                                                    disabled={!this.state.friStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Saturday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.satStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'satStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="satStart"
                                                    onChange={(e) => this.handleTimeChange(e, "satStart")}
                                                    value={this.state.satStart}
                                                    disabled={!this.state.satStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="satEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "satEnd")}
                                                    value={this.state.satEnd}
                                                    disabled={!this.state.satStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Sunday
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={this.state.sunStatus}
                                                    onChange={(e) => this.handleChangeSwitch(e, 'sunStatus')}
                                                    checkedIcon={false}
                                                    uncheckedIcon={false}
                                                    disabled={userAccess && !userAccess.modifyTenant}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="sunStart"
                                                    onChange={(e) => this.handleTimeChange(e, "sunStart")}
                                                    value={this.state.sunStart}
                                                    disabled={!this.state.sunStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                            <td>
                                                <TimePicker
                                                    controlId="sunEnd"
                                                    onChange={(e) => this.handleTimeChange(e, "sunEnd")}
                                                    value={this.state.sunEnd}
                                                    disabled={!this.state.sunStatus || (userAccess && !userAccess.modifyTenant)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="btnSave">
                        <Button
                            variant="outline-primary"
                            text='Save'
                            onClick={() => handleSubmit(this.state)}
                            disabled={userAccess && !userAccess.modifyTenant}
                            icon="save"
                        />
                    </div>
                </div>
            </div>
        )
    }
}


OpeningHours.propTypes = {
    tenantOpenHours: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
    tenantOpenHours: selectors.makeSelectTenantOpenHoursSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(OpeningHours));
