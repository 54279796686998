import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../settingMenu.css';
import * as selectors from '../../../../store/selector/itemSelector';
import { 
  makeSelectObjectStatusList,
  makeSelectUOMList
  } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/itemAction';
import Table from '../../../layout/form/Table';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import AddItem from './AddItem';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader'
import DeleteModal from '../../../layout/form/deleteModal';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import SearchField from '../../../layout/form/SearchField';

class Item extends Component {

  constructor(props) {
    super(props);

    this.state = { 
        isFilterFormOpen: false,
        isValidationError:false,
        errorMassage:'',
        selectedRow:{},
        isUpdate: false,
				isDeleteModalOpen:false,
				itemId:'',
				category:'',
				status:'',
				description:'',
        pageSize:20,
        pageNumber:1,
        commonSearch:'',
    }
  }
  
      handleModal = (data) => {
          if(data && data.itemId){
            this.setState({selectedRow:data, isUpdate: true})
          }
          this.props.dispatch(actions.saveIsItemModalOpenStatus(true));
      }
  
      handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsItemModalOpenStatus(false));
      }
  
      handleFilter = () => {
        this.setState({
						isFilterFormOpen:!this.state.isFilterFormOpen,
						itemId:this.state.itemId,
						category:this.state.category,
						status:this.state.status,
						description:this.state.description,
            commonSearch:''
        })
      }

      handleChangeSearchValue = (e)=> {
        if(e.target.value === "" || e.target.value === null){
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen:false,
            itemId:'',
            category:'',
            status:'',
            description:'',
            pageSize:20,
            pageNumber:1,
        })
      }

      handleFilterFormClose = () =>{
        this.setState({
          isFilterFormOpen:false,
					itemId:'',
					category:'',
					status:'',
					description:'',
          pageSize:20,
          pageNumber:1,
          commonSearch:''
        })

        const parameters= {
          pageSize:20,
          pageNumber:1,
          itemId:'',
          categoryId:'',
          description:'',
          status:'',
          commonSearch:''
        }
        this.props.dispatch(actions.loadItemListRequest(parameters))
      }

      handleSaveAddNew = (details) => {
        // console.log(details)
        if(this.state.isUpdate){
          this.updateItem(details)
        }else{
          this.addNewItem(details)
        }
      }

      updateItem = (details) =>{
        if(
          details.itemId ==='' || 
          details.description === ''|| 
          details.unitOfMeasure === ''|| 
          details.categoryId === ''|| 
          details.quantityInStock < 0 ||
          details.unitPrice < 0 ||
          details.status === ''){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
      }
        const parameters = {
          itemId: details.itemId,
          description: details.description,
          unitOfMeasure: details.unitOfMeasure,
          categoryId: details.categoryId,
          quantityInStock: details.quantityInStock,
          unitPrice: details.unitPrice,
          status: details.status,
          // salesPrice: details.salesPrice,
      }
        // console.log(details)
        this.props.dispatch(actions.sendUpdateItemRequest(parameters))
      }

      addNewItem = (details) => {
        if(
          details.itemId ==='' || 
          details.description === ''|| 
          details.unitOfMeasure === ''|| 
          details.categoryId === ''|| 
          details.quantityInStock < 0 ||
          details.unitPrice < 0 ||
          details.status === ''){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
      }
      const parameters = {
        itemId: details.itemId,
        description: details.description,
        unitOfMeasure: details.unitOfMeasure,
        categoryId: details.categoryId,
        quantityInStock: details.quantityInStock,
        unitPrice: details.unitPrice ,
        status: details.status,
        // salesPrice: details.salesPrice,
    }
    // console.log(parameters)
    this.props.dispatch(actions.sendAddNewItemRequest(parameters))
    }

    handleDeleteConfirm = (data) => {
      console.log(data.itemId)
      this.setState({isDeleteModalOpen:true, selectedRow:data})
		}
		
		handleDeleteModalClose = () =>{
			this.setState({isDeleteModalOpen:false, selectedRow:{}})
		}

    handleDeleteItem = () =>{
			this.handleDeleteModalClose()
			this.props.dispatch(actions.sendDeleteItemRequest(this.state.selectedRow.itemId))
		}

    errorModalClose = () => {
      this.setState({isValidationError:false, errorMassage:''})
      this.props.dispatch(actions.sendAddNewItemError(''))
      }

    successModalClose = () => {
      this.getItemListData()
      this.props.dispatch(actions.sendAddNewItemSuccess(''))
			this.props.dispatch(actions.sendUpdateItemSuccess(''))
			this.handleModalClose()
      this.props.dispatch(actions.loadItemListLoading(false))
      // this.props.dispatch(actions.saveIsItemModalOpenStatus(false));
    }

    getNextPage = () => {
      this.setState({
          pageNumber:this.state.pageNumber + 1
      })
  }

  getPrevPage = () => {
      this.setState({
          pageNumber:this.state.pageNumber - 1
      })
  }

  setClickedPage = (page) => {
      this.setState({
          pageNumber:page
      })
  }

  setPageSize = (size) => {
    this.setState({
        pageSize:size,
        pageNumber:1
    })
}

    getItemListData = () => {

      const parameters= {
        pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
        pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
        itemId:this.state.itemId,
        categoryId:this.state.category,
        description:this.state.description,
        status:this.state.status,
        commonSearch: this.state.commonSearch
      }
      if(this.state.isFilterFormOpen){
          this.setState({
            pageNumber:1,
            pageSize:20
          })
      }
      this.props.dispatch(actions.loadItemListRequest(parameters))
		}

    onPressKeySearch = (value) => {
      const parameters= {
        pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
        pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
        itemId:this.state.itemId,
        categoryId:this.state.category,
        description:this.state.description,
        status:this.state.status,
        commonSearch: value
      }
      if(this.state.isFilterFormOpen){
          this.setState({
              pageNumber:1,
              pageSize:20
          })
      }
      this.setState({
          commonSearch:value
      })
      this.props.dispatch(actions.loadItemListRequest(parameters))
  }
		
		handleChange = (e) => {
			this.setState({
					[e.target.id]: e.target.value
			})
		}

    handleChangeCategory = (value) =>{
      this.setState({category:value})
    }

    handleChangeStatus = (value) =>{
      this.setState({status:value})
    }

		handleSearch = () =>{
			this.getItemListData()
			this.handleFilter()
    }
    
    setUomDescription = (data) => {
      const { uomList } = this.props;
      if(uomList && uomList.length && uomList.length !== 0){
        const desc = uomList.find(select =>  select.id === data.unitOfMeasure)
        return (desc && desc.description ? desc.description : '')
      }
    }

    setStatusDescription= (data) => {
      const { objectStatusList } = this.props;
      if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
        const desc = objectStatusList.find(select =>  select.id === data.status)
        return (desc && desc.description ? desc.description : '')
      }
    }

    setCategoryDescription= (data) => {
      const { itemCategoryList } = this.props;
      if(itemCategoryList && itemCategoryList.length && itemCategoryList.length !== 0){
        const desc = itemCategoryList.find(select =>  select.id === data.categoryId)
        return (desc && desc.description ? desc.description : '')
      }
    }

    componentDidMount (){
      this.props.dispatch(loadObjectStatusSuccess({}))
      this.getItemListData()
      this.props.dispatch(actions.loadItemCategoryListRequest());
      this.props.dispatch(loadObjectStatusRequest(common.objectId.items));
      // this.props.dispatch(actions.loadObjectStatusRequest());
      // this.props.dispatch(actions.loadUOMRequest());
    }

    componentDidUpdate (prevProps, prevState){
      // if(this.state.isValidationError || this.props.addNewItemError !== ''){
      //     setTimeout(
      //         () => this.errorModalClose(), 
      //         500
      //       );
      // }else 
      if (this.props.addNewItemSuccess !== '' || this.props.updateItemSuccess !==''){
          setTimeout(
              () => this.successModalClose(), 
              1000
          );
      }

      if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
        this.getItemListData()
    }
  }

    render() {
        
        const {
          isAddNewItemModalOpen,
          itemCategoryList,
          objectStatusList,
          uomList,
          addNewItemError,
          addNewItemSuccess,
          isItemListLoading,
          itemList,
          isUpdateItemLoading,
          updateItemSuccess
        } = this.props;

        const {
          isFilterFormOpen,
          isValidationError,
          errorMassage,
          selectedRow,
          isDeleteModalOpen
        } =this.state;

        const columns = [{
            Header: 'Item Id',
            accessor: 'id',
            width: "8%",
          }, {
            Header: 'Description',
            accessor: 'description',
          }, {
            Header: 'Category',
            width: "10%",
            Cell:((row)=>(
              <div>
                {this.setCategoryDescription(row.row.original)}
              </div>
            ))
          }, {
            Header:'UOM',
            width: "10%",
            Cell:((row)=>(
              <div>
                {this.setUomDescription(row.row.original)}
              </div>
            ))
          }, {
            Header:'Unit Price',
            width: "8%",
            Cell:((row)=>(
              <div style={{
                      textAlign:"right"
                    }}>
                {parseFloat(row.row.original.unitPrice).toFixed(2)}
              </div>
            ))
          }, {
            Header: 'Quantity',
            width: "8%",
            Cell:((row)=>(
              <div style={{
                      textAlign:"right"
                    }}>
                {parseFloat(row.row.original.quantityInStock).toFixed(2)}
              </div>
            )),
            // accessor:'quantityInStock'
          }, {
            Header: 'Status',
            width: "10%",
            Cell:((row)=>(
              <div>
                {this.setStatusDescription(row.row.original)}
              </div>
            ))
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>    
                      <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]


        return (
            <div>
              {(isItemListLoading || 
                isUpdateItemLoading ||
                (itemList.result && itemList.result.length && itemList.result.length === 0) || 
                (itemCategoryList && itemCategoryList.length && itemCategoryList.length === 0) ||
                (objectStatusList && objectStatusList.length && objectStatusList.length === 0) ||
                (uomList && uomList.length && uomList.length === 0)) ?
                <Loader show={isItemListLoading}/> : ''}
              {(isValidationError || addNewItemError !== '') && 
                    <ErrorModal
                        show={true} 
                        massage={addNewItemError === ''  ? errorMassage : addNewItemError}
                        handleClose={this.errorModalClose}
                    />}
                {(addNewItemSuccess !== '' || updateItemSuccess !== '') && 
                    <SuccessModal
                        show={true} 
                        massage={addNewItemSuccess ? addNewItemSuccess : updateItemSuccess}
                        handleClose={this.successModalClose}
                    />}
								{isDeleteModalOpen && 
									<DeleteModal 
										show={isDeleteModalOpen}
										handleDeleteModalClose={this.handleDeleteModalClose}
										handleDelete={this.handleDeleteItem}
										title={selectedRow.description}
									/>}
              <div className="page-header">
                <div className="btn-group">
                    <ButtonGroup
                        primaryBtnClick={this.handleModal}
                        secondaryBtnClick={this.handleFilter}
                        primaryBtnVariant="outline-primary"
                        primaryBtnText='Add New'
                        primaryBtnIcon='sys-add'
                        secondaryBtnText='Filter'
                        secondaryBtnVariant="outline-primary" 
                        secondaryBtnIcon='filter'
                    />
                </div>
              </div>
                <div className="body-content">
                    <div style={{display:'flex'}}>
                        <div>
                            <h5 style={{marginLeft:'5px'}}>Item List</h5>
                        </div>
                        <div style={{marginLeft:'20px'}}>
                            <SearchField
                                onClickSearch={this.getItemListData}
                                controlId="commonSearch"
                                onChange={this.handleChangeSearchValue}
                                defaultValue={this.state.commonSearch}
                                onPressKeySearch={this.onPressKeySearch}
                            />
                        </div>
                    </div>
                    {isFilterFormOpen && 
                    <div className="filter-form">
                        <div className="filter-header">
                          <h5>Filter Form</h5>
                          <div className="btn-close" onClick={this.handleFilterFormClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                          </div>
                        </div>
                        <div className="row">
                                <div className="col-sm-3">
                                    <TextField                                            
                                        text="Item Id"
                                        controlId="itemId"
                                        placeholder="Item Id"
                                        type="text"
                                        onChange={this.handleChange}
                                        maxLength={common.maxLengths.itemId}
                                        defaultValue={this.state.itemId}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <SelectField                                            
                                        text="Category"
                                        controlId="category"
																				onChange={this.handleChangeCategory}
                                        options={itemCategoryList}
                                        selectedValue={this.state.category}
                                    />
                                </div>
                                <div className="col-sm">
                                    <SelectField
                                        text="Status"
                                        controlId="status"
																				onChange={this.handleChangeStatus}
																				options={objectStatusList}
                                        selectedValue={this.state.status}
                                    />
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                    <TextField                                            
                                        text="Description"
                                        controlId="description"
                                        placeholder="Description"
                                        type="text"
                                        onChange={this.handleChange}
                                        maxLength={common.maxLengths.itemDescription}
                                        defaultValue={this.state.description}
                                    />
                              </div>
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleSearch}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                    </div>
                    }
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={itemList.result && itemList.result.length ? itemList.result : []}                   
                        />
                    </div>
                </div>
                <Pagination
                    currentPage={itemList.page ? itemList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {itemList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewItemModalOpen && 
                    <AddItem
                        isAddNewItemModalOpen={isAddNewItemModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        itemCategoryList={itemCategoryList}
                        objectStatusList={objectStatusList}
                        uomList={uomList}
                        selectedRow={selectedRow}
                        isUpdate={this.state.isUpdate}
                    />}
            </div>
        )
    }
}

Item.propTypes = {
    isAddNewItemModalOpen: PropTypes.bool,
    itemCategoryList: PropTypes.any,
    objectStatusList: PropTypes.any,
    uomList: PropTypes.any,
    addNewItemError: PropTypes.any,
    addNewItemSuccess: PropTypes.any,
    isItemListLoading: PropTypes.bool,
    itemList: PropTypes.any,
    isUpdateItemLoading: PropTypes.bool,
    updateItemSuccess: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    isAddNewItemModalOpen: selectors.makeSelectItemPopupOpen(),
    itemCategoryList: selectors.makeSelectItemCategoryList(),
    objectStatusList:makeSelectObjectStatusList(),
    uomList: makeSelectUOMList(),
    addNewItemSuccess: selectors.makeSelectAddNewItemSuccess(),
    addNewItemError: selectors.makeSelectAddNewItemError(),
    isItemListLoading: selectors.makeSelectItemListLoading(),
    itemList: selectors.makeSelectItemList(),
    isUpdateItemLoading: selectors.makeSelectUpdateItemLoading(),
    updateItemSuccess: selectors.makeSelectUpdateItemSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Item));