import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Modal from '../../../layout/form/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
// import SelectField from '../../layout/form/SelectField';
import TextField from '../../../layout/form/TextField';
// import DatePicker from '../../layout/form/datePicker';
import common from '../../../common';
import moment from 'moment';
import { loadSearchedVehicleRequest, saveCustomerModalOpenStatus, sendNewCustomerSuccess } from '../../../../store/action/bookingAction';
import { loadCustomerListRequest, loadCustomerListSuccess, loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
import DateTimePicker from '../../../layout/form/dateTimePicker';
import CustomSelectField from '../../../layout/form/CustomSelectField';

const today = new Date() 
export class AddBooking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vehicleRegistrationNo:'',
            customer:'',
            email:'',
            phoneNumber:'',
            date:moment(today).format(common.dateTimeFormat),
            notes:'',
            initial:true,
            isChangeRegNo:false,
            customerId:0,
            vehicleId:0,
            isNewCustomerList: false,
            customers: []
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { isUpdate, selectedRow,isSelectedSlot,slots, customerList,isNewCustomer ,selectedCustomer } = prevProps;
        const { initial } = prevState
        const customers = []
        if(isUpdate && selectedRow && selectedRow.id && initial && customerList && customerList.result){
            for(let i = 0; i < customerList.result.length; i++){
                const item = customerList.result[i]
                const element = {
                    label: item.displayName,
                    value: item.id,
                }
                customers.push(element)
            }
            return{
                vehicleRegistrationNo:selectedRow.vehicleRegistrationNumber,
                customer:selectedRow.customerName,
                email:selectedRow.customerEmail ? selectedRow.customerEmail : null,
                phoneNumber:selectedRow.customerPhone,
                date:moment(selectedRow.requestedDate).format(common.dateTimeFormat),
                notes:selectedRow.notes,
                initial:false,
                customerId:selectedRow.customerId,
                vehicleId:selectedRow.vehicleId,
                id:selectedRow.id,
                jobcardId:selectedRow.jobcardId,
                customers:customers
            }
        }
        else if(isSelectedSlot && slots !== '' && initial && customerList && customerList.result  && !isNewCustomer){
            const timeSlot = slots[0]
            for(let i = 0; i < customerList.result.length; i++){
                const item = customerList.result[i]
                const element = {
                    label: item.displayName,
                    value: item.id,
                }
                customers.push(element)
            }
            return{
                initial:false,
                date:moment(timeSlot).format(common.dateTimeFormat),
                customers:customers
            }
        }
        else if( initial && customerList && customerList.result && isNewCustomer && selectedCustomer && selectedCustomer.id){
            const timeSlot = slots[0]
            for(let i = 0; i < customerList.result.length; i++){
                const item = customerList.result[i]
                const element = {
                    label: item.displayName,
                    value: item.id,
                }
                customers.push(element)
            }
            return{
                initial:false,
                date:moment(timeSlot).format(common.dateTimeFormat),
                customers:customers,
                isNewCustomerList: true,
                customer:selectedCustomer.displayName,
                email: selectedCustomer.emailAddress,
                phoneNumber:selectedCustomer.mobilePhone,
                customerId:selectedCustomer.id,
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
        if(!this.props.isUpdate && e.target.id === 'vehicleRegistrationNo' && e.target.value !==''){
            const parameters= {
                pageSize:9999,
                pageNumber:1,
                Customer:'',
                Make:'',
                Model:'',
                Search:e.target.value,
                Status:'',
                commonSearch:''
            }
            this.props.dispatch(loadSelectedCustomerSuccess({}))
            this.props.dispatch(loadSearchedVehicleRequest(parameters))
            this.setState({
                isChangeRegNo:true,
                customerId:0,
                vehicleId:0
            })
        }
    }

    handleDateChange = (date, id) => {
        if(date === null){
            this.setState({
                [id]: moment(today).format(common.dateTimeFormat),
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateTimeFormat),
            })
        }
    }

    handleChangeCustomer = (id) => {
        const { customerList } = this.props
        const customer = customerList.result.find(row => row.id === id)
        this.setState({
            customerId: id,
            customer:customer.displayName,
            email: customer.emailAddress,
            phoneNumber:customer.mobilePhone,
        })
    }

    getCustomerList = () => {
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            name: '',
            email: '',
            mobile: '',
            commonSearch: '',
            city:''
        }
        this.props.dispatch(loadCustomerListRequest(parameters))
    }

    componentDidMount = () =>{
        this.props.dispatch(loadCustomerListSuccess([]))
        this.getCustomerList()
        this.props.dispatch(sendNewCustomerSuccess(false))
        this.props.dispatch(saveCustomerModalOpenStatus(false))
    }

    componentDidUpdate (prevProps, prevState){
        const { customerList } = this.props
        const { isNewCustomerList } = this.state
  
        if(isNewCustomerList && customerList && customerList.result){
            const customers = []
            for(let i = 0; i < customerList.result.length; i++){
                const item = customerList.result[i]
                const element = {
                    label: item.displayName,
                    value: item.id,
                }
                customers.push(element)
            }
            this.setState({
                isNewCustomerList: false,
                customers: customers
            })
        }
    }

    render() {
        const {
            isAddNewBookingModalOpen,
            handleModalClose,
            // handleConfirm,
            selectedRow,
            isUpdate,
            handleSaveAddNew,
            // handleCreateJobcard,
            handleDeleteConfirm,
            // handleBookingReminder,
            handleCreate
        } = this.props;

        return (
            <div>
                {((isUpdate && selectedRow && selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewBookingModalOpen} 
                    onHide={handleModalClose}
                    title="Booking Information"
                    width='40%'
                    body={
                        <div>
                            {/* <div className="col-sm">
                                <TextField
                                    text="Vehicle Registration No"
                                    controlId="vehicleRegistrationNo"
                                    // placeholder="Email"
                                    type="text"
                                    onBlur={this.handleChange}
                                    required
                                    defaultValue={this.state.vehicleRegistrationNo}
                                    // maxLength={common.maxLengths.customerEmail}
                                />
                            </div> */}
                            <div className="col-sm">
                                {/* <SelectField
                                    text="Customer"
                                    controlId="customer"
                                    // options={customerList.result}
                                    // onChange={(e) => this.handleChange(e,"customer")}
                                    selectedValue={this.state.customer}
                                    display='displayName'
                                    isClearable
                                /> */}
                                {/* <TextField
                                    text="Customer"
                                    controlId="customer"
                                    // placeholder="Email"
                                    type="text"
                                    onBlur={this.handleChange}
                                    defaultValue={this.state.customer}
                                    maxLength={common.maxLengths.customerEmail}
                                    required
                                /> */}
                                <CustomSelectField
                                    text="Customer"
                                    controlId="customer"
                                    options={this.state.customers}
                                    selectedValue={this.state.customerId}
                                    onChange={(e)=>this.handleChangeCustomer(e)}
                                    display="displayName"
                                    handleCreate={handleCreate}
                                    required
                                />
                            </div>
                            <div className="col-sm">
                                <TextField
                                    text="Email"
                                    controlId="email"
                                    // placeholder="Email"
                                    type="email"
                                    onBlur={this.handleChange}
                                    defaultValue={this.state.email}
                                    maxLength={common.maxLengths.customerEmail}
                                />
                            </div>
                            <div className="col-sm">
                                <TextField
                                    text="Phone Number"
                                    controlId="phoneNumber"
                                    // placeholder="Phone"
                                    type="text"
                                    onBlur={this.handleChange}
                                    defaultValue={this.state.phoneNumber}
                                    maxLength={common.maxLengths.customerPhone}
                                />
                            </div>
                            <div className="col-sm">
                                <DateTimePicker
                                    text="Date"
                                    controlId="date"
                                    onChange={(e) => this.handleDateChange(e,"date")}
                                    value={this.state.date === '' ? '' :new Date(this.state.date)}
                                    required
                                    // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <TextField
                                    text="Notes"
                                    as="textarea"
                                    row={50}
                                    controlId="notes"
                                    defaultValue={this.state.notes}
                                    style={{ height: 200 }}
                                    onBlur={this.handleChange}
                                    // placeholder="Notes"
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div>
                            <div className="filter-btns" style={{marginRight:'15px'}}>
                               {!isUpdate &&  <ButtonGroup
                                    primaryBtnClick={handleModalClose}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Go Back'
                                    primaryBtnIcon='undo'
                                    secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                    secondaryBtnText='Save'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='save'
                                />}
                                {isUpdate && 
                                <div style={{display:'flex'}}>
                                <ButtonGroup
                                    primaryBtnClick={()=>handleDeleteConfirm(selectedRow)}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Delete'
                                    primaryBtnIcon='delete'
                                    secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                    secondaryBtnText='Save'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='save'
                                />
                                {/* <ButtonGroup
                                    // primaryBtnClick={() => handleConfirm(this.state)}
                                    primaryBtnClick={
                                        selectedRow.status === "10"? () => handleBookingReminder(selectedRow) : () => handleConfirm(this.state)
                                    }
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText={
                                        selectedRow.status === "10"? 'Send Reminder':'Confirm'}
                                    primaryBtnIcon='accept'
                                    secondaryBtnClick={() => handleCreateJobcard(this.state)}
                                    secondaryBtnText='Create JobCard'
                                    secondaryBtnVariant="outline-primary" 
                                    secondaryBtnIcon='customer-order-entry'
                                /> */}
                                </div>}
                            </div>
                        </div>
                    }
                />
                }
            </div>
        )
    }
}

AddBooking.propTypes = {
    isAddNewBookingModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    selectedRow: PropTypes.any,
    handleConfirm: PropTypes.any,
    isUpdate: PropTypes.bool,
    handleSaveAddNew: PropTypes.any,
    vehicleList: PropTypes.any,
    selectedCustomer: PropTypes.any,
    handleCreateJobcard: PropTypes.any,
    bookingVehicleDetails: PropTypes.any,
    isBookingUsingVehicle: PropTypes.any,
    handleDeleteConfirm: PropTypes.any,
    handleBookingReminder: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(AddBooking));
