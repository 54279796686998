import React, { Component } from 'react';
import TextField from '../layout/form/TextField';
import Button from '../layout/form/Button';
import { Link } from 'react-router-dom';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import { Link } from 'react-router-dom';
import VemasLogo from '../../images/VEMAS.png';
import "./auth.css";
import { sendConfirmationCodeRequest } from '../service/authService';
import common from '../common';
// import ErrorModal from '../layout/form/errorModal';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        const user = localStorage.getItem('user')
        // localStorage.removeItem('user')
        this.state = {
        email:user ? user :'',
        isError:false,
        errorMassage:''
    }
}

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.email === ''){
            this.setState({isError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }
        
        localStorage.setItem('user', this.state.email)
        const parameters = {
            Email: this.state.email,
        }
        sendConfirmationCodeRequest(parameters).then(
            (res) =>{ 
                // console.log(res)
                this.props.history.push("/changePassword")
            },
            (err) => {
                // console.log(err.response.data.detail)
                const massage = err.response.data.detail
                this.setState({isError:true, errorMassage:massage})
            }
        )
    }

    // errorModalClose = () => {
    //     this.setState({isError:false, errorMassage:''})
    // }

    componentDidUpdate (){
        if(this.state.isError){
            setTimeout(
                () => this.setState({ isError:false, errorMassage:''}), 
                2000
              );
        }
    }

    render() {
        const { isError, errorMassage } = this.state;
        return (
            <div>
                {/* {isError && 
                    <ErrorModal 
                        show={true} 
                        massage={errorMassage}
                        handleClose={this.errorModalClose}
                    />} */}
            <div className="auth-form">
                <img alt="vemaslogo" id="vemaslogo" src={VemasLogo}/>
                <form>
                    <h5>Forgot User Id/Password</h5>
                        <TextField
                            controlId="email"
                            // text="Email"
                            placeholder="Email"
                            type="email"
                            onChange={this.handleChange}
                            defaultValue={this.state.email}
                        />
                        {isError ?
                                <div className="authErrorMassage">
                                    <span><ui5-icon class="samples-margin" name="message-error" id="messageError"></ui5-icon></span>
                                    {errorMassage}
                                </div> : 
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <Button 
                                variant="primary" 
                                text ='Send'
                                onClick={this.handleSubmit}
                            />
                        </div>}
                        <div className="auth-pages">
                            <div>Back to <Link to='/'> Sign In</Link></div>
                        </div>
                </form>    
            </div>
            
            </div>
        )
    }
}

export  default ForgotPassword;
