import { put, call } from 'redux-saga/effects';
import {
  addNewSupplierRequest,
  getSupplierList,
  updateSupplierRequest,
  deleteSupplierRequest,
  getSelectedSupplierRequest
} from '../service/supplierService';
import * as actions from '../../store/action/supplierAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewSupplierRequest(data){
  //console.log('sendAddNewSupplierRequest')
  yield put(actions.loadSupplierListError(''))
  try{
    // yield put(actions.loadSupplierListLoading(true));
    const response = yield call(addNewSupplierRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      //console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendSupplierSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadSupplierListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewSupplierLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewSupplierLoading(false));
    //console.log(error.response.data.detail)
    const massage = common.error
    yield put(actions.loadSupplierListError(massage))
  }
}

export function* loadSupplierListRequest(data){
  //console.log('loadSupplierListRequest')
  yield put(actions.loadSupplierListError(''))
  try{
    yield put(actions.loadSupplierListLoading(true));
    const response = yield call(getSupplierList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const supplierList = response.data.result
      yield put(actions.loadSupplierListSuccess(supplierList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadSupplierListLoading(false));
      const massage = common.error
      yield put(actions.loadSupplierListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadSupplierListLoading(false));
  }catch(error){
    yield put(actions.loadSupplierListLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.loadSupplierListError(massage))
  }
}

export function* sendUpdateSupplierRequest(data){
  //console.log('sendUpdateSupplierRequest')
  yield put(actions.loadSupplierListError(''))
  try{
    // yield put(actions.sendUpdateSupplierLoading(true));
    const response = yield call(updateSupplierRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendSupplierSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadSupplierListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateSupplierLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateSupplierLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadSupplierListError(massage))
  }
}

export function* sendDeleteSupplierRequest(data){
  //console.log('sendDeleteSupplierRequest')
  yield put(actions.loadSupplierListError(''))
  try{
    yield put(actions.loadSupplierListLoading(true));
    const response = yield call(deleteSupplierRequest,data.payload);
    if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 404 && response.data.result.code ){
      var massage = common.massages.supplierDeleteMassage
      const errorCode = common.deleteMassages.find(element => element.id === response.data.result.code)
      if(errorCode){
        massage = errorCode.description
      }
      yield put(actions.loadSupplierListError(massage)) 
    } 
    else if(response && response.status && response.status === 200 && response.data &&  response.data.statusCode &&  response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendSupplierSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadSupplierListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadSupplierListLoading(false));
  }catch(error){
    yield put(actions.loadSupplierListLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadSupplierListError(massage))
  }
}

export function* loadSelectedSupplierRequest(data){
  //console.log('loadSelectedSupplierRequest')
  yield put(actions.loadSupplierListError(''))
  try{
    yield put(actions.loadSupplierListLoading(true));
    const response = yield call(getSelectedSupplierRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data)
      const supplier = response.data
      yield put(actions.loadSelectedSupplierSuccess(supplier))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      yield put(actions.loadSupplierListLoading(false));
      const massage = common.error
      yield put(actions.loadSupplierListError(massage))
    }
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadSupplierListLoading(false));
  }catch(error){
    yield put(actions.loadSupplierListLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.loadSupplierListError(massage))
  }
}
