import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUOMList } from '../../../../store/selector/selector';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';
import FormGroup from 'react-bootstrap/cjs/FormGroup';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
import DateTimePicker from '../../../layout/form/dateTimePicker';
import Table from '../../../layout/form/Table';
import PdfViewer from '../../../layout/form/PdfViewer';
// import { loadSelectedJobCardRequest, loadSelectedJobCardSuccess } from '../../../../store/action/jobCardAction';
import DatePicker from '../../../layout/form/datePicker';
import moment from 'moment';
import common, { calculateTotal } from '../../../common';
import { loadSelectedSupplierRequest, loadSelectedSupplierSuccess } from '../../../../store/action/supplierAction';
import { makeSelectSelectedSupplierSuccess } from '../../../../store/selector/supplierSelector';
import Form from 'react-bootstrap/cjs/Form';

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

const customStylesDiscount = {
    control: base => ({
        ...base,
        height: 30,
        minHeight: 38,
        marginBottom: 0,
        marginTop: -20
    })
};

const date = new Date()
const currentDate = moment(date).format(common.dateFormat)
const hours = 17;
const minutes = 10;
var timeAndDate = moment(currentDate).set("hour", hours).set("minute", minutes);

export class AddNewPurchasing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            jobCardNo: '',
            supplier: '',
            email: '',
            orderDate: date,
            referenceNo: '',
            purchasingItems: [],
            isDelete: false,
            subTotal: 0,
            discountValue: 0,
            tax: 0,
            total: 0,
            discountTypeValue: 0,
            discountType: '',
            supplierNotes: '',
            initial: true,
            tempPurchasingItemsList: [],
            selectedJobCardId: '',
            isSupplierChanged: false,
            selectedTaxRate: 0,
            isWarning: false,
            selectedJobCardDetails: '',
            address: '',
            requiredTime: timeAndDate,
            isGetSelectedSupplier: false,
            isPriceInclusiveTax: false,
            gstCalValue: 0,
            isUserAccess: false
        }
        // this.handleRowDescription = this.handleRowDescription.bind(this);
    }


    static getDerivedStateFromProps(prevProps, prevState) {
        const {
            selectedPurchasing, isUpdate, selectedRow,
            objectStatusList, tenantFinance, isNewPurchasing, selectedSupplier, userAccess, supplierList
        } = prevProps;
        var access = false
        if (selectedPurchasing && selectedPurchasing.id && prevState.initial && isUpdate && selectedPurchasing.id === selectedRow.id) {
            if (userAccess && userAccess.modifyPurchaseOrder) {
                access = true
            }
            var itemList = []

            const supplier = supplierList.find(data => (data.id === selectedPurchasing.supplier.id))
            if (selectedPurchasing.purchaseOrderItems.length !== 0) {
                for (let i = 0; i < selectedPurchasing.purchaseOrderItems.length; i++) {
                    const element = selectedPurchasing.purchaseOrderItems[i];

                    const newRow = {
                        'lineNumber': '',
                        "id": element.id,
                        "description": element.description,
                        "unitOfMeasure": element.unitOfMeasure,
                        "quantity": element.quantity,
                        "price": element.price,
                        "subTotal": element.subTotal,
                        "operation": "U",
                        "tax": element.tax ? element.tax : '',
                        'supplierPartNo': element.supplierPartNo ? element.supplierPartNo : '',
                        'partId': element.partId ? element.partId : '',
                    }
                    itemList.push(newRow)
                }
            }
            const values = calculateTotal(tenantFinance.taxRate, itemList, selectedPurchasing.taxIncPrice, selectedPurchasing.discountType, selectedPurchasing.discountTypeValue)

            return {
                status: selectedPurchasing.statusId,
                supplier: selectedPurchasing.supplier.id,
                email: supplier.email ? supplier.email : '',
                address:
                    (supplier.streetName ? supplier.streetName + ' ,' : '') +
                    (supplier.streetNo ? supplier.streetNo + ' ,\n' : '') +
                    (supplier.region ? supplier.region + ' ,' : '') +
                    supplier.cityName,
                jobCardNo: selectedPurchasing.jobcardId,
                orderDate: moment(selectedPurchasing.orderDate).format(common.dateFormat),
                requiredTime: moment(selectedPurchasing.requiredTime).format(common.dateTimeFormat),
                referenceNo: selectedPurchasing.referenceNo,
                purchasingItems: itemList,
                subTotal: selectedPurchasing.subTotal,
                discountValue: selectedPurchasing.discountValue,
                tax: selectedPurchasing.tax,
                selectedTaxRate: selectedPurchasing.taxRate,
                total: selectedPurchasing.total,
                discountTypeValue: selectedPurchasing.discountTypeValue,
                discountType: selectedPurchasing.discountType,
                supplierNotes: selectedPurchasing.notes ? selectedPurchasing.notes : '',
                initial: false,
                isGetSelectedSupplier: true,
                isPriceInclusiveTax: selectedPurchasing.taxIncPrice,
                gstCalValue: values.gstCalValue,
                isUserAccess: access
            }
        }
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            isNewPurchasing && prevState.initial && tenantFinance && tenantFinance.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            if (userAccess && userAccess.createPurchaseOrder) {
                access = true
            }
            return {
                status: defaultStatus[0].id,
                initial: false,
                selectedTaxRate: tenantFinance.taxRate,
                isUserAccess: access
                // paymentTerm:1
            }
        }
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            selectedSupplier && selectedSupplier.id && prevState.initial && tenantFinance && tenantFinance.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            if (userAccess && userAccess.createPurchaseOrder) {
                access = true
            }
            // const selctedSupplier = supplierList.find(row => row.id === supplierId)
            return {
                status: defaultStatus[0].id,
                initial: false,
                selectedTaxRate: tenantFinance.taxRate,
                supplier: selectedSupplier.id,
                address:
                    (selectedSupplier.streetName ? selectedSupplier.streetName + ' ,' : '') +
                    (selectedSupplier.streetNo ? selectedSupplier.streetNo + ' ,\n' : '') +
                    (selectedSupplier.region ? selectedSupplier.region + ' ,' : '') +
                    selectedSupplier.cityName,
                email: selectedSupplier.email ? selectedSupplier.email : '',
                isUserAccess: access
            }
        }
        return null
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateTimeChange = (date, id) => {
        this.setState({
            [id]: date
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleCheck = (e) => {
        const { discountType, discountTypeValue } = this.state;
        this.setState({
            isPriceInclusiveTax: e.target.checked
        })
        this.calTotal(this.state.purchasingItems, e.target.checked, discountType, discountTypeValue)
    }

    handleChangeDiscountType = (e, valueType) => {
        const { purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;

        if (valueType === "discountType") {
            this.setState({
                discountType: e
            })
            this.calTotal(purchasingItems, isPriceInclusiveTax, e, discountTypeValue)
        } else {
            this.setState({
                [e.target.id]: e.target.value
            })
        }

        if (e && e.target && e.target.id === 'discountTypeValue') {
            this.calTotal(purchasingItems, isPriceInclusiveTax, discountType, e.target.value)
        }

        if (valueType === 'discountType' && (e === '' || e === null)) {
            this.setState({
                discountTypeValue: 0
            })
            this.calTotal(purchasingItems, isPriceInclusiveTax, discountType, 0)
        }
    }

    // handleChangeJobCard = (id) => {
    //     this.props.dispatch(loadSelectedJobCardSuccess({}))
    //     const jobCardId = parseInt(id)
    //     const { jobCardList, supplierList } = this.props;
    //     const selectedJobCard = jobCardList.find(row => row.id === jobCardId)
    //     this.props.dispatch(loadSelectedJobCardRequest(id))
    //     const selctedSupplier = supplierList.find(row => row.id === selectedJobCard.supplierId)

    //     if (selectedJobCard.statusId === "30") {
    //         this.setState({
    //             isWarning: true,
    //             selectedJobCardDetails: selectedJobCard
    //         })
    //     } else {
    //         this.setState({
    //             jobCardNo: selectedJobCard.id,
    //             supplier: selectedJobCard.supplierId,
    //             vehicleNo: selectedJobCard.vehicleId,
    //             printName: selctedSupplier.displayName,
    //             email: selctedSupplier.emailAddress ? selctedSupplier.emailAddress : '',
    //             selectedJobCardId: jobCardId,
    //             isChangedJobCard: true
    //         })
    //     }
    // }

    handleWarningModalClose = () => {
        const { objectStatusList, paymentTermList, tenantFinance } = this.props;
        const defaultStatus = objectStatusList.filter(data => (data.default === true))
        const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
        const today = new Date()
        const dueDate = new Date()
        dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
        this.setState({
            isWarning: false,
            selectedJobCardDetails: '',
            status: defaultStatus[0].id,
            orderDate: moment(today).format(common.dateFormat),
            dueDate: moment(dueDate).format(common.dateFormat),
            paymentTerm: defaultPaymentTerm[0].id,
            selectedTaxRate: tenantFinance.taxRate,
            jobCardNo: '',
            vehicleNo: '',
            supplier: '',
            printName: '',
            email: '',
            invoiceNo: '',
            referenceNo: '',
            purchasingItems: [],
            subTotal: 0,
            discountValue: 0,
            tax: 0,
            total: 0,
            discountTypeValue: 0,
            discountType: '',
            supplierNotes: '',
        })
    }

    handleChangeSupplier = (id) => {
        this.props.dispatch(loadSelectedSupplierSuccess(''))
        const supplierId = parseInt(id)
        const { supplierList } = this.props;
        const selectedSupplier = supplierList.find(row => row.id === supplierId)
        // this.props.dispatch(loadSelectedSupplierRequest(selctedSupplier.id))
        this.setState({
            supplier: selectedSupplier.id,
            address:
                (selectedSupplier.streetName ? selectedSupplier.streetName + ' ,' : '') +
                (selectedSupplier.streetNo ? selectedSupplier.streetNo + ' ,\n' : '') +
                (selectedSupplier.region ? selectedSupplier.region + ' ,' : '') +
                selectedSupplier.cityName,
            email: selectedSupplier.email ? selectedSupplier.email : '',
        })
    }

    handleDateChange = (date, id) => {
        if (date === null || date === '') {
            this.setState({
                [id]: '',
            })
        } else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }

    }

    addNewRow = () => {
        var { purchasingItems } = this.state;
        var newRow = { "lineNumber": "", "description": "", operation: "C", "partId": '' }
        purchasingItems.push(newRow)
        this.setState({ purchasingItems })
    }

    handleRowData = (id, rowData) => {
        // console.log(e.target.value, rowData) //TODO
        const { partList } = this.props;
        const { purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = partList.find(row => row.id === id)
        const newRow = {
            // 'id':selectedRow.partId,
            'description': selectedRow.description,
            'supplierPartNo': selectedRow.sku,
            'partId': selectedRow.sku,
            operation: "C",
            'unitOfMeasure': selectedRow.unitOfMeasureId,
            "price": selectedRow.unitPrice,
            "quantity": 0,
            "subTotal": 0
        }
        purchasingItems[rowData.index] = newRow
        this.setState({ purchasingItems })
        this.calTotal(purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue)
    }

    handleRowSupplierPartNo = (e, rowData) => {
        const { purchasingItems } = this.state;
        // const { isUpdate } = this.props
        const selectedRow = purchasingItems[rowData.index]
        const newRow = {
            'lineNumber': '',
            'description': selectedRow.description ? selectedRow.description : '',
            'supplierPartNo': e.target.value,
            'partId': selectedRow.partId ? selectedRow.partId : '',
            operation: rowData.original.id ? "U" : "C",
            'id': rowData.original.id ? rowData.original.id : 0,
            'unitOfMeasure': selectedRow.unitOfMeasure ? selectedRow.unitOfMeasure : '',
            "price": selectedRow.price ? selectedRow.price : 0,
            "quantity": selectedRow.quantity ? selectedRow.quantity : 0,
            "salesCategoryId": selectedRow.salesCategoryId,
            "subTotal": (selectedRow.price && selectedRow.quantity ? selectedRow.price * selectedRow.quantity : 0)
        }
        setTimeout(
            purchasingItems[rowData.index] = newRow,
            () => this.setState({ purchasingItems }),
            10
        );
    }

    handleRowDescription = (e, rowData) => {
        const { purchasingItems } = this.state;
        // const { isUpdate } = this.props
        const selectedRow = purchasingItems[rowData.index]
        const newRow = {
            'lineNumber': '',
            'description': e.target.value,
            'supplierPartNo': selectedRow.supplierPartNo ? selectedRow.supplierPartNo : '',
            'partId': selectedRow.partId ? selectedRow.partId : '',
            operation: rowData.original.id ? "U" : "C",
            'id': rowData.original.id ? rowData.original.id : 0,
            'unitOfMeasure': selectedRow.unitOfMeasure ? selectedRow.unitOfMeasure : '',
            "price": selectedRow.price ? selectedRow.price : 0,
            "quantity": selectedRow.quantity ? selectedRow.quantity : 0,
            "salesCategoryId": selectedRow.salesCategoryId,
            "subTotal": (selectedRow.price && selectedRow.quantity ? selectedRow.price * selectedRow.quantity : 0)
        }
        setTimeout(
            purchasingItems[rowData.index] = newRow,
            () => this.setState({ purchasingItems }),
            10
        );
    }

    handleRowUnitOfMeasure = (id, rowData) => {
        const { purchasingItems } = this.state;
        // const { isUpdate } = this.props
        const selectedRow = purchasingItems[rowData.index]
        const newRow = {
            'lineNumber': '',
            'description': selectedRow.description ? selectedRow.description : '',
            'supplierPartNo': selectedRow.supplierPartNo ? selectedRow.supplierPartNo : '',
            'partId': selectedRow.partId ? selectedRow.partId : '',
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': id,
            'id': rowData.original.id ? rowData.original.id : 0,
            "price": selectedRow.price ? selectedRow.price : 0,
            "quantity": selectedRow.quantity ? selectedRow.quantity : 0,
            "salesCategoryId": selectedRow.salesCategoryId,
            "subTotal": (selectedRow.price && selectedRow.quantity ? selectedRow.price * selectedRow.quantity : 0)
        }
        purchasingItems[rowData.index] = newRow
        this.setState({ purchasingItems })
    }

    handleRowUnitPrice = (e, rowData) => {
        const { purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        // const { isUpdate } = this.props
        const selectedRow = purchasingItems[rowData.index]
        const newRow = {
            'lineNumber': '',
            'description': selectedRow.description ? selectedRow.description : '',
            'supplierPartNo': selectedRow.supplierPartNo ? selectedRow.supplierPartNo : '',
            'partId': selectedRow.partId ? selectedRow.partId : '',
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': selectedRow.unitOfMeasure ? selectedRow.unitOfMeasure : '',
            "price": e.target.value,
            'id': rowData.original.id ? rowData.original.id : 0,
            "quantity": selectedRow.quantity ? selectedRow.quantity : 0,
            "salesCategoryId": selectedRow.salesCategoryId,
            "subTotal": (e.target.value && selectedRow.quantity ? e.target.value * selectedRow.quantity : 0)
        }
        // setTimeout(
        purchasingItems[rowData.index] = newRow
        this.calTotal(purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue)
        // console.log(repairTotal) 
        //     10
        // );
    }

    handleRowQuantity = (e, rowData) => {
        const { purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        // const { isUpdate } = this.props
        const selectedRow = purchasingItems[rowData.index]
        const newRow = {
            'lineNumber': '',
            'description': selectedRow.description ? selectedRow.description : '',
            'supplierPartNo': selectedRow.supplierPartNo ? selectedRow.supplierPartNo : '',
            'partId': selectedRow.partId ? selectedRow.partId : '',
            operation: rowData.original.id ? "U" : "C",
            'id': rowData.original.id ? rowData.original.id : 0,
            'unitOfMeasure': selectedRow.unitOfMeasure ? selectedRow.unitOfMeasure : '',
            "price": selectedRow.price ? selectedRow.price : 0,
            "quantity": e.target.value,
            "salesCategoryId": selectedRow.salesCategoryId,
            "subTotal": (selectedRow.price && e.target.value ? selectedRow.price * e.target.value : 0)
        }
        // setTimeout(
        purchasingItems[rowData.index] = newRow
        this.setState({ purchasingItems })
        this.calTotal(purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue)
        //     10
        // );
    }

    handleDelete = (rowData) => {
        var { purchasingItems } = this.state;
        // const selectedRow = Service.find(row => row.lineNumber === rowData.index+1)
        // console.log(selectedRow)
        const newRow = {
            'lineNumber': '',
            'description': rowData.original.description,
            'supplierPartNo': rowData.supplierPartNo ? rowData.supplierPartNo : '',
            'partId': rowData.partId ? rowData.partId : '',
            'operation': "D",
            'id': rowData.original.id ? rowData.original.id : 0,
            'price': rowData.original.price,
            'quantity': rowData.original.quantity,
            "salesCategoryId": rowData.original.salesCategoryId,
            'subTotal': rowData.original.subTotal
        }
        purchasingItems[rowData.index] = newRow
        this.setState({ purchasingItems, isDelete: true })
    }

    calTotal = (purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue) => {
        const { tenantFinance } = this.props;
        const values = calculateTotal(tenantFinance.taxRate, purchasingItems, isPriceInclusiveTax, discountType, discountTypeValue)
        this.setState({
            subTotal: values.subTotal,
            discountValue: values.discountValue,
            tax: values.tax,
            total: values.total,
            gstCalValue: values.gstCalValue
        })
    }



    componentDidUpdate(prevProps, prevState) {
        const { purchasingItems, isDelete, tempPurchasingItemsList, isGetSelectedSupplier, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const { selectedPurchasing, selectedRow } = this.props;
        if (isDelete) {
            const purchasingItemsList = purchasingItems.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = purchasingItems.filter(data => (data.id !== null))
            this.setState({ purchasingItems: purchasingItemsList, isDelete: false, tempPurchasingItemsList: tempPurchasingItemsList.concat(tempItemList) })
            this.calTotal(purchasingItemsList, isPriceInclusiveTax, discountType, discountTypeValue)
        }

        if (isGetSelectedSupplier && selectedPurchasing && selectedPurchasing.id && selectedRow.id && selectedPurchasing.id === selectedRow.id) {
            this.props.dispatch(loadSelectedSupplierRequest(selectedPurchasing.supplier.id))
            this.setState({
                isSupplierChanged: true,
                isGetSelectedSupplier: false
            })
        }

        // if(isSupplierChanged && selectedSupplier && selectedSupplier.id){
        //     this.setState({
        //         address:
        //             (selectedSupplier.streetName ? selectedSupplier.streetName+  ' ,': ''  )+
        //             (selectedSupplier.streetNo ? selectedSupplier.streetNo +  ' ,\n':'' )+
        //             (selectedSupplier.region ? selectedSupplier.region+  ' ,':'')+
        //             selectedSupplier.cityName,
        //         email: selectedSupplier.email ? selectedSupplier.email :'',
        //         isSupplierChanged:false
        //     })
        // }
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            uomList,
            supplierList,
            objectStatusList,
            handlePrint,
            isPrintModalOpen,
            purchasingPrint,
            printModalClose,
            handleSendEmail,
            tenantFinance,
            isUpdate,
            selectedPurchasing,
            partList
        } = this.props;

        const {
            purchasingItems,
            gstCalValue,
            isUserAccess
            // isWarning,
            // selectedJobCardDetails 
        } = this.state;

        const purchasingItemsColumns = [{
            Header: 'Line no',
            // accessor: 'no',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="index"
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
            //   },{
            //     Header: 'Item No',
            //     accessor: 'partId',
            //     width:'10%',
            //     // width:1000,
            //     Cell:(rowData) =>(
            //         <div>
            //             {rowData.cell.row.original.partId === '' ? 
            //             <SelectField
            //                 controlId="partId"
            //                 onChange={(e)=>this.handleRowData(e,rowData.cell.row)}
            //                 options={partList}
            //                 style={customStyles}
            //                 id="partId"
            //                 // selectedValue={rowData.cell.row.original.partId}
            //             />:
            //             <BootstrapFormControl 
            //                 controlId={rowData.cell.row.original.task} // TODO
            //                 type="text"
            //                 defaultValue={rowData.cell.row.original.partId}
            //                 size="sm"
            //                 disabled
            //             />
            //             }
            //         </div>
            //       ),    
        }, {
            Header: 'Description',
            accessor: 'description',
            // width:1000,
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.description === '' ?
                        <SelectField
                            controlId="partId"
                            onChange={(e) => this.handleRowData(e, rowData.cell.row)}
                            options={partList}
                            style={customStyles}
                            id="id"
                            disabled={!isUserAccess}
                        // selectedValue={rowData.cell.row.original.partId}
                        /> :
                        <BootstrapFormControl
                            controlId="description"
                            type="text"
                            defaultValue={rowData.cell.row.original.description}
                            size="sm"
                            onBlur={(e) => this.handleRowDescription(e, rowData.cell.row)}
                            maxLength={200}
                            disabled={!isUserAccess}
                        />
                    }
                </div>
            ),
        }, {
            Header: 'SKU',
            // accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="supplierPartNo"
                        type="text"
                        defaultValue={rowData.cell.row.original.supplierPartNo}
                        size="sm"
                        onBlur={(e) => this.handleRowSupplierPartNo(e, rowData.cell.row)}
                        maxLength={16}
                        disabled
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    {/* <BootstrapFormControl 
                        controlId="unitOfMeasure"
                        type="text"
                        defaultValue={rowData.cell.row.original.unitOfMeasure}
                        size="sm"
                        as="select"
                        onChange={(e)=>this.handleRowUnitOfMeasure(e,rowData.cell.row)}
                        // width="100%"
                    >
                        <option key='' value=''> </option> 
                            {uomList.map(option =>
                            <option key={option.id} value={option.id}>{option.description}</option>)}
                    </BootstrapFormControl> */}
                    <SelectField
                        controlId="unitOfMeasure"
                        onChange={(e) => this.handleRowUnitOfMeasure(e, rowData.cell.row)}
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                        disabled={!isUserAccess}
                    // id="partId"
                    />
                </div>
            ),
        }, {
            // Header:'Unit Price',
            Header: () => (<div style={{ textAlign: "right" }}>Unit Price</div>),
            accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="price"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.price).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowUnitPrice(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        maxLength={10}
                        disabled={!isUserAccess}
                    />
                </div>
            )),
            width: "7%",
        }, {
            // Header: 'Quantity',
            accessor: 'quantity',
            Header: () => (<div style={{ textAlign: "right" }}>Quantity</div>),
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="quantity"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.quantity).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowQuantity(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        maxLength={10}
                        disabled={!isUserAccess}
                    // width="100%"
                    />
                </div>
            )),
            width: "7%",
        }, {
            // Header: 'Amount',
            accessor: 'subTotal',
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="amount"
                        type="text"
                        value={rowData.cell.row.original.subTotal ? parseFloat(rowData.cell.row.original.subTotal).toFixed(2) : '0.00'}
                        size="sm"
                        disabled
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "7%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={isUserAccess ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]
        return (
            <div>
                {tenantFinance && tenantFinance.id &&
                    <div>
                        <div className="filter-header">
                            <h5 style={{ marginLeft: '15px' }}>
                                Purchase orders {isUpdate && selectedPurchasing && selectedPurchasing.id ? " - " + selectedPurchasing.orderNumber : ""}
                            </h5>
                            <div className="btn-close" onClick={handleClose} style={{ marginRight: '10px' }}>
                                <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                            </div>
                        </div>
                        <div className="row" style={{ margin: '5px' }}>
                            <div className="col-sm page_header" style={{ padding: '10px' }}>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Supplier"
                                            controlId="supplier"
                                            options={
                                                supplierList && supplierList.length && supplierList.length > 0 ?
                                                    supplierList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ?
                                                        1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) : []}
                                            selectedValue={this.state.supplier}
                                            onChange={this.handleChangeSupplier}
                                            display="name"
                                            // disabled={this.state.jobCardNo !== '' || this.state.vehicleNo !== ''}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Date"
                                            controlId="orderDate"
                                            onChange={(e) => this.handleDateChange(e, "orderDate")}
                                            value={this.state.orderDate === '' ? '' : new Date(this.state.orderDate)}
                                            required
                                            disabled={!isUserAccess}
                                        // format={dateFormat}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Address"
                                            controlId="address"
                                            value={this.state.address}
                                            disabled
                                            as="textarea"
                                            row={50}
                                            style={{ height: 100 }}
                                        />
                                    </div>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm">
                                        <div className="row">
                                            <div className="col-sm">
                                                <DateTimePicker
                                                    text="Required date and time"
                                                    controlId="requiredTime"
                                                    //placeholder="Service due date"
                                                    onChange={(e) => this.handleDateTimeChange(e, "requiredTime")}
                                                    value={this.state.requiredTime === '' ? '' : new Date(this.state.requiredTime)}
                                                    required
                                                    disabled={!isUserAccess}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">
                                                <TextField
                                                    text="Reference no"
                                                    controlId="referenceNo"
                                                    placeholder="Reference no"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    defaultValue={this.state.referenceNo}
                                                    maxLength={common.maxLengths.referenceNo}
                                                    disabled={!isUserAccess}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Email address"
                                            controlId="email"
                                            placeholder="Email address"
                                            type="text"
                                            // onChange={this.handleChange}
                                            disabled
                                            defaultValue={this.state.email}
                                        />
                                    </div>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            onChange={(e) => this.handleChangeSelect(e, 'status')}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="invoice-title">
                                <span>Purchasing Items</span>
                                <span>
                                    <div style={{ margin: '0 20px' }}>
                                        <Form.Group controlId="inclusiveTaxCheckBox">
                                            <Form.Check type="checkbox" style={{ fontSize: "14px" }} checked={this.state.isPriceInclusiveTax} label="Price inclusive tax" disabled={!isUserAccess} onChange={this.handleCheck} />
                                        </Form.Group>
                                    </div>
                                </span>
                            </div>
                            <div className="table-invoice">
                                <Table
                                    columns={purchasingItemsColumns}
                                    data={purchasingItems}
                                />
                                <div className="add-icon" >
                                    <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={isUserAccess ? this.addNewRow : ''} ></ui5-icon>
                                </div>

                            </div>
                        </div>
                        <div className="row" style={{ margin: '0 5px' }}>
                            <div className='col-sm-6'>
                                <TextField
                                    text="Notes to supplier"
                                    controlId="supplierNotes"
                                    defaultValue={this.state.supplierNotes}
                                    onBlur={this.handleChange}
                                    as="textarea"
                                    // row={50}
                                    style={{ height: 200 }}
                                    disabled={!isUserAccess}
                                />
                            </div>
                            <div className='col-sm ' style={{ textAlign: 'right', marginRight: '-15px' }}>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Sub total
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="subTotal"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.subTotal).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" >
                                    <div className='col-sm'>
                                        {/* <FormGroup controlId="discountType">
                                        <FormControl
                                            as="select"
                                            style={{textAlign:'right'}}
                                            value={this.state.discountType}
                                            onChange={this.handleChangeDiscountType}
                                        >
                                            <option key='' value=''> </option> 
                                            <option key='0' value='0'>Discount Percentage</option> 
                                            <option key='1' value='1'>Discount Value</option> 
                                        </FormControl>
                                    </FormGroup> */}
                                        <SelectField
                                            controlId="discountType"
                                            onChange={(e) => this.handleChangeDiscountType(e, "discountType")}
                                            options={common.discountType}
                                            style={customStylesDiscount}
                                            selectedValue={this.state.discountType}
                                            isClearable
                                            disabled={!isUserAccess}
                                        // id="partId"
                                        />
                                    </div>
                                    <div className='col-sm'>
                                        <FormGroup controlId="discountTypeValue">
                                            <FormControl
                                                type="number"
                                                style={{ textAlign: 'right' }}
                                                value={this.state.discountTypeValue}
                                                onChange={this.handleChangeDiscountType}
                                                disabled={!isUserAccess}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="discountValue"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.discountValue).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            {gstCalValue > 0 ?
                                                <small>Include GST at {this.state.selectedTaxRate}% on {parseFloat(gstCalValue).toFixed(2)}</small> :
                                                <div>Tax {this.state.selectedTaxRate} %</div>
                                            }
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="tax"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.tax).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value">
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Total due
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="total"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.total).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-btns" style={{ marginRight: '15px' }}>
                            <ButtonGroup
                                primaryBtnClick={() => handleSaveAddNew(this.state)}
                                primaryBtnDisabled={!isUserAccess}
                                primaryBtnIcon='save'
                                primaryBtnText='Save'
                                primaryBtnVariant="outline-primary"
                                secondaryBtnClick={() => handleSendEmail(this.state)}
                                secondaryBtnIcon='email'
                                secondaryBtnText='Save and send'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnDisabled={this.state.email === '' || !isUserAccess}
                            />
                            <ButtonGroup
                                secondaryBtnClick={handleClose}
                                // secondaryBtnIcon='decline'
                                // secondaryBtnText='Cancel'
                                secondaryBtnText='Go Back'
                                secondaryBtnIcon='undo'
                                secondaryBtnVariant="outline-primary"
                                primaryBtnClick={() => handlePrint(this.state)}
                                primaryBtnIcon='print'
                                primaryBtnText='Print or preview'
                                primaryBtnVariant="outline-primary"
                                primaryBtnDisabled={!isUserAccess}
                            />
                        </div>
                        {
                            isPrintModalOpen && purchasingPrint !== '' &&
                            <PdfViewer
                                show={isPrintModalOpen}
                                pdf={purchasingPrint}
                                onHide={printModalClose}
                            />
                        }
                    </div>}
            </div>
        )
    }
}

AddNewPurchasing.propTypes = {
    handleClose: PropTypes.any,
    vehicleList: PropTypes.any,
    supplierList: PropTypes.any,
    selectedPurchasing: PropTypes.any,
    // modelList: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    objectStatusList: PropTypes.any,
    jobCardList: PropTypes.any,
    handlePrint: PropTypes.any,
    uomList: PropTypes.any,
    isPrintModalOpen: PropTypes.any,
    isUpdate: PropTypes.bool,
    invoicePrint: PropTypes.any,
    printModalClose: PropTypes.any,
    selectedJobCard: PropTypes.any,
    handleSendEmail: PropTypes.any,
    paymentTermList: PropTypes.any,
    selectedSupplier: PropTypes.any,
    isNewPurchasing: PropTypes.any,
    partList: PropTypes.any,
    purchasingPrint: PropTypes.any,
}


const mapStateToProps = createStructuredSelector({
    // repairList: makeSelectRepairList(),
    // partList: makeSelectPartList(),
    uomList: makeSelectUOMList(),
    selectedSupplier: makeSelectSelectedSupplierSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AddNewPurchasing));