import { createSelector } from 'reselect';

const selectA2AMappingDomain = state => state.a2aMapping;

const makeSelectA2AMappingLoading = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isA2AMappingLoading') : false
  );

const makeSelectA2AMappingError = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aMappingError') : null
  );

const makeSelectA2AMappingSuccess = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('a2aMappingSuccess') : null
  );

const makeSelectAccountEmployee = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountEmployeeList') : []
  );


const makeSelectMappingEmployee = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingEmployeeList') : []
  );

const makeSelectAccountEmployeeListStatus = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isAccountEmployeeList') : false
  );

const makeSelectAccountPayType = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountPayTypeList') : []
  );


const makeSelectMappingPayType = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('mappingPayTypeList') : []
  );

const makeSelectAccountPayTypeListStatus = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('isAccountPayTypeList') : false
  );

const makeSelectAccountCompanyFile = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountCompanyFileList') : []
  );

const makeSelectAccountTaxCodeList = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('accountTaxCodeList') : []
  );

const makeSelectInvoiceReconciliationReport = () =>
  createSelector(
    selectA2AMappingDomain,
    a2aMappingState =>
      a2aMappingState ? a2aMappingState.get('invoiceReconciliationReport') : []
  );

export {
  makeSelectA2AMappingError,
  makeSelectA2AMappingLoading,
  makeSelectA2AMappingSuccess,
  makeSelectAccountEmployee,
  makeSelectMappingEmployee,
  makeSelectAccountEmployeeListStatus,
  makeSelectAccountPayType,
  makeSelectMappingPayType,
  makeSelectAccountPayTypeListStatus,
  makeSelectAccountCompanyFile,
  makeSelectAccountTaxCodeList,
  makeSelectInvoiceReconciliationReport
}
