const PATH = 'app/EmailTemplate';

export const SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS = `${PATH}SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS`;
export const LOAD_EMAIL_TEMPLATE_LIST_REQUEST = `${PATH}LOAD_EMAIL_TEMPLATE_LIST_REQUEST`;
export const LOAD_EMAIL_TEMPLATE_LIST_ERROR = `${PATH}LOAD_EMAIL_TEMPLATE_LIST_ERROR`;
export const LOAD_EMAIL_TEMPLATE_LIST_LOADING = `${PATH}LOAD_EMAIL_TEMPLATE_LIST_LOADING`;
export const LOAD_EMAIL_TEMPLATE_LIST_SUCCESS = `${PATH}LOAD_EMAIL_TEMPLATE_LIST_SUCCESS`;
export const LOAD_SELECTED_EMAIL_TEMPLATE_REQUEST = `${PATH}LOAD_SELECTED_EMAIL_TEMPLATE_REQUEST`;
export const LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS = `${PATH}LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS`;
export const SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST = `${PATH}SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST`;
export const SEND_EMAIL_TEMPLATE_SUCCESS = `${PATH}SEND_EMAIL_TEMPLATE_SUCCESS`;
export const SEND_UPDATE_EMAIL_TEMPLATE_REQUEST = `${PATH}SEND_UPDATE_EMAIL_TEMPLATE_REQUEST`;
export const SEND_DELETE_EMAIL_TEMPLATE_REQUEST = `${PATH}SEND_DELETE_EMAIL_TEMPLATE_REQUEST`;