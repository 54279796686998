import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/a2aMappingSelector';
import {makeSelectAccountProductCategoryError } from '../../../../store/selector/accountProductCategorySelector';
// import { Link } from 'react-router-dom';
import Table from '../../../layout/form/Table';
import Form from 'react-bootstrap/cjs/Form';
import * as actions from '../../../../store/action/a2aMappingAction';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader';
import Button from '../../../layout/form/Button';
import { loadTenantAccountSystemRequest } from '../../../../store/action/tenantAction';

class MyobCompany extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: {},
      companyFileList: [],
      initial: true,
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const {
      tenantAccountSystem,
      accountCompanyFileList
    } = prevProps;
    const { initial } = prevState;

    if (initial && tenantAccountSystem && tenantAccountSystem.realmId && accountCompanyFileList && accountCompanyFileList.length > 0) {
      const list = []
      for (let i = 0; i < accountCompanyFileList.length; i++) {
        const element = accountCompanyFileList[i];
        var select = false
        if (element.id === tenantAccountSystem.realmId) {
          select = true
        }
        const newRow = {
          id: element.id,
          companyName: element.companyName,
          uri: element.uri,
          isSelect: select
        }
        list.push(newRow)
      }
      return {
        initial: false,
        companyFileList: list
      }
    } else if (initial && tenantAccountSystem && tenantAccountSystem.id === 0 && accountCompanyFileList && accountCompanyFileList.length > 0) {
      const list = []
      for (let i = 0; i < accountCompanyFileList.length; i++) {
        const element = accountCompanyFileList[i];
        const newRow = {
          id: element.id,
          companyName: element.companyName,
          uri: element.uri,
          isSelect: false
        }
        list.push(newRow)
      }
      return {
        initial: false,
        companyFileList: list
      }
    }
    return null

  }


  errorModalClose = () => {
    this.props.dispatch(actions.loadA2AMappingError(''))
  }

  successModalClose = () => {
    this.props.dispatch(actions.loadA2AMappingSuccess(null))
    this.props.dispatch(actions.saveAccountEmployeeListStatus(false))
    this.getCompanyFileListData()
  }

  getCompanyFileListData = () => {
    this.props.dispatch(actions.loadCompanyFileRequest())
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.a2aMappingSuccess !== null) {
      setTimeout(
        () => this.successModalClose(),
        1000
      );
    }
  }

  componentDidMount() {
    const id = sessionStorage.getItem("tenantId")
    this.props.dispatch(loadTenantAccountSystemRequest(id))
    this.getCompanyFileListData()
  }

  handleCheckCompany = (e, details) => {
    const { companyFileList } = this.state;
    const checked = e.target.checked;
    for (let i = 0; i < companyFileList.length; i++) {
      const element = companyFileList[i];
      if (i === details.index) {
        const newRow = {
          id: element.id,
          companyName: element.companyName,
          uri: element.uri,
          isSelect: checked
        }
        companyFileList[i] = newRow
      } else {
        const newRow = {
          id: element.id,
          companyName: element.companyName,
          uri: element.uri,
          isSelect: false
        }
        companyFileList[i] = newRow
      }
    }
    if (checked) {
      this.setState({
        selectedCompany: details.original,
        companyFileList
      })
    }
    else
      this.setState({
        selectedCompany: {},
        companyFileList
      })
  }

  handleSaveCompanyFile = () => {
    const { selectedCompany } = this.state;
    const parms = {
      id: selectedCompany.id,
      companyName: selectedCompany.companyName,
      uri: selectedCompany.uri
    }
    this.props.dispatch(actions.sendCompanyFileRequest(parms))
  }

  render() {
    const {
      isA2AMappingLoading,
      accountProductCategoryError,
      a2aMappingSuccess
    } = this.props;

    const columns = [{
      Header: '', accessor: 'select',
      width: "5%",
      Cell: ((row) => (
        <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
          <Form.Group controlId="addressCheckBox">
            <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckCompany(e, row.row)} checked={row.row.original.isSelect} />
          </Form.Group>
        </div>
      ))
    }, {
      Header: 'Company name',
      accessor: 'companyName',
    }]

    return (
      <div>
        {accountProductCategoryError !== '' &&
          <ErrorModal
            show={true}
            massage={accountProductCategoryError}
            handleClose={this.errorModalClose}
          />}

        {(a2aMappingSuccess) &&
          <SuccessModal
            show={true}
            massage={a2aMappingSuccess}
            handleClose={this.successModalClose}
          />}
        {isA2AMappingLoading ?
          <Loader /> : ''}
        <div className="body-content">
          <div className="table-content">
            <Table
              columns={columns}
              data={this.state.companyFileList}
            />
          </div>
          <div className="filter-btns">
            <Button
              onClick={this.handleSaveCompanyFile}
              // primaryBtnIcon='decline'
              text='Save'
              icon='save'
              variant="outline-primary"
            />
          </div>
        </div>
      </div>
    )
  }

}


const mapStateToProps = createStructuredSelector({
  isA2AMappingLoading: selectors.makeSelectA2AMappingLoading(),
  a2aMappingError: selectors.makeSelectA2AMappingError(),
  accountProductCategoryError: makeSelectAccountProductCategoryError(),
  tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
  accountCompanyFileList: selectors.makeSelectAccountCompanyFile(),
  a2aMappingSuccess: selectors.makeSelectA2AMappingSuccess(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(MyobCompany));
