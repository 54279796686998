import { createSelector } from 'reselect';
import common from '../../component/common';

const selectStockDocumentDomain = state => state.stockDocument;

const makeSelectStockDocumentPopupOpen = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('isStockDocumentOpen') : false
  );

const makeSelectStockDocumentDetails = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentDetails') : common.stockDocument
  );

const makeSelectStockDocumentTypeList = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentTypeList') : []
  );


const makeSelectStockDocumentList = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentList') : []
  );

const makeSelectStockDocumentListLoading = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('isStockDocumentListLoading') : false
  );

const makeSelectStockDocumentListError = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentListError') : null
  );

const makeSelectSelectedStockDocument = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocument') : null
  );

const makeSelectStockDocumentSuccess = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentSuccess') : null
  );


const makeSelectStockDocumentPrintSuccess = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentPrint') : null
  );


const makeSelectStockDocumentProductReportLoading = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('isStockDocumentProductReportLoading') : false
  );

const makeSelectStockDocumentProductReportError = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentProductReportError') : null
  );

const makeSelectStockDocumentProductReportDocument = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('stockDocumentProductReport') : {}
  );

const makeSelectStockReportPopupOpen = () =>
  createSelector(
    selectStockDocumentDomain,
    stockDocumentState =>
      stockDocumentState ? stockDocumentState.get('isStockReportOpen') : false
  );

export {
  makeSelectStockDocumentPopupOpen,
  makeSelectStockDocumentDetails,
  makeSelectStockDocumentTypeList,
  makeSelectStockDocumentList,
  makeSelectStockDocumentListLoading,
  makeSelectStockDocumentListError,
  makeSelectSelectedStockDocument,
  makeSelectStockDocumentSuccess,
  makeSelectStockDocumentPrintSuccess,
  makeSelectStockDocumentProductReportLoading,
  makeSelectStockDocumentProductReportError,
  makeSelectStockDocumentProductReportDocument,
  makeSelectStockReportPopupOpen
}