const PATH = 'app/accountProductCategory';

export const LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST = `${PATH}LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST`;
export const LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR = `${PATH}LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR`;
export const LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING = `${PATH}LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING`;
export const LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS = `${PATH}LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS`;
export const SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY = `${PATH}SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY`;
export const LOAD_A2A_PRODUCT_CATEGORY_REQUEST = `${PATH}LOAD_A2A_PRODUCT_CATEGORY_REQUEST`;
export const LOAD_A2A_PRODUCT_CATEGORY_SUCCESS = `${PATH}LOAD_A2A_PRODUCT_CATEGORY_SUCCESS`;
export const SEND_A2A_PRODUCT_CATEGORY_REQUEST = `${PATH}SEND_A2A_PRODUCT_CATEGORY_REQUEST`;
export const SEND_A2A_PRODUCT_CATEGORY_SUCCESS = `${PATH}SEND_A2A_PRODUCT_CATEGORY_SUCCESS`;
export const LOAD_ACCOUNT_CUSTOMER_REQUEST = `${PATH}LOAD_ACCOUNT_CUSTOMER_REQUEST`;
export const LOAD_ACCOUNT_CUSTOMER_SUCCESS = `${PATH}LOAD_ACCOUNT_CUSTOMER_SUCCESS`;
export const SAVE_IS_LOAD_ACCOUNT_CUSTOMER = `${PATH}SAVE_IS_LOAD_ACCOUNT_CUSTOMER`;
export const LOAD_A2A_CUSTOMER_REQUEST = `${PATH}LOAD_A2A_CUSTOMER_REQUEST`;
export const LOAD_A2A_CUSTOMER_SUCCESS = `${PATH}LOAD_A2A_CUSTOMER_SUCCESS`;
export const SEND_A2A_CUSTOMER_REQUEST = `${PATH}SEND_A2A_CUSTOMER_REQUEST`;
export const LOAD_A2A_ACCOUNT_REQUEST = `${PATH}LOAD_A2A_ACCOUNT_REQUEST`;
export const LOAD_A2A_ACCOUNT_SUCCESS = `${PATH}LOAD_A2A_ACCOUNT_SUCCESS`;
export const SEND_A2A_ACCOUNT_REQUEST = `${PATH}SEND_A2A_ACCOUNT_REQUEST`;
