import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/supplierSelector';
import { makeSelectCountryList, makeSelectObjectStatusList } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/supplierAction';
import { loadTitleListRequest } from '../../../../store/action/titleAction';
import PropTypes from 'prop-types';
import '../../settingMenu/settingMenu.css';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddNewSupplier from './AddNewSupplier';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import { Link } from 'react-router-dom';
import Pagination from '../../../layout/form/Pagination';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import common from '../../../common';
import Avatar from 'react-avatar';
import SearchField from '../../../layout/form/SearchField';

class Supplier extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            name:'',
            email:'',
            mobile:'',
            city:'',
            isValidationError:false,
            isDeleteModalOpen:false,
            selectedRow:{},
            errorMassage:'',
            isUpdate:false,
            pageNumber:1,
            pageSize:20,
            commonSearch:'',
        }
    }

    handleModal = (data) => {
        if(data && data.id){
            this.props.dispatch(actions.loadSelectedSupplierRequest(data.id))
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsSupplierModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsSupplierModalOpenStatus(false));
        this.props.dispatch(actions.loadSelectedSupplierSuccess(''))
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadSupplierListError(''))
    }

    successModalClose = () => {
        this.getSupplierListData()
        this.props.dispatch(actions.sendSupplierSuccess(''))
        this.handleModalClose()
        this.props.dispatch(actions.loadSupplierListLoading(false))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            name:this.state.name,
            email:this.state.email,
            mobile:this.state.mobile,
            city:this.state.city,
            commonSearch:''
        })
    }

    handleChangeSearchValue = (e)=> {
        if(e.target.value === "" || e.target.value === null){
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen:false,
            name:'',
            email:'',
            mobile:'',
            city:'',
            pageSize:20,
            pageNumber:1,
        })
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleFilter = () =>{
        this.getSupplierListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen:false,
            name:'',
            email:'',
            mobile:'',
            city:'',
            pageSize:20,
            pageNumber:1,
            commonSearch:''
        })

        const parameters= {
            pageSize:20,
            pageNumber:1,
            name:'',
            email:'',
            mobile:'',
            city:'',
            commonSearch:''
          }
          this.props.dispatch(actions.loadSupplierListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        const { selectedSupplier } = this.props
        if(details.name === '' ||
            details.status === ''
            ){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }

        const parameters = {
            id: (selectedSupplier && selectedSupplier.id && this.state.isUpdate) ? selectedSupplier.id: '',
            Name:details.name,
            ContactName:details.contactName,
            Email:details.email === ''  ? null: details.email,
            Phone:details.phone,
            Mobile:details.mobile,
            Fax:details.fax,
            CustomField1:details.customField1,
            CustomField2:details.customField2,
            StatusId:details.status,
            StreetNo: details.streetNo,
            StreetName: details.streetName,
            CityName: details.city,
            PostalCode: details.postCode,
            Region: details.state,
            Country: {
              code: details.country,
            }
        }
        // console.log(details)
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendUpdateSupplierRequest(parameters))
        }else{
            this.props.dispatch(actions.sendAddNewSupplierRequest(parameters))
        }
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteSupplier = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteSupplierRequest(this.state.selectedRow.id))
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    getSupplierListData = () => {
        const parameters= {
          pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
          pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
          name:this.state.name,
          email:this.state.email,
          mobile:this.state.mobile,
          city:this.state.city,
          commonSearch: this.state.commonSearch
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.props.dispatch(actions.loadSupplierListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            name:this.state.name,
            email:this.state.email,
            mobile:this.state.mobile,
            city:this.state.city,
            commonSearch: value
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.setState({
            commonSearch:value
        })
        this.props.dispatch(actions.loadSupplierListRequest(parameters))
    }

    componentDidMount (){
        this.props.dispatch(actions.loadSupplierListSuccess([]))
        this.props.dispatch(actions.loadSelectedSupplierSuccess(''))
        this.props.dispatch(loadObjectStatusSuccess([]))
        const parameters= {
            pageSize:9999,
            pageNumber:'',
            name:'',
          }
        this.getSupplierListData()
        this.props.dispatch(loadTitleListRequest(parameters))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.supplier));
    }

    componentDidUpdate (prevProps, prevState){
        // if(this.state.isValidationError || this.props.supplierListError !== ''){
        //     setTimeout(
        //         () => this.errorModalClose(), 
        //         500
        //       );
        // }else 
        if (this.props.supplierSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }

        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getSupplierListData()
        }
    }

    render() {
        
        const {
            isAddNewSupplierModalOpen,
            supplierList,
            isSupplierListLoading,
            supplierListError,
            supplierSuccess,
            objectStatusList,
            countryList,
            selectedSupplier
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow
         } = this.state;

        const columns = [{
            Header: 'Business name',
            accessor: 'name',
            // accessor: 'displayName',
            Cell:((row) =>
                <div>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:'10px'}}>
                            <Avatar name={row.row.original.name} size={30} round="50%" maxInitials={2}/>
                        </div>
                        <div style={{marginTop:'5px'}}>{row.row.original.name}</div>
                    </div>
                </div>
            )
          },{
            Header: 'Contact name',
            accessor: 'contactName',
          },{
            Header: 'Mobile',
            accessor: 'mobile',
            width: "15%",
          },{
            Header: 'Email',
            accessor: 'email',
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>  
                      {/* <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>   */}
                        <span className="dropdown" style={{float:'right'}}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={()=>this.handleDeleteConfirm(row.row.original)}>Delete</li>
                            </div>
                        </span> 
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(isSupplierListLoading || 
                        (supplierList.result && supplierList.result.length && supplierList.result.length === 0)) ? 
                        <Loader show={isSupplierListLoading}/> :''}

                {(isValidationError || supplierListError !== '' ) && 
                        <ErrorModal
                            show={true} 
                            massage={errorMassage ? errorMassage : supplierListError}
                            handleClose={this.errorModalClose}
                        />}

                {(supplierSuccess) && 
                        <SuccessModal
                            show={true} 
                            massage={supplierSuccess}
                            handleClose={this.successModalClose}
                        />}
                {isDeleteModalOpen && 
                    <DeleteModal 
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteSupplier}								
                        title={selectedRow.name}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        <span><Link to='./supplier'><ui5-icon class="samples-margin" name="supplier" id="tab-icon"></ui5-icon> Supplier</Link></span>
                        <span><Link to='./purchasing'><ui5-icon class="samples-margin" name="customer-order-entry" id="tab-icon"></ui5-icon> Purchase orders</Link></span>
                    </div>
                    <div className="header-btn">
                        <ButtonGroup
                            primaryBtnClick={this.handleModal}
                            secondaryBtnClick={this.handleFilterForm}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Add New'
                            primaryBtnIcon='sys-add'
                            secondaryBtnText='Filter'
                            secondaryBtnVariant="outline-primary" 
                            secondaryBtnIcon='filter'
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div style={{display:'flex'}}>
                        <div>
                            <h5 style={{marginLeft:'5px'}}>Supplier List</h5>
                        </div>
                        <div style={{marginLeft:'20px'}}>
                            <SearchField
                                onClickSearch={this.getSupplierListData}
                                controlId="commonSearch"
                                onChange={this.handleChangeSearchValue}
                                defaultValue={this.state.commonSearch}
                                onPressKeySearch={this.onPressKeySearch}
                            />
                        </div>
                    </div>
                    {isFilterFormOpen && 
                            <div className="filter-form">
                                <div className="filter-header">
                                    <h5>Filter Form</h5>
                                    <div className="btn-close" onClick={this.handleFilterFormClose} >
                                        <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Name"
                                        controlId="name"
                                        placeholder="Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Email"
                                        controlId="email"
                                        placeholder="Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.email}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="City"
                                        controlId="city"
                                        placeholder="City"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.city}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Mobile"
                                        controlId="mobile"
                                        placeholder="Mobile"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.mobile}
                                    />
                                </div>
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                        </div>}
                        <div className="table-content">
                            <Table
                                columns={columns}
                                data={supplierList.result && supplierList.result.length ? supplierList.result : []}                   
                            />
                        </div>
                </div>
                <Pagination
                    currentPage={supplierList.page ? supplierList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {supplierList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewSupplierModalOpen && 
                    <AddNewSupplier
                        isAddNewSupplierModalOpen={isAddNewSupplierModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedRow={selectedRow}
                        objectStatusList={objectStatusList}
                        countryList={countryList}
                        selectedSupplier={selectedSupplier}
                        isUpdate={this.state.isUpdate}
                    />
                }
            </div>
        )
    }
}

Supplier.propTypes = {
    isAddNewSupplierModalOpen: PropTypes.bool,
    supplierList: PropTypes.any,
    isSupplierListLoading: PropTypes.bool,
    supplierListError: PropTypes.any,
    supplierSuccess: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedSupplier: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    isAddNewSupplierModalOpen: selectors.makeSelectSupplierPopupOpen(),
    supplierList: selectors.makeSelectSupplierList(),
    isSupplierListLoading: selectors.makeSelectSupplierListLoading(),
    supplierListError: selectors.makeSelectSupplierListError(),
    supplierSuccess: selectors.makeSelectSupplierSuccess(),
    objectStatusList:makeSelectObjectStatusList(),
    countryList: makeSelectCountryList(),
    selectedSupplier: selectors.makeSelectSelectedSupplierSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Supplier));