import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getStockDocumentTypeList() {
    return axios.get(baseURL + 'stockDocumentType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function addStockDocumentRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.post(baseURL + 'stockDocument', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getStockDocumentList(parameters) {
    return axios.get(baseURL + 'stockDocument?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&type=' + parameters.type + '&supplier=' + parameters.supplier + '&toDate=' + parameters.toDate + '&fromDate=' + parameters.fromDate + '&commonSearch=' + parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedStockDocumentRequest(id) {
    return axios.get(baseURL + 'stockDocument/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function printStockDocumentByIdRequest(id) {
    return axios.patch(baseURL + 'stockDocument/print/' + id, null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getStockDocumentProductReport(parameters) {
    return axios.get(baseURL + 'stockDocument/productReport?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&productId=' + parameters.product + '&toDate=' + parameters.toDate + '&fromDate=' + parameters.fromDate, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}