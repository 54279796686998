import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import { Link } from 'react-router-dom';
import SelectField from '../../../layout/form/SelectField';
import DatePicker from '../../../layout/form/datePicker';
import * as actions from '../../../../store/action/reportAction';
import * as selectors from '../../../../store/selector/reportSelector';
import moment from 'moment';
import { saveLoginUser } from '../../../../store/action/authAction';
import  common, { invalidToken, refeshTokenset, numberWithCommas } from '../../../common';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Table from '../../../layout/form/Table';
import Pagination from '../../../layout/form/Pagination';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import PdfViewer from '../../../layout/form/PdfViewer';
import { CSVLink } from 'react-csv';
import { getReportList } from '../../../service/reportService';
import { loadCustomerListSuccess } from '../../../../store/action/customerAction';
import { loadInvoiceListSuccess } from '../../../../store/action/invoiceAction';

const dateFormat = sessionStorage.getItem("dateFormat")
class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportPeriod:'',
            fromDate:'',
            toDate:'',
            groupBy:1,
            pageNumber:1,
            pageSize:20,
            initial:true,
            salesReportList:[],
            isPrintModalOpen : false,
            isValidationError:false, 
            errorMassage:'',
            isCSVExportModalOpen:false,
            csvReport:{},
            csvHeaders:[],
            csvData:[]
        }
        this.csvLinkEl = React.createRef();
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { reportList } =  prevProps;
        const { pageNumber, initial } = prevState;
        if(reportList && reportList.result && reportList.result.length > 0 && reportList.totalPages === pageNumber && initial){
          const element = [{
            invoiceDate:'Total',
            month:'Total',
            year:'Total',
            subTotal: reportList.result[0].sumSubTotal,
            // serviceValue: reportList.result[0].sumServiceValue,
            // repairValue: reportList.result[0].sumRepairValue,
            // partValue: reportList.result[0].sumPartValue,
            // otherValue: reportList.result[0].sumOtherValue,
            discountValue: reportList.result[0].sumDiscountValue,
            tax: reportList.result[0].sumTax,
            total: reportList.result[0].sumTotal,
          }]
          return{
            initial:false,
            salesReportList: reportList.result.concat(element)
          }
        }
        else if(reportList && reportList.result && reportList.result.length && initial){
          return{
            initial:false,
            salesReportList: reportList.result
          }
        }
        return null
    }

    handleDateChange = (date, id) => {
        if(date === null){
            this.setState({
                [id]: ''
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateFormat),
            })
        }
    }

    handleChangeSelect  = (value, id) => {
        const { reportPeriodList } = this.props;
        this.props.dispatch(actions.loadReportSuccess([]))

        this.setState({
          salesReportList:[],
          initial:true
        })

        if(id === "reportPeriod" && value === ''){
            this.setState({
                fromDate:'',
                toDate:'',
                reportPeriod:''
            })
            return
        }

        if(id === "reportPeriod"){
            const period = reportPeriodList.find(element => element.id === value)
            this.setState({
                fromDate:period.startDate,
                toDate:period.endDate
            })
        } 

        if(id === "groupBy" && value === ''){
            this.setState({
                groupBy:1,
            })
            return
        }

        this.setState({
            [id]: value
        })
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadReportError(''))
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat? dateFormat : common.dateFormat);
        return date
    }

    setMonthFormat = (value) => {
        const date = moment(value).format('MMMM-YYYY');
        return date
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    handleRunReport = () => {
      if(this.state.fromDate === '' ||
        this.state.toDate === '' ||
        this.state.groupBy === ''){
          this.setState({isValidationError:true, errorMassage:'Select Report Period'})
          return
      }
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            startDate: moment(this.state.fromDate).format(common.dateFormat),
            endDate: moment(this.state.toDate).format(common.dateFormat),
            groupBy: this.state.groupBy,
            operation: 'R'
        }
        this.setState({
          initial:true
        })
        this.props.dispatch(actions.loadReportSuccess([]))
        this.props.dispatch(actions.loadReportRequest(parameters))
    }

    handlePrint = () => {
      if(this.state.fromDate === '' ||
        this.state.toDate === '' ||
        this.state.groupBy === ''){
          this.setState({isValidationError:true, errorMassage:'Select Report Period'})
          return
      }
      const parameters = {
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        startDate: moment(this.state.fromDate).format(common.dateFormat),
        endDate: moment(this.state.toDate).format(common.dateFormat),
        groupBy: this.state.groupBy,
        operation: 'P'
      }
      this.props.dispatch(actions.loadReportPrintRequest(parameters))
      this.setState({isPrintModalOpen : true})
    }

    printModalClose = () =>{
      this.setState({isPrintModalOpen : false})
      this.props.dispatch(actions.loadReportPrintSuccess(''))
    }

    getReportCSV = () => {
        const parameters = {
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          startDate: moment(this.state.fromDate).format(common.dateFormat),
          endDate: moment(this.state.toDate).format(common.dateFormat),
          groupBy: this.state.groupBy,
          operation: 'C'
        }
      return getReportList(parameters).then(
        (response) => response,
        (err) => err
        )
      }

    downloadReport = async () => {
      if(this.state.fromDate === '' ||
        this.state.toDate === '' ||
        this.state.groupBy === ''){
          this.setState({isValidationError:true, errorMassage:'Select Report Period'})
          return
      }
      this.props.dispatch(actions.loadReportLoading(true));
      const response = await this.getReportCSV();

      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 && response.data.result.errorCode &&  response.data.result.errorCode === 5000){
        const massage  = 'Data not available for requested period'
        this.props.dispatch(actions.loadReportError(massage))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result)
        this.setState({ 
          csvData: response.data.result.tableData,
          csvHeaders: response.data.result.header
        }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      }   
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        this.props.dispatch(saveLoginUser(false))
        invalidToken()
      } 
      else{
        const massage  =common.error
        this.props.dispatch(actions.loadReportError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      this.props.dispatch(actions.loadReportLoading(false));
    }

    handleClose = () => {
        this.setState({
            reportPeriod:'',
            fromDate:'',
            toDate:'',
            groupBy:1,
            pageNumber:1,
            pageSize:20,
            salesReportList:[],
            initial:true
        })
        this.props.dispatch(actions.loadReportSuccess([]))
    }

    redirectCustomer = () => {
      this.props.dispatch(loadCustomerListSuccess([]))
    }

    redirectInvoice = () => {
      this.props.dispatch(loadInvoiceListSuccess([]))
      this.props.dispatch(loadCustomerListSuccess([]))
    }

    componentDidUpdate (prevProps, prevState){
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.handleRunReport()
        }
    }

    componentDidMount (){
        // this.props.dispatch(actions.loadReportPeriodRequest())
        this.props.dispatch(actions.loadReportSuccess([]))
        this.setState({
          salesReportList:[]
        })
    }

    render() {
        const { 
            reportPeriodList,
            reportList,
            reportListError,
            isReportListLoading,
            reportPdf
        } = this.props;

        const { 
          salesReportList,
          isPrintModalOpen,
          isValidationError,
          errorMassage, 
          csvHeaders,
          csvData
        } = this.state;

        const columnInvoiceNo = [{
            Header: 'Date',
            accessor: 'invoiceDate',
            width: "10%",
            Cell:((row)=>(
                <div >
                  {row.row.original.invoiceDate === '1/1/0001' ? '' :
                   (row.row.original.invoiceDate === 'Total' ? "Total" : this.setDateFormat(row.row.original.invoiceDate))}
                </div>
              )),
          },{
            Header: 'Invoice No',
            accessor: 'invoiceNo',
            width: "10%",
          // },{
          //   // Header: 'Service Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Service Value</div>),
          //   accessor: 'serviceValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.serviceValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Repair Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Repair Value</div>),
          //   accessor: 'repairValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.repairValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Parts Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Parts Value</div>),
          //   accessor: 'partValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.partValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Other Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Other Value</div>),
          //   accessor: 'otherValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.otherValue).toFixed(2))}
          //       </div>
          //   ))
          },{
            // Header: 'Total Value',
            Header: () => (<div style={{textAlign:"right"}}>Total Value</div>),
            accessor: 'subTotal',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.subTotal).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Discount Value',
            Header: () => (<div style={{textAlign:"right"}}>Discount Value</div>),
            accessor: 'discountValue',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.discountValue).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Tax Value',
            Header: () => (<div style={{textAlign:"right"}}>Tax Value</div>),
            accessor: 'tax',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.tax).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Gross Value',
            Header: () => (<div style={{textAlign:"right"}}>Gross Value</div>),
            accessor: 'total',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
          },
        ]

        const columnDate = [{
            Header: 'Date',
            Cell:((row)=>(
                <div >
                  {row.row.original.invoiceDate === '1/1/0001' ? '' :
                  (row.row.original.invoiceDate === 'Total' ? "Total" : this.setDateFormat(row.row.original.invoiceDate))}
                </div>
              )),
          // },{
          //   // Header: 'Service Value', 
          //   Header: () => (<div style={{textAlign:"right"}}>Service Value</div>),
          //   accessor: 'serviceValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.serviceValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Repair Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Repair Value</div>),
          //   accessor: 'repairValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.repairValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Parts Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Parts Value</div>),
          //   accessor: 'partValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.partValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Other Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Other Value</div>),
          //   accessor: 'otherValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.otherValue).toFixed(2))}
          //       </div>
          //   ))
          },{
            // Header: 'Total Value',
            Header: () => (<div style={{textAlign:"right"}}>Total Value</div>),
            accessor: 'subTotal',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.subTotal).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Discount Value',
            Header: () => (<div style={{textAlign:"right"}}>Discount Value</div>),
            accessor: 'discountValue',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.discountValue).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Tax Value',
            Header: () => (<div style={{textAlign:"right"}}>Tax Value</div>),
            accessor: 'tax',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.tax).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Gross Value',
            Header: () => (<div style={{textAlign:"right"}}>Gross Value</div>),
            accessor: 'total',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
          },
        ]

        const columnMonth = [{
            Header: 'Month',
            Cell:((row)=>(
                <div >
                  {row.row.original.month === 'Total' ? "Total" : this.setMonthFormat(row.row.original.month)}
                </div>
              )),
          // },{
          //   // Header: 'Service Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Service Value</div>),
          //   accessor: 'serviceValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.serviceValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Repair Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Repair Value</div>),
          //   accessor: 'repairValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.repairValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Parts Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Parts Value</div>),
          //   accessor: 'partValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.partValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Other Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Other Value</div>),
          //   accessor: 'otherValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.otherValue).toFixed(2))}
          //       </div>
          //   ))
          },{
            // Header: 'Total Value',
            Header: () => (<div style={{textAlign:"right"}}>Total Value</div>),
            accessor: 'subTotal',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.subTotal).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Discount Value',
            Header: () => (<div style={{textAlign:"right"}}>Discount Value</div>),
            accessor: 'discountValue',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.discountValue).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Tax Value',
            Header: () => (<div style={{textAlign:"right"}}>Tax Value</div>),
            accessor: 'tax',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.tax).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Gross Value',
            Header: () => (<div style={{textAlign:"right"}}>Gross Value</div>),
            accessor: 'total',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
          },
        ]

        const columnYear = [{
            Header: 'Year',
            Cell:((row)=>(
                <div >
                  {row.row.original.year === 'Total' ? "Total" : row.row.original.year}
                </div>
              )),
          // },{
          //   // Header: 'Service Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Service Value</div>),
          //   accessor: 'serviceValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.serviceValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Repair Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Repair Value</div>),
          //   accessor: 'repairValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.repairValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Parts Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Parts Value</div>),
          //   accessor: 'partValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.partValue).toFixed(2))}
          //       </div>
          //   ))
          // },{
          //   // Header: 'Other Value',
          //   Header: () => (<div style={{textAlign:"right"}}>Other Value</div>),
          //   accessor: 'otherValue',
          //   width: "10%",
          //   Cell:((row)=>(
          //       <div style={{
          //               textAlign:"right"
          //             }}>
          //         {numberWithCommas(parseFloat(row.row.original.otherValue).toFixed(2))}
          //       </div>
          //   ))
          },{
            // Header: 'Total Value',
            Header: () => (<div style={{textAlign:"right"}}>Total Value</div>),
            accessor: 'subTotal',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.subTotal).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Discount Value',
            Header: () => (<div style={{textAlign:"right"}}>Discount Value</div>),
            accessor: 'discountValue',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.discountValue).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Tax Value',
            Header: () => (<div style={{textAlign:"right"}}>Tax Value</div>),
            accessor: 'tax',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.tax).toFixed(2))}
                </div>
            ))
          },{
            // Header: 'Gross Value',
            Header: () => (<div style={{textAlign:"right"}}>Gross Value</div>),
            accessor: 'total',
            width: "10%",
            Cell:((row)=>(
                <div style={{
                        textAlign:"right"
                      }}>
                  {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
          },
        ]

        return(
            <div>
                {(isReportListLoading || 
                    (reportList && reportList.length && reportList.length === 0)) ? 
                    <Loader show={isReportListLoading}/> :''}

                {(isValidationError || reportListError !== '')  && 
                    <ErrorModal
                        show={true} 
                        massage={errorMassage ? errorMassage : reportListError}
                        handleClose={this.errorModalClose}
                    />}
                <div className="content-header">
                    <div className="header-pages">
                        {/* <span><Link to='./customer' onClick={() => this.redirectCustomer()}><ui5-icon class="samples-margin" name="customer" id="tab-icon"></ui5-icon> Customer</Link></span>
                        <span><Link to='./quotation'><ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Quotation</Link></span>
                        <span><Link to='./invoice' onClick={() => this.redirectInvoice()}><ui5-icon class="samples-margin" name="monitor-payments" id="tab-icon"></ui5-icon> Invoice</Link></span> */}
                        <span><Link to='./salesReports'><ui5-icon class="samples-margin" name="bar-chart" id="tab-icon"></ui5-icon>Sales Report</Link></span>
                    </div>
                </div>
                <div className="content-body">
                    <h5>Sales Report</h5>
                    <div className="filter-form">
                        <div className="row ">
                            <div className="col-sm">
                                <SelectField
                                    text="Report Period"
                                    controlId="reportPeriod"
                                    onChange={(e)=>this.handleChangeSelect(e,'reportPeriod')}
                                    options={reportPeriodList}
                                    selectedValue={this.state.reportPeriod}
                                    isClearable
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="From date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e,"fromDate")}
                                    value={this.state.fromDate === '' ? '' :new Date(this.state.fromDate)}
                                    required
                                    // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e,"toDate")}
                                    value={this.state.toDate === '' ? '' :new Date(this.state.toDate)}
                                    required
                                    // format={dateFormat}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <SelectField
                                    text="Group By"
                                    controlId="groupBy"
                                    onChange={(e)=>this.handleChangeSelect(e,'groupBy')}
                                    options={common.groupBy}
                                    selectedValue={this.state.groupBy}
                                    isClearable
                                    required
                                />
                            </div>
                        </div>
                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={() => this.handleRunReport()}
                                primaryBtnIcon='accept'
                                primaryBtnText='Run Report'
                                primaryBtnVariant="outline-primary" 
                                // secondaryBtnClick={() => this.createCSV.click()}
                                secondaryBtnClick={() => this.downloadReport()}
                                secondaryBtnIcon='email'
                                secondaryBtnText='CSV'
                                secondaryBtnVariant="outline-primary" 
                            />
                            <ButtonGroup
                                secondaryBtnClick={() => this.handleClose()}
                                secondaryBtnIcon='decline'
                                secondaryBtnText='Cancel'
                                secondaryBtnVariant="outline-primary" 
                                primaryBtnClick={() => this.handlePrint()}
                                primaryBtnIcon='print'
                                primaryBtnText='PDF'
                                primaryBtnVariant="outline-primary" 
                            />
                            <CSVLink
                              headers={csvHeaders}
                              filename="Sales Report.csv"
                              data={csvData}
                              ref={this.csvLinkEl}
                            />
                        </div>
                    </div>
                    <div className="table-content">
                        <Table
                            columns={
                                this.state.groupBy === 1 ? columnInvoiceNo : 
                                (this.state.groupBy === 2 ?  columnDate : 
                                    (this.state.groupBy === 3 ? columnMonth : columnYear)) }
                            data={salesReportList}                   
                        />
                    </div>
                </div>
                {salesReportList.length > 0 && 
                <Pagination
                    currentPage={reportList.page ? reportList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {reportList.totalPages ? reportList.totalPages : 1}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />}
                {
                  isPrintModalOpen && reportPdf !== '' &&
                    <PdfViewer
                      show={isPrintModalOpen}
                      pdf={reportPdf}
                      onHide={this.printModalClose}
                      title="Sales Report"
                    />
                } 
            </div>
        )
    }
}

SalesReport.propTypes = {
    reportPeriodList: PropTypes.any,
    reportList: PropTypes.any,
    reportListError: PropTypes.any,
    isReportListLoading: PropTypes.any,
    reportPdf: PropTypes.any,
    reportCSV: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    reportPeriodList: selectors.makeSelectReportPeriodSuccess(),
    reportList: selectors.makeSelectReportList(),
    reportListError: selectors.makeSelectReportListError(),
    isReportListLoading: selectors.makeSelectReportListLoading(),
    reportPdf: selectors.makeSelectReportPrintSuccess(),
    reportCSV: selectors.makeSelectReportCSVSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(SalesReport));