import React, { Component } from "react";
import TextField from "../../../layout/form/TextField";
import SelectField from "../../../layout/form/SelectField";
import Table from "../../../layout/form/Table";
import { connect } from 'react-redux';
import { compose } from 'redux';
import common from '../../../common';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/userRoleSelector';
import * as actions from '../../../../store/action/userRoleAction';
import AddAccess from "./AddAccess";
import ButtonGroup from "../../../layout/form/ButtonGroup";
import ErrorModal from "../../../layout/form/errorModal";

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

class AddNewRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            isAccessModalOpen: false,
            selectedObject: '',
            roleName: '',
            initial: true,
            modifyUserRole: false,
            isDelete:false,
            tempList :[],
            isValidationError: false, 
            errorMassage: null
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { initial } = prevState;
        const { selectedRole, isUpdate, roleObjectList, objectList, userAccess } = prevProps;
        var objectList_ = []

        if (initial && selectedRole && selectedRole.id > 0 && isUpdate && roleObjectList && roleObjectList.length > 0) {
            var access = false
            if (userAccess && userAccess.modifyUserRole && selectedRole.modifiable) {
                access = true
            }
            for (let i = 0; i < roleObjectList.length; i++) {
                const element = roleObjectList[i]
                const object = objectList.find(e => e.id === element.id)
                const newRow = {
                    id: element.id,
                    operation: "U",
                    description: object ? object.description : '',
                    operationList: []
                }
                objectList_.push(newRow)
            }
            return {
                initial: false,
                roleName: selectedRole.description,
                list: objectList_,
                modifyUserRole: access
            }
        } else if (initial && !isUpdate) {
            access = false
            if (userAccess && userAccess.createUserRole) {
                access = true
            }
            return {
                initial: false,
                modifyUserRole: access
            }
        }
        return null;
    }

    addNewRow = () => {
        const { list } = this.state;
        const newRow = {
            id: '',
            operation: "C",
            description: "",
            operationList: []
        }
        list.push(newRow)
        this.setState({
            list: list
        })
    }

    handleRowObject = (id, rowData) => {
        const { list } = this.state;
        const { objectList } = this.props;
        const element = objectList.find(e => e.id === id)
        const isExist = list.find(e => e.id === id)
        if(isExist){
            this.setState({ isValidationError: true, errorMassage: common.massages.objectExistMassage })
            return
        }
        const newRow = {
            id: id,
            description: element.description,
            operation: rowData.original.operation,
            operationList: []
        }
        list[rowData.index] = newRow
        this.setState({
            list: list
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
    }

    handleModal = (details) => {
        const { selectedRole } = this.props;
        this.setState({
            isAccessModalOpen: true,
            selectedObject: details
        })
        const parameters = {
            search: details.original.id,
            role: selectedRole && selectedRole.id ? selectedRole.id : 0
        }
        this.props.dispatch(actions.loadObjectOperationListRequest(parameters))
    }

    handleModalClose = () => {
        const { selectedObject, list } = this.state;
        const newRow = {
            id: selectedObject.original.id,
            description: selectedObject.original.description,
            operation: selectedObject.original.operation,
            operationList: selectedObject.original.operationList
        }
        list[selectedObject.index] = newRow
        this.setState({
            isAccessModalOpen: false,
            selectedObject: '',
            list: list,
        })
        this.props.dispatch(actions.loadObjectOperationListSuccess([]))
    }

    handleAddNewAccess = (details) => {
        const { selectedObject, list } = this.state;
        const newRow = {
            id: selectedObject.original.id,
            description: selectedObject.original.description,
            operation: selectedObject.original.operation,
            operationList: details.operationList
        }
        list[selectedObject.index] = newRow
        this.setState({
            list: list,
            isAccessModalOpen: false,
            selectedObject: ''
        })
        this.props.dispatch(actions.loadObjectOperationListSuccess([]))
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    handleDelete = (rowData) => {
        var {list} = this.state;
        const newRow = rowData.original
        newRow.operation = "D"
        list[rowData.index] = newRow
        this.setState({list, isDelete:true})
    }

    componentDidUpdate(prevProps, prevState){
        const { list, isDelete, tempList } = this.state;
        if(isDelete){
            const itemsList = list.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = list.filter(data => (data.id !== null ))
            this.setState({list:itemsList, isDelete:false, tempList:tempList.concat(tempItemList)})
        }
    }


    render() {

        const {
            handleClose,
            objectList,
            objectOperationList,
            handleAddNewRole
        } = this.props;

        const {
            list,
            isAccessModalOpen,
            selectedObject,
            modifyUserRole
        } = this.state;

        const columns = [{
            Header: 'Object',
            accessor: 'id',
            Cell: (row) => (
                <div>
                    <SelectField
                        controlId="id"
                        onChange={(e) => this.handleRowObject(e, row.row)}
                        options={objectList && objectList.length ? objectList : []}
                        style={customStyles}
                        selectedValue={row.row.original.id}
                        disabled={!modifyUserRole}
                    />
                </div>
            ),
        }, {
            Header: 'Access',
            width: "5%",
            Cell: ((row) => (
                <div>
                    <span style={{ float: 'center' }} onClick={row.row.original.id === '' ? '' : () => this.handleModal(row.row)}>
                        <ui5-icon class="samples-margin" name="permission" id="table-icon"></ui5-icon>
                    </span>
                    <span onClick={modifyUserRole ? ()=>this.handleDelete(row.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyUserRole ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                </div>
            )),
            accessor: 'access'
            // }, {
            //     Header: 'Action',
            //     width: "5%",
            //     Cell: ((row) => (
            //         <div>
            //             <span style={{ float: 'left' }}>
            //                 <ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon>
            //             </span>
            //         </div>
            //     )),
            //     accessor: 'action'
        }]

        return (
            <div>
                {(this.state.isValidationError ) &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage}
                        handleClose={this.errorModalClose}
                    />}
                {
                    isAccessModalOpen &&
                    <AddAccess
                        isAccessModalOpen={isAccessModalOpen}
                        handleModalClose={this.handleModalClose}
                        objectOperationList={objectOperationList}
                        selectedObject={selectedObject.original}
                        handleAddNewAccess={this.handleAddNewAccess}
                        modifyUserRole={modifyUserRole}
                    />
                }
                <div className='content-body'>
                    <div className="filter-header">
                        <div className="header-pages">
                            <span>Role </span>
                        </div>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <div className="content-header" style={{ margin: '-50px 0 15px 0' }}></div>
                </div>
                <div className="profile-content" >
                    <div className="row" style={{ margin: '2px' }}>
                        <div className="col-sm-5">
                            <TextField
                                text="Role name"
                                controlId="roleName"
                                placeholder="Role Name"
                                type="text"
                                onChange={this.handleChange}
                                defaultValue={this.state.roleName}
                                required
                                maxLength={common.maxLengths.roleName}
                                disabled={!modifyUserRole}
                            />
                        </div>
                    </div>
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={list}
                        />
                    </div>
                    <div style={{marginRight:'20px'}} className="add-icon">
                        <ui5-icon class="samples-margin" name="sys-add" id={modifyUserRole ? "add-activity" : "add-activity-disabled"} onClick={modifyUserRole ? () => this.addNewRow() : ''}></ui5-icon>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonGroup
                            primaryBtnClick={handleClose}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Go Back'
                            primaryBtnIcon='undo'
                            secondaryBtnClick={() => handleAddNewRole(this.state)}
                            secondaryBtnText='Save'
                            secondaryBtnVariant="outline-primary"
                            secondaryBtnIcon='save'
                            secondaryBtnDisabled={!modifyUserRole}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    objectOperationList: selectors.makeObjectOperationListSuccess(),
    roleObjectList: selectors.makeUserRoleObjectListSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AddNewRole));