import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
import Form from 'react-bootstrap/cjs/Form';
import common from '../../../common';

export class AddProduct extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            sku: '',
            description: '',
            unitOfMeasure: '',
            categoryId: '',
            quantityInStock: parseFloat(0).toFixed(2),
            unitPrice: parseFloat(0).toFixed(2),
            status:'',
            // salesPrice:'',
            initial:true,
            productType:'',
            productTypeId:1,
            typeIcon:"action-settings",
            cost:parseFloat(0).toFixed(2),
            expenseAccount:'',
            lowStockLevel:parseFloat(0).toFixed(2),
            maxOrderQuantity:parseFloat(0).toFixed(2),
            minOrderQuantity:parseFloat(0).toFixed(2),
            incomeAccount: '',
            isPurchasing : true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        
        const {isUpdate, selectedProduct, objectStatusList,productTypeList } = prevProps;
        if(selectedProduct && selectedProduct.id && prevState.initial && isUpdate){
            const typeId = parseInt(selectedProduct.typeId)
            const type = productTypeList.find(select =>  select.id === typeId)
            return {
                sku: selectedProduct.sku,
                description: selectedProduct.description,
                unitOfMeasure: selectedProduct.unitOfMeasureId,
                categoryId: selectedProduct.categoryId,
                quantityInStock: parseFloat(selectedProduct.quantityInStock).toFixed(2),
                unitPrice: parseFloat(selectedProduct.unitPrice).toFixed(2),
                status: selectedProduct.statusId,
                productType: type.description,
                productTypeId: typeId,
                lowStockLevel: parseFloat(selectedProduct.lowStockLevel).toFixed(2),
                minOrderQuantity: parseFloat(selectedProduct.minOrderQuantity).toFixed(2),
                maxOrderQuantity: parseFloat(selectedProduct.maxOrderQuantity).toFixed(2),
                isPurchasing : selectedProduct.purchasingFlg,
                cost: parseFloat(selectedProduct.costPrice).toFixed(2),
                expenseAccount: selectedProduct.expenseAccount,
                incomeAccount: selectedProduct.incomeAccount,
                id: selectedProduct.id,
                typeIcon: typeId === 1 ? "action-settings" : "technical-object",
                // salesPrice:selectedRow.salesPrice,
                initial:false
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const type = productTypeList[0]
            const typeId = type.id
            return {
                status:defaultStatus[0].id,
                initial:false,
                productType: type.description,
                productTypeId: typeId,
                typeIcon: typeId === 1 ? "action-settings" : "technical-object",
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeProductType = (id) => {
        const { productTypeList } =this.props;
        var purchaseFlag = true
        if(id === 2){
            purchaseFlag = false
        }
        const type = productTypeList.find(select =>  select.id === id)
        this.setState({
            productTypeId: id,
            productType: type.description,
            typeIcon: id === 1 ? "action-settings" : "technical-object",
            isPurchasing : purchaseFlag
        })
    }

    handleOnBlur = (e) => {
        this.setState({
            [e.target.id]: parseFloat(e.target.value).toFixed(2)
        })
    }

    handleChangeStatus = (value) =>{
        this.setState({status:value})
    }

    handleChangeIncomeAccount = (value) =>{
        this.setState({incomeAccount:value})
    }

    handleChangeCategory = (value) =>{
        this.setState({categoryId:value})
    }
    
    handleChangeUOM = (value) =>{
        this.setState({unitOfMeasure:value})
    }

    handleCheck = (e) => {
        this.setState({
            isPurchasing:e.target.checked
        })
    }
  
    render() {
        const {
            isAddNewProductModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            productCategoryList,
            objectStatusList,
            uomList,
            productTypeList
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewProductModalOpen} 
                    onHide={handleModalClose}
                    title="Product/Service Information"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form>
                                <div className='product-type'>
                                    <div>
                                        <ui5-icon class="samples-margin" name={this.state.typeIcon} id="product-icon"></ui5-icon>
                                    </div>
                                    <div className='product-type-text'> { this.state.productType } </div> 
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField                                            
                                            text="Change Type"
                                            controlId="productType"
                                            onChange={this.handleChangeProductType}
                                            options={productTypeList}
                                            selectedValue={this.state.productTypeId}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="SKU"
                                            controlId="sku"
                                            placeholder="SKU"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.sku}
                                            maxLength={common.maxLengths.sku}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField                                            
                                            text="Category"
                                            controlId="category"
                                            onChange={this.handleChangeCategory}
                                            options={productCategoryList}
                                            selectedValue={this.state.categoryId}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm"> 
                                        <TextField
                                            text="Description"
                                            controlId="description"
                                            placeholder="Description"
                                            type="text"
                                            as="textarea" 
                                            rows={3}
                                            onChange={this.handleChange}
                                            defaultValue={this.state.description}
                                            maxLength={common.maxLengths.productDescription}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Unit of Measure"
                                            controlId="unitOfMeasure"
                                            onChange={this.handleChangeUOM}
                                            options={uomList}
                                            selectedValue={this.state.unitOfMeasure}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Unit Price"
                                            controlId="unitPrice"
                                            placeholder="Unit Price"
                                            type="number"
                                            onChange={this.handleChange}
                                            // value={this.state.unitPrice === '' ? '' : parseFloat(this.state.unitPrice).toFixed(2)}
                                            value={this.state.unitPrice}
                                            onBlur={this.handleOnBlur}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productUnitPrice}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Income account"
                                            controlId="incomeAccount"
                                            onChange={this.handleChangeIncomeAccount}
                                            // options={objectStatusList}
                                            selectedValue={this.state.incomeAccount}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Quntity"
                                            controlId="quantityInStock"
                                            placeholder="Quantity"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.quantityInStock}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productQuantity}
                                            required={this.state.productTypeId === 1}
                                            disabled={this.state.productTypeId === 2}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Low stock level"
                                            controlId="lowStockLevel"
                                            placeholder="Low stock level"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.lowStockLevel}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productQuantity}
                                            required={this.state.productTypeId === 1}
                                            disabled={this.state.productTypeId === 2}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Min order quantity"
                                            controlId="minOrderQuantity"
                                            placeholder="Min order quantity"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.minOrderQuantity}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productQuantity}
                                            required={this.state.productTypeId === 1}
                                            disabled={this.state.productTypeId === 2}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Max order quantity"
                                            controlId="maxOrderQuantity"
                                            placeholder="Max order quantity"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.maxOrderQuantity}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productQuantity}
                                            required={this.state.productTypeId === 1}
                                            disabled={this.state.productTypeId === 2}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={this.handleChangeStatus}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            required
                                        />
                                     </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm" style={{marginLeft:'5px'}}>
                                        <Form.Group controlId="purchaseCheckBox">
                                            <Form.Check type="checkbox" style={{fontSize:"14px"}} checked={this.state.isPurchasing} label="Purchase this product/service from a supplier" onChange={this.handleCheck}/>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Cost"
                                            controlId="cost"
                                            placeholder="Cost"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.cost}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.productUnitPrice}
                                            disabled = {!this.state.isPurchasing}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Expense account"
                                            controlId="expenseAccount"
                                            // onChange={this.handleChangeCost}
                                            selectedValue={this.state.cost}
                                            disabled = {!this.state.isPurchasing}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

AddProduct.propTypes = {
    isAddNewProductModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    saveDetails: PropTypes.any,
    productCategoryList: PropTypes.any,
    objectStatusList: PropTypes.any,
    uomList: PropTypes.any,
    selectedRow: PropTypes.any
}

export default AddProduct;