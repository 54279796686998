const PATH = 'app/Booking';

export const SAVE_IS_REMINDER_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_REMINDER_MODAL_OPEN_STATUS`;
export const SEND_ADD_NEW_REMINDER_REQUEST = `${PATH}SEND_ADD_NEW_REMINDER_REQUEST`;
export const LOAD_REMINDER_LIST_REQUEST = `${PATH}LOAD_REMINDER_LIST_REQUEST`;
export const LOAD_REMINDER_LIST_ERROR = `${PATH}LOAD_REMINDER_LIST_ERROR`;
export const LOAD_REMINDER_LIST_LOADING = `${PATH}LOAD_REMINDER_LIST_LOADING`;
export const LOAD_REMINDER_LIST_SUCCESS = `${PATH}LOAD_REMINDER_LIST_SUCCESS`;
export const SEND_REMINDER_SUCCESS = `${PATH}SEND_REMINDER_SUCCESS`;
export const SEND_REMINDER_DELETE_REQUEST = `${PATH}SEND_REMINDER_DELETE_REQUEST`;
export const SEND_REMINDER_UPDATE_REQUEST = `${PATH}SEND_REMINDER_UPDATE_REQUEST`;
export const LOAD_SEARCHED_VEHICLE_REQUEST = `${PATH}LOAD_SEARCHED_VEHICLE_REQUEST`;
export const SAVE_REMINDER_DETAILS_SUCCESS = `${PATH}SAVE_REMINDER_DETAILS_SUCCESS`;
export const SAVE_IS_NEW_REMINDER_USING_VEHICLE =  `${PATH}SAVE_IS_NEW_REMINDER_USING_VEHICLE`;
export const SAVE_NEW_REMINDER_VEHICLE_DETAILS =  `${PATH}SAVE_NEW_REMINDER_VEHICLE_DETAILS`;
export const SEND_REMINDER_REMINDER_REQUEST = `${PATH}SEND_REMINDER_REMINDER_REQUEST`;
export const LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS = `${PATH}LOAD_UNCONFIRMED_REMINDER_LIST_SUCCESS`;
export const LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST = `${PATH}LOAD_UNCONFIRMED_REMINDER_LIST_REQUEST`;