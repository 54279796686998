import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import { Link } from 'react-router-dom';
import * as selectors from '../../../../store/selector/stockDocumentSelector';
import * as actions from '../../../../store/action/stockDocumentAction';
import Table from "../../../layout/form/Table";
import SearchField from "../../../layout/form/SearchField";
import moment from "moment";
import common from "../../../common";
import AddStockDocument from "./AddGoodsReceipts";
import Loader from "../../../layout/form/Loader";
import ErrorModal from "../../../layout/form/errorModal";
import SuccessModal from "../../../layout/form/successModal";
import SelectField from "../../../layout/form/SelectField";
import ButtonGroup from "../../../layout/form/ButtonGroup";
import DatePicker from '../../../layout/form/datePicker';
import { loadSupplierListRequest, loadSupplierListSuccess } from "../../../../store/action/supplierAction";
import { makeSelectSupplierList } from "../../../../store/selector/supplierSelector";
import Pagination from "../../../layout/form/Pagination";
import PdfViewer from "../../../layout/form/PdfViewer";
import { makeSelectUserAccessSuccess } from "../../../../store/selector/usersSelector";

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

class GoodsReceipts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            type: "10",
            errorMassage: '',
            isValidationError: false,
            selectedRow: {},
            isUpdate: false,
            isDeleteModalOpen: false,
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
            isFilterFormOpen: false,
            supplier: '',
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            isStockDocumentPrintModalOpen: false,
        }
    }

    getStockDocument = () => {

        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            commonSearch: this.state.commonSearch,
            supplier: this.state.supplier,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            type: this.state.type
        }
        this.props.dispatch(actions.loadStockDocumentListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            commonSearch: value,
            supplier: this.state.supplier,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            type: this.state.type
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            commonSearch: value
        })
        this.props.dispatch(actions.loadStockDocumentListRequest(parameters))
    }

    handleChangeSearchValue = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen: false,
            pageSize: 20,
            pageNumber: 1,
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            supplier: '',
        })
    }

    handleFilterFormClose = () => {
        this.setState({
            isFilterFormOpen: false,
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            type: this.state.type,
            supplier: ''
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
            supplier: '',
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            type: this.state.type
        }
        this.props.dispatch(actions.loadStockDocumentListRequest(parameters))
    }

    handleFilter = () => {
        this.getStockDocument()
        this.handleFilterForm()
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "fromDate") {
            this.setState({
                [id]: moment(prevDate).format(common.dateFormat),
            })
        }
        else if (date === null && id === "toDate") {
            this.setState({
                [id]: moment(today).format(common.dateFormat),
            })
        } else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    setDocumentType = (id) => {
        const { stockDocumentTypeList } = this.props;
        if (stockDocumentTypeList && stockDocumentTypeList.length && stockDocumentTypeList.length !== 0) {
            const desc = stockDocumentTypeList.find(select => select.id === id)
            return (desc && desc.description ? desc.description : '')
        }
    }

    setSupplier = (data) => {
        const { supplierList } = this.props;
        if (supplierList && supplierList.result && supplierList.result.length && supplierList.result.length !== 0) {
            const desc = supplierList.result.find(select => select.id === data.supplierId)
            return (desc && desc.name ? desc.name : '')
        }
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    handleStockDocument = (isUpdate, data) => {
        const stockDocument = common.stockDocument
        stockDocument.object = null
        this.props.dispatch(actions.saveIsStockDocumentOpenStatus(true))
        this.props.dispatch(actions.saveStockDocumentDetails(stockDocument))
        if (isUpdate) {
            stockDocument.object = common.objectId.stockDocument
            this.props.dispatch(actions.loadSelectedStockDocumentRequest(data.id))
        }

    }

    handleStockDocumentClose = () => {
        this.props.dispatch(actions.saveIsStockDocumentOpenStatus(false))
        this.props.dispatch(actions.saveStockDocumentDetails(common.stockDocument))
        this.props.dispatch(actions.loadSelectedStockDocumentSuccess({}))
    }

    handleStockDocumentSave = (details) => {
        if (details.documentType === '' || details.supplier === '' || details.supplier === 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if (details.documentItem.length <= 0) {
        //     this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
        //     return
        // }

        const parameters = common.parameters.stockDocument
        const userId = sessionStorage.getItem("tenantUserId")
        parameters.DocumentItems = details.documentItem
        parameters.DocumentDate = details.date
        parameters.DocumentType = details.documentType
        parameters.HeaderText = details.documentText
        parameters.UserId = parseInt(userId)
        parameters.ReferenceNo = details.purchaseOrder
        parameters.ReferenceObject = details.purchaseOrder ? common.objectId.purchasing : common.objectId.stockDocument
        parameters.SupplierId = details.supplier
        this.props.dispatch(actions.sendAnyTypeStockDocumentRequest(parameters))
    }

    stockDocumentPrintAndSave = (details) => {
        if (details.documentType === '' || details.supplier === '' || details.supplier === 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if (details.documentItem.length <= 0) {
        //     this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
        //     return
        // }

        const parameters = common.parameters.stockDocument
        const userId = sessionStorage.getItem("tenantUserId")
        parameters.DocumentItems = details.documentItem
        parameters.DocumentDate = details.date
        parameters.DocumentType = details.documentType
        parameters.HeaderText = details.documentText
        parameters.UserId = parseInt(userId)
        parameters.ReferenceNo = details.purchaseOrder
        parameters.ReferenceObject = details.purchaseOrder ? common.objectId.purchasing : common.objectId.stockDocument
        parameters.SupplierId = details.supplier

        this.props.dispatch(actions.sendStockDocumentPrintRequest(parameters))
        this.setState({
            isStockDocumentPrintModalOpen: true
        })
    }

    handlePrint = (data) => {
        this.props.dispatch(actions.loadStockDocumentPrintRequest(data.id))
        this.setState({
            isStockDocumentPrintModalOpen: true
        })
    }

    stockDocumentPrintModalClose = () => {
        this.setState({ isStockDocumentPrintModalOpen: false })
        this.props.dispatch(actions.loadStockDocumentPrintSuccess(null))
        this.handleStockDocumentClose()
        this.getStockDocument()
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            commonSearch: '',
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadStockDocumentListError(null))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendStockDocumentSuccess(null))
        this.handleStockDocumentClose()
        this.getStockDocument()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.stockDocumentSuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getStockDocument()
        }
    }

    componentDidMount() {
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            name: '',
            email: '',
            mobile: '',
            city: '',
            commonSearch: '',
        }
        this.props.dispatch(loadSupplierListSuccess([]))
        this.props.dispatch(actions.loadStockDocumentListSuccess([]))
        this.props.dispatch(loadSupplierListRequest(parameters))
        this.props.dispatch(actions.loadStockDocumentTypeRequest())
        this.props.dispatch(actions.loadStockDocumentPrintSuccess(null))
        this.getStockDocument()
        this.handleStockDocumentClose()
    }

    render() {
        const {
            stockDocumentList,
            isStockDocumentOpen,
            isStockDocumentListLoading,
            stockDocumentListError,
            stockDocumentSuccess,
            supplierList,
            stockDocumentPrint,
            userAccess
        } = this.props;

        const {
            isValidationError,
            errorMassage,
            isFilterFormOpen,
            isStockDocumentPrintModalOpen,
        } = this.state;

        const columns = [{
            Header: 'Document No',
            accessor: 'documentNo',
            width: "12%",
        }, {
            Header: 'Document Type',
            accessor: 'documentType',
            Cell: ((row) => (
                <div >
                    {this.setDocumentType(row.row.original.documentType)}
                </div>
            )),
            width: "15%"
        }, {
            Header: 'Supplier',
            accessor: 'supplier',
            Cell: ((row) => (
                <div >
                    {row.row.original.supplierId ? this.setSupplier(row.row.original) : ''}
                </div>
            ))
        }, {
            Header: 'Document Date',
            accessor: 'documentDate',
            width: "12%",
            Cell: ((row) => (
                <div >
                    {row.row.original.documentDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.documentDate)}
                </div>
            )),
        }, {
            Header: 'User',
            accessor: 'userName',
            width: "15%",
        }, {
            Header: 'Action',
            width: "5%",
            Cell: ((row) => (
                <div>
                    <span className="dropdown" >
                        <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                        <div className="dropdown-content">
                            <li onClick={() => this.handleStockDocument(true, row.row.original)}><ui5-icon class="samples-margin" name="display" id="action-icon"></ui5-icon> Display</li>
                            <li onClick={() => this.handlePrint(row.row.original)}><ui5-icon class="samples-margin" name="print" id="action-icon"></ui5-icon> Print</li>
                        </div>
                    </span>
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {isStockDocumentListLoading ?
                    <Loader show={isStockDocumentListLoading} /> : ''}

                {(isValidationError || stockDocumentListError !== null) &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : stockDocumentListError}
                        handleClose={this.errorModalClose}
                    />}

                {(stockDocumentSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={stockDocumentSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isStockDocumentOpen ?
                    <div>
                        <AddStockDocument
                            handleClose={this.handleStockDocumentClose}
                            handleSubmit={this.handleStockDocumentSave}
                            printModalClose={this.stockDocumentPrintModalClose}
                            isPrintModalOpen={isStockDocumentPrintModalOpen}
                            handlePrint={this.stockDocumentPrintAndSave}
                            handlePrintById={this.handlePrint}
                        />
                    </div> :
                    <div>
                        <div className="content-header">
                            <div className="header-pages">
                                <span><Link to='./stock'><ui5-icon class="samples-margin" name="product" id="tab-icon"></ui5-icon> Stocks</Link></span>
                                <span><Link to='./goodsReceipts'><ui5-icon class="samples-margin" name="order-status" id="tab-icon"></ui5-icon> Goods Receipts</Link></span>
                                <span><Link to='./goodsReturns'><ui5-icon class="samples-margin" name="fallback" id="tab-icon"></ui5-icon> Goods Returns</Link></span>
                                <span><Link to='./goodIssues'><ui5-icon class="samples-margin" name="cart-2" id="tab-icon"></ui5-icon> Goods Issues</Link></span>
                                <span><Link to='./customerReturns'><ui5-icon class="samples-margin" name="cart-3" id="tab-icon"></ui5-icon> Customer returns</Link></span>
                            </div>
                            <div className="header-btn">
                                <ButtonGroup
                                    primaryBtnClick={() => this.handleStockDocument(false, null)}
                                    secondaryBtnClick={this.handleFilterForm}
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnText='Add New'
                                    primaryBtnIcon='sys-add'
                                    secondaryBtnText='Filter'
                                    secondaryBtnVariant="outline-primary"
                                    secondaryBtnIcon='filter'
                                    primaryBtnDisabled={userAccess && !userAccess.createStockDocument}
                                />
                            </div>
                        </div>
                        <div className="body-content">
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <h5 style={{ marginLeft: '5px' }}>Goods Receipts</h5>
                                </div>
                                <div style={{ marginLeft: '20px' }}>
                                    <SearchField
                                        onClickSearch={this.getStockDocument}
                                        controlId="commonSearch"
                                        onChange={this.handleChangeSearchValue}
                                        defaultValue={this.state.commonSearch}
                                        onPressKeySearch={this.onPressKeySearch}
                                    />
                                </div>
                            </div>
                            {isFilterFormOpen &&
                                <div className="filter-form">
                                    <div className="filter-header">
                                        <h5>Filter Form</h5>
                                        <div className="btn-close" onClick={this.handleFilterFormClose} >
                                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <SelectField
                                                text="Supplier"
                                                controlId="supplier"
                                                onChange={(e) => this.handleChangeSelect(e, 'supplier')}
                                                options={
                                                    supplierList && supplierList.result && supplierList.result.length && supplierList.result.length > 0 ?
                                                        supplierList.result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ?
                                                            1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) : []}
                                                selectedValue={this.state.supplier}
                                                display="name"
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="From Date"
                                                controlId="fromDate"
                                                onChange={(e) => this.handleDateChange(e, "fromDate")}
                                                value={new Date(this.state.fromDate)}
                                            // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="To Date"
                                                controlId="toDate"
                                                onChange={(e) => this.handleDateChange(e, "toDate")}
                                                value={new Date(this.state.toDate)}
                                            // format={dateFormat}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-btns">
                                        <ButtonGroup
                                            primaryBtnClick={this.handleFilter}
                                            primaryBtnIcon='search'
                                            primaryBtnText='Search'
                                            primaryBtnVariant="outline-primary"
                                            secondaryBtnClick={this.handleFilterFormClose}
                                            secondaryBtnIcon='clear-filter'
                                            secondaryBtnText='Clear'
                                            secondaryBtnVariant="outline-primary"
                                        />
                                    </div>
                                </div>}
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={stockDocumentList.result && stockDocumentList.result.length ? stockDocumentList.result : []}
                                />
                            </div>
                            <Pagination
                                currentPage={stockDocumentList.page ? stockDocumentList.page : this.state.pageNumber}
                                getNextPage={this.getNextPage}
                                getPrevPage={this.getPrevPage}
                                totalPage={stockDocumentList.totalPages}
                                setClickedPage={this.setClickedPage}
                                currentPageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                        </div>
                    </div>
                }
                {
                    isStockDocumentPrintModalOpen && stockDocumentPrint !== null &&
                    <PdfViewer
                        show={isStockDocumentPrintModalOpen}
                        pdf={stockDocumentPrint}
                        onHide={this.stockDocumentPrintModalClose}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    stockDocumentList: selectors.makeSelectStockDocumentList(),
    stockDocumentTypeList: selectors.makeSelectStockDocumentTypeList(),
    isStockDocumentOpen: selectors.makeSelectStockDocumentPopupOpen(),
    stockDocumentListError: selectors.makeSelectStockDocumentListError(),
    isStockDocumentListLoading: selectors.makeSelectStockDocumentListLoading(),
    stockDocumentSuccess: selectors.makeSelectStockDocumentSuccess(),
    supplierList: makeSelectSupplierList(),
    stockDocumentPrint: selectors.makeSelectStockDocumentPrintSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(GoodsReceipts));