import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import Form from 'react-bootstrap/cjs/Form';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';
import * as selectors from '../../../../store/selector/stockDocumentSelector';
import * as actions from '../../../../store/action/stockDocumentAction';
import SelectField from "../../../layout/form/SelectField";
import DatePicker from '../../../layout/form/datePicker';
// import { loadJobCardListRequest } from "../../../../store/action/jobCardAction";
import { loadSupplierListRequest } from "../../../../store/action/supplierAction";
import { loadPurchasingListRequest, loadSelectedPurchasingRequest, loadSelectedPurchasingSuccess } from "../../../../store/action/purchasingAction";
import { makeSelectPurchasingList, makeSelectSelectedPurchasingSuccess } from "../../../../store/selector/purchasingSelector";
import { makeSelectSupplierList } from "../../../../store/selector/supplierSelector";
// import { makeSelectJobCardList } from "../../../../store/selector/jobCardSelector";
import TextField from "../../../layout/form/TextField";
import common from "../../../common";
import { makeSelectPartList } from "../../../../store/selector/partSelector";
import { makeSelectUOMList } from "../../../../store/selector/selector";
import Table from "../../../layout/form/Table";
import ButtonGroup from "../../../layout/form/ButtonGroup";
import Button from "../../../layout/form/Button";
import moment from "moment";
import { loadProductListRequest } from "../../../../store/action/productAction";
import PdfViewer from "../../../layout/form/PdfViewer";

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

function calTotal(list) {
    var total = 0
    for (let i = 0; i < list.length; i++) {
        const element = list[i]
        total = total + element.quantity * element.price
    }
    return total;
}

class AddGoodsReturns extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initial: true,
            supplier: 0,
            date: new Date(),
            purchaseOrder: '',
            jobCardNo: '',
            documentText: '',
            vehicleNo: '',
            documentItem: [],
            totalValue: 0,
            displayOnly: false,
            isAddNewItem: true,
            documentType: "25",
            purchaseOrderList: [],
            supplierField: false,
            purchaseOrderField: false,
            isChangeSupplier: false,
            isPurchaseOrderChanges: false,
            isSelectedPurchaseOrder: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { stockDocumentDetails, selectedPurchasing, partList, stockDocument, purchasingList } = prevProps;
        const { initial } = prevState;
        var item = []
        var total = 0

        if (initial && stockDocumentDetails && stockDocumentDetails.object === common.objectId.purchasing && selectedPurchasing && selectedPurchasing.id > 0 && purchasingList && purchasingList.result && purchasingList.result.length >= 0) {
            for (let i = 0; i < selectedPurchasing.purchaseOrderItems.length; i++) {
                const purchasing = selectedPurchasing.purchaseOrderItems[i]
                const product = partList.find(e => (e.sku === purchasing.partId))
                const element = {
                    quantity: purchasing.quantity,
                    price: purchasing.price,
                    subTotal: purchasing.quantity * purchasing.price,
                    referenceNo: selectedPurchasing.id,
                    referenceObject: common.objectId.purchasing,
                    lineNumber: purchasing.lineNumber,
                    description: product.description,
                    productSKU: purchasing.partId,
                    unitOfMeasure: product.unitOfMeasureId,
                    productType: product.typeId,
                    productId: product.id,
                    jobCardId: stockDocumentDetails.jobCardId ? stockDocumentDetails.jobCardId : 0
                }
                item.push(element)
                total = total + element.subTotal
            }
            return {
                supplier: stockDocumentDetails.supplierId,
                purchaseOrder: stockDocumentDetails.purchaseOrderId,
                documentItem: item,
                totalValue: total,
                initial: false,
                isAddNewItem: false,
                purchaseOrderList: purchasingList.result,
                supplierField: true,
                purchaseOrderField: true
            }
        }
        else if (initial && stockDocumentDetails.object === common.objectId.stockDocument && stockDocument && stockDocument.id > 0 && purchasingList && purchasingList.result && purchasingList.result.length >= 0) {
            return {
                initial: false,
                documentItem: stockDocument.documentItems,
                date: new Date(stockDocument.documentDate),
                documentText: stockDocument.headerText,
                documentType: stockDocument.documentType,
                supplier: stockDocument.supplierId,
                purchaseOrder: stockDocument.referenceNo,
                totalValue: calTotal(stockDocument.documentItems),
                displayOnly: true,
                isAddNewItem: false,
                purchaseOrderList: purchasingList.result,
                supplierField: true,
                purchaseOrderField: true
            }
        }
        else if (initial && stockDocumentDetails.object === null && purchasingList && purchasingList.result && purchasingList.result.length >= 0) {
            return {
                initial: false,
                purchaseOrderList: purchasingList.result
            }
        }
        return null;
    }

    calTotal = (list) => {
        var total = 0
        for (let i = 0; i < list.length; i++) {
            const element = list[i]
            total = total + element.subTotal
        }
        this.setState({
            documentItem: list,
            totalValue: total
        })
    }

    handleChangeSelect = (id, value) => {
        const { documentItem } = this.state
        var isChangeSupplier = false
        var isPurchaseOrderChanges = false
        var list = documentItem
        if (id === "supplier") {
            isChangeSupplier = true
            list = []
        } else if (id === "purchaseOrder") {
            isPurchaseOrderChanges = true
            list = []
        }
        this.setState({
            [id]: value,
            isChangeSupplier,
            isPurchaseOrderChanges,
            documentItem: list
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateChange = (date, id) => {
        if (date === null || date === '') {
            this.setState({
                [id]: '',
            })
        } else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }

    }

    handleRowData = (id, rowData) => {
        const { partList } = this.props;
        const { documentItem } = this.state;
        const selectedRow = partList.find(row => row.id === id)
        const newRow = {
            quantity: 0,
            price: 0,
            referenceNo: '',
            referenceObject: '',
            lineNumber: '',
            description: selectedRow.description,
            productSKU: selectedRow.sku,
            unitOfMeasure: selectedRow.unitOfMeasureId,
            productType: selectedRow.typeId,
            productId: selectedRow.id,
            jobCardId: '',
            subTotal: 0
        }
        documentItem[rowData.index] = newRow
        this.setState({ documentItem })
    }

    handleJobCard = (id, rowData) => {
        const { documentItem } = this.state;
        const selectedRow = documentItem[rowData.index]
        const newRow = {
            quantity: selectedRow.quantity,
            price: selectedRow.price,
            referenceNo: selectedRow.referenceNo,
            referenceObject: selectedRow.referenceObject,
            lineNumber: selectedRow.lineNumber,
            description: selectedRow.description,
            productSKU: selectedRow.productSKU,
            unitOfMeasure: selectedRow.unitOfMeasure,
            productType: selectedRow.productType,
            productId: selectedRow.productId,
            jobCardId: id,
            subTotal: selectedRow.subTotal
        }
        documentItem[rowData.index] = newRow
        this.setState({ documentItem })
    }


    handleRowUnitPrice = (e, rowData) => {
        const { documentItem } = this.state;
        const selectedRow = documentItem[rowData.index]
        const newRow = {
            quantity: selectedRow.quantity,
            price: e.target.value,
            referenceNo: selectedRow.referenceNo,
            referenceObject: selectedRow.referenceObject,
            lineNumber: selectedRow.lineNumber,
            description: selectedRow.description,
            productSKU: selectedRow.productSKU,
            unitOfMeasure: selectedRow.unitOfMeasure,
            productType: selectedRow.productType,
            productId: selectedRow.productId,
            jobCardId: selectedRow.jobCardId,
            subTotal: (e.target.value && selectedRow.quantity ? e.target.value * selectedRow.quantity : 0)
        }
        documentItem[rowData.index] = newRow
        this.calTotal(documentItem)
    }

    handleRowQuantity = (e, rowData) => {
        const { documentItem } = this.state;
        const selectedRow = documentItem[rowData.index]
        const newRow = {
            quantity: e.target.value,
            price: selectedRow.price,
            referenceNo: selectedRow.referenceNo,
            referenceObject: selectedRow.referenceObject,
            lineNumber: selectedRow.lineNumber,
            description: selectedRow.description,
            productSKU: selectedRow.productSKU,
            unitOfMeasure: selectedRow.unitOfMeasure,
            productType: selectedRow.productType,
            productId: selectedRow.productId,
            jobCardId: selectedRow.jobCardId,
            subTotal: (e.target.value && selectedRow.price ? e.target.value * selectedRow.price : 0)
        }
        documentItem[rowData.index] = newRow
        this.calTotal(documentItem)
    }

    handleDelete = (rowData) => {
        var { documentItem } = this.state;
        const newList = documentItem.filter(e => (e.productId !== rowData.original.productId))
        this.calTotal(newList)
    }

    addNewRow = () => {
        var { documentItem } = this.state;
        const newRow = {
            quantity: 0,
            price: 0,
            referenceNo: '',
            referenceObject: common.objectId.stockDocument,
            lineNumber: '',
            description: '',
            productSKU: '',
            unitOfMeasure: '',
            productType: '',
            productId: '',
            jobCardId: '',
            subTotal: 0
        }
        documentItem.push(newRow)
        this.setState({ documentItem })
    }

    componentDidUpdate(prevProps, prevState) {
        const { isChangeSupplier, isPurchaseOrderChanges, supplier, purchaseOrder, isSelectedPurchaseOrder } = this.state
        const { purchasingList, selectedPurchasing, partList } = this.props

        if (isChangeSupplier) {
            const orderList = purchasingList.result.filter(data => (data.supplier.id === supplier))
            this.setState({
                isChangeSupplier: false,
                purchaseOrderList: orderList
            })
        }

        else if (isPurchaseOrderChanges) {
            this.props.dispatch(loadSelectedPurchasingSuccess({}))
            this.props.dispatch(loadSelectedPurchasingRequest(purchaseOrder))
            this.setState({
                isPurchaseOrderChanges: false,
                isSelectedPurchaseOrder: true
            })
        }

        else if (isSelectedPurchaseOrder && selectedPurchasing && selectedPurchasing.id > 0) {
            var total = 0
            var item = []
            for (let i = 0; i < selectedPurchasing.purchaseOrderItems.length; i++) {
                const purchasing = selectedPurchasing.purchaseOrderItems[i]
                const product = partList.find(e => (e.sku === purchasing.partId))
                if (product && product.id > 0) {
                    const element = {
                        quantity: purchasing.quantity,
                        price: purchasing.price,
                        subTotal: purchasing.quantity * purchasing.price,
                        referenceNo: selectedPurchasing.id,
                        referenceObject: common.objectId.purchasing,
                        lineNumber: purchasing.lineNumber,
                        description: product.description,
                        productSKU: purchasing.partId,
                        unitOfMeasure: product.unitOfMeasureId,
                        productType: product.typeId,
                        productId: product.id,
                        jobCardId: selectedPurchasing.jobCardId ? selectedPurchasing.jobCardId : 0
                    }
                    item.push(element)
                    total = total + element.subTotal
                }
            }
            this.setState({
                isSelectedPurchaseOrder: false,
                documentItem: item,
                totalValue: total,
                isAddNewItem: false,
                supplier: selectedPurchasing.supplier.id
            })
        }

    }

    componentDidMount() {
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            Customer: '',
            VehicleRegNo: '',
            JobNo: '',
            Status: '',
            description: '',
            name: '',
            email: '',
            mobile: '',
            city: '',
            makeId: '',
            Make: '',
            Model: '',
            Search: '',
            status: '',
            codeId: '',
            categoryId: '',
            partId: '',
            commonSearch: '',
            sku: '',
            purchasingFlg: true,
            stockFlg: true,
            supplier: null,
            orderNo: '',
            jobCardId: '',
            vehicleId: '',
        }
        // this.props.dispatch(loadJobCardListRequest(parameters))
        this.props.dispatch(loadSupplierListRequest(parameters))
        this.props.dispatch(loadPurchasingListRequest(parameters))
        this.props.dispatch(actions.loadStockDocumentTypeRequest())
        this.props.dispatch(loadProductListRequest(parameters))
        this.props.dispatch(actions.loadStockDocumentPrintSuccess(null))
    }


    render() {
        const {
            handleClose,
            supplierList,
            stockDocumentTypeList,
            partList,
            uomList,
            handleSubmit,
            handlePrint,
            isPrintModalOpen,
            stockDocumentPrint,
            printModalClose,
            handlePrintById,
            stockDocument
        } = this.props;

        const {
            documentItem,
            displayOnly,
            isAddNewItem,
            purchaseOrderList,
            supplierField,
            purchaseOrderField
        } = this.state;

        const columns = [{
            Header: 'Line no',
            // accessor: 'no',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="index"
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="productId"
                        onChange={(e) => this.handleRowData(e, rowData.cell.row)}
                        options={partList}
                        style={customStyles}
                        id="id"
                        disabled={!isAddNewItem}
                        selectedValue={rowData.cell.row.original.productId}
                    />
                </div>
            ),
        }, {
            Header: 'SKU',
            // accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="productSKU"
                        type="text"
                        defaultValue={rowData.cell.row.original.productSKU}
                        size="sm"
                        disabled
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="unitOfMeasure"
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                        // disabled={!isAddNewItem}
                        disabled
                    />
                </div>
            ),
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Unit Price</div>),
            accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="price"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.price).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowUnitPrice(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        maxLength={10}
                        disabled={displayOnly}
                    />
                </div>
            )),
            width: "8%",
        }, {
            accessor: 'quantity',
            Header: () => (<div style={{ textAlign: "right" }}>Quantity</div>),
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="quantity"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.quantity).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowQuantity(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        maxLength={10}
                        disabled={displayOnly}
                    />
                </div>
            )),
            width: "8%",
        }, {
            accessor: 'subTotal',
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="amount"
                        type="text"
                        value={(rowData.cell.row.original.quantity && rowData.cell.row.original.price) ? parseFloat(rowData.cell.row.original.quantity * rowData.cell.row.original.price).toFixed(2) : '0.00'}
                        size="sm"
                        disabled={!isAddNewItem}
                        style={{ textAlign: 'right' }}
                    />
                </div>
            )),
            width: "8%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={displayOnly ? "" : () => this.handleDelete(rowData.cell.row)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                <div className="content-body">
                    <div className="filter-header">
                        <h5>
                            Goods returns
                        </h5>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <form style={{ margin: "15px" }}>
                        <div className="row">
                            <div className="col-sm-6">
                                <SelectField
                                    text="Supplier"
                                    controlId="supplier"
                                    options={
                                        supplierList && supplierList.result && supplierList.result.length && supplierList.result.length > 0 ?
                                            supplierList.result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ?
                                                1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) : []}
                                    selectedValue={this.state.supplier}
                                    onChange={(e) => this.handleChangeSelect("supplier", e)}
                                    display="name"
                                    disabled={supplierField}
                                />
                            </div>
                            <div className="col-sm">
                                <SelectField
                                    text="Purchase Order No"
                                    controlId="purchaseOrder"
                                    options={purchaseOrderList}
                                    selectedValue={this.state.purchaseOrder}
                                    onChange={(e) => this.handleChangeSelect("purchaseOrder", e)}
                                    display="orderNumber"
                                    disabled={purchaseOrderField}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="Date"
                                    controlId="date"
                                    onChange={(e) => this.handleDateChange(e, "date")}
                                    value={this.state.date === '' ? '' : new Date(this.state.date)}
                                    disabled={displayOnly}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <SelectField
                                    text="Document Type"
                                    controlId="documentType"

                                    options={stockDocumentTypeList && stockDocumentTypeList.length ? stockDocumentTypeList : []}
                                    selectedValue={this.state.documentType}
                                    onChange={(e) => this.handleChangeSelect("documentType", e)}
                                    disabled
                                />
                            </div>
                            {/* <div className="col-sm" style={{ marginTop: '30px' }}>
                                <Form.Group controlId="addressCheckBox">
                                    <Form.Check type="checkbox" style={{ fontSize: "14px" }} label="Automatically Issue to Jobcard" onChange={this.handleCheckMoreFields} />
                                </Form.Group>
                            </div>
                            <div className="col-sm">
                                <SelectField
                                    text="Job card no"
                                    controlId="jobCardNo"
                                    options={jobCardList.result && jobCardList.result.length ? jobCardList.result : []}
                                    selectedValue={this.state.jobCardNo}
                                    onChange={this.handleChangeSupplier}
                                    display="jobNumber"
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <TextField
                                    text="Document text"
                                    controlId="documentText"
                                    value={this.state.documentText}
                                    onChange={this.handleChange}
                                    as="textarea"
                                    row={25}
                                    style={{ height: 50 }}
                                    disabled={displayOnly}
                                />
                            </div>
                            {/* <div className="col-sm">
                                <TextField
                                    text="Vehicle no"
                                    controlId="vehicleNo"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.vehicleNo}
                                // maxLength={common.maxLengths.customerDisplayName}
                                />
                            </div> */}
                        </div>
                    </form>
                    <div className="invoice-title">
                        <span>Items</span>
                    </div>
                    <div className="table-invoice">
                        <Table
                            columns={columns}
                            data={documentItem}
                        />
                        <div className="add-icon" >
                            <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={isAddNewItem ? this.addNewRow : ''} ></ui5-icon>
                        </div>

                    </div>
                    <div className="row" style={{ margin: '0 5px' }}>
                        <div className='col-sm-6'></div>
                        <div className='col-sm ' style={{ textAlign: 'right', marginRight: '-15px' }}>
                            <div className="row value" style={{ marginBottom: '20px' }}>
                                <div className='col-sm'></div>
                                <div className='col-sm value-title'>
                                    <Label >
                                        Total Value
                                    </Label>
                                </div>
                                <div className='col-sm'>
                                    <FormControl
                                        controlId="totalValue"
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        value={parseFloat(this.state.totalValue).toFixed(2)}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {displayOnly ?
                        <div className="filter-btns" style={{ marginRight: '15px' }}>
                            <div style={{ margin: '5px' }}>
                                <Button
                                    variant="outline-primary"
                                    text='Print'
                                    type="submit"
                                    icon='print'
                                    onClick={() => handlePrintById(stockDocument)}
                                />
                            </div>
                        </div> :
                        <div className="filter-btns" style={{ marginRight: '15px' }}>
                            <div style={{ margin: '5px' }}>
                                <Button
                                    variant="outline-primary"
                                    text='Save'
                                    type="submit"
                                    icon='save'
                                    onClick={() => handleSubmit(this.state)}
                                />
                            </div>
                            <div>
                                <ButtonGroup
                                    secondaryBtnClick={handleClose}
                                    secondaryBtnText='Go Back'
                                    secondaryBtnIcon='undo'
                                    secondaryBtnVariant="outline-primary"
                                    primaryBtnClick={() => handlePrint(this.state)}
                                    primaryBtnIcon='print'
                                    primaryBtnText='Print or preview'
                                    primaryBtnVariant="outline-primary"
                                // primaryBtnDisabled={!isUserAccess}
                                />
                            </div>
                        </div>
                    }
                    {
                        isPrintModalOpen && stockDocumentPrint !== null &&
                        <PdfViewer
                            show={isPrintModalOpen}
                            pdf={stockDocumentPrint}
                            onHide={printModalClose}
                        />
                    }
                </div>
            </div>
        )
    }
}



const mapStateToProps = createStructuredSelector({
    stockDocumentDetails: selectors.makeSelectStockDocumentDetails(),
    stockDocumentTypeList: selectors.makeSelectStockDocumentTypeList(),
    purchasingList: makeSelectPurchasingList(),
    supplierList: makeSelectSupplierList(),
    // jobCardList: makeSelectJobCardList(),
    selectedPurchasing: makeSelectSelectedPurchasingSuccess(),
    partList: makeSelectPartList(),
    uomList: makeSelectUOMList(),
    stockDocument: selectors.makeSelectSelectedStockDocument(),
    stockDocumentPrint: selectors.makeSelectStockDocumentPrintSuccess()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AddGoodsReturns));