import { fromJS } from 'immutable';
import {
  LOAD_BOOKING_LIST_ERROR,
  LOAD_BOOKING_LIST_LOADING,
  LOAD_BOOKING_LIST_SUCCESS,
  SAVE_BOOKING_DETAILS_SUCCESS,
  SAVE_CUSTOMER_MODAL_OPEN_STATUS,
  SAVE_IS_BOOKING_MODAL_OPEN_STATUS, 
  SAVE_IS_NEW_BOOKING_USING_VEHICLE, 
  SAVE_NEW_BOOKING_VEHICLE_DETAILS, 
  SEND_BOOKING_SUCCESS,
  SEND_NEW_CUSTOMER_SUCCESS,
} from '../constant/bookingConstant';

export const initialState = fromJS({
    isAddNewBookingModalOpen:false,
    bookingList:[],
    isBookingListLoading:false,
    bookingListError:'',
    bookingSuccess:'',
    selectedBooking:'',
    bookingVehicleDetails:'',
    isBookingUsingVehicle:false,
    isNewCustomerSuccess: false,
    isAddNewCustomerModalOpen: false
});

function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_BOOKING_MODAL_OPEN_STATUS:
      return state.set('isAddNewBookingModalOpen', action.open)
    case SEND_BOOKING_SUCCESS:
      return state.set('bookingSuccess', action.payload)
    case LOAD_BOOKING_LIST_SUCCESS:
      return state.set('bookingList', action.payload)
    case LOAD_BOOKING_LIST_LOADING:
      return state.set('isBookingListLoading', action.payload)
    case LOAD_BOOKING_LIST_ERROR:
      return state.set('bookingListError', action.payload)
    case SAVE_BOOKING_DETAILS_SUCCESS:
      return state.set('selectedBooking', action.payload)
    case SAVE_IS_NEW_BOOKING_USING_VEHICLE:
      return state.set('isBookingUsingVehicle', action.payload)
    case SAVE_NEW_BOOKING_VEHICLE_DETAILS:
      return state.set('bookingVehicleDetails', action.payload)
    case SEND_NEW_CUSTOMER_SUCCESS:
      return state.set('isNewCustomerSuccess', action.payload)
    case SAVE_CUSTOMER_MODAL_OPEN_STATUS:
      return state.set('isAddNewCustomerModalOpen', action.open)
      default:
      return state;
  }
}

export default bookingReducer;
