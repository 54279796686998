import { fromJS } from 'immutable';
import {
  LOAD_SMS_TEMPLATE_LIST_ERROR,
  LOAD_SMS_TEMPLATE_LIST_LOADING,
  LOAD_SMS_TEMPLATE_LIST_SUCCESS,
  LOAD_SELECTED_SMS_TEMPLATE_SUCCESS,
  SAVE_IS_SMS_TEMPLATE_OPEN_STATUS,
  SEND_SMS_TEMPLATE_SUCCESS,
} from '../constant/smsTempConstant';

export const initialState = fromJS({
    isAddNewSMSTemplateOpen:false,
    smsTemplateList:[],
    isSMSTemplateListLoading: false,
    smsTemplateListError: null,
    smsTemplateSuccess: null,
    selectedSMSTemplate:{}
});

function smsTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_SMS_TEMPLATE_OPEN_STATUS:
      return state.set('isAddNewSMSTemplateOpen', action.open)
    case LOAD_SMS_TEMPLATE_LIST_SUCCESS:
      return state.set('smsTemplateList', action.payload)
    case LOAD_SMS_TEMPLATE_LIST_LOADING:
      return state.set('isSMSTemplateListLoading', action.payload)
    case LOAD_SMS_TEMPLATE_LIST_ERROR:
      return state.set('smsTemplateListError', action.payload)
    case SEND_SMS_TEMPLATE_SUCCESS:
      return state.set('smsTemplateSuccess', action.payload)
    case LOAD_SELECTED_SMS_TEMPLATE_SUCCESS:
      return state.set('selectedSMSTemplate', action.payload)
    default:
      return state;
  }
}

export default smsTemplateReducer;
