import { fromJS } from 'immutable';
import {
  LOAD_ALL_PRODUCTS_SUCCESS,
  LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS,
  LOAD_BALANCE_STOCK_PRINT_SUCCESS,
  // LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
  LOAD_PRODUCT_LIST_ERROR,
  LOAD_PRODUCT_LIST_LOADING,
  LOAD_PRODUCT_LIST_SUCCESS,
  LOAD_PRODUCT_TYPE_LIST_SUCCESS,
  LOAD_SELECTED_PRODUCT_SUCCESS,
  SAVE_IS_PRODUCT_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_PRODUCT_ERROR,
  SEND_ADD_NEW_PRODUCT_LOADING,
  SEND_ADD_NEW_PRODUCT_SUCCESS,
  SEND_DELETE_PRODUCT_ERROR,
  SEND_DELETE_PRODUCT_LOADING,
  SEND_DELETE_PRODUCT_SUCCESS,
  SEND_UPDATE_PRODUCT_ERROR,
  SEND_UPDATE_PRODUCT_LOADING,
  SEND_UPDATE_PRODUCT_SUCCESS,
} from '../constant/productConstant';

export const initialState = fromJS({
  isAddNewProductModalOpen: false,
  isAddNewProductLoading: false,
  addNewProductSuccess: '',
  addNewProductError: '',
  productList: [],
  isProductListLoading: false,
  getProductListError: '',
  // productCategoryList:[],
  objectStatusList: [],
  uomList: [],
  isUpdateProductLoading: false,
  updateProductSuccess: '',
  updateProductError: '',
  isDeleteProductLoading: false,
  deleteProductSuccess: '',
  deleteProductError: '',
  productTypeList: [],
  selectedProduct: {},
  balanceStockPrint: '',
  allProducts: [],
  alternativeProductList: {}
});

function productReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_PRODUCT_MODAL_OPEN_STATUS:
      return state.set('isAddNewProductModalOpen', action.open)
    case SEND_ADD_NEW_PRODUCT_LOADING:
      return state.set('isAddNewProductLoading', action.payload)
    case SEND_ADD_NEW_PRODUCT_SUCCESS:
      return state.set('addNewProductSuccess', action.payload)
    case SEND_ADD_NEW_PRODUCT_ERROR:
      return state.set('addNewProductError', action.payload)
    case LOAD_PRODUCT_LIST_SUCCESS:
      return state.set('productList', action.payload)
    case LOAD_PRODUCT_LIST_LOADING:
      return state.set('isProductListLoading', action.payload)
    case LOAD_PRODUCT_LIST_ERROR:
      return state.set('getProductListError', action.payload)
    // case LOAD_PRODUCT_CATEGORY_LIST_SUCCESS:
    //   return state.set('productCategoryList', action.payload)
    case SEND_UPDATE_PRODUCT_LOADING:
      return state.set('isUpdateProductLoading', action.payload)
    case SEND_UPDATE_PRODUCT_SUCCESS:
      return state.set('updateProductSuccess', action.payload)
    case SEND_UPDATE_PRODUCT_ERROR:
      return state.set('updateProductError', action.payload)
    case SEND_DELETE_PRODUCT_LOADING:
      return state.set('isDeleteProductLoading', action.payload)
    case SEND_DELETE_PRODUCT_SUCCESS:
      return state.set('deleteProductSuccess', action.payload)
    case SEND_DELETE_PRODUCT_ERROR:
      return state.set('deleteProductError', action.payload)
    case LOAD_PRODUCT_TYPE_LIST_SUCCESS:
      return state.set('productTypeList', action.payload)
    case LOAD_SELECTED_PRODUCT_SUCCESS:
      return state.set('selectedProduct', action.payload)
    case LOAD_BALANCE_STOCK_PRINT_SUCCESS:
      return state.set('balanceStockPrint', action.payload)
    case LOAD_ALL_PRODUCTS_SUCCESS:
      return state.set('allProducts', action.payload)
    case LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS:
      return state.set('alternativeProductList', action.payload)
    default:
      return state;
  }
}

export default productReducer;
