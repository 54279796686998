import { createSelector } from 'reselect';

const selectPartDomain = state => state.part;

const makeSelectPartPopupOpen = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('isAddNewPartModalOpen') : false
  );

const makeSelectAddNewPartSuccess = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('addNewPartSuccess') : ''
  );

const makeSelectAddNewPartLoading = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('isAddNewPartLoading') : false
  );

const makeSelectAddNewPartError = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('addNewPartError') : ''
  );

const makeSelectPartList = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('partList') : []
  );

const makeSelectPartListLoading = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('isPartListLoading') : false
  );

const makeSelectPartListError = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('getPartListError') : ''
  );

const makeSelectPartCategoryList = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('partCategoryList') : []
  );

const makeSelectUpdatePartSuccess = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('updatePartSuccess') : ''
  );

const makeSelectUpdatePartLoading = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('isUpdatePartLoading') : false
  );

const makeSelectUpdatePartError = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('updatePartError') : ''
  );

const makeSelectDeletePartSuccess = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('deletePartSuccess') : ''
  );

const makeSelectDeletePartLoading = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('isDeletePartLoading') : false
  );

const makeSelectDeletePartError = () =>
  createSelector(
    selectPartDomain,
    partState => 
      partState ? partState.get('deletePartError') : ''
  );

export {
    makeSelectPartPopupOpen,
    makeSelectAddNewPartSuccess,
    makeSelectAddNewPartLoading,
    makeSelectAddNewPartError,
    makeSelectPartList,
    makeSelectPartListLoading,
    makeSelectPartListError,
    makeSelectPartCategoryList,
    makeSelectUpdatePartSuccess,
    makeSelectUpdatePartLoading,
    makeSelectUpdatePartError,
    makeSelectDeletePartSuccess,
    makeSelectDeletePartLoading,
    makeSelectDeletePartError
}
