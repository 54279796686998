import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getDashboadDetails(){
  return axios.get(baseURL+'dashboards', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function getSalesSummary(){
  return axios.get(baseURL+'dashboards/salesSummary', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function getRatingSummary(){
  return axios.get(baseURL+'rating/ratingSummary', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function getPurchaseOrderSummary(){
  return axios.get(baseURL+'dashboards/purchaseOrderSummary', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}