import { put, call } from 'redux-saga/effects';
import {
  addNewBookingRequest, bookingReminderRequest, deleteBookingRequest, getBookingList, updateBookingRequest,
} from '../service/bookingService';
import {
  addNewCustomerRequest
} from '../service/customerService';
import * as actions from '../../store/action/bookingAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { loadSelectedCustomerSuccess } from '../../store/action/customerAction';
// import { getVehicleList } from '../service/vehicleService';
// import { loadSelectedCustomerRequest } from '../../store/action/customerAction';
// import { loadVehicleListSuccess } from '../../store/action/vehicleAction';


export function* sendAddNewBookingRequest(data){
  // console.log('sendAddNewBookingRequest')
  yield put(actions.loadBookingListError(''))
  try{
    // yield put(actions.loadBookingListLoading(true));
    const response = yield call(addNewBookingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendBookingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewBookingLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewBookingLoading(false));
    // console.log(error.response.data.detail)
    const massage = common.error
    yield put(actions.loadBookingListError(massage))
  }
}

export function* loadBookingListRequest(data){
  // console.log('loadBookingListRequest')
  yield put(actions.loadBookingListError(''))
  try{
    yield put(actions.loadBookingListLoading(true));
    const response = yield call(getBookingList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const bookingList = response.data.result
      yield put(actions.loadBookingListSuccess(bookingList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadBookingListLoading(false));
      const massage = common.error
      yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadBookingListLoading(false));
  }catch(error){
    yield put(actions.loadBookingListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadBookingListError(massage))
  }
}

export function* sendDeleteBookingRequest(data){
  // console.log('sendDeleteBookingRequest')
  yield put(actions.loadBookingListError(''))
  try{
    yield put(actions.loadBookingListLoading(true));
    const response = yield call(deleteBookingRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendBookingSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadBookingListLoading(false));
  }catch(error){
    yield put(actions.loadBookingListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadBookingListError(massage))
  }
}

export function* sendUpdateBookingRequest(data){
  // console.log('sendUpdateBookingRequest')
  yield put(actions.loadBookingListError(''))
  try{
    // yield put(actions.sendUpdateBookingLoading(true));
    const response = yield call(updateBookingRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendBookingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateBookingLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateBookingLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadBookingListError(massage))
  }
}

// export function* loadSearchedVehicleRequest(data){
//   // console.log('loadSearchedVehicleRequest')
//   try{
//     const response = yield call(getVehicleList,data.payload);
//     if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
//       // console.log(response.data.result.result)
//       if(response.data.result.result.length > 0){
//         const customerId = response.data.result.result[0].customerId
//         // console.log(customerId)
//         yield put(loadSelectedCustomerRequest(customerId))
//         yield put(loadVehicleListSuccess(response.data.result))
//       }else{
//         const data = {
//           result :{isSearchedVehicle:false}
//         }
//         yield put(loadVehicleListSuccess(data))
//       }
//     }
//     else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
//       yield put(saveLoginUser(false))
//       invalidToken()
//     } 
//     // else{
//     //   const massage  =common.error
//     //   // console.log(massage)
//     // }
//     if(response && response.data && response.data.result && response.data.result.newAccessToken){
//       refeshTokenset(response.data.result.newAccessToken)
//     }
//   }catch(error){
//     // console.log(error)
//   }
// }


export function* sendBookingReminderRequest(data){
  //console.log('sendServiceReminderRequest')
  yield put(actions.loadBookingListError(''))
  try{
    // yield put(actions.loadBookingListLoading(true));
    const response = yield call(bookingReminderRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      //console.log(response.data.message)
      const massage = common.bookingRemindersMessage
      yield put(actions.sendBookingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 5001 ){
      const massage = common.massages.emailMassage
      yield put(actions.loadBookingListError(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.message
      yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewVehicleLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewVehicleLoading(false));
    //console.log(error.response.data.detail)
    const massage  =common.error
    yield put(actions.loadBookingListError(massage))
  }
}


export function* sendAddNewCustomerRequest(data){
  try{
    const response = yield call(addNewCustomerRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      yield put(actions.sendNewCustomerSuccess(true))
      yield put(loadSelectedCustomerSuccess(response.data.result))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
  }
}
