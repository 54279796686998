import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import common from '../../../common';
import moment from 'moment';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import { makeSelectPartList } from '../../../../store/selector/partSelector';
import { makeSelectUOMList } from '../../../../store/selector/selector';
import { loadSelectedCustomerRequest, loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
import { makeSelectSelectedCustomerSuccess } from '../../../../store/selector/customerSelector';
import PdfViewer from '../../../layout/form/PdfViewer';
// import Images from './Images';
import { makeSelectTenantBasic } from '../../../../store/selector/tenantSelector';
import { saveIsQuotationImageEmailModalOpenStatus } from '../../../../store/action/quotationAction';
import { makeSelectQuotationImageEmailOpenStatus } from '../../../../store/selector/quotationSelector';
import DatePicker from '../../../layout/form/datePicker';
import SelectField from '../../../layout/form/SelectField';
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import Table from '../../../layout/form/Table';


const today = new Date()
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

function generateFileName(list, startNumber) {
    var name_ = `Image${startNumber}`
    for (let i = 0; ;) {
        name_ = `Image${i}`
        // eslint-disable-next-line no-loop-func
        const exist = list.find(e => e.name.split("_").pop() === name_)
        if (exist && exist.name.split("_").pop() === name_) {
            i = i + 1
        }
        else {
            return name_
        }
    }
}

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};


class AddQuotation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: '',
            displayName: '',
            companyName: '',
            email: '',
            address: '',
            date: moment(today).format(common.dateFormat),
            vehicleId: '',
            make: '',
            model: '',
            modelVariant: '',
            vehicleIdentificationNo: '',
            engineNo: '',
            buildDate: '',
            currentMileage: '',
            serviceValue: 0,
            partsValue: 0,
            repairsValue: 0,
            subTotal: 0,
            tax: 0,
            total: 0,
            registrationNo: '',
            taxRate: 0,
            status: '',
            isChangeVehicle: false,
            initial: true,
            Repair: [],
            tempRepairList: [],
            isDeleteRepair: false,
            Parts: [],
            isDeletePart: false,
            tempPartList: [],
            Service: [],
            ServiceScheduleId: '',
            EstimatedQuantity: 0,
            UnitPrice: 0,
            serviceSchUOM: '',
            isDeleteService: false,
            tempServiceList: [],
            isChangeSrvSchedule: false,
            isTemlateListShow: false,
            selectedTemplateId: '',
            isCopyTemplate: false,
            noteToCustomer: '',
            internalNote: '',
            isSetAddress: false,
            ImagesList: [],
            fileList: [],
            isAddImages: false,
            isDeleteImage: false,
            tempImageList: [],
            isSelected: false,
            selectedImageList: [],
            isSendEmailModalOpen: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {

        const { selectedQuotation, isUpdate, selectedRow, tenantFinance } = prevProps;
        var partTotal = 0
        var repairTotal = 0
        var serivceTotal = 0
        var partsList = []
        var repairsList = []
        var serviceList = []
        var customerNote = ''
        var internalNotes = ''
        var imagesList = []
        if (selectedQuotation && selectedQuotation.id && prevState.initial && isUpdate && selectedQuotation.id === selectedRow.id) {

            if (selectedQuotation.parts.length !== 0) {
                for (let i = 0; i < selectedQuotation.parts.length; i++) {
                    const element = selectedQuotation.parts[i];
                    partTotal = partTotal + (element.unitPrice * element.estimatedQuantity)

                    const newRow = {
                        'index': '',
                        'id': element.id,
                        'description': element.description,
                        'operation': "U",
                        'unitOfMeasure': element.unitOfMeasure,
                        "unitPrice": element.unitPrice,
                        "estimatedQuantity": element.estimatedQuantity
                    }
                    partsList.push(newRow)
                }
            }

            if (selectedQuotation.serviceScheduleActivities.length !== 0) {
                serivceTotal = selectedQuotation.serviceScheduleActivities[0].unitPrice * selectedQuotation.serviceScheduleActivities[0].estimatedQuantity
            }

            if (selectedQuotation.repairs.length !== 0) {
                for (let i = 0; i < selectedQuotation.repairs.length; i++) {
                    const element = selectedQuotation.repairs[i];
                    repairTotal = repairTotal + (element.unitPrice * element.estimatedQuantity)

                    const newRow = {
                        'index': '',
                        'id': element.id,
                        'description': element.description,
                        'operation': "U",
                        'unitOfMeasure': element.unitOfMeasure,
                        "unitPrice": element.unitPrice,
                        "estimatedQuantity": element.estimatedQuantity
                    }
                    repairsList.push(newRow)
                }
            }

            if (selectedQuotation.serviceActivities.length !== 0) {
                for (let i = 0; i < selectedQuotation.serviceActivities.length; i++) {
                    const element = selectedQuotation.serviceActivities[i];

                    const newRow = {
                        'index': '',
                        'id': element.id,
                        'operation': "U",
                        'description': element.description,
                        'unitOfMeasure': element.unitOfMeasure,
                    }
                    serviceList.push(newRow)
                }
            }

            if (selectedQuotation.note.length !== 0) {
                customerNote = selectedQuotation.note.find(select => select.noteType === 'c')
                internalNotes = selectedQuotation.note.find(select => select.noteType === 'i')
            }

            if (selectedQuotation.images.length !== 0) {
                for (let i = 0; i < selectedQuotation.images.length; i++) {
                    const element = selectedQuotation.images[i];

                    const newRow = {
                        id: element.id,
                        operation: "U",
                        description: element.description,
                        name: element.name,
                        imageURL: element.imageURL,
                        isChecked: false
                    }
                    imagesList.push(newRow)
                }
            }

            const subTotalValue = partTotal + repairTotal + serivceTotal
            const taxvalue = subTotalValue * (selectedQuotation.taxRate / 100)
            // const totalValue = subTotalValue + taxvalue

            const subTotal_ = Math.round((subTotalValue + Number.EPSILON) * 100) / 100
            const taxValue_ = Math.round((taxvalue + Number.EPSILON) * 100) / 100
            const totalValue = subTotal_ + taxValue_

            return {
                customer: selectedQuotation.customerId,
                displayName: selectedQuotation.customerName,
                companyName: selectedQuotation.companyName,
                email: selectedQuotation.customerEmail,
                address: selectedQuotation.address,
                date: moment(selectedQuotation.quotationDate).format(common.dateFormat),
                vehicleId: selectedQuotation.vehicleId,
                make: selectedQuotation.makeId,
                model: selectedQuotation.modelId,
                modelVariant: selectedQuotation.modelVariant,
                vehicleIdentificationNo: selectedQuotation.identificationNo,
                engineNo: selectedQuotation.engineNo,
                buildDate: moment(selectedQuotation.buildDate).format(common.dateFormat),
                currentMileage: selectedQuotation.currentMileage,
                serviceValue: serivceTotal,
                partsValue: partTotal,
                repairsValue: repairTotal,
                subTotal: subTotal_,
                tax: selectedQuotation.tax,
                total: totalValue,
                Parts: partsList,
                Repair: repairsList,
                Service: serviceList,
                registrationNo: selectedQuotation.registrationNo,
                taxRate: selectedQuotation.taxRate,
                status: selectedQuotation.statusId,
                initial: false,
                internalNote: internalNotes && internalNotes.note ? internalNotes.note : '',
                internalNoteId: internalNotes && internalNotes.id,
                noteToCustomer: customerNote && customerNote.note ? customerNote.note : '',
                noteToCustomerId: customerNote && customerNote.id,
                ServiceScheduleId: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseInt(selectedQuotation.serviceScheduleActivities[0].serviceScheduleId),
                Description: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && selectedQuotation.serviceScheduleActivities[0].description,
                UnitPrice: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseFloat(selectedQuotation.serviceScheduleActivities[0].unitPrice).toFixed(2),
                EstimatedQuantity: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseFloat(selectedQuotation.serviceScheduleActivities[0].estimatedQuantity).toFixed(2),
                serviceSchUOM: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && selectedQuotation.serviceScheduleActivities[0].unitOfMeasure,
                id: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && selectedQuotation.serviceScheduleActivities[0].id,
                ImagesList: imagesList
            }
        }
        else if (!isUpdate && prevState.initial && tenantFinance && tenantFinance.id) {
            return {
                taxRate: tenantFinance.taxRate,
                initial: false
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnBlurChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnBlur = (e) => {
        this.setState({
            [e.target.id]: parseFloat(e.target.value).toFixed(2)
        })

        if (e.target.id === 'UnitPrice') {
            this.setState({
                serviceValue: e.target.value * this.state.EstimatedQuantity
            })
            this.calAmount("service", e.target.value * this.state.EstimatedQuantity)
        }

        if (e.target.id === 'EstimatedQuantity') {
            this.setState({
                serviceValue: e.target.value * this.state.UnitPrice
            })
            this.calAmount("service", e.target.value * this.state.UnitPrice)
        }
    }

    handleSelect = (value, id) => {
        const { customerList, vehicleList } = this.props;

        if (id === "make") {
            // const parameters= {
            //     pageSize:9999,
            //     pageNumber:'',
            //     name:'',
            //     makeId:value,
            // }
            // this.props.dispatch(loadModelListRequest(parameters))
        }

        if (id === "customer") {
            // this.props.dispatch(loadSelectedVehicleSuccess(''))
            // this.props.dispatch(loadModelListSuccess({}))
            this.setState({
                vehicleId: '',
                registrationNo: '',
                model: '',
                make: '',
                modelVariant: '',
                vehicleIdentificationNo: '',
                engineNo: '',
                buildDate: '',
            })
        }

        if (id === "customer" && value !== '' && customerList && customerList.length && customerList.length !== 0) {
            this.props.dispatch(loadSelectedCustomerSuccess({}))
            this.props.dispatch(loadSelectedCustomerRequest(value))
            const desc = customerList.find(select => select.id === value)
            this.setState({
                displayName: desc.displayName,
                email: desc.emailAddress,
                companyName: desc.businessName,
                isSetAddress: true
            })
        }

        if (id === "vehicleId" && value !== '' && vehicleList.result && vehicleList.result.length && vehicleList.result.length !== 0) {
            // this.props.dispatch(loadSelectedVehicleSuccess(''))
            const desc = vehicleList.result.find(select => select.id === value)
            this.setState({
                registrationNo: desc.vehicleRegistrationNumber,
                model: desc.modelId,
                make: desc.makeId
            })
            // const parameters = {
            //     make:desc.makeId,
            //     model:desc.modelId,
            //     description:'',
            //     pageSize:9999,
            //     pageNumber:1
            // }
            // this.props.dispatch(loadSrvScheduleListRequest(parameters))
        }
        this.setState({
            [id]: value
        })
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "date") {
            const setDate = moment(today).format(common.dateFormat)
            this.setState({
                [id]: setDate
            })
        } else if (date === null && id === "buildDate") {
            this.setState({
                [id]: ''
            })
        }
        else {
            const setNewDate = moment(date).format(common.dateFormat)
            this.setState({
                [id]: setNewDate
            })
        }
    }

    getVehicleList = () => {
        // const parameters = {
        //     Customer:this.state.customer,
        //     Make:0,
        //     Model:0,
        //     Search:'',
        //     Status:'',
        //     pageSize:9999,
        //     pageNumber:1,
        //     commonSearch:''
        // }
        // this.props.dispatch(loadVehicleListRequest(parameters))
    }

    getSelectedVehicle = () => {
        // this.props.dispatch(loadSelectedVehicleRequest(this.state.vehicleId))
        this.setState({
            isChangeVehicle: true
        })
    }

    calAmount = (type, typeTotal) => {
        const { repairsValue, partsValue, serviceValue, taxRate, subTotal } = this.state;
        var total = subTotal
        var taxvalue = 0
        var totalValue = 0
        if (type === "repair") {
            total = typeTotal + partsValue + serviceValue

        } else if (type === "part") {
            total = typeTotal + repairsValue + serviceValue
        } else if (type === "service") {
            total = typeTotal + repairsValue + partsValue
        }
        taxvalue = total * (taxRate / 100)
        const total_ = Math.round((total + Number.EPSILON) * 100) / 100
        const taxValue_ = Math.round((taxvalue + Number.EPSILON) * 100) / 100
        totalValue = total_ + taxValue_
        this.setState({
            subTotal: total_,
            tax: taxValue_,
            total: totalValue
        })
    }

    partRowData = (id, rowData) => {
        // console.log(e.target.value, rowData) //TODO
        const { partList } = this.props;
        const { Parts, isPriceInclusiveTax } = this.state;
        const selectedRow = partList.find(row => row.id === id)
        // const selectedRow = Service.indexOf(rowData.index)
        // console.log(selectedRow)
        const newRow = {
            // 'id':selectedRow.partId,
            'index': '',
            'description': selectedRow.description,
            operation: "C",
            'unitOfMeasure': selectedRow.unitOfMeasureId,
            "unitPrice": selectedRow.unitPrice,
            "estimatedQuantity": selectedRow.quantityInStock,
            "value": (selectedRow.unitPrice * selectedRow.quantityInStock),
            "stockFlg": selectedRow.stockFlg,
            "partId": id
        }
        // setTimeout(
        Parts[rowData.index] = newRow
        var partTotal = 0
        for (let i = 0; i < Parts.length; i++) {
            const element = Parts[i];
            partTotal = partTotal + (element.unitPrice * element.estimatedQuantity)
        }
        this.setState({ Parts, partsValue: partTotal })
        this.calAmount("part", partTotal, isPriceInclusiveTax)
        //     500
        // );
    }

    addNewPart = () => {
        var { Parts } = this.state;
        var newRow = { "index": "", "description": "", operation: "C" }
        Parts.push(newRow)
        this.setState({ Parts })
    }

    partDelete = (rowData) => {
        var { Parts } = this.state;
        // const selectedRow = Service.find(row => row.Index === rowData.index+1)
        // console.log(selectedRow)
        const newRow = {
            'index': '',
            'description': rowData.original.description,
            operation: "D",
            'id': rowData.original.id ? rowData.original.id : null,
            'unitOfMeasure': rowData.original.unitOfMeasure,
            "unitPrice": rowData.original.unitPrice,
            "estimatedQuantity": rowData.original.estimatedQuantity,
        }
        Parts[rowData.index] = newRow
        this.setState({ Parts, isDeletePart: true })
    }

    handleRowPartDescription = (e, rowData) => {
        const { Parts, isPriceInclusiveTax } = this.state;
        const newRow = {
            'id': rowData.original.id,
            'index': '',
            'description': e.target.value,
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': rowData.original.unitOfMeasure,
            "unitPrice": rowData.original.unitPrice,
            "estimatedQuantity": rowData.original.estimatedQuantity,
            "value": (rowData.original.unitPrice * rowData.original.estimatedQuantity),
            "stockFlg": rowData.original.stockFlg,
            "partId": rowData.original.partId
        }
        // setTimeout(
        Parts[rowData.index] = newRow
        var partTotal = 0
        for (let i = 0; i < Parts.length; i++) {
            const element = Parts[i];
            partTotal = partTotal + (element.unitPrice * element.estimatedQuantity)
        }
        this.setState({ Parts, partsValue: partTotal })
        this.calAmount("part", partTotal, isPriceInclusiveTax)
        // 500
        // );
    }

    handleRowPartUnitPrice = (e, rowData) => {
        const { Parts, isPriceInclusiveTax } = this.state;
        const newRow = {
            'id': rowData.original.id,
            'index': '',
            'description': rowData.original.description,
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': rowData.original.unitOfMeasure,
            "unitPrice": e.target.value,
            "estimatedQuantity": rowData.original.estimatedQuantity,
            "value": (e.target.value * rowData.original.estimatedQuantity),
            "stockFlg": rowData.original.stockFlg,
            "partId": rowData.original.partId
        }
        // setTimeout(
        Parts[rowData.index] = newRow
        var partTotal = 0
        for (let i = 0; i < Parts.length; i++) {
            const element = Parts[i];
            partTotal = partTotal + (element.unitPrice * element.estimatedQuantity)
        }
        this.setState({ Parts, partsValue: partTotal })
        this.calAmount("part", partTotal, isPriceInclusiveTax)
        // 500
        // );
    }

    handleRowPartQuantity = (e, rowData) => {
        const { Parts, isPriceInclusiveTax } = this.state;
        const newRow = {
            'id': rowData.original.id,
            'index': '',
            'description': rowData.original.description,
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': rowData.original.unitOfMeasure,
            "unitPrice": rowData.original.unitPrice,
            "estimatedQuantity": e.target.value,
            "value": (rowData.original.unitPrice * e.target.value),
            "stockFlg": rowData.original.stockFlg,
            "partId": rowData.original.partId
        }
        // setTimeout(
        Parts[rowData.index] = newRow
        var partTotal = 0
        for (let i = 0; i < Parts.length; i++) {
            const element = Parts[i];
            partTotal = partTotal + (element.unitPrice * element.estimatedQuantity)
        }
        this.setState({ Parts, partsValue: partTotal })
        this.calAmount("part", partTotal, isPriceInclusiveTax)
        // 500
        // );
    }

    handleRowPartUnitOfMeasure = (id, rowData) => {
        const { Parts } = this.state;
        const newRow = {
            'id': rowData.original.id,
            'index': '',
            'description': rowData.original.description,
            operation: rowData.original.id ? "U" : "C",
            'unitOfMeasure': id,
            "unitPrice": rowData.original.unitPrice,
            "estimatedQuantity": rowData.original.estimatedQuantity,
            "value": ((rowData.original.unitPrice && rowData.original.estimatedQuantity) ? (rowData.original.unitPrice * rowData.original.estimatedQuantity) : 0),
            "stockFlg": rowData.original.stockFlg,
            "partId": rowData.original.partId
        }
        // setTimeout(
        Parts[rowData.index] = newRow
        this.setState({ Parts })
        // 500
        // );
    }

    copyFromTemplate = () => {
        this.setState({ isTemlateListShow: true })
        // const parameters= {
        //     pageSize:9999,
        //     pageNumber:'',
        //     Name:'',
        //     TemplateId: '',
        //     status: ''
        //   }
        //   this.props.dispatch(loadJobCardTemplateListRequest(parameters))
    }

    onHideTemplate = () => {
        this.setState({ isTemlateListShow: false, selectedTemplateId: '' })
        // this.props.dispatch(loadJobCardTemplateListSuccess({}))
        // this.props.dispatch(loadSelectedJobCardTemplateSuccess({}))
    }

    selectedTemplate = (rowData) => {
        this.setState({ selectedTemplateId: rowData })
    }

    handleCopiedTemplate = () => {
        this.setState({ isCopyTemplate: true })
        // this.props.dispatch(loadSelectedJobCardTemplateRequest(this.state.selectedTemplateId))
        this.onHideTemplate()
    }

    uploadImage = async (event) => {
        const { ImagesList } = this.state;
        const { isUpdate, selectedQuotation } = this.props;
        const files = event.target.files;
        var list = [];
        if (files && files.length && files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const name = generateFileName(ImagesList.concat(list), 0)
                if (isUpdate) {
                    const file = files[i]
                    const base64 = await convertBase64(file)
                    // const name = generateFileName(ImagesList.concat(list),0)
                    const image = {
                        imageURL: base64,
                        name: "_" + name,
                        description: "",
                        operation: "C",
                        id: 0
                    }
                    list.push(image)
                    // this.setState({ fileList: formData, ImagesList: ImagesList.concat(list), isAddImages:true })
                } else {
                    const file = files[i]
                    const base64 = await convertBase64(file)
                    // const name = generateFileName(ImagesList.concat(list),0)
                    const image = {
                        imageURL: base64,
                        name: "_" + name,
                        description: "",
                        operation: "C",
                        id: 0
                    }
                    list.push(image)
                    // this.setState({ fileList: files, ImagesList: ImagesList.concat(list), isAddImages:true })
                }
                // const name = generateFileName(ImagesList.concat(list),0)
                formData.append(`${selectedQuotation.id}`, "jobCardId")
                formData.append(`${selectedQuotation.id}_${name}`, files[i])

            }
            this.setState({ fileList: (isUpdate ? formData : files), ImagesList: ImagesList.concat(list), isAddImages: true })
        }
    }

    handleRowImageDescription = (e, rowData) => {
        const { ImagesList } = this.state;
        const newRow = {
            name: `_Image${rowData.index}`,
            description: e.target.value,
            imageURL: rowData.original.imageURL,
            operation: (rowData.original.id ? "U" : "C"),
            id: (rowData.original.id ? rowData.original.id : 0),
            isChecked: rowData.original.isChecked
        }
        ImagesList[rowData.index] = newRow
        this.setState({
            ImagesList
        })
    }

    handleDeleteImage = (rowData) => {
        // console.log(rowData)
        const { ImagesList, fileList } = this.state;
        if (rowData.original.id > 0) {
            const newRow = {
                name: rowData.original.name,
                description: rowData.original.description,
                imageURL: rowData.original.imageURL,
                operation: "D",
                id: rowData.original.id,
                isChecked: rowData.original.isChecked
            }
            ImagesList[rowData.index] = newRow
            this.setState({ ImagesList, isDeleteImage: true })
        } else {
            const newRow = {
                name: rowData.original.name,
                description: rowData.original.description,
                imageURL: rowData.original.imageURL,
                operation: "D",
                id: 0,
                isChecked: rowData.original.isChecked
            }
            ImagesList[rowData.index] = newRow
            // const fileListArr = [fileList]
            // fileListArr.splice(rowData.index, 1)
            // const a = fileList[rowData.index]
            // const arr = fileList.filter(item => a.name=== item.name)
            // const files = Array.from(fileList)
            // files.splice(rowData.index, 1)
            // this.setState({ImagesList, isDeleteImage:true,fileList: files})
            // console.log(files, arr)

            const dt = new DataTransfer()
            const input = fileList
            // const { files } = input

            for (let i = 0; i < input.length; i++) {
                const file = input[i]
                if (rowData.index !== i)
                    dt.items.add(file) // here you exclude the file. thus removing it.
            }
            // console.log(dt.files)
            const files = dt.files
            this.setState({ ImagesList, isDeleteImage: true, fileList: files })
        }
    }

    handleRowSelect = (e, rowData) => {
        const { ImagesList } = this.state;
        const newRow = {
            name: rowData.original.name,
            description: rowData.original.description,
            imageURL: rowData.original.imageURL,
            operation: rowData.original.operation,
            id: rowData.original.id,
            isChecked: e.target.checked
        }
        ImagesList[rowData.index] = newRow
        const list = ImagesList.filter(element => element.isChecked === true)
        var isSelected = false;
        if (list && list.length > 0) {
            isSelected = true
        }
        this.setState({
            ImagesList,
            isSelected
        })
    }

    handleDeleteSelected = () => {
        const { ImagesList, fileList, isSelected } = this.state;
        const list = ImagesList.filter(element => element.isChecked === true)
        if (!isSelected) {
            return
        }
        for (let i = 0; i < list.length; i++) {
            const rowData = list[i]
            if (rowData.id > 0) {
                const newRow = {
                    name: rowData.name,
                    description: rowData.description,
                    imageURL: rowData.imageURL,
                    operation: "D",
                    id: rowData.id,
                    isChecked: rowData.isChecked
                }
                const index = ImagesList.map(function (e) { return e.name; }).indexOf(rowData.name);
                ImagesList[index] = newRow
                this.setState({ ImagesList, isDeleteImage: true })
            } else {
                const newRow = {
                    name: rowData.name,
                    description: rowData.description,
                    imageURL: rowData.imageURL,
                    operation: "D",
                    id: 0,
                    isChecked: rowData.isChecked
                }
                const index = ImagesList.map(function (e) { return e.name; }).indexOf(rowData.name);
                ImagesList[index] = newRow

                const dt = new DataTransfer()
                const input = fileList
                // const { files } = input

                for (let i = 0; i < input.length; i++) {
                    const file = input[i]
                    if (index !== i)
                        dt.items.add(file) // here you exclude the file. thus removing it.
                }
                // console.log(dt.files)
                const files = dt.files
                this.setState({ ImagesList, isDeleteImage: true, fileList: files })
            }
        }
    }

    handleSend = () => {
        const { ImagesList, isSelected } = this.state;
        var newImages = ImagesList.filter(element => element.id === 0)
        if (!isSelected || !this.props.isUpdate || (newImages && newImages.length > 0)) {
            return
        }
        const list = ImagesList.filter(element => element.isChecked === true)
        this.props.dispatch(saveIsQuotationImageEmailModalOpenStatus(true))
        this.setState({
            selectedImageList: list
        })
    }

    handleSendEmailModalClose = () => {
        // this.setState({
        //     isSendEmailModalOpen:false,
        //     // selectedImageList:[]
        // })
        this.props.dispatch(saveIsQuotationImageEmailModalOpenStatus(false))
    }

    componentDidMount() {
        // const parameters= {
        //     pageSize:9999,
        //     pageNumber:1,
        //     name:'',
        //     makeId:''
        // }
        // this.props.dispatch(loadMakeListRequest(parameters))
        // this.props.dispatch(loadModelListRequest(parameters))
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            // isDeleteRepair,
            // tempRepairList,
            // Repair,
            isDeletePart,
            Parts,
            tempPartList,
        } = this.state;

        const { selectedCustomer } = this.props;

        if(isDeletePart){
            const partListFilterd = Parts.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = Parts.filter(data => (data.id !== null ))
            var partTotal = 0
            for (let i = 0; i < partListFilterd.length; i++) {
                const element = partListFilterd[i];
                partTotal = partTotal+(element.unitPrice*element.estimatedQuantity)
            }

            this.calAmount("part", partTotal )
            this.setState({
                Parts:partListFilterd, 
                isDeletePart:false, 
                partsValue:partTotal, 
                tempPartList:tempPartList.concat(tempItemList)
            })
        }
        if (selectedCustomer && selectedCustomer.id && this.state.isSetAddress) {
            this.setState({
                address:
                    `${selectedCustomer.billStreetNumber}, ${selectedCustomer.billStreetName}, ${selectedCustomer.billCity}, ${selectedCustomer.billPostalCode}`,
                isSetAddress: false
            })
        }
    }

    render() {
        const {
            handleClose,
            customerList,
            // vehicleList,
            handleSaveAddNew,
            // makeList,
            // modelList,
            // repairList,
            partList,
            uomList,
            // srvScheduleList,
            // jobCardTemplateList,
            handlePrint,
            quotationPrint,
            isPrintModalOpen,
            printModalClose,
            handleSendEmail,
            isUpdate,
            selectedQuotation,
            // tenantDetails,
            // isSendQuotationImageEmailModalOpen
        } = this.props;

        const partColumns = [{
            Header: 'Item No',
            // accessor: 'no',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.no} // TODO
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            // width:1000,
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.description === '' ?
                        <SelectField
                            controlId="description"
                            onChange={(e) => this.partRowData(e, rowData.cell.row)}
                            options={partList}
                            style={customStyles}
                        />
                        :
                        <BootstrapFormControl
                            controlId={rowData.cell.row.original.task} // TODO
                            type="text"
                            defaultValue={rowData.cell.row.original.description}
                            size="sm"
                            onBlur={(e) => this.handleRowPartDescription(e, rowData.cell.row)}
                        />
                    }
                </div>
            ),
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '10%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="unitOfMeasure"
                        onChange={(e) => this.handleRowPartUnitOfMeasure(e, rowData.cell.row)}
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                    // id="partId"
                    />
                </div>
            ),
        }, {
            Header: 'Unit Price',
            // accessor: 'unitPrice',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.task} // TODO
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.unitPrice).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowPartUnitPrice(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Est. Quantity',
            // accessor:'estimatedQuantity',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.task} // TODO
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.estimatedQuantity).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowPartQuantity(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Value',
            // accessor:'estimatedQuantity',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.task} // TODO
                        type="text"
                        value={rowData.cell.row.original.unitPrice && rowData.cell.row.original.estimatedQuantity ? parseFloat(rowData.cell.row.original.estimatedQuantity * rowData.cell.row.original.unitPrice).toFixed(2) : '0.00'}
                        size="sm"
                        disabled
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={() => this.partDelete(rowData.cell.row)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                <div className="filter-header">
                    <h5>
                        Quotation {isUpdate && selectedQuotation && selectedQuotation.id ? " - " + selectedQuotation.documentNumber : ""}
                    </h5>
                    <div className="btn-close" onClick={handleClose} >
                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                </div>
                <form style={{ margin: "15px" }}>
                    <div className="row">
                        <div className="col-sm page_header">
                            <div className="page_header-title">
                                <span>Customer details</span>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <SelectField
                                        text="Customer"
                                        controlId="customer"
                                        options={
                                            customerList && customerList.length && customerList.length > 0 ?
                                                customerList.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                    1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                        selectedValue={this.state.customer}
                                        display='displayName'
                                        onChange={(e) => this.handleSelect(e, 'customer')}
                                        required
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Display Name"
                                        controlId="displayName"
                                        // placeholder="Display Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.displayName}
                                        maxLength={common.maxLengths.customerDisplayName}
                                    // required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Company Name"
                                        controlId="companyName"
                                        // placeholder="Company Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.companyName}
                                        maxLength={common.maxLengths.customerBusinessName}
                                    />
                                </div>
                                <div className="col-sm">
                                    <TextField
                                        text="Email Address"
                                        controlId="email"
                                        // placeholder="Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.email}
                                        maxLength={common.maxLengths.quotaionEmail}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <TextField
                                        text="Address"
                                        controlId="address"
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        as="textarea"
                                        row={50}
                                        style={{ height: 100 }}
                                    />
                                </div>
                                <div className="col-sm">
                                    <DatePicker
                                        text="Date"
                                        controlId="date"
                                        //placeholder="Last Service Date"
                                        onChange={(e) => this.handleDateChange(e, "date")}
                                        value={new Date(this.state.date)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page_header-title">
                        <span>Items</span>
                    </div>
                    <div className="table-content">
                        <Table
                            columns={partColumns}
                            data={this.state.Parts}
                        />
                        <div className="add-icon" >
                            <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={this.addNewPart}></ui5-icon>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm" style={{ margin: '30px' }}></div>
                        <div className="col-sm page_header">
                            <div className="page_header-title">
                                <span>Amounts</span>
                            </div>
                            <div className="row " style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <div className='col-sm'></div>
                                <div className='col-sm value-title'>
                                    <Label >
                                        Sub total
                                    </Label>
                                </div>
                                <div className='col-sm-6'>
                                    <FormControl
                                        controlId="subTotal"
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        value={parseFloat(this.state.subTotal).toFixed(2)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row " style={{ marginBottom: '20px' }}>
                                <div className='col-sm'></div>
                                <div className='col-sm value-title'>
                                    <Label >
                                        Tax {this.state.taxRate}%
                                    </Label>
                                </div>
                                <div className='col-sm-6'>
                                    <FormControl
                                        controlId="tax"
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        value={parseFloat(this.state.tax).toFixed(2)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-sm'></div>
                                <div className='col-sm value-title'>
                                    <Label >
                                        Total
                                    </Label>
                                </div>
                                <div className='col-sm-6'>
                                    <FormControl
                                        controlId="total"
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        value={parseFloat(this.state.total).toFixed(2)}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <div className="filter-btns" style={{ marginRight: '15px' }}>
                    <ButtonGroup
                        primaryBtnClick={() => handleSaveAddNew(this.state)}
                        primaryBtnIcon='save'
                        primaryBtnText='Save'
                        primaryBtnVariant="outline-primary"
                        secondaryBtnClick={() => handleSendEmail(this.state)}
                        secondaryBtnIcon='email'
                        secondaryBtnText='Save and send'
                        secondaryBtnVariant="outline-primary"
                        secondaryBtnDisabled={this.state.email === ''}
                    />
                    <ButtonGroup
                        secondaryBtnClick={handleClose}
                        // secondaryBtnIcon='decline'
                        // secondaryBtnText='Cancel'
                        secondaryBtnText='Go Back'
                        secondaryBtnIcon='undo'
                        secondaryBtnVariant="outline-primary"
                        primaryBtnClick={() => handlePrint(this.state)}
                        primaryBtnIcon='print'
                        primaryBtnText='Print or preview'
                        primaryBtnVariant="outline-primary"
                    />
                </div>
                {
                    isPrintModalOpen && quotationPrint !== '' &&
                    <PdfViewer
                        show={isPrintModalOpen}
                        pdf={quotationPrint}
                        onHide={printModalClose}
                    />
                }
            </div>
        )
    }
}


AddQuotation.propTypes = {
    handleClose: PropTypes.any,
    customerList: PropTypes.any,
    vehicleList: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    makeList: PropTypes.any,
    modelList: PropTypes.any,
    selectedVehicle: PropTypes.any,
    repairList: PropTypes.any,
    partList: PropTypes.any,
    uomList: PropTypes.any,
    tenantFinance: PropTypes.any,
    srvScheduleList: PropTypes.any,
    jobCardTemplateList: PropTypes.any,
    selectedJobCardTemplate: PropTypes.any,
    selectedSrvSchedule: PropTypes.any,
    selectedCustomer: PropTypes.any,
    handlePrint: PropTypes.any,
    quotationPrint: PropTypes.any,
    printModalClose: PropTypes.any,
    handleSendEmail: PropTypes.any,
    isUpdate: PropTypes.bool,
    selectedQuotation: PropTypes.any,
}


const mapStateToProps = createStructuredSelector({
    // vehicleList: makeSelectVehicleList(),
    // makeList: makeSelectMakeList(),
    // modelList: makeSelectModelList(),
    // selectedVehicle: makeSelectSelectedVehicleSuccess(),
    // repairList: makeSelectRepairList(),
    partList: makeSelectPartList(),
    uomList: makeSelectUOMList(),
    // srvScheduleList: makeSelectSrvScheduleList(),
    // jobCardTemplateList: makeSelectJobCardTemplateList(),
    // selectedJobCardTemplate: makeSelectSelectedJobCardTemplateSuccess(),
    // selectedSrvSchedule: makeSelectSelectedSrvSchedule(),
    selectedCustomer: makeSelectSelectedCustomerSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    isSendQuotationImageEmailModalOpen: makeSelectQuotationImageEmailOpenStatus(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(AddQuotation));