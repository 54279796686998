import { fromJS } from 'immutable';
import {
  LOAD_A2A_EMPLOYEE_SUCCESS,
  LOAD_A2A_MAPPING_ERROR,
  LOAD_A2A_MAPPING_LOADING,
  LOAD_A2A_MAPPING_SUCCESS,
  LOAD_A2A_PAY_TYPE_SUCCESS,
  LOAD_ACCOUNT_COMPANY_FILE_SUCCESS,
  LOAD_ACCOUNT_EMPLOYEE_SUCCESS,
  LOAD_ACCOUNT_PAY_TYPE_SUCCESS,
  LOAD_ACCOUNT_TAX_CODE_SUCCESS,
  LOAD_INVOICE_RECONCILIATION_REPORT_SUCCESS,
  SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS,
  SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS,
} from '../constant/a2aMappingConstant';

export const initialState = fromJS({
  accountEmployeeList: [],
  mappingEmployeeList: [],
  isA2AMappingLoading: false,
  a2aMappingError: null,
  a2aMappingSuccess: null,
  isAccountEmployeeList: false,
  accountPayTypeList: [],
  mappingPayTypeList: [],
  isAccountPayTypeList: false,
  accountCompanyFileList: [],
  accountTaxCodeList: [],
  invoiceReconciliationReport: []
});

function a2aMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_A2A_MAPPING_LOADING:
      return state.set('isA2AMappingLoading', action.payload)
    case LOAD_A2A_MAPPING_ERROR:
      return state.set('a2aMappingError', action.payload)
    case LOAD_A2A_MAPPING_SUCCESS:
      return state.set('a2aMappingSuccess', action.payload)
    case LOAD_ACCOUNT_EMPLOYEE_SUCCESS:
      return state.set('accountEmployeeList', action.payload)
    case LOAD_A2A_EMPLOYEE_SUCCESS:
      return state.set('mappingEmployeeList', action.payload)
    case SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS:
      return state.set('isAccountEmployeeList', action.payload)
    case LOAD_ACCOUNT_PAY_TYPE_SUCCESS:
      return state.set('accountPayTypeList', action.payload)
    case LOAD_A2A_PAY_TYPE_SUCCESS:
      return state.set('mappingPayTypeList', action.payload)
    case SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS:
      return state.set('isAccountPayTypeList', action.payload)
    case LOAD_ACCOUNT_COMPANY_FILE_SUCCESS:
      return state.set('accountCompanyFileList', action.payload)
    case LOAD_ACCOUNT_TAX_CODE_SUCCESS:
      return state.set('accountTaxCodeList', action.payload)
    case LOAD_INVOICE_RECONCILIATION_REPORT_SUCCESS:
      return state.set('invoiceReconciliationReport', action.payload)
    default:
      return state;
  }
}

export default a2aMappingReducer;
