import React from "react";
import moment from 'moment';
import '../../layout/form/form.css'
import ButtonGroup from '../../layout/form/ButtonGroup';
import common from '../../common';
import VemasLogo from '../../../images/VEMAS.png';

function Expired({
    handleWarningModalClose,
    handleChangeStep,
    trialExpiryDate,
    trailPeriod
}) {
    return (
        <div>
            <div className="vemas_logo">
                <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
            </div>
            <div style={{ margin: '0 5% 2% 5%' }}>
                <div style={{ textAlign: 'center', fontWeight: 600, borderBottom: '2px solid #d8a61e', paddingBottom: '5px', marginBottom: '40px',fontSize:"18px", color: 'red' }}>We are sorry, Your trial period has expired.</div>
                <div>
                    Your {trailPeriod} days trial period has expired on {moment(trialExpiryDate).format(common.dateFormat)}. We hope that you have explored how VEMAS can help you run your business simple. If you wish to continue using VEMAS, you need to activate your account.
                </div>
                <div className="filter-btns" style={{ margin: '20px 0' }}>
                    <ButtonGroup
                        primaryBtnClick={() => handleWarningModalClose()}
                        primaryBtnVariant="outline-primary"
                        primaryBtnText='Cancel'
                        secondaryBtnClick={() => handleChangeStep("secondStep")}
                        secondaryBtnText='Active'
                        secondaryBtnVariant="outline-primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default Expired;