import { createSelector } from 'reselect';

const selectProductDomain = state => state.product;

const makeSelectProductPopupOpen = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('isAddNewProductModalOpen') : false
  );

const makeSelectAddNewProductSuccess = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('addNewProductSuccess') : ''
  );

const makeSelectAddNewProductLoading = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('isAddNewProductLoading') : false
  );

const makeSelectAddNewProductError = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('addNewProductError') : ''
  );

const makeSelectProductList = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('productList') : []
  );

const makeSelectProductListLoading = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('isProductListLoading') : false
  );

const makeSelectProductListError = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('getProductListError') : ''
  );

// const makeSelectProductCategoryList = () =>
//   createSelector(
//     selectProductDomain,
//     productState => 
//       productState ? productState.get('productCategoryList') : []
//   );

const makeSelectUpdateProductSuccess = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('updateProductSuccess') : ''
  );

const makeSelectUpdateProductLoading = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('isUpdateProductLoading') : false
  );

const makeSelectUpdateProductError = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('updateProductError') : ''
  );

const makeSelectDeleteProductSuccess = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('deleteProductSuccess') : ''
  );

const makeSelectDeleteProductLoading = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('isDeleteProductLoading') : false
  );

const makeSelectDeleteProductError = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('deleteProductError') : ''
  );

const makeSelectProductTypeList = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('productTypeList') : []
  );

const makeSelectSelectedProduct = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('selectedProduct') : {}
  );

const makeSelectBalanceStockPrintSuccess = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('balanceStockPrint') : ''
  );

const makeSelectAllProducts = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('allProducts') : []
  );

const makeSelectAlternativeProductList = () =>
  createSelector(
    selectProductDomain,
    productState =>
      productState ? productState.get('alternativeProductList') : {}
  );

export {
  makeSelectProductPopupOpen,
  makeSelectAddNewProductSuccess,
  makeSelectAddNewProductLoading,
  makeSelectAddNewProductError,
  makeSelectProductList,
  makeSelectProductListLoading,
  makeSelectProductListError,
  // makeSelectProductCategoryList,
  makeSelectUpdateProductSuccess,
  makeSelectUpdateProductLoading,
  makeSelectUpdateProductError,
  makeSelectDeleteProductSuccess,
  makeSelectDeleteProductLoading,
  makeSelectDeleteProductError,
  makeSelectProductTypeList,
  makeSelectSelectedProduct,
  makeSelectBalanceStockPrintSuccess,
  makeSelectAllProducts,
  makeSelectAlternativeProductList
}
