import { combineReducers } from 'redux'
import customerReducer from './customerReducer'
import titleReducer from './titleReducer'
import authReducer from './authReducer'
import appReducer from './appReducer'
import invoiceReducer from './invoiceReducer'
import tenantReducer from './tenantReducer'
import bookingReducer from './bookingReducer'
import dashboadReducer from './dashboardReducer'
import quotationReducer from './quotationReducer'
import supplierReducer from './supplierReducer'
import purchasingReducer from './purchasingReducer'
import usersReducer from './usersReducer'
import navBarReducer from './navBarReducer'
import itemReducer from './itemReducer'
import stockDocumentReducer from './stockDocumentReducer'
import emailTemplateReducer from './emailTempReducer'
import smsTemplateReducer from './smsTempReducer'
import notificationReducer from './notificationReducer'
import productReducer from './productReducer'
import reportReducer from './reportReducer'
import accountReducer from './accountReducer'
import accountProductCategoryReducer from './accountProductCategoryReducer'
import a2aMappingReducer from './a2aMappingReducer'
import partReducer from './partReducer'
import productCategoryReducer from './productCategoryReducer'
import reminderReducer from './reminderReducer'
import analyticsReducer from './analyticsReducer'
import userRoleReducer from './userRoleReducer'
import appBillingReducer from './appBillingReducer'

const rootReducer = combineReducers({
    app : appReducer,
    customer: customerReducer,
    title: titleReducer,
    auth: authReducer,
    invoice: invoiceReducer,
    tenant: tenantReducer,
    booking: bookingReducer,
    dashboard: dashboadReducer,
    quotation: quotationReducer,
    supplier: supplierReducer,
    purchasing: purchasingReducer,
    users: usersReducer,
    navBar: navBarReducer,
    item: itemReducer,
    stockDocument : stockDocumentReducer,
    emailTemplate: emailTemplateReducer,
    smsTemplate: smsTemplateReducer,
    notification: notificationReducer,
    product: productReducer,
    part: partReducer,
    reports: reportReducer,
    accounting: accountReducer,
    accountProductCategory: accountProductCategoryReducer,
    a2aMapping: a2aMappingReducer,
    productCategory: productCategoryReducer,
    reminder: reminderReducer,
    analytics: analyticsReducer,
    userRoles: userRoleReducer,
    appBilling: appBillingReducer,
});

export default rootReducer