import React from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import './form.css'
import Button from './Button';

const Welcome = ({
    show,
    handleModalClose,
    title
}) => (
      <BootstrapModal 
        show={show} 
        onHide={handleModalClose}
        animation={false}
        // dialogClassName="modal_width"
        centered
        >
        <BootstrapModal.Header>
            <BootstrapModal.Title style={{color:'#4278b7', fontSize:'1.1rem'}}>
                Welcome to VEMAS
            </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body style={{color:'#4278b7'}}> 
            <div style={{fontWeight:500}}>{title ? title : ''}</div>
            <div style={{textAlign:'center'}}>
                <div>You have to enter all required data for the successful operation of the system</div>
            </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
            <Button 
                variant="outline-primary" 
                text ='Close'
                type="submit"
                onClick={handleModalClose}
            />
        </BootstrapModal.Footer>
      </BootstrapModal>
);

Welcome.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleWarning: PropTypes.any,
    handleWarningModalClose:PropTypes.any,
    title: PropTypes.any,
}

export default Welcome;