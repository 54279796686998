import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addNewUserRequest(data) {
  const parameters = JSON.stringify(data)
  //console.log(sessionStorage.getItem("accessToken"))
  return axios.post(baseURL + 'auth/CreateCognitoUser', parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}


export function getUserListRequest(parameters) {
  //console.log(sessionStorage.getItem("accessToken"))
  return axios.get(baseURL + 'users?UserId=' + parameters.userId + '&Name=' + parameters.name + '&Email=' + parameters.email + '&Phone=' + parameters.phoneNumber + '&StatusId=' + parameters.status, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updateUserStatusRequest(data) {
  const parameters = JSON.stringify(data)
  //console.log(sessionStorage.getItem("accessToken"))
  return axios.patch(baseURL + 'users/changeStatus/' + data.id, parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function updateUserRequest(data) {
  const parameters = JSON.stringify(data)
  //console.log(sessionStorage.getItem("accessToken"))
  return axios.patch(baseURL + 'users/' + data.id, parameters, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function getSelectedUserRequest(id) {
  //console.log(sessionStorage.getItem("accessToken"))
  return axios.get(baseURL + 'users/' + id, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}

export function getUserAccessRequest() {
  return axios.get(baseURL + 'users/userAccess', {
    headers: {
      'Content-Type': 'application/json',
      'authorization': sessionStorage.getItem("accessToken")
    }
  })
}