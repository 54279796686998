import { put, call } from 'redux-saga/effects';
import {
  getAppInvoiceList,
  getAppPaymentMethodRequest,
  getAppServiceTenant, getPaymentSetupRequest, getSelectedAppInvoiceRequest, printAppInvoiceRequest, updateBillingRequest, updateDefaultPaymentMethodRequest, updatePaymentMethodDetailsRequest, updatePaymentMethodRequest,
  updateTenantPackage
} from '../service/appBillingService';
import * as actions from '../../store/action/appBillingAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadApptenantServiceRequest(data) {
  // console.log('loadRatingDetailsRequest')
  yield put(actions.loadAppBillingError(''))
  try {
    yield put(actions.loadAppBillingLoading(true));
    const response = yield call(getAppServiceTenant, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const RatingList = response.data.result.result
      yield put(actions.loadAppServiceTenantSuccess(RatingList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAppBillingLoading(false));
      const massage = common.error
      yield put(actions.loadAppBillingError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false));
  } catch (error) {
    yield put(actions.loadAppBillingLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAppBillingError(massage))
  }
}


export function* sendUpdateBillingRequest(data) {
  yield put(actions.loadAppBillingError(''))
  try {
    const response = yield call(updateBillingRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendAppBillingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = "Your payment details is incorrect"
      yield put(actions.loadAppBillingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadAppBillingError(massage))
  }
}


export function* loadPaymentSetupRequest(data) {
  yield put(actions.loadAppBillingError(''))
  try {
    yield put(actions.loadAppBillingLoading(true));
    const response = yield call(getPaymentSetupRequest);
    if (response && response.status && response.status === 200) {
      const RatingList = response.data
      yield put(actions.loadPaymentSetupSuccess(RatingList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAppBillingLoading(false));
      const massage = common.error
      yield put(actions.loadAppBillingError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false));
  } catch (error) {
    yield put(actions.loadAppBillingLoading(false));
    const massage = common.error
    yield put(actions.loadAppBillingError(massage))
  }
}


export function* sendUpdatePaymentMethodRequest(data) {
  yield put(actions.loadAppBillingLoading(true));
  yield put(actions.loadAppBillingError(''))
  try {
    const response = yield call(updatePaymentMethodRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendAppBillingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = "Your payment details is incorrect"
      yield put(actions.loadAppBillingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false));
  } catch (error) {
    const massage = common.error
    yield put(actions.loadAppBillingLoading(false));
    yield put(actions.loadAppBillingError(massage))
  }
}


export function* loadAppInvoiceListRequest(data) {
  yield put(actions.loadAppInvoiceListError(''))
  try {
    yield put(actions.loadAppInvoiceListLoading(true));
    const response = yield call(getAppInvoiceList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result.result)
      const invoiceList = response.data.result
      yield put(actions.loadAppInvoiceListSuccess(invoiceList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadAppInvoiceListLoading(false));
      const massage = common.error
      yield put(actions.loadAppInvoiceListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAppInvoiceListLoading(false));
  } catch (error) {
    yield put(actions.loadAppInvoiceListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAppInvoiceListError(massage))
  }
}


export function* loadSelectedAppInvoiceRequest(data) {
  // console.log('loadSelectedAppInvoiceRequest')
  yield put(actions.loadAppInvoiceListError(''))
  try {
    yield put(actions.loadAppInvoiceListLoading(true));
    const response = yield call(getSelectedAppInvoiceRequest, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const invoice = response.data
      yield put(actions.loadSelectedAppInvoiceSuccess(invoice))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadAppInvoiceListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppInvoiceListLoading(false));
  } catch (error) {
    yield put(actions.loadAppInvoiceListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAppInvoiceListError(massage))
  }
}


export function* loadPrintbyIdRequest(data) {
  // console.log('loadPrintbyIdRequest')
  yield put(actions.loadAppInvoiceListError(''))
  try {
    yield put(actions.loadAppInvoiceListLoading(true));
    const response = yield call(printAppInvoiceRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      // console.log(response.data.result)
      const pdf = response.data.result.byteCode
      yield put(actions.loadAppInvoicePrintSuccess(pdf))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadAppInvoiceListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAppInvoiceListLoading(false));
  } catch (error) {
    yield put(actions.loadAppInvoiceListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadAppInvoiceListError(massage))
  }
}


export function* loadAppPaymentMethodRequest() {
  try {
    const response = yield call(getAppPaymentMethodRequest);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.loadAppPaymentMethodSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
  }
}


export function* sendDefaultPaymentMethodRequest(data){
  try{
    yield put(actions.loadAppBillingLoading(true))
    const response = yield call(updateDefaultPaymentMethodRequest,data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendAppBillingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false))
  }catch(error){
  }
}

export function* sendUpdatePaymentMethodDetailsRequest(data){
  try{
    yield put(actions.loadAppBillingLoading(true))
    const response = yield call(updatePaymentMethodDetailsRequest,data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendAppBillingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false))
  }catch(error){
  }
}


export function* sendUpdatePackageRequest(data){
  try{
    yield put(actions.loadAppBillingLoading(true))
    const response = yield call(updateTenantPackage,data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.sendAppBillingSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadAppBillingLoading(false))
  }catch(error){
    yield put(actions.loadAppBillingLoading(false))
  }
}
