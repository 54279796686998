import { fromJS } from 'immutable';
import {
  LOAD_PURCHASING_LIST_ERROR,
  LOAD_PURCHASING_LIST_LOADING,
  LOAD_PURCHASING_LIST_SUCCESS,
  LOAD_PURCHASING_PRINT_SUCCESS,
  LOAD_SELECTED_PURCHASING_SUCCESS,
  SAVE_IS_PURCHASING_MODAL_OPEN_STATUS,
  SEND_PURCHASING_SUCCESS,
} from '../constant/purchasingConstant';

export const initialState = fromJS({
    isAddNewPurchasingModalOpen:false,
    purchasingList:[],
    isPurchasingListLoading:false,
    purchasingListError:'',
    purchasingSuccess:'',
    purchasingPrint:'',
    selectedPurchasing:{},
});

function purchasingReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_PURCHASING_MODAL_OPEN_STATUS:
      return state.set('isAddNewPurchasingModalOpen', action.open)
    case LOAD_PURCHASING_LIST_SUCCESS:
      return state.set('purchasingList', action.payload)
    case LOAD_PURCHASING_LIST_LOADING:
      return state.set('isPurchasingListLoading', action.payload)
    case LOAD_PURCHASING_LIST_ERROR:
      return state.set('purchasingListError', action.payload)
    case SEND_PURCHASING_SUCCESS:
      return state.set('purchasingSuccess', action.payload)
    case LOAD_PURCHASING_PRINT_SUCCESS:
      return state.set('purchasingPrint', action.payload)
    case LOAD_SELECTED_PURCHASING_SUCCESS:
      return state.set('selectedPurchasing', action.payload)
    default:
      return state;
  }
}

export default purchasingReducer;
