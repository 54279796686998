const PATH = 'app/Purchasing';

export const SAVE_IS_PURCHASING_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_PURCHASING_MODAL_OPEN_STATUS`;
export const LOAD_PURCHASING_LIST_REQUEST = `${PATH}LOAD_PURCHASING_LIST_REQUEST`;
export const LOAD_PURCHASING_LIST_ERROR = `${PATH}LOAD_PURCHASING_LIST_ERROR`;
export const LOAD_PURCHASING_LIST_LOADING = `${PATH}LOAD_PURCHASING_LIST_LOADING`;
export const LOAD_PURCHASING_LIST_SUCCESS = `${PATH}LOAD_PURCHASING_LIST_SUCCESS`;
export const SEND_ADD_NEW_PURCHASING_REQUEST = `${PATH}SEND_ADD_NEW_PURCHASING_REQUEST`;
export const SEND_PURCHASING_SUCCESS = `${PATH}SEND_PURCHASING_SUCCESS`;
export const SEND_UPDATE_PURCHASING_REQUEST = `${PATH}SEND_UPDATE_PURCHASING_REQUEST`;
export const SEND_DELETE_PURCHASING_REQUEST = `${PATH}SEND_DELETE_PURCHASING_REQUEST`;
export const SEND_PURCHASING_PRINT_REQUEST = `${PATH}SEND_PURCHASING_PRINT_REQUEST`;
export const LOAD_PURCHASING_PRINT_SUCCESS = `${PATH}LOAD_PURCHASING_PRINT_SUCCESS`;
export const LOAD_SELECTED_PURCHASING_REQUEST = `${PATH}LOAD_SELECTED_PURCHASING_REQUEST`;
export const LOAD_SELECTED_PURCHASING_SUCCESS = `${PATH}LOAD_SELECTED_PURCHASING_SUCCESS`;
export const SEND_PURCHASING_ADD_PRINT_REQUEST = `${PATH}SEND_PURCHASING_ADD_PRINT_REQUEST`;
export const SEND_PURCHASING_ADD_SEND_REQUEST = `${PATH}SEND_PURCHASING_ADD_SEND_REQUEST`;
export const SEND_PURCHASING_SEND_REQUEST = `${PATH}SEND_PURCHASING_SEND_REQUEST`;
export const LOAD_PURCHASING_SEND_SUCCESS = `${PATH}LOAD_PURCHASING_SEND_SUCCESS`;
export const LOAD_PURCHASING_PRINT_BY_ID_REQUEST = `${PATH}LOAD_PURCHASING_PRINT_BY_ID_REQUEST`;
export const LOAD_PURCHASING_SEND_BY_ID_REQUEST = `${PATH}LOAD_PURCHASING_SEND_BY_ID_REQUEST`;
export const SEND_STOCK_DOCUMENT_REQUEST = `${PATH}SEND_STOCK_DOCUMENT_REQUEST`;