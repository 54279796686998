import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import * as actions from '../../../../store/action/bookingAction';
import * as selectors from '../../../../store/selector/bookingSelector';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../App.css';
import common from '../../../common';
import Loader from '../../../layout/form/Loader';
import Button from '../../../layout/form/Button';
import AddBooking from './AddNewBooking';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import AddNewCustomer from '../../sales/customer/AddNewCustomer';
// import { makeSelectVehicleList } from '../../../store/selector/vehicleSelector';
import { makeSelectCustomerList, makeSelectSelectedCustomerSuccess } from '../../../../store/selector/customerSelector';
import { loadCustomerListRequest, loadCustomerListSuccess, loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import { makeSelectCountryList, makeSelectObjectStatusList } from '../../../../store/selector/selector';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';
import { loadTitleListRequest } from '../../../../store/action/titleAction';
import { makeSelectTitleList } from '../../../../store/selector/titleSelector';

moment.locale('en-GB');
// Calendar.momentLocalizer(moment);
const localizer = momentLocalizer(moment)
const currentDate = new Date() 
const prevDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),1)
var nextDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)+1),1)
nextDate.setDate(nextDate.getDate() - 1)
class GraphicalCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMaintenancePageShow:true,
            initial:true,
            events :[],
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(nextDate).format(common.dateFormat),
            vehicleRegistrationNo:'',
            customer:'',
            pageNumber:1,
            pageSize:9999,
            resultMonth:currentDate.getMonth(currentDate)+1,
            resultDate:currentDate.getDate(currentDate),
            resultWeek:moment(currentDate).startOf('week').toDate(),
            calendarView: common.graphicalCalender.monthView,
            selectedDate:currentDate,
            isChangeStep:false,
            selectedRow:{},
            isUpdate:false,
            isValidationError:false,
            errorMassage:'',
            isDeleteModalOpen:false,
            slots:'',
            isSelectedSlot:true,
            isNewCustomer: false
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { bookingList } = prevProps;
        const { initial,resultMonth,calendarView } = prevState;
        if(bookingList && bookingList.result && bookingList.result.length && bookingList.result.length > 0  && initial){
            const newResultDate = new Date(bookingList.result[0].requestedDate)
            const newResultMonth = newResultDate.getMonth(newResultDate)+1
            var list =[]
            if(calendarView === common.graphicalCalender.monthView && newResultMonth === resultMonth){
                for (let i = 0; i < bookingList.result.length; i++) {
                    const element = bookingList.result[i]
                    const title = element.customerName
                    const event = {
                        id: element.id,
                        start: element.requestedDate,
                        end: element.requestedDate,
                        resourceId: element.id,
                        title: title,
                        bgColor: element.status === '50'? common.graphicalCalender.unConfirmColor : common.graphicalCalender.confirmedColor,
                        vehicleRegistrationNumber:element.vehicleRegistrationNumber,
                        customerName:element.customerName,
                        customerEmail:element.customerEmail,
                        customerPhone:element.customerPhone,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        customerId:element.customerId,
                        vehicleId:element.vehicleId,
                        jobcardId:element.jobcardId,
                        status:element.status
                    }
                    list.push(event)
                }
                return {
                    initial:false,
                    events:list,
                    isChangeStep:false
                }
            }
        }
        return null
    }

    getBookingList = () => {
        const startDay = new Date(this.state.fromDate)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(this.state.toDate)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),   
            toDate: moment(endDateTime).format(common.dateTimeFormat),
            regNo: this.state.vehicleRegistrationNo,
            customerName:this.state.customer,
        }
        this.setState({
            isChangeStep:true
        })
        this.props.dispatch(actions.loadBookingListRequest(parameters))
    }

    getSelectedBookingList = (day1 , day2) => {
        const startDay = new Date(day1)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(day2)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),   
            toDate: moment(endDateTime).format(common.dateTimeFormat),
            regNo: this.state.vehicleRegistrationNo,
            customerName:this.state.customer,
        }
        this.props.dispatch(actions.loadBookingListRequest(parameters))
    }

    onNavigate = (setdate)  => {
        const { calendarView } = this.state;
        this.props.dispatch(actions.loadBookingListSuccess([]))
        if(calendarView === common.graphicalCalender.monthView){
            const date = new Date(setdate)
            const day1 = new Date(date.getFullYear(date),(date.getMonth(date)),1)
            var day2 = new Date(date.getFullYear(date),(date.getMonth(date)+1),1)
            day2.setDate(day2.getDate() - 1)
            this.setState({
                isChangeStep:true,
                resultMonth:date.getMonth(date)+1,
                selectedDate:date,
                events:[],
                fromDate: moment(day1).format(common.dateFormat),
                toDate: moment(day2).format(common.dateFormat),
            })
            this.getSelectedBookingList(day1 , day2)
        }else if(calendarView === common.graphicalCalender.weekView){
            const date = new Date(setdate)
            const startOfWeek = moment(setdate).startOf('week').toDate();
            const endOfWeek   = moment(setdate).endOf('week').toDate();
            this.setState({
                isChangeStep:true,
                resultMonth:date.getMonth(date)+1,
                selectedDate:date,
                resultWeek:moment(startOfWeek).startOf('week').toDate(),
                events:[],
                fromDate: moment(startOfWeek).format(common.dateFormat),
                toDate: moment(endOfWeek).format(common.dateFormat),
            })
            this.getSelectedBookingList(startOfWeek , endOfWeek)
        }else if(calendarView === common.graphicalCalender.dayView){
            const date = new Date(setdate)
            const startOfDay = moment(setdate).startOf('day').toDate();
            const endOfDay   = moment(setdate).endOf('day').toDate();
            this.setState({
                isChangeStep:true,
                selectedDate:date,
                resultDate:moment(startOfDay).startOf('day').toDate(),
                events:[],
                fromDate: moment(startOfDay).format(common.dateFormat),
                toDate: moment(endOfDay).format(common.dateFormat),
            })
            this.getSelectedBookingList(startOfDay , endOfDay)
        }
        
    }

    onView = (view)  =>{
        const { selectedDate } =this.state;
        this.props.dispatch(actions.loadBookingListSuccess([]))
        this.setState({
            calendarView:view,
            events:[]
        })

        if(view === common.graphicalCalender.monthView){
            const day1 = new Date(selectedDate.getFullYear(selectedDate),(selectedDate.getMonth(selectedDate)),1)
            var day2 = new Date(selectedDate.getFullYear(selectedDate),(selectedDate.getMonth(selectedDate)+1),1)
            day2.setDate(day2.getDate() - 1)
            this.getSelectedBookingList(day1 , day2)
            this.setState({
                isChangeStep:true,
                fromDate: moment(day1).format(common.dateFormat),
                toDate: moment(day2).format(common.dateFormat),
            })
        }
        else if(view === common.graphicalCalender.weekView){
            // this.props.dispatch(actions.loadBookingListSuccess([]))
            const startOfWeek = moment(selectedDate).startOf('week').toDate();
            const endOfWeek   = moment(selectedDate).endOf('week').toDate();
            this.getSelectedBookingList(startOfWeek , endOfWeek)
            this.setState({
                isChangeStep:true,
                resultWeek :startOfWeek,
                fromDate: moment(startOfWeek).format(common.dateFormat),
                toDate: moment(endOfWeek).format(common.dateFormat),
            })
            
        }else if(view === common.graphicalCalender.dayView){
            const startOfDay = moment(selectedDate).startOf('day').toDate();
            const endOfDay   = moment(selectedDate).endOf('day').toDate();
            this.getSelectedBookingList(startOfDay , endOfDay)
            this.setState({
                isChangeStep:true,
                resultDate :startOfDay,
                fromDate: moment(startOfDay).format(common.dateFormat),
                toDate: moment(endOfDay).format(common.dateFormat),
            })
        }
        
    }

    // onSelectEvent =(event) => {
    //     console.log(event)
    // }

    eventStyleGetter = (event, start, end, isSelected) =>{
        // console.log(event);
        var backgroundColor = event.bgColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: '#ffffff',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    handleModal = (data) => {
        if(data && data.id){
            // this.props.dispatch(actions.loadSelectedBookingRequest(data.id))
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsBookingModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({
            selectedRow:{}, 
            isUpdate:false,
            isSelectedSlot:false,
            slots:'',
            isNewCustomer: false
        })
        this.props.dispatch(actions.saveIsBookingModalOpenStatus(false));
        this.props.dispatch(loadSelectedCustomerSuccess(''));
        this.props.dispatch(actions.saveIsBookingUsingVehicle(false));
        this.props.dispatch(actions.saveBookingVehicleDetails(''));
        // this.props.dispatch(actions.loadSelectedBookingSuccess({}))
    }

    handleSaveAddNew = (details) => {
        const { selectedRow, isUpdate } = this.state;
        if(details.date === '' ||
            details.customer === '' ){
                this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
                return
        }
        const isChangeDate = (moment(details.date).format(common.dateFormat) === moment(selectedRow.requestedDate).format(common.dateFormat))
        // const setDate =
        const date = moment(details.date).format(common.dateFormat);
        // const time_ = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),(currentDate.getDate(currentDate)),8,30,0)
        const time = moment(details.date).format(common.timeFormat);
        const setDate = date+"T"+time
        const parameters = {
            id:(isUpdate ? selectedRow.id : 0),
            VehicleId:(isUpdate ? selectedRow.vehicleId : details.vehicleId ? details.vehicleId: 0),
            RequestedDate:setDate,
            CustomerId: (isUpdate ? selectedRow.customerId : details.customerId ? details.customerId : 0),
            CustomerName: details.customer,
            CustomerEmail: details.email,
            Notes: details.notes,
            CustomerPhone: details.phoneNumber,
            ObjectId:"BOOKING",
            VehicleRegistrationNumber: details.vehicleRegistrationNo,
            Status:(isUpdate ? selectedRow.status : "50"),
            isEmail:(isUpdate ? (isChangeDate ? 0 : 1 ):0)
        }
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendBookingUpdateRequest(parameters))
        }else{
            this.props.dispatch(actions.sendAddNewBookingRequest(parameters))
        }
    }

    handleConfirm = (details) => {
        const { selectedRow } = this.state;
        if(selectedRow.status === "10" || details.status === "10"){
            this.setState({isValidationError:true, errorMassage:'Booking Already Confirmed' })
            return
        }
        if(details.date === '' ||
            details.customer === '' ){
                this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
                return
        }

        const parameters = {
            id:(selectedRow.id ? selectedRow.id : details.id) ,
            VehicleId:(selectedRow.vehicleId ? selectedRow.vehicleId :details.vehicleId),
            RequestedDate:moment(selectedRow.requestedDate ? selectedRow.requestedDate : details.requestedDate).format(common.dateTimeFormat),
            CustomerId: (selectedRow.customerId ? selectedRow.customerId : details.customerId),
            CustomerName: (selectedRow.customerName ? selectedRow.customerName : details.customerName),
            CustomerEmail: (selectedRow.customerEmail ? selectedRow.customerEmail : details.customerEmail),
            Notes: (selectedRow.notes ? selectedRow.notes : details.notes),
            CustomerPhone: (selectedRow.customerPhone),
            ObjectId:"BOOKING",
            VehicleRegistrationNumber: (selectedRow.vehicleRegistrationNumber ? selectedRow.vehicleRegistrationNumber : details.vehicleRegistrationNumber),
            Status:"10",
            isEmail:1
        }
        this.props.dispatch(actions.sendBookingUpdateRequest(parameters))
    }

    handleCreateJobcard = (details) => {
        if(!details.vehicleId ){
            this.setState({isValidationError:true, errorMassage:'Vehicle not registered' })
            return
        }

        if(details.jobcardId > 0 ){
            this.setState({isValidationError:true, errorMassage:'JobCard Already Created' })
            return
        }

        this.props.dispatch(actions.saveSelectedBookingSuccess(details))
        this.props.history.push('/jobCard')
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadBookingListError(''))
    }

    successModalClose = () => {
        this.getBookingList()
        this.props.dispatch(actions.sendBookingSuccess(''))
        this.props.dispatch(actions.loadBookingListSuccess([]))
        this.handleModalClose()
        this.props.dispatch(actions.loadBookingListLoading(false))
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.props.dispatch(actions.saveIsBookingModalOpenStatus(false));
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteBooking = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendBookingDeleteRequest(this.state.selectedRow.id))
    }

    handleBookingReminder = (booking) => {
        if(!booking.customerEmail){
            this.setState({isValidationError:true, errorMassage:'Please enter email address' })
            this.handleModalClose()
        }
        else {
            this.props.dispatch(actions.sendBookingReminderRequest(booking.id))
        }
    }

    handleCreate = (inputValue) => {
        this.props.dispatch(loadObjectStatusSuccess([]))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.customer));
        this.props.dispatch(actions.saveIsBookingModalOpenStatus(false))   
        this.props.dispatch(actions.saveCustomerModalOpenStatus(true))
    }

    handleCustomerModalClose = () => {
        this.props.dispatch(actions.saveCustomerModalOpenStatus(false))
        this.props.dispatch(actions.saveIsBookingModalOpenStatus(true))   
        this.props.dispatch(loadSelectedCustomerSuccess(''))
    }

    handleSaveNewCustomer = (details) => {
        const { selectedCustomer } = this.props
        if(details.displayName === '' ||
            details.status === ''){
            this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }
        // const addresses = []
        const notes = []
        notes.push({
            Title:"note",
            NoteName:details.notes,
            recordId:details.noteId ? details.noteId : ''
        })
        

        const parameters = {
            id: (selectedCustomer && selectedCustomer.id && this.state.isUpdate) ? selectedCustomer.id: '',
            TitleId:details.titleId,
            FirstName:details.firstName,
            MiddleName:details.middleName,
            LastName:details.lastName,
            EmailAddress:details.email === ''  ? null: details.email,
            BusinessName:details.companyName,
            FixedPhone:details.phone,
            MobilePhone:details.mobile,
            Fax:details.fax,
            DisplayName:details.displayName,
            CustomField1:details.customField1,
            CustomField2:details.customField2,
            notes:notes,
            ObjectStatus:details.status,
            // addresses: addresses,
            shpStreetNumber: details.streetNoShipping,
            shpStreetName: details.streetNameShipping,
            shpCity: details.cityShipping,
            shpPostalCode: details.postCodeShipping,
            shpRegion: details.stateShipping,
            shpCountry: {
              code: details.countryShipping,
            },
            billStreetNumber: details.streetNoBilling,
            billStreetName: details.streetNameBilling,
            billCity: details.cityBilling,
            billPostalCode: details.postCodeBilling,
            billRegion: details.stateBilling,
            billCountry: {
              code: details.countryBilling,
            },
        }
        this.props.dispatch(actions.sendNewCustomerRequest(parameters))
        
    }

    getCustomerList = () => {
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            name: '',
            email: '',
            mobile: '',
            commonSearch: '',
            city:''
        }
        this.props.dispatch(loadCustomerListRequest(parameters))
    }

    componentDidMount = () =>{
        this.getBookingList()
        this.getCustomerList()
        const parameters= {
            pageSize:9999,
            pageNumber:'',
            name:'',
          }
        this.props.dispatch(loadTitleListRequest(parameters))
    }

    componentDidUpdate (prevProps, prevState){
        const { isChangeStep,resultMonth,calendarView,resultWeek,resultDate } = this.state;
        const { bookingList,isNewCustomerSuccess } = this.props;

        if (this.props.bookingSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
        if(isChangeStep  &&  
            bookingList && bookingList.result && bookingList.result.length && bookingList.result.length > 0  ){
            const newResultDate = new Date(bookingList.result[0].requestedDate)
            const newResultMonth = newResultDate.getMonth(newResultDate)+1
            const startDateWeek = moment(newResultDate).startOf('week').toDate();
            const startTimeDay = moment(newResultDate).startOf('day').toDate();
            var list =[]
            if(calendarView === common.graphicalCalender.monthView && newResultMonth === resultMonth){
                for (let i = 0; i < bookingList.result.length; i++) {
                    const element = bookingList.result[i]
                    const title = element.customerName
                    var EndTime = new Date(element.requestedDate);
                    EndTime.setHours(EndTime.getHours() + 1);
                    const event = {
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: EndTime,
                        resourceId: element.id,
                        title: title,
                        bgColor: element.status === '50'? common.graphicalCalender.unConfirmColor : common.graphicalCalender.confirmedColor,
                        vehicleRegistrationNumber:element.vehicleRegistrationNumber,
                        customerName:element.customerName,
                        customerEmail:element.customerEmail,
                        customerPhone:element.customerPhone,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        customerId:element.customerId,
                        vehicleId:element.vehicleId,
                        jobcardId:element.jobcardId,
                        status:element.status
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }
            else if(calendarView === common.graphicalCalender.weekView && Date(startDateWeek) === Date(resultWeek)){
                for (let i = 0; i < bookingList.result.length; i++) {
                    const element = bookingList.result[i]
                    const title = element.customerName
                    var setEndTime = new Date(element.requestedDate);
                    setEndTime.setHours(setEndTime.getHours() + 1);
                    const event = {
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: setEndTime,
                        resourceId: element.id,
                        title: title,
                        bgColor: element.status === '50'? common.graphicalCalender.unConfirmColor : common.graphicalCalender.confirmedColor,
                        vehicleRegistrationNumber:element.vehicleRegistrationNumber,
                        customerName:element.customerName,
                        customerEmail:element.customerEmail,
                        customerPhone:element.customerPhone,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        customerId:element.customerId,
                        vehicleId:element.vehicleId,
                        jobcardId:element.jobcardId,
                        status:element.status
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }else if(calendarView === common.graphicalCalender.dayView && Date(startTimeDay) === Date(resultDate)){
                for (let i = 0; i < bookingList.result.length; i++) {
                    const element = bookingList.result[i]
                    const title = element.customerName
                    var set_EndTime = new Date(element.requestedDate);
                    set_EndTime.setHours(set_EndTime.getHours() + 1);
                    const event = {
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: set_EndTime,
                        resourceId: element.id,
                        title: title,
                        bgColor: element.status === '50'? common.graphicalCalender.unConfirmColor : common.graphicalCalender.confirmedColor,
                        vehicleRegistrationNumber:element.vehicleRegistrationNumber,
                        customerName:element.customerName,
                        customerEmail:element.customerEmail,
                        customerPhone:element.customerPhone,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        customerId:element.customerId,
                        vehicleId:element.vehicleId,
                        jobcardId:element.jobcardId,
                        status:element.status
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }
        }
        if(this.props.isBookingUsingVehicle && !this.props.isAddNewBookingModalOpen){
            this.props.dispatch(actions.saveIsBookingModalOpenStatus(true))
        }

        if(isNewCustomerSuccess){
            this.props.dispatch(actions.sendNewCustomerSuccess(false))
            this.handleCustomerModalClose()
            this.props.dispatch(loadCustomerListSuccess([]))
            this.setState({
                isNewCustomer: true
            })
        }
    }

    onSelectSlot = ({ action, slots /*, ...props */ }) => {
        if (action === "click") {
          this.setState({
              slots:slots,
              isSelectedSlot:true
          })
          this.props.dispatch(actions.saveIsBookingModalOpenStatus(true))   
        }
        return false;
    };

    render() {

        const {
            isBookingListLoading,
            bookingList,
            reportPeriodList,
            isAddNewBookingModalOpen,
            bookingListError,
            bookingSuccess,
            vehicleList,
            selectedCustomer,
            isBookingUsingVehicle,
            bookingVehicleDetails,
            objectStatusList,
            userAccess,
            titleList,
            countryList,
            isNewCustomerSuccess,
            customerList,
            isAddNewCustomerModalOpen
        } = this.props;

        const { 
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            isUpdate,
            isNewCustomer
        } = this.state;

        return (
            <div>
                {(isBookingListLoading || 
                    (reportPeriodList && reportPeriodList.length && bookingList && bookingList.result && bookingList.result.length && bookingList.result.length === 0)) ? 
                    <Loader show={isBookingListLoading}/> :''}

                {(isValidationError || bookingListError !== '' ) && 
                    <ErrorModal
                        show={true} 
                        massage={errorMassage ? errorMassage : bookingListError}
                        handleClose={this.errorModalClose}
                    />}

                {(bookingSuccess) && 
                    <SuccessModal
                        show={true} 
                        massage={bookingSuccess}
                        handleClose={this.successModalClose}
                    />}

                {isDeleteModalOpen && 
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteBooking}								
                        title={selectedRow.id}
                    />}
                <div className="headerBtn">
                    <Button
                        onClick={this.handleModal}
                        variant="outline-primary"
                        text='Add New'
                        icon='sys-add'
                    />
                </div>
                <div className="content-body">
                    <div style={{ height: 700 }}>
                        <Calendar
                        localizer={localizer}
                        events={this.state.events}
                        step={30}
                        view={this.state.calendarView}
                        views={['month','week','day']}
                        eventPropGetter={(this.eventStyleGetter)}
                        onNavigate={this.onNavigate}
                        onView={this.onView}
                        // start time 8:00am
                        min={
                            new Date(
                                currentDate.getFullYear(), 
                                currentDate.getMonth(), 
                                currentDate.getDate(), 
                                8
                            )
                        }
                        // end time 5:00pm
                        max={
                            new Date(
                                currentDate.getFullYear(), 
                                currentDate.getMonth(), 
                                currentDate.getDate(), 
                                17
                            )
                       }
                        onSelectEvent={this.handleModal}
                        selectable
                        onSelectSlot={this.onSelectSlot}
                        // date={new Date(2018, 0, 1)}
                        />
                    </div>
                    {/* {this.state.isMaintenancePageShow && <MaintenancePage
                        show={this.state.isMaintenancePageShow}
                    />} */}
                </div>
                {
                    isAddNewBookingModalOpen && 
                        <AddBooking
                            isAddNewBookingModalOpen={isAddNewBookingModalOpen}
                            handleModalClose={this.handleModalClose}
                            handleSaveAddNew={this.handleSaveAddNew}
                            isUpdate={isUpdate}
                            selectedRow={selectedRow}
                            handleConfirm={this.handleConfirm}
                            vehicleList={vehicleList}
                            selectedCustomer={selectedCustomer}
                            handleCreateJobcard={this.handleCreateJobcard}
                            isSelectedSlot={this.state.isSelectedSlot}
                            slots={this.state.slots}
                            isBookingUsingVehicle={isBookingUsingVehicle}
                            bookingVehicleDetails={bookingVehicleDetails}
                            handleDeleteConfirm={this.handleDeleteConfirm}
                            handleBookingReminder={this.handleBookingReminder}
                            handleCreate={this.handleCreate}
                            isNewCustomerSuccess={isNewCustomerSuccess}
                            customerList={customerList}
                            isNewCustomer={isNewCustomer}
                        />
                    }

                    {isAddNewCustomerModalOpen && 
                        <AddNewCustomer
                            isAddNewCustomerModalOpen={isAddNewCustomerModalOpen}
                            handleModalClose={this.handleCustomerModalClose}
                            handleSaveAddNew={this.handleSaveNewCustomer}
                            titleList={titleList}
                            objectStatusList={objectStatusList}
                            countryList={countryList}
                            isUpdate={false}
                            userAccess={userAccess}
                        />
                    }
            </div>
        )
    }
}

GraphicalCalendar.propTypes = {
    bookingList: PropTypes.any,
    isBookingListLoading: PropTypes.any,
    isAddNewBookingModalOpen: PropTypes.bool,
    bookingListError: PropTypes.any,
    bookingSuccess: PropTypes.any,
    vehicleList: PropTypes.any,
    selectedCustomer: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    bookingList: selectors.makeSelectBookingList(),
    isBookingListLoading: selectors.makeSelectBookingLoading(),
    isAddNewBookingModalOpen: selectors.makeSelectBookingPopupOpen(),
    bookingListError: selectors.makeSelectBookingError(),
    bookingSuccess: selectors.makeSelectBookingSucess(),
    // vehicleList: makeSelectVehicleList(),
    selectedCustomer: makeSelectSelectedCustomerSuccess(),
    bookingVehicleDetails:selectors.makeSelectBookingVehicleDetails(),
    isBookingUsingVehicle:selectors.makeSelectIsBookingUsingVehicle(),
    titleList: makeSelectTitleList(),
    objectStatusList:makeSelectObjectStatusList(),
    countryList: makeSelectCountryList(),
    userAccess: makeSelectUserAccessSuccess(),
    isNewCustomerSuccess: selectors.makeSelectNewCustomerSuccess(),
    customerList: makeSelectCustomerList(),
    isAddNewCustomerModalOpen: selectors.makeSelectCustomerPopupOpen()
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default withRouter(compose(withConnect)(GraphicalCalendar));
