import React from "react";
import '../../layout/form/form.css'
import PaymentMethod from "./PaymentMethod";
import VemasLogo from '../../../images/VEMAS.png';


function BillingInformation({
    handleChangeStep,
    paymentSetup,
    handleSubmit
}) {
    return (
        <div style={{ margin: '0 5%' }}>
            <div className="row">
                <div className="col-sm-5" style={{marginTop:"40px", textAlign:'center'}}>
                    <div className="vemas_logo">
                        <img alt="vemaslogo" id="vemas_logo" src={VemasLogo} />
                    </div>
                    <div style={{fontSize:"18px", fontWeight: 600}}>It's Simple, Intuitive and Transformative</div>
                </div>
                <div className="col-sm">
                    <PaymentMethod paymentSetup={paymentSetup} handleChangeStep={handleChangeStep} handleSubmit={handleSubmit} />
                </div>
            </div>

        </div>
    )
}

export default BillingInformation;