import { 
    LOAD_NOTIFICATION_LIST_ERROR,
    LOAD_NOTIFICATION_LIST_LOADING,
    LOAD_NOTIFICATION_LIST_REQUEST,
    LOAD_NOTIFICATION_LIST_SUCCESS,
    LOAD_SMS_NOTIFICATION_LIST_ERROR,
    LOAD_SMS_NOTIFICATION_LIST_LOADING,
    LOAD_SMS_NOTIFICATION_LIST_REQUEST,
    LOAD_SMS_NOTIFICATION_LIST_SUCCESS,
    } from '../constant/notificationConstant';

    export function loadNotificationListRequest(data) {
      return {
        type: LOAD_NOTIFICATION_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadNotificationListLoading(data) {
      return {
        type: LOAD_NOTIFICATION_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadNotificationListSuccess(data) {
      return {
        type: LOAD_NOTIFICATION_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadNotificationListError(data) {
      return {
        type: LOAD_NOTIFICATION_LIST_ERROR,
        payload: data,
      }
    };

    export function loadSMSNotificationListRequest(data) {
      return {
        type: LOAD_SMS_NOTIFICATION_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadSMSNotificationListLoading(data) {
      return {
        type: LOAD_SMS_NOTIFICATION_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadSMSNotificationListSuccess(data) {
      return {
        type: LOAD_SMS_NOTIFICATION_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadSMSNotificationListError(data) {
      return {
        type: LOAD_SMS_NOTIFICATION_LIST_ERROR,
        payload: data,
      }
    };
  