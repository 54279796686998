const PATH = 'app/SMSTemplate';

export const SAVE_IS_SMS_TEMPLATE_OPEN_STATUS = `${PATH}SAVE_IS_SMS_TEMPLATE_OPEN_STATUS`;
export const LOAD_SMS_TEMPLATE_LIST_REQUEST = `${PATH}LOAD_SMS_TEMPLATE_LIST_REQUEST`;
export const LOAD_SMS_TEMPLATE_LIST_ERROR = `${PATH}LOAD_SMS_TEMPLATE_LIST_ERROR`;
export const LOAD_SMS_TEMPLATE_LIST_LOADING = `${PATH}LOAD_SMS_TEMPLATE_LIST_LOADING`;
export const LOAD_SMS_TEMPLATE_LIST_SUCCESS = `${PATH}LOAD_SMS_TEMPLATE_LIST_SUCCESS`;
export const LOAD_SELECTED_SMS_TEMPLATE_REQUEST = `${PATH}LOAD_SELECTED_SMS_TEMPLATE_REQUEST`;
export const LOAD_SELECTED_SMS_TEMPLATE_SUCCESS = `${PATH}LOAD_SELECTED_SMS_TEMPLATE_SUCCESS`;
export const SEND_ADD_NEW_SMS_TEMPLATE_REQUEST = `${PATH}SEND_ADD_NEW_SMS_TEMPLATE_REQUEST`;
export const SEND_SMS_TEMPLATE_SUCCESS = `${PATH}SEND_SMS_TEMPLATE_SUCCESS`;
export const SEND_UPDATE_SMS_TEMPLATE_REQUEST = `${PATH}SEND_UPDATE_SMS_TEMPLATE_REQUEST`;
export const SEND_DELETE_SMS_TEMPLATE_REQUEST = `${PATH}SEND_DELETE_SMS_TEMPLATE_REQUEST`;