import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import * as selectors from '../../store/selector/navBarSelector';
import NavBar from '../layout/navBar/NavBar';
import Footer from '../layout/footer/Footer';
import CalendarView from './calendar/calendarView';
// import ServiceSchedule from '../pages/settingMenu/serviceSchedule/ServiceSchedule';
// import VehicleType from '../pages/settingMenu/vehicleType/VehicleType';
// import Repair from '../pages/settingMenu/repair/Repair';
// import Parts from '../pages/settingMenu/part/Parts';
// import JobCardTemplate from '../pages/settingMenu/jobcardTemplates/JobcardTemplates';
import Users from '../pages/settingMenu/users/Users';
import Profile from '../pages/settingMenu/profile/Profile';
// import Model from '../pages/settingMenu/model/Model';
import Dashboard from '../pages/Dashboard';
import { makeSelectLoginUser } from '../../store/selector/authSelector';
import * as actions from '../../store/action/action';
import Cookies from 'js-cookie';
// import Title from './settingMenu/title/Title';
import Customer from './sales/customer/Customer';
// import Vehicle from './vehicle/vehicle/Vehicle';
// import Make from './settingMenu/make/Make';
// import JobCard from './vehicle/jobCard/JobCard';
import Invoice from './sales/invoice/Invoice';
import { saveExpireStatusRequest, saveLoginUser } from '../../store/action/authAction';
// import VehicleHistory from './vehicle/vehicleHistory/VehicleHistory';
// import NotificatonType from './settingMenu/notificationType/NotificatonType';
import Quotation from './sales/quotation/Quotation';
// import Report from './sales/reports/Report';
import { loadReportPeriodRequest } from '../../store/action/reportAction';
import { loadTenantBasicRequest, loadTenantDefaultRequest, loadTenantReactivateSuccess, saveTenantActiveSataus } from '../../store/action/tenantAction';
import { loadDashboardRequest, loadPurchaseOrderSummaryRequest, loadRatingSummaryRequest, loadSalesSummaryRequest } from '../../store/action/dashboardAction';
import Supplier from './purchasing/supplier/Supplier';
import Purchasing from './purchasing/purchasing/Purchasing';
import Item from './settingMenu/item/Item';
import Stock from './stock/stock/Stock';
import GoodsReceipts from './stock/goodSReceipts/GoodsReceipts';
import GoodsReturns from './stock/goodsReturns/GoodsReturns';
import GoodIssues from './stock/goodIssues/GoodIssues';
import CustomerReturns from './stock/customerReturns/CustomerReturns';
import EmailTemplate from './settingMenu/emailTemplates/EmailTemplate';
import SMSTemplate from './settingMenu/smsTemplates/SMSTemplate';
import Notification from './Notification/Notification';
import { loadUserAccessRequest } from '../../store/action/usersAction';
import Product from './settingMenu/product/Product';
import Service from './settingMenu/service/Service';
import SalesReport from './reports/salesReports/Report';
import ProductCategoryMapping from './settingMenu/accounting/ProductCategory';
import QuickBooks from './settingMenu/accounting/QuickBooks';
import Accounting from './settingMenu/accounting/Accounting';
import InvoiceReport from './settingMenu/accounting/InvoiceReport';
import Myob from './settingMenu/accounting/Myob';
import MyAccount from './settingMenu/users/MyAccount';
import ProductCategory from './settingMenu/productCategory/productCategory';
import Analytics from './analytics/customerFeedback/Analytics';
import CustomerAcquisition from './analytics/customerAcquisition/Analytics';
import AppService from './app/appService/AppService';
import AppInvoice from './app/appInvoice/Invoice';
import TenantPaymentMethod from './app/paymentMethod/TenantPaymentMethod';
import InformationModal from '../auth/inforModal';
import WarningModal from '../auth/warningModal';
import { invalidToken } from '../common';
import UserRoles from './settingMenu/userRoles/UserRoles';
import { makeSelectAppBillingTermsList, makeSelectTenantUserExpireStatus } from '../../store/selector/selector';
import { makeSelectTenanReactivateSuccess, makeSelectTenantActiveStatus } from '../../store/selector/tenantSelector';
// import Notification from './Notification';
// import Analytics from './Analytics';

var duration = 14400 // duration in seconds
setInterval(updateTimer, 1000)

function updateTimer() {
  duration--
  // console.log(duration)
  if (duration < 1) {
    Cookies.remove("user")
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }
}

window.addEventListener("click", resetTimer)

function resetTimer() {
  duration = 14400 // duration in seconds
}

class Pages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      billingTerms: null
    }
  }

  handleSignOut = () => {
    Cookies.remove("user")
    this.props.dispatch(saveLoginUser(false))
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
  }

  handleWarningModalClose = () => {
    this.props.dispatch(actions.saveTenantUserExpireStatus(false))
    invalidToken() // logout
  }

  handleWarning = () => {
    this.props.history.push("./appBilling")
    this.props.dispatch(actions.saveTenantUserExpireStatus(false))
    this.props.dispatch(saveTenantActiveSataus(true));
  }

  handleInfoModalClose = () => {
    this.props.dispatch(loadTenantReactivateSuccess({}))
  }

  handleSelect = (e) => {
    this.setState({
      billingTerms: e
    })
  }

  componentDidMount() {
    const user = Cookies.get("user")
    if (this.props.isLoginUser || user) {
      const id = sessionStorage.getItem("tenantId")
      const userId = sessionStorage.getItem("tenantUserId")
      this.props.dispatch(loadTenantDefaultRequest(id))
      this.props.dispatch(loadTenantBasicRequest(id))
      this.props.dispatch(actions.loadUOMRequest());
      this.props.dispatch(actions.loadCountryRequest());
      this.props.dispatch(loadReportPeriodRequest());
      this.props.dispatch(loadSalesSummaryRequest());
      this.props.dispatch(loadDashboardRequest());
      this.props.dispatch(loadRatingSummaryRequest());
      this.props.dispatch(loadPurchaseOrderSummaryRequest())
      this.props.dispatch(actions.loadTenantUserRequest(userId))
      this.props.dispatch(loadUserAccessRequest())
      this.props.dispatch(actions.loadAppBillingTermsRequest())
      this.props.dispatch(saveExpireStatusRequest(null))
    }

    //   if (this.props.isLoginUser || user){
    //     setTimeout(
    //         () => this.handleSignOut(), 
    //         900000
    //         // 1.8e+6
    //     );
    // }
    //   window.addEventListener("click", console.log('active'))
  }

  SecuredRoute = (props) => {
    const user = Cookies.get("user")
    return (
      <Route path={props.path} render={data => this.props.isLoginUser || user ? (
        <props.component {...data}></props.component>) :
        (<Redirect to={{ pathname: '/' }}></Redirect>)}></Route>
    )
  }
  render() {
    const {
      isSideBarStatus,
      clickUnderExpiredStatus,
      tenantUserExpireStatus,
      tenantReactivateStatus,
      billingTermsList
    } = this.props;
    return (
      <div>
        <NavBar />
        {
          tenantUserExpireStatus ?
            <WarningModal
              show={tenantUserExpireStatus}
              handleWarningModalClose={this.handleWarningModalClose}
              handleWarning={this.handleWarning.bind(this)}
              billingTermsList={billingTermsList}
              handleSelect={this.handleSelect}
              billingTerms={this.state.billingTerms}
            />
            : ""
        }
        {
          tenantReactivateStatus.activeStatus ?
            <InformationModal
              show={tenantReactivateStatus.activeStatus}
              handleInfoModalClose={this.handleInfoModalClose}
              tenantReactivateStatus={tenantReactivateStatus}
              billingTermsList={billingTermsList}
            />
            : ""
        }
        <div className={isSideBarStatus ? "content-full-sidebar" : "content-icon-sidebar"}>
          {/* <this.SecuredRoute path='/repair' component={ Repair }/>
                    <this.SecuredRoute path='/parts' component= { Parts } />
                    <this.SecuredRoute path='/vehicletype' component={ VehicleType }/>
                    <this.SecuredRoute path='/srvschedule' component= { ServiceSchedule }/>
                    <this.SecuredRoute path='/jobcardtemplates' component= { JobCardTemplate } />  */}
          <this.SecuredRoute path='/calendar' component={CalendarView} />
          <this.SecuredRoute path='/users' component={Users} />
          <this.SecuredRoute path='/profile' component={Profile} />
          <this.SecuredRoute path='/itmes' component={Item} />
          {/* <this.SecuredRoute path='/model' component={ Model}/> */}
          <this.SecuredRoute path='/dashboard' component={Dashboard} />
          {/* <this.SecuredRoute path='/title' component={ Title } /> */}
          <this.SecuredRoute path='/customer' component={Customer} />
          {/* <this.SecuredRoute path='/vehicle' component={ Vehicle } />
                    <this.SecuredRoute path='/make' component={ Make }/>
                    <this.SecuredRoute path='/jobCard' component={ JobCard }/> */}
          <this.SecuredRoute path='/invoice' component={Invoice} />
          {/* <this.SecuredRoute path='/vehiclehistory' component={ VehicleHistory } />
                    <this.SecuredRoute path='/notificationType' component={ NotificatonType } />*/}
          <this.SecuredRoute path='/quotation' component={Quotation} />
          <this.SecuredRoute path='/supplier' component={Supplier} />
          <this.SecuredRoute path='/purchasing' component={Purchasing} />
          <this.SecuredRoute path='/stock' component={Stock} />
          <this.SecuredRoute path='/goodsReceipts' component={GoodsReceipts} />
          <this.SecuredRoute path='/goodsReturns' component={GoodsReturns} />
          <this.SecuredRoute path='/goodIssues' component={GoodIssues} />
          <this.SecuredRoute path='/customerReturns' component={CustomerReturns} />
          <this.SecuredRoute path='/emailTemplates' component={EmailTemplate} />
          <this.SecuredRoute path='/smsTemplates' component={SMSTemplate} />
          <this.SecuredRoute path='/notification' component={Notification} />
          <this.SecuredRoute path='/products' component={Product} />
          <this.SecuredRoute path='/services' component={Service} />
          <this.SecuredRoute path='/salesReports' component={SalesReport} />
          <this.SecuredRoute path='/accounting' component={Accounting} />
          <this.SecuredRoute path='/xero' component={ProductCategoryMapping} />
          <this.SecuredRoute path='/quickBooks' component={QuickBooks} />
          <this.SecuredRoute path='/myob' component={Myob} />
          <this.SecuredRoute path='/invoiceReconciliationReport' component={InvoiceReport} />
          <this.SecuredRoute path='/myAcccount' component={MyAccount} />
          <this.SecuredRoute path='/productCategory' component={ProductCategory} />
          <this.SecuredRoute path='/analytics' component={Analytics} />
          <this.SecuredRoute path='/customerAcquisition' component={CustomerAcquisition} />
          <this.SecuredRoute path='/appBilling' component={AppService} />
          <this.SecuredRoute path='/appInvoice' component={AppInvoice} />
          <this.SecuredRoute path='/appPaymentMethod' component={TenantPaymentMethod} />
          <this.SecuredRoute path='/userRoles' component={ UserRoles } />
          {/* <this.SecuredRoute path='/accSystemIntegration' component={SalesCategory} /> */}
        </div>
        {clickUnderExpiredStatus ?
          <InformationModal
            show={clickUnderExpiredStatus}
            handleInfoModalClose={this.handleInfoModalClose}
          // handleInfo={this.handleInfo}
          /> : ''}
        <Footer />
      </div>
    )
  }
}

Pages.propTypes = {
  isSideBarStatus: PropTypes.bool,
  isLoginUser: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
  isLoginUser: makeSelectLoginUser(),
  isSideBarStatus: selectors.makeSelectSideBarStatus(),
  tenantUserExpireStatus: makeSelectTenantUserExpireStatus(),
  billingTermsList: makeSelectAppBillingTermsList(),
  tenantReactivateStatus: makeSelectTenanReactivateSuccess(),
  isTenantActiveStatus: makeSelectTenantActiveStatus(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Pages));
