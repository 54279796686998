import { fromJS } from 'immutable';
import {
  LOAD_QUOTATION_LIST_ERROR,
  LOAD_QUOTATION_LIST_LOADING,
  LOAD_QUOTATION_LIST_SUCCESS,
  LOAD_SELECTED_QUOTATION_SUCCESS,
  SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS,
  SAVE_IS_QUOTATION_MODAL_OPEN_STATUS,
  SEND_EMAIL_QUOTATION_SUCCESS,
  SEND_PRINT_QUOTATION_SUCCESS,
  SEND_QUOTATION_IMAGE_EMAIL_ERROR,
  SEND_QUOTATION_IMAGE_EMAIL_SUCCESS,
  SEND_QUOTATION_SUCCESS,
} from '../constant/quotationConstant';

export const initialState = fromJS({
    isAddNewQuotationModalOpen:false,
    quotationList:[],
    isQuotationListLoading:false,
    quotationListError:'',
    quotationSuccess:'',
    selectedQuotation:'',
    quotationPrint:'',
    quotationEmail:'',
    quotationImageError:'',
    quotationImageSuccess:'',
    isSendQuotationImageEmailModalOpen:false
});

function quotationReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_QUOTATION_MODAL_OPEN_STATUS:
      return state.set('isAddNewQuotationModalOpen', action.open)
    case LOAD_QUOTATION_LIST_SUCCESS:
      return state.set('quotationList', action.payload)
    case LOAD_QUOTATION_LIST_LOADING:
      return state.set('isQuotationListLoading', action.payload)
    case LOAD_QUOTATION_LIST_ERROR:
      return state.set('quotationListError', action.payload)
    case SEND_QUOTATION_SUCCESS:
      return state.set('quotationSuccess', action.payload)
    case LOAD_SELECTED_QUOTATION_SUCCESS:
      return state.set('selectedQuotation', action.payload)
    case SEND_PRINT_QUOTATION_SUCCESS:
      return state.set('quotationPrint', action.payload)
    case SEND_EMAIL_QUOTATION_SUCCESS:
      return state.set('quotationEmail', action.payload)
    case SEND_QUOTATION_IMAGE_EMAIL_ERROR:
      return state.set('quotationImageError', action.payload)
    case SEND_QUOTATION_IMAGE_EMAIL_SUCCESS:
      return state.set('quotationImageSuccess', action.payload)
    case SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS:
      return state.set('isSendQuotationImageEmailModalOpen', action.open)
    default:
      return state;
  }
}

export default quotationReducer;
