import { 
    LOAD_QUOTATION_LIST_ERROR,
    LOAD_QUOTATION_LIST_LOADING,
    LOAD_QUOTATION_LIST_REQUEST,
    LOAD_QUOTATION_LIST_SUCCESS,
    LOAD_SELECTED_QUOTATION_SUCCESS,
    SAVE_IS_QUOTATION_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_QUOTATION_REQUEST,
    SEND_QUOTATION_SUCCESS,
    SEND_DELETE_QUOTATION_REQUEST,
    SEND_UPDATE_QUOTATION_REQUEST,
    LOAD_SELECTED_QUOTATION_REQUEST,
    SEND_PRINT_QUOTATION_SUCCESS,
    SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST,
    SEND_ADD_AND_PRINT_QUOTATION_REQUEST,
    SEND_PRINT_QUOTATION_REQUEST,
    SEND_EMAIL_QUOTATION_SUCCESS,
    SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST,
    SEND_EMAIL_QUOTATION_REQUEST,
    SEND_QUOTATION_IMAGE_REQUEST,
    SEND_QUOTATION_IMAGE_SUCCESS,
    SEND_QUOTATION_IMAGE_EMAIL_REQUEST,
    SEND_QUOTATION_IMAGE_EMAIL_ERROR,
    SEND_QUOTATION_IMAGE_EMAIL_SUCCESS,
    SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS
    } from '../constant/quotationConstant';
    
    export function saveIsQuotationModalOpenStatus(open){
      return {
        type: SAVE_IS_QUOTATION_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadQuotationListRequest(data) {
      return {
        type: LOAD_QUOTATION_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadQuotationListLoading(data) {
      return {
        type: LOAD_QUOTATION_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadQuotationListSuccess(data) {
      return {
        type: LOAD_QUOTATION_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadQuotationListError(data) {
      return {
        type: LOAD_QUOTATION_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewQuotationRequest(data) {
      return {
        type: SEND_ADD_NEW_QUOTATION_REQUEST,
        payload: data,
      }
    };
    
    export function sendQuotationSuccess(data) {
      return {
        type: SEND_QUOTATION_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateQuotationRequest(data) {
      return {
        type: SEND_UPDATE_QUOTATION_REQUEST,
        payload: data,
      }
    };
    
    export function sendDeleteQuotationRequest(data) {
      return {
        type: SEND_DELETE_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function loadSelectedQuotationSuccess(data) {
      return {
        type: LOAD_SELECTED_QUOTATION_SUCCESS,
        payload: data,
      }
    };
  
    export function loadSelectedQuotationRequest(data) {
      return {
        type:LOAD_SELECTED_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendPrintQuotationSuccess(data) {
      return {
        type: SEND_PRINT_QUOTATION_SUCCESS,
        payload: data,
      }
    };
  
    export function sendUpdateAndPrintQuotationRequest(data) {
      return {
        type:SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendAddAndPrintQuotationRequest(data) {
      return {
        type:SEND_ADD_AND_PRINT_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendPrintQuotationRequest(data) {
      return {
        type:SEND_PRINT_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendEmailQuotationSuccess(data) {
      return {
        type: SEND_EMAIL_QUOTATION_SUCCESS,
        payload: data,
      }
    };
  
    export function sendUpdateAndEmailQuotationRequest(data) {
      return {
        type:SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendEmailQuotationRequest(data) {
      return {
        type:SEND_EMAIL_QUOTATION_REQUEST,
        payload: data,
      }
    };

    export function sendQuotationImageRequest(data) {
      return {
        type: SEND_QUOTATION_IMAGE_REQUEST,
        payload: data,
      }
    };

    export function sendQuotationImageSuccess(data) {
      return {
        type: SEND_QUOTATION_IMAGE_SUCCESS,
        payload: data,
      }
    };

    export function sendQuotationImageEmailRequest(data) {
      return {
        type: SEND_QUOTATION_IMAGE_EMAIL_REQUEST,
        payload: data,
      }
    };
      
    export function sendQuotationImageEmailSuccess(data) {
      return {
        type: SEND_QUOTATION_IMAGE_EMAIL_SUCCESS,
        payload: data,
      }
    };
    
    export function sendQuotationImageEmailError(data) {
      return {
        type: SEND_QUOTATION_IMAGE_EMAIL_ERROR,
        payload: data,
      }
    };

    export function saveIsQuotationImageEmailModalOpenStatus(open){
      return {
        type: SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS,
        open,
      }
    };
    
    