const PATH = 'app/a2aMapping';

export const LOAD_A2A_MAPPING_ERROR = `${PATH}LOAD_A2A_MAPPING_ERROR`;
export const LOAD_A2A_MAPPING_LOADING = `${PATH}LOAD_A2A_MAPPING_LOADING`;
export const LOAD_A2A_MAPPING_SUCCESS = `${PATH}LOAD_A2A_MAPPING_SUCCESS`;
export const LOAD_A2A_EMPLOYEE_REQUEST = `${PATH}LOAD_A2A_EMPLOYEE_REQUEST`;
export const LOAD_A2A_EMPLOYEE_SUCCESS = `${PATH}LOAD_A2A_EMPLOYEE_SUCCESS`;
export const SEND_A2A_EMPLOYEE_REQUEST = `${PATH}SEND_A2A_EMPLOYEE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_REQUEST = `${PATH}LOAD_ACCOUNT_EMPLOYEE_REQUEST`;
export const LOAD_ACCOUNT_EMPLOYEE_SUCCESS = `${PATH}LOAD_ACCOUNT_EMPLOYEE_SUCCESS`;
export const SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS = `${PATH}SAVE_ACCOUNT_EMPLOYEE_LIST_STATUS`;
export const LOAD_A2A_PAY_TYPE_REQUEST = `${PATH}LOAD_A2A_PAY_TYPE_REQUEST`;
export const LOAD_A2A_PAY_TYPE_SUCCESS = `${PATH}LOAD_A2A_PAY_TYPE_SUCCESS`;
export const SEND_A2A_PAY_TYPE_REQUEST = `${PATH}SEND_A2A_PAY_TYPE_REQUEST`;
export const LOAD_ACCOUNT_PAY_TYPE_REQUEST = `${PATH}LOAD_ACCOUNT_PAY_TYPE_REQUEST`;
export const LOAD_ACCOUNT_PAY_TYPE_SUCCESS = `${PATH}LOAD_ACCOUNT_PAY_TYPE_SUCCESS`;
export const SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS = `${PATH}SAVE_ACCOUNT_PAY_TYPE_LIST_STATUS`;
export const LOAD_ACCOUNT_COMPANY_FILE_REQUEST = `${PATH}LOAD_ACCOUNT_COMPANY_FILE_REQUEST`;
export const LOAD_ACCOUNT_COMPANY_FILE_SUCCESS = `${PATH}LOAD_ACCOUNT_COMPANY_FILE_SUCCESS`;
export const SEND_ACCOUNT_COMPANY_FILE_REQUEST = `${PATH}SEND_ACCOUNT_COMPANY_FILE_REQUEST`;
export const LOAD_ACCOUNT_TAX_CODE_REQUEST = `${PATH}LOAD_ACCOUNT_TAX_CODE_REQUEST`;
export const LOAD_ACCOUNT_TAX_CODE_SUCCESS = `${PATH}LOAD_ACCOUNT_TAX_CODE_SUCCESS`;
export const SEND_ACCOUNT_TAX_CODE_REQUEST = `${PATH}SEND_ACCOUNT_TAX_CODE_REQUEST`;
export const LOAD_INVOICE_RECONCILIATION_REPORT_REQUEST = `${PATH}LOAD_INVOICE_RECONCILIATION_REPORT_REQUEST`;
export const LOAD_INVOICE_RECONCILIATION_REPORT_SUCCESS = `${PATH}LOAD_INVOICE_RECONCILIATION_REPORT_SUCCESS`;
