import { put, call } from 'redux-saga/effects';
import {
  getAccountCompanyFileRequest,
  getAccountEmployeeRequest,
  getAccountPayTypeRequest,
  getAccountTaxCodeRequest,
  getInvoiceReconciliationReportRequest,
  getMappingEmployeeRequest,
  getMappingPayTypeRequest,
  sendAccountCompanyFileRequest,
  sendAccountTaxCodeRequest,
  sendMappingEmployeeRequest,
  sendMappingPayTypeRequest
} from '../service/a2aMappingService';
import * as actions from '../../store/action/a2aMappingAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { loadAccountProductCategoryError, sendMappingProductCategorySuccess } from '../../store/action/accountProductCategoryAction';


export function* loadAccountEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getAccountEmployeeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadAccountEmployeeSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}


export function* loadMappingEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getMappingEmployeeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result
      yield put(actions.loadMappingEmployeeSuccess(system))
      yield put(actions.saveAccountEmployeeListStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendUpdateMappingEmployeeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendMappingEmployeeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadAccountPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getAccountPayTypeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadAccountPayTypeSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}


export function* loadMappingPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getMappingPayTypeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result
      yield put(actions.loadMappingPayTypeSuccess(system))
      yield put(actions.saveAccountPayTypeListStatus(true))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendUpdateMappingPayTypeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendMappingPayTypeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadAccountCompanyFileRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    yield put(actions.loadA2AMappingLoading(true));
    const response = yield call(getAccountCompanyFileRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const system = response.data.result.result
      yield put(actions.loadCompanyFileSuccess(system))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadA2AMappingLoading(false));
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadA2AMappingLoading(false));
  } catch (error) {
    yield put(actions.loadA2AMappingLoading(false));
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendCompanyFileRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(sendAccountCompanyFileRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(actions.loadA2AMappingSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* loadAccountTaxCodeRequest(data) {
  yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getAccountTaxCodeRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const taxCodes = response.data.result.result
      yield put(actions.loadTaxCodeSuccess(taxCodes))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadA2AMappingError(massage))
  }
}

export function* sendTaxCodeRequest(data) {
  yield put(loadAccountProductCategoryError(''))
  try {
    const response = yield call(sendAccountTaxCodeRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const massage = response.data.message
      yield put(sendMappingProductCategorySuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(loadAccountProductCategoryError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(loadAccountProductCategoryError(massage))
  }
}


export function* loadInvoiceReconciliationReportRequest(data) {
  // yield put(actions.loadA2AMappingError(''))
  try {
    const response = yield call(getInvoiceReconciliationReportRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const report = response.data.result
      yield put(actions.loadInvoiceReconciliationReportSuccess(report))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      // const massage = common.error
      // yield put(actions.loadA2AMappingError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    console.log(error)
    // const massage = common.error
    // yield put(actions.loadA2AMappingError(massage))
  }
}

