const PATH = 'app/Item';

export const SAVE_IS_ITEM_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_ITEM_MODAL_OPEN_STATUS`;
export const LOAD_ITEM_LIST_REQUEST = `${PATH}LOAD_ITEM_LIST_REQUEST`;
export const LOAD_ITEM_LIST_ERROR = `${PATH}LOAD_ITEM_LIST_ERROR`;
export const LOAD_ITEM_LIST_LOADING = `${PATH}LOAD_ITEM_LIST_LOADING`;
export const LOAD_ITEM_LIST_SUCCESS = `${PATH}LOAD_ITEM_LIST_SUCCESS`;
export const SEND_ADD_NEW_ITEM_REQUEST = `${PATH}SEND_ADD_NEW_ITEM_REQUEST`;
export const SEND_ADD_NEW_ITEM_ERROR = `${PATH}SEND_ADD_NEW_ITEM_ERROR`;
export const SEND_ADD_NEW_ITEM_LOADING = `${PATH}SEND_ADD_NEW_ITEM_LOADING`;
export const SEND_ADD_NEW_ITEM_SUCCESS = `${PATH}SEND_ADD_NEW_ITEM_SUCCESS`;
export const LOAD_ITEM_CATEGORY_LIST_REQUEST = `${PATH}LOAD_ITEM_CATEGORY_LIST_REQUEST`;
export const LOAD_ITEM_CATEGORY_LIST_SUCCESS = `${PATH}LOAD_ITEM_CATEGORY_LIST_SUCCESS`;
export const SEND_UPDATE_ITEM_REQUEST = `${PATH}SEND_UPDATE_ITEM_REQUEST`;
export const SEND_UPDATE_ITEM_ERROR = `${PATH}SEND_UPDATE_ITEM_ERROR`;
export const SEND_UPDATE_ITEM_LOADING = `${PATH}SEND_UPDATE_ITEM_LOADING`;
export const SEND_UPDATE_ITEM_SUCCESS = `${PATH}SEND_UPDATE_ITEM_SUCCESS`;
export const SEND_DELETE_ITEM_REQUEST = `${PATH}SEND_DELETE_ITEM_REQUEST`;
export const SEND_DELETE_ITEM_ERROR = `${PATH}SEND_DELETE_ITEM_ERROR`;
export const SEND_DELETE_ITEM_LOADING = `${PATH}SEND_DELETE_ITEM_LOADING`;
export const SEND_DELETE_ITEM_SUCCESS = `${PATH}SEND_DELETE_ITEM_SUCCESS`;