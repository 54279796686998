import { 
    SAVE_IS_PART_MODAL_OPEN_STATUS,
    LOAD_PART_LIST_REQUEST,
    LOAD_PART_LIST_LOADING,
    LOAD_PART_LIST_SUCCESS,
    LOAD_PART_LIST_ERROR,
    SEND_ADD_NEW_PART_REQUEST,
    SEND_ADD_NEW_PART_LOADING,
    SEND_ADD_NEW_PART_SUCCESS,
    SEND_ADD_NEW_PART_ERROR,
    LOAD_PART_CATEGORY_LIST_REQUEST,
    LOAD_PART_CATEGORY_LIST_SUCCESS,
    SEND_UPDATE_PART_REQUEST,
    SEND_UPDATE_PART_LOADING,
    SEND_UPDATE_PART_SUCCESS,
    SEND_UPDATE_PART_ERROR,
    SEND_DELETE_PART_REQUEST,
    SEND_DELETE_PART_LOADING,
    SEND_DELETE_PART_SUCCESS,
    SEND_DELETE_PART_ERROR
  } from '../constant/partConstant';
  
export function saveIsPartModalOpenStatus(open){
  return {
    type: SAVE_IS_PART_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadPartListRequest(data) {
  return {
    type: LOAD_PART_LIST_REQUEST,
    payload: data,
  }
};

export function loadPartListLoading(data) {
  return {
    type: LOAD_PART_LIST_LOADING,
    payload: data,
  }
};
  
export function loadPartListSuccess(data) {
  return {
    type: LOAD_PART_LIST_SUCCESS,
    payload: data,
  }
};

export function loadPartListError(data) {
  return {
    type: LOAD_PART_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewPartRequest(data) {
  return {
    type: SEND_ADD_NEW_PART_REQUEST,
    payload: data,
  }
};

export function sendAddNewPartLoading(data) {
  return {
    type: SEND_ADD_NEW_PART_LOADING,
    payload: data,
  }
};

export function sendAddNewPartSuccess(data) {
  return {
    type: SEND_ADD_NEW_PART_SUCCESS,
    payload: data,
  }
};

export function sendAddNewPartError(data) {
  return {
    type: SEND_ADD_NEW_PART_ERROR,
    payload: data,
  }
};

export function loadPartCategoryListRequest() {
  return {
    type: LOAD_PART_CATEGORY_LIST_REQUEST
  }
};

export function loadPartCategoryListSuccess(data) {
  return {
    type: LOAD_PART_CATEGORY_LIST_SUCCESS,
    payload: data,
  }
};

export function sendUpdatePartRequest(data) {
  return {
    type: SEND_UPDATE_PART_REQUEST,
    payload: data,
  }
};

export function sendUpdatePartLoading(data) {
  return {
    type: SEND_UPDATE_PART_LOADING,
    payload: data,
  }
};
  
export function sendUpdatePartSuccess(data) {
  return {
    type: SEND_UPDATE_PART_SUCCESS,
    payload: data,
  }
};

export function sendUpdatePartError(data) {
  return {
    type: SEND_UPDATE_PART_ERROR,
    payload: data,
  }
};


export function sendDeletePartRequest(data) {
  return {
    type: SEND_DELETE_PART_REQUEST,
    payload: data,
  }
};

export function sendDeletePartLoading(data) {
  return {
    type: SEND_DELETE_PART_LOADING,
    payload: data,
  }
};
  
export function sendDeletePartSuccess(data) {
  return {
    type: SEND_DELETE_PART_SUCCESS,
    payload: data,
  }
};

export function sendDeletePartError(data) {
  return {
    type: SEND_DELETE_PART_ERROR,
    payload: data,
  }
};