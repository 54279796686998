import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getAccountEmployeeRequest(id){
    return axios.get(baseURL+'accountIntegration/employee', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingEmployeeRequest(parameters){
    return axios.get(baseURL+'a2aEmployee?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendMappingEmployeeRequest(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL+'a2aEmployee',parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountPayTypeRequest(id){
    return axios.get(baseURL+'accountIntegration/payTypeCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getMappingPayTypeRequest(parameters){
    return axios.get(baseURL+'a2aPayType?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}


export function sendMappingPayTypeRequest(data){
    const parameters = JSON.stringify(data)
    // console.log(parameters)
    return axios.patch(baseURL+'a2aPayType',parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountCompanyFileRequest(id){
    return axios.get(baseURL+'accountIntegration/companyFiles', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendAccountCompanyFileRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'accountIntegration/companyFiles',parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getAccountTaxCodeRequest(id){
    return axios.get(baseURL+'accountIntegration/taxCode', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendAccountTaxCodeRequest(parameters){
    return axios.patch(baseURL+'accountIntegration/taxCode',parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getInvoiceReconciliationReportRequest(parameters){
    return axios.get(baseURL+'accountIntegration/invoiceReport?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&InvoiceDate='+parameters.invoiceDate+'&DueDate='+parameters.dueDate, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}
