import { createSelector } from 'reselect';

const selectBookingDomain = state => state.booking;

const makeSelectBookingPopupOpen =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('isAddNewBookingModalOpen') : false
  );

const makeSelectBookingList =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('bookingList') : []
  );

const makeSelectBookingLoading =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('isBookingListLoading') : false
  );

const makeSelectBookingError =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('bookingListError') : ''
  );

const makeSelectBookingSucess =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('bookingSuccess') : ''
  );

const makeSelectSelectedBookingSucess =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('selectedBooking') : ''
  );

const makeSelectIsBookingUsingVehicle =() =>
    createSelector(
      selectBookingDomain,
      bookingState => 
        bookingState ? bookingState.get('isBookingUsingVehicle') : false
    );
  
const makeSelectBookingVehicleDetails =() =>
    createSelector(
      selectBookingDomain,
      bookingState => 
        bookingState ? bookingState.get('bookingVehicleDetails') : ''
    );

const makeSelectNewCustomerSuccess =() =>
    createSelector(
      selectBookingDomain,
      bookingState => 
        bookingState ? bookingState.get('isNewCustomerSuccess') : false
    );

const makeSelectCustomerPopupOpen =() =>
  createSelector(
    selectBookingDomain,
    bookingState => 
      bookingState ? bookingState.get('isAddNewCustomerModalOpen') : false
  );

export {
    makeSelectBookingPopupOpen,
    makeSelectBookingList,
    makeSelectBookingLoading,
    makeSelectBookingError,
    makeSelectBookingSucess,
    makeSelectSelectedBookingSucess,
    makeSelectIsBookingUsingVehicle,
    makeSelectBookingVehicleDetails,
    makeSelectNewCustomerSuccess,
    makeSelectCustomerPopupOpen
}