import { createSelector } from 'reselect';

const selectPartDomain = state => state.app;

const makeSelectObjectStatusList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('objectStatusList') : []
  );

const makeSelectUOMList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('uomList') : []
  );

const makeSelectCountryList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('countryList') : []
  );

const makeSelectTenant = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenant') : {}
  );


const makeSelectTenantUser = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenantUser') : {}
  );

const makeSelectTenantUserExpireStatus = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenantUserExpireStatus') : false
  );

const makeSelectClickUnderExpiredStatus = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('clickUnderExpiredStatus') : false
  );

const makeSelectCommonSearchData = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('commonSearchData') : {}
  );

const makeSelectAppBillingTermsList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('billingTermsList') : []
  );

const makeSelectAppPackagesList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('appPackageList') : []
  );

export {
  makeSelectObjectStatusList,
  makeSelectUOMList,
  makeSelectCountryList,
  makeSelectTenant,
  makeSelectTenantUser,
  makeSelectTenantUserExpireStatus,
  makeSelectClickUnderExpiredStatus,
  makeSelectCommonSearchData,
  makeSelectAppBillingTermsList,
  makeSelectAppPackagesList
}
