import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import './form.css';

const ButtonGroup = ({
    primaryBtnClick,
    secondaryBtnClick,
    primaryBtnVariant,
    primaryBtnText,
    primaryBtnIcon,
    secondaryBtnText,
    secondaryBtnVariant,
    secondaryBtnIcon,
    primaryBtnDisabled,
    secondaryBtnDisabled
}) => (
    <div style={{display: 'flex'}}>
        <div style={{margin:'5px'}}>
            <Button
                onClick={primaryBtnClick}
                variant={primaryBtnVariant}
                text ={primaryBtnText}
                size='sm'
                icon={primaryBtnIcon}
                disabled={primaryBtnDisabled}
            />
        </div>
        <div style={{margin:'5px'}}>
            <Button
                onClick={secondaryBtnClick}
                variant={secondaryBtnVariant}
                text ={secondaryBtnText}
                size='sm'
                icon={secondaryBtnIcon}
                disabled={secondaryBtnDisabled}
            />
        </div>
    </div>
);

ButtonGroup.propTypes = {
    primaryBtnClick: PropTypes.any,
    primaryBtnIcon: PropTypes.any,
    primaryBtnText: PropTypes.any,
    primaryBtnVariant: PropTypes.any,
    secondaryBtnClick: PropTypes.any,
    secondaryBtnIcon: PropTypes.any,
    secondaryBtnText: PropTypes.any,
    secondaryBtnVariant: PropTypes.any,
    secondaryBtnDisabled: PropTypes.any,
    primaryBtnDisabled: PropTypes.any,
}

export default ButtonGroup;