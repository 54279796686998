import { createSelector } from 'reselect';

const selectReportDomain = state => state.reports;

const makeSelectReportList = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('reportList') : []
  );

const makeSelectReportListLoading = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('isReportListLoading') : false
  );

const makeSelectReportListError = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('reportListError') : ''
  );

const makeSelectReportPeriodSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('reportPeriodList') : []
  );

const makeSelectReportPrintSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('reportPdf') : ''
  );

const makeSelectReportCSVSuccess = () =>
  createSelector(
    selectReportDomain,
    reportState => 
      reportState ? reportState.get('reportCSV') : ''
  );

export {
    makeSelectReportList,
    makeSelectReportListLoading,
    makeSelectReportListError,
    makeSelectReportPeriodSuccess,
    makeSelectReportPrintSuccess,
    makeSelectReportCSVSuccess
}