import React from 'react';
import PropTypes from 'prop-types';
import './form.css';
import { Line } from 'react-chartjs-2'


const options={
    scales: {
        y: {
            beginAtZero: true
        }
    },
    responsive: true,
}
const LineChart = ({
    data,
    height
}) => (
    <div style={{width:'100%', height:'500px'}} >
        <Line
        data={data} 
        options={options}
        height={height}
        />
    </div>
);

LineChart.propTypes = {
    data: PropTypes.any,
    height: PropTypes.any,
}

export default LineChart;