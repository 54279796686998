import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function getRatingDetails(parameters){
  return axios.get(baseURL+'rating?ToDate='+parameters.toDate+'&FromDate='+parameters.fromDate+'&GroupBy='+parameters.groupBy+'&Rating='+parameters.rating+'&CustomerId='+parameters.customer, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}


