import { put, call } from 'redux-saga/effects';
import {
  deleteConnectionRequest,
  getAccountSystemRequest, getQuickBooksConnectionRequest, sendConnectionRequest, updateConnectionFromDateRequest
} from '../service/accountService';
import * as actions from '../../store/action/accountAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadAccountSystemRequest(data){
  // console.log('loadAccountSystemRequest')
  yield put(actions.loadAccountError(''))
  try{
    yield put(actions.loadAccountLoading(true));
    const response = yield call(getAccountSystemRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data)
      const system = response.data.result.result
      yield put(actions.loadAccountSystemSuccess(system))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else {
      yield put(actions.loadAccountLoading(false));
      const massage = common.error
      yield put(actions.loadAccountError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountLoading(false));
  }catch(error){
    yield put(actions.loadAccountLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadAccountError(massage))
  }
}

export function* loadQuickBooksConnectionRequest(){
  // console.log('loadQuickBooksConnectionRequest')
  yield put(actions.loadAccountError(''))
  try{
    yield put(actions.loadAccountLoading(true));
    const response = yield call(getQuickBooksConnectionRequest);
    if(response && response.data && response.status && response.status === 200 ){
      // console.log(response.data)
    //   const massage = response.data
      yield put(actions.loadQuickBooksConnectionSuccess(response.data))
    }  
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    if(response && response.data && response.data.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountLoading(false));
  }catch(error){
    yield put(actions.loadAccountLoading(false));
    // console.log(error)
  }
}


export function* sendDeleteConnectionRequest(data){
  // console.log('sendDeleteBookingRequest')
  // yield put(actions.loadBookingListError(''))
  try{
    // yield put(actions.loadBookingListLoading(true));
    const response = yield call(deleteConnectionRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendDeleteConnectionSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      // const massage = response.data.message
      // yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.loadBookingListLoading(false));
  }catch(error){
    // yield put(actions.loadBookingListLoading(false));
    console.log(error.response)
    // const massage = common.error
    // yield put(actions.loadBookingListError(massage))
  }
}

export function* addConnectionRequest(data){
  // console.log('sendUpdateBookingRequest')
  // yield put(actions.loadBookingListError(''))
  try{
    yield put(actions.loadAccountLoading(true));
    const response = yield call(sendConnectionRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.result)
      const data = response.data
      yield put(actions.sendConnectionSuccess(data))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      // const massage = response.data.message
      // yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountLoading(false));
  }catch(error){
    yield put(actions.loadAccountLoading(false));
    // console.log(error.response)
    // const massage = common.error
    // yield put(actions.loadBookingListError(massage))
  }
}

export function* updateConnectionDateRequest(data){
  // console.log('sendUpdateBookingRequest')
  // yield put(actions.loadBookingListError(''))
  try{
    yield put(actions.loadAccountLoading(true));
    const response = yield call(updateConnectionFromDateRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      // const data = response.data
      yield put(actions.sendConnectionUpdateSuccess(true))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      // const massage = response.data.message
      // yield put(actions.loadBookingListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadAccountLoading(false));
  }catch(error){
    yield put(actions.loadAccountLoading(false));
    // console.log(error.response)
    // const massage = common.error
    // yield put(actions.loadBookingListError(massage))
  }
}