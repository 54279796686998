import { createSelector } from 'reselect';

const selectProductCategoryDomain = state => state.productCategory;

const makeSelectProductCategoryPopupOpen =() =>
  createSelector(
    selectProductCategoryDomain,
    productCategoryState => 
      productCategoryState ? productCategoryState.get('isAddNewProductCategoryModalOpen') : false
  );

const makeSelectProductCategoryList = () =>
  createSelector(
    selectProductCategoryDomain,
    productCategoryState => 
      productCategoryState ? productCategoryState.get('productCategoryList') : []
  );

const makeSelectProductCategoryListLoading = () =>
  createSelector(
    selectProductCategoryDomain,
    productCategoryState => 
      productCategoryState ? productCategoryState.get('isProductCategoryListLoading') : false
  );

const makeSelectProductCategoryListError = () =>
  createSelector(
    selectProductCategoryDomain,
    productCategoryState => 
      productCategoryState ? productCategoryState.get('productCategoryListError') : ''
  );

const makeSelectProductCategorySuccess = () =>
  createSelector(
    selectProductCategoryDomain,
    productCategoryState => 
      productCategoryState ? productCategoryState.get('productCategorySuccess') : ''
  );

export {
    makeSelectProductCategoryPopupOpen,
    makeSelectProductCategoryList,
    makeSelectProductCategoryListLoading,
    makeSelectProductCategoryListError,
    makeSelectProductCategorySuccess
}
