import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
// import SelectField from '../../../layout/form/SelectField';

export class ServiceModal extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            description: '',
            title:'',
            id:'',
            initial: true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedRow,isUpdateService } = prevProps;
        if(isUpdateService && selectedRow  && selectedRow.id >= 0 && prevState.initial){
            return {
                description: selectedRow.description,
                title: selectedRow.title,
                id: selectedRow.id,
                index: selectedRow.index,
                initial:false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnBlur = (e) => {
        this.setState({
            [e.target.id]: parseFloat(e.target.value).toFixed(2)
        })
    }

    // handleChangeUOM = (value) =>{
    //     this.setState({unitOfMeasure:value})
    //   }
    
  
    render() {
        const {
            isAddNewServiceModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            // repairCategoryList,
            // objectStatusList,
            // uomList,
            // selectedRow
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewServiceModalOpen} 
                    onHide={handleModalClose}
                    title="Add Services"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"25px"}}>
                                <TextField
                                    text="Title"
                                    controlId="title"
                                    placeholder="Title"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.title}
                                    required
                                    maxLength={45}
                                />
                                <TextField
                                    text="Description"
                                    controlId="description"
                                    placeholder="Description"
                                    type="text"
                                    as="textarea" 
                                    rows={3}
                                    onChange={this.handleChange}
                                    defaultValue={this.state.description}
                                    required
                                    maxLength={250}
                                />
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Add'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

ServiceModal.propTypes = {
    isAddNewServiceModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.any,
    saveDetails: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    repairCategoryList: PropTypes.any,
    uomList: PropTypes.any,
    objectStatusList: PropTypes.any,
    selectedRow: PropTypes.any
}

export default ServiceModal;