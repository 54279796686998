const PATH = 'app/Repair';

export const SAVE_IS_REPAIR_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_REPAIR_MODAL_OPEN_STATUS`;
export const LOAD_REPAIR_LIST_REQUEST = `${PATH}LOAD_REPAIR_LIST_REQUEST`;
export const LOAD_REPAIR_LIST_ERROR = `${PATH}LOAD_REPAIR_LIST_ERROR`;
export const LOAD_REPAIR_LIST_LOADING = `${PATH}LOAD_REPAIR_LIST_LOADING`;
export const LOAD_REPAIR_LIST_SUCCESS = `${PATH}LOAD_REPAIR_LIST_SUCCESS`;
export const SEND_ADD_NEW_REPAIR_REQUEST = `${PATH}SEND_ADD_NEW_REPAIR_REQUEST`;
export const SEND_REPAIR_SUCCESS = `${PATH}SEND_REPAIR_SUCCESS`;
export const SEND_UPDATE_REPAIR_REQUEST = `${PATH}SEND_UPDATE_REPAIR_REQUEST`;
export const SEND_DELETE_REPAIR_REQUEST = `${PATH}SEND_DELETE_REPAIR_REQUEST`;
export const LOAD_REPAIR_CATEGORY_LIST_REQUEST = `${PATH}LOAD_REPAIR_CATEGORY_LIST_REQUEST`;
export const LOAD_REPAIR_CATEGORY_LIST_SUCCESS = `${PATH}LOAD_REPAIR_CATEGORY_LIST_SUCCESS`;