import { 
  LOAD_SMS_TEMPLATE_LIST_ERROR,
  LOAD_SMS_TEMPLATE_LIST_LOADING,
  LOAD_SMS_TEMPLATE_LIST_REQUEST,
  LOAD_SMS_TEMPLATE_LIST_SUCCESS,
  LOAD_SELECTED_SMS_TEMPLATE_REQUEST,
  LOAD_SELECTED_SMS_TEMPLATE_SUCCESS,
  SAVE_IS_SMS_TEMPLATE_OPEN_STATUS,
  SEND_ADD_NEW_SMS_TEMPLATE_REQUEST,
  SEND_DELETE_SMS_TEMPLATE_REQUEST,
  SEND_SMS_TEMPLATE_SUCCESS,
  SEND_UPDATE_SMS_TEMPLATE_REQUEST,
  } from '../constant/smsTempConstant';
  
  export function saveIsSMSTemplateModalOpenStatus(open){
    return {
      type: SAVE_IS_SMS_TEMPLATE_OPEN_STATUS,
      open,
    }
  };

  export function loadSMSTemplateListRequest(data) {
    return {
      type: LOAD_SMS_TEMPLATE_LIST_REQUEST,
      payload: data,
    }
  };
  
  export function loadSMSTemplateListLoading(data) {
    return {
      type: LOAD_SMS_TEMPLATE_LIST_LOADING,
      payload: data,
    }
  };
    
  export function loadSMSTemplateListSuccess(data) {
    return {
      type: LOAD_SMS_TEMPLATE_LIST_SUCCESS,
      payload: data,
    }
  };
  
  export function loadSMSTemplateListError(data) {
    return {
      type: LOAD_SMS_TEMPLATE_LIST_ERROR,
      payload: data,
    }
  };

  export function sendAddNewSMSTemplateRequest(data) {
    return {
      type: SEND_ADD_NEW_SMS_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  
  export function sendSMSTemplateSuccess(data) {
    return {
      type: SEND_SMS_TEMPLATE_SUCCESS,
      payload: data,
    }
  };
  
  export function sendUpdateSMSTemplateRequest(data) {
    return {
      type: SEND_UPDATE_SMS_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  
  export function sendDeleteSMSTemplateRequest(data) {
    return {
      type: SEND_DELETE_SMS_TEMPLATE_REQUEST,
      payload: data,
    }
  };

  export function loadSelectedSMSTemplateSuccess(data) {
    return {
      type: LOAD_SELECTED_SMS_TEMPLATE_SUCCESS,
      payload: data,
    }
  };

  export function loadSelectedSMSTemplateRequest(data) {
    return {
      type: LOAD_SELECTED_SMS_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  