import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import SelectField from "../../../layout/form/SelectField";
import DatePicker from '../../../layout/form/datePicker';
import moment from "moment";
import common, { numberWithCommas } from "../../../common";
import { loadStockDocumentProductReportError, loadStockDocumentProductReportRequest, loadStockDocumentProductReportSuccess } from "../../../../store/action/stockDocumentAction";
import { makeSelectStockDocumentProductReportDocument, makeSelectStockDocumentProductReportError, makeSelectStockDocumentProductReportLoading } from "../../../../store/selector/stockDocumentSelector";
import Table from "../../../layout/form/Table";
import Button from "../../../layout/form/Button";
import Loader from "../../../layout/form/Loader";
import ErrorModal from "../../../layout/form/errorModal";
import Pagination from "../../../layout/form/Pagination";

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

// const customStyles = {
//   control: base => ({
//     ...base,
//     height: 20,
//     minHeight: 32,
//     marginBottom: 0,
//     marginTop: -20
//   })
// };

class StockHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      product: '',
      fromDate: moment(prevDate).format(common.dateFormat),
      toDate: moment(today).format(common.dateFormat),
      initial: true
    };

  };

  static getDerivedStateFromProps(prevProps, prevState) {
    if (prevState.initial && prevProps.product && prevProps.product > 0) {
      return {
        initial: false,
        product: prevProps.product
      }
    }
    return null
  }

  getReport = () => {
    const parameters = {
      product: this.state.product,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate
    }
    this.props.dispatch(loadStockDocumentProductReportRequest(parameters))
  }

  componentDidMount() {
    if (this.props.product > 0) {
      this.getReport()
    }
  }

  handleDateChange = (date, id) => {
    this.props.dispatch(loadStockDocumentProductReportSuccess({}))
    if (date === null && id === "fromDate") {
      this.setState({
        [id]: moment(prevDate).format(common.dateFormat),
      })
    }
    else if (date === null && id === "toDate") {
      this.setState({
        [id]: moment(today).format(common.dateFormat),
      })
    } else {
      this.setState({
        [id]: moment(date).format(common.dateFormat),
      })
    }
  }

  handleChangeSelect = (id, value) => {
    this.props.dispatch(loadStockDocumentProductReportSuccess({}))
    this.setState({
      [id]: value
    })
  }

  setQuantity = (id) => {
    const { partList } = this.props;
    if (partList && partList.length && partList.length !== 0) {
      const desc = partList.find(select => select.id === id)
      return (desc && desc.quantityInStock ? numberWithCommas(parseFloat(desc.quantityInStock).toFixed(2)) : '0.00')
    }
  }

  setPrice = (id) => {
    const { partList } = this.props;
    if (partList && partList.length && partList.length !== 0) {
      const desc = partList.find(select => select.id === id)
      return (desc && desc.quantityInStock ? numberWithCommas(parseFloat(desc.quantityInStock * desc.unitPrice).toFixed(2)) : '0.00')
    }
  }

  errorModalClose = () => {
    this.props.dispatch(loadStockDocumentProductReportError(null))
  }

  setDateFormat = (value) => {
    const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
    return date
  }

  getNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      this.getReport()
    }
  }

  render() {

    const {
      handleClose,
      partList,
      stockDocumentProductReport,
      isStockDocumentProductReportLoading,
      stockDocumentProductReportError
    } = this.props

    const columns = [{
      Header: 'Document Date',
      accessor: 'documentDate',
      width: "12%",
      Cell: ((row) => (
        <div >
          {row.row.original.documentDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.documentDate)}
        </div>
      )),
    }, {
      Header: 'Document No',
      accessor: 'documentNo',
      width: "12%",
    }, {
      Header: 'Document Type',
      accessor: 'documentType',
      width: "15%"
    }, {
      Header: 'Supplier',
      accessor: 'partnerName',
    }, {
      Header: 'UOM',
      accessor: 'unitOfMeasure',
      width: '7%',
    }, {
      accessor: 'quantity',
      Header: () => (<div style={{ textAlign: "right" }}>Quantity</div>),
      width: "8%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>
          {numberWithCommas(parseFloat(row.row.original.quantity).toFixed(2))}
        </div>
      ))
    }, {
      Header: () => (<div style={{ textAlign: "right" }}>Unit Price</div>),
      accessor: 'price',
      width: "8%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>
          {numberWithCommas(parseFloat(row.row.original.price).toFixed(2))}
        </div>
      ))
    }, {
      accessor: 'value',
      Header: () => (<div style={{ textAlign: "right" }}>Value</div>),

      Cell: ((row) => (
        <div style={{
          textAlign: "right"
        }}>
          {numberWithCommas(parseFloat(row.row.original.quantity * row.row.original.price).toFixed(2))}
        </div>
      )),
      width: "8%",
    }, {
      Header: 'Action',
      width: '8%',
      Cell: ((rowData) => (
        <div>
          {<span style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <span><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span> */}
          </span>}
        </div>
      )),
      accessor: 'action'
    }]

    return (
      <div>
        {isStockDocumentProductReportLoading ?
          <Loader show={isStockDocumentProductReportLoading} /> : ''}
        {stockDocumentProductReportError !== null &&
          <ErrorModal
            show={true}
            massage={stockDocumentProductReportError}
            handleClose={this.errorModalClose}
          />}
        <div className="content-body">
          <div className="filter-header">
            <h5>

            </h5>
            <div className="btn-close" onClick={() => handleClose()} >
              <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
            </div>
          </div>
          <form style={{ margin: "15px" }}>
            <div className="row">
              <div className="col-sm-6">
                <SelectField
                  text="Product"
                  controlId="product"
                  options={
                    partList.length && partList.length > 0 ?
                      partList.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ?
                        1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0)) : []}
                  selectedValue={this.state.product}
                  onChange={(e) => this.handleChangeSelect("product", e)}
                  id="id"
                />
              </div>
              <div className="col-sm" style={{ padding: '30px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px', fontWeight: 600 }}>
                  <div style={{ paddingRight:'30px' }}>
                    Quantity in Stock  : {this.setQuantity(this.state.product)}
                  </div>
                  <div>
                    Stock Value  : {this.setPrice(this.state.product)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <DatePicker
                  text="From Date"
                  controlId="fromDate"
                  onChange={(e) => this.handleDateChange(e, "fromDate")}
                  value={new Date(this.state.fromDate)}
                // format={dateFormat}
                />
              </div>
              <div className="col-sm-3">
                <DatePicker
                  text="To Date"
                  controlId="toDate"
                  onChange={(e) => this.handleDateChange(e, "toDate")}
                  value={new Date(this.state.toDate)}
                // format={dateFormat}
                />
              </div>
            </div>
            <div className="filter-btns" style={{ marginRight: '15px' }}>
              <div style={{ margin: '5px' }}>
                <Button
                  variant="outline-primary"
                  text='Run'
                  // type="submit"
                  icon='begin'
                  onClick={() => this.getReport()}
                />
              </div>
            </div>
          </form>
          <div className="table-invoice">
            <Table
              columns={columns}
              data={stockDocumentProductReport && stockDocumentProductReport.result && stockDocumentProductReport.result.length > 0 ? stockDocumentProductReport.result : []}
            />

          </div>
        </div>
        <Pagination
          currentPage={stockDocumentProductReport.page ? stockDocumentProductReport.page : this.state.pageNumber}
          getNextPage={this.getNextPage}
          getPrevPage={this.getPrevPage}
          totalPage={stockDocumentProductReport.totalPages}
          setClickedPage={this.setClickedPage}
          currentPageSize={this.state.pageSize}
          setPageSize={this.setPageSize}
        />
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  stockDocumentProductReport: makeSelectStockDocumentProductReportDocument(),
  stockDocumentProductReportError: makeSelectStockDocumentProductReportError(),
  isStockDocumentProductReportLoading: makeSelectStockDocumentProductReportLoading()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default (compose(withConnect)(StockHistory));