const PATH = 'app/Product';

export const SAVE_IS_PRODUCT_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_PRODUCT_MODAL_OPEN_STATUS`;
export const LOAD_PRODUCT_LIST_REQUEST = `${PATH}LOAD_PRODUCT_LIST_REQUEST`;
export const LOAD_PRODUCT_LIST_ERROR = `${PATH}LOAD_PRODUCT_LIST_ERROR`;
export const LOAD_PRODUCT_LIST_LOADING = `${PATH}LOAD_PRODUCT_LIST_LOADING`;
export const LOAD_PRODUCT_LIST_SUCCESS = `${PATH}LOAD_PRODUCT_LIST_SUCCESS`;
export const LOAD_SELECTED_PRODUCT_REQUEST = `${PATH}LOAD_SELECTED_PRODUCT_REQUEST`;
export const LOAD_SELECTED_PRODUCT_SUCCESS = `${PATH}LOAD_SELECTED_PRODUCT_SUCCESS`;
export const SEND_ADD_NEW_PRODUCT_REQUEST = `${PATH}SEND_ADD_NEW_PRODUCT_REQUEST`;
export const SEND_ADD_NEW_PRODUCT_ERROR = `${PATH}SEND_ADD_NEW_PRODUCT_ERROR`;
export const SEND_ADD_NEW_PRODUCT_LOADING = `${PATH}SEND_ADD_NEW_PRODUCT_LOADING`;
export const SEND_ADD_NEW_PRODUCT_SUCCESS = `${PATH}SEND_ADD_NEW_PRODUCT_SUCCESS`;
// export const LOAD_PRODUCT_CATEGORY_LIST_REQUEST = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_REQUEST`;
// export const LOAD_PRODUCT_CATEGORY_LIST_SUCCESS = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_SUCCESS`;
export const SEND_UPDATE_PRODUCT_REQUEST = `${PATH}SEND_UPDATE_PRODUCT_REQUEST`;
export const SEND_UPDATE_PRODUCT_ERROR = `${PATH}SEND_UPDATE_PRODUCT_ERROR`;
export const SEND_UPDATE_PRODUCT_LOADING = `${PATH}SEND_UPDATE_PRODUCT_LOADING`;
export const SEND_UPDATE_PRODUCT_SUCCESS = `${PATH}SEND_UPDATE_PRODUCT_SUCCESS`;
export const SEND_DELETE_PRODUCT_REQUEST = `${PATH}SEND_DELETE_PRODUCT_REQUEST`;
export const SEND_DELETE_PRODUCT_ERROR = `${PATH}SEND_DELETE_PRODUCT_ERROR`;
export const SEND_DELETE_PRODUCT_LOADING = `${PATH}SEND_DELETE_PRODUCT_LOADING`;
export const SEND_DELETE_PRODUCT_SUCCESS = `${PATH}SEND_DELETE_PRODUCT_SUCCESS`;
export const LOAD_PRODUCT_TYPE_LIST_REQUEST = `${PATH}LOAD_PRODUCT_TYPE_LIST_REQUEST`;
export const LOAD_PRODUCT_TYPE_LIST_SUCCESS = `${PATH}LOAD_PRODUCT_TYPE_LIST_SUCCESS`;
export const LOAD_BALANCE_STOCK_PRINT_REQUEST = `${PATH}LOAD_BALANCE_STOCK_PRINT_REQUEST`;
export const LOAD_BALANCE_STOCK_PRINT_SUCCESS = `${PATH}LOAD_BALANCE_STOCK_PRINT_SUCCESS`;
export const LOAD_ALL_PRODUCTS_REQUEST = `${PATH}LOAD_ALL_PRODUCTS_REQUEST`;
export const LOAD_ALL_PRODUCTS_SUCCESS = `${PATH}LOAD_ALL_PRODUCTS_SUCCESS`;
export const LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST = `${PATH}LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST`;
export const LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS = `${PATH}LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS`;
export const SEND_ALTERNATIVE_PRODUCT_REQUEST = `${PATH}SEND_ALTERNATIVE_PRODUCT_REQUEST`;