import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import MyobCompany from './MyobCompany';
import MyobMapping from './MyobMapping';

class Myob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataMapping:false
        }
    }

    redirectDataMapping = () => {
        this.setState({
            isDataMapping:true
        })
    }

    redirectCompanyMapping = () => {
        this.setState({
            isDataMapping:false
        })
    }

    render() {

        const { 
            isDataMapping
        } = this.state;

        return (
            <div>
              <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        <span>
                            <Link to='./myob'>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span>
                        <span>
                          <Link to='./invoiceReconciliationReport'>
                            <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                          </Link>
                        </span>
                    </div>
                </div>
                <div style={{display:'flex', margin:'10px'}}>
                  <span style={{cursor:'pointer'}} onClick={() => this.redirectCompanyMapping()}><ui5-icon class="samples-margin" name="calendar" id="tab-icon"></ui5-icon> Company</span>
                  <span style={{cursor:'pointer'}} onClick={() => this.redirectDataMapping()} ><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> Data</span>
                </div>
                {
                  isDataMapping ?
                    <MyobMapping/> :
                    <MyobCompany/>
                }
            </div>
        )
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapDispatchToProps,
);
  

export default (compose(withConnect)(Myob));
