import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/invoiceSelector';
// import { makeSelectTitleList } from '../../../../store/selector/titleSelector';
import { makeSelectCommonSearchData, makeSelectCountryList, makeSelectObjectStatusList } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/invoiceAction';
// import { loadTitleListRequest } from '../../../../store/action/titleAction';
import PropTypes from 'prop-types';
import '../../settingMenu/settingMenu.css';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddNewInvoice from './AddNewInvoice';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import Form from 'react-bootstrap/cjs/Form';
import { Link } from 'react-router-dom';
// import { loadJobCardListRequest, loadJobCardListSuccess, loadSelectedJobCardSuccess } from '../../../../store/action/jobCardAction';
// import { makeSelectJobCardList, makeSelectSelectedJobCardSuccess } from '../../../../store/selector/jobCardSelector';
import { loadCustomerListRequest, loadCustomerListSuccess, loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
// import { loadSelectedVehicleSuccess, loadVehicleListRequest, loadVehicleListSuccess } from '../../../../store/action/vehicleAction';
// import { makeSelectSelectedVehicleSuccess, makeSelectVehicleList } from '../../../../store/selector/vehicleSelector';
import { makeSelectCustomerList, makeSelectSelectedCustomerSuccess } from '../../../../store/selector/customerSelector';
import PdfViewer from '../../../layout/form/PdfViewer';
import Pagination from '../../../layout/form/Pagination';
import moment from 'moment';
import SelectField from '../../../layout/form/SelectField';
import { loadObjectStatusRequest, loadObjectStatusSuccess, saveCommonSearchSuccess } from '../../../../store/action/action';
import { loadTenantFinanceRequest } from '../../../../store/action/tenantAction';
import common, { numberWithCommas } from '../../../common';
import DatePicker from '../../../layout/form/datePicker';
import { makeSelectTenantFinance } from '../../../../store/selector/tenantSelector';
import SearchField from '../../../layout/form/SearchField';
import { loadProductListRequest } from '../../../../store/action/productAction';
import EmailViewer from '../../../layout/form/EmailViewer';
import SMS from '../../../layout/form/SMS';
import { makeSelectSMSTemplateList } from '../../../../store/selector/smsTempSelector';
import { loadSMSTemplateListRequest } from '../../../../store/action/smsTempAction';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Email from '../../../layout/form/Email';
import { makeSelectEmailTemplateList } from '../../../../store/selector/emailTempSelector';
import { loadEmailTemplateListRequest } from '../../../../store/action/emailTempAction';
import { loadDashboardSuccess, loadPurchaseOrderSummarySuccess, loadRatingSummarySuccess, loadSalesSummarySuccess } from '../../../../store/action/dashboardAction';


const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

class Invoice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFilterFormOpen: false,
            customer: '',
            invoiceNo: '',
            // invoiceDate:`${prevDate.getFullYear()}-${prevDate.getMonth()+1}-${prevDate.getDate()}`,
            invoiceDate: moment(prevDate).format(common.dateFormat),
            // dueDate:`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()+1}`,
            dueDate: moment(today).format(common.dateFormat),
            isValidationError: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            errorMassage: '',
            isUpdate: false,
            isPrintModalOpen: false,
            showMenu: false,
            pageNumber: 1,
            pageSize: 20,
            jobNumber: '',
            vehicleRegistrationNo: '',
            status: '',
            isNewInvoice: false,
            commonSearch: '',
            isSMSModalOpen: false,
            invoiceEmailDetails: '',
            invoices: [],
            initial: true,
            selectedList: [],
            isEmailModalOpen: false,
            isSelectedEmails: false,
        }

        // this.showMenu = this.showMenu.bind(this);
        // this.closeMenu = this.closeMenu.bind(this);
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { invoiceList } = prevProps;
        if (invoiceList && invoiceList.result && invoiceList.result.length && prevState.initial) {
            return {
                invoices: invoiceList.result,
                initial: false
            }
        }
        return null
    }

    handleModal = (data) => {
        if (data && data.id) {
            this.props.dispatch(actions.loadSelectedInvoiceRequest(data.id))
            this.setState({ selectedRow: data, isUpdate: true })
        } else {
            this.setState({ isNewInvoice: true })
        }
        this.props.dispatch(actions.saveIsInvoiceModalOpenStatus(true))
    }

    handleClose = () => {
        this.setState({ selectedRow: {}, isUpdate: false, isNewInvoice: false, invoiceEmailDetails: '' })
        // this.props.dispatch(actions.loadInvoiceListLoading(false));
        this.props.dispatch(actions.loadSelectedInvoiceSuccess({}))
        this.props.dispatch(actions.saveIsEmailEditorOpenStatus(false))
        this.props.dispatch(loadSelectedCustomerSuccess(''))
        this.props.dispatch(actions.saveIsInvoiceModalOpenStatus(false));
        this.getInvoiceListData()
        this.handleSMSClose()
        this.handleEmailClose()
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadInvoiceListError(''))
        this.props.dispatch(actions.loadSelectedInvoiceSuccess({}))
        this.props.dispatch(actions.saveIsEmailEditorOpenStatus(false))
    }

    successModalClose = () => {
        // this.getInvoiceListData()
        this.props.dispatch(actions.sendInvoiceSuccess(''))
        this.props.dispatch(loadSalesSummarySuccess([]))
        this.props.dispatch(loadDashboardSuccess(''))
        this.props.dispatch(loadRatingSummarySuccess([]))
        this.props.dispatch(loadPurchaseOrderSummarySuccess(''))
        this.handleClose()
    }

    handleCheck = (e, data) => {
        const { invoices, selectedList } = this.state;
        var list = selectedList
        const checked = e.target.checked;
        const invoice = invoices.find(element => element.id === data.id);
        const invoiceIndex = invoices.findIndex(element => element.id === data.id);
        const newInvoice = {
            isSelect: checked,
            id: invoice.id,
            customerEmail: invoice.customerEmail,
            customerId: invoice.customerId,
            customerName: invoice.customerName,
            customerPhone: invoice.customerPhone,
            discountTypeValue: invoice.discountTypeValue,
            discountValue: invoice.discountValue,
            dueDate: invoice.dueDate,
            invoiceDate: invoice.invoiceDate,
            invoiceItems: invoice.invoiceItems,
            invoiceNo: invoice.invoiceNo,
            jobcardId: invoice.jobcardId,
            jobNo: invoice.jobNo,
            netTotal: invoice.netTotal,
            paymentMethodId: invoice.paymentMethodId,
            statusId: invoice.statusId,
            subTotal: invoice.subTotal,
            tax: invoice.tax,
            taxIncPrice: invoice.taxIncPrice,
            taxRate: invoice.taxRate,
            total: invoice.total,
            vehicleId: invoice.vehicleId,
            vehicleRegNo: invoice.vehicleRegNo
        }
        invoices[invoiceIndex] = newInvoice
        if (checked) {
            const selectedInvoice = {
                id: invoice.id,
                destinationNumber: invoice.customerPhone,
                receiverEmail: invoice.customerEmail,
                customerId: invoice.customerId,
            }
            list.push(selectedInvoice)
        } else if (!checked) {
            list = selectedList.filter(element => element.id !== data.id);
        }
        this.setState({
            invoices,
            selectedList: list
        })
    }

    handleCheckAll = (e) => {
        const { invoices, selectedList } = this.state;
        var list = selectedList
        const checked = e.target.checked
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i]
            const element = {
                isSelect: checked,
                id: invoice.id,
                customerEmail: invoice.customerEmail,
                customerId: invoice.customerId,
                customerName: invoice.customerName,
                customerPhone: invoice.customerPhone,
                discountTypeValue: invoice.discountTypeValue,
                discountValue: invoice.discountValue,
                dueDate: invoice.dueDate,
                invoiceDate: invoice.invoiceDate,
                invoiceItems: invoice.invoiceItems,
                invoiceNo: invoice.invoiceNo,
                jobcardId: invoice.jobcardId,
                jobNo: invoice.jobNo,
                netTotal: invoice.netTotal,
                paymentMethodId: invoice.paymentMethodId,
                statusId: invoice.statusId,
                subTotal: invoice.subTotal,
                tax: invoice.tax,
                taxIncPrice: invoice.taxIncPrice,
                taxRate: invoice.taxRate,
                total: invoice.total,
                vehicleId: invoice.vehicleId,
                vehicleRegNo: invoice.vehicleRegNo
            }
            invoices[i] = element
            if (checked) {
                const selectedInvoice = {
                    id: invoice.id,
                    destinationNumber: invoice.customerPhone,
                    receiverEmail: invoice.customerEmail,
                    customerId: invoice.customerId,
                }
                list.push(selectedInvoice)
            } else if (!checked) {
                list = selectedList.filter(item => item.id !== invoice.id);
            }
        }
        this.setState({
            isAllSelect: checked,
            invoices
        })
    }

    sendSMSTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Send SMS
        </Tooltip>
    );

    sendEmailTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Send Email
        </Tooltip>
    );

    handleSelectedEmail = () => {
        if (this.state.selectedList.length === 0) {
            this.setState({ isValidationError: true, errorMassage: 'Please select invoice' })
            return
        }
        this.setState({
            isEmailModalOpen: true,
            isSelectedEmails: true
        })
    }

    handleSelectedSMS = () => {
        if (this.state.selectedList.length === 0) {
            this.setState({ isValidationError: true, errorMassage: 'Please select invoice' })
            return
        }
        this.setState({
            isSMSModalOpen: true,
            isSelectedSMS: true
        })
    }

    handleEmailClose = (details) => {
        const { invoices } = this.state;
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i]
            const element = {
                isSelect: false,
                id: invoice.id,
                customerEmail: invoice.customerEmail,
                customerId: invoice.customerId,
                customerName: invoice.customerName,
                customerPhone: invoice.customerPhone,
                discountTypeValue: invoice.discountTypeValue,
                discountValue: invoice.discountValue,
                dueDate: invoice.dueDate,
                invoiceDate: invoice.invoiceDate,
                invoiceItems: invoice.invoiceItems,
                invoiceNo: invoice.invoiceNo,
                jobcardId: invoice.jobcardId,
                jobNo: invoice.jobNo,
                netTotal: invoice.netTotal,
                paymentMethodId: invoice.paymentMethodId,
                statusId: invoice.statusId,
                subTotal: invoice.subTotal,
                tax: invoice.tax,
                taxIncPrice: invoice.taxIncPrice,
                taxRate: invoice.taxRate,
                total: invoice.total,
                vehicleId: invoice.vehicleId,
                vehicleRegNo: invoice.vehicleRegNo
            }
            invoices[i] = element
        }
        this.setState({
            isEmailModalOpen: false,
            selectedRow: {},
            initial: true,
            isSelectedEmails: false,
            invoices: invoices,
            selectedList: []
        })
    }

    handleAddEmail = (details) => {
        const { isSelectedEmails, selectedList } = this.state;
        if (details.emailAddress === '' && !isSelectedEmails) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.emailSubject === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const list = []
        if (isSelectedEmails) {
            for (let i = 0; i < selectedList.length; i++) {
                const e = selectedList[i]
                const element = {
                    id: e.id,
                    receiverEmail: e.receiverEmail,
                    customerId: e.customerId
                }
                list.push(element)
            }
        }
        else {
            const element = {
                id: details.id,
                receiverEmail: details.emailAddress,
                customerId: details.customerId
            }
            list.push(element)
        }
        const date = moment(details.date).format(common.dateFormat);
        const setDate = date + "T" + details.requestedTime
        const dateTime = new Date(setDate)
        var utcTime = new Date(dateTime).toISOString();
        const parms = {
            EmailBody: encodeURIComponent(details.email),
            Email: list,
            EmailSubject: details.emailSubject,
            RequestedDate: utcTime,
            objectId: common.objectId.invoice
        }
        this.props.dispatch(actions.sendInvoiceNotificationRequest(parms))
    }


    handleSMS = (details) => {
        this.setState({
            isSMSModalOpen: true,
            selectedRow: details
        })
    }

    handleSMSClose = () => {
        this.setState({
            isSMSModalOpen: false,
            selectedRow: {},
        })
    }

    handleAddSMS = (details) => {
        const { isSelectedSMS, selectedList } = this.state;
        if (details.mobile === '' && !isSelectedSMS) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const list = []
        if (isSelectedSMS) {
            for (let i = 0; i < selectedList.length; i++) {
                const e = selectedList[i]
                const element = {
                    id: e.id,
                    destinationNumber: e.destinationNumber,
                    customerId: e.customerId
                }
                list.push(element)
            }
        } else {
            const element = {
                id: details.id,
                destinationNumber: details.mobile,
                customerId: details.customerId
            }
            list.push(element)
        }
        const date = moment(details.date).format(common.dateFormat);
        const setDate = date + "T" + details.requestedTime
        const dateTime = new Date(setDate)
        var utcTime = new Date(dateTime).toISOString();
        const parms = {
            SMSBody: details.sms,
            SMS: list,
            RequestedDate: utcTime,
            objectId: common.objectId.invoice
        }
        this.props.dispatch(actions.sendInvoiceNotificationRequest(parms))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            customer: this.state.customer,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            jobCardId: this.state.jobNumber,
            vehicleId: this.state.vehicleRegistrationNo,
            status: this.state.status,
            commonSearch: '',
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleDateChange = (date, id) => {
        if (date === null && id === "invoiceDate") {
            this.setState({
                [id]: moment(prevDate).format(common.dateFormat),
            })
        }
        else if (date === null && id === "dueDate") {
            this.setState({
                [id]: moment(today).format(common.dateFormat),
            })
        } else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleFilter = () => {
        this.getInvoiceListData()
        this.handleFilterForm()
    }

    handleChangeSearchValue = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen: false,
            customer: '',
            invoiceNo: '',
            jobNumber: '',
            vehicleRegistrationNo: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            pageSize: 20,
            pageNumber: 1,
        })
    }

    handleFilterFormClose = () => {
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
        this.setState({
            isFilterFormOpen: false,
            customer: '',
            invoiceNo: '',
            jobNumber: '',
            vehicleRegistrationNo: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            pageSize: 20,
            pageNumber: 1,
            commonSearch: '',
            initial: true,
            invoices: []
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            customer: '',
            invoiceNo: '',
            jobCardId: '',
            vehicleId: '',
            status: '',
            invoiceDate: moment(prevDate).format(common.dateFormat),
            dueDate: moment(today).format(common.dateFormat),
            commonSearch: ''
        }
        this.props.dispatch(actions.loadInvoiceListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        const { selectedInvoice, tenantFinance, userAccess } = this.props
        if (details.customer === '' ||
            details.printName === '' ||
            details.paymentTerm === '' ||
            details.invoiceDate === '' ||
            details.dueDate === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.total < 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.invoiceAmountMassages })
            return
        }

        const tempItemList = details.tempInvoiceItemsList.filter(data => (data.operation === "D"))
        const invoiceItem = this.handleList(details.invoiceItems.concat(tempItemList))
        const stockHandleList = invoiceItem.filter(data => (data.stockFlg === true))

        const parameters = {
            id: (selectedInvoice && selectedInvoice.id && this.state.isUpdate) ? selectedInvoice.id : '',
            invoiceDate: details.invoiceDate === '' ? null : details.invoiceDate,
            currency: tenantFinance.currencyCode,
            subTotal: details.subTotal === '' ? null : details.subTotal,
            discountType: details.discountType === '' ? null : details.discountType,
            discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
            discountValue: details.discountValue === '' ? null : details.discountValue,
            netTotal: details.subTotal - details.discountValue,
            tax: details.tax === '' ? null : details.tax,
            total: details.total === '' ? null : details.total,
            jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
            statusId: details.status === '' ? null : details.status,
            customerId: details.customer === '' ? 0 : details.customer,
            customerEmail: details.email === '' ? null : details.email,
            referenceNo: details.referenceNo === '' ? null : details.referenceNo,
            customerName: details.printName === '' ? null : details.printName,
            vehicleId: details.vehicleNo === '' ? 0 : details.vehicleNo,
            externalDocumentNo: details.referenceNo === '' ? null : details.referenceNo,
            paymentTermId: details.paymentTerm === '' ? null : details.paymentTerm,
            paymentMethodId: details.paymentMethodId === '' ? null : details.paymentMethodId,
            dueDate: details.dueDate === '' ? null : details.dueDate,
            notes: details.customerNotes === '' ? null : details.customerNotes,
            taxRate: details.selectedTaxRate,
            invoiceItems: invoiceItem,
            taxIncPrice: details.isPriceInclusiveTax,
            stockFlg: stockHandleList && stockHandleList.length > 0 ? true : false
        }
        // console.log(details)
        if (this.state.isUpdate && userAccess && userAccess.modifyInvoice) {
            this.props.dispatch(actions.sendUpdateInvoiceRequest(parameters))
        } else if (userAccess && userAccess.createInvoice) {
            this.props.dispatch(actions.sendAddNewInvoiceRequest(parameters))
        }
    }

    handleList = (data) => {
        if (data.length === 0) {
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.recordIndex = i + 1
            data[i] = element

            if (i === data.length - 1) {
                return (data)
            }
        }
    }

    // updateInvoice = (details) =>{

    // // const parameters = {
    // //     name: details.name,
    // //     id:this.state.selectedRow.id
    // // }
    //     // console.log(details)
    //     // this.props.dispatch(actions.sendUpdateInvoiceRequest(parameters))
    //   }

    // addNewInvoice = (parameters) => {
    // //   const parameters = {
    // //     name: details.name,
    // // }

    // }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteInvoice = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteInvoiceRequest(this.state.selectedRow.id))
    }

    handleSendEmailById = (data) => {
        if (data && data.id && data.customerEmail) {
            // this.props.dispatch(actions.loadInvoiceSendByIdRequest(data.id))
            this.props.dispatch(actions.loadInvoiceEmailRequest(data.id))
            this.props.dispatch(actions.saveIsEmailEditorOpenStatus(true))
        } else {
            this.setState({ isValidationError: true, errorMassage: common.massages.emailMassage })
            //
        }
    }

    handleEmailViewerClose = (data) => {
        this.props.dispatch(actions.loadInvoiceEmailSuccess(null))
        this.props.dispatch(actions.saveIsEmailEditorOpenStatus(false))
        this.props.dispatch(actions.loadSelectedInvoiceSuccess({}))
        this.handleClose()
        this.setState({
            invoiceEmailDetails: {}
        })
    }

    onEditorChange = (editorState) => {
        const { invoiceEmailDetails } = this.state;
        this.setState({
            invoiceEmailDetails: {
                email: editorState,
                emailPdf: invoiceEmailDetails.emailPdf,
                id: invoiceEmailDetails.id,
                receiverEmail: invoiceEmailDetails.receiverEmail,
                senderEmail: invoiceEmailDetails.senderEmail,
                emailSubject: invoiceEmailDetails.emailSubject,
                emailTemplate: invoiceEmailDetails.emailTemplate
            }
        })
    };

    handleChangeEmailDetails = (e) => {
        const { invoiceEmailDetails } = this.state;
        if (e.target.id === "emailSubject") {
            this.setState({
                invoiceEmailDetails: {
                    email: invoiceEmailDetails.email,
                    emailPdf: invoiceEmailDetails.emailPdf,
                    id: invoiceEmailDetails.id,
                    receiverEmail: invoiceEmailDetails.receiverEmail,
                    senderEmail: invoiceEmailDetails.senderEmail,
                    emailSubject: e.target.value,
                    emailTemplate: invoiceEmailDetails.emailTemplate
                }
            })
        } else if (e.target.id === "receiverEmail") {
            this.setState({
                invoiceEmailDetails: {
                    email: invoiceEmailDetails.email,
                    emailPdf: invoiceEmailDetails.emailPdf,
                    id: invoiceEmailDetails.id,
                    receiverEmail: e.target.value,
                    senderEmail: invoiceEmailDetails.senderEmail,
                    emailSubject: invoiceEmailDetails.emailSubject,
                    emailTemplate: invoiceEmailDetails.emailTemplate
                }
            })
        }
    }

    handleSendInvoiceEmail = () => {
        const { invoiceEmailDetails } = this.state;
        const emailContent = invoiceEmailDetails.email.replace('h3', 'h2').replace('margin: 1% 5%', 'margin: 2% 20%')
        const email = invoiceEmailDetails.emailTemplate.replace('[email_content]', emailContent)
        const parameters = {
            email: encodeURIComponent(email),
            receiverEmail: invoiceEmailDetails.receiverEmail,
            senderEmail: invoiceEmailDetails.senderEmail,
            emailSubject: invoiceEmailDetails.emailSubject,
            id: invoiceEmailDetails.id
        }
        this.props.dispatch(actions.sendInvoiceEmailRequest(parameters))
    }

    handlePrintById = (data) => {
        this.setState({ isPrintModalOpen: true })
        this.props.dispatch(actions.loadInvoicePrintByIdRequest(data.id))
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
            const desc = objectStatusList.find(select => select.id === data.statusId)
            return (desc && desc.description ? desc.description : '')
        }
    }

    setPaymentMethod = (data) => {
        const { paymentMethodList } = this.props;
        if (paymentMethodList && paymentMethodList.length && paymentMethodList.length !== 0) {
            const desc = paymentMethodList.find(select => select.id === data.paymentMethodId)
            return (desc && desc.description ? desc.description : '')
        }
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    handlePrint = (details) => {
        const { selectedInvoice, tenantFinance, userAccess } = this.props
        if (details.customer === '' ||
            details.printName === '' ||
            details.paymentTerm === '' ||
            details.invoiceDate === '' ||
            details.dueDate === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.total < 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.invoiceAmountMassages })
            return
        }

        const tempItemList = details.tempInvoiceItemsList.filter(data => (data.operation === "D"))
        const invoiceItem = this.handleList(details.invoiceItems.concat(tempItemList))
        const stockHandleList = invoiceItem.filter(data => (data.stockFlg === true))

        if (this.state.isUpdate && userAccess && userAccess.modifyInvoice) {
            const parameters = {
                id: (selectedInvoice && selectedInvoice.id && this.state.isUpdate) ? selectedInvoice.id : null,
                invoiceDate: details.invoiceDate === '' ? null : details.invoiceDate,
                currency: tenantFinance.currencyCode,
                subTotal: details.subTotal === '' ? null : details.subTotal,
                discountType: details.discountType === '' ? null : details.discountType,
                discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
                discountValue: details.discountValue === '' ? null : details.discountValue,
                netTotal: details.subTotal - details.discountValue,
                tax: details.tax === '' ? null : details.tax,
                total: details.total === '' ? null : details.total,
                jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
                statusId: details.status === '' ? null : details.status,
                customerId: details.customer === '' ? 0 : details.customer,
                customerEmail: details.email === '' ? null : details.email,
                referenceNo: details.referenceNo === '' ? null : details.referenceNo,
                customerName: details.printName === '' ? null : details.printName,
                vehicleId: details.vehicleNo === '' ? 0 : details.vehicleNo,
                externalDocumentNo: details.referenceNo === '' ? null : details.referenceNo,
                paymentTermId: details.paymentTerm === '' ? null : details.paymentTerm,
                paymentMethodId: details.paymentMethodId === '' ? null : details.paymentMethodId,
                dueDate: details.dueDate === '' ? null : details.dueDate,
                notes: details.customerNotes === '' ? null : details.customerNotes,
                taxRate: details.selectedTaxRate,
                invoiceItems: invoiceItem,
                taxIncPrice: details.isPriceInclusiveTax,
                stockFlg: stockHandleList && stockHandleList.length > 0 ? true : false
            }
            this.props.dispatch(actions.loadInvoicePrintRequest(parameters))
        } else if (userAccess && userAccess.createInvoice) {
            const parameters = {
                id: (selectedInvoice && selectedInvoice.id && this.state.isUpdate) ? selectedInvoice.id : null,
                invoiceDate: details.invoiceDate === '' ? null : details.invoiceDate,
                currency: tenantFinance.currencyCode,
                subTotal: details.subTotal === '' ? null : details.subTotal,
                discountType: details.discountType === '' ? null : details.discountType,
                discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
                discountValue: details.discountValue === '' ? null : details.discountValue,
                netTotal: details.subTotal - details.discountValue,
                tax: details.tax === '' ? null : details.tax,
                total: details.total === '' ? null : details.total,
                jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
                statusId: details.status === '' ? null : details.status,
                customerId: details.customer === '' ? 0 : details.customer,
                customerEmail: details.email === '' ? null : details.email,
                referenceNo: details.referenceNo === '' ? null : details.referenceNo,
                customerName: details.printName === '' ? null : details.printName,
                vehicleId: details.vehicleNo === '' ? 0 : details.vehicleNo,
                externalDocumentNo: details.referenceNo === '' ? null : details.referenceNo,
                paymentTermId: details.paymentTerm === '' ? null : details.paymentTerm,
                paymentMethodId: details.paymentMethodId === '' ? null : details.paymentMethodId,
                dueDate: details.dueDate === '' ? null : details.dueDate,
                notes: details.customerNotes === '' ? null : details.customerNotes,
                taxRate: details.selectedTaxRate,
                invoiceItems: invoiceItem,
                taxIncPrice: details.isPriceInclusiveTax,
                stockFlg: stockHandleList && stockHandleList.length > 0 ? true : false
            }
            this.props.dispatch(actions.loadAddAndPrintInvoiceRequest(parameters))
        }

        this.setState({ isPrintModalOpen: true })
    }

    printModalClose = () => {
        this.setState({ isPrintModalOpen: false })
        this.props.dispatch(actions.loadInvoicePrintSuccess(''))
        this.handleClose()
    }

    handleSendEmail = (details) => {
        const { selectedInvoice, tenantFinance, userAccess } = this.props
        if (details.customer === '' ||
            details.printName === '' ||
            details.paymentTerm === '' ||
            details.invoiceDate === '' ||
            details.dueDate === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        if (details.total < 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.invoiceAmountMassages })
            return
        }

        const tempItemList = details.tempInvoiceItemsList.filter(data => (data.operation === "D"))
        const invoiceItem = this.handleList(details.invoiceItems.concat(tempItemList))
        const stockHandleList = invoiceItem.filter(data => (data.stockFlg === true))

        if (this.state.isUpdate && userAccess && userAccess.modifyInvoice) {
            const parameters = {
                id: (selectedInvoice && selectedInvoice.id && this.state.isUpdate) ? selectedInvoice.id : null,
                invoiceDate: details.invoiceDate === '' ? null : details.invoiceDate,
                currency: tenantFinance.currencyCode,
                subTotal: details.subTotal === '' ? null : details.subTotal,
                discountType: details.discountType === '' ? null : details.discountType,
                discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
                discountValue: details.discountValue === '' ? null : details.discountValue,
                netTotal: details.subTotal - details.discountValue,
                tax: details.tax === '' ? null : details.tax,
                total: details.total === '' ? null : details.total,
                jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
                statusId: details.status === '' ? null : details.status,
                customerId: details.customer === '' ? 0 : details.customer,
                customerEmail: details.email === '' ? null : details.email,
                referenceNo: details.referenceNo === '' ? null : details.referenceNo,
                customerName: details.printName === '' ? null : details.printName,
                vehicleId: details.vehicleNo === '' ? 0 : details.vehicleNo,
                externalDocumentNo: details.referenceNo === '' ? null : details.referenceNo,
                paymentTermId: details.paymentTerm === '' ? null : details.paymentTerm,
                paymentMethodId: details.paymentMethodId === '' ? null : details.paymentMethodId,
                dueDate: details.dueDate === '' ? null : details.dueDate,
                notes: details.customerNotes === '' ? null : details.customerNotes,
                taxRate: details.selectedTaxRate,
                invoiceItems: invoiceItem,
                taxIncPrice: details.isPriceInclusiveTax,
                stockFlg: stockHandleList && stockHandleList.length > 0 ? true : false
            }
            this.props.dispatch(actions.loadInvoiceSendRequest(parameters))
        } else if (userAccess && userAccess.createInvoice) {
            const parameters = {
                id: (selectedInvoice && selectedInvoice.id && this.state.isUpdate) ? selectedInvoice.id : null,
                invoiceDate: details.invoiceDate === '' ? null : details.invoiceDate,
                currency: tenantFinance.currencyCode,
                subTotal: details.subTotal === '' ? null : details.subTotal,
                discountType: details.discountType === '' ? null : details.discountType,
                discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
                discountValue: details.discountValue === '' ? null : details.discountValue,
                netTotal: details.subTotal - details.discountValue,
                tax: details.tax === '' ? null : details.tax,
                total: details.total === '' ? null : details.total,
                jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
                statusId: details.status === '' ? null : details.status,
                customerId: details.customer === '' ? 0 : details.customer,
                customerEmail: details.email === '' ? null : details.email,
                referenceNo: details.referenceNo === '' ? null : details.referenceNo,
                customerName: details.printName === '' ? null : details.printName,
                vehicleId: details.vehicleNo === '' ? 0 : details.vehicleNo,
                externalDocumentNo: details.referenceNo === '' ? null : details.referenceNo,
                paymentTermId: details.paymentTerm === '' ? null : details.paymentTerm,
                paymentMethodId: details.paymentMethodId === '' ? null : details.paymentMethodId,
                dueDate: details.dueDate === '' ? null : details.dueDate,
                notes: details.customerNotes === '' ? null : details.customerNotes,
                taxRate: details.selectedTaxRate,
                invoiceItems: invoiceItem,
                taxIncPrice: details.isPriceInclusiveTax,
                stockFlg: stockHandleList && stockHandleList.length > 0 ? true : false
            }
            this.props.dispatch(actions.loadAddAndSendInvoiceRequest(parameters))
        }
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    getInvoiceListData = () => {
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
        var customer = null
        var vehicle = null
        var jobCard = null
        if (this.props.commonSearchData && this.props.commonSearchData.component && this.props.commonSearchData.component === 'customer') {
            customer = this.props.commonSearchData.id
            this.setState({
                customer: customer
            })
        }

        if (this.props.commonSearchData && this.props.commonSearchData.component && this.props.commonSearchData.component === 'vehicle') {
            vehicle = this.props.commonSearchData.id
            this.setState({
                vehicleRegistrationNo: vehicle
            })
        }

        if (this.props.commonSearchData && this.props.commonSearchData.component && this.props.commonSearchData.component === 'jobCard') {
            jobCard = this.props.commonSearchData.id
            this.setState({
                jobCardId: jobCard
            })
        }
        this.props.dispatch(saveCommonSearchSuccess({}))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            customer: customer === null ? this.state.customer : customer,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            jobCardId: jobCard === null ? this.state.jobNumber : jobCard,
            vehicleId: vehicle === null ? this.state.vehicleRegistrationNo : vehicle,
            status: this.state.status,
            commonSearch: this.state.commonSearch
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            invoices: [],
            initial: true
        })
        this.props.dispatch(actions.loadInvoiceListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            customer: this.state.customer,
            invoiceNo: this.state.invoiceNo,
            invoiceDate: this.state.invoiceDate,
            dueDate: this.state.dueDate,
            jobCardId: this.state.jobNumber,
            vehicleId: this.state.vehicleRegistrationNo,
            status: this.state.status,
            commonSearch: value
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            commonSearch: value,
            invoices: [],
            initial: true
        })
        this.props.dispatch(actions.loadInvoiceListRequest(parameters))
    }

    redirectCustomer = () => {
        this.props.dispatch(loadCustomerListSuccess([]))
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
    }

    redirectQuotation = () => {
        this.props.dispatch(loadCustomerListSuccess([]))
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
    }

    redirectReports = () => {
        this.props.dispatch(loadCustomerListSuccess([]))
        this.props.dispatch(actions.loadInvoiceListSuccess([]))
    }

    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadObjectStatusSuccess({}))
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            Customer: '',
            VehicleRegNo: '',
            JobNo: '',
            Status: '',
            description: '',
            name: '',
            email: '',
            mobile: '',
            city: '',
            makeId: '',
            Make: '',
            Model: '',
            Search: '',
            status: '',
            codeId: '',
            categoryId: '',
            partId: '',
            commonSearch: '',
            sku: '',
            purchasingFlg: false
        }
        this.getInvoiceListData()
        this.props.dispatch(actions.saveIsInvoiceModalOpenStatus(false))
        this.props.dispatch(loadCustomerListRequest(parameters))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.invoice));
        this.props.dispatch(actions.loadPaymentTermRequest())
        this.props.dispatch(loadTenantFinanceRequest(id))
        this.props.dispatch(loadProductListRequest(parameters))
        this.props.dispatch(loadSMSTemplateListRequest(parameters));
        this.props.dispatch(loadEmailTemplateListRequest(parameters));
        this.props.dispatch(actions.loadPaymentMethodRequest());
        // this.props.dispatch(loadTitleListRequest(parameters))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.invoiceSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }

        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getInvoiceListData()
        }

        if (!this.props.isAddNewInvoiceModalOpen &&
            (this.props.selectedCustomer !== '')) {
            this.props.dispatch(actions.saveIsInvoiceModalOpenStatus(true));
        }

        if (this.props.isEmailEditorOpen && this.props.invoiceEmail !== null && this.props.invoiceEmail.id && this.props.invoiceEmail.id > 0) {
            var email = decodeURIComponent(this.props.invoiceEmail.email)
            var emailTemplate = decodeURIComponent(this.props.invoiceEmail.emailTemplate)
            this.setState({
                invoiceEmailDetails: {
                    email: email,
                    emailPdf: this.props.invoiceEmail.emailPdf.byteCode,
                    id: this.props.invoiceEmail.id,
                    receiverEmail: this.props.invoiceEmail.receiverEmail,
                    senderEmail: this.props.invoiceEmail.senderEmail,
                    emailSubject: this.props.invoiceEmail.emailSubject,
                    emailTemplate: emailTemplate
                }
            })
            this.props.dispatch(actions.loadInvoiceEmailSuccess(null))
        }
    }

    render() {

        const {
            isAddNewInvoiceModalOpen,
            customerList,
            invoiceList,
            isInvoiceListLoading,
            invoiceListError,
            invoiceSuccess,
            objectStatusList,
            countryList,
            selectedInvoice,
            invoicePrint,
            paymentTermList,
            tenantFinance,
            selectedCustomer,
            isEmailEditorOpen,
            smsTemplateList,
            paymentMethodList,
            userAccess,
            emailTemplateList
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            isPrintModalOpen,
            isNewInvoice,
            invoiceEmailDetails,
            isSMSModalOpen,
            invoices,
            isEmailModalOpen,
            selectedList,
            isSelectedSMS
            // selectedInvoiceId
        } = this.state;

        const columns = [{
            Header: () => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckAll(e)} checked={this.state.isAllSelect} />
                    </Form.Group>
                </div>
            ),
            accessor: 'select',
            width: "3%",
            Cell: ((row) => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheck(e, row.row.original)} checked={row.row.original.isSelect} />
                    </Form.Group>
                </div>
            ))
        }, {
            Header: 'Invoice No',
            accessor: 'invoiceNo',
            width: "10%",
        }, {
            Header: 'Date',
            accessor: 'invoiceDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.invoiceDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.invoiceDate)}
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Customer',
            accessor: 'customerName',
        }, {
            Header: 'Payment method',
            accessor: 'paymentMethodId',
            Cell: ((row) => (
                <div >
                    {row.row.original.paymentMethodId ? this.setPaymentMethod(row.row.original) : 'Unknown'}
                </div>
            )),
            width: "10%"
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            // Header: 'Amount',
            accessor: 'total',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
        }, {
            Header: 'Status',
            // accessor: 'statusId',
            Cell: ((row) => (
                <div style={{ padding: '2px' }}>
                    {row.row.original.statusId === "10" ?
                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.statusId === "20" ?
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                            <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {/* <Button */}
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        {/* <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>   */}
                        <span className="dropdown" style={{ float: 'right' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={() => this.handleSendEmailById(row.row.original)}><ui5-icon class="samples-margin" name="email" id="action-icon"></ui5-icon> Email</li>
                                <li onClick={() => this.handlePrintById(row.row.original)}><ui5-icon class="samples-margin" name="print" id="action-icon"></ui5-icon> Print</li>
                                {userAccess && userAccess.deleteInvoice ? <li onClick={() => this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="cancel" id="action-icon"></ui5-icon> Cancel</li> : ''}
                                <li onClick={() => this.handleSMS(row.row.original)}><ui5-icon class="samples-margin" name="comment" id="action-icon"></ui5-icon> Send sms</li>
                            </div>
                        </span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {(isInvoiceListLoading ||
                    (invoiceList && invoiceList.result && invoiceList.result.length && invoiceList.result.length === 0)) ?
                    <Loader show={isInvoiceListLoading} /> : ''}

                {(isValidationError || invoiceListError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : invoiceListError}
                        handleClose={this.errorModalClose}
                    />}

                {(invoiceSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={invoiceSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteInvoice}
                        title={selectedRow.invoiceNo}
                    />}
                {!isAddNewInvoiceModalOpen &&
                    <div className="content-header">
                        <div className="header-pages">
                            <span><Link to='./customer' onClick={() => this.redirectCustomer()}><ui5-icon class="samples-margin" name="customer" id="tab-icon"></ui5-icon> Customer</Link></span>
                            <span><Link to='./quotation' onClick={() => this.redirectQuotation()}><ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Quotation</Link></span>
                            <span><Link to='./invoice'><ui5-icon class="samples-margin" name="monitor-payments" id="tab-icon"></ui5-icon> Invoice</Link></span>
                        </div>
                        <div className="header-btn">
                            <ButtonGroup
                                primaryBtnClick={this.handleModal}
                                secondaryBtnClick={this.handleFilterForm}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Add New'
                                primaryBtnIcon='sys-add'
                                primaryBtnDisabled={userAccess && !userAccess.createInvoice}
                                secondaryBtnText='Filter'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='filter'
                            />
                        </div>
                    </div>}
                <div className="body-content">
                    {isAddNewInvoiceModalOpen ?
                        <AddNewInvoice
                            isAddNewInvoiceModalOpen={isAddNewInvoiceModalOpen}
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveAddNew}
                            selectedRow={selectedRow}
                            customerList={customerList.result}
                            objectStatusList={objectStatusList}
                            countryList={countryList}
                            selectedInvoice={selectedInvoice}
                            isUpdate={this.state.isUpdate}
                            handlePrint={this.handlePrint}
                            isPrintModalOpen={isPrintModalOpen}
                            printModalClose={this.printModalClose}
                            handleSendEmail={this.handleSendEmail}
                            invoicePrint={invoicePrint}
                            paymentTermList={paymentTermList}
                            tenantFinance={tenantFinance}
                            selectedCustomer={selectedCustomer}
                            isNewInvoice={isNewInvoice}
                            isEmailEditorOpen={isEmailEditorOpen}
                            handleEmailViewerClose={this.handleEmailViewerClose}
                            invoiceEmailDetails={invoiceEmailDetails}
                            onEditorChange={this.onEditorChange}
                            handleSendInvoiceEmail={this.handleSendInvoiceEmail}
                            handleChangeEmailDetails={this.handleChangeEmailDetails}
                            paymentMethodList={paymentMethodList}
                            userAccess={userAccess}
                            isInvoiceListLoading={isInvoiceListLoading}
                        /> :
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <h5 style={{ marginLeft: '5px' }}>Invoice List</h5>
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <SearchField
                                            onClickSearch={this.getInvoiceListData}
                                            controlId="commonSearch"
                                            onChange={this.handleChangeSearchValue}
                                            defaultValue={this.state.commonSearch}
                                            onPressKeySearch={this.onPressKeySearch}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', margin: '10px 10px -15px 0' }}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.sendEmailTooltip}
                                    >
                                        <span className="add-icon" style={{ marginRight: '10px' }} onClick={() => this.handleSelectedEmail()}>
                                            <ui5-icon class="samples-margin" name="email" id="table-icon"></ui5-icon>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.sendSMSTooltip}
                                    >
                                        <span className="add-icon" onClick={() => this.handleSelectedSMS()}>
                                            <ui5-icon class="samples-margin" name="comment" id="table-icon"></ui5-icon>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            {isFilterFormOpen &&
                                <div className="filter-form">
                                    <div className="filter-header">
                                        <h5>Filter Form</h5>
                                        <div className="btn-close" onClick={this.handleFilterFormClose} >
                                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm">
                                            <TextField
                                                text="Invoice no"
                                                controlId="invoiceNo"
                                                placeholder="Invoice no"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.invoiceNo}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <SelectField
                                                text="Status"
                                                controlId="status"
                                                onChange={(e) => this.handleChangeSelect(e, 'status')}
                                                options={objectStatusList}
                                                selectedValue={this.state.status}
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <SelectField
                                                text="Customer"
                                                controlId="customer"
                                                onChange={(e) => this.handleChangeSelect(e, 'customer')}
                                                options={
                                                    customerList.result && customerList.result.length && customerList.result.length > 0 ?
                                                        customerList.result.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                            1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                                selectedValue={this.state.customer}
                                                display="displayName"
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Invoice Date"
                                                controlId="invoiceDate"
                                                onChange={(e) => this.handleDateChange(e, "invoiceDate")}
                                                value={new Date(this.state.invoiceDate)}
                                            // format={dateFormat}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="Due Date"
                                                controlId="dueDate"
                                                onChange={(e) => this.handleDateChange(e, "dueDate")}
                                                value={new Date(this.state.dueDate)}
                                            // format={dateFormat}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-btns">
                                        <ButtonGroup
                                            primaryBtnClick={this.handleFilter}
                                            primaryBtnIcon='search'
                                            primaryBtnText='Search'
                                            primaryBtnVariant="outline-primary"
                                            secondaryBtnClick={this.handleFilterFormClose}
                                            secondaryBtnIcon='clear-filter'
                                            secondaryBtnText='Clear'
                                            secondaryBtnVariant="outline-primary"
                                        />
                                    </div>
                                </div>}
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={invoices}
                                />
                            </div>
                            <Pagination
                                currentPage={invoiceList.page ? invoiceList.page : this.state.pageNumber}
                                getNextPage={this.getNextPage}
                                getPrevPage={this.getPrevPage}
                                totalPage={invoiceList.totalPages}
                                setClickedPage={this.setClickedPage}
                                currentPageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                            {
                                isPrintModalOpen && invoicePrint !== '' &&
                                <PdfViewer
                                    show={isPrintModalOpen}
                                    pdf={invoicePrint}
                                    onHide={this.printModalClose}
                                    title="Invoice"
                                />
                            }
                            {
                                isEmailEditorOpen &&
                                <EmailViewer
                                    show={isEmailEditorOpen}
                                    onHide={this.handleEmailViewerClose}
                                    title="Invoice"
                                    editorState={invoiceEmailDetails && invoiceEmailDetails.email && invoiceEmailDetails.email.replace('margin: 2% 20%', 'margin: 1% 5%').replace('h2', 'h3')}
                                    // emailView = {invoiceEmailDetails && invoiceEmailDetails.emailTemplate &&
                                    // invoiceEmailDetails.emailTemplate.replace('margin: 2% 20%', 'margin: 1% 5%').replace('[email_content]', invoiceEmailDetails.emailContent).replace('h2','h3')}
                                    onEditorStateChange={this.onEditorChange}
                                    handleSendEmail={this.handleSendInvoiceEmail}
                                    invoiceEmailDetails={invoiceEmailDetails}
                                    handleChangeEmailDetails={this.handleChangeEmailDetails}
                                />
                            }
                            {isSMSModalOpen &&
                                <SMS
                                    isModalOpen={isSMSModalOpen}
                                    isSelectedSMS={isSelectedSMS}
                                    handleModalClose={this.handleSMSClose}
                                    data={selectedRow}
                                    handleSaveAddNew={this.handleAddSMS}
                                    smsTemplateList={smsTemplateList.result && smsTemplateList.result.length ? smsTemplateList.result : []}
                                />}
                            {isEmailModalOpen &&
                                <Email
                                    isModalOpen={isEmailModalOpen}
                                    handleModalClose={this.handleEmailClose}
                                    data={selectedRow}
                                    handleSaveAddNew={this.handleAddEmail}
                                    selectedList={selectedList}
                                    isSelectedEmails={this.state.isSelectedEmails}
                                    emailTemplateList={emailTemplateList.result && emailTemplateList.result.length ? emailTemplateList.result : []}
                                />}
                        </div>
                    }

                </div>

            </div>
        )
    }
}

Invoice.propTypes = {
    isAddNewInvoiceModalOpen: PropTypes.bool,
    invoiceList: PropTypes.any,
    customerList: PropTypes.any,
    isInvoiceListLoading: PropTypes.bool,
    invoiceListError: PropTypes.any,
    invoiceSuccess: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedInvoice: PropTypes.any,
    jobCardList: PropTypes.any,
    vehicleList: PropTypes.any,
    invoicePrint: PropTypes.any,
    paymentTermList: PropTypes.any,
    tenantFinance: PropTypes.any,
    selectedCustomer: PropTypes.any,
    selectedVehicle: PropTypes.any,
    selectedJobCard: PropTypes.any,
    invoiceEmail: PropTypes.any,
    isEmailEditorOpen: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isAddNewInvoiceModalOpen: selectors.makeSelectInvoicePopupOpen(),
    invoiceList: selectors.makeSelectInvoiceList(),
    isInvoiceListLoading: selectors.makeSelectInvoiceListLoading(),
    invoiceListError: selectors.makeSelectInvoiceListError(),
    invoiceSuccess: selectors.makeSelectInvoiceSuccess(),
    objectStatusList: makeSelectObjectStatusList(),
    countryList: makeSelectCountryList(),
    customerList: makeSelectCustomerList(),
    selectedInvoice: selectors.makeSelectSelectedInvoiceSuccess(),
    invoicePrint: selectors.makeSelectInvoicePrintSuccess(),
    paymentTermList: selectors.makeSelectPaymentTermSuccess(),
    tenantFinance: makeSelectTenantFinance(),
    selectedCustomer: makeSelectSelectedCustomerSuccess(),
    commonSearchData: makeSelectCommonSearchData(),
    invoiceEmail: selectors.makeSelectInvoiceEmailSuccess(),
    isEmailEditorOpen: selectors.makeSelectSaveIsEmailEditorOpen(),
    smsTemplateList: makeSelectSMSTemplateList(),
    paymentMethodList: selectors.makeSelectPaymentMethodList(),
    userAccess: makeSelectUserAccessSuccess(),
    emailTemplateList: makeSelectEmailTemplateList(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Invoice));