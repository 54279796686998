import { put, call } from 'redux-saga/effects';
import {
  addNewReminderRequest, 
  deleteReminderRequest, 
  getReminderList, 
  getUnconfirmedReminderRequest, 
  updateReminderRequest,
} from '../service/reminderService';
import * as actions from '../../store/action/reminderAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewReminderRequest(data){
  yield put(actions.loadReminderListError(''))
  try{
    const response = yield call(addNewReminderRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      // console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendReminderSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadReminderListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewReminderLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewReminderLoading(false));
    // console.log(error.response.data.detail)
    const massage = common.error
    yield put(actions.loadReminderListError(massage))
  }
}

export function* loadReminderListRequest(data){
  // console.log('loadReminderListRequest')
  yield put(actions.loadUnconfirmedReminderListRequest())
  yield put(actions.loadReminderListError(''))
  try{
    yield put(actions.loadReminderListLoading(true));
    const response = yield call(getReminderList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const bookingList = response.data.result
      yield put(actions.loadReminderListSuccess(bookingList))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadReminderListLoading(false));
      const massage = common.error
      yield put(actions.loadReminderListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadReminderListLoading(false));
  }catch(error){
    yield put(actions.loadReminderListLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadReminderListError(massage))
  }
}

export function* sendDeleteReminderRequest(data){
  // console.log('sendDeleteReminderRequest')
  yield put(actions.loadReminderListError(''))
  try{
    yield put(actions.loadReminderListLoading(true));
    const response = yield call(deleteReminderRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendReminderSuccess(massage)) 
    } 
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadReminderListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadReminderListLoading(false));
  }catch(error){
    yield put(actions.loadReminderListLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadReminderListError(massage))
  }
}

export function* sendUpdateReminderRequest(data){
  // console.log('sendUpdateReminderRequest')
  yield put(actions.loadReminderListError(''))
  try{
    // yield put(actions.sendUpdateReminderLoading(true));
    const response = yield call(updateReminderRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendReminderSuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      const massage = response.data.message
      yield put(actions.loadReminderListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateReminderLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateReminderLoading(false));
    // console.log(error.response)
    const massage = common.error
    yield put(actions.loadReminderListError(massage))
  }
}


export function* loadUnconfirmedReminderListRequest(){
  yield put(actions.loadReminderListError(''))
  try{
    const response = yield call(getUnconfirmedReminderRequest);
    if(response && response.status && response.status === 200 ){
      const booking = response.data
      yield put(actions.loadUnconfirmedReminderListSuccess(booking))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
  }catch(error){
    console.log(error)
  }
}


