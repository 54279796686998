const PATH = 'app/Supplier';

export const SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS`;
export const LOAD_SUPPLIER_LIST_REQUEST = `${PATH}LOAD_SUPPLIER_LIST_REQUEST`;
export const LOAD_SUPPLIER_LIST_ERROR = `${PATH}LOAD_SUPPLIER_LIST_ERROR`;
export const LOAD_SUPPLIER_LIST_LOADING = `${PATH}LOAD_SUPPLIER_LIST_LOADING`;
export const LOAD_SUPPLIER_LIST_SUCCESS = `${PATH}LOAD_SUPPLIER_LIST_SUCCESS`;
export const SEND_ADD_NEW_SUPPLIER_REQUEST = `${PATH}SEND_ADD_NEW_SUPPLIER_REQUEST`;
export const SEND_SUPPLIER_SUCCESS = `${PATH}SEND_SUPPLIER_SUCCESS`;
export const SEND_UPDATE_SUPPLIER_REQUEST = `${PATH}SEND_UPDATE_SUPPLIER_REQUEST`;
export const SEND_DELETE_SUPPLIER_REQUEST = `${PATH}SEND_DELETE_SUPPLIER_REQUEST`;
export const LOAD_SELECTED_SUPPLIER_REQUEST = `${PATH}LOAD_SELECTED_SUPPLIER_REQUEST`;
export const LOAD_SELECTED_SUPPLIER_SUCCESS = `${PATH}LOAD_SELECTED_SUPPLIER_SUCCESS`;