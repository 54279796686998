import { fromJS } from 'immutable';
import {
  LOAD_DASHBOARD_ERROR,
  LOAD_DASHBOARD_LOADING,
  LOAD_DASHBOARD_SUCCESS,
  LOAD_PURCHASING_SUMMARY_SUCCESS,
  LOAD_RATING_SUMMARY_SUCCESS,
  LOAD_SALES_SUMMARY_SUCCESS,
} from '../constant/dashboardConstant';

export const initialState = fromJS({
    isDashboardLoading:false,
    dashboardError:'',
    dashboadDetails:'',
    salesSummary:[],
    ratingSummary:[],
    purchaseOrderSummary:''
});

function dashboadReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DASHBOARD_SUCCESS:
      return state.set('dashboadDetails', action.payload)
    case LOAD_DASHBOARD_LOADING:
      return state.set('isDashboardLoading', action.payload)
    case LOAD_DASHBOARD_ERROR:
      return state.set('dashboardError', action.payload)
    case LOAD_SALES_SUMMARY_SUCCESS:
      return state.set('salesSummary', action.payload)
    case LOAD_RATING_SUMMARY_SUCCESS:
      return state.set('ratingSummary', action.payload)
    case LOAD_PURCHASING_SUMMARY_SUCCESS:
        return state.set('purchaseOrderSummary', action.payload)
    default:
      return state;
  }
}

export default dashboadReducer;
