import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUOMList } from '../../../../store/selector/selector';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import Label from 'react-bootstrap/cjs/FormLabel';
import FormControl from 'react-bootstrap/cjs/FormControl';
import FormGroup from 'react-bootstrap/cjs/FormGroup';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
// import { makeSelectRepairList } from '../../../../store/selector/repairSelector';
// import { makeSelectPartList } from '../../../../store/selector/partSelector';
import Table from '../../../layout/form/Table';
// import { makeSelectInvoicePrintSuccess } from '../../../../store/selector/invoiceSelector';
import PdfViewer from '../../../layout/form/PdfViewer';
// import { loadSelectedJobCardRequest, loadSelectedJobCardSuccess } from '../../../../store/action/jobCardAction';
// import { makeSelectSelectedJobCardSuccess } from '../../../../store/selector/jobCardSelector';
import DatePicker from '../../../layout/form/datePicker';
import moment from 'moment';
import common from '../../../common';
import { makeSelectProductList } from '../../../../store/selector/productSelector';
// import { loadSelectedVehicleSuccess } from '../../../../store/action/vehicleAction';
// import WarningModal from '../../../layout/form/warningModal';


const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};

const customStylesDiscount = {
    control: base => ({
        ...base,
        height: 30,
        minHeight: 38,
        marginBottom: 0,
        marginTop: -20
    })
};

//   function setInvoiceItems(list, SalesCategoryId) {
//     var itemsList =  []
//     var itemTotal = 0
//     if(list.length === 0){
//         itemsList = []
//     }else{
//         for (let i = 0; i < list.length; i++) {
//             const element = list[i];
//             itemTotal = itemTotal+(element.unitPrice*element.estimatedQuantity)

//             const newRow = {
//                 'recordIndex':'', 
//                 // "id": element.id,
//                 "parentItemId":element.parentItemId ? element.parentItemId : '',
//                 "salesCategoryId": SalesCategoryId ,
//                 "referenceItemId": element.referenceItemId ? element.referenceItemId : '',
//                 "description": element.description,
//                 "unitOfMeasure": element.unitOfMeasure,
//                 "quantity": element.estimatedQuantity ? element.estimatedQuantity : 0.00,
//                 "price": element.unitPrice ? element.unitPrice : 0.00,
//                 "subTotal": (element.estimatedQuantity !== 0 && element.unitPrice !== 0) ? element.estimatedQuantity*element.unitPrice : 0.00,
//                 "operation":"U",
//                 "tax": element.tax ? element.tax : ''
//             }
//             itemsList.push(newRow)
//         }
//     }
//     return itemsList
// }

export class AddNewJobCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: '',
            jobCardNo: '',
            vehicleNo: '',
            customer: '',
            printName: '',
            email: '',
            invoiceDate: '',
            invoiceNo: '',
            paymentTerm: '',
            dueDate: '',
            referenceNo: '',
            invoiceItems: [],
            isDelete: false,
            subTotal: 0,
            discountValue: 0,
            tax: 0,
            total: 0,
            discountTypeValue: 0,
            discountType: '',
            customerNotes: '',
            initial: true,
            tempInvoiceItemsList: [],
            selectedJobCardId: '',
            // isChangedJobCard:false,
            selectedTaxRate: 0,
            isUserAccess: false,
            paymentMethodId: '',
            // isWarning:false,
            // selectedJobCardDetails:''
        }
    }


    static getDerivedStateFromProps(prevProps, prevState) {
        const {
            selectedInvoice, isUpdate, selectedRow,
            objectStatusList, paymentTermList, tenantFinance,
            selectedCustomer, isNewInvoice, paymentMethodList,
            userAccess
        } = prevProps;
        var access = false
        if (selectedInvoice && selectedInvoice.id && prevState.initial && isUpdate && selectedInvoice.id === selectedRow.id) {

            if (userAccess && userAccess.modifyInvoice) {
                access = true
            }
            var itemList = []

            if (selectedInvoice.invoiceItems.length !== 0) {
                for (let i = 0; i < selectedInvoice.invoiceItems.length; i++) {
                    const element = selectedInvoice.invoiceItems[i];

                    const newRow = element
                    element.recordIndex = ''
                    element.parentItemId = element.parentItemId ? element.parentItemId : ''
                    element.salesCategoryId = element.salesCategoryId ? element.salesCategoryId : 4
                    element.referenceItemId = element.referenceItemId ? element.referenceItemId : ''
                    element.operation = "U"
                    element.tax = element.tax ? element.tax : ''

                    itemList.push(newRow)
                }
            }

            return {
                status: selectedInvoice.statusId,
                customer: selectedInvoice.customerId,
                vehicleNo: selectedInvoice.vehicleId,
                printName: selectedInvoice.customerName,
                invoiceDate: moment(selectedInvoice.invoiceDate).format(common.dateFormat),
                invoiceNo: selectedInvoice.invoiceNo,
                email: selectedInvoice.customerEmail ? selectedInvoice.customerEmail : '',
                paymentTerm: parseInt(selectedInvoice.paymentTermId),
                dueDate: moment(selectedInvoice.dueDate).format(common.dateFormat),
                referenceNo: selectedInvoice.referenceNo,
                invoiceItems: itemList,
                subTotal: selectedInvoice.subTotal,
                discountValue: selectedInvoice.discountValue,
                tax: selectedInvoice.tax,
                selectedTaxRate: selectedInvoice.taxRate,
                total: selectedInvoice.total,
                discountTypeValue: selectedInvoice.discountTypeValue,
                discountType: selectedInvoice.discountType,
                customerNotes: selectedInvoice.notes ? selectedInvoice.notes : '',
                paymentMethodId: selectedInvoice.paymentMethodId,
                initial: false,
                isUserAccess: access
            }
        }
        // New Invoice create using customer
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            paymentTermList && paymentTermList.length && paymentTermList.length !== 0 &&
            paymentMethodList && paymentMethodList.length && paymentMethodList.length !== 0 &&
            !isUpdate && prevState.initial && tenantFinance && tenantFinance.id &&
            selectedCustomer && selectedCustomer.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
            const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
            const today = new Date()
            const dueDate = new Date()
            dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
            if (userAccess && userAccess.createInvoice) {
                access = true
            }
            return {
                status: defaultStatus[0].id,
                initial: false,
                invoiceDate: moment(today).format(common.dateFormat),
                dueDate: moment(dueDate).format(common.dateFormat),
                paymentTerm: defaultPaymentTerm[0].id,
                paymentMethodId: defaultPaymentMethod[0].id,
                selectedTaxRate: tenantFinance.taxRate,
                customer: selectedCustomer.id,
                printName: selectedCustomer.displayName,
                email: selectedCustomer.emailAddress,
                isUserAccess: access
            }
        }
        // New Invoice create
        else if (
            objectStatusList && objectStatusList.length && objectStatusList.length !== 0 &&
            paymentTermList && paymentTermList.length && paymentTermList.length !== 0 &&
            isNewInvoice && prevState.initial && tenantFinance && tenantFinance.id) {
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            const defaultPaymentMethod = paymentMethodList.filter(data => (data.default === true))
            const defaultPaymentTerm = paymentTermList.filter(data => (data.id === tenantFinance.defaultPaymentTerm))
            const today = new Date()
            const dueDate = new Date()
            dueDate.setDate(dueDate.getDate() + defaultPaymentTerm[0].numberOfDaysDue)
            if (userAccess && userAccess.createInvoice) {
                access = true
            }
            return {
                status: defaultStatus[0].id,
                initial: false,
                invoiceDate: moment(today).format(common.dateFormat),
                dueDate: moment(dueDate).format(common.dateFormat),
                paymentTerm: defaultPaymentTerm[0].id,
                paymentMethodId: defaultPaymentMethod[0].id,
                selectedTaxRate: tenantFinance.taxRate,
                isUserAccess: access
                // paymentTerm:1
            }
        }
        return null
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeStatus = (id) => {
        this.setState({ status: id })
    }

    handleChangePaymentMethod = (id) => {
        this.setState({
            paymentMethodId: id,
        })
    }

    handleChangeDiscountType = (e, valueType) => {
        const { tenantFinance } = this.props;
        const { invoiceItems, discountType, discountTypeValue } = this.state;
        const taxRate = (tenantFinance.taxRate / 100)

        if (valueType === "discountType") {
            this.setState({
                discountType: e
            })
        } else {
            this.setState({
                [e.target.id]: e.target.value
            })
        }

        var newSubtotal = 0
        var taxValue = 0
        var discount = 0

        if (e && e.target && e.target.id === 'discountTypeValue') {
            for (let i = 0; i < invoiceItems.length; i++) {
                const element = invoiceItems[i];
                newSubtotal = newSubtotal + (element.subTotal ? element.subTotal : 0)
            }

            if (newSubtotal === 0) {
                this.setState({
                    subTotal: newSubtotal,
                    tax: 0,
                    total: 0
                })
                return
            }

            if (discountType === '0') {
                discount = newSubtotal * (e.target.value / 100)
            } else if (discountType === '1') {
                discount = parseInt(e.target.value)
            }

            taxValue = (newSubtotal - discount) * taxRate
            // const taxValue_ = parseFloat(taxValue).toFixed(2)*1.00
            //const discount_ = parseFloat(discount).toFixed(2)*1.00
            // const newSubtotal_ = parseFloat(newSubtotal).toFixed(2)*1.00
            const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
            const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
            const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
            this.setState({
                subTotal: newSubtotal_,
                discountValue: discount_,
                tax: taxValue_,
                total: newSubtotal_ - discount_ + taxValue_
            })
        }

        else if (valueType === 'discountType') {
            for (let i = 0; i < invoiceItems.length; i++) {
                const element = invoiceItems[i];
                newSubtotal = newSubtotal + (element.subTotal ? element.subTotal : 0)
            }

            if (newSubtotal === 0) {
                this.setState({
                    subTotal: newSubtotal,
                    tax: 0,
                    total: 0
                })
                return
            }

            if (e === '0') {
                discount = newSubtotal * (discountTypeValue / 100)
            } else if (e === '1') {
                discount = parseInt(discountTypeValue)
            }

            taxValue = (newSubtotal - discount) * taxRate
            // const taxValue_ = parseFloat(taxValue).toFixed(2)*1.00
            //const discount_ = parseFloat(discount).toFixed(2)*1.00
            // const newSubtotal_ = parseFloat(newSubtotal).toFixed(2)*1.00
            const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
            const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
            const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
            this.setState({
                subTotal: newSubtotal_,
                discountValue: discount_,
                tax: taxValue_,
                total: newSubtotal_ - discount_ + taxValue_
            })
        }
        if (valueType === 'discountType' && (e === '' || e === null)) {
            this.setState({
                discountTypeValue: 0
            })
        }
    }

    handleChangeCustomer = (id) => {
        const customerId = parseInt(id)
        const { customerList } = this.props;
        const selctedCustomer = customerList.find(row => row.id === customerId)
        this.setState({
            customer: selctedCustomer.id,
            printName: selctedCustomer.displayName ? selctedCustomer.displayName : '',
            email: selctedCustomer.emailAddress ? selctedCustomer.emailAddress : '',
        })
    }

    handleChangePaymentTerm = (id) => {
        const { paymentTermList } = this.props;
        const { invoiceDate } = this.state;
        const dateInvoice = new Date(invoiceDate)
        const selctedPaymentTerm = paymentTermList.find(row => row.id === id)
        dateInvoice.setDate(dateInvoice.getDate() + selctedPaymentTerm.numberOfDaysDue)
        const newDueDate = moment(dateInvoice).format(common.dateFormat)
        this.setState({
            paymentTerm: id,
            dueDate: newDueDate
        })
    }

    handleDateChange = (date, id) => {
        const { paymentTermList } = this.props;
        if (date === null) {
            const today = new Date()
            const currentDate = new Date()
            const selctedPaymentTerm = paymentTermList.find(row => row.id === this.state.paymentTerm)
            today.setDate(today.getDate() + selctedPaymentTerm.numberOfDaysDue)
            const newDueDate = moment(today).format(common.dateFormat)
            this.setState({
                invoiceDate: moment(currentDate).format(common.dateFormat),
                dueDate: newDueDate
            })
        } else {
            const dateInvoice = new Date(date)
            const selctedPaymentTerm = paymentTermList.find(row => row.id === this.state.paymentTerm)
            dateInvoice.setDate(dateInvoice.getDate() + selctedPaymentTerm.numberOfDaysDue)
            const newDueDate = moment(dateInvoice).format(common.dateFormat)
            this.setState({
                [id]: moment(date).format(common.dateFormat),
                dueDate: newDueDate
            })
        }
    }

    addNewRow = () => {
        var { invoiceItems } = this.state;
        var newRow = { recordIndex: "", description: "", operation: "C", salesCategoryId: 4 }
        invoiceItems.push(newRow)
        this.setState({ invoiceItems })
    }

    handleRowData = (id, rowData) => {
        const { productList } = this.props;
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = productList.result.find(row => row.id === id)
        const newRow = {
            recordIndex: '',
            description: selectedRow.description,
            operation: "C",
            unitOfMeasure: selectedRow.unitOfMeasureId,
            price: selectedRow.unitPrice,
            quantity: selectedRow.defaultIssueQuantity,
            subTotal: (selectedRow.unitPrice * selectedRow.defaultIssueQuantity),
            salesCategoryId: 4,
            stockFlg: selectedRow.stockFlg,
            referenceItemId: id,
            jobCardId: selectedRow.jobCardId
        }
        invoiceItems[rowData.index] = newRow
        this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
        this.setState({ invoiceItems })
    }

    handleRowDescription = (e, rowData) => {
        const { invoiceItems } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.description = e.target.value
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
    }

    handleRowUnitOfMeasure = (id, rowData) => {
        const { invoiceItems } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.unitOfMeasure = id
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
    }

    handleRowUnitPrice = (e, rowData) => {
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.price = e.target.value
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.subTotal = (e.target.value && selectedRow.quantity ? e.target.value * selectedRow.quantity : 0)
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
        this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
    }

    handleRowQuantity = (e, rowData) => {
        const { invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue } = this.state;
        const selectedRow = invoiceItems[rowData.index]
        const newRow = selectedRow
        newRow.operation = rowData.original.id ? "U" : "C"
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.quantity = e.target.value
        newRow.subTotal = (selectedRow.price && e.target.value ? selectedRow.price * e.target.value : 0)
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems })
        this.calTotal(invoiceItems, isPriceInclusiveTax, discountType, discountTypeValue)
    }

    handleDelete = (rowData) => {
        var { invoiceItems } = this.state;
        const newRow = rowData.original
        newRow.id = rowData.original.id ? rowData.original.id : null
        newRow.operation = "D"
        invoiceItems[rowData.index] = newRow
        this.setState({ invoiceItems, isDelete: true })
    }

    calTotal = (invoiceItems) => {
        const { tenantFinance } = this.props;
        const { discountType, discountTypeValue } = this.state;
        const taxRate = (tenantFinance.taxRate / 100)

        if (invoiceItems.length === 0) {
            this.setState({
                subTotal: 0,
                tax: 0,
                total: 0
            })
            return
        }

        var newSubtotal = 0
        var taxValue = 0
        var discount = 0
        for (let i = 0; i < invoiceItems.length; i++) {
            const element = invoiceItems[i];
            newSubtotal = newSubtotal + (element.subTotal ? element.subTotal : 0)
        }

        if (newSubtotal === 0) {
            this.setState({
                subTotal: newSubtotal,
                tax: 0,
                total: 0
            })
            return
        }

        if (discountType === '0') {
            discount = newSubtotal * (discountTypeValue / 100)
        } else if (discountType === '1') {
            discount = parseInt(discountTypeValue)
        }

        taxValue = (newSubtotal - discount) * (taxRate)
        const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
        const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
        const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
        this.setState({
            subTotal: newSubtotal_,
            discountValue: discount_,
            tax: taxValue_,
            total: newSubtotal_ - discount_ + taxValue_
        })
    }



    componentDidUpdate(prevProps, prevState) {
        const { invoiceItems, isDelete, tempInvoiceItemsList } = this.state;
        // const { selectedJobCard } = this.props;
        if (isDelete) {
            const invoiceItemsList = invoiceItems.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = invoiceItems.filter(data => (data.id !== null))
            this.setState({ invoiceItems: invoiceItemsList, isDelete: false, tempInvoiceItemsList: tempInvoiceItemsList.concat(tempItemList) })
            this.calTotal(invoiceItemsList)
        }

    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            uomList,
            customerList,
            objectStatusList,
            handlePrint,
            isPrintModalOpen,
            invoicePrint,
            printModalClose,
            handleSendEmail,
            paymentTermList,
            tenantFinance,
            isUpdate,
            selectedInvoice,
            isInvoiceListLoading,
            paymentMethodList,
            productList
        } = this.props;

        const {
            invoiceItems,
            isUserAccess
            // isWarning,
            // selectedJobCardDetails 
        } = this.state;

        const invoiceItemsColumns = [{
            Header: 'Item No',
            // accessor: 'no',
            width: '7%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="index"
                        type="text"
                        defaultValue={rowData.cell.row.index + 1}
                        size="sm"
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Description',
            accessor: 'description',
            // width:1000,
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.description === '' ?
                        <SelectField
                            controlId="description"
                            onChange={(e) => this.handleRowData(e, rowData.cell.row)}
                            options={productList.result}
                            style={customStyles}
                            disabled={!isUserAccess}
                        // id="partId"
                        />
                        :
                        <BootstrapFormControl
                            controlId="description"
                            type="text"
                            defaultValue={rowData.cell.row.original.description}
                            size="sm"
                            onBlur={(e) => this.handleRowDescription(e, rowData.cell.row)}
                            maxLength={common.maxLengths.jobCardDescription}
                            disabled={!isUserAccess}
                        />
                    }
                </div>
            ),
        }, {
            Header: 'UOM',
            accessor: 'unitOfMeasure',
            width: '10%',
            Cell: (rowData) => (
                <div>
                    <SelectField
                        controlId="unitOfMeasure"
                        onChange={(e) => this.handleRowUnitOfMeasure(e, rowData.cell.row)}
                        options={uomList}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.unitOfMeasure}
                        disabled={!isUserAccess}
                    // id="partId"
                    />
                </div>
            ),
        }, {
            Header: 'Unit Price',
            // accessor: 'price',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="price"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.price).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowUnitPrice(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!isUserAccess}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Quantity',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="quantity"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.quantity).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleRowQuantity(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!isUserAccess || (rowData.cell.row.original.id > 0 && rowData.cell.row.original.stockFlg)}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Amount',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="amount"
                        type="text"
                        value={rowData.cell.row.original.subTotal ? parseFloat(rowData.cell.row.original.subTotal).toFixed(2) : '0.00'}
                        size="sm"
                        disabled
                        style={{ textAlign: 'right' }}
                    // width="100%"
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'center' }}>
                        <span onClick={isUserAccess && this.state.status !== "40" ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]
        return (
            <div>
                {/* {isWarning && 
                    <WarningModal
                        show={isWarning}
                        handleWarningModalClose={this.handleWarningModalClose}
                        handleWarning={this.handleWarning}
                        title={"Job number " + selectedJobCardDetails.jobNumber}
                    />} */}
                {tenantFinance && tenantFinance.id &&
                    <div>
                        <div className="filter-header">
                            <h5 style={{ marginLeft: '15px' }}>
                                Invoice {isUpdate && selectedInvoice && selectedInvoice.id ? " - " + selectedInvoice.invoiceNo : ""}
                            </h5>
                            <div onClick={handleClose} style={{ marginRight: '10px' }}>
                                <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                            </div>
                        </div>
                        <div className="row" style={{ margin: '0 5px' }}>
                            <div className="col-sm page_header">
                                <div className="page_header-title">
                                    <span>Customer Details</span>
                                </div>
                                <div className="row">
                                </div>
                                <SelectField
                                    text="Customer"
                                    controlId="customer"
                                    options={
                                        customerList && customerList.length && customerList.length > 0 ?
                                            customerList.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                    selectedValue={this.state.customer}
                                    onChange={this.handleChangeCustomer}
                                    display="displayName"
                                    disabled={!isUserAccess}
                                    required
                                />
                                <TextField
                                    text="Print name"
                                    controlId="printName"
                                    placeholder="Print name"
                                    type="text"
                                    onChange={this.handleChange}
                                    required
                                    defaultValue={this.state.printName}
                                    disabled={!isUserAccess}
                                />
                                <TextField
                                    text="Email address"
                                    controlId="email"
                                    placeholder="Email address"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.email}
                                    disabled={!isUserAccess}
                                />
                            </div>
                            <div className="col-sm page_header">
                                <div className="page_header-title">
                                    <span>Reference Details</span>
                                </div>
                                <TextField
                                    text="Invoice no"
                                    controlId="invoiceNo"
                                    placeholder="Invoice no"
                                    type="text"
                                    onChange={this.handleChange}
                                    disabled
                                    defaultValue={this.state.invoiceNo}
                                />
                                <div className='row'>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Payment method"
                                            controlId="paymentMethodId"
                                            options={paymentMethodList}
                                            selectedValue={this.state.paymentMethodId}
                                            onChange={this.handleChangePaymentMethod}
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Payment term"
                                            controlId="paymentTerm"
                                            options={paymentTermList}
                                            selectedValue={this.state.paymentTerm}
                                            onChange={this.handleChangePaymentTerm}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Invoice date"
                                            controlId="invoiceDate"
                                            onChange={(e) => this.handleDateChange(e, "invoiceDate")}
                                            value={this.state.invoiceDate === '' ? '' : new Date(this.state.invoiceDate)}
                                            required
                                            disabled={!isUserAccess}
                                        // format={dateFormat}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <DatePicker
                                            text="Due date"
                                            controlId="dueDate"
                                            disabled
                                            required
                                            value={this.state.dueDate === '' ? '' : new Date(this.state.dueDate)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Reference no"
                                            controlId="referenceNo"
                                            placeholder="Reference no"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.referenceNo}
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            onChange={this.handleChangeStatus}
                                            required
                                            disabled={!isUserAccess}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="invoice-title">
                                <span>Invoice Items</span>
                            </div>
                            <div className="table-invoice">
                                <Table
                                    columns={invoiceItemsColumns}
                                    data={invoiceItems}
                                />
                                <div className="add-icon" >
                                    <ui5-icon class="samples-margin" name="add-activity" id="add-activity" onClick={isUserAccess && this.state.status !== "40" ? this.addNewRow : ''}></ui5-icon>
                                </div>

                            </div>
                        </div>
                        <div className="row" style={{ margin: '0 5px' }}>
                            <div className='col-sm-6'>
                                <TextField
                                    text="Notes"
                                    controlId="customerNotes"
                                    defaultValue={this.state.customerNotes}
                                    onBlur={this.handleChange}
                                    as="textarea"
                                    disabled={!isUserAccess}
                                    style={{ height: 200 }}
                                />
                            </div>
                            <div className='col-sm ' style={{ textAlign: 'right', marginRight: '-15px' }}>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Sub total
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="subTotal"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.subTotal).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" >
                                    <div className='col-sm'>
                                        {/* <FormGroup controlId="discountType">
                                        <FormControl
                                            as="select"
                                            style={{textAlign:'right'}}
                                            value={this.state.discountType}
                                            onChange={this.handleChangeDiscountType}
                                        >
                                            <option key='' value=''> </option> 
                                            <option key='0' value='0'>Discount Percentage</option> 
                                            <option key='1' value='1'>Discount Value</option> 
                                        </FormControl>
                                    </FormGroup> */}
                                        <SelectField
                                            controlId="discountType"
                                            onChange={(e) => this.handleChangeDiscountType(e, "discountType")}
                                            options={common.discountType}
                                            style={customStylesDiscount}
                                            selectedValue={this.state.discountType}
                                            isClearable
                                            disabled={!isUserAccess}
                                        // id="partId"
                                        />
                                    </div>
                                    <div className='col-sm'>
                                        <FormGroup controlId="discountTypeValue">
                                            <FormControl
                                                type="number"
                                                style={{ textAlign: 'right' }}
                                                value={this.state.discountTypeValue}
                                                onChange={this.handleChangeDiscountType}
                                                disabled={!isUserAccess}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="discountValue"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.discountValue).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value" style={{ marginBottom: '20px' }}>
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Tax {this.state.selectedTaxRate}%
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="tax"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.tax).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="row value">
                                    <div className='col-sm'></div>
                                    <div className='col-sm value-title'>
                                        <Label >
                                            Total due
                                        </Label>
                                    </div>
                                    <div className='col-sm'>
                                        <FormControl
                                            controlId="total"
                                            type="number"
                                            style={{ textAlign: 'right' }}
                                            value={parseFloat(this.state.total).toFixed(2)}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-btns" style={{ marginRight: '15px' }}>
                            <ButtonGroup
                                primaryBtnClick={() => handleSaveAddNew(this.state)}
                                primaryBtnDisabled={!isUserAccess || isInvoiceListLoading || this.state.status === "40"}
                                primaryBtnIcon='save'
                                primaryBtnText='Save'
                                primaryBtnVariant="outline-primary"
                                secondaryBtnClick={() => handleSendEmail(this.state)}
                                secondaryBtnIcon='email'
                                secondaryBtnText='Save and send'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnDisabled={this.state.email === '' || !isUserAccess || isInvoiceListLoading || this.state.status === "40"}
                            />
                            <ButtonGroup
                                secondaryBtnClick={handleClose}
                                // secondaryBtnIcon='decline'
                                // secondaryBtnText='Cancel'
                                secondaryBtnText='Go Back'
                                secondaryBtnIcon='undo'
                                secondaryBtnVariant="outline-primary"
                                primaryBtnClick={() => handlePrint(this.state)}
                                primaryBtnIcon='print'
                                primaryBtnText='Print or preview'
                                primaryBtnVariant="outline-primary"
                            />
                        </div>
                        {
                            isPrintModalOpen && invoicePrint !== '' &&
                            <PdfViewer
                                show={isPrintModalOpen}
                                pdf={invoicePrint}
                                onHide={printModalClose}
                            />
                        }
                    </div>}
            </div>
        )
    }
}

AddNewJobCard.propTypes = {
    handleClose: PropTypes.any,
    vehicleList: PropTypes.any,
    customerList: PropTypes.any,
    selectedInvoice: PropTypes.any,
    // modelList: PropTypes.any,
    handleSaveAddNew: PropTypes.any,
    objectStatusList: PropTypes.any,
    jobCardList: PropTypes.any,
    // partList: PropTypes.any,
    uomList: PropTypes.any,
    isPrintModalOpen: PropTypes.any,
    isUpdate: PropTypes.bool,
    invoicePrint: PropTypes.any,
    printModalClose: PropTypes.any,
    selectedJobCard: PropTypes.any,
    handleSendEmail: PropTypes.any,
    paymentTermList: PropTypes.any,
    selectedCustomer: PropTypes.any,
    isNewInvoice: PropTypes.any,
}


const mapStateToProps = createStructuredSelector({
    productList: makeSelectProductList(),
    uomList: makeSelectUOMList(),
    // selectedJobCard: makeSelectSelectedJobCardSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(AddNewJobCard));