import React, { Component } from 'react';
import '../settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/accountSelector';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import Table from '../../../layout/form/Table';
// import Button from '../../../layout/form/Button';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import { loadAccountLoading, loadAccountSystemRequest, loadAccountSystemSuccess, sendConnectionRequest, sendConnectionSuccess, sendConnectionUpdateRequest, sendConnectionUpdateSuccess, sendDeleteConnectionRequest } from '../../../../store/action/accountAction';
import { loadTenantAccountSystemRequest, loadTenantAccountSystemSuccess } from '../../../../store/action/tenantAction';
import Loader from '../../../layout/form/Loader';
import { Link } from 'react-router-dom';
// import Cards from '../../../layout/form/Cards'
import Switch from "react-switch";
import common from '../../../common';
import InformationModal from './inforModal';
import moment from 'moment';
import UpdateAccountSystem from './UpdateSystem';

function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const date = new Date();
const dateFormat = sessionStorage.getItem("dateFormat")

class Accounting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quickBooks: {},
            xero: {},
            myob: {},
            initial: true,
            activeSystem: null,
            quickBooksStatus: 0,
            xeroStatus: 0,
            myobStatus: 0,
            isXeroDisabled: false,
            isQuickBooksDisabled: false,
            isMyobDisabled: false,
            isActiveFromDateModal: false,
            activeFromDate: date,
            isChecked: false,
            systemId: '',
            xeroActiveDate: '',
            quickBooksActiveDate: '',
            myobActiveDate: '',
            isUpdateAccountSystemModalOpen: false
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { accountSystem, tenantAccountSystem } = prevProps;
        const { initial } = prevState;
        if (accountSystem && accountSystem.length && accountSystem.length > 0 && initial && tenantAccountSystem && tenantAccountSystem.id === 0) {
            const activeSystem = accountSystem.find(element => element.systemId === tenantAccountSystem.systemId)
            const quickBooksSystem = accountSystem.find(element => element.systemId === common.accounting.quickBooks)
            const xeroSystem = accountSystem.find(element => element.systemId === common.accounting.xero)
            const myobSystem = accountSystem.find(element => element.systemId === common.accounting.myob)
            var quickBooksStatus = 0
            var xeroStatus = 0
            var myobStatus = 0
            var isXeroDisabled = false
            var isQuickBooksDisabled = false
            var isMyobDisabled = false
            var xeroActiveDate = ''
            var quickBooksActiveDate = ''
            var myobActiveDate = ''
            if (tenantAccountSystem && tenantAccountSystem.connectionActive !== 0 && tenantAccountSystem.systemId === common.accounting.quickBooks) {
                quickBooksStatus = tenantAccountSystem.connectionActive
                isXeroDisabled = true
                isMyobDisabled = true
                quickBooksActiveDate = tenantAccountSystem.activeFromDate

            } else if (tenantAccountSystem && tenantAccountSystem.connectionActive !== 0 && tenantAccountSystem.systemId === common.accounting.xero) {
                xeroStatus = tenantAccountSystem.connectionActive
                isMyobDisabled = true
                isQuickBooksDisabled = true
                xeroActiveDate = tenantAccountSystem.activeFromDate
            } else if (tenantAccountSystem && tenantAccountSystem.connectionActive !== 0 && tenantAccountSystem.systemId === common.accounting.myob) {
                myobStatus = tenantAccountSystem.connectionActive
                myobActiveDate = tenantAccountSystem.activeFromDate
                isXeroDisabled = true
                isQuickBooksDisabled = true
            }
            return {
                initial: false,
                activeSystem: activeSystem && activeSystem.systemId ? activeSystem.systemId : null,
                quickBooks: quickBooksSystem,
                xero: xeroSystem,
                myob: myobSystem,
                quickBooksStatus: quickBooksStatus,
                xeroStatus: xeroStatus,
                myobStatus: myobStatus,
                isXeroDisabled: isXeroDisabled,
                isQuickBooksDisabled: isQuickBooksDisabled,
                isMyobDisabled: isMyobDisabled,
                quickBooksActiveDate: quickBooksActiveDate,
                xeroActiveDate: xeroActiveDate,
                myobActiveDate: myobActiveDate
            }
        }
        return null
    }

    getActiveFromDate = (value, systemId) => {
        if (value) {
            this.setState({
                isActiveFromDateModal: true,
                systemId: systemId
            })
        } else {
            this.props.dispatch(sendDeleteConnectionRequest(systemId))
            this.props.dispatch(loadAccountLoading(true))
            this.getAccountDetails()
        }

    }

    handleCheck = (e) => {
        this.setState({
            isChecked: e.target.checked
        })
    }

    handleInfoModalClose = () => {
        this.setState({
            isActiveFromDateModal: false,
            systemId: ''
        })
    }

    handleDateChange = (date) => {
        this.setState({
            activeFromDate: date
        })
    }

    handleConnection = () => {
        const parameters = {
            systemId: this.state.systemId,
            activeFromDate: moment(this.state.activeFromDate).format(common.dateFormat)
        }
        this.props.dispatch(sendConnectionRequest(parameters))
    }

    updateAccountSystemModalOpen = (systemId) => {
        this.setState({
            isUpdateAccountSystemModalOpen: true,
            systemId: systemId
        })
    }

    updateAccountSystemModalClose = () => {
        this.setState({
            isUpdateAccountSystemModalOpen: false,
            systemId: ''
        })
    }

    handleUpdateActiveDate = (details) => {
        const parameters = {
            systemId: this.state.systemId,
            activeFromDate: moment(details.activeFromDate).format(common.dateFormat)
        }
        this.props.dispatch(sendConnectionUpdateRequest(parameters))
    }

    getAccountDetails = () => {
        this.props.dispatch(loadTenantAccountSystemSuccess(''))
        this.props.dispatch(loadAccountSystemSuccess([]))
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantAccountSystemRequest(id))
        this.props.dispatch(loadAccountSystemRequest())
        this.setState({
            initial: true
        })
    }

    componentDidMount() {
        this.getAccountDetails()
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountConnection, accountConnectionUpdate } = this.props
        const { quickBooks, xero, myob } = this.state;
        const id = sessionStorage.getItem("tenantId")
        var baseURI = ""
        if (accountConnection && accountConnection.statusCode && accountConnection.statusCode === 201) {
            if (accountConnection.result.systemId === common.accounting.quickBooks) {
                baseURI = quickBooks.baseURI
                baseURI = baseURI.replace("clientId", quickBooks.clientId)
                baseURI = baseURI.replace("redirectUri", quickBooks.redirectURL)
                baseURI = baseURI.replace("antiForgeryStateToken", id)
                openInNewTab(baseURI)
            } else if (accountConnection.result.systemId === common.accounting.xero) {
                baseURI = xero.baseURI
                baseURI = baseURI.replace("clientId", xero.clientId)
                baseURI = baseURI.replace("redirectUri", xero.redirectURL)
                baseURI = baseURI.replace("tenantId", id)
                openInNewTab(baseURI)
            } else if (accountConnection.result.systemId === common.accounting.myob) {
                baseURI = myob.baseURI
                baseURI = baseURI.replace("clientId", myob.clientId)
                baseURI = baseURI.replace("redirectUri", myob.redirectURL)
                baseURI = baseURI.replace("tenantId", id)
                openInNewTab(baseURI)
            }
            this.props.dispatch(loadAccountLoading(true))
            this.props.dispatch(sendConnectionSuccess(''))
            this.handleInfoModalClose()
            this.setState({
                initial: true
            })
            this.getAccountDetails()
        }
        if (accountConnectionUpdate) {
            this.props.dispatch(sendConnectionUpdateSuccess(false))
            this.updateAccountSystemModalClose()
            this.setState({
                initial: true
            })
            this.getAccountDetails()
        }
    }

    render() {
        const {
            isAccountLoading,
            // accountSystem,
            tenantAccountSystem
        } = this.props;
        const {
            quickBooks,
            xero,
            myob,
            // activeSystem,
            quickBooksStatus,
            xeroStatus,
            myobStatus,
            isXeroDisabled,
            isQuickBooksDisabled,
            isMyobDisabled,
            isUpdateAccountSystemModalOpen
        } = this.state

        return (
            <div>
                {isAccountLoading ?
                    <Loader /> : ''}

                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero && tenantAccountSystem.connectionActive === 1 ?
                            <div>
                                <span>
                                    <Link to='./xero'>
                                        <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                                    </Link>
                                </span>
                                <span>
                                    <Link to='./invoiceReconciliationReport'>
                                        <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                                    </Link>
                                </span>
                            </div> : ''}
                        {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks && tenantAccountSystem.connectionActive === 1 ?
                            <div>
                                <span>
                                    <Link to='./quickBooks'>
                                        <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                                    </Link>
                                </span>
                                <span>
                                    <Link to='./invoiceReconciliationReport'>
                                        <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                                    </Link>
                                </span>
                            </div> : ''}
                        {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob && tenantAccountSystem.connectionActive === 1 ?
                            <div>
                                <span>
                                    <Link to='./myob'>
                                        <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                                    </Link>
                                </span>
                                <span>
                                    <Link to='./invoiceReconciliationReport'>
                                        <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                                    </Link>
                                </span>
                            </div> : ''}
                    </div>
                </div>
                <div className="body-content">
                    <div className="table-content" >
                        <div className='row'>
                            <div className='col-sm account_system'>
                                <div className='row' style={{ margin: '5px 0' }}>
                                    <div className='col-sm-2'>
                                        <img src={quickBooks.logo} alt={quickBooks.systemName} id="acc_sys_logo_quickBooks" />
                                    </div>
                                    <div className='col-sm systemName'>
                                        <div>{quickBooks.systemName}</div>
                                    </div>
                                    <div className='col-sm-3 switch-button'>
                                        <div>
                                            <Switch
                                                checked={quickBooksStatus}
                                                onChange={(e) => this.getActiveFromDate(e, common.accounting.quickBooks)}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                disabled={isQuickBooksDisabled || quickBooksStatus === 2 || xeroStatus === 2}
                                                onColor={quickBooksStatus === 2 ? '#ff555d' : '#080'}
                                            // offColor='#4278b7'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='system-description'>{quickBooks.description}</div>
                                <div className='system-date'>
                                    <div>
                                        {quickBooksStatus === 1 ? <span>Active From Date : {moment(this.state.quickBooksActiveDate).format(dateFormat ? dateFormat : common.dateFormat)}</span> : ''}
                                    </div>
                                    <div>
                                        {quickBooksStatus === 1 ?
                                            <span onClick={() => this.updateAccountSystemModalOpen(common.accounting.quickBooks)}><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span>}
                                        {quickBooksStatus === 2 ?
                                            <span onClick={() => this.getAccountDetails()}><ui5-icon class="samples-margin" name="synchronize" id="account-icon-color"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="synchronize" id="account-icon"></ui5-icon></span>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm account_system'>
                                <div className='row' style={{ margin: '5px 0' }}>
                                    <div className='col-sm-2'>
                                        <img src={xero.logo} alt={xero.systemName} id="acc_sys_logo_xero" />
                                    </div>
                                    <div className='col-sm systemName'>
                                        <div>{xero.systemName}</div>
                                    </div>
                                    <div className='col-sm-3 switch-button'>
                                        <div>
                                            <Switch
                                                checked={xeroStatus}
                                                onChange={(e) => this.getActiveFromDate(e, common.accounting.xero)}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                disabled={isXeroDisabled || quickBooksStatus === 2 || xeroStatus === 2}
                                                onColor={xeroStatus === 2 ? '#ff555d' : '#080'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='system-description'>{xero.description}</div>
                                <div className='system-date'>
                                    <div>
                                        {xeroStatus === 1 ? <span>Active From Date : {moment(this.state.xeroActiveDate).format(dateFormat ? dateFormat : common.dateFormat)}</span> : ''}
                                    </div>
                                    <div>
                                        {xeroStatus === 1 ?
                                            <span onClick={() => this.updateAccountSystemModalOpen(common.accounting.xero)}><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span>}
                                        {xeroStatus === 2 ?
                                            <span onClick={() => this.getAccountDetails()}><ui5-icon class="samples-margin" name="synchronize" id="account-icon-color"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="synchronize" id="account-icon"></ui5-icon></span>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-sm account_system'>
                                <div className='row' style={{ margin: '5px 0' }}>
                                    <div className='col-sm-2'>
                                        <img src={myob.logo} alt={myob.systemName} id="acc_sys_logo_xero" />
                                    </div>
                                    <div className='col-sm systemName'>
                                        <div>{myob.systemName}</div>
                                    </div>
                                    <div className='col-sm-3 switch-button'>
                                        <div>
                                            <Switch
                                                checked={myobStatus}
                                                onChange={(e) => this.getActiveFromDate(e, common.accounting.myob)}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                disabled={isMyobDisabled || quickBooksStatus === 2 || xeroStatus === 2 || myobStatus === 2}
                                                onColor={myobStatus === 2 ? '#ff555d' : '#080'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='system-description'>{myob.description}</div>
                                <div className='system-date'>
                                    <div>
                                        {myobStatus === 1 ? <span>Active From Date : {moment(this.state.myobActiveDate).format(dateFormat ? dateFormat : common.dateFormat)}</span> : ''}
                                    </div>
                                    <div>
                                        {myobStatus === 1 ?
                                            <span onClick={() => this.updateAccountSystemModalOpen(common.accounting.myob)}><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="edit" id="account-icon"></ui5-icon></span>}
                                        {myobStatus === 2 ?
                                            <span onClick={() => this.getAccountDetails()}><ui5-icon class="samples-margin" name="synchronize" id="account-icon-color"></ui5-icon></span> :
                                            <span><ui5-icon class="samples-margin" name="synchronize" id="account-icon"></ui5-icon></span>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm'>

                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isActiveFromDateModal ?
                    <InformationModal
                        show={this.state.isActiveFromDateModal}
                        handleInfoModalClose={this.handleInfoModalClose}
                        handleDateChange={this.handleDateChange}
                        activeFromDate={this.state.activeFromDate}
                        isChecked={this.state.isChecked}
                        handleCheck={this.handleCheck}
                        handleSubmit={this.handleConnection}
                    /> : ''}
                {
                    isUpdateAccountSystemModalOpen &&
                    <UpdateAccountSystem
                        isUpdateAccountSystemModalOpen={isUpdateAccountSystemModalOpen}
                        handleModalClose={this.updateAccountSystemModalClose}
                        activeFromDate={
                            this.state.quickBooksActiveDate !== '' ?
                                this.state.quickBooksActiveDate :
                                this.state.xeroActiveDate !== '' ?
                                    this.state.xeroActiveDate : ''
                        }
                        handleSaveAddNew={this.handleUpdateActiveDate}
                    />
                }
            </div>
        )
    }
}

Accounting.propTypes = {
    tenantAccountSystem: PropTypes.any,
    accountSystem: PropTypes.any,
    quickBooksConnection: PropTypes.any,
    isAccountLoading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
    tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
    accountSystem: selectors.makeSelectAccountSystem(),
    quickBooksConnection: selectors.makeSelectQuickBooksConnection(),
    isAccountLoading: selectors.makeSelectAccountLoading(),
    accountConnection: selectors.makeSelectAccountConnection(),
    accountConnectionUpdate: selectors.makeSelectAccountConnectionUpdate()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Accounting));