import { put, call } from 'redux-saga/effects';
import {
  getRatingDetails
} from '../service/analyticsService';
import * as actions from '../../store/action/analyticsAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { getCustomerCreateSummary } from '../service/customerService';


export function* loadRatingDetailsRequest(data) {
  // console.log('loadRatingDetailsRequest')
  yield put(actions.loadRatingError(''))
  try {
    yield put(actions.loadRatingLoading(true));
    const response = yield call(getRatingDetails, data.payload);
    if (response && response.status && response.status === 200) {
      // console.log(response.data)
      const RatingList = response.data.result.result
      yield put(actions.loadRatingSuccess(RatingList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadRatingLoading(false));
      const massage = common.error
      yield put(actions.loadRatingError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadRatingLoading(false));
  } catch (error) {
    yield put(actions.loadRatingLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadRatingError(massage))
  }
}



export function* loadCustomerSummaryRequest(){
  yield put(actions.loadCustomerSummaryLoading(true));
  yield put(actions.loadCustomerSummaryError(''))
  try{
    const response = yield call(getCustomerCreateSummary);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response.data.result.result)
      const summary = response.data.result.result
      yield put(actions.loadCustomerSummarySuccess(summary))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    }  
    else{
      yield put(actions.loadCustomerSummaryLoading(false));
      const massage = common.error
      yield put(actions.loadCustomerSummaryError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadCustomerSummaryLoading(false));
  }catch(error){
    yield put(actions.loadCustomerSummaryLoading(false));
    // console.log(error)
    const massage = common.error
    yield put(actions.loadCustomerSummaryError(massage))
  }
}