import {
  LOAD_CUSTOMER_SUMMARY_ERROR,
  LOAD_CUSTOMER_SUMMARY_LOADING,
  LOAD_CUSTOMER_SUMMARY_REQUEST,
  LOAD_CUSTOMER_SUMMARY_SUCCESS,
  LOAD_RATING_ERROR,
  LOAD_RATING_LOADING,
  LOAD_RATING_REQUEST,
  LOAD_RATING_SUCCESS,
} from '../constant/analyticsConstant';


export function loadRatingRequest(data) {
  return {
    type: LOAD_RATING_REQUEST,
    payload: data,
  }
};

export function loadRatingLoading(data) {
  return {
    type: LOAD_RATING_LOADING,
    payload: data,
  }
};

export function loadRatingSuccess(data) {
  return {
    type: LOAD_RATING_SUCCESS,
    payload: data,
  }
};

export function loadRatingError(data) {
  return {
    type: LOAD_RATING_ERROR,
    payload: data,
  }
};


export function loadCustomerSummaryRequest(data) {
  return {
    type: LOAD_CUSTOMER_SUMMARY_REQUEST,
    payload: data,
  }
};

export function loadCustomerSummaryLoading(data) {
  return {
    type: LOAD_CUSTOMER_SUMMARY_LOADING,
    payload: data,
  }
};

export function loadCustomerSummarySuccess(data) {
  return {
    type: LOAD_CUSTOMER_SUMMARY_SUCCESS,
    payload: data,
  }
};

export function loadCustomerSummaryError(data) {
  return {
    type: LOAD_CUSTOMER_SUMMARY_ERROR,
    payload: data,
  }
};

