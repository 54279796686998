import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import * as actions from '../../../../store/action/reminderAction';
import * as selectors from '../../../../store/selector/reminderSelector';
import moment from 'moment';   
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../App.css';
import common from '../../../common';
import Loader from '../../../layout/form/Loader';
import Button from '../../../layout/form/Button';
import AddReminder from './AddNewReminder';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import { makeSelectTenantUser } from '../../../../store/selector/selector';
// import { makeSelectVehicleList } from '../../../../store/selector/vehicleSelector';
// import { makeSelectSelectedCustomerSuccess } from '../../../../store/selector/customerSelector';
// import { loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
// import { saveClickUnderExpiredStatus } from '../../../../store/action/action';
// import { makeSelectTenantUserExpireStatus } from '../../../../store/selector/selector';

moment.locale('en-GB');
// Calendar.momentLocalizer(moment);
const localizer = momentLocalizer(moment)
const currentDate = new Date() 
const prevDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),1)
var nextDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)+1),1)
nextDate.setDate(nextDate.getDate() - 1)
class GraphicalCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMaintenancePageShow:true,
            initial:true,
            events :[],
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(nextDate).format(common.dateFormat),
            vehicleRegistrationNo:'',
            customer:'',
            pageNumber:1,
            pageSize:9999,
            resultMonth:currentDate.getMonth(currentDate)+1,
            resultDate:currentDate.getDate(currentDate),
            resultWeek:moment(currentDate).startOf('week').toDate(),
            calendarView: common.reminder.monthView,
            selectedDate:currentDate,
            isChangeStep:false,
            selectedRow:{},
            isUpdate:false,
            isValidationError:false,
            errorMassage:'',
            isDeleteModalOpen:false,
            slots:'',
            isSelectedSlot:true
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { reminderList } = prevProps;
        const { initial,resultMonth,calendarView } = prevState;
        if(reminderList && reminderList.result && reminderList.result.length && reminderList.result.length > 0  && initial){
            const newResultDate = new Date(reminderList.result[0].requestedDate)
            const newResultMonth = newResultDate.getMonth(newResultDate)+1
            var list =[]
            if(calendarView === common.reminder.monthView && newResultMonth === resultMonth){
                for (let i = 0; i < reminderList.result.length; i++) {
                    const element = reminderList.result[i]
                    const event = {
                        id: element.id,
                        start: element.requestedDate,
                        end: element.requestedDate,
                        resourceId: element.id,
                        title: element.description,
                        description:element.description,
                        bgColor: new Date(element.requestedDate) > currentDate ? common.reminder.unConfirmColor : common.reminder.confirmedColor,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        status:element.status,
                        userName: element.firstName +' '+ element.lastName
                    }
                    list.push(event)
                }
                return {
                    initial:false,
                    events:list,
                    isChangeStep:false
                }
            }
        }
        return null
    }

    getRemainderList = () => {
        const startDay = new Date(this.state.fromDate)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(this.state.toDate)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),   
            toDate: moment(endDateTime).format(common.dateTimeFormat)
        }
        this.setState({
            isChangeStep:true
        })
        this.props.dispatch(actions.loadReminderListRequest(parameters))
    }

    getSelectedBookingList = (day1 , day2) => {
        const startDay = new Date(day1)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(day2)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),   
            toDate: moment(endDateTime).format(common.dateTimeFormat),
            regNo: this.state.vehicleRegistrationNo,
            customerName:this.state.customer,
        }
        this.props.dispatch(actions.loadReminderListRequest(parameters))
    }

    onNavigate = (setdate)  => {
        const { calendarView } = this.state;
        this.props.dispatch(actions.loadReminderListSuccess([]))
        if(calendarView === common.reminder.monthView){
            const date = new Date(setdate)
            const day1 = new Date(date.getFullYear(date),(date.getMonth(date)),1)
            var day2 = new Date(date.getFullYear(date),(date.getMonth(date)+1),1)
            day2.setDate(day2.getDate() - 1)
            this.setState({
                isChangeStep:true,
                resultMonth:date.getMonth(date)+1,
                selectedDate:date,
                events:[],
                fromDate: moment(day1).format(common.dateFormat),
                toDate: moment(day2).format(common.dateFormat),
            })
            this.getSelectedBookingList(day1 , day2)
        }else if(calendarView === common.reminder.weekView){
            const date = new Date(setdate)
            const startOfWeek = moment(setdate).startOf('week').toDate();
            const endOfWeek   = moment(setdate).endOf('week').toDate();
            this.setState({
                isChangeStep:true,
                resultMonth:date.getMonth(date)+1,
                selectedDate:date,
                resultWeek:moment(startOfWeek).startOf('week').toDate(),
                events:[],
                fromDate: moment(startOfWeek).format(common.dateFormat),
                toDate: moment(endOfWeek).format(common.dateFormat),
            })
            this.getSelectedBookingList(startOfWeek , endOfWeek)
        }else if(calendarView === common.reminder.dayView){
            const date = new Date(setdate)
            const startOfDay = moment(setdate).startOf('day').toDate();
            const endOfDay   = moment(setdate).endOf('day').toDate();
            this.setState({
                isChangeStep:true,
                selectedDate:date,
                resultDate:moment(startOfDay).startOf('day').toDate(),
                events:[],
                fromDate: moment(startOfDay).format(common.dateFormat),
                toDate: moment(endOfDay).format(common.dateFormat),
            })
            this.getSelectedBookingList(startOfDay , endOfDay)
        }
        
    }

    onView = (view)  =>{
        const { selectedDate } =this.state;
        this.props.dispatch(actions.loadReminderListSuccess([]))
        this.setState({
            calendarView:view,
            events:[]
        })

        if(view === common.reminder.monthView){
            const day1 = new Date(selectedDate.getFullYear(selectedDate),(selectedDate.getMonth(selectedDate)),1)
            var day2 = new Date(selectedDate.getFullYear(selectedDate),(selectedDate.getMonth(selectedDate)+1),1)
            day2.setDate(day2.getDate() - 1)
            this.getSelectedBookingList(day1 , day2)
            this.setState({
                isChangeStep:true,
                fromDate: moment(day1).format(common.dateFormat),
                toDate: moment(day2).format(common.dateFormat),
            })
        }
        else if(view === common.reminder.weekView){
            const startOfWeek = moment(selectedDate).startOf('week').toDate();
            const endOfWeek   = moment(selectedDate).endOf('week').toDate();
            this.getSelectedBookingList(startOfWeek , endOfWeek)
            this.setState({
                isChangeStep:true,
                resultWeek :startOfWeek,
                fromDate: moment(startOfWeek).format(common.dateFormat),
                toDate: moment(endOfWeek).format(common.dateFormat),
            })
            
        }else if(view === common.reminder.dayView){
            const startOfDay = moment(selectedDate).startOf('day').toDate();
            const endOfDay   = moment(selectedDate).endOf('day').toDate();
            this.getSelectedBookingList(startOfDay , endOfDay)
            this.setState({
                isChangeStep:true,
                resultDate :startOfDay,
                fromDate: moment(startOfDay).format(common.dateFormat),
                toDate: moment(endOfDay).format(common.dateFormat),
            })
        }
        
    }

    // onSelectEvent =(event) => {
    //     console.log(event)
    // }

    eventStyleGetter = (event, start, end, isSelected) =>{
        // console.log(event);
        var backgroundColor = event.bgColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: '#ffffff',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    handleModal = (data) => {
        if(data && data.id){
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({
            selectedRow:{}, 
            isUpdate:false,
            isSelectedSlot:false,
            slots:''
        })
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(false));
        // this.props.dispatch(loadSelectedCustomerSuccess(''));
        // this.props.dispatch(actions.saveIsBookingUsingVehicle(false));
        // this.props.dispatch(actions.saveBookingVehicleDetails(''));
        // this.props.dispatch(actions.loadSelectedBookingSuccess({}))
    }

    handleSaveAddNew = (details) => {
        const { selectedRow, isUpdate } = this.state;
        const { tenantUser } = this.props;
        if(details.date === ''|| details.description === '' ){
                this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
                return
        }
        // const isChangeDate = (moment(details.date).format(common.dateFormat) === moment(selectedRow.requestedDate).format(common.dateFormat))
        // const setDate =
        const date = moment(details.date).format(common.dateFormat);
        // const time_ = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),(currentDate.getDate(currentDate)),8,30,0)
        const time = moment(details.date).format(common.timeFormat);
        const setDate = date+"T"+time
        const parameters = {
            id:(isUpdate ? selectedRow.id : 0),
            RequestedDate:setDate,
            Notes: details.notes,
            Status:(isUpdate ? selectedRow.status : "50"),
            UserId: tenantUser && tenantUser.id,
            description : details.description
        }
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendReminderUpdateRequest(parameters))
        }else{
            this.props.dispatch(actions.sendAddNewReminderRequest(parameters))
        }
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadReminderListError(''))
    }

    successModalClose = () => {
        this.getRemainderList()
        this.props.dispatch(actions.sendReminderSuccess(''))
        this.props.dispatch(actions.loadReminderListSuccess([]))
        this.handleModalClose()
        this.props.dispatch(actions.loadReminderListLoading(false))
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(false));
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteBooking = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendReminderDeleteRequest(this.state.selectedRow.id))
    }

    // handleBookingReminder = (booking) => {
    //     if(this.props.tenantUserExpireStatus){
    //         this.props.dispatch(saveClickUnderExpiredStatus(true))
    //         this.handleModalClose()
    //         return
    //     }

    //     if(!booking.customerEmail){
    //         this.setState({isValidationError:true, errorMassage:'Please enter email address' })
    //         this.handleModalClose()
    //     }
    //     else {
    //         this.props.dispatch(actions.sendBookingReminderRequest(booking.id))
    //     }
    // }

    componentDidMount = () =>{
        this.getRemainderList()
    }

    componentDidUpdate (prevProps, prevState){
        const { isChangeStep,resultMonth,calendarView,resultWeek,resultDate } = this.state;
        const { reminderList } = this.props;

        if (this.props.reminderSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
        if(isChangeStep  &&  
            reminderList && reminderList.result && reminderList.result.length && reminderList.result.length > 0  ){
            const newResultDate = new Date(reminderList.result[0].requestedDate)
            const newResultMonth = newResultDate.getMonth(newResultDate)+1
            const startDateWeek = moment(newResultDate).startOf('week').toDate();
            const startTimeDay = moment(newResultDate).startOf('day').toDate();
            var list =[]
            if(calendarView === common.reminder.monthView && newResultMonth === resultMonth){
                for (let i = 0; i < reminderList.result.length; i++) {
                    const element = reminderList.result[i]
                    var EndTime = new Date(element.requestedDate);
                    EndTime.setHours(EndTime.getHours() + 1);
                    const event = {
                        resourceId: element.id,
                        title: element.description,
                        description:element.description,
                        bgColor: new Date(element.requestedDate) > currentDate ? common.reminder.unConfirmColor : common.reminder.confirmedColor,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: EndTime,
                        notes:element.notes,
                        status:element.status,
                        userName: element.firstName +' '+ element.lastName
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }
            else if(calendarView === common.reminder.weekView && Date(startDateWeek) === Date(resultWeek)){
                for (let i = 0; i < reminderList.result.length; i++) {
                    const element = reminderList.result[i]
                    var setEndTime = new Date(element.requestedDate);
                    setEndTime.setHours(setEndTime.getHours() + 1);
                    const event = {
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: setEndTime,
                        resourceId: element.id,
                        title: element.description,
                        description:element.description,
                        bgColor: new Date(element.requestedDate) > currentDate ? common.reminder.unConfirmColor : common.reminder.confirmedColor,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        status:element.status,
                        userName: element.firstName +' '+ element.lastName
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }else if(calendarView === common.reminder.dayView && Date(startTimeDay) === Date(resultDate)){
                for (let i = 0; i < reminderList.result.length; i++) {
                    const element = reminderList.result[i]
                    var set_EndTime = new Date(element.requestedDate);
                    set_EndTime.setHours(set_EndTime.getHours() + 1);
                    const event = {
                        id: element.id,
                        start: new Date(element.requestedDate),
                        end: set_EndTime,
                        resourceId: element.id,
                        title: element.description,
                        description:element.description,
                        bgColor: new Date(element.requestedDate) > currentDate ? common.reminder.unConfirmColor : common.reminder.confirmedColor,
                        requestedDate:moment(element.requestedDate).format(common.dateTimeFormat),
                        notes:element.notes,
                        status:element.status,
                        userName: element.firstName +' '+ element.lastName
                    }
                    list.push(event)
                }
                this.setState( {
                    isChangeStep:false,
                    events:list
                })
            }
        }
        if(this.props.isBookingUsingVehicle && !this.props.isAddNewReminderModalOpen){
            this.props.dispatch(actions.saveIsReminderModalOpenStatus(true))
        }
    }

    onSelectSlot = ({ action, slots /*, ...props */ }) => {
        if (action === "click") {
          this.setState({
              slots:slots,
              isSelectedSlot:true
          })
          this.props.dispatch(actions.saveIsReminderModalOpenStatus(true))   
        }
        return false;
    };

    render() {

        const {
            isReminderListLoading,
            reminderList,
            reportPeriodList,
            isAddNewReminderModalOpen,
            reminderListError,
            reminderSuccess,
            vehicleList,
            selectedCustomer,
            isBookingUsingVehicle,
            bookingVehicleDetails,
            tenantUser
        } = this.props;

        const { 
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            isUpdate
        } = this.state;

        return (
            <div>
                {(isReminderListLoading || 
                    (reportPeriodList && reportPeriodList.length && reminderList && reminderList.result && reminderList.result.length && reminderList.result.length === 0)) ? 
                    <Loader show={isReminderListLoading}/> :''}

                {(isValidationError || reminderListError !== '' ) && 
                    <ErrorModal
                        show={true} 
                        massage={errorMassage ? errorMassage : reminderListError}
                        handleClose={this.errorModalClose}
                    />}

                {(reminderSuccess) && 
                    <SuccessModal
                        show={true} 
                        massage={reminderSuccess}
                        handleClose={this.successModalClose}
                    />}

                {isDeleteModalOpen && 
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteBooking}								
                        title={selectedRow.id}
                    />}
                <div className="headerBtn">
                    <Button
                        onClick={this.handleModal}
                        variant="outline-primary"
                        text='Add New'
                        icon='sys-add'
                    />
                </div>
                <div className="content-body">
                    <div style={{ height: 700 }}>
                        <Calendar
                        localizer={localizer}
                        events={this.state.events}
                        step={30}
                        view={this.state.calendarView}
                        views={['month','week','day']}
                        eventPropGetter={(this.eventStyleGetter)}
                        onNavigate={this.onNavigate}
                        onView={this.onView}
                        // start time 8:00am
                        min={
                            new Date(
                                currentDate.getFullYear(), 
                                currentDate.getMonth(), 
                                currentDate.getDate(), 
                                8
                            )
                        }
                        // end time 5:00pm
                        max={
                            new Date(
                                currentDate.getFullYear(), 
                                currentDate.getMonth(), 
                                currentDate.getDate(), 
                                17
                            )
                       }
                        onSelectEvent={this.handleModal}
                        selectable
                        onSelectSlot={this.onSelectSlot}
                        // date={new Date(2018, 0, 1)}
                        />
                    </div>
                    {/* {this.state.isMaintenancePageShow && <MaintenancePage
                        show={this.state.isMaintenancePageShow}
                    />} */}
                </div>
                {
                    isAddNewReminderModalOpen && 
                        <AddReminder
                            isAddNewReminderModalOpen={isAddNewReminderModalOpen}
                            handleModalClose={this.handleModalClose}
                            handleSaveAddNew={this.handleSaveAddNew}
                            isUpdate={isUpdate}
                            selectedRow={selectedRow}
                            // handleConfirm={this.handleConfirm}
                            vehicleList={vehicleList}
                            selectedCustomer={selectedCustomer}
                            // handleCreateJobcard={this.handleCreateJobcard}
                            isSelectedSlot={this.state.isSelectedSlot}
                            slots={this.state.slots}
                            isBookingUsingVehicle={isBookingUsingVehicle}
                            bookingVehicleDetails={bookingVehicleDetails}
                            handleDeleteConfirm={this.handleDeleteConfirm}
                            tenantUser={tenantUser}
                            // handleBookingReminder={this.handleBookingReminder}
                        />
                    }
            </div>
        )
    }
}

GraphicalCalendar.propTypes = {
    reminderList: PropTypes.any,
    isReminderListLoading: PropTypes.any,
    isAddNewReminderModalOpen: PropTypes.bool,
    reminderListError: PropTypes.any,
    reminderSuccess: PropTypes.any,
    // vehicleList: PropTypes.any,
    // selectedCustomer: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    reminderList: selectors.makeSelectReminderList(),
    isReminderListLoading: selectors.makeSelectReminderLoading(),
    isAddNewReminderModalOpen: selectors.makeSelectReminderPopupOpen(),
    reminderListError: selectors.makeSelectReminderError(),
    reminderSuccess: selectors.makeSelectReminderSucess(),
    tenantUser: makeSelectTenantUser(),
    // vehicleList: makeSelectVehicleList(),
    // selectedCustomer: makeSelectSelectedCustomerSuccess(),
    // bookingVehicleDetails:selectors.makeSelectBookingVehicleDetails(),
    // isBookingUsingVehicle:selectors.makeSelectIsBookingUsingVehicle(),
    // tenantUserExpireStatus: makeSelectTenantUserExpireStatus()
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default withRouter(compose(withConnect)(GraphicalCalendar));
