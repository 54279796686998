import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import SearchField from "../../../layout/form/SearchField";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../../layout/form/Table";
import * as selectors from '../../../../store/selector/userRoleSelector';
import * as actions from '../../../../store/action/userRoleAction';
import AddNewRole from "./AddNewRole";
import Loader from "../../../layout/form/Loader";
import ErrorModal from "../../../layout/form/errorModal";
import SuccessModal from "../../../layout/form/successModal";
import DeleteModal from "../../../layout/form/deleteModal";
import Pagination from "../../../layout/form/Pagination";
import common from "../../../common";
import { makeSelectUserAccessSuccess } from "../../../../store/selector/usersSelector";
import { loadUserAccessRequest } from '../../../../store/action/usersAction';


class UserRoles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commonSearch: '',
            pageNumber: 1,
            pageSize: 20,
            selectedRole: {},
            isUpdate: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            errorMassage: '',
            isValidationError: false
        }
    }

    componentDidMount() {
        this.props.dispatch(actions.saveIsUserRoleModalOpenStatus(false))
        this.props.dispatch(actions.loadObjectListRequest())
        this.getUserRoleList()
    }

    getUserRoleList = () => {
        this.props.dispatch(actions.loadUserRoleObjectListSuccess([]))
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            commonSearch: this.state.commonSearch
        }
        this.props.dispatch(actions.loadUserRoleListRequest(parameters))
    }

    handleChangeSearchValue = (e) => {
        this.setState({
            commonSearch: e.target.value,
            pageSize: 20,
            pageNumber: 1,
        })
    }

    onPressKeySearch = (value) => {
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            commonSearch: value
        }
        this.setState({
            commonSearch: value
        })
        this.props.dispatch(actions.loadUserRoleListRequest(parameters))
    }

    handleModal = (isUpdate, details) => {
        this.setState({
            isUpdate,
            selectedRole: details
        })
        this.props.dispatch(actions.saveIsUserRoleModalOpenStatus(true))
        if (isUpdate) {
            this.props.dispatch(actions.loadUserRoleObjectListRequest(details.id))
        }
    }

    handleClose = () => {
        this.setState({
            isUpdate: false,
            selectedRole: {}
        })
        this.props.dispatch(actions.saveIsUserRoleModalOpenStatus(false))
        this.getUserRoleList()
    }

    handleDeleteConfirm = (data) => {
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteUserRole = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteUserRoleRequest(this.state.selectedRow.id))
    }

    handleList = (data) =>{
        if(data.length === 0){
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.recordIndex=i+1
            data[i]=element

            if(i === data.length-1){
                return (data)
            }
        }
    }

    handleAddNewRole = (details) => {
        const { isUpdate, selectedRole } = this.state;
        if (details.roleName === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredRoleNameMassage })
            return
        }
        const tempItemList = details.tempList.filter(data => (data.operation === "D"))
        const itemList = this.handleList(details.list.concat(tempItemList))

        const object = itemList.find(select => select.operation === "C")
        if (object && object.operationList && object.operationList.length <= 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredRoleOperationMassage + ' - ' + object.description })
            return
        }

        const parameters = {
            description: details.roleName,
            businessTypeId: "02",
            objectList: itemList,
            id: selectedRole && selectedRole.id ? selectedRole.id : 0
        }
        if (isUpdate) {
            this.props.dispatch(actions.sendUpdateUserRoleRequest(parameters))
        } else
            this.props.dispatch(actions.sendAddNewUserRoleRequest(parameters))
    }

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add new role
        </Tooltip>
    );

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadUserRoleError(null))
    }

    successModalClose = () => {
        this.props.dispatch(actions.loadUserRoleObjectListSuccess([]))
        this.props.dispatch(actions.sendUserRoleSuccess(null))
        this.props.dispatch(loadUserAccessRequest())
        this.handleClose()
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getUserRoleList()
        }
    }

    render() {

        const {
            userAccess,
            roleList,
            isAddNewUserRoleModalOpen,
            objectList,
            loading,
            success,
            error
        } = this.props;

        const {
            selectedRole,
            isUpdate,
            isDeleteModalOpen,
            selectedRow
        } = this.state;

        const columns = [{
            Header: 'Role name',
            accessor: 'description',
            Cell: ((row) => (
                <div onClick={() => this.handleModal(true,row.row.original)} style={{ cursor: 'pointer' }}>
                    {row.row.original.description}
                </div>
            ))
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(true, row.row.original)}><ui5-icon class="samples-margin" name="display" id="table-icon"></ui5-icon></span>
                        <span onClick={userAccess && userAccess.deleteUserRole ? () => this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id={userAccess && userAccess.deleteUserRole ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                        {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {loading ?
                    <Loader show={loading} /> : ''}

                {(this.state.isValidationError || error !== null) &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage ? this.state.errorMassage : error}
                        handleClose={this.errorModalClose}
                    />}
                {success !== null &&
                    <SuccessModal
                        show={true}
                        massage={success}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteUserRole}
                        title={selectedRow.description}
                    />}

                {isAddNewUserRoleModalOpen ?
                    <AddNewRole
                        handleClose={this.handleClose}
                        objectList={objectList}
                        handleAddNewRole={this.handleAddNewRole}
                        selectedRole={selectedRole}
                        isUpdate={isUpdate}
                        userAccess={userAccess}
                    /> :
                    <div>
                        <div className="content-header">
                            <div className="header-pages">
                                <ui5-icon class="samples-margin" name="person-placeholder" id="header-icon"></ui5-icon><span>USER ROLES</span>
                            </div>
                        </div>
                        <div className="content-body">
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <h5 style={{ marginLeft: '5px' }}>User roles list</h5>
                                    </div>
                                    <div style={{ marginLeft: '20px' }}>
                                        <SearchField
                                            onClickSearch={this.getUserRoleList}
                                            controlId="commonSearch"
                                            onChange={this.handleChangeSearchValue}
                                            defaultValue={this.state.commonSearch}
                                            onPressKeySearch={this.onPressKeySearch}
                                        />
                                    </div>
                                </div>
                                <div className="header-btn">
                                    <div style={{ display: 'flex' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.addTooltip}
                                        >
                                            <span className={userAccess && userAccess.createUserRole ? "header-icon" : "header-icon-disabled"} onClick={userAccess && userAccess.createUserRole ? () => this.handleModal(false) : ''}>
                                                <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-content">
                            <Table
                                columns={columns}
                                data={roleList && roleList.result ? roleList.result : []}
                            />
                        </div>
                        <Pagination
                            currentPage={roleList.page ? roleList.page : this.state.pageNumber}
                            getNextPage={this.getNextPage}
                            getPrevPage={this.getPrevPage}
                            totalPage={roleList.totalPages}
                            setClickedPage={this.setClickedPage}
                            currentPageSize={this.state.pageSize}
                            setPageSize={this.setPageSize}
                        />
                    </div>}
            </div>
        )
    }
}


const mapStateToProps = createStructuredSelector({
    roleList: selectors.makeUserRoleListSuccess(),
    isAddNewUserRoleModalOpen: selectors.makeSelectUserRolePopupOpen(),
    objectList: selectors.makeObjectListSuccess(),
    loading: selectors.makeSelectUserRoleLoading(),
    success: selectors.makeSelectUserRoleSuccess(),
    error: selectors.makeSelectUserRoleError(),
    userAccess: makeSelectUserAccessSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(UserRoles));