import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import * as selectors from '../../../../store/selector/quotationSelector';
import * as actions from '../../../../store/action/quotationAction';
// import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import { Link } from 'react-router-dom';
import Table from '../../../layout/form/Table';
import AddQuotation from './AddQuotation';
import { makeSelectCustomerList } from '../../../../store/selector/customerSelector';
import { loadCustomerListRequest, loadCustomerListSuccess, loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';
import moment from 'moment';
import common, { numberWithCommas } from '../../../common';
import { loadTenantFinanceRequest } from '../../../../store/action/tenantAction';
import { makeSelectTenantFinance } from '../../../../store/selector/tenantSelector';
import PdfViewer from '../../../layout/form/PdfViewer';
import Pagination from '../../../layout/form/Pagination';
import SelectField from '../../../layout/form/SelectField';
import { loadInvoiceListSuccess } from '../../../../store/action/invoiceAction';
import { loadProductListRequest } from '../../../../store/action/productAction';
// import Pagination from '../../../layout/form/Pagination';
const dateFormat = sessionStorage.getItem("dateFormat")

class Quotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            isValidationError: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            errorMassage: '',
            isUpdate: false,
            pageNumber: 1,
            pageSize: 20,
            quotationNo: 0,
            vehicleId: 0,
            customerId: 0,
            statusId: '',
            isPrintModalOpen: false,
        }
    }

    handleModal = (data) => {
        if (data && data.id) {
            this.props.dispatch(actions.loadSelectedQuotationRequest(data.id))
            this.setState({ selectedRow: data, isUpdate: true })
        }
        this.props.dispatch(actions.saveIsQuotationModalOpenStatus(true))
    }

    handleClose = () => {
        this.setState({ selectedRow: {}, isUpdate: false })
        this.props.dispatch(actions.saveIsQuotationModalOpenStatus(false));
        this.props.dispatch(actions.loadSelectedQuotationSuccess(''));
        this.props.dispatch(loadSelectedCustomerSuccess(''))
        this.getQuotationListData()
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadQuotationListError(''))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendQuotationSuccess(''))
        this.handleClose()
    }

    handleDeleteConfirm = (data) => {
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteQuotation = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteQuotationRequest(this.state.selectedRow.id))
    }

    handlePrintById = (data) => {
        this.setState({ isPrintModalOpen: true })
        this.props.dispatch(actions.sendPrintQuotationRequest(data.id))
    }

    handleSendEmailById = (data) => {
        this.props.dispatch(actions.sendEmailQuotationRequest(data.id))
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen
        })
    }

    handleFilter = () => {
        this.getQuotationListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () => {
        this.setState({
            isFilterFormOpen: false,
            quotationNo: 0,
            vehicleId: 0,
            customerId: 0,
            statusId: '',
            pageSize: 20,
            pageNumber: 1,
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            quotationNo: 0,
            vehicleId: 0,
            customerId: 0,
            statusId: '',
        }
        this.props.dispatch(actions.loadQuotationListRequest(parameters))
    }

    setDate = (value) => {
        if (value === '' || value === null)
            return ''

        const date = new Date(value)
        const formatedDate = moment(date).format(common.dateFormat);
        return formatedDate
    }

    handleSaveAddNew = (details) => {
        const { selectedQuotation } = this.props
        if (details.customer === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const tempServiceActivities = details.tempServiceList.filter(data => (data.operation === "D"))
        const ServiceActivities = this.handleList(details.Service.concat(tempServiceActivities))

        const tempRepair = details.tempRepairList.filter(data => (data.operation === "D"))
        const Repairs = this.handleList(details.Repair.concat(tempRepair))

        const tempPart = details.tempPartList.filter(data => (data.operation === "D"))
        const Parts = this.handleList(details.Parts.concat(tempPart))

        const Notes = this.handleNotes(details)
        const SrvScheduleActivities = this.handleServiceSchedule(details, selectedQuotation)

        const tempImage = details.tempImageList.filter(data => (data.operation === "D"))
        const Images = this.handleList(details.ImagesList.concat(tempImage))
        var ImagesList = []

        if (Images.length > 0) {
            for (let i = 0; i < Images.length; i++) {
                const row = Images[i]
                const newRow = {
                    name: row.name,
                    description: row.description,
                    id: (row.id ? row.id : 0),
                    operation: row.operation
                }
                ImagesList.push(newRow)
            }
        }
        var isAddImages = details.isAddImages;
        const addImages = ImagesList.find(element => (element.id === 0))
        if (addImages && addImages.id === 0) {
            isAddImages = true
        } else {
            isAddImages = false
        }

        const parameters = {
            id: (selectedQuotation && selectedQuotation.id && this.state.isUpdate) ? selectedQuotation.id : '',
            quotationDate: this.setDate(details.date),
            buildDate: this.setDate(details.buildDate),
            documentTitle: "TODO",
            customerName: details.displayName,
            companyName: details.companyName,
            customerEmail: details.email,
            modelVariant: details.modelVariant,
            currentMileage: details.currentMileage,
            address: details.address,
            customerId: details.customer,
            vehicleId: details.vehicleId,
            registrationNo: details.registrationNo,
            engineNo: details.engineNo,
            identificationNo: details.vehicleIdentificationNo,
            makeId: details.make,
            modelId: details.model,
            statusId: '02',//details.status,
            subTotal: details.subTotal,
            tax: details.tax,
            taxRate: details.taxRate,
            amountTotal: details.total,
            serviceActivities: ServiceActivities,
            repairs: Repairs,
            parts: Parts,
            note: Notes,
            serviceScheduleActivities: SrvScheduleActivities,
            fileList: details.fileList,
            isAddImages: isAddImages,
            Images: ImagesList
        }
        if (this.state.isUpdate) {
            this.props.dispatch(actions.sendUpdateQuotationRequest(parameters))
        }
        else
            this.props.dispatch(actions.sendAddNewQuotationRequest(parameters))

    }

    handlePrint = (details) => {
        const { selectedQuotation } = this.props
        if (details.customer === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const tempServiceActivities = details.tempServiceList.filter(data => (data.operation === "D"))
        const ServiceActivities = this.handleList(details.Service.concat(tempServiceActivities))

        const tempRepair = details.tempRepairList.filter(data => (data.operation === "D"))
        const Repairs = this.handleList(details.Repair.concat(tempRepair))

        const tempPart = details.tempPartList.filter(data => (data.operation === "D"))
        const Parts = this.handleList(details.Parts.concat(tempPart))

        const Notes = this.handleNotes(details)
        const SrvScheduleActivities = this.handleServiceSchedule(details, selectedQuotation)

        if (this.state.isUpdate) {
            const parameters = {
                quotationDetails: {
                    id: selectedQuotation.id,
                    quotationDate: this.setDate(details.date),
                    buildDate: this.setDate(details.buildDate),
                    documentTitle: "TODO",
                    customerName: details.displayName,
                    companyName: details.companyName,
                    customerEmail: details.email,
                    modelVariant: details.modelVariant,
                    currentMileage: details.currentMileage,
                    address: details.address,
                    customerId: details.customer,
                    vehicleId: details.vehicleId,
                    registrationNo: details.registrationNo,
                    engineNo: details.engineNo,
                    identificationNo: details.vehicleIdentificationNo,
                    makeId: details.make,
                    modelId: details.model,
                    statusId: '02',//details.status,
                    subTotal: details.subTotal,
                    tax: details.tax,
                    taxRate: details.taxRate,
                    amountTotal: details.total,
                    serviceActivities: ServiceActivities,
                    repairs: Repairs,
                    parts: Parts,
                    note: Notes,
                    serviceScheduleActivities: SrvScheduleActivities,
                }
            }
            this.props.dispatch(actions.sendUpdateAndPrintQuotationRequest(parameters))
        } else {
            const parameters = {
                action: "P",
                quotationDate: this.setDate(details.date),
                buildDate: this.setDate(details.buildDate),
                documentTitle: "TODO",
                customerName: details.displayName,
                companyName: details.companyName,
                customerEmail: details.email,
                modelVariant: details.modelVariant,
                currentMileage: details.currentMileage,
                address: details.address,
                customerId: details.customer,
                vehicleId: details.vehicleId,
                registrationNo: details.registrationNo,
                engineNo: details.engineNo,
                identificationNo: details.vehicleIdentificationNo,
                makeId: details.make,
                modelId: details.model,
                statusId: '02',//details.status,
                subTotal: details.subTotal,
                tax: details.tax,
                taxRate: details.taxRate,
                amountTotal: details.total,
                serviceActivities: ServiceActivities,
                repairs: Repairs,
                parts: Parts,
                note: Notes,
                serviceScheduleActivities: SrvScheduleActivities,
            }
            this.props.dispatch(actions.sendAddAndPrintQuotationRequest(parameters))
        }
        this.setState({ isPrintModalOpen: true })
    }

    printModalClose = () => {
        this.setState({ isPrintModalOpen: false })
        this.props.dispatch(actions.sendPrintQuotationSuccess(''))
    }

    handleSendEmail = (details) => {
        const { selectedQuotation } = this.props
        if (details.customer === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        const tempServiceActivities = details.tempServiceList.filter(data => (data.operation === "D"))
        const ServiceActivities = this.handleList(details.Service.concat(tempServiceActivities))

        const tempRepair = details.tempRepairList.filter(data => (data.operation === "D"))
        const Repairs = this.handleList(details.Repair.concat(tempRepair))

        const tempPart = details.tempPartList.filter(data => (data.operation === "D"))
        const Parts = this.handleList(details.Parts.concat(tempPart))

        const Notes = this.handleNotes(details)
        const SrvScheduleActivities = this.handleServiceSchedule(details, selectedQuotation)

        if (this.state.isUpdate) {
            const parameters = {
                quotationDetails: {
                    id: selectedQuotation.id,
                    quotationDate: this.setDate(details.date),
                    buildDate: this.setDate(details.buildDate),
                    documentTitle: "TODO",
                    customerName: details.displayName,
                    companyName: details.companyName,
                    customerEmail: details.email,
                    modelVariant: details.modelVariant,
                    currentMileage: details.currentMileage,
                    address: details.address,
                    customerId: details.customer,
                    vehicleId: details.vehicleId,
                    registrationNo: details.registrationNo,
                    engineNo: details.engineNo,
                    identificationNo: details.vehicleIdentificationNo,
                    makeId: details.make,
                    modelId: details.model,
                    statusId: '02',//details.status,
                    subTotal: details.subTotal,
                    tax: details.tax,
                    taxRate: details.taxRate,
                    amountTotal: details.total,
                    serviceActivities: ServiceActivities,
                    repairs: Repairs,
                    parts: Parts,
                    note: Notes,
                    serviceScheduleActivities: SrvScheduleActivities,
                }
            }
            this.props.dispatch(actions.sendUpdateAndEmailQuotationRequest(parameters))
        } else {
            const parameters = {
                action: "E",
                quotationDate: this.setDate(details.date),
                buildDate: this.setDate(details.buildDate),
                documentTitle: "TODO",
                customerName: details.displayName,
                companyName: details.companyName,
                customerEmail: details.email,
                modelVariant: details.modelVariant,
                currentMileage: details.currentMileage,
                address: details.address,
                customerId: details.customer,
                vehicleId: details.vehicleId,
                registrationNo: details.registrationNo,
                engineNo: details.engineNo,
                identificationNo: details.vehicleIdentificationNo,
                makeId: details.make,
                modelId: details.model,
                statusId: '02',//details.status,
                subTotal: details.subTotal,
                tax: details.tax,
                taxRate: details.taxRate,
                amountTotal: details.total,
                serviceActivities: ServiceActivities,
                repairs: Repairs,
                parts: Parts,
                note: Notes,
                serviceScheduleActivities: SrvScheduleActivities,
            }
            this.props.dispatch(actions.sendAddNewQuotationRequest(parameters))
        }
    }

    handleList = (data) => {
        if (data.length === 0) {
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.index = i + 1
            data[i] = element

            if (i === data.length - 1) {
                return (data)
            }
        }
    }

    handleNotes = (details) => {
        var Notes = []
        if (details.noteToCustomer === '' && details.internalNote === '') {
            Notes = []
        }

        if (details.noteToCustomer !== '') {
            Notes.push({
                Note: details.noteToCustomer,
                NoteType: "c",
                Operation: details.noteToCustomerId ? "U" : "C",
                id: details.noteToCustomerId
            })
        }

        if (details.noteToCustomer === '' && details.noteToCustomerId) {
            Notes.push({
                Note: details.noteToCustomer,
                NoteType: "c",
                Operation: "D",
                id: details.noteToCustomerId
            })
        }

        if (details.internalNote !== '') {
            Notes.push({
                Note: details.internalNote,
                NoteType: "i",
                Operation: details.internalNoteId ? "U" : "C",
                id: details.internalNoteId
            })
        }

        if (details.internalNote === '' && details.internalNoteId) {
            Notes.push({
                Note: details.internalNote,
                NoteType: "i",
                Operation: "D",
                id: details.internalNoteId
            })
        }

        return Notes
    }

    handleServiceSchedule = (details, selectedQuotation) => {
        var SrvScheduleActivities = []

        if (details.ServiceScheduleId !== 0 && details.ServiceScheduleId !== '') {
            SrvScheduleActivities.push({
                ServiceScheduleId: details.ServiceScheduleId,
                Description: details.Description,
                UnitPrice: parseFloat(details.UnitPrice).toFixed(2),
                EstimatedQuantity: parseFloat(details.EstimatedQuantity).toFixed(2),
                id: details.id,
                Operation: (details.id !== 0) ? "U" : "C",
                unitOfMeasure: details.serviceSchUOM,
                Amount: details.UnitPrice * details.EstimatedQuantity
            })
        } else if (details.id && details.ServiceScheduleId === '') {
            SrvScheduleActivities.push({
                // ServiceScheduleId:details.ServiceScheduleId ,
                // Description:details.Description,
                // UnitPrice:0,
                // EstimatedQuantity:0,
                id: details.id,
                ServiceScheduleId: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseInt(selectedQuotation.serviceScheduleActivities[0].serviceScheduleId),
                Description: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && selectedQuotation.serviceScheduleActivities[0].description,
                UnitPrice: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseFloat(selectedQuotation.serviceScheduleActivities[0].unitPrice).toFixed(2),
                EstimatedQuantity: selectedQuotation.serviceScheduleActivities.length && selectedQuotation.serviceScheduleActivities.length !== 0 && parseFloat(selectedQuotation.serviceScheduleActivities[0].estimatedQuantity).toFixed(2),
                Operation: "D",
                unitOfMeasure: details.serviceSchUOM
            })
        }
        return SrvScheduleActivities
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    getQuotationListData = () => {
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            quotationNo: this.state.quotationNo,
            vehicleId: this.state.vehicleId,
            customerId: this.state.customerId,
            statusId: this.state.statusId,
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.props.dispatch(actions.loadQuotationListRequest(parameters))
    }

    redirectCustomer = () => {
        this.props.dispatch(loadCustomerListSuccess([]))
    }

    redirectInvoice = () => {
        this.props.dispatch(loadInvoiceListSuccess([]))
        this.props.dispatch(loadCustomerListSuccess([]))
    }

    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        const parameters = {
            pageSize: 9999,
            pageNumber: 1,
            name: '',
            email: '',
            mobile: '',
            city: '',
            makeId: '',
            Customer: '',
            Make: '',
            Model: '',
            Search: '',
            Status: '',
            status: '',
            codeId: '',
            categoryId: '',
            partId: '',
            description: '',
            commonSearch: '',
            sku: '',
            purchasingFlg: false
        }
        this.getQuotationListData()
        this.props.dispatch(actions.saveIsQuotationModalOpenStatus(false));
        this.props.dispatch(loadCustomerListRequest(parameters))
        this.props.dispatch(loadProductListRequest(parameters))
        this.props.dispatch(loadTenantFinanceRequest(id))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.quotationSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }

        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getQuotationListData()
        }

    }

    render() {
        const {
            isAddNewQuotationModalOpen,
            isQuotationListLoading,
            quotationList,
            quotationListError,
            quotationSuccess,
            customerList,
            selectedQuotation,
            tenantFinance,
            quotationPrint,
            // vehicleList
        } = this.props;

        const {
            isValidationError,
            errorMassage,
            isFilterFormOpen,
            selectedRow,
            isUpdate,
            isDeleteModalOpen,
            isPrintModalOpen
        } = this.state;

        const columns = [{
            Header: 'Number',
            accessor: 'documentNumber',
            width: "10%",
        }, {
            Header: 'Customer',
            accessor: 'customerName',
        }, {
            // Header: 'Amount',
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            accessor: 'amountTotal',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.amountTotal).toFixed(2))}
                </div>
            ))
        }, {
            Header: 'Date',
            Cell: ((row) => (
                <div >
                    {row.row.original.quotationDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.quotationDate)}
                </div>
            )),
            width: "10%",
            //   }, {
            //     Header: 'Status',
            //     accessor: 'statusId',
            //     width: "10%",
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {/* <Button */}
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        {/* <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>   */}
                        <span className="dropdown" style={{ float: 'right' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={() => this.handleDeleteConfirm(row.row.original)}>Delete</li>
                                <li onClick={() => this.handleSendEmailById(row.row.original)}>Email</li>
                                <li onClick={() => this.handlePrintById(row.row.original)}>Print</li>
                                {/* <li onClick={()=>this.handleCreateJobCard(row.row.original)}>Create Job Card</li> */}
                            </div>
                        </span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {(isQuotationListLoading ||
                    (quotationList.result && quotationList.result.length && quotationList.result.length === 0)) ?
                    <Loader show={isQuotationListLoading} /> : ''}

                {(isValidationError || quotationListError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : quotationListError}
                        handleClose={this.errorModalClose}
                    />}

                {(quotationSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={quotationSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteQuotation}
                        title={selectedRow.id}
                    />}
                {!isAddNewQuotationModalOpen &&
                    <div className="content-header">
                        <div className="header-pages">
                            <span><Link to='./customer' onClick={() => this.redirectCustomer()}><ui5-icon class="samples-margin" name="customer" id="tab-icon"></ui5-icon> Customer </Link></span>
                            <span><Link to='./quotation'><ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Quotation</Link></span>
                            <span><Link to='./invoice' onClick={() => this.redirectInvoice()}><ui5-icon class="samples-margin" name="monitor-payments" id="tab-icon"></ui5-icon> Invoice</Link></span>
                            {/* <span><Link to='./reports'><ui5-icon class="samples-margin" name="bar-chart" id="tab-icon"></ui5-icon> Reports</Link></span> */}
                        </div>
                        <div className="header-btn">
                            <ButtonGroup
                                primaryBtnClick={this.handleModal}
                                secondaryBtnClick={this.handleFilterForm}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Add New'
                                primaryBtnIcon='sys-add'
                                secondaryBtnText='Filter'
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnIcon='filter'
                            />
                        </div>
                    </div>}
                <div className="content-body">
                    {isAddNewQuotationModalOpen ?
                        <AddQuotation
                            handleClose={this.handleClose}
                            customerList={customerList.result}
                            handleSaveAddNew={this.handleSaveAddNew}
                            selectedQuotation={selectedQuotation}
                            selectedRow={selectedRow}
                            isUpdate={isUpdate}
                            tenantFinance={tenantFinance}
                            handlePrint={this.handlePrint}
                            isPrintModalOpen={isPrintModalOpen}
                            quotationPrint={quotationPrint}
                            printModalClose={this.printModalClose}
                            handleSendEmail={this.handleSendEmail}
                        />
                        :
                        <div>
                            <h5 style={{ marginLeft: '5px' }}>Quotations</h5>
                            {isFilterFormOpen &&
                                <div className="filter-form">
                                    <div className="filter-header">
                                        <h5>Filter Form</h5>
                                        <div className="btn-close" onClick={this.handleFilterFormClose} >
                                            <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="Quotation number"
                                                controlId="quotationNo"
                                                placeholder="Quotation number"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.quotationNo}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <SelectField
                                                text="Customer"
                                                controlId="customerId"
                                                options={
                                                    customerList && customerList.result && customerList.result.length && customerList.result.length > 0 ?
                                                        customerList.result.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ?
                                                            1 : ((b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 0)) : []}
                                                selectedValue={this.state.customerId}
                                                display='displayName'
                                                onChange={(e) => this.handleSelect(e, 'customerId')}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-btns">
                                        <ButtonGroup
                                            primaryBtnClick={this.handleFilter}
                                            primaryBtnIcon='search'
                                            primaryBtnText='Search'
                                            primaryBtnVariant="outline-primary"
                                            secondaryBtnClick={this.handleFilterFormClose}
                                            secondaryBtnIcon='clear-filter'
                                            secondaryBtnText='Clear'
                                            secondaryBtnVariant="outline-primary"
                                        />
                                    </div>
                                </div>
                            }
                            <div className="table-content">
                                <Table
                                    columns={columns}
                                    data={quotationList.result && quotationList.result.length ? quotationList.result : []}
                                />
                            </div>

                            <Pagination
                                currentPage={quotationList.page ? quotationList.page : this.state.pageNumber}
                                getNextPage={this.getNextPage}
                                getPrevPage={this.getPrevPage}
                                totalPage={quotationList.totalPages}
                                setClickedPage={this.setClickedPage}
                                currentPageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                            {
                                isPrintModalOpen && quotationPrint !== '' &&
                                <PdfViewer
                                    show={isPrintModalOpen}
                                    pdf={quotationPrint}
                                    onHide={this.printModalClose}
                                    title="Quotation"
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}



Quotation.propTypes = {
    isAddNewQuotationModalOpen: PropTypes.bool,
    quotationList: PropTypes.any,
    isQuotationListLoading: PropTypes.bool,
    quotationListError: PropTypes.any,
    quotationSuccess: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedQuotation: PropTypes.any,
    customerList: PropTypes.any,
    tenantFinance: PropTypes.any,
    quotationPrint: PropTypes.any,
    vehicleList: PropTypes.any,
    selectedVehicle: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isAddNewQuotationModalOpen: selectors.makeSelectQuotationPopupOpen(),
    quotationList: selectors.makeSelectQuotationList(),
    isQuotationListLoading: selectors.makeSelectQuotationListLoading(),
    quotationListError: selectors.makeSelectQuotationListError(),
    quotationSuccess: selectors.makeSelectQuotationSuccess(),
    selectedQuotation: selectors.makeSelectSelectedQuotationSuccess(),
    customerList: makeSelectCustomerList(),
    tenantFinance: makeSelectTenantFinance(),
    quotationPrint: selectors.makeSelectPrintQuotationSuccess(),
    // vehicleList: makeSelectVehicleList(),
    // selectedVehicle: makeSelectSelectedVehicleSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Quotation));