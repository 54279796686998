import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import EmailNotification from './EmailNotification';
import SMSNotification from './SMSNotification';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmailNotification: true
        }
    }

    redirectEmailList = () => {
        this.setState({
            isEmailNotification: true
        })
    }

    redirectSMSList = () => {
        this.setState({
            isEmailNotification: false
        })
        // this.props.dispatch(loadReminderListSuccess([]))
    }

    render() {

        const {
            isEmailNotification
        } = this.state;

        return (
            <div>
                <div className="content-header">
                    <div className="header-pages">
                        <span><Link onClick={() => this.redirectEmailList()} ><ui5-icon class="samples-margin" name="email" id="tab-icon"></ui5-icon> Email</Link></span>
                        <span><Link onClick={() => this.redirectSMSList()}><ui5-icon class="samples-margin" name="message-popup" id="tab-icon"></ui5-icon> SMS</Link></span>
                    </div>
                </div>
                {
                    isEmailNotification ?
                        <EmailNotification /> :
                        <SMSNotification />
                }
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapDispatchToProps,
);


export default (compose(withConnect)(Notification));
