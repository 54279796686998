const PATH = 'app/Quotation';

export const SAVE_IS_QUOTATION_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_QUOTATION_MODAL_OPEN_STATUS`;
export const LOAD_QUOTATION_LIST_REQUEST = `${PATH}LOAD_QUOTATION_LIST_REQUEST`;
export const LOAD_QUOTATION_LIST_ERROR = `${PATH}LOAD_QUOTATION_LIST_ERROR`;
export const LOAD_QUOTATION_LIST_LOADING = `${PATH}LOAD_QUOTATION_LIST_LOADING`;
export const LOAD_QUOTATION_LIST_SUCCESS = `${PATH}LOAD_QUOTATION_LIST_SUCCESS`;
export const SEND_ADD_NEW_QUOTATION_REQUEST = `${PATH}SEND_ADD_NEW_QUOTATION_REQUEST`;
export const SEND_QUOTATION_SUCCESS = `${PATH}SEND_QUOTATION_SUCCESS`;
export const SEND_UPDATE_QUOTATION_REQUEST = `${PATH}SEND_UPDATE_QUOTATION_REQUEST`;
export const SEND_DELETE_QUOTATION_REQUEST = `${PATH}SEND_DELETE_QUOTATION_REQUEST`;
export const LOAD_SELECTED_QUOTATION_REQUEST = `${PATH}LOAD_SELECTED_QUOTATION_REQUEST`;
export const LOAD_SELECTED_QUOTATION_SUCCESS = `${PATH}LOAD_SELECTED_QUOTATION_SUCCESS`;
export const SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST = `${PATH}SEND_UPDATE_AND_PRINT_QUOTATION_REQUEST`;
export const SEND_ADD_AND_PRINT_QUOTATION_REQUEST = `${PATH}SEND_ADD_AND_PRINT_QUOTATION_REQUEST`;
export const SEND_PRINT_QUOTATION_SUCCESS = `${PATH}SEND_PRINT_QUOTATION_SUCCESS`;
export const SEND_PRINT_QUOTATION_REQUEST = `${PATH}SEND_PRINT_QUOTATION_REQUEST`;
export const SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST = `${PATH}SEND_UPDATE_AND_EMAIL_QUOTATION_REQUEST`;
export const SEND_EMAIL_QUOTATION_SUCCESS = `${PATH}SEND_EMAIL_QUOTATION_SUCCESS`;
export const SEND_EMAIL_QUOTATION_REQUEST = `${PATH}SEND_EMAIL_QUOTATION_REQUEST`;
export const SEND_QUOTATION_IMAGE_REQUEST = `${PATH}SEND_QUOTATION_IMAGE_REQUEST`;
export const SEND_QUOTATION_IMAGE_SUCCESS = `${PATH}SEND_QUOTATION_IMAGE_SUCCESS`;
export const SEND_QUOTATION_IMAGE_EMAIL_REQUEST = `${PATH}SEND_QUOTATION_IMAGE_EMAIL_REQUEST`;
export const SEND_QUOTATION_IMAGE_EMAIL_ERROR = `${PATH}SEND_QUOTATION_IMAGE_EMAIL_ERROR`;
export const SEND_QUOTATION_IMAGE_EMAIL_SUCCESS = `${PATH}SEND_QUOTATION_IMAGE_EMAIL_SUCCESS`;
export const SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_QUOTATION_IMAGE_EMAIL_MODAL_OPEN_STATUS`;