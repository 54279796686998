import { 
    LOAD_SUPPLIER_LIST_ERROR,
    LOAD_SUPPLIER_LIST_LOADING,
    LOAD_SUPPLIER_LIST_REQUEST,
    LOAD_SUPPLIER_LIST_SUCCESS,
    LOAD_SELECTED_SUPPLIER_SUCCESS,
    SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_SUPPLIER_REQUEST,
    SEND_SUPPLIER_SUCCESS,
    SEND_DELETE_SUPPLIER_REQUEST,
    SEND_UPDATE_SUPPLIER_REQUEST,
    LOAD_SELECTED_SUPPLIER_REQUEST
    } from '../constant/supplierConstant';
    
    export function saveIsSupplierModalOpenStatus(open){
      return {
        type: SAVE_IS_SUPPLIER_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadSupplierListRequest(data) {
      return {
        type: LOAD_SUPPLIER_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadSupplierListLoading(data) {
      return {
        type: LOAD_SUPPLIER_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadSupplierListSuccess(data) {
      return {
        type: LOAD_SUPPLIER_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadSupplierListError(data) {
      return {
        type: LOAD_SUPPLIER_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewSupplierRequest(data) {
      return {
        type: SEND_ADD_NEW_SUPPLIER_REQUEST,
        payload: data,
      }
    };
    
    export function sendSupplierSuccess(data) {
      return {
        type: SEND_SUPPLIER_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateSupplierRequest(data) {
      return {
        type: SEND_UPDATE_SUPPLIER_REQUEST,
        payload: data,
      }
    };
    
    export function sendDeleteSupplierRequest(data) {
      return {
        type: SEND_DELETE_SUPPLIER_REQUEST,
        payload: data,
      }
    };

    export function loadSelectedSupplierSuccess(data) {
      return {
        type: LOAD_SELECTED_SUPPLIER_SUCCESS,
        payload: data,
      }
    };
  
    export function loadSelectedSupplierRequest(data) {
      return {
        type:LOAD_SELECTED_SUPPLIER_REQUEST,
        payload: data,
      }
    };
    
    