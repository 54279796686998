import { 
  LOAD_REPAIR_CATEGORY_LIST_REQUEST,
  LOAD_REPAIR_CATEGORY_LIST_SUCCESS,
  LOAD_REPAIR_LIST_ERROR,
  LOAD_REPAIR_LIST_LOADING,
  LOAD_REPAIR_LIST_REQUEST,
  LOAD_REPAIR_LIST_SUCCESS,
  SAVE_IS_REPAIR_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_REPAIR_REQUEST,
  SEND_DELETE_REPAIR_REQUEST,
  SEND_REPAIR_SUCCESS,
  SEND_UPDATE_REPAIR_REQUEST,
  } from '../constant/repairConstant';
  
  export function saveIsRepairModalOpenStatus(open){
    return {
      type: SAVE_IS_REPAIR_MODAL_OPEN_STATUS,
      open,
    }
  };

  export function loadRepairListRequest(data) {
    return {
      type: LOAD_REPAIR_LIST_REQUEST,
      payload: data,
    }
  };
  
  export function loadRepairListLoading(data) {
    return {
      type: LOAD_REPAIR_LIST_LOADING,
      payload: data,
    }
  };
    
  export function loadRepairListSuccess(data) {
    return {
      type: LOAD_REPAIR_LIST_SUCCESS,
      payload: data,
    }
  };
  
  export function loadRepairListError(data) {
    return {
      type: LOAD_REPAIR_LIST_ERROR,
      payload: data,
    }
  };

  export function sendAddNewRepairRequest(data) {
    return {
      type: SEND_ADD_NEW_REPAIR_REQUEST,
      payload: data,
    }
  };
  
  export function sendRepairSuccess(data) {
    return {
      type: SEND_REPAIR_SUCCESS,
      payload: data,
    }
  };
  
  export function sendUpdateRepairRequest(data) {
    return {
      type: SEND_UPDATE_REPAIR_REQUEST,
      payload: data,
    }
  };
  
  export function sendDeleteRepairRequest(data) {
    return {
      type: SEND_DELETE_REPAIR_REQUEST,
      payload: data,
    }
  };
  
  export function loadRepairCategoryListRequest() {
    return {
      type: LOAD_REPAIR_CATEGORY_LIST_REQUEST
    }
  };
  
  export function loadRepairCategoryListSuccess(data) {
    return {
      type: LOAD_REPAIR_CATEGORY_LIST_SUCCESS,
      payload: data,
    }
  };