import {
  SEND_PURCHASING_ADD_PRINT_REQUEST,
  LOAD_PURCHASING_LIST_ERROR,
  LOAD_PURCHASING_LIST_LOADING,
  LOAD_PURCHASING_LIST_REQUEST,
  LOAD_PURCHASING_LIST_SUCCESS,
  LOAD_PURCHASING_PRINT_BY_ID_REQUEST,
  // LOAD_PURCHASING_PRINT_BY_ID_SUCCESS,
  SEND_PURCHASING_PRINT_REQUEST,
  LOAD_PURCHASING_PRINT_SUCCESS,
  LOAD_PURCHASING_SEND_BY_ID_REQUEST,
  // LOAD_PURCHASING_SEND_BY_ID_SUCCESS,
  SEND_PURCHASING_SEND_REQUEST,
  LOAD_PURCHASING_SEND_SUCCESS,
  LOAD_SELECTED_PURCHASING_REQUEST,
  LOAD_SELECTED_PURCHASING_SUCCESS,
  SAVE_IS_PURCHASING_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_PURCHASING_REQUEST,
  SEND_DELETE_PURCHASING_REQUEST,
  SEND_PURCHASING_SUCCESS,
  SEND_UPDATE_PURCHASING_REQUEST,
  SEND_PURCHASING_ADD_SEND_REQUEST,
  SEND_STOCK_DOCUMENT_REQUEST,
} from '../constant/purchasingConstant';

export function saveIsPurchasingModalOpenStatus(open) {
  return {
    type: SAVE_IS_PURCHASING_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadPurchasingListRequest(data) {
  return {
    type: LOAD_PURCHASING_LIST_REQUEST,
    payload: data,
  }
};

export function loadPurchasingListLoading(data) {
  return {
    type: LOAD_PURCHASING_LIST_LOADING,
    payload: data,
  }
};

export function loadPurchasingListSuccess(data) {
  return {
    type: LOAD_PURCHASING_LIST_SUCCESS,
    payload: data,
  }
};

export function loadPurchasingListError(data) {
  return {
    type: LOAD_PURCHASING_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewPurchasingRequest(data) {
  return {
    type: SEND_ADD_NEW_PURCHASING_REQUEST,
    payload: data,
  }
};

export function sendPurchasingSuccess(data) {
  return {
    type: SEND_PURCHASING_SUCCESS,
    payload: data,
  }
};

export function sendUpdatePurchasingRequest(data) {
  return {
    type: SEND_UPDATE_PURCHASING_REQUEST,
    payload: data,
  }
};

export function sendDeletePurchasingRequest(data) {
  return {
    type: SEND_DELETE_PURCHASING_REQUEST,
    payload: data,
  }
};

export function sendPurchasingPrintRequest(data) {
  return {
    type: SEND_PURCHASING_PRINT_REQUEST,
    payload: data,
  }
};

export function loadPurchasingPrintSuccess(data) {
  return {
    type: LOAD_PURCHASING_PRINT_SUCCESS,
    payload: data,
  }
};

export function loadSelectedPurchasingRequest(data) {
  return {
    type: LOAD_SELECTED_PURCHASING_REQUEST,
    payload: data,
  }
};

export function loadSelectedPurchasingSuccess(data) {
  return {
    type: LOAD_SELECTED_PURCHASING_SUCCESS,
    payload: data,
  }
};

export function sendAddAndPrintPurchasingRequest(data) {
  return {
    type: SEND_PURCHASING_ADD_PRINT_REQUEST,
    payload: data,
  }
};

export function sendPurchasingSendRequest(data) {
  return {
    type: SEND_PURCHASING_SEND_REQUEST,
    payload: data,
  }
};

export function sendAddPurchasingAndSendRequest(data) {
  return {
    type: SEND_PURCHASING_ADD_SEND_REQUEST,
    payload: data,
  }
};

export function loadPurchasingSendSuccess(data) {
  return {
    type: LOAD_PURCHASING_SEND_SUCCESS,
    payload: data,
  }
};

export function loadPurchasingPrintByIdRequest(data) {
  return {
    type: LOAD_PURCHASING_PRINT_BY_ID_REQUEST,
    payload: data,
  }
};

export function loadPurchasingSendByIdRequest(data) {
  return {
    type: LOAD_PURCHASING_SEND_BY_ID_REQUEST,
    payload: data,
  }
};

export function sendStockDocumentRequest(data) {
  return {
    type: SEND_STOCK_DOCUMENT_REQUEST,
    payload: data,
  }
};

