import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TextField from '../layout/form/TextField';
import Button from '../layout/form/Button';
import { Link } from 'react-router-dom';
import VemasLogo from '../../images/VEMAS.png';
import "./auth.css";
import * as actions from '../../store/action/authAction';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Cookies from 'js-cookie';
import {
    signInRequest,
    tempPasswordChangeRequest
} from '../service/authService';
import ChangeTempPassword from './ChangeTempPassword';
// import ErrorModal from '../layout/form/errorModal';
import Loader from '../layout/form/Loader';
// import { loadTenantRequest } from '../../store/action/action';
import common from '../common';
// import { loadTenantBasicRequest } from '../../store/action/tenantAction';
import WarningModal from './warningModal';
import InformationModal from './inforModal';
import { saveTenantUserExpireStatus } from '../../store/action/action';
// import ErrorAlert from '../layout/form/errorAlert'

class SignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
        email:'',
        password:'',
        isTempPassword: false,
        session:'',
        isChangeTempPassword:false,
        newPassword:'',
        confirmNewPassword:'',
        isError:false,
        errorMassage:'',
        loading:false,
        isUserExpireStatus:false,
        isActivateModalStatus:false
    }}

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if(e.target.id === 'email' && !this.state.isChangeTempPassword){
            localStorage.setItem('user', e.target.value)
        }
    }

    handleSubmit = (e) => {
        this.setState({loading:true})
        e.preventDefault();
        if(this.state.email ==='' || this.state.password === ''){
            this.setState({isError:true, errorMassage:common.massages.requiredFieldMassage,loading:false})
            return
        }
        const parameters = {
            UserName: this.state.email,
            password: this.state.password
        }
        // this.props.dispatch(actions.sendSignInRequest(parameters));
        signInRequest(parameters).then(
            (res) =>{ 
                // console.log(res)
                const accessToken = res.data.accessToken;
                sessionStorage.setItem('accessToken', accessToken);
                // if(res.data.code === 1000 && res.data.expireStatus){
                //     this.setState({loading:false, isUserExpireStatus:true})
                //     this.props.dispatch(actions.saveUserExpire(res.data))
                // }
                // else 
                if(res.data.code === 1000){
                    this.setState({loading:false})
                    this.props.dispatch(actions.saveLoginUser(true))
                    // this.props.dispatch(loadTenantRequest(res.data.tenantId))
                    sessionStorage.setItem('tenantId', res.data.tenantId);
                    sessionStorage.setItem('tenantUserId', res.data.id);
                    if(res.data.expireStatus){
                        this.props.dispatch(saveTenantUserExpireStatus(true))
                        this.props.history.push("/dashboard")
                    }else{
                        this.props.history.push("/dashboard")
                    }
                    Cookies.set("user","loginTrue", { expires: 1, path: '' })
                }else if(res.data.code === 1001){
                    this.setState({isChangeTempPassword:true, session:res.data.session, loading:false})
                }},
            (err) => {
                if (err.response){
                    // console.log(err.response.data.detail)
                    const massage = err.response.data.detail
                    this.setState({isError:true, errorMassage:massage, loading:false})
                }else{
                    const massage = err.message
                    this.setState({isError:true, errorMassage:massage, loading:false})
                }
                
            }
        )
    }

    handleTempPassword = () => {
        this.setState({isChangeTempPassword:true})
    }

    changeTempPassword = () => {
        this.setState({loading:true})
        const { 
            newPassword, 
            confirmNewPassword,
            session,
            email
         } = this.state;
        if(email === '' || newPassword === '' || confirmNewPassword === ''){
            this.setState({isError:true, errorMassage:common.massages.requiredFieldMassage, loading:false})
            return
        }

        if(newPassword === confirmNewPassword){
            const parameters ={
                newPassword: newPassword,
                Session:session,
                Email:email
            }
            tempPasswordChangeRequest(parameters).then(
                (res) =>  {
                    if(res.data.code === 1002){
                        this.setState({
                            password:'',
                            isTempPassword: false,
                            session:'',
                            isChangeTempPassword:false,
                            newPassword:'',
                            confirmNewPassword:'',
                            loading: false
                        })
                    }
                    else if(res.data.code === 1003){
                        const massage = res.data.message
                        this.setState({isError:true, errorMassage:massage ,loading:false})
                    }
                },
                (err) => {
                    // console.log(err.response.data.detail)
                    const massage = err.response.data.detail
                    this.setState({isError:true, errorMassage:massage ,loading:false})
                }
            )
        }else{
            this.setState({isError:true, errorMassage:'Password and confirm password are not matching.',loading:false})
        }
    }

    // errorModalClose = () => {
    //     this.setState({isError:false, errorMassage:''})
    // }

    handleWarningModalClose = () =>{
        this.setState({
            isUserExpireStatus:false
        })
    }

    handleWarning = () => {
        this.setState({
            isUserExpireStatus:false,
            isActivateModalStatus:true
        })
    }

    handleInfoModalClose = () => {
        this.setState({
            isActivateModalStatus:false
        })
    }

    componentDidUpdate (){
        if(this.state.isError){
            setTimeout(
                () => this.setState({ isError:false, errorMassage:''}), 
                2000
              );
        }
    }

    render() {
        const { 
            isTempPassword, 
            isChangeTempPassword, 
            isError,
            errorMassage,
            loading,
            isUserExpireStatus,
            isActivateModalStatus
        } =  this.state
        
        const user = localStorage.getItem('user')
        return (
            <div>
                {/* <ErrorAlert/> */}
                {loading && <Loader show={loading}/>}
                {/* {isError && 
                    <ErrorModal 
                        show={true} 
                        massage={errorMassage}
                        handleClose={this.errorModalClose}
                    />} */}
                {
                    isUserExpireStatus ? 
                        <WarningModal 
                            show={isUserExpireStatus}
                            handleWarningModalClose={this.handleWarningModalClose}
                            handleWarning={this.handleWarning}
                        /> 
                    : ""
                }
                {
                    isActivateModalStatus ? 
                        <InformationModal 
                            show={isActivateModalStatus}
                            handleInfoModalClose={this.handleInfoModalClose}
                            handleInfo={this.handleInfo}
                        /> 
                    : ""
                }
                {!isChangeTempPassword ?
                <div className="auth-form">
                    <img alt="vemaslogo" id="vemaslogo" src={VemasLogo}/>
                    <form>
                        <h5>Sign In</h5>
                            <TextField
                                controlId="email"
                                // text="Email"
                                placeholder="Email/Login Id"
                                type="text"
                                onBlur={this.handleChange}
                                defaultValue={this.state.email}
                            />
                            <TextField
                                controlId="password"
                                // text="Password"
                                placeholder="Password"
                                type="password"
                                onBlur={this.handleChange}
                            />
                            {isError ?
                                <div className="authErrorMassage">
                                    <span><ui5-icon class="samples-margin" name="message-error" id="messageError"></ui5-icon></span>
                                    {errorMassage}
                                </div> : (isTempPassword ?
                                <div style={{color:'red'}}>
                                    You are required to Change the password. Click 
                                    <span onClick={this.handleTempPassword} className="confirm-code"> here </span>to change the password. 
                                </div> :
                                <div style={{display:'flex', justifyContent:'center',margin:'5px'}}>
                                    <Button 
                                        variant="primary" 
                                        text ='Sign In'
                                        type="submit"
                                        onClick={this.handleSubmit}
                                    />
                                </div>)}
                            {/* {isError ?
                                <div className="authErrorMassage">
                                    <span><ui5-icon class="samples-margin" name="message-error" id="messageError"></ui5-icon></span>
                                    {errorMassage}
                                </div> : */}
                                <p>By clicking Sign In, you agree to our Terms and Service and have read and acknowledge our Privacy Statement.</p>
                            {/* } */}
                            {!isTempPassword && <div className="auth-pages">
                                <div><Link to='./forgotPassword'>I forgot my User Id</Link> or <Link to='./forgotPassword'>password</Link></div>
                                <div><Link to='./changePassword'>Change password with verification code</Link></div>
                                {/* <div>New to VEMAS ? <Link> Create an account</Link></div> */}
                            </div>}
                            <div><Link>Privacy policy</Link> and <Link>Terms of use</Link></div>
                            {/* <div><Link to='./dashboard'>dashboard</Link></div> */}
                        
                    </form>    
                </div>:
                <ChangeTempPassword
                    handleChange={this.handleChange}
                    handleSubmit={this.changeTempPassword}
                    isError={isError}
                    errorMassage={errorMassage}
                    user = {user}
                />}
            </div>
        )
    }
}


// SignIn.propTypes = {
//     isAddNewPartModalOpen: PropTypes.bool,
// }

// const mapStateToProps = createStructuredSelector({
//     isAddNewPartModalOpen: selectors.makeSelectPartPopupOpen(),
//   });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    // mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(SignIn));