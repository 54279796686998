import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/productCategorySelector';
import * as actions from '../../../../store/action/productCategoryAction';
import PropTypes from 'prop-types';
import '../settingMenu.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddNewProductCategory from './AddNew';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import { makeSelectProductTypeList } from '../../../../store/selector/productSelector';
import { loadProductTypeListRequest } from '../../../../store/action/productAction';
import SelectField from '../../../layout/form/SelectField';

class ProductCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            description:'',
            typeId: '',
            isValidationError:false,
            isDeleteModalOpen:false,
            selectedRow:{},
            errorMassage:'',
            pageSize:20,
            pageNumber:1,
        }
    }

    handleModal = (data) => {
        if(data && data.id){
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsProductCategoryModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsProductCategoryModalOpenStatus(false));
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadProductCategoryListError(''))
    }

    successModalClose = () => {
        this.getProductCategoryListData()
        this.props.dispatch(actions.sendProductCategorySuccess(''))
        this.handleModalClose()
        this.props.dispatch(actions.loadProductCategoryListLoading(false))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            description: this.state.description,
            typeId: this.state.typeId,
        })
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeProductType = (id) => {
        this.setState({
            typeId: id
        })
    }

    handleFilter = () =>{
        this.getProductCategoryListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen:false,
            description:'',
            typeId: '',
            pageSize:20,
            pageNumber:1,
        })

        const parameters= {
            pageSize:20,
            pageNumber:1,
            description:'',
            typeId: '',
          }
          this.props.dispatch(actions.loadProductCategoryListRequest(parameters))
    }

    handleSaveAddNew = (details) => {
        // console.log(details)
        if(this.state.isUpdate){
          this.updateProductCategory(details)
        }else{
          this.addNewProductCategory(details)
        }
    }

    updateProductCategory = (details) =>{
        if( details.description === '' || details.typeId === '' ||  details.typeId === null){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
    }
    // this.handleModalClose()
    const parameters = {
        description: details.description,
        typeId : details.typeId,
        id:this.state.selectedRow.id
    }
        // console.log(details)
        this.props.dispatch(actions.sendUpdateProductCategoryRequest(parameters))
      }


    setProductType = (data) => {
        const { productTypeList } = this.props;
        if(productTypeList && productTypeList.length && productTypeList.length !== 0){
          const desc = productTypeList.find(select =>  select.id === data.typeId)
          return (desc && desc.description ? desc.description : '')
        }
    }

    addNewProductCategory = (details) => {
        if(details.description === '' || details.typeId === '' ||  details.typeId === null){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
        }
      const parameters = {
        description: details.description,
        typeId: details.typeId
    }
    this.props.dispatch(actions.sendAddNewProductCategoryRequest(parameters))
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteProductCategory = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteProductCategoryRequest(this.state.selectedRow.id))
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }
  
    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }
  
    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }
  
    setPageSize = (size) => {
      this.setState({
          pageSize:size,
          pageNumber:1
      })
    }

    getProductCategoryListData = () => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
          description:this.state.description,
          typeId: this.state.typeId
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.props.dispatch(actions.loadProductCategoryListRequest(parameters))
    }

    componentDidMount (){
        this.getProductCategoryListData()
        this.props.dispatch(loadProductTypeListRequest())
    }

    componentDidUpdate (prevProps, prevState){
        // if(this.state.isValidationError || this.props.productCategoryListError !== ''){
        //     setTimeout(
        //         () => this.errorModalClose(), 
        //         500
        //       );
        // }else 
        if (this.props.productCategorySuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                500
            );
        }

        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getProductCategoryListData()
        }
    }

    render() {
        
        const {
            isAddNewProductCategoryModalOpen,
            productCategoryList,
            isProductCategoryListLoading,
            productCategoryListError,
            productCategorySuccess,
            productTypeList
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow
         } = this.state;

        const columns = [{
            Header: 'Description',
            accessor: 'description',
          },{
            Header: 'Product Type',
            accessor: 'typeId',
            width: "10%",
            Cell:((row)=>(
                <div>
                  {this.setProductType(row.row.original)}
                </div>
              )),
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>  
                      <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>  
                      {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(isProductCategoryListLoading || 
                        (productCategoryList && productCategoryList.result && productCategoryList.result.length && productCategoryList.result.length === 0)) ? 
                        <Loader show={isProductCategoryListLoading}/> :''}

                {(isValidationError || productCategoryListError !== '' ) && 
                        <ErrorModal
                            show={true} 
                            massage={errorMassage ? errorMassage : productCategoryListError}
                            handleClose={this.errorModalClose}
                        />}

                {(productCategorySuccess) && 
                        <SuccessModal
                            show={true} 
                            massage={productCategorySuccess}
                            handleClose={this.successModalClose}
                        />}
                {isDeleteModalOpen && 
                    <DeleteModal 
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteProductCategory}								
                        title={selectedRow.description}
                    />}
                <div className="page-header">
                    <div className="btn-group">
                        <ButtonGroup
                            primaryBtnClick={this.handleModal}
                            secondaryBtnClick={this.handleFilterForm}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Add New'
                            primaryBtnIcon='sys-add'
                            secondaryBtnText='Filter'
                            secondaryBtnVariant="outline-primary" 
                            secondaryBtnIcon='filter'
                        />
                    </div>
                </div>
                <div className="body-content">
                    <h5>Product Category List</h5>
                    {isFilterFormOpen && 
                            <div className="filter-form">
                                <div className="filter-header">
                                    <h5>Filter Form</h5>
                                    <div className="btn-close" onClick={this.handleFilterFormClose} >
                                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField
                                        text="Description"
                                        controlId="description"
                                        placeholder="Description"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.description}
                                    />
                                </div>
                                <div className="col-sm">
                                    <SelectField                                            
                                        text="Change Type"
                                        controlId="productType"
                                        onChange={this.handleChangeProductType}
                                        options={productTypeList}
                                        selectedValue={this.state.typeId}
                                    />
                                </div>
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                        </div>}
                        <div className="table-content">
                            <Table
                                columns={columns}
                                data={productCategoryList.result && productCategoryList.result.length ? productCategoryList.result : []}                      
                            />
                        </div>
                </div>
                <Pagination
                    currentPage={productCategoryList.page ? productCategoryList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {productCategoryList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewProductCategoryModalOpen && 
                    <AddNewProductCategory
                        isAddNewProductCategoryModalOpen={isAddNewProductCategoryModalOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedRow={selectedRow}
                        productTypeList={productTypeList}
                    />
                }
            </div>
        )
    }
}

ProductCategory.propTypes = {
    isAddNewProductCategoryModalOpen: PropTypes.bool,
    productCategoryList: PropTypes.any,
    isProductCategoryListLoading: PropTypes.bool,
    productCategoryListError: PropTypes.any,
    productCategorySuccess: PropTypes.any,
    productTypeList: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isAddNewProductCategoryModalOpen: selectors.makeSelectProductCategoryPopupOpen(),
    productCategoryList: selectors.makeSelectProductCategoryList(),
    isProductCategoryListLoading: selectors.makeSelectProductCategoryListLoading(),
    productCategoryListError: selectors.makeSelectProductCategoryListError(),
    productCategorySuccess: selectors.makeSelectProductCategorySuccess(),
    productTypeList: makeSelectProductTypeList(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(ProductCategory));