import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import CalendarListView from './CalendarListView';
import GraphicalCalendar from './GraphicalCalendar';
import { loadBookingListSuccess } from '../../../../store/action/bookingAction';

class VemasCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isListView:false
        }
    }

    redirectList = () => {
        this.setState({
            isListView:true
        })
        this.props.dispatch(loadBookingListSuccess([]))
    }

    redirectGraphical = () => {
        this.setState({
            isListView:false
        })
        this.props.dispatch(loadBookingListSuccess([]))
    }

    render() {

        const { 
            isListView
        } = this.state;

        return (
            <div>
                <div className="content-header">
                    <div className="header-pages">
                        <span><Link onClick={() => this.redirectGraphical()}><ui5-icon class="samples-margin" name="calendar" id="tab-icon"></ui5-icon> Graphical</Link></span>
                        <span><Link onClick={() => this.redirectList()} ><ui5-icon class="samples-margin" name="list" id="tab-icon"></ui5-icon> List</Link></span>
                    </div>
                </div>
                {
                        isListView ?
                        <CalendarListView/> :
                        <GraphicalCalendar/>
                    }
            </div>
        )
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapDispatchToProps,
);
  

export default (compose(withConnect)(VemasCalendar));
// export default VemasCalendar;
