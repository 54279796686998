import { fromJS } from 'immutable';
import {
  LOAD_APP_BILLING_TERMS_SUCCESS,
  LOAD_APP_PACKAGE_SUCCESS,
  LOAD_COUNTRY_SUCCESS,
  LOAD_OBJECT_STATUS_SUCCESS,
  LOAD_TENANT_SUCCESS,
  LOAD_TENANT_USER_SUCCESS,
  LOAD_UOM_SUCCESS,
  SAVE_CLICK_UNDER_EXPIRED_STATUS,
  SAVE_COMMON_SEARCH_SUCCESS,
  SAVE_TENANT_USER_EXPIRE_STATUS,
} from '../constant/constant';

export const initialState = fromJS({
  objectStatusList: [],
  uomList: [],
  countryList: [],
  tenant: {},
  tenantUser: {},
  tenantUserExpireStatus: false,
  clickUnderExpiredStatus: false,
  commonSearchData: {},
  billingTermsList: [],
  appPackageList: []
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_OBJECT_STATUS_SUCCESS:
      return state.set('objectStatusList', action.payload)
    case LOAD_UOM_SUCCESS:
      return state.set('uomList', action.payload)
    case LOAD_COUNTRY_SUCCESS:
      return state.set('countryList', action.payload)
    case LOAD_TENANT_SUCCESS:
      return state.set('tenant', action.payload)
    case LOAD_TENANT_USER_SUCCESS:
      return state.set('tenantUser', action.payload)
    case SAVE_TENANT_USER_EXPIRE_STATUS:
      return state.set('tenantUserExpireStatus', action.payload)
    case SAVE_CLICK_UNDER_EXPIRED_STATUS:
      return state.set('clickUnderExpiredStatus', action.payload)
    case SAVE_COMMON_SEARCH_SUCCESS:
      return state.set('commonSearchData', action.payload)
    case LOAD_APP_BILLING_TERMS_SUCCESS:
      return state.set('billingTermsList', action.payload)
    case LOAD_APP_PACKAGE_SUCCESS:
      return state.set('appPackageList', action.payload)
    default:
      return state;
  }
}

export default appReducer;
