import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewInvoiceRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.post(baseURL + 'invoices', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getInvoiceList(parameters) {
    return axios.get(baseURL + 'invoices?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&CustomerId=' + parameters.customer + '&InvoiceNo=' + parameters.invoiceNo + '&InvoiceDate=' + parameters.invoiceDate + '&DueDate=' + parameters.dueDate + '&JobCardId=' + parameters.jobCardId + '&VehicleId=' + parameters.vehicleId + '&statusId=' + parameters.status + '&commonSearch=' + parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateInvoiceRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'invoices/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteInvoiceRequest(id) {
    return axios.delete(baseURL + 'invoices/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function printInvoiceRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'invoices/printAndUpdate/' + data.invoiceDetails.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedInvoiceRequest(id) {
    return axios.get(baseURL + 'invoices/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

// export function sendInvoiceRequest(data){
//     const parameters = JSON.stringify(data)
//     //console.log(parameters)
//     return axios.patch(baseURL+'invoices/emailAndUpdate/'+data.invoiceDetails.id, parameters, {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization' : sessionStorage.getItem("accessToken")
//         }
//     })
// }

export function printInvoiceByIdRequest(id) {
    const appId = "02" // velawa app ai = 02
    return axios.patch(baseURL + 'invoices/printAction/' + id + '?appId=' + appId, null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getPaymentTermRequest() {
    return axios.get(baseURL + 'tenants/paymentTerm', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

// export function getInvoiceEmailRequest(id) {
//     return axios.get(baseURL + 'invoices/sendEmail/' + id, {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization': sessionStorage.getItem("accessToken")
//         }
//     })
// }

// export function sendInvoiceEmail(data) {
//     const parameters = JSON.stringify(data)
//     //console.log(parameters)
//     return axios.patch(baseURL + 'invoices/sendEmail/' + data.id, parameters, {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization': sessionStorage.getItem("accessToken")
//         }
//     })
// }

export function getPaymentMethodList() {
    return axios.get(baseURL + 'paymentMethod', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getInvoiceEmailRequest(id) {
    const appId = "02" // velawa app ai = 02
    return axios.get(baseURL + 'invoices/sendEmail/' + id + '?appId=' + appId, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendInvoiceEmail(data) {
    const parameters = JSON.stringify(data)
    const appId = "02" // velawa app ai = 02
    return axios.patch(baseURL + 'invoices/sendEmail/' + data.id + '?appId=' + appId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendInvoiceByIdRequest(id) {
    const appId = "02" // velawa app ai = 02
    return axios.patch(baseURL + 'invoices/emailAction/' + id + '?appId=' + appId, null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}
