import { fromJS } from 'immutable';
import {
  LOAD_ITEM_CATEGORY_LIST_SUCCESS,
  LOAD_ITEM_LIST_ERROR,
  LOAD_ITEM_LIST_LOADING,
  LOAD_ITEM_LIST_SUCCESS,
  SAVE_IS_ITEM_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_ITEM_ERROR,
  SEND_ADD_NEW_ITEM_LOADING,
  SEND_ADD_NEW_ITEM_SUCCESS,
  SEND_DELETE_ITEM_ERROR,
  SEND_DELETE_ITEM_LOADING,
  SEND_DELETE_ITEM_SUCCESS,
  SEND_UPDATE_ITEM_ERROR,
  SEND_UPDATE_ITEM_LOADING,
  SEND_UPDATE_ITEM_SUCCESS,
} from '../constant/itemConstant';

export const initialState = fromJS({
    isAddNewItemModalOpen:false,
    isAddNewItemLoading:false,
    addNewItemSuccess:'',
    addNewItemError:'',
    itemList:[],
    isItemListLoading: false,
    getItemListError:'',
    itemCategoryList:[],
    objectStatusList:[],
    uomList:[],
    isUpdateItemLoading:false,
    updateItemSuccess:'',
    updateItemError:'',
    isDeleteItemLoading:false,
    deleteItemSuccess:'',
    deleteItemError:''
});

function itemReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_ITEM_MODAL_OPEN_STATUS:
      return state.set('isAddNewItemModalOpen', action.open)
    case SEND_ADD_NEW_ITEM_LOADING:
      return state.set('isAddNewItemLoading', action.payload)
    case SEND_ADD_NEW_ITEM_SUCCESS:
      return state.set('addNewItemSuccess', action.payload)
    case SEND_ADD_NEW_ITEM_ERROR:
      return state.set('addNewItemError', action.payload)
    case LOAD_ITEM_LIST_SUCCESS:
      return state.set('itemList', action.payload)
    case LOAD_ITEM_LIST_LOADING:
      return state.set('isItemListLoading', action.payload)
    case LOAD_ITEM_LIST_ERROR:
      return state.set('getItemListError', action.payload)
    case LOAD_ITEM_CATEGORY_LIST_SUCCESS:
      return state.set('itemCategoryList', action.payload)
    case SEND_UPDATE_ITEM_LOADING:
      return state.set('isUpdateItemLoading', action.payload)
    case SEND_UPDATE_ITEM_SUCCESS:
      return state.set('updateItemSuccess', action.payload)
    case SEND_UPDATE_ITEM_ERROR:
      return state.set('updateItemError', action.payload)
    case SEND_DELETE_ITEM_LOADING:
      return state.set('isDeleteItemLoading', action.payload)
    case SEND_DELETE_ITEM_SUCCESS:
      return state.set('deleteItemSuccess', action.payload)
    case SEND_DELETE_ITEM_ERROR:
      return state.set('deleteItemError', action.payload)
    default:
      return state;
  }
}

export default itemReducer;
