import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getProductCategoryList() {
    return axios.get(baseURL + 'productCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function addNewProductRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.post(baseURL + 'products', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProductList(parameters) {
    return axios.get(baseURL + 'products?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&sku=' + parameters.sku + '&categoryId=' + parameters.categoryId + '&description=' + parameters.description + '&status=' + parameters.status + '&commonSearch=' + parameters.commonSearch + '&typeId=' + parameters.productTypeId + '&purchasingFlg=' + parameters.purchasingFlg + '&stockFlg=' + parameters.stockFlg, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateProductRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'products/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteProductRequest(id) {
    return axios.delete(baseURL + 'products/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getProductTypeList() {
    return axios.get(baseURL + 'productType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedProduct(id) {
    return axios.get(baseURL + 'products/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function printBalanceStockRequest(type) {
    return axios.patch(baseURL + 'products/stockBalanceReport/' + type, null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getAlternativeProduct(id) {
    return axios.get(baseURL + 'products/alternatives/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateAlternativeProductRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL + 'products/alternatives/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}