import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/smsTempSelector';
import * as actions from '../../../../store/action/smsTempAction';
import PropTypes from 'prop-types';
import '../settingMenu.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import AddSMSTemplate from './AddNew';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';

class SMSTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            name:'',
            isValidationError:false,
            isDeleteModalOpen:false,
            selectedRow:{},
            errorMassage:'',
            pageSize: 20,
            pageNumber: 1
        }
    }

    handleModal = (data) => {
        if(data && data.id){
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsSMSTemplateModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsSMSTemplateModalOpenStatus(false));
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadSMSTemplateListError(null))
    }

    successModalClose = () => {
        this.getSMSTemplateListData()
        this.props.dispatch(actions.sendSMSTemplateSuccess(null))
        this.handleModalClose()
        this.props.dispatch(actions.loadSMSTemplateListLoading(false))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            name: this.state.name
        })
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleFilter = () =>{
        this.getSMSTemplateListData()
        this.handleFilterForm()
    }

    handleFilterFormClose = () =>{
        this.setState({
            isFilterFormOpen:false,
            name:''
        })

        const parameters= {
            pageSize: 20,
            pageNumber: 1,
            name:'',
          }
          this.props.dispatch(actions.loadSMSTemplateListRequest(parameters))
    }

    handleSaveAddNew = (details) => {  
        const { userAccess } = this.props
        if(this.state.isUpdate && userAccess && userAccess.modifySMSTemplate){
          this.updateSMSTemplate(details)
        }else if(userAccess && userAccess.createSMSTemplate){
          this.addNewSMSTemplate(details)
        }
    }

    updateSMSTemplate = (details) =>{
        if( details.name === '' ){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
        }
        const parameters = {
            body: details.sms,
            name: details.name,
            id:this.state.selectedRow.id
        }
        this.props.dispatch(actions.sendUpdateSMSTemplateRequest(parameters))
      }

    addNewSMSTemplate = (details) => {
        if(details.name === ''){
          this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
          return
        }
        const parameters = {
            body: details.sms,
            name: details.name
        }
        this.props.dispatch(actions.sendAddNewSMSTemplateRequest(parameters))
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteSMSTemplate = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteSMSTemplateRequest(this.state.selectedRow.id))
    }

    setSMSBody = (details) => {
        var string = decodeURIComponent(details.body)
        if(string.length > 100 ){
            return(
                <div>
                    {string.substring(0, 95)+" ....."}
                </div>
            )
        }
        else 
            return(
                <div>
                    {string}
                </div>
            )
    }

    getSMSTemplateListData = () => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 :this.state.pageNumber,
            name: this.state.name,
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.props.dispatch(actions.loadSMSTemplateListRequest(parameters))
    }

    componentDidMount (){
        this.getSMSTemplateListData()
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    componentDidUpdate (prevProps, prevState){
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getEmailTemplateListData()
        }
        if (this.props.smsTemplateSuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                500
            );
        }
    }

    render() {
        
        const {
            isAddNewSMSTemplateOpen,
            smsTemplateList,
            isSMSTemplateListLoading,
            smsTemplateListError,
            smsTemplateSuccess,
            userAccess
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow
         } = this.state;

        const columns = [{
            Header: 'SMS subject',
            accessor: 'name',
            width: "20%",
          },{
            Header: 'SMS',
            accessor: 'body',
            Cell:((row) => (
                <div>
                    {this.setSMSBody(row.row.original)}
                </div>
            ))
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>  
                      <span onClick={userAccess && userAccess.deleteSMSTemplate ? ()=>this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>  
                      {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {(isSMSTemplateListLoading || 
                        (smsTemplateList && smsTemplateList.length && smsTemplateList.length === 0)) ? 
                        <Loader show={isSMSTemplateListLoading}/> :''}

                {(isValidationError || smsTemplateListError !== null ) && 
                        <ErrorModal
                            show={true} 
                            massage={errorMassage ? errorMassage : smsTemplateListError}
                            handleClose={this.errorModalClose}
                        />}

                {(smsTemplateSuccess !== null) && 
                        <SuccessModal
                            show={true} 
                            massage={smsTemplateSuccess}
                            handleClose={this.successModalClose}
                        />}
                {isDeleteModalOpen && 
                    <DeleteModal 
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteSMSTemplate}								
                        title={selectedRow.name}
                    />}
                <div className="page-header">
                    <div className="btn-group">
                        <ButtonGroup
                            primaryBtnClick={this.handleModal}
                            secondaryBtnClick={this.handleFilterForm}
                            primaryBtnVariant="outline-primary"
                            primaryBtnText='Add New'
                            primaryBtnIcon='sys-add'
                            primaryBtnDisabled={userAccess && !userAccess.createSMSTemplate}
                            secondaryBtnText='Filter'
                            secondaryBtnVariant="outline-primary" 
                            secondaryBtnIcon='filter'
                        />
                    </div>
                </div>
                <div className="body-content">
                    <h5>SMS Template List</h5>
                    {isFilterFormOpen && 
                            <div className="filter-form">
                                <div className="filter-header">
                                    <h5>Filter Form</h5>
                                    <div className="btn-close" onClick={this.handleFilterFormClose} >
                                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextField
                                        text="Name"
                                        controlId="name"
                                        placeholder="Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.name}
                                    />
                                </div>
                                {/* <div className="col-sm">
                                    <TextField
                                        text="Make"
                                        controlId="make"
                                        placeholder="Make"
                                        type="text"
                                        as="select"
                                        // onChange={this.handleChange}
                                    />
                                </div> */}
                            </div>
                            <div className="filter-btns">
                                <ButtonGroup
                                    primaryBtnClick={this.handleFilter}
                                    primaryBtnIcon='search'
                                    primaryBtnText='Search'
                                    primaryBtnVariant="outline-primary" 
                                    secondaryBtnClick={this.handleFilterFormClose}
                                    secondaryBtnIcon='clear-filter'
                                    secondaryBtnText='Clear'
                                    secondaryBtnVariant="outline-primary" 
                                />
                            </div>
                        </div>}
                        <div className="table-content">
                            <Table
                                columns={columns}
                                data={smsTemplateList.result && smsTemplateList.result.length ? smsTemplateList.result : [] }                   
                            />
                        </div>
                </div>
                <Pagination
                    currentPage={smsTemplateList.page ? smsTemplateList.page : this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {smsTemplateList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
                {isAddNewSMSTemplateOpen && 
                    <AddSMSTemplate
                        isAddNewSMSTemplateOpen={isAddNewSMSTemplateOpen}
                        handleModalClose={this.handleModalClose}
                        handleSaveAddNew={this.handleSaveAddNew}
                        selectedRow={selectedRow}
                        userAccess={userAccess}
                    />
                }
            </div>
        )
    }
}

SMSTemplate.propTypes = {
    isAddNewSMSTemplateOpen: PropTypes.bool,
    smsTemplateList: PropTypes.any,
    isSMSTemplateListLoading: PropTypes.bool,
    smsTemplateListError: PropTypes.any,
    smsTemplateSuccess: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    isAddNewSMSTemplateOpen: selectors.makeSelectSMSTemplatePopupOpen(),
    smsTemplateList: selectors.makeSelectSMSTemplateList(),
    isSMSTemplateListLoading: selectors.makeSelectSMSTemplateListLoading(),
    smsTemplateListError: selectors.makeSelectSMSTemplateListError(),
    smsTemplateSuccess: selectors.makeSelectSMSTemplateSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(SMSTemplate));