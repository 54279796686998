import React, { Component } from 'react'
import LineChart from '../../../layout/form/LineChart';

class GraphicalRating extends Component {

    render() {
        const { 
            data,
            handleClose
        } = this.props;
        return (
            <div>
                <div className="filter-header">
                    <h5>
                        Feedback Comparison
                    </h5>
                    <div className="btn-close" onClick={handleClose} >
                        <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                </div>
                <div className="analytics-chart">
                    <div className="chart-content">
                        
                        <LineChart
                            data={data.data}
                            height="80px"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default GraphicalRating
