import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import '../../../../App.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import BasicData from './BasicData';
import Finance from './Finance';
import Correspondence from './Correspondence';
import { loadPaymentTermRequest } from '../../../../store/action/invoiceAction';
import Marketing from './Marketing';
import Welcome from '../../../layout/form/welcomeModal';
import common from '../../../common';
// import Loader from '../../../layout/form/Loader';
// import ErrorModal from '../../../layout/form/errorModal';
// import SuccessModal from '../../../layout/form/successModal';
import OpeningHours from './OpeningHours';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';

class Profile extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            tab:'basicData',
            isChangeTab: false,
            clickedTab:'basicData'
        }
    }


    componentDidMount (){
        this.props.dispatch(loadPaymentTermRequest())
    }

    welcomeModalClose = () => {
        this.props.dispatch(actions.saveTenantIdentityStatus(false))
    }

    handleTab = (value,details) => {
        const {tenantCorrespondence, userAccess} = this.props;

        if(userAccess && userAccess.modifyTenant === false){
            this.setState({
                clickedTab: value,
                isChangeTab: true
            })
            return
        }
        
        if(this.state.tab === 'basicData' && details.isChangesBasicData){
            this.props.dispatch(actions.loadTenantDetailsLoading(true))
            this.handleSubmitBasicData(details)
            this.props.dispatch(actions.loadTenantBasicSuccess({}))
        }
        else if(this.state.tab === 'finance' && details.isChangesFinance){
            this.props.dispatch(actions.loadTenantDetailsLoading(true))
            this.handleSubmitFinance(details)
            this.props.dispatch(actions.loadTenantFinanceSuccess({}))
        }
        else if(this.state.tab === 'correspondence'){
            const invoiceEditorState = decodeURIComponent(tenantCorrespondence.invoice)
            const quotationEditorState = decodeURIComponent(tenantCorrespondence.quotation)
            const serviceEditorState = decodeURIComponent(tenantCorrespondence.serviceReminder)
            const registrationEditorState = decodeURIComponent(tenantCorrespondence.registrationReminder)
            const bookingEditorState = decodeURIComponent(tenantCorrespondence.bookingReminder)
            if(
                invoiceEditorState !== details.invoiceEditorState || 
                quotationEditorState !== details.quotationEditorState ||
                serviceEditorState !== details.serviceEditorState ||
                registrationEditorState !== details.registrationEditorState ||
                bookingEditorState !== details.bookingEditorState ||
                details.bookingSmsBody !== tenantCorrespondence.bookingSMSBody||
                details.isBookingSms !== tenantCorrespondence.bookingSMS ||
                details.isBookingEmail !== tenantCorrespondence.bookingEmail){
                this.props.dispatch(actions.loadTenantDetailsLoading(true))
                this.handleSubmitCorrespondance(details)
                this.props.dispatch(actions.loadTenantCorrespondenceSuccess({}))
            }
        }
        else if(this.state.tab === 'marketing' && details.isChangesMarketing){
            this.props.dispatch(actions.loadTenantDetailsLoading(true))
            this.handleSubmitMarketing(details)
            this.props.dispatch(actions.loadTenantCorrespondenceSuccess({}))
        }
        else if(this.state.tab === 'openingHours' && details.isChangesOpeningHours){
            this.props.dispatch(actions.loadTenantDetailsLoading(true))
            this.handleSubmitOpeningHours(details)
            this.props.dispatch(actions.loadTenantOpenHoursSuccess({}))
        }
        this.setState({
            clickedTab: value,
            isChangeTab: true
        })
    }

    handleSubmitBasicData  = (details) => {
        if(
            details.businessName === '' || 
            details.email === '' || 
            details.phoneNumber === '' ||
            details.registrationNo === '' ||
            details.cityName === '' ||
            details.stateName === '' ||
            details.streetName === '' ||
            details.postalCode === '' ){
                this.props.dispatch(actions.loadTenantDetailsError(common.massages.requiredFieldMassageProfile))
            return
        }else{
            const id = sessionStorage.getItem("tenantId")

            const parameters = {
                id:id,
                businessName:details.businessName,
                phoneNumber:details.phoneNumber,
                LicenseNumber:details.licenseNumber,
                fax:details.faxNumber,
                email:details.email,
                businessRegistrationNumber:details.registrationNo,
                logo:details.seletedImage,
                statusId:details.statusId,
                billStreetNumber:details.streetNo,
                billStreetName:details.streetName,
                billCity:details.cityName,
                billPostalCode:details.postalCode,
                billRegion:details.stateName,
                billCountry:{
                    code:details.country,
                },
                shpCountry:{
                    code:details.country,
                 },
              
                commonDetails:{
                    dateFormat:"DD-MM-YYYY"
                }
            }
            this.props.dispatch(actions.sendTenantDetailsRequest(parameters))
        }
    }

    handleSubmitFinance  = (details) => {
        if(
            details.nameOfTheBank !== '' && (
                details.bankAccountName === '' ||
                details.bSB === '' ||
                details.account === ''
            )){
                this.props.dispatch(actions.loadTenantDetailsError(common.massages.requiredFieldMassageProfile))
            return
        }else{
            const parameters = {
                CurrencyCode:details.currencyCode,
                CurrencySymbol:details.currencySymbol,
                RegisteredForTax:1, // TODO
                TaxRate:details.taxRate,
                PriceInclusiveOfTax:details.priceIncludeTax === 'Yes'? true : false,
                DefaultPaymentTerm:details.defaultPaymentTerm,
                BankAccountName:details.bankAccountName,
                BankName:details.nameOfTheBank,
                BankStateBranch:details.bSB,
                AccountNumber:details.account,
            }
            this.props.dispatch(actions.sendTenantFinanceRequest(parameters))
        }
    }

    handleSubmitCorrespondance  = (details) => {
        const parameters = {
            Invoice:encodeURIComponent(details.invoiceEditorState),
            Quotation:encodeURIComponent(details.quotationEditorState),
            BookingReminder:encodeURIComponent(details.bookingEditorState),
            BookingSMSBody: details.bookingSmsBody,
            BookingSMS: details.isBookingSms,
            BookingEmail: details.isBookingEmail
        }
        this.props.dispatch(actions.sendTenantCorrespondanceRequest(parameters))
    }

    handleSubmitMarketing  = (details) => {
        const id = sessionStorage.getItem("tenantId")
            const tempService = details.tempServiceList.filter(data => (data.operation === "D"))
            const services = this.handleList(details.servicesList.concat(tempService))

            const tempArea = details.tempAreaList.filter(data => (data.operation === "D"))
            const areas = this.handleList(details.areaList.concat(tempArea))

            const parameters = {
                id:id,
                bannerImage:details.seletedBanner ? details.seletedBanner : null,
                TenantAreas:areas,
                TenantServices:services,
                Online: details.isOnline === true ? '1' : '0'
            }
            this.props.dispatch(actions.sendTenantMarketingRequest(parameters))
        
    }

    handleSubmitOpeningHours= (details) => {
        const id = sessionStorage.getItem("tenantId")
        const parameters = {
            tenantId:id,
            monStart:details.monStart,
            monEnd:details.monEnd,
            monStatus: details.monStatus,
            tueStart:details.tueStart,
            tueEnd :details.tueEnd,
            tueStatus: details.tueStatus,
            wedStart :details.wedStart,
            wedEnd :details.wedEnd,
            wedStatus: details.wedStatus,
            thuStart :details.thuStart,
            thuEnd :details.thuEnd,
            thuStatus: details.thuStatus,
            friStart:details.friStart,
            friEnd :details.friEnd,
            friStatus : details.friStatus,
            satStart :details.satStart,
            satEnd:details.satEnd,
            satStatus : details.satStatus,
            sunStart :details.sunStart,
            sunEnd :details.sunEnd,
            sunStatus: details.sunStatus,
        }
        this.props.dispatch(actions.sendTenantOpenHoursRequest(parameters))
    }

    handleList = (data) =>{
        if(data.length === 0){
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.index=i+1
            data[i]=element

            if(i === data.length-1){
                return (data)
            }
        }
    }


    componentDidUpdate (prevProps, prevState){
        if(this.state.isChangeTab && !this.props.isTenantDetailsLoading && this.props.tenantDetailsSuccess === '' ){
            this.setState({
                tab : this.state.clickedTab,
                isChangeTab: false
            })
        }
    }

    render() {
        const {
            newUserIdentitiyStatus,
            userAccess
        } = this.props

        const { 
            tab
        } = this.state;
        return (
            <div>
                {newUserIdentitiyStatus ? 
                    <Welcome 
                        show={newUserIdentitiyStatus}
                        handleModalClose={this.welcomeModalClose}/> :''}

                {tab === "basicData" && <BasicData handleTab={this.handleTab} handleSubmit={this.handleSubmitBasicData} userAccess={userAccess}/> }
                {tab === "finance" && <Finance handleTab={this.handleTab} handleSubmit={this.handleSubmitFinance} userAccess={userAccess}/> }
                {tab === "correspondence" && <Correspondence handleTab={this.handleTab} handleSubmit={this.handleSubmitCorrespondance} userAccess={userAccess}/> }
                {tab === "openingHours" && <OpeningHours handleTab={this.handleTab} handleSubmit={this.handleSubmitOpeningHours} userAccess={userAccess}/> }
                {tab === "marketing" && <Marketing handleTab={this.handleTab} handleSubmit={this.handleSubmitMarketing} userAccess={userAccess}/> }

            </div>
        )
    }
}

Profile.propTypes = {
    newUserIdentitiyStatus:PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    newUserIdentitiyStatus: selectors.makeSelectTenantIdentityStatus(),
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
    tenantCorrespondence: selectors.makeSelectTenantCorrespondenceSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(Profile));
