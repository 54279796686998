import { put, call } from 'redux-saga/effects';
import * as actions from '../../store/action/notificationAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { getNotificationList, getSMSNotificationList} from '../service/notificationService';


export function* loadNotificationListRequest(data){
    // console.log('loadNotificationTypeListRequest')
    yield put(actions.loadNotificationListError(''))
    try{
      yield put(actions.loadNotificationListLoading(true));
      const response = yield call(getNotificationList,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
        const notificationTypeList = response.data.result
        yield put(actions.loadNotificationListSuccess(notificationTypeList))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadNotificationListLoading(false));
        const massage = common.error
        yield put(actions.loadNotificationListError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(actions.loadNotificationListLoading(false));
    }catch(error){
      yield put(actions.loadNotificationListLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadNotificationListError(massage))
    }
  }


  export function* loadSMSNotificationListRequest(data){
    // console.log('loadNotificationTypeListRequest')
    yield put(actions.loadSMSNotificationListError(''))
    try{
      yield put(actions.loadSMSNotificationListLoading(true));
      const response = yield call(getSMSNotificationList,data.payload);
      if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
        // console.log(response.data.result.result)
        const notificationTypeList = response.data.result
        yield put(actions.loadSMSNotificationListSuccess(notificationTypeList))
      }
      else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
        yield put(saveLoginUser(false))
        invalidToken()
      }  
      else{
        yield put(actions.loadSMSNotificationListLoading(false));
        const massage = common.error
        yield put(actions.loadSMSNotificationListError(massage))
      }
      if(response && response.data && response.data.result && response.data.result.newAccessToken){
        refeshTokenset(response.data.result.newAccessToken)
      }
      yield put(actions.loadSMSNotificationListLoading(false));
    }catch(error){
      yield put(actions.loadSMSNotificationListLoading(false));
      // console.log(error)
      const massage = common.error
      yield put(actions.loadSMSNotificationListError(massage))
    }
  }
