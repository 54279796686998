import { createSelector } from 'reselect';

const selectNotificationDomain = state => state.notification;

const makeSelectNotificationList = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('notificationList') : []
  );

const makeSelectNotificationListLoading = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('isNotificationListLoading') : false
  );

const makeSelectNotificationListError = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('notificationListError') : ''
  );

const makeSelectSMSNotificationList = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('smsList') : []
  );

const makeSelectSMSNotificationListLoading = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('isSMSNotificationListLoading') : false
  );

const makeSelectSMSNotificationListError = () =>
  createSelector(
    selectNotificationDomain,
    notificationState => 
      notificationState ? notificationState.get('smsListError') : ''
  );

export {
    makeSelectNotificationList,
    makeSelectNotificationListLoading,
    makeSelectNotificationListError,
    makeSelectSMSNotificationList,
    makeSelectSMSNotificationListLoading,
    makeSelectSMSNotificationListError
}
