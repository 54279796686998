const PATH = 'app/Report';

export const LOAD_REPORT_REQUEST = `${PATH}LOAD_REPORT_REQUEST`;
export const LOAD_REPORT_ERROR = `${PATH}LOAD_REPORT_ERROR`;
export const LOAD_REPORT_LOADING = `${PATH}LOAD_REPORT_LOADING`;
export const LOAD_REPORT_SUCCESS = `${PATH}LOAD_REPORT_SUCCESS`;
export const LOAD_REPORT_PERIOD_REQUEST = `${PATH}LOAD_REPORT_PERIOD_REQUEST`;
export const LOAD_REPORT_PERIOD_SUCCESS = `${PATH}LOAD_REPORT_PERIOD_SUCCESS`;
export const LOAD_REPORT_PRINT_REQUEST = `${PATH}LOAD_REPORT_PRINT_REQUEST`;
export const LOAD_REPORT_PRINT_SUCCESS = `${PATH}LOAD_REPORT_PRINT_SUCCESS`;
export const LOAD_REPORT_CSV_REQUEST = `${PATH}LOAD_REPORT_CSV_REQUEST`;
export const LOAD_REPORT_CSV_SUCCESS = `${PATH}LOAD_REPORT_CSV_SUCCESS`;