import { createSelector } from 'reselect';

const selectAnalyticsDomain = state => state.analytics;

const makeSelectRatingDetails = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('ratingDetails') : ''
  );

const makeSelectRatingLoading = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('isRatingLoading') : false
  );

const makeSelectRatingError = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('ratingError') : ''
  );


const makeSelectCustomerAcquisitionSummaryDetails = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('customerAcquisitionSummary') : ''
  );

const makeSelectCustomerSummaryLoading = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('isCustomerSummaryLoading') : false
  );

const makeSelectCustomerSummaryError = () =>
  createSelector(
    selectAnalyticsDomain,
    analyticsState =>
      analyticsState ? analyticsState.get('customerSummaryError') : ''
  );

export {
  makeSelectRatingDetails,
  makeSelectRatingLoading,
  makeSelectRatingError,
  makeSelectCustomerAcquisitionSummaryDetails,
  makeSelectCustomerSummaryLoading,
  makeSelectCustomerSummaryError
}
