import { 
  LOAD_EMAIL_TEMPLATE_LIST_ERROR,
  LOAD_EMAIL_TEMPLATE_LIST_LOADING,
  LOAD_EMAIL_TEMPLATE_LIST_REQUEST,
  LOAD_EMAIL_TEMPLATE_LIST_SUCCESS,
  LOAD_SELECTED_EMAIL_TEMPLATE_REQUEST,
  LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS,
  SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS,
  SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST,
  SEND_DELETE_EMAIL_TEMPLATE_REQUEST,
  SEND_EMAIL_TEMPLATE_SUCCESS,
  SEND_UPDATE_EMAIL_TEMPLATE_REQUEST,
  } from '../constant/emailTempConstant';
  
  export function saveIsEmailTemplateModalOpenStatus(open){
    return {
      type: SAVE_IS_EMAIL_TEMPLATE_OPEN_STATUS,
      open,
    }
  };

  export function loadEmailTemplateListRequest(data) {
    return {
      type: LOAD_EMAIL_TEMPLATE_LIST_REQUEST,
      payload: data,
    }
  };
  
  export function loadEmailTemplateListLoading(data) {
    return {
      type: LOAD_EMAIL_TEMPLATE_LIST_LOADING,
      payload: data,
    }
  };
    
  export function loadEmailTemplateListSuccess(data) {
    return {
      type: LOAD_EMAIL_TEMPLATE_LIST_SUCCESS,
      payload: data,
    }
  };
  
  export function loadEmailTemplateListError(data) {
    return {
      type: LOAD_EMAIL_TEMPLATE_LIST_ERROR,
      payload: data,
    }
  };

  export function sendAddNewEmailTemplateRequest(data) {
    return {
      type: SEND_ADD_NEW_EMAIL_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  
  export function sendEmailTemplateSuccess(data) {
    return {
      type: SEND_EMAIL_TEMPLATE_SUCCESS,
      payload: data,
    }
  };
  
  export function sendUpdateEmailTemplateRequest(data) {
    return {
      type: SEND_UPDATE_EMAIL_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  
  export function sendDeleteEmailTemplateRequest(data) {
    return {
      type: SEND_DELETE_EMAIL_TEMPLATE_REQUEST,
      payload: data,
    }
  };

  export function loadSelectedEmailTemplateSuccess(data) {
    return {
      type: LOAD_SELECTED_EMAIL_TEMPLATE_SUCCESS,
      payload: data,
    }
  };

  export function loadSelectedEmailTemplateRequest(data) {
    return {
      type: LOAD_SELECTED_EMAIL_TEMPLATE_REQUEST,
      payload: data,
    }
  };
  