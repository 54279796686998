import { put, call } from 'redux-saga/effects';
import {
  addNewUserRequest,
  getSelectedUserRequest,
  getUserAccessRequest,
  getUserListRequest,
  updateUserRequest,
  updateUserStatusRequest
} from '../service/usersService';
import * as actions from '../../store/action/usersAction';
import common, { getUserAccess, invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';
import { updateImageRequest } from '../service/tenantService';

export function* sendAddNewUserRequest(data) {
  yield put(actions.sendAddNewUserError(''))
  try {
    const response = yield call(addNewUserRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const id = response.data.result.id;
      if (data.payload.isChangeProfileImage) {
        const files = data.payload.fileList;
        const formData = new FormData();
        formData.append(`${id}`, "id")
        for (let i = 0; i < files.length; i++) {
          formData.append(`${id}_Image_${i + 1}`, files[i])
        }
        const parms = {
          formData: formData,
          type: common.imageType.userLogo
        }
        yield call(updateImageRequest, parms);
        // console.log(response.data.message)
      }
      const massage = response.data.message
      yield put(actions.sendAddNewUserSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewUserLoading(false));
  } catch (error) {
    var massage = common.error
    // yield put(actions.sendAddNewUserLoading(false));
    if (error && error.response && error.response.data && error.response.data.status) {
      massage = error.response.data.detail
    }
    yield put(actions.sendAddNewUserError(massage))
    //console.log(error.response.data.detail)
  }
}

export function* loadUserListRequest(data) {
  //console.log('loadUserListRequest')
  yield put(actions.sendAddNewUserError(''))
  try {
    yield put(actions.sendAddNewUserLoading(true));
    const response = yield call(getUserListRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result.result)
      const userList = response.data.result
      yield put(actions.loadUserListSuccess(userList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.sendAddNewUserLoading(false));
      const massage = common.error
      yield put(actions.sendAddNewUserError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.sendAddNewUserLoading(false));
  } catch (error) {
    yield put(actions.sendAddNewUserLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.sendAddNewUserError(massage))
  }
}

export function* sendUpdateUserStatusRequest(data) {
  //console.log('sendUpdateUserStatusRequest')
  yield put(actions.sendAddNewUserError(''))
  try {
    yield put(actions.sendAddNewUserLoading(true));
    const response = yield call(updateUserStatusRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendAddNewUserSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.sendAddNewUserError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.sendAddNewUserLoading(false));
  } catch (error) {
    yield put(actions.sendAddNewUserLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.sendAddNewUserError(massage))
  }
}


export function* sendUpdateUserRequest(data) {
  //console.log('sendUpdateUserRequest')
  yield put(actions.sendAddNewUserError(''))
  try {
    // yield put(actions.sendAddNewUserLoading(true));
    const response = yield call(updateUserRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const id = data.payload.id;
      if (data.payload.isChangeProfileImage) {
        const files = data.payload.fileList;
        const formData = new FormData();
        formData.append(`${id}`, "id")
        for (let i = 0; i < files.length; i++) {
          formData.append(`${id}_Image_${i + 1}`, files[i])
        }
        const parms = {
          formData: formData,
          type: common.imageType.userLogo
        }
        yield call(updateImageRequest, parms);
        // console.log(response.data.message)
      }
      const massage = response.data.message
      yield put(actions.sendAddNewUserSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.sendAddNewUserError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewUserLoading(false));
  } catch (error) {
    // yield put(actions.sendAddNewUserLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.sendAddNewUserError(massage))
  }
}

export function* loadSelectedUserRequest(data) {
  //console.log('loadSelectedUserRequest')
  yield put(actions.sendAddNewUserError(''))
  try {
    yield put(actions.sendAddNewUserLoading(true));
    const response = yield call(getSelectedUserRequest, data.payload);
    if (response && response.status && response.status === 200) {
      //console.log(response.data)
      const User = response.data
      yield put(actions.loadSelectedUserSuccess(User))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.sendAddNewUserError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.sendAddNewUserLoading(false));
  } catch (error) {
    yield put(actions.sendAddNewUserLoading(false));
    //console.log(error)
    const massage = common.error
    yield put(actions.sendAddNewUserError(massage))
  }
}


// export function* loadUserRoleListRequest(data) {
//   try {
//     const response = yield call(getUserRoleRequest);
//     if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
//       const userList = response.data.result.result
//       yield put(actions.loadUserRoleListSuccess(userList))
//     }
//     else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
//       yield put(saveLoginUser(false))
//       invalidToken()
//     }
//     if (response && response.data && response.data.result && response.data.result.newAccessToken) {
//       refeshTokenset(response.data.result.newAccessToken)
//     }
//   } catch (error) {
//   }
// }

export function* loadUserAccessRequest() {
  yield put(actions.loadUserAccessSuccess(common.accessList))
  try {
    const response = yield call(getUserAccessRequest);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const userAccess = response.data.result.result
      const access = getUserAccess(userAccess)
      yield put(actions.loadUserAccessSuccess(access))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
  } catch (error) {
    console.log(error)
  }
}

