import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function signInRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
    return axios.post(baseURL+'auth/SignIn?AppId=03', parameters, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
  }

export function tempPasswordChangeRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
  return axios.post(baseURL+'auth/ChangePassword', parameters, {
      headers: {
          'Content-Type': 'application/json',
      }
  })
}


export function sendConfirmationCodeRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
  return axios.post(baseURL+'auth/SendConfirmationCode', parameters, {
      headers: {
          'Content-Type': 'application/json',
      }
  })
}

export function changePasswordRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
  return axios.post(baseURL+'auth/ConfirmForgotPassword', parameters, {
      headers: {
          'Content-Type': 'application/json',
      }
  })
}

export function updateActivateRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
  return axios.patch(baseURL+'tenants/activate'+data.id, parameters, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function expireStatusRequest(data){
  return axios.get(baseURL+'auth/ExpireStatus?AppId=03', {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

