import React from 'react';
import DateTimePicker  from 'react-datetime-picker';
import 'react-date-picker/dist/DatePicker.css'
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css';

const dateTimePicker = ({
    controlId,
    text,
    // type,
    placeholder,
    // as,
    // row,
    onChange,
    value,
    format,
    required,
    ...props
}) => (
    <div style={{fontSize:'14px',fontWeight: 500,padding:'0 5px'}}>
        {/* <BootstrapFormGroup controlId={controlId}>
            <BootstrapFormLabel>
                {text}
            </BootstrapFormLabel>
            <BootstrapFormControl 
                {...props}
                type={type}
                placeholder={placeholder}
                as={as}
                row={row}
                onChange={onChange}
                size="sm"
            />
        </BootstrapFormGroup> */}

        <BootstrapFormGroup controlId={controlId}>
            <div><BootstrapFormLabel>{text} {required ? <span style={{color:'#FF5733'}}>*</span> : ''}</BootstrapFormLabel></div>
                <DateTimePicker 
                {...props}
                    className="datePicker"
                    onChange={onChange} 
                    placeholder={placeholder} 
                    value={value} 
                    disableClock
                    format="y-MM-dd h:mm:ss a"
                    // locale="hu-HU"
                    // minDate={new Date()}
                />
        </BootstrapFormGroup>
    </div>
);

dateTimePicker.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.any,
    required: PropTypes.any,
    placeholder: PropTypes.any,
    // as: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
    format: PropTypes.any
}

export default dateTimePicker;
