const PATH = 'app/Part';

export const SAVE_IS_PART_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_PART_MODAL_OPEN_STATUS`;
export const LOAD_PART_LIST_REQUEST = `${PATH}LOAD_PART_LIST_REQUEST`;
export const LOAD_PART_LIST_ERROR = `${PATH}LOAD_PART_LIST_ERROR`;
export const LOAD_PART_LIST_LOADING = `${PATH}LOAD_PART_LIST_LOADING`;
export const LOAD_PART_LIST_SUCCESS = `${PATH}LOAD_PART_LIST_SUCCESS`;
export const SEND_ADD_NEW_PART_REQUEST = `${PATH}SEND_ADD_NEW_PART_REQUEST`;
export const SEND_ADD_NEW_PART_ERROR = `${PATH}SEND_ADD_NEW_PART_ERROR`;
export const SEND_ADD_NEW_PART_LOADING = `${PATH}SEND_ADD_NEW_PART_LOADING`;
export const SEND_ADD_NEW_PART_SUCCESS = `${PATH}SEND_ADD_NEW_PART_SUCCESS`;
export const LOAD_PART_CATEGORY_LIST_REQUEST = `${PATH}LOAD_PART_CATEGORY_LIST_REQUEST`;
export const LOAD_PART_CATEGORY_LIST_SUCCESS = `${PATH}LOAD_PART_CATEGORY_LIST_SUCCESS`;
export const SEND_UPDATE_PART_REQUEST = `${PATH}SEND_UPDATE_PART_REQUEST`;
export const SEND_UPDATE_PART_ERROR = `${PATH}SEND_UPDATE_PART_ERROR`;
export const SEND_UPDATE_PART_LOADING = `${PATH}SEND_UPDATE_PART_LOADING`;
export const SEND_UPDATE_PART_SUCCESS = `${PATH}SEND_UPDATE_PART_SUCCESS`;
export const SEND_DELETE_PART_REQUEST = `${PATH}SEND_DELETE_PART_REQUEST`;
export const SEND_DELETE_PART_ERROR = `${PATH}SEND_DELETE_PART_ERROR`;
export const SEND_DELETE_PART_LOADING = `${PATH}SEND_DELETE_PART_LOADING`;
export const SEND_DELETE_PART_SUCCESS = `${PATH}SEND_DELETE_PART_SUCCESS`;