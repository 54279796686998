import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import '../settingMenu.css';
import * as selectors from '../../../../store/selector/productSelector';
import { makeSelectProductCategoryList } from '../../../../store/selector/productCategorySelector';
import {
  makeSelectObjectStatusList,
  makeSelectUOMList
} from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/productAction';
import { loadProductCategoryListRequest } from '../../../../store/action/productCategoryAction'
import Table from '../../../layout/form/Table';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import AddProduct from './AddProduct';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader'
import DeleteModal from '../../../layout/form/deleteModal';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import SearchField from '../../../layout/form/SearchField';

class Product extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isFilterFormOpen: false,
      isValidationError: false,
      errorMassage: '',
      selectedRow: {},
      isUpdate: false,
      isDeleteModalOpen: false,
      sku: '',
      category: '',
      status: '',
      description: '',
      pageSize: 20,
      pageNumber: 1,
      commonSearch: '',
      productTypeId: common.productType.product,
      purchasingFlg: false,
      productActiveTab: "productInformation"
    }
  }

  handleModal = (data) => {
    if (data && data.sku) {
      this.setState({ selectedRow: data, isUpdate: true })
      this.props.dispatch(actions.loadSelectedProductRequest(data.id))
    }
    this.props.dispatch(actions.saveIsProductModalOpenStatus(true));
  }

  handleModalClose = () => {
    this.setState({ selectedRow: {}, isUpdate: false, productActiveTab: "productInformation" })
    this.props.dispatch(actions.saveIsProductModalOpenStatus(false));
    this.props.dispatch(actions.loadSelectedProductSuccess({}))
  }

  handleFilter = () => {
    this.setState({
      isFilterFormOpen: !this.state.isFilterFormOpen,
      sku: this.state.sku,
      category: this.state.category,
      status: this.state.status,
      description: this.state.description,
      commonSearch: '',
      productTypeId: this.state.productTypeId
    })
  }

  handleChangeSearchValue = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      this.handleFilterFormClose()
    }
    this.setState({
      commonSearch: e.target.value,
      isFilterFormOpen: false,
      sku: '',
      category: '',
      status: '',
      description: '',
      productTypeId: common.productType.product,
      pageSize: 20,
      pageNumber: 1,
    })
  }

  handleFilterFormClose = () => {
    this.setState({
      isFilterFormOpen: false,
      sku: '',
      category: '',
      status: '',
      description: '',
      pageSize: 20,
      pageNumber: 1,
      commonSearch: '',
      productTypeId: common.productType.product
    })

    const parameters = {
      pageSize: 20,
      pageNumber: 1,
      sku: '',
      categoryId: '',
      description: '',
      status: '',
      commonSearch: '',
      productTypeId: common.productType.product
    }
    this.props.dispatch(actions.loadProductListRequest(parameters))
  }

  handleSaveAddNew = (details) => {
    if (
      details.sku === '' ||
      details.description === '' ||
      details.unitOfMeasure === '' ||
      details.categoryId === '' ||
      details.status === '') {
      this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
      return
    }

    const parameters = {
      sku: details.sku,
      description: details.description,
      unitOfMeasureId: details.unitOfMeasure,
      categoryId: details.categoryId,
      quantityInStock: details.quantityInStock,
      unitPrice: details.unitPrice,
      statusId: details.status,
      typeId: details.productTypeId,
      purchasingFlg: details.isPurchasing,
      stockFlg: details.isStock,
      lowStockLevel: details.lowStockLevel,
      minOrderQuantity: details.minOrderQuantity,
      maxOrderQuantity: details.maxOrderQuantity,
      defaultIssueQuantity: details.defaultIssueQuantity,
      costPrice: details.cost,
      expenseAccount: details.expenseAccount,
      incomeAccount: details.incomeAccount,
      id: details.id,
    }
    if (this.state.isUpdate) {
      this.props.dispatch(actions.sendUpdateProductRequest(parameters))
    } else {
      this.props.dispatch(actions.sendAddNewProductRequest(parameters))
    }
  }

  handleSaveAlternativeProducts = (details) => {
    const tempItemList = details.tempProductList.filter(data => (data.operation === "D"))
    const list = details.alternativeList.concat(tempItemList)

    const parameters = {
      id: details.id,
      productId: details.id,
      alternatives: list
    }
    this.props.dispatch(actions.sendAlternativeProductRequest(parameters))
  }

  redirectTab = (tab, details) => {
    const { productActiveTab } = this.state;
    if (productActiveTab === 'productInformation') {
      if (details.sku === '' ||
        details.description === '' ||
        details.unitOfMeasure === '' ||
        details.categoryId === '' ||
        details.status === '') {
        this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
        return
      }
    }
    if (productActiveTab === 'productInformation' && details.isChange && tab !== "productInformation") {
      this.handleSaveAddNew(details)
    }
    if (productActiveTab === 'alternatives' && details.isChange && tab !== "alternatives") {
      this.handleSaveAlternativeProducts(details)
    }
    this.setState({
      productActiveTab: tab
    })
  }

  handleDeleteConfirm = (data) => {
    this.setState({ isDeleteModalOpen: true, selectedRow: data })
  }

  handleDeleteModalClose = () => {
    this.setState({ isDeleteModalOpen: false, selectedRow: {} })
  }

  handleDeleteProduct = () => {
    this.handleDeleteModalClose()
    this.props.dispatch(actions.sendDeleteProductRequest(this.state.selectedRow.id))
  }

  errorModalClose = () => {
    this.setState({ isValidationError: false, errorMassage: '' })
    this.props.dispatch(actions.loadProductListError(''))
  }

  successModalClose = () => {
    this.getProductListData()
    this.props.dispatch(actions.sendAddNewProductSuccess(''))
    this.props.dispatch(actions.sendUpdateProductSuccess(''))
    this.handleModalClose()
    this.props.dispatch(actions.loadProductListLoading(false))
    // this.props.dispatch(actions.saveIsProductModalOpenStatus(false));
  }

  getNextPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  getProductListData = () => {

    const parameters = {
      pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
      pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
      sku: this.state.sku,
      categoryId: this.state.category,
      description: this.state.description,
      status: this.state.status,
      commonSearch: this.state.commonSearch,
      productTypeId: this.state.productTypeId,
      purchasingFlg: this.state.purchasingFlg
    }
    if (this.state.isFilterFormOpen) {
      this.setState({
        pageNumber: 1,
        pageSize: 20
      })
    }
    this.props.dispatch(actions.loadProductListRequest(parameters))
  }

  onPressKeySearch = (value) => {
    const parameters = {
      pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
      pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
      sku: this.state.sku,
      categoryId: this.state.category,
      description: this.state.description,
      status: this.state.status,
      commonSearch: value,
      productTypeId: this.state.productTypeId,
      purchasingFlg: this.state.purchasingFlg
    }
    if (this.state.isFilterFormOpen) {
      this.setState({
        pageNumber: 1,
        pageSize: 20
      })
    }
    this.setState({
      commonSearch: value
    })
    this.props.dispatch(actions.loadProductListRequest(parameters))
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleChangeCategory = (value) => {
    this.setState({ category: value })
  }

  handleChangeStatus = (value) => {
    this.setState({ status: value })
  }

  handleChangeProductType = (id) => {
    this.setState({
      productTypeId: id,
    })
  }

  handleSearch = () => {
    this.getProductListData()
    this.handleFilter()
  }

  setUomDescription = (data) => {
    const { uomList } = this.props;
    if (uomList && uomList.length && uomList.length !== 0) {
      const desc = uomList.find(select => select.id === data.unitOfMeasureId)
      return (desc && desc.description ? desc.description : '')
    }
  }

  setStatusDescription = (data) => {
    const { objectStatusList } = this.props;
    if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
      const desc = objectStatusList.find(select => select.id === data.statusId)
      return (desc && desc.description ? desc.description : '')
    }
  }

  setCategoryDescription = (data) => {
    const { productCategoryList } = this.props;
    if (productCategoryList && productCategoryList.result && productCategoryList.result.length && productCategoryList.result.length !== 0) {
      const desc = productCategoryList.result.find(select => select.id === data.categoryId)
      return (desc && desc.description ? desc.description : '')
    }
  }

  componentDidMount() {
    const parameters = {
      pageSize: 9999,
      pageNumber: 1,
      description: '',
      typeId: '',
      name: '',
      sku: '',
      categoryId: '',
      status: '',
      commonSearch: '',
      productTypeId: '',
      purchasingFlg: ''
    }
    this.props.dispatch(actions.loadProductListSuccess([]))
    this.props.dispatch(loadObjectStatusSuccess({}))
    this.getProductListData()
    this.props.dispatch(loadProductCategoryListRequest(parameters));
    this.props.dispatch(loadObjectStatusRequest(common.objectId.products));
    this.props.dispatch(actions.loadProductTypeListRequest())
    this.props.dispatch(actions.loadAllProductsRequest(parameters))
  }

  componentDidUpdate(prevProps, prevState) {
    // if(this.state.isValidationError || this.props.getProductListError !== ''){
    //     setTimeout(
    //         () => this.errorModalClose(), 
    //         500
    //       );
    // }else 
    if (this.props.addNewProductSuccess !== '' || this.props.updateProductSuccess !== '') {
      setTimeout(
        () => this.successModalClose(),
        1000
      );
    }

    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      this.getProductListData()
    }
  }

  render() {

    const {
      isAddNewProductModalOpen,
      productCategoryList,
      objectStatusList,
      uomList,
      getProductListError,
      addNewProductSuccess,
      isProductListLoading,
      productList,
      isUpdateProductLoading,
      updateProductSuccess,
      productTypeList,
      selectedProduct,
      alternativeProductList,
      allProducts
    } = this.props;

    const {
      isFilterFormOpen,
      isValidationError,
      errorMassage,
      selectedRow,
      isDeleteModalOpen
    } = this.state;

    const columns = [{
      Header: 'SKU',
      accessor: 'sku',
      width: "8%",
      Cell: ((row) => (
        <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
          {row.row.original.sku}
        </div>
      ))
    }, {
      //   Header: 'Type',
      //   accessor: 'type',
      //   width: "8%",
      //   Cell: ((row) => (
      //       <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
      //           {row.row.original.type}
      //       </div>
      //   ))
      // }, {
      Header: 'Description',
      accessor: 'description',
      Cell: ((row) => (
        <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
          {row.row.original.description}
        </div>
      ))
    }, {
      Header: 'Category',
      width: "10%",
      Cell: ((row) => (
        <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
          {this.setCategoryDescription(row.row.original)}
        </div>
      ))
    }, {
      Header: 'UOM',
      width: "10%",
      Cell: ((row) => (
        <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
          {this.setUomDescription(row.row.original)}
        </div>
      ))
    }, {
      Header: 'Unit Price',
      width: "8%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right", cursor: 'pointer'
        }} onClick={() => this.handleModal(row.row.original)}>
          {parseFloat(row.row.original.unitPrice).toFixed(2)}
        </div>
      ))
    }, {
      Header: 'Quantity',
      width: "8%",
      Cell: ((row) => (
        <div style={{
          textAlign: "right", cursor: 'pointer'
        }} onClick={() => this.handleModal(row.row.original)}>
          {parseFloat(row.row.original.quantityInStock).toFixed(2)}
        </div>
      )),
      // accessor:'quantityInStock'
    }, {
      Header: 'Status',
      width: "10%",
      Cell: ((row) => (
        <div onClick={() => this.handleModal(row.row.original)} style={{ cursor: 'pointer' }}>
          {this.setStatusDescription(row.row.original)}
        </div>
      ))
    }, {
      Header: 'Action',
      width: "8%",
      Cell: ((row) => (
        <div>
          {/* <Button */}
          {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
            <span onClick={() => this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>
          </span>}
          {/* /> */}
        </div>
      )),
      accessor: 'action'
    }]


    return (
      <div>
        {(isProductListLoading ||
          isUpdateProductLoading ||
          (productList.result && productList.result.length && productList.result.length === 0) ||
          (productCategoryList && productCategoryList.result && productCategoryList.result.length && productCategoryList.result.length === 0) ||
          (objectStatusList && objectStatusList.length && objectStatusList.length === 0) ||
          (uomList && uomList.length && uomList.length === 0)) ?
          <Loader show={isProductListLoading} /> : ''}
        {(isValidationError || getProductListError !== '') &&
          <ErrorModal
            show={true}
            massage={getProductListError === '' ? errorMassage : getProductListError}
            handleClose={this.errorModalClose}
          />}
        {(addNewProductSuccess !== '' || updateProductSuccess !== '') &&
          <SuccessModal
            show={true}
            massage={addNewProductSuccess ? addNewProductSuccess : updateProductSuccess}
            handleClose={this.successModalClose}
          />}
        {isDeleteModalOpen &&
          <DeleteModal
            show={isDeleteModalOpen}
            handleDeleteModalClose={this.handleDeleteModalClose}
            handleDelete={this.handleDeleteProduct}
            title={selectedRow.description}
          />}
        {isAddNewProductModalOpen ?
          <div className="body-content">
            <AddProduct
              isAddNewProductModalOpen={isAddNewProductModalOpen}
              handleModalClose={this.handleModalClose}
              handleSaveAddNew={this.handleSaveAddNew}
              productCategoryList={productCategoryList.result}
              objectStatusList={objectStatusList}
              uomList={uomList}
              selectedRow={selectedRow}
              isUpdate={this.state.isUpdate}
              productTypeList={productTypeList}
              selectedProduct={selectedProduct}
              productActiveTab={this.state.productActiveTab}
              redirectTab={this.redirectTab}
              alternativeProductList={alternativeProductList}
              allProducts={allProducts}
              handleSaveAlternativeProducts={this.handleSaveAlternativeProducts}
            />
          </div> :
          <div>
            <div className="page-header">
              <div className="btn-group">
                <ButtonGroup
                  primaryBtnClick={this.handleModal}
                  secondaryBtnClick={this.handleFilter}
                  primaryBtnVariant="outline-primary"
                  primaryBtnText='Add New'
                  primaryBtnIcon='sys-add'
                  secondaryBtnText='Filter'
                  secondaryBtnVariant="outline-primary"
                  secondaryBtnIcon='filter'
                />
              </div>
            </div>
            <div className="body-content">
              <div style={{ display: 'flex' }}>
                <div>
                  <h5 style={{ marginLeft: '5px' }}>Product List</h5>
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <SearchField
                    onClickSearch={this.getProductListData}
                    controlId="commonSearch"
                    onChange={this.handleChangeSearchValue}
                    defaultValue={this.state.commonSearch}
                    onPressKeySearch={this.onPressKeySearch}
                  />
                </div>
              </div>
              {isFilterFormOpen &&
                <div className="filter-form">
                  <div className="filter-header">
                    <h5>Filter Form</h5>
                    <div className="btn-close" onClick={this.handleFilterFormClose} >
                      <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <TextField
                        text="Product Id"
                        controlId="sku"
                        placeholder="Product Id"
                        type="text"
                        onChange={this.handleChange}
                        maxLength={common.maxLengths.sku}
                        defaultValue={this.state.sku}
                      />
                    </div>
                    <div className="col-sm-3">
                      <SelectField
                        text="Category"
                        controlId="category"
                        onChange={this.handleChangeCategory}
                        options={productCategoryList.result}
                        selectedValue={this.state.category}
                      />
                    </div>
                    <div className="col-sm">
                      <SelectField
                        text="Status"
                        controlId="status"
                        onChange={this.handleChangeStatus}
                        options={objectStatusList}
                        selectedValue={this.state.status}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <TextField
                        text="Description"
                        controlId="description"
                        placeholder="Description"
                        type="text"
                        onChange={this.handleChange}
                        maxLength={common.maxLengths.productDescription}
                        defaultValue={this.state.description}
                      />
                    </div>
                    {/* <div className="col-sm">
                                <SelectField                                            
                                  text="Type"
                                  controlId="productType"
                                  onChange={this.handleChangeProductType}
                                  options={productTypeList}
                                  selectedValue={this.state.productTypeId}
                                />
                              </div> */}
                  </div>
                  <div className="filter-btns">
                    <ButtonGroup
                      primaryBtnClick={this.handleSearch}
                      primaryBtnIcon='search'
                      primaryBtnText='Search'
                      primaryBtnVariant="outline-primary"
                      secondaryBtnClick={this.handleFilterFormClose}
                      secondaryBtnIcon='clear-filter'
                      secondaryBtnText='Clear'
                      secondaryBtnVariant="outline-primary"
                    />
                  </div>
                </div>
              }
              <div className="table-content">
                <Table
                  columns={columns}
                  data={productList.result && productList.result.length ? productList.result : []}
                />
              </div>
            </div>
            <Pagination
              currentPage={productList.page ? productList.page : this.state.pageNumber}
              getNextPage={this.getNextPage}
              getPrevPage={this.getPrevPage}
              totalPage={productList.totalPages}
              setClickedPage={this.setClickedPage}
              currentPageSize={this.state.pageSize}
              setPageSize={this.setPageSize}
            />
          </div>}
      </div>
    )
  }
}

Product.propTypes = {
  isAddNewProductModalOpen: PropTypes.bool,
  productCategoryList: PropTypes.any,
  objectStatusList: PropTypes.any,
  uomList: PropTypes.any,
  getProductListError: PropTypes.any,
  addNewProductSuccess: PropTypes.any,
  isProductListLoading: PropTypes.bool,
  productList: PropTypes.any,
  isUpdateProductLoading: PropTypes.bool,
  updateProductSuccess: PropTypes.any,
  productTypeList: PropTypes.any,
  selectedProduct: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
  isAddNewProductModalOpen: selectors.makeSelectProductPopupOpen(),
  productCategoryList: makeSelectProductCategoryList(),
  objectStatusList: makeSelectObjectStatusList(),
  uomList: makeSelectUOMList(),
  addNewProductSuccess: selectors.makeSelectAddNewProductSuccess(),
  getProductListError: selectors.makeSelectProductListError(),
  isProductListLoading: selectors.makeSelectProductListLoading(),
  productList: selectors.makeSelectProductList(),
  isUpdateProductLoading: selectors.makeSelectUpdateProductLoading(),
  updateProductSuccess: selectors.makeSelectUpdateProductSuccess(),
  productTypeList: selectors.makeSelectProductTypeList(),
  selectedProduct: selectors.makeSelectSelectedProduct(),
  alternativeProductList: selectors.makeSelectAlternativeProductList(),
  allProducts: selectors.makeSelectAllProducts()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(Product));