import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getObjectStatusList(search){
    return axios.get(baseURL+'objectStatus?Search='+search, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getUOMList(){
    return axios.get(baseURL+'unitOfMeasure', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getCountryList(){
    return axios.get(baseURL+'countries', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTenantCommon(id){
    return axios.get(baseURL+'tenants/common/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getBillingTerms() {
    return axios.get(baseURL + 'billingTerms', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getAppPackages() {
    return axios.get(baseURL + 'appPackages?AppId=03', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}