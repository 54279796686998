import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getAccountSystemRequest(id){
    return axios.get(baseURL+'accounting/accountSystem', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteConnectionRequest(id){
    return axios.delete(baseURL+'accountIntegration/connection/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
  }

export function getQuickBooksConnectionRequest(){
    return axios.get(baseURL+'quickBooks/quickBooksConnection', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateConnectionFromDateRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'accountIntegration/connectionFromDate', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendConnectionRequest(data){
    const parameters = JSON.stringify(data)
    return axios.post(baseURL+'accountIntegration', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}