import React from 'react';
import BootstrapButton from 'react-bootstrap/cjs/Button';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css';

const Button = ({
    variant,
    text,
    onClick,
    type,
    icon,
    color,
    ...props
}) => (
    <div style={{maxWidth:"150px",minWidth:'120px', justifyContent:"center", marginTop:'10px'}}>
        <BootstrapButton 
            variant = {variant}
            onClick = {onClick}
            type = {type}
            {...props}
            style={{borderRadius:'20px', color:{color} ,width:'100%',fontSize:"12px"}}
            className="button"
        >
            {icon ? 
            <span>
                <ui5-icon class="samples-margin" name={icon} id="btn-icon"></ui5-icon> {text}
            </span> : text}
        </BootstrapButton>
    </div>
);

Button.propTypes = {
    variant: PropTypes.any,
    text: PropTypes.string,
    onClick: PropTypes.any,
    type: PropTypes.any,
    icon: PropTypes.any,
    color: PropTypes.any,
}

export default Button;