import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import { Link } from 'react-router-dom';
import DatePicker from '../../../layout/form/datePicker';
import * as actions from '../../../../store/action/a2aMappingAction';
import * as selectors from '../../../../store/selector/a2aMappingSelector';
import moment from 'moment';
import common, { numberWithCommas } from '../../../common';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Table from '../../../layout/form/Table';
import Pagination from '../../../layout/form/Pagination';
// import Loader from '../../../layout/form/Loader';
// import ErrorModal from '../../../layout/form/errorModal';
import { loadCustomerListSuccess } from '../../../../store/action/customerAction';
import { loadInvoiceListSuccess } from '../../../../store/action/invoiceAction';
// import { loadSelectedJobCardSuccess } from '../../../../store/action/jobCardAction';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import Modal from '../../../layout/form/Modal';
import Button from '../../../layout/form/Button';

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

class InvoiceReconciliationReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(today).format(common.dateFormat),
            pageNumber: 1,
            pageSize: 20,
            initial: true,
            invoiceReport: [],
            isValidationError: false,
            errorMassage: '',
            isInfoModalOpen: false,
            infoMessage: ''
        }
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { invoiceReconciliationReport } = prevProps;
        const { initial } = prevState;
        if (invoiceReconciliationReport && invoiceReconciliationReport.result && invoiceReconciliationReport.result.length && initial) {
            return {
                initial: false,
                invoiceReport: invoiceReconciliationReport.result
            }
        }
        return null
    }

    handleDateChange = (date, id) => {
        if (date === null) {
            return
        }
        else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
            })
        }
    }



    // errorModalClose = () => {
    //     this.setState({ isValidationError: false, errorMassage: '' })
    //     this.props.dispatch(actions.loadReportError(''))
    // }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    setMonthFormat = (value) => {
        const date = moment(value).format('MMMM-YYYY');
        return date
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    handleRunReport = () => {
        if (this.state.fromDate === '' ||
            this.state.toDate === '') {
            this.setState({ isValidationError: true, errorMassage: 'Select Report Period' })
            return
        }
        var invoiceDate = new Date(this.state.fromDate)
        var dueDate = new Date(this.state.toDate)
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            invoiceDate: `${invoiceDate.getFullYear()}, ${invoiceDate.getMonth() + 1}, ${invoiceDate.getDate()}`,
            dueDate: `${dueDate.getFullYear()}, ${dueDate.getMonth() + 1}, ${dueDate.getDate()}`,
        }
        this.setState({
            initial: true
        })
        this.props.dispatch(actions.loadInvoiceReconciliationReportSuccess([]))
        this.props.dispatch(actions.loadInvoiceReconciliationReportRequest(parameters))
    }

    handleClose = () => {
        this.setState({
            reportPeriod: '',
            fromDate: '',
            toDate: '',
            groupBy: 1,
            pageNumber: 1,
            pageSize: 20,
            invoiceReport: [],
            initial: true
        })
        this.props.dispatch(actions.loadInvoiceReconciliationReportSuccess([]))
    }

    redirectCustomer = () => {
        this.props.dispatch(loadCustomerListSuccess([]))
    }

    redirectInvoice = () => {
        this.props.dispatch(loadInvoiceListSuccess([]))
        this.props.dispatch(loadCustomerListSuccess([]))
    }

    handleModal = (details) => {
        this.setState({
            isInfoModalOpen: true,
            infoMessage: details.message ? details.message : null
        })
    }

    handleModalClose = () => {
        this.setState({
            isInfoModalOpen: false,
            infoMessage: ''
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.handleRunReport()
        }
    }

    componentDidMount() {
        this.props.dispatch(actions.loadInvoiceReconciliationReportSuccess([]))
        this.handleRunReport()
    }

    render() {
        const {
            invoiceReconciliationReport,
            tenantAccountSystem
            // reportListError,
            // isReportListLoading,
        } = this.props;

        const {
            invoiceReport,
            isInfoModalOpen,
            infoMessage
            // isValidationError,
            // errorMassage,
        } = this.state;

        const column = [{
            Header: 'Invoice No',
            accessor: 'intInvoiceNo',
            width: "8%",
        }, {
            Header: 'Date',
            accessor: 'invoiceDate',
            width: "8%",
            Cell: ((row) => (
                <div >
                    {row.row.original.invoiceDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.invoiceDate)}
                </div>
            )),
        }, {
            Header: 'Customer',
            accessor: 'customerName',
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Total </div>),
            accessor: 'intSubTotal',
            width: "8%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.intSubTotal).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Discount</div>),
            accessor: 'intDiscountValue',
            width: "7%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.intDiscountValue).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Tax </div>),
            accessor: 'intTax',
            width: "7%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.intTax).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (
                <div>
                    {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks ? "QuickBooks Invoice No" :
                        tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero ? "Xero Invoice No" :
                            tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob ? "Myob Invoice No" : ""}
                </div>),
            accessor: 'extInvoiceNo',
            width: "10%",
        }, {
            Header: () => (
                <div style={{ textAlign: "right" }}>
                    {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks ? "QuickBooks Total" :
                        tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero ? "Xero Total" :
                            tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob ? "Myob Total" : ""}
                </div>),
            accessor: 'extSubTotal',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.extSubTotal).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (
                <div style={{ textAlign: "right" }}>
                    {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks ? "QuickBooks Discount" :
                        tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero ? "Xero Discount" :
                            tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob ? "Myob Discount" : ""}
                </div>),
            accessor: 'extDiscountValue',
            width: "8%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.extDiscountValue).toFixed(2))}
                </div>
            ))
        }, {
            Header: () => (
                <div style={{ textAlign: "right" }}>
                    {tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks ? "QuickBooks Tax" :
                        tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero ? "Xero Tax" :
                            tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob ? "Myob Tax" : ""}
                </div>),
            accessor: 'extTax',
            width: "8%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.extTax).toFixed(2))}
                </div>
            ))
        }, {
            Header: 'Status',
            accessor: 'status',
            width: "5%",
        }, {
            Header: 'Details',
            width: "5%",
            Cell: ((row) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {/* {(isReportListLoading ||
                    (invoiceReconciliationReport && invoiceReconciliationReport.length && invoiceReconciliationReport.length === 0)) ?
                    <Loader show={isReportListLoading} /> : ''}

                {(isValidationError || reportListError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : reportListError}
                        handleClose={this.errorModalClose}
                    />} */}

                {isInfoModalOpen &&
                    <Modal
                        show={isInfoModalOpen}
                        onHide={this.handleModalClose}
                        title="Details"
                        width='40%'
                        body={
                            <div>
                                <div>
                                    {infoMessage}
                                </div>
                            </div>
                        }
                        footer={
                            <div>
                                <Button
                                    variant="outline-primary"
                                    text='Cancel'
                                    type="submit"
                                    onClick={this.handleModalClose}
                                    icon="undo"
                                />
                            </div>
                        }
                    />
                }
                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        <span>
                            <Link to={
                                tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.quickBooks ? './quickBooks' :
                                    tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.xero ? './xero' :
                                        tenantAccountSystem && tenantAccountSystem.systemId === common.accounting.myob ? './myob' : ''}>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span>
                        <span>
                            <Link to='./invoiceReconciliationReport'>
                                <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="content-body">
                    <h5>Invoice Reconciliation Report</h5>
                    <div className="filter-form">
                        <div className="row ">
                            <div className="col-sm-4">
                                <DatePicker
                                    text="From date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e, "fromDate")}
                                    value={this.state.fromDate === '' ? '' : new Date(this.state.fromDate)}
                                    required
                                // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm-4">
                                <DatePicker
                                    text="To date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e, "toDate")}
                                    value={this.state.toDate === '' ? '' : new Date(this.state.toDate)}
                                    required
                                // format={dateFormat}
                                />
                            </div>
                        </div>

                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={() => this.handleRunReport()}
                                primaryBtnIcon='accept'
                                primaryBtnText='Run Report'
                                primaryBtnVariant="outline-primary"
                                secondaryBtnClick={() => this.handleClose()}
                                secondaryBtnIcon='decline'
                                secondaryBtnText='Cancel'
                                secondaryBtnVariant="outline-primary"
                            />
                        </div>
                    </div>
                    <div className="table-content">
                        <Table
                            columns={column}
                            data={invoiceReport}
                        />
                    </div>
                </div>
                {invoiceReport.length > 0 &&
                    <Pagination
                        currentPage={invoiceReconciliationReport.page ? invoiceReconciliationReport.page : this.state.pageNumber}
                        getNextPage={this.getNextPage}
                        getPrevPage={this.getPrevPage}
                        totalPage={invoiceReconciliationReport.totalPages ? invoiceReconciliationReport.totalPages : 1}
                        setClickedPage={this.setClickedPage}
                        currentPageSize={this.state.pageSize}
                        setPageSize={this.setPageSize}
                    />}
            </div>
        )
    }
}

InvoiceReconciliationReport.propTypes = {
    invoiceReconciliationReport: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    invoiceReconciliationReport: selectors.makeSelectInvoiceReconciliationReport(),
    tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(InvoiceReconciliationReport));