import React, { Component } from 'react';
import '../settingMenu.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/accountProductCategorySelector';
// import * as actions from '../../../../store/action/accountProductCategoryAction';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Loader from '../../../layout/form/Loader';
import SelectField from '../../../layout/form/SelectField';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import {
  loadAccountProductCategoryError,
  loadAccountProductCategoryRequest,
  saveIsAccountProductCategory,
  sendMappingProductCategorySuccess,
  loadAccountCustomerRequest,
  loadMappingCustomerRequest,
  sendMappingCustomerRequest,
  saveIsAccountCustomerStatus,
  loadMappingAccountRequest,
  sendMappingAccountRequest
} from '../../../../store/action/accountProductCategoryAction';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import { makeSelectCustomerList } from '../../../../store/selector/customerSelector';
import Pagination from '../../../layout/form/Pagination';
import { makeSelectAccountTaxCodeList } from '../../../../store/selector/a2aMappingSelector';
import { loadTaxCodeRequest, sendTaxCodeRequest } from '../../../../store/action/a2aMappingAction';

const customStyles = {
  control: base => ({
    ...base,
    height: 20,
    minHeight: 32,
    marginBottom: 0,
    marginTop: -20
  })
};

const tenantId = sessionStorage.getItem("tenantId")
class MyobMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      serviceList: [],
      contactList: [],
      accountList: [],
      initial: true,
      pageNumber: 1,
      pageSize: 20,
      taxCode: ''
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    const {
      tenantAccountSystem,
      isAccountProductCategoryList,
      mappingAccountList,
      isAccountCustomerList,
      mappingCustomerList
    } = prevProps;
    if (
      prevState.initial &&
      tenantAccountSystem && tenantAccountSystem.id === 0 && isAccountProductCategoryList && isAccountCustomerList) {

      var contactList = []
      var newMappingContactList = []
      var newMappingAccountList = []
      if (mappingCustomerList && mappingCustomerList.result && mappingCustomerList.result.length && mappingCustomerList.result.length > 0) {
        for (let q = 0; q < mappingCustomerList.result.length; q++) {
          const customerItem_ = mappingCustomerList.result[q]
          const item = {
            tenantId: customerItem_.tenantId,
            intlCustomerId: customerItem_.intlCustomerId,
            accSystemId: customerItem_.accSystemId,
            displayName: customerItem_.displayName,
            isChange: false,
            extlCustomerId: customerItem_.extlCustomerId,
          }
          newMappingContactList.push(item)
        }
      }

      if (mappingAccountList && mappingAccountList.length && mappingAccountList.length > 0) {
        for (let q = 0; q < mappingAccountList.length; q++) {
          const account_ = mappingAccountList[q]
          const item = {
            tenantId: account_.tenantId,
            intlAccountId: account_.intlAccountId,
            accSystemId: tenantAccountSystem.systemId,
            intlName: account_.intlName,
            isChange: false,
            extlAccountId: account_.extlAccountId,
            code: account_.code
          }
          newMappingAccountList.push(item)
        }
      }
      return {
        initial: false,
        contactList: newMappingContactList.concat(contactList),
        accountList: newMappingAccountList,
        taxCode: tenantAccountSystem.taxCode
      }
    }
    return null
  }

  componentDidMount() {
    this.getProductCategoryList()
  }

  getProductCategoryList = () => {
    this.props.dispatch(loadMappingAccountRequest())
    this.props.dispatch(loadAccountProductCategoryRequest())
    this.props.dispatch(loadAccountCustomerRequest())
    this.props.dispatch(loadTaxCodeRequest())
    this.getMappingCustomerListData()
  }

  getMappingCustomerListData = () => {
    const mappingCustomerParms = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    }
    this.props.dispatch(loadMappingCustomerRequest(mappingCustomerParms))
  }

  handleCustomer = (e, rowData) => {
    const { contactList } = this.state;
    var newRow = {
      tenantId: rowData.original.tenantId,
      accSystemId: rowData.original.accSystemId,
      isChange: true,
      intlCustomerId: rowData.original.intlCustomerId,
      displayName: rowData.original.displayName,
      extlCustomerId: e
    }
    contactList[rowData.index] = newRow
    this.setState({
      contactList
    })
  }

  handleAccounts = (e, rowData) => {
    const { accountList } = this.state;
    const { accountProductCategoryList } = this.props;
    const selectedRow = accountProductCategoryList.find(row => row.id === e)
    var newRow = {
      tenantId: rowData.original.tenantId,
      accSystemId: rowData.original.accSystemId,
      isChange: true,
      intlAccountId: rowData.original.intlAccountId,
      intlName: rowData.original.intlName,
      code: e,
      extlAccountId: selectedRow.extlId,
    }
    accountList[rowData.index] = newRow
    this.setState({
      accountList
    })
  }

  handleSaveCustomer = () => {
    const { contactList } = this.state;
    const Customers = contactList.filter(e => e.isChange === true)
    const parms = {
      tenantId: parseInt(tenantId),
      Customers: Customers,
      AccSystemId: this.props.tenantAccountSystem.systemId
    }
    if (Customers && Customers.length > 0) {
      this.props.dispatch(sendMappingCustomerRequest(parms))
    }
  }

  handleTaxCode = (id) => {
    this.setState({
      taxCode: id
    })
  }

  handleSaveAccounts = () => {
    const { accountList } = this.state;
    const accounts = accountList.filter(e => e.isChange === true)
    const parms = {
      tenantId: parseInt(tenantId),
      Accounts: accounts,
      AccSystemId: this.props.tenantAccountSystem.systemId
    }
    if (accounts && accounts.length > 0) {
      this.props.dispatch(sendMappingAccountRequest(parms))
    }
  }

  handleSaveTaxCode = () => {
    const { taxCode } = this.state;
    const parms = {
      taxCode: taxCode
    }
    this.props.dispatch(sendTaxCodeRequest(parms))
  }

  handleClose = () => {
    this.setState({
      initial: true
    })
    this.props.dispatch(saveIsAccountProductCategory(false))
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.getProductCategoryList()
  }

  errorModalClose = () => {
    this.props.dispatch(loadAccountProductCategoryError(''))
  }

  successModalClose = () => {
    this.props.dispatch(sendMappingProductCategorySuccess(''))
    this.props.dispatch(saveIsAccountProductCategory(false));
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.getProductCategoryList()
  }

  getNextPage = () => {
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.setState({
      pageNumber: this.state.pageNumber + 1
    })
  }

  getPrevPage = () => {
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.setState({
      pageNumber: this.state.pageNumber - 1
    })
  }

  setClickedPage = (page) => {
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.setState({
      pageNumber: page
    })
  }

  setPageSize = (size) => {
    this.props.dispatch(saveIsAccountCustomerStatus(false))
    this.setState({
      pageSize: size,
      pageNumber: 1
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mappingProductCategory !== '') {
      setTimeout(
        () => this.successModalClose(),
        1000
      );
    }

    if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
      this.setState({
        initial: true
      })
      this.handleSaveCustomer()
      this.getMappingCustomerListData()
      this.props.dispatch(saveIsAccountCustomerStatus(false))
    }
  }

  render() {
    const {
      isAccountProductCategoryLoading,
      accountProductCategoryList,
      accountProductCategoryError,
      mappingProductCategory,
      accountCustomerList,
      mappingCustomerList,
      accountTaxCodeList
    } = this.props;
    const {
      contactList,
      accountList
    } = this.state;
    const columnsAccount = [{
      Header: 'Account ID',
      accessor: 'intlAccountId',
      width: '15%',
    }, {
      Header: 'Account Name',
      accessor: 'intlName',
    }, {
      Header: 'Acc.System Item Description',
      accessor: 'accountSystemDescription',
      Cell: (rowData) => (
        <div>
          <SelectField
            controlId="accountSystemId"
            onChange={(e) => this.handleAccounts(e, rowData.cell.row)}
            options={accountProductCategoryList}
            style={customStyles}
            selectedValue={rowData.cell.row.original.code}
            display="description"
            id="id"
          />
        </div>
      ),
    }, {
      Header: 'Acc.System Item Code',
      accessor: 'code',
      Cell: (rowData) => (
        <div>{rowData.cell.row.original.code ? rowData.cell.row.original.code : ''}</div>
      ),
      width: '20%',
    }]

    const columnsCustomer = [{
      Header: 'Customer ID',
      accessor: 'intlCustomerId',
      width: '15%',
    }, {
      Header: 'Customer Name',
      accessor: 'displayName',
    }, {
      Header: 'Acc.System Customer Name',
      accessor: 'extlCustomerId',
      Cell: (rowData) => (
        <div>
          <SelectField
            controlId="extlCustomerId"
            onChange={(e) => this.handleCustomer(e, rowData.cell.row)}
            options={accountCustomerList}
            style={customStyles}
            selectedValue={rowData.cell.row.original.extlCustomerId}
            display="name"
            id="id"
          />
        </div>
      ),
    }]

    return (
      <div>
        {accountProductCategoryError !== '' &&
          <ErrorModal
            show={true}
            massage={accountProductCategoryError}
            handleClose={this.errorModalClose}
          />}

        {(mappingProductCategory) &&
          <SuccessModal
            show={true}
            massage={mappingProductCategory}
            handleClose={this.successModalClose}
          />}
        {isAccountProductCategoryLoading ?
          <Loader /> : ''}
        <div className="body-content">
          {/* <h5>Sales Category List</h5> */}
          <Tabs defaultActiveKey="taxCode" id="masterDataMappingTab">
            <Tab
              eventKey="taxCode"
              title={<span> Tax code</span>}>
              <div className="table-content">
                <SelectField
                  controlId="taxCode"
                  text="Select tax code"
                  onChange={this.handleTaxCode}
                  options={accountTaxCodeList}
                  selectedValue={this.state.taxCode}
                />
              </div>
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleClose}
                  // primaryBtnIcon='decline'
                  primaryBtnText='Go Back'
                  primaryBtnIcon='undo'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleSaveTaxCode}
                  secondaryBtnIcon='save'
                  secondaryBtnText='Save'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </Tab>
            <Tab
              eventKey="account"
              title={<span> Account</span>}>
              <div className="table-content">
                <Table
                  columns={columnsAccount}
                  data={accountList}
                // data={accountProductCategoryList && accountProductCategoryList.length ? accountProductCategoryList : []}                   
                />
              </div>
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleClose}
                  // primaryBtnIcon='decline'
                  primaryBtnText='Go Back'
                  primaryBtnIcon='undo'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleSaveAccounts}
                  secondaryBtnIcon='save'
                  secondaryBtnText='Save'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </Tab>
            <Tab
              eventKey="customer"
              title={<span> Customer</span>}>
              <div className="table-content">
                <Table
                  columns={columnsCustomer}
                  data={contactList}
                // data={accountProductCategoryList && accountProductCategoryList.length ? accountProductCategoryList : []}                   
                />
              </div>
              <Pagination
                currentPage={mappingCustomerList.page ? mappingCustomerList.page : this.state.pageNumber}
                getNextPage={this.getNextPage}
                getPrevPage={this.getPrevPage}
                totalPage={mappingCustomerList.totalPages}
                setClickedPage={this.setClickedPage}
                currentPageSize={this.state.pageSize}
                setPageSize={this.setPageSize}
              />
              <div className="filter-btns">
                <ButtonGroup
                  primaryBtnClick={this.handleClose}
                  // primaryBtnIcon='decline'
                  primaryBtnText='Go Back'
                  primaryBtnIcon='undo'
                  primaryBtnVariant="outline-primary"
                  secondaryBtnClick={this.handleSaveCustomer}
                  secondaryBtnIcon='save'
                  secondaryBtnText='Save'
                  secondaryBtnVariant="outline-primary"
                />
              </div>
            </Tab>
          </Tabs>
        </div>

      </div>
    )
  }
}

MyobMapping.propTypes = {
  isAccountProductCategoryLoading: PropTypes.any,
  accountProductCategoryList: PropTypes.any,
  // productCategoryList: PropTypes.any,
  accountProductCategoryError: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
  isAccountProductCategoryLoading: selectors.makeSelectAccountProductCategoryLoading(),
  accountProductCategoryList: selectors.makeSelectAccountProductCategory(),
  // productCategoryList: makeSelectProductCategoryList(),
  accountProductCategoryError: selectors.makeSelectAccountProductCategoryError(),
  tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
  isAccountProductCategoryList: selectors.makeSelectIsLoadAccountProductCategory(),
  // mappingProductCategoryList : selectors.makeSelectMappingProductCategory(),
  mappingProductCategory: selectors.makeSelectMappingProductCategorySuccess(),
  customerList: makeSelectCustomerList(),
  isAccountCustomerList: selectors.makeSelectIsLoadAccountCustomer(),
  mappingCustomerList: selectors.makeSelectMappingCustomer(),
  accountCustomerList: selectors.makeSelectAccountCustomer(),
  mappingAccountList: selectors.makeSelectMappingAccountList(),
  accountTaxCodeList: makeSelectAccountTaxCodeList()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);


export default (compose(withConnect)(MyobMapping));