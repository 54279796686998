import React from 'react';
import PropTypes from 'prop-types';
import './form.css';
import { Doughnut } from 'react-chartjs-2'



const PieChart = ({
    data
}) => (
    <div style={{width:'100%',height:'250px'}} >
        <Doughnut
            data={data} 
            options={{
                maintainAspectRatio: false,
                responsive: true,
            }}
        />
    </div>
);

PieChart.propTypes = {
    data: PropTypes.any,
}

export default PieChart;