import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import TextEditor from '../../../layout/form/TextEditor';
import common from '../../../common';

export class AddEmailTemplate extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            email:'',
            emailSubject:'',
            initial:true,
            id:0,
            // isEdit: true,
            isUserAccess: false
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        var access = false
        if(prevProps.selectedRow && prevProps.selectedRow.id && prevState.initial){
            if(prevProps.userAccess && prevProps.userAccess.modifyEmailTemplate){
                access = true
            }
            return {
                emailSubject:prevProps.selectedRow.name,
                email: decodeURIComponent(prevProps.selectedRow.body),
                id: prevProps.selectedRow.id,
                initial:false,
                // isEdit: false,
                isUserAccess: access
            }
        }else if( prevState.initial){
            if(prevProps.userAccess && prevProps.userAccess.createEmailTemplate){
                access = true
            }
            return {
                initial:false,
                isUserAccess: access
            }
        }
        return null
    }

    onEditorChange  = (editorState) => {
        this.setState({
            email:editorState,
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleEdit = () => {
        this.setState({
            isEdit: true
        })
    }

    editTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Edit
        </Tooltip>
    );
    
  
    render() {
        const {
            isAddNewEmailTemplateOpen, 
            handleModalClose,
            handleSaveAddNew,
            // selectedRow
        } = this.props;

        const { isUserAccess } = this.state;

        return (
            <div>
                <Modal
                    show ={isAddNewEmailTemplateOpen} 
                    onHide={handleModalClose}
                    title="Template Information"
                    width='60%'
                    body={
                        <div>
                            {/* <div className="content-header" style={{margin:'-20px 0 15px 0'}}>
                                <div></div>
                                <div style={{display:'flex'}}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.editTooltip}
                                    >
                                        <span className="header-edit-icon" onClick={() => this.handleEdit()}>
                                            <ui5-icon class="samples-margin" name="edit" id="header-edit-icon"></ui5-icon>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </div> */}
                            <form style={{marginTop:"5px"}}>
                                <div className='row'>
                                    <div className="col-sm">
                                        <TextField
                                            text="Email Subject"
                                            onBlur={this.handleChange}
                                            controlId="emailSubject"
                                            type="text"
                                            defaultValue={this.state.emailSubject}
                                            maxLength={common.maxLengths.smsTemplateName}
                                            required
                                            disabled={!( isUserAccess)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm">
                                        <div className='email-body'>
                                            <TextEditor
                                                editorState={this.state.email}
                                                onEditorStateChange={(e) => this.onEditorChange(e)}
                                                readonly = {!(isUserAccess)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                                secondaryBtnDisabled={!this.state.isUserAccess}
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

AddEmailTemplate.propTypes = {
    isAddNewEmailTemplateOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any
}

export default AddEmailTemplate;