import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/purchasingSelector';
import { makeSelectCommonSearchData, makeSelectCountryList, makeSelectObjectStatusList } from '../../../../store/selector/selector'
import * as actions from '../../../../store/action/purchasingAction';
import PropTypes from 'prop-types';
import '../../settingMenu/settingMenu.css';
import '../../pages.css'
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../../layout/form/Table';
import TextField from '../../../layout/form/TextField';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import DeleteModal from '../../../layout/form/deleteModal';
import { Link } from 'react-router-dom';
// import PdfViewer from '../../../layout/form/PdfViewer';
import Pagination from '../../../layout/form/Pagination';
import moment from 'moment';
import SelectField from '../../../layout/form/SelectField';
import { loadObjectStatusRequest, loadObjectStatusSuccess, saveCommonSearchSuccess } from '../../../../store/action/action';
import { loadTenantFinanceRequest } from '../../../../store/action/tenantAction';
import common, { numberWithCommas } from '../../../common';
import { makeSelectTenantFinance } from '../../../../store/selector/tenantSelector';
// import { makeSelectJobCardList } from '../../../../store/selector/jobCardSelector';
// import { makeSelectVehicleList } from '../../../../store/selector/vehicleSelector';
import { makeSelectSelectedSupplierSuccess, makeSelectSupplierList } from '../../../../store/selector/supplierSelector';
// import { loadJobCardListRequest } from '../../../../store/action/jobCardAction';
import { loadSelectedSupplierSuccess, loadSupplierListRequest } from '../../../../store/action/supplierAction';
// import { loadVehicleListRequest } from '../../../../store/action/vehicleAction';
import AddNewPurchasing from './AddNewPurchasing';
import { makeSelectPartList } from '../../../../store/selector/partSelector';
// import { loadPartListRequest } from '../../../../store/action/partAction';
import PdfViewer from '../../../layout/form/PdfViewer';
import SearchField from '../../../layout/form/SearchField';
import { loadProductListRequest, loadProductListSuccess } from '../../../../store/action/productAction';
import { makeSelectProductList } from '../../../../store/selector/productSelector';
import { makeSelectUserAccessSuccess } from '../../../../store/selector/usersSelector';
import { loadDashboardSuccess, loadPurchaseOrderSummarySuccess, loadRatingSummarySuccess, loadSalesSummarySuccess } from '../../../../store/action/dashboardAction';
import { loadStockDocumentPrintSuccess, saveIsStockDocumentOpenStatus, saveStockDocumentDetails, sendStockDocumentPrintRequest } from '../../../../store/action/stockDocumentAction';
import { makeSelectStockDocumentPopupOpen } from '../../../../store/selector/stockDocumentSelector';
import AddGoodsReceipts from '../../stock/goodSReceipts/AddGoodsReceipts';



const dateFormat = sessionStorage.getItem("dateFormat")

class Purchasing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            pageSize: 20,
            status: '',
            isFilterFormOpen: false,
            supplier: '',
            orderNo: '',
            jobCardId: '',
            vehicleId: '',
            isValidationError: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            errorMassage: '',
            isUpdate: false,
            isPrintModalOpen: false,
            isStockDocumentPrintModalOpen: false,
            isNewPurchasing: false,
            commonSearch: '',
            productList: [],
            isproductList: false
        }
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    setDateTimeFormat = (value_) => {
        const value = new Date(value_)
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        const time = moment(value).format(common.timeFormat);
        return date + " - " + time
    }

    setPickupTimeFormat = (value) => {
        const date = moment(value).format(common.dateFormat);
        const time = moment(value).format(common.timeFormat);
        return date + "T" + time
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            supplier: this.state.supplier,
            orderNo: this.state.orderNo,
            jobCardId: this.state.jobCardId,
            vehicleId: this.state.vehicleId,
            status: this.state.status,
            commonSearch: ''
        })
    }

    handleFilter = () => {
        this.getPurchasingListData()
        this.handleFilterForm()
    }

    handleChangeSearchValue = (e) => {
        if (e.target.value === "" || e.target.value === null) {
            this.handleFilterFormClose()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen: false,
            supplier: '',
            orderNo: '',
            jobCardId: '',
            vehicleId: '',
            status: '',
            pageSize: 20,
            pageNumber: 1,
        })
    }

    handleFilterFormClose = () => {
        this.setState({
            isFilterFormOpen: false,
            supplier: '',
            orderNo: '',
            jobCardId: '',
            vehicleId: '',
            status: '',
            pageSize: 20,
            pageNumber: 1,
            commonSearch: ''
        })

        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            supplier: '',
            orderNo: '',
            jobCardId: '',
            vehicleId: '',
            status: '',
            commonSearch: ''
        }
        this.props.dispatch(actions.loadPurchasingListRequest(parameters))
    }

    handleModal = (data) => {
        if (data && data.id) {
            this.props.dispatch(actions.loadSelectedPurchasingRequest(data.id))
            this.setState({ selectedRow: data, isUpdate: true })
        } else {
            this.setState({ isNewPurchasing: true })
        }
        this.props.dispatch(actions.saveIsPurchasingModalOpenStatus(true))
    }

    handleClose = () => {
        this.setState({ selectedRow: {}, isUpdate: false, isNewPurchasing: false })
        this.props.dispatch(actions.saveIsPurchasingModalOpenStatus(false));
        // this.props.dispatch(actions.loadInvoiceListLoading(false));
        this.props.dispatch(actions.loadSelectedPurchasingSuccess({}))
        this.props.dispatch(loadSelectedSupplierSuccess(''))
        // this.props.dispatch(loadSelectedCustomerSuccess(''))
        // this.props.dispatch(loadSelectedVehicleSuccess(''))
        // this.props.dispatch(loadSelectedJobCardSuccess(''))
        this.getPurchasingListData()
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handlePrintById = (data) => {
        this.setState({ isPrintModalOpen: true })
        this.props.dispatch(actions.loadPurchasingPrintByIdRequest(data.id))
    }

    printModalClose = () => {
        this.setState({ isPrintModalOpen: false })
        this.props.dispatch(actions.loadPurchasingPrintSuccess(''))
        this.handleClose()
    }

    handlePrint = (details) => {
        const { selectedPurchasing, tenantFinance } = this.props
        if (details.supplier === '' ||
            details.orderDate === '' ||
            details.requiredTime === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if(details.purchasingItems.length === 0 || details.total <= 0){
        //     this.setState({isValidationError:true, errorMassage:"Please add purchase order items"})
        //     return
        // }

        const tempItemList = details.tempPurchasingItemsList.filter(data => (data.operation === "D"))
        const purchasingItem = this.handleList(details.purchasingItems.concat(tempItemList))

        const parameters = {
            id: (selectedPurchasing && selectedPurchasing.id && this.state.isUpdate) ? selectedPurchasing.id : '',
            orderDate: details.orderDate === '' ? null : details.orderDate,
            requiredTime: details.requiredTime === '' ? null : this.setPickupTimeFormat(details.requiredTime),
            currency: tenantFinance.currencyCode,
            subTotal: details.subTotal === '' ? null : details.subTotal,
            discountType: details.discountType === '' ? null : details.discountType,
            discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
            discountValue: details.discountValue === '' ? null : details.discountValue,
            netTotal: details.subTotal - details.discountValue,
            tax: details.tax === '' ? null : details.tax,
            total: details.total === '' ? null : details.total,
            jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
            statusId: details.status === '' ? null : details.status,
            supplierId: details.supplier === '' ? 0 : details.supplier,
            referenceNo: details.referenceNo === '' ? null : details.referenceNo,
            notes: details.supplierNotes === '' ? null : details.supplierNotes,
            taxRate: details.selectedTaxRate,
            purchaseOrderItems: purchasingItem,
            taxIncPrice: details.isPriceInclusiveTax
        }
        // console.log(details)
        if (this.state.isUpdate) {
            this.props.dispatch(actions.sendPurchasingPrintRequest(parameters))
        } else {
            this.props.dispatch(actions.sendAddAndPrintPurchasingRequest(parameters))
        }
        this.setState({
            isPrintModalOpen: true
        })
    }

    handleSendEmail = (details) => {
        const { selectedPurchasing, tenantFinance } = this.props
        if (details.supplier === '' ||
            details.orderDate === '' ||
            details.requiredTime === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if(details.purchasingItems.length === 0 || details.total <= 0){
        //     this.setState({isValidationError:true, errorMassage:"Please add purchase order items"})
        //     return
        // }

        const tempItemList = details.tempPurchasingItemsList.filter(data => (data.operation === "D"))
        const purchasingItem = this.handleList(details.purchasingItems.concat(tempItemList))

        const parameters = {
            id: (selectedPurchasing && selectedPurchasing.id && this.state.isUpdate) ? selectedPurchasing.id : '',
            orderDate: details.orderDate === '' ? null : details.orderDate,
            requiredTime: details.requiredTime === '' ? null : this.setPickupTimeFormat(details.requiredTime),
            currency: tenantFinance.currencyCode,
            subTotal: details.subTotal === '' ? null : details.subTotal,
            discountType: details.discountType === '' ? null : details.discountType,
            discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
            discountValue: details.discountValue === '' ? null : details.discountValue,
            netTotal: details.subTotal - details.discountValue,
            tax: details.tax === '' ? null : details.tax,
            total: details.total === '' ? null : details.total,
            jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
            statusId: details.status === '' ? null : details.status,
            supplierId: details.supplier === '' ? 0 : details.supplier,
            referenceNo: details.referenceNo === '' ? null : details.referenceNo,
            notes: details.supplierNotes === '' ? null : details.supplierNotes,
            taxRate: details.selectedTaxRate,
            purchaseOrderItems: purchasingItem,
            taxIncPrice: details.isPriceInclusiveTax
        }
        // console.log(details)
        if (this.state.isUpdate) {
            this.props.dispatch(actions.sendPurchasingSendRequest(parameters))
        } else {
            this.props.dispatch(actions.sendAddPurchasingAndSendRequest(parameters))
        }
    }

    handleSendEmailById = (data) => {
        const supplier = this.props.supplierList.result.find(e => (e.id === (data.supplier && data.supplier.id)))
        if (data && supplier && supplier.email) {
            this.props.dispatch(actions.loadPurchasingSendByIdRequest(data.id))
        } else {
            this.setState({ isValidationError: true, errorMassage: common.massages.supplierEmailMassage })
        }
    }

    handleStockDocument = (data) => {
        const { jobCardList } = this.props;
        const stockDocument = common.stockDocument
        stockDocument.object = common.objectId.purchasing
        stockDocument.jobCardId = data.jobcardId
        stockDocument.supplierId = data.supplier.id
        stockDocument.purchaseOrderId = data.id
        if (data.jobcardId > 0) {
            const jobcard = jobCardList.result.find(e => (e.id === data.jobcardId))
            stockDocument.vehicleId = jobcard && jobcard.vehicleId
            stockDocument.vehicleRegNo = jobcard && jobcard.vehicleRegNo
        }
        this.props.dispatch(saveStockDocumentDetails(stockDocument))
        this.props.dispatch(saveIsStockDocumentOpenStatus(true))
        this.props.dispatch(actions.loadSelectedPurchasingRequest(data.id))
    }

    handleStockDocumentClose = () => {
        this.props.dispatch(saveIsStockDocumentOpenStatus(false))
        this.props.dispatch(saveStockDocumentDetails(common.stockDocument))
        this.props.dispatch(actions.loadSelectedPurchasingSuccess({}))
    }

    handleStockDocumentSave = (details) => {
        if (details.documentType === '' || details.supplier === '' || details.supplier === 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if (details.documentItem.length <= 0) {
        //     this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
        //     return
        // }

        const parameters = common.parameters.stockDocument
        const userId = sessionStorage.getItem("tenantUserId")
        parameters.DocumentItems = details.documentItem
        parameters.DocumentDate = details.date
        parameters.DocumentType = details.documentType
        parameters.HeaderText = details.documentText
        parameters.UserId = parseInt(userId)
        parameters.ReferenceNo = details.purchaseOrder
        parameters.ReferenceObject = common.objectId.purchasing
        parameters.SupplierId = details.supplier
        this.props.dispatch(actions.sendStockDocumentRequest(parameters))
    }

    stockDocumentPrintAndSave = (details) => {
        if (details.documentType === '' || details.supplier === '' || details.supplier === 0) {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if (details.documentItem.length <= 0) {
        //     this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
        //     return
        // }

        const parameters = common.parameters.stockDocument
        const userId = sessionStorage.getItem("tenantUserId")
        parameters.DocumentItems = details.documentItem
        parameters.DocumentDate = details.date
        parameters.DocumentType = details.documentType
        parameters.HeaderText = details.documentText
        parameters.UserId = parseInt(userId)
        parameters.ReferenceNo = details.purchaseOrder
        parameters.ReferenceObject = common.objectId.purchasing
        parameters.SupplierId = details.supplier

        this.props.dispatch(sendStockDocumentPrintRequest(parameters))
        this.setState({
            isStockDocumentPrintModalOpen: true
        })
    }

    stockDocumentPrintModalClose = () => {
        this.setState({ isStockDocumentPrintModalOpen: false })
        this.props.dispatch(loadStockDocumentPrintSuccess(null))
        this.handleStockDocumentClose()
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadPurchasingListError(''))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendPurchasingSuccess(''))
        this.props.dispatch(loadSelectedSupplierSuccess(''))
        this.props.dispatch(loadSalesSummarySuccess([]))
        this.props.dispatch(loadDashboardSuccess(''))
        this.props.dispatch(loadRatingSummarySuccess([]))
        this.props.dispatch(loadPurchaseOrderSummarySuccess(''))
        this.props.dispatch(actions.saveIsPurchasingModalOpenStatus(false))
        this.handleClose()
        this.handleStockDocumentClose()
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeletePurchasing = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeletePurchasingRequest(this.state.selectedRow.id))
    }

    handleSaveAddNew = (details) => {
        const { selectedPurchasing, tenantFinance } = this.props
        if (details.supplier === '' ||
            details.orderDate === '' ||
            details.requiredTime === '' ||
            details.status === '') {
            this.setState({ isValidationError: true, errorMassage: common.massages.requiredFieldMassage })
            return
        }

        // if(details.purchasingItems.length === 0 || details.total <= 0){
        //     this.setState({isValidationError:true, errorMassage:"Please add purchase order items"})
        //     return
        // }

        const tempItemList = details.tempPurchasingItemsList.filter(data => (data.operation === "D"))
        const purchasingItem = this.handleList(details.purchasingItems.concat(tempItemList))

        const parameters = {
            id: (selectedPurchasing && selectedPurchasing.id && this.state.isUpdate) ? selectedPurchasing.id : '',
            orderDate: details.orderDate === '' ? null : details.orderDate,
            requiredTime: details.requiredTime === '' ? null : this.setPickupTimeFormat(details.requiredTime),
            currency: tenantFinance.currencyCode,
            subTotal: details.subTotal === '' ? null : details.subTotal,
            discountType: details.discountType === '' ? null : details.discountType,
            discountTypeValue: details.discountTypeValue === '' ? null : details.discountTypeValue,
            discountValue: details.discountValue === '' ? null : details.discountValue,
            netTotal: details.subTotal - details.discountValue,
            tax: details.tax === '' ? null : details.tax,
            total: details.total === '' ? null : details.total,
            jobcardId: details.jobCardNo === '' ? 0 : details.jobCardNo,
            statusId: details.status === '' ? null : details.status,
            supplierId: details.supplier === '' ? 0 : details.supplier,
            referenceNo: details.referenceNo === '' ? null : details.referenceNo,
            notes: details.supplierNotes === '' ? null : details.supplierNotes,
            taxRate: details.selectedTaxRate,
            purchaseOrderItems: purchasingItem,
            taxIncPrice: details.isPriceInclusiveTax
        }
        // console.log(details)
        if (this.state.isUpdate) {
            this.props.dispatch(actions.sendUpdatePurchasingRequest(parameters))
        } else {
            this.props.dispatch(actions.sendAddNewPurchasingRequest(parameters))
        }
    }

    handleList = (data) => {
        if (data.length === 0) {
            return (data)
        }

        for (let i = 0; i < data.length; i++) {
            var element = data[i];
            element.lineNumber = i + 1
            data[i] = element

            if (i === data.length - 1) {
                return (data)
            }
        }
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    getPurchasingListData = () => {
        var supplierId = null
        if (this.props.commonSearchData && this.props.commonSearchData.component && this.props.commonSearchData.component === 'supplier') {
            supplierId = this.props.commonSearchData.id
            this.setState({
                supplier: supplierId
            })
        }

        this.props.dispatch(saveCommonSearchSuccess({}))
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            supplier: supplierId === null ? this.state.supplier : supplierId,
            orderNo: this.state.orderNo,
            jobCardId: this.state.jobCardId,
            vehicleId: this.state.vehicleId,
            status: this.state.status,
            commonSearch: this.state.commonSearch
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.props.dispatch(actions.loadPurchasingListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            supplier: this.state.supplier,
            orderNo: this.state.orderNo,
            jobCardId: this.state.jobCardId,
            vehicleId: this.state.vehicleId,
            status: this.state.status,
            commonSearch: value
        }
        if (this.state.isFilterFormOpen) {
            this.setState({
                pageNumber: 1,
                pageSize: 20
            })
        }
        this.setState({
            commonSearch: value
        })
        this.props.dispatch(actions.loadPurchasingListRequest(parameters))
    }

    componentDidMount() {
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadObjectStatusSuccess({}))
        this.props.dispatch(loadProductListSuccess([]))
        const parameters = {
            pageSize: 9999,
            pageNumber: '',
            Customer: '',
            VehicleRegNo: '',
            JobNo: '',
            Status: '',
            description: '',
            name: '',
            email: '',
            mobile: '',
            city: '',
            makeId: '',
            Make: '',
            Model: '',
            Search: '',
            status: '',
            codeId: '',
            categoryId: '',
            partId: '',
            commonSearch: '',
            sku: '',
            purchasingFlg: true
        }
        this.getPurchasingListData()
        this.props.dispatch(actions.saveIsPurchasingModalOpenStatus(false))
        this.props.dispatch(loadSupplierListRequest(parameters))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.purchasing));
        this.props.dispatch(loadTenantFinanceRequest(id))
        this.props.dispatch(loadProductListRequest(parameters))
        this.props.dispatch(loadStockDocumentPrintSuccess(null))
        this.handleStockDocumentClose()
        // this.props.dispatch(loadPartListRequest(parameters))
        // this.props.dispatch(loadTitleListRequest(parameters))
    }

    componentDidUpdate(prevProps, prevState) {
        const { productList } = this.props;
        if (this.props.purchasingSuccess !== '') {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }

        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getPurchasingListData()
        }

        if (!this.props.isAddNewPurchasingModalOpen &&
            (this.props.selectedSupplier !== '')) {
            this.props.dispatch(actions.saveIsPurchasingModalOpenStatus(true));
        }

        if (productList && productList.result && productList.result.length && productList.result.length > 0 && !this.state.isproductList) {
            this.setState({
                productList: productList.result,
                isproductList: true
            })
        }
    }

    render() {

        const {
            purchasingList,
            isAddNewPurchasingModalOpen,
            jobCardList,
            vehicleList,
            objectStatusList,
            supplierList,
            isPurchasingListLoading,
            purchasingListError,
            purchasingSuccess,
            tenantFinance,
            selectedPurchasing,
            purchasingPrint,
            userAccess,
            isStockDocumentOpen
        } = this.props;

        const {
            isFilterFormOpen,
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            isNewPurchasing,
            isPrintModalOpen,
            isStockDocumentPrintModalOpen,
            productList
        } = this.state;


        const columns = [{
            Header: 'Order No',
            accessor: 'orderNumber',
            width: "10%",
        }, {
            Header: 'Date',
            accessor: 'orderDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.orderDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.orderDate)}
                </div>
            )),
            width: "10%",
        }, {
            Header: 'Supplier',
            accessor: 'supplier',
            Cell: ((row) => (
                <div >
                    {row.row.original.supplier && row.row.original.supplier.name}
                </div>
            )),
        }, {
            Header: 'Phone No',
            accessor: 'supplierPhone',
            Cell: ((row) => (
                <div >
                    {row.row.original.supplier && row.row.original.supplier.phone}
                </div>
            )),
            width: "10%"
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Amount</div>),
            // Header: 'Amount',
            accessor: 'total',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {numberWithCommas(parseFloat(row.row.original.total).toFixed(2))}
                </div>
            ))
        }, {
            Header: 'Requested time',
            // accessor: 'statusId',
            Cell: ((row) => (
                <div >
                    {row.row.original.requiredTime === '0001-01-01T00:00:00' ? '' : this.setDateTimeFormat(row.row.original.requiredTime)}
                </div>
            )),
            width: "15%",
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {/* <Button */}
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleModal(row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        {/* <span onClick={()=>this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="table-icon"></ui5-icon></span>   */}
                        <span className="dropdown" style={{ float: 'right' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                {userAccess && userAccess.deletePurchaseOrder ? <li onClick={() => this.handleDeleteConfirm(row.row.original)}>Delete</li> : ''}
                                <li onClick={() => this.handlePrintById(row.row.original)}>Print</li>
                                <li onClick={() => this.handleSendEmailById(row.row.original)}>Email</li>
                                {userAccess && userAccess.createStockDocument ? <li onClick={() => this.handleStockDocument(row.row.original)}>Goods Receipts</li> : ''}
                            </div>
                        </span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {(isPurchasingListLoading ||
                    (purchasingList.result && purchasingList.result.length && purchasingList.result.length === 0)) ?
                    <Loader show={isPurchasingListLoading} /> : ''}

                {(isValidationError || purchasingListError !== '') &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage ? errorMassage : purchasingListError}
                        handleClose={this.errorModalClose}
                    />}

                {(purchasingSuccess) &&
                    <SuccessModal
                        show={true}
                        massage={purchasingSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeletePurchasing}
                        title={selectedRow.orderNumber}
                    />}
                {isStockDocumentOpen ?
                    <div>
                        <AddGoodsReceipts
                            handleClose={this.handleStockDocumentClose}
                            handleSubmit={this.handleStockDocumentSave}
                            printModalClose={this.stockDocumentPrintModalClose}
                            isPrintModalOpen={isStockDocumentPrintModalOpen}
                            handlePrint={this.stockDocumentPrintAndSave}
                        />
                    </div> :
                    <div>
                        {!isAddNewPurchasingModalOpen &&
                            <div className="content-header">
                                <div className="header-pages">
                                    <span><Link to='./supplier'><ui5-icon class="samples-margin" name="supplier" id="tab-icon"></ui5-icon> Supplier</Link></span>
                                    <span><Link to='./purchasing'><ui5-icon class="samples-margin" name="customer-order-entry" id="tab-icon"></ui5-icon> Purchase orders</Link></span>
                                </div>
                                <div className="header-btn">
                                    <ButtonGroup
                                        primaryBtnClick={this.handleModal}
                                        secondaryBtnClick={this.handleFilterForm}
                                        primaryBtnVariant="outline-primary"
                                        primaryBtnText='Add New'
                                        primaryBtnIcon='sys-add'
                                        primaryBtnDisabled={userAccess && !userAccess.createPurchaseOrder}
                                        secondaryBtnText='Filter'
                                        secondaryBtnVariant="outline-primary"
                                        secondaryBtnIcon='filter'
                                    />
                                </div>
                            </div>}
                        <div className="body-content">
                            {isAddNewPurchasingModalOpen ?
                                <AddNewPurchasing
                                    isAddNewPurchasingModalOpen={isAddNewPurchasingModalOpen}
                                    handleClose={this.handleClose}
                                    partList={productList}
                                    handleSaveAddNew={this.handleSaveAddNew}
                                    selectedRow={selectedRow}
                                    supplierList={supplierList.result}
                                    // vehicleList={vehicleList.result}
                                    objectStatusList={objectStatusList}
                                    // countryList={countryList}
                                    selectedPurchasing={selectedPurchasing}
                                    isUpdate={this.state.isUpdate}
                                    handlePrint={this.handlePrint}
                                    isPrintModalOpen={isPrintModalOpen}
                                    printModalClose={this.printModalClose}
                                    handleSendEmail={this.handleSendEmail}
                                    purchasingPrint={purchasingPrint}
                                    isPurchasingListLoading={isPurchasingListLoading}
                                    // paymentTermList={paymentTermList}
                                    tenantFinance={tenantFinance}
                                    // selectedCustomer={selectedCustomer}
                                    isNewPurchasing={isNewPurchasing}
                                    userAccess={userAccess}
                                // selectedVehicle={selectedVehicle}
                                /> :
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <h5 style={{ marginLeft: '5px' }}>Purchase Order List</h5>
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            <SearchField
                                                onClickSearch={this.getPurchasingListData}
                                                controlId="commonSearch"
                                                onChange={this.handleChangeSearchValue}
                                                defaultValue={this.state.commonSearch}
                                                onPressKeySearch={this.onPressKeySearch}
                                            />
                                        </div>
                                    </div>
                                    {isFilterFormOpen &&
                                        <div className="filter-form">
                                            <div className="filter-header">
                                                <h5>Filter Form</h5>
                                                <div className="btn-close" onClick={this.handleFilterFormClose} >
                                                    <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm">
                                                    <TextField
                                                        text="Order no"
                                                        controlId="orderNo"
                                                        placeholder="Order no"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.orderNo}
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    <SelectField
                                                        text="Supplier"
                                                        controlId="supplier"
                                                        onChange={(e) => this.handleChangeSelect(e, 'supplier')}
                                                        options={
                                                            supplierList.result && supplierList.result.length && supplierList.result.length > 0 ?
                                                                supplierList.result.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ?
                                                                    1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)) : []}
                                                        selectedValue={this.state.supplier}
                                                        display="name"
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    <SelectField
                                                        text="Vehicle registration no"
                                                        controlId="vehicleId"
                                                        onChange={(e) => this.handleChangeSelect(e, 'vehicleId')}
                                                        options={
                                                            vehicleList.result && vehicleList.result.length && vehicleList.result.length > 0 ?
                                                                vehicleList.result.sort((a, b) => (a.vehicleRegistrationNumber.toLowerCase() > b.vehicleRegistrationNumber.toLowerCase()) ?
                                                                    1 : ((b.vehicleRegistrationNumber.toLowerCase() > a.vehicleRegistrationNumber.toLowerCase()) ? -1 : 0)) : []}
                                                        selectedValue={this.state.vehicleId}
                                                        display="vehicleRegistrationNumber"
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    <SelectField
                                                        text="Job number"
                                                        controlId="jobCardId"
                                                        onChange={(e) => this.handleChangeSelect(e, 'jobCardId')}
                                                        options={jobCardList.result}
                                                        selectedValue={this.state.jobCardId}
                                                        display="jobCardId"
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    <SelectField
                                                        text="Status"
                                                        controlId="status"
                                                        onChange={(e) => this.handleChangeSelect(e, 'status')}
                                                        options={objectStatusList}
                                                        selectedValue={this.state.status}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                            <div className="filter-btns">
                                                <ButtonGroup
                                                    primaryBtnClick={this.handleFilter}
                                                    primaryBtnIcon='search'
                                                    primaryBtnText='Search'
                                                    primaryBtnVariant="outline-primary"
                                                    secondaryBtnClick={this.handleFilterFormClose}
                                                    secondaryBtnIcon='clear-filter'
                                                    secondaryBtnText='Clear'
                                                    secondaryBtnVariant="outline-primary"
                                                />
                                            </div>
                                        </div>}
                                    <div className="table-content">
                                        <Table
                                            columns={columns}
                                            data={purchasingList.result && purchasingList.result.length ? purchasingList.result : []}
                                        />
                                    </div>
                                    <Pagination
                                        currentPage={purchasingList.page ? purchasingList.page : this.state.pageNumber}
                                        getNextPage={this.getNextPage}
                                        getPrevPage={this.getPrevPage}
                                        totalPage={purchasingList.totalPages}
                                        setClickedPage={this.setClickedPage}
                                        currentPageSize={this.state.pageSize}
                                        setPageSize={this.setPageSize}
                                    />
                                    {
                                        isPrintModalOpen && purchasingPrint !== '' &&
                                        <PdfViewer
                                            show={isPrintModalOpen}
                                            pdf={purchasingPrint}
                                            onHide={this.printModalClose}
                                            title="Invoice"
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>}
            </div>
        )
    }
}

Purchasing.propTypes = {
    isAddNewPurchasingModalOpen: PropTypes.bool,
    purchasingList: PropTypes.any,
    supplierList: PropTypes.any,
    isPurchasingListLoading: PropTypes.bool,
    purchasingListError: PropTypes.any,
    purchasingSuccess: PropTypes.any,
    objectStatusList: PropTypes.any,
    countryList: PropTypes.any,
    selectedPurchasing: PropTypes.any,
    jobCardList: PropTypes.any,
    vehicleList: PropTypes.any,
    paymentTermList: PropTypes.any,
    tenantFinance: PropTypes.any,
    selectedCustomer: PropTypes.any,
    selectedVehicle: PropTypes.any,
    selectedJobCard: PropTypes.any,
    partList: PropTypes.any,
    purchasingPrint: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    isAddNewPurchasingModalOpen: selectors.makeSelectPurchasingPopupOpen(),
    purchasingList: selectors.makeSelectPurchasingList(),
    isPurchasingListLoading: selectors.makeSelectPurchasingListLoading(),
    purchasingListError: selectors.makeSelectPurchasingListError(),
    purchasingSuccess: selectors.makeSelectPurchasingSuccess(),
    objectStatusList: makeSelectObjectStatusList(),
    countryList: makeSelectCountryList(),
    supplierList: makeSelectSupplierList(),
    selectedPurchasing: selectors.makeSelectSelectedPurchasingSuccess(),
    tenantFinance: makeSelectTenantFinance(),
    partList: makeSelectPartList(),
    purchasingPrint: selectors.makeSelectPurchasingPrintSuccess(),
    selectedSupplier: makeSelectSelectedSupplierSuccess(),
    commonSearchData: makeSelectCommonSearchData(),
    productList: makeSelectProductList(),
    userAccess: makeSelectUserAccessSuccess(),
    isStockDocumentOpen: makeSelectStockDocumentPopupOpen()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Purchasing));