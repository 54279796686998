import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';

export class AddItem extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            itemId: '',
            description: '',
            unitOfMeasure: '',
            categoryId: '',
            quantityInStock: parseFloat(0).toFixed(2),
            unitPrice: parseFloat(0).toFixed(2),
            status:'',
            // salesPrice:'',
            initial:true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        
        const {isUpdate, selectedRow, objectStatusList } = prevProps;
        if(selectedRow && selectedRow.itemId && prevState.initial){
            return {
                itemId: selectedRow.itemId,
                description: selectedRow.description,
                unitOfMeasure: selectedRow.unitOfMeasure,
                categoryId: selectedRow.categoryId,
                quantityInStock: parseFloat(selectedRow.quantityInStock).toFixed(2),
                unitPrice: parseFloat(selectedRow.unitPrice).toFixed(2),
                status:selectedRow.status,
                // salesPrice:selectedRow.salesPrice,
                initial:false
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && !isUpdate && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            return {
                status:defaultStatus[0].id,
                initial:false,
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnBlur = (e) => {
        this.setState({
            [e.target.id]: parseFloat(e.target.value).toFixed(2)
        })
    }

    handleChangeStatus = (value) =>{
        this.setState({status:value})
      }

    handleChangeCategory = (value) =>{
        this.setState({categoryId:value})
      }
    
    handleChangeUOM = (value) =>{
        this.setState({unitOfMeasure:value})
      }
  
    render() {
        const {
            isAddNewItemModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            itemCategoryList,
            objectStatusList,
            uomList,
            selectedRow
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewItemModalOpen} 
                    onHide={handleModalClose}
                    title="Item Information"
                    width='60%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"25px"}}>
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField
                                            text="Item Id"
                                            controlId="itemId"
                                            placeholder="Item Id"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.itemId}
                                            disabled={selectedRow && selectedRow.itemId ? true: false}
                                            maxLength={common.maxLengths.itemId}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField                                            
                                            text="Category"
                                            controlId="category"
                                            onChange={this.handleChangeCategory}
                                            options={itemCategoryList}
                                            selectedValue={this.state.categoryId}
                                            required
                                        />
                                    </div> 
                                    <div className="col-sm">
                                        <SelectField                                            
                                            text="Type"
                                            controlId="type"
                                            // onChange={this.handleChangeCategory}
                                            // options={itemCategoryList}
                                            selectedValue={this.state.typeId}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <SelectField
                                            text="Unit of Measure"
                                            controlId="unitOfMeasure"
                                            onChange={this.handleChangeUOM}
                                            options={uomList}
                                            selectedValue={this.state.unitOfMeasure}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <TextField
                                            text="Unit Price"
                                            controlId="unitPrice"
                                            placeholder="Unit Price"
                                            type="number"
                                            onChange={this.handleChange}
                                            // value={this.state.unitPrice === '' ? '' : parseFloat(this.state.unitPrice).toFixed(2)}
                                            value={this.state.unitPrice}
                                            onBlur={this.handleOnBlur}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.itemUnitPrice}
                                            required
                                        />
                                    </div>
                                    {/* <div className="col-sm">
                                        <TextField
                                            text="Sales Price"
                                            controlId="salesPrice"
                                            placeholder="Sales Price"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={this.state.salesPrice === '' ? '' : parseFloat(this.state.salesPrice).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            required
                                        />
                                     </div> */}
                                    <div className="col-sm">
                                        <TextField
                                            text="Quntity"
                                            controlId="quantityInStock"
                                            placeholder="Quantity"
                                            type="number"
                                            onChange={this.handleChange}
                                            onBlur={this.handleOnBlur}
                                            value={this.state.quantityInStock}
                                            // value={this.state.quantityInStock === '' ? '' : parseFloat(this.state.quantityInStock).toFixed(2)}
                                            style={{textAlign:'right'}}
                                            maxLength={common.maxLengths.itemQuantity}
                                            required
                                        />
                                     </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8"> 
                                        <TextField
                                            text="Description"
                                            controlId="description"
                                            placeholder="Description"
                                            type="text"
                                            as="textarea" 
                                            rows={3}
                                            onChange={this.handleChange}
                                            defaultValue={this.state.description}
                                            maxLength={common.maxLengths.itemDescription}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <SelectField
                                            text="Status"
                                            controlId="status"
                                            onChange={this.handleChangeStatus}
                                            options={objectStatusList}
                                            selectedValue={this.state.status}
                                            required
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

AddItem.propTypes = {
    isAddNewItemModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    saveDetails: PropTypes.any,
    itemCategoryList: PropTypes.any,
    objectStatusList: PropTypes.any,
    uomList: PropTypes.any,
    selectedRow: PropTypes.any
}

export default AddItem;