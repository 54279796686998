import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/accountProductCategorySelector';
import { Link } from 'react-router-dom';
import Table from '../../../layout/form/Table';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import { loadAccountCustomerRequest, loadAccountProductCategoryError, loadAccountProductCategoryRequest, loadMappingAccountRequest, loadMappingCustomerRequest, saveIsAccountCustomerStatus, sendMappingAccountRequest, sendMappingCustomerRequest, sendMappingProductCategorySuccess } from '../../../../store/action/accountProductCategoryAction';
import { makeSelectTenantAccountSystemSuccess } from '../../../../store/selector/tenantSelector';
import { makeSelectCustomerList } from '../../../../store/selector/customerSelector';
import SelectField from '../../../layout/form/SelectField';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import Loader from '../../../layout/form/Loader';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Pagination from '../../../layout/form/Pagination';

const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 32,
      marginBottom: 0,
      marginTop: -20
    })
  };
const tenantId = sessionStorage.getItem("tenantId")

class QuickBooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
          contactList: [],
          accountList: [],
          initial:true,
          pageNumber:1,
          pageSize:20
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { 
          tenantAccountSystem, 
          isAccountCustomerList,
          mappingCustomerList,
          isAccountProductCategoryList,
          mappingAccountList,
        } = prevProps;
        if(
          prevState.initial &&
          tenantAccountSystem && tenantAccountSystem.id === 0  && isAccountProductCategoryList  && isAccountCustomerList)
        {
          var contactList = []
          var newMappingContactList = []
          var newMappingAccountList = []

          if(mappingAccountList && mappingAccountList.length && mappingAccountList.length > 0){
            for (let q = 0; q < mappingAccountList.length; q++){
              const account_ = mappingAccountList[q]
              const item = {
                tenantId : account_.tenantId,
                intlAccountId : account_.intlAccountId,
                accSystemId : tenantAccountSystem.systemId,
                intlName : account_.intlName,
                isChange : false,
                extlAccountId : account_.extlAccountId,
                code : account_.code
              }
              newMappingAccountList.push(item)
            }
          }
          if(mappingCustomerList && mappingCustomerList.result && mappingCustomerList.result.length && mappingCustomerList.result.length > 0){
            for (let q = 0; q < mappingCustomerList.result.length; q++){
              const customerItem_ = mappingCustomerList.result[q]
              const item = {
                tenantId : customerItem_.tenantId,
                intlCustomerId : customerItem_.intlCustomerId,
                accSystemId : customerItem_.accSystemId,
                displayName : customerItem_.displayName,
                isChange : false,
                extlCustomerId : customerItem_.extlCustomerId,
                extlCustomerName : customerItem_.ExtlCustomerName
              }
              newMappingContactList.push(item)
            }
          }
          return{
            initial:false,
            contactList: newMappingContactList.concat(contactList),
            accountList: newMappingAccountList
          }
        }
        return null
    }

    handleSaveCustomer = () => {
        const { contactList } = this.state;
        const Customers = contactList.filter(e => e.isChange === true)
        const parms = {
          tenantId: parseInt(tenantId),
          Customers : Customers,
          AccSystemId: this.props.tenantAccountSystem.systemId
        }
        if(Customers && Customers.length > 0){
          this.props.dispatch(sendMappingCustomerRequest(parms))
        }
    }

    handleSaveAccounts = () => {
      const { accountList } = this.state;
      const accounts = accountList.filter(e => e.isChange === true)
      const parms = {
        tenantId: parseInt(tenantId),
        Accounts : accounts,
        AccSystemId: this.props.tenantAccountSystem.systemId
      }
      if(accounts && accounts.length > 0){
        this.props.dispatch(sendMappingAccountRequest(parms))
      }
    }

    handleAccounts = (e,rowData) => {
      const { accountList } = this.state;
      // const { accountProductCategoryList } = this.props;
      // const selectedRow = accountProductCategoryList.find(row => row.id === e)
      var newRow = {
        tenantId : rowData.original.tenantId,
        accSystemId : rowData.original.accSystemId,
        isChange : true,
        intlAccountId : rowData.original.intlAccountId,
        intlName : rowData.original.intlName,
        // code : e,
        extlAccountId : e,
      }
      accountList[rowData.index] = newRow
      this.setState({
        accountList
      })
    }

    handleCustomer = (e,rowData) => {
        const { contactList } = this.state;
      const { accountCustomerList } = this.props;
      const selectedRow = accountCustomerList.find(row => row.id === e)
        var newRow = {
          tenantId : rowData.original.tenantId,
          accSystemId : rowData.original.accSystemId,
          isChange : true,
          intlCustomerId : rowData.original.intlCustomerId,
          displayName : rowData.original.displayName,
          extlCustomerName : selectedRow.name,
          extlCustomerId : e
        }
        contactList[rowData.index] = newRow
        this.setState({
          contactList
        })
    }

    handleClose = () => {
        this.setState({
          initial:true
        })
        this.props.dispatch(saveIsAccountCustomerStatus(false))
        this.getProductCategoryList()
    }
  
    errorModalClose = () => {
        this.props.dispatch(loadAccountProductCategoryError(''))
    }
  
    successModalClose = () => {
        this.props.dispatch(sendMappingProductCategorySuccess(''))
        this.props.dispatch(saveIsAccountCustomerStatus(false))
        this.getProductCategoryList()
    }
  
    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }
  
    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }
  
    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }
  
    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }
  
    componentDidUpdate (prevProps, prevState){
        if (this.props.mappingProductCategory !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
  
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
          this.setState({
            initial:true
          })
          this.handleSaveCustomer()
          this.getMappingCustomerListData()
          this.props.dispatch(saveIsAccountCustomerStatus(false))
      }
    }

    componentDidMount (){
        this.getProductCategoryList()
      }
  
    getProductCategoryList = () => {
      this.props.dispatch(loadMappingAccountRequest())
      this.props.dispatch(loadAccountProductCategoryRequest())
      this.props.dispatch(loadAccountCustomerRequest())
      this.getMappingCustomerListData()
      }
  
    getMappingCustomerListData = () => {
        const mappingCustomerParms = {
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
        }
        this.props.dispatch(loadMappingCustomerRequest(mappingCustomerParms))
      }

    render() {
        const { 
            isAccountProductCategoryLoading,
            accountProductCategoryError,
            mappingProductCategory,
            accountCustomerList,
            mappingCustomerList,
            accountProductCategoryList,
          } = this.props;

          const { 
            contactList,
            accountList
          } = this.state;

        const columnsCustomer = [{
            Header: 'Customer ID',
            accessor: 'intlCustomerId',
            width:'15%',
          },{
            Header: 'Customer Name',
            accessor: 'displayName',
          },{
            Header: 'Acc.System Customer Name',
            accessor: 'extlCustomerId',
            Cell:(rowData) =>(
              <div>       
                <SelectField
                      controlId="extlCustomerId"
                      onChange={(e)=>this.handleCustomer(e,rowData.cell.row)}
                      options={accountCustomerList}
                      style={customStyles}
                      selectedValue = {rowData.cell.row.original.extlCustomerId}
                      display="name"
                      id="id"
                  />
              </div>
            ), 
        }]

        const columnsAccount = [{
          Header: 'Account ID',
          accessor: 'intlAccountId',
          width:'15%',
        },{
          Header: 'Account Name',
          accessor: 'intlName',
        },{
          Header: 'Acc.System Item Description',
          accessor: 'accountSystemDescription',
          Cell:(rowData) =>(
            <div>       
              <SelectField
                    controlId="accountSystemId"
                    onChange={(e)=>this.handleAccounts(e,rowData.cell.row)}
                    options={accountProductCategoryList}
                    style={customStyles}
                    selectedValue = {rowData.cell.row.original.extlAccountId}
                    display="description"
                    id="id"
                />
            </div>
          ), 
        },{
          Header: 'Acc.System Item Code',
          accessor: 'extlAccountId',
          Cell:(rowData) =>(
            <div>{rowData.cell.row.original.extlAccountId ? rowData.cell.row.original.extlAccountId : ''}</div>
            ),
          width:'20%',
        }]

        return (
            <div>
               {accountProductCategoryError !== '' && 
                    <ErrorModal
                        show={true} 
                        massage={accountProductCategoryError}
                        handleClose={this.errorModalClose}
                    />}

                {(mappingProductCategory) && 
                    <SuccessModal
                        show={true} 
                        massage={mappingProductCategory}
                        handleClose={this.successModalClose}
                    />}
                {isAccountProductCategoryLoading ? 
                    <Loader/> :''}
                <div className="content-header">
                    <div className="header-pages">
                        <span>
                            <Link to='./accounting'>
                                <ui5-icon class="samples-margin" name="connected" id="tab-icon"></ui5-icon> Connection
                            </Link>
                        </span>
                        <span>
                            <Link to='./quickBooks'>
                                <ui5-icon class="samples-margin" name="sales-quote" id="tab-icon"></ui5-icon> Mapping
                            </Link>
                        </span>
                        <span>
                          <Link to='./invoiceReconciliationReport'>
                            <ui5-icon class="samples-margin" name="manager-insight" id="tab-icon"></ui5-icon> Invoice Reconciliation Report
                          </Link>
                        </span>
                    </div>
                </div>
                <div className="body-content">
                    <Tabs defaultActiveKey="account" id="masterDataMappingTab">
                      <Tab 
                          eventKey="account" 
                          title={<span> Account</span>}>
                          <div className="table-content">
                            <Table
                                columns={columnsAccount}
                                data={accountList}
                            />
                        </div>
                        <div className="filter-btns">
                          <ButtonGroup
                              primaryBtnClick={this.handleClose}
                              // primaryBtnIcon='decline'
                              primaryBtnText='Go Back'
                              primaryBtnIcon='undo'
                              primaryBtnVariant="outline-primary" 
                              secondaryBtnClick={this.handleSaveAccounts}
                              secondaryBtnIcon='save'
                              secondaryBtnText='Save'
                              secondaryBtnVariant="outline-primary" 
                          />
                      </div>
                      </Tab>
                      <Tab 
                          eventKey="customer" 
                          title={<span> Customer</span>}>
                          <div className="table-content">
                            <Table
                                columns={columnsCustomer}
                                data={contactList}
                                // data={accountProductCategoryList && accountProductCategoryList.length ? accountProductCategoryList : []}                   
                            />
                        </div>
                        <Pagination
                              currentPage={mappingCustomerList.page ? mappingCustomerList.page :this.state.pageNumber}
                              getNextPage={this.getNextPage}
                              getPrevPage={this.getPrevPage}
                              totalPage = {mappingCustomerList.totalPages}
                              setClickedPage= {this.setClickedPage}
                              currentPageSize={this.state.pageSize}
                              setPageSize={this.setPageSize}
                          />
                        <div className="filter-btns">
                          <ButtonGroup
                              primaryBtnClick={this.handleClose}
                              // primaryBtnIcon='decline'
                              primaryBtnText='Go Back'
                              primaryBtnIcon='undo'
                              primaryBtnVariant="outline-primary" 
                              secondaryBtnClick={this.handleSaveCustomer}
                              secondaryBtnIcon='save'
                              secondaryBtnText='Save'
                              secondaryBtnVariant="outline-primary" 
                          />
                      </div>
                      </Tab>
                  </Tabs> 
                </div>
            </div>
        )
    }

} 

QuickBooks.propTypes = {

}


const mapStateToProps = createStructuredSelector({
    isAccountProductCategoryLoading: selectors.makeSelectAccountProductCategoryLoading(),
    accountProductCategoryList: selectors.makeSelectAccountProductCategory(),
    // productCategoryList: makeSelectProductCategoryList(),
    accountProductCategoryError: selectors.makeSelectAccountProductCategoryError(),
    tenantAccountSystem: makeSelectTenantAccountSystemSuccess(),
    isAccountProductCategoryList: selectors.makeSelectIsLoadAccountProductCategory(),
    // mappingProductCategoryList : selectors.makeSelectMappingProductCategory(),
    mappingProductCategory: selectors.makeSelectMappingProductCategorySuccess(),
    customerList: makeSelectCustomerList(),
    isAccountCustomerList: selectors.makeSelectIsLoadAccountCustomer(),
    mappingCustomerList: selectors.makeSelectMappingCustomer(),
    accountCustomerList: selectors.makeSelectAccountCustomer(),
    mappingAccountList: selectors.makeSelectMappingAccountList(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(QuickBooks));
