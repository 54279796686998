import { fromJS } from 'immutable';
import {
  LOAD_CUSTOMER_SUMMARY_ERROR,
  LOAD_CUSTOMER_SUMMARY_LOADING,
  LOAD_CUSTOMER_SUMMARY_SUCCESS,
  LOAD_RATING_ERROR,
  LOAD_RATING_LOADING,
  LOAD_RATING_SUCCESS,
} from '../constant/analyticsConstant';

export const initialState = fromJS({
  isRatingLoading: false,
  ratingError: '',
  ratingDetails: '',
  customerAcquisitionSummary: '',
  isCustomerSummaryLoading: false,
  customerSummaryError: ''
});

function ratingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_RATING_SUCCESS:
      return state.set('ratingDetails', action.payload)
    case LOAD_RATING_LOADING:
      return state.set('isRatingLoading', action.payload)
    case LOAD_RATING_ERROR:
      return state.set('ratingError', action.payload)
    case LOAD_CUSTOMER_SUMMARY_SUCCESS:
      return state.set('customerAcquisitionSummary', action.payload)
    case LOAD_CUSTOMER_SUMMARY_LOADING:
      return state.set('isCustomerSummaryLoading', action.payload)
    case LOAD_CUSTOMER_SUMMARY_ERROR:
      return state.set('customerSummaryError', action.payload)
    default:
      return state;
  }
}

export default ratingReducer;
