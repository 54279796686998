import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import '../../pages.css';
import Button from '../../../layout/form/Button';
import SelectField from '../../../layout/form/SelectField';
import DatePicker from '../../../layout/form/datePicker';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import Table from '../../../layout/form/Table';
import AddReminder from './AddNewReminder';
// import TextField from '../../../layout/form/TextField';
import moment from 'moment';
import { withRouter} from 'react-router-dom';
import common from '../../../common';
import Pagination from '../../../layout/form/Pagination';
import * as actions from '../../../../store/action/reminderAction';
import * as selectors from '../../../../store/selector/reminderSelector';
import { makeSelectReportPeriodSuccess } from '../../../../store/selector/reportSelector';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { loadObjectStatusRequest, loadObjectStatusSuccess } from '../../../../store/action/action';
import { makeSelectObjectStatusList, makeSelectTenantUserExpireStatus } from '../../../../store/selector/selector';
import DeleteModal from '../../../layout/form/deleteModal';
import { makeSelectTenantUser } from '../../../../store/selector/selector';
// import { loadVehicleListSuccess } from '../../../../store/action/vehicleAction';
// import { makeSelectVehicleList } from '../../../../store/selector/vehicleSelector';
// import { makeSelectSelectedCustomerSuccess } from '../../../../store/selector/customerSelector';
// import { loadSelectedCustomerSuccess } from '../../../../store/action/customerAction';

const currentDate = new Date() 
// const prevDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),1)
// var nextDate = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)+1),1)
var nextDate= moment(currentDate).add(30, 'days');
// nextDate.setDate(nextDate.getDate() - 1)
nextDate = new Date(nextDate)
const dateFormat = sessionStorage.getItem("dateFormat")
class CalendarListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow:{},
            isUpdate:false,
            isValidationError:false,
            errorMassage:'',
            period:"",
            fromDate: moment(currentDate).format(common.dateFormat),
            toDate: moment(nextDate).format(common.dateFormat),
            initial:true,
            pageNumber:1,
            pageSize:20,
            isDeleteModalOpen:false
        }
    }

    handleModal = (data) => {
        if(data && data.id){
            this.setState({selectedRow:data, isUpdate: true})
        }
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(true))
    }

    handleModalClose = () => {
        this.setState({selectedRow:{}, isUpdate:false})
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(false));
    }

    handleDeleteConfirm = (data) => {
        // console.log(data.id)
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteBooking = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendReminderDeleteRequest(this.state.selectedRow.id))
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadReminderListError(''))
    }

    successModalClose = () => {
        this.getBookingList()
        this.props.dispatch(actions.sendReminderSuccess(''))
        this.handleModalClose()
        this.props.dispatch(actions.loadReminderListLoading(false))
    }

    handleDateChange = (date, id) => {
        if(date === null && id === 'toDate'){
            this.setState({
                [id]: moment(nextDate).format(common.dateFormat),
            })
            return
        }
        if(date === null && id === 'fromDate'){
            this.setState({
                [id]: moment(currentDate).format(common.dateFormat),
            })
            return
        }
        else{
            this.setState({
                [id]:moment(date).format(common.dateFormat),
            })
        }
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleChangeSelect  = (value, id) => {
        const {reportPeriodList} =this.props;
        if(id === "period" && value === ''){
            this.setState({
                fromDate: moment(currentDate).format(common.dateFormat),
                toDate: moment(nextDate).format(common.dateFormat),
                [id]: "CM"
            })
            return
        }
        if(id === 'period'){
            const period = reportPeriodList.find(element => element.id === value)
            this.setState({
                fromDate: period.startDate,
                toDate: period.endDate
            })
        }
        this.setState({
            [id]: value
        })
    }

    handleSaveAddNew = (details) => {
        const { selectedRow, isUpdate } = this.state;
        const { tenantUser } = this.props;
        if(details.date === ''|| details.description === '' ){
                this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
                return
        }
        // const isChangeDate = (moment(details.date).format(common.dateFormat) === moment(selectedRow.requestedDate).format(common.dateFormat))
        // const setDate =
        const date = moment(details.date).format(common.dateFormat);
        // const time_ = new Date(currentDate.getFullYear(currentDate),(currentDate.getMonth(currentDate)),(currentDate.getDate(currentDate)),8,30,0)
        const time = moment(details.date).format(common.timeFormat);
        const setDate = date+"T"+time
        const parameters = {
            id:(isUpdate ? selectedRow.id : 0),
            RequestedDate:setDate,
            Notes: details.notes,
            Status:(isUpdate ? selectedRow.status : "50"),
            UserId: tenantUser && tenantUser.id,
            description : details.description
        }
        if(this.state.isUpdate){
            this.props.dispatch(actions.sendReminderUpdateRequest(parameters))
        }else{
            this.props.dispatch(actions.sendAddNewReminderRequest(parameters))
        }
    }

    // handleConfirm = (details) => {
    //     const { selectedRow } = this.state;
    //     if(selectedRow.status === "10" || details.status === "10"){
    //         this.setState({isValidationError:true, errorMassage:'Booking Already Confirmed' })
    //         return
    //     }
    //     if(details.date === '' ||
    //         details.customer === '' ||
    //         details.vehicleRegistrationNo === ''){
    //             this.setState({isValidationError:true, errorMassage:common.massages.requiredFieldMassage})
    //             return
    //     }

    //     const parameters = {
    //         id:(selectedRow.id ? selectedRow.id : details.id) ,
    //         VehicleId:(selectedRow.vehicleId ? selectedRow.vehicleId :details.vehicleId),
    //         RequestedDate:moment(selectedRow.requestedDate ? selectedRow.requestedDate : details.requestedDate).format(common.dateTimeFormat),
    //         CustomerId: (selectedRow.customerId ? selectedRow.customerId : details.customerId),
    //         CustomerName: (selectedRow.customerName ? selectedRow.customerName : details.customerName),
    //         CustomerEmail: (selectedRow.customerEmail ? selectedRow.customerEmail : details.customerEmail),
    //         Notes: (selectedRow.notes ? selectedRow.notes : details.notes),
    //         CustomerPhone: (selectedRow.customerPhone ? selectedRow.customerPhone : details.customerPhone),
    //         ObjectId:"BOOKING",
    //         VehicleRegistrationNumber: (selectedRow.vehicleRegistrationNumber ? selectedRow.vehicleRegistrationNumber : details.vehicleRegistrationNumber),
    //         Status:"10",
    //         isEmail:1
    //     }
    //     this.props.dispatch(actions.sendReminderUpdateRequest(parameters))
    // }

    // handleServiceReminder = (details) => {
    //     if(this.props.tenantUserExpireStatus){
    //         this.props.dispatch(saveClickUnderExpiredStatus(true))
    //         this.handleModalClose()
    //         return
    //     }

    //     const parameters = {
    //         id:details.id ,
    //         VehicleId:details.vehicleId,
    //         RequestedDate:moment(details.requestedDate).format(common.dateFormat),
    //         CustomerId: details.customerId,
    //         CustomerName: details.customerName,
    //         CustomerEmail: details.customerEmail,
    //         Notes: details.notes,
    //         CustomerPhone: details.customerPhone,
    //         ObjectId:"BOOKING",
    //         VehicleRegistrationNumber:details.vehicleRegistrationNumber,
    //         Status: details.status,
    //         isEmail:1
    //     }
    //     this.props.dispatch(actions.sendReminderUpdateRequest(parameters))
    // }

    // handleCreateJobcard = (details) => {
    //     if(this.props.tenantUserExpireStatus){
    //         this.props.dispatch(saveClickUnderExpiredStatus(true))
    //         this.handleModalClose()
    //         return
    //     }

    //     if(!details.vehicleId ){
    //         this.setState({isValidationError:true, errorMassage:'Vehicle not registered' })
    //         return
    //     }

    //     if(details.jobcardId > 0 ){
    //         this.setState({isValidationError:true, errorMassage:'JobCard Already Created' })
    //         return
    //     }

    //     this.props.dispatch(actions.saveSelectedBookingSuccess(details))
    //     this.props.history.push('/jobCard')
    // }

    getBookingList = () => {
        const startDay = new Date(this.state.fromDate)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(this.state.toDate)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),   
            toDate: moment(endDateTime).format(common.dateTimeFormat),
        }
        this.props.dispatch(actions.loadReminderListRequest(parameters))
    }

    handleFilterFormClose  = () => {
        const startDay = new Date(currentDate)
        const startDateTime = moment(startDay).startOf('day').toDate();
        const endDay = new Date(nextDate)
        const endDateTime = moment(endDay).endOf('day').toDate();
        const parameters = {
            pageSize: 20,
            pageNumber: 1,
            fromDate: moment(startDateTime).format(common.dateTimeFormat),
            toDate: moment(endDateTime).format(common.dateTimeFormat),
        }
        this.setState({
            pageSize: 20,
            pageNumber: 1,
            fromDate: moment(currentDate).format(common.dateFormat),
            toDate: moment(nextDate).format(common.dateFormat),
            period:""
        })
        this.props.dispatch(actions.loadReminderListRequest(parameters))
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    // handleBookingReminder = (booking) => {
    //     if(!booking.customerEmail){
    //         this.setState({isValidationError:true, errorMassage:'Please enter email address' })
    //         this.handleModalClose()
    //     }
    //     else {
    //         this.props.dispatch(actions.sendBookingReminderRequest(booking.id))
    //     }
    // }

    componentDidMount = () =>{
        this.props.dispatch(actions.saveIsReminderModalOpenStatus(false))
        this.props.dispatch(loadObjectStatusSuccess([]))
        // this.props.dispatch(loadVehicleListSuccess([]))
        this.props.dispatch(actions.loadReminderListSuccess([]))
        this.getBookingList()
        this.props.dispatch(loadObjectStatusRequest(common.objectId.booking));
    }

    componentDidUpdate (prevProps, prevState){

        if (this.props.reminderSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getBookingList()
        }

        // if(this.props.isBookingUsingVehicle && !this.props.isAddNewReminderModalOpen){
        //     this.props.dispatch(actions.saveIsBookingModalOpenStatus(true))
        // }
    }


    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
          const desc = objectStatusList.find(select =>  select.id === data.status)
          return (desc && desc.description ? desc.description : '')
        }
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat? dateFormat : common.dateFormat);
        return date
    }
    
    render() {

        const {
            isAddNewReminderModalOpen,
            isReminderListLoading,
            reminderList,
            reminderListError,
            reminderSuccess,
            reportPeriodList,
            // objectStatusList,
            tenantUser,
            vehicleList,
            selectedCustomer,
            tenantUserExpireStatus
            // bookingVehicleDetails,
            // isBookingUsingVehicle
        } = this.props;

        const { 
            isValidationError,
            errorMassage,
            isDeleteModalOpen,
            selectedRow,
            isUpdate
        } = this.state;

        const columns = [{
            Header: 'Remainder No',
            accessor: 'id',
            width: "10%",
          },{
            Header: 'Remainder Date',
            width: "12%",
            Cell:((row)=>(
                <div >
                  {row.row.original.requestedDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.requestedDate)}
                </div>
              )),
          },{
            Header: 'Description',
            accessor: 'description',
        //   },{
        //     Header: 'No',
        //     accessor: 'vehicleRegistrationNumber',
        //   },{
        //     Header: 'Status',
        //     width: "10%",
        //     Cell:((row)=>(
        //         <div style={{padding:'2px'}}>
        //             {row.row.original.status === "50" ? 
        //                 <div style={{backgroundColor:'#ff6384',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'5px'}}>{this.setStatusDescription(row.row.original)}</div> : 
        //                 <div style={{backgroundColor:'#36a2eb',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'5px'}}>{this.setStatusDescription(row.row.original)}</div>
        //             }
        //         </div>
        //       )),
          }, {
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(row.row.original)} ><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span> 
                      {/* onClick={()=>this.handleModal(row.row.original)} */}
                        <span className="dropdown" style={{float:'right'}}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={()=>this.handleDeleteConfirm(row.row.original)}>Delete</li>
                                {/* <li onClick={()=>this.handleConfirm(row.row.original)}>Confirm</li>
                                {!tenantUserExpireStatus ? <li onClick={()=>this.handleServiceReminder(row.row.original)}>Send Reminders</li> : ''}
                                {!tenantUserExpireStatus ? <li onClick={()=>this.handleCreateJobcard(row.row.original)}>Create JobCard</li>: ''} */}
                            </div>
                        </span> 
                    </span>}
                </div>
            )),
            accessor:'action'
          }
        ]

        return (
            <div>
                {(isReminderListLoading || 
                    (reportPeriodList && reportPeriodList.length && reminderList && reminderList.result && reminderList.result.length && reminderList.result.length === 0)) ? 
                    <Loader show={isReminderListLoading}/> :''}

                {(isValidationError || reminderListError !== '' ) && 
                    <ErrorModal
                        show={true} 
                        massage={errorMassage ? errorMassage : reminderListError}
                        handleClose={this.errorModalClose}
                    />}

                {(reminderSuccess) && 
                    <SuccessModal
                        show={true} 
                        massage={reminderSuccess}
                        handleClose={this.successModalClose}
                    />}

                {isDeleteModalOpen && 
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteBooking}								
                        title={selectedRow.id}
                    />}
                <div className="headerBtn">
                    <Button
                        onClick={this.handleModal}
                        variant="outline-primary"
                        text='Add New'
                        icon='sys-add'
                    />
                </div>
                <div className="content-body">
                    <div className="filter-form">
                        <div className="row ">
                            <div className="col-sm">
                                <SelectField
                                    text="Period"
                                    controlId="period"
                                    onChange={(e)=>this.handleChangeSelect(e,'period')}
                                    options={reportPeriodList}
                                    selectedValue={this.state.period}
                                    // isClearable
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="From date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e,"fromDate")}
                                    value={this.state.fromDate === '' ? '' :new Date(this.state.fromDate)}
                                    // format={dateFormat}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e,"toDate")}
                                    value={this.state.toDate === '' ? '' :new Date(this.state.toDate)}
                                    // format={dateFormat}
                                />
                            </div>
                            {/* <div className="col-sm">
                                <TextField
                                    text="Vehicle Registration No"
                                    controlId="vehicleRegistrationNo"
                                    // placeholder="Email"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.vehicleRegistrationNo}
                                    // maxLength={common.maxLengths.customerEmail}
                                />
                            </div> */}
                            {/* <div className="col-sm">
                                <TextField
                                    text="Customer"
                                    controlId="customer"
                                    // placeholder="Email"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.customer}
                                />
                            </div> */}
                        </div>
                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={this.getBookingList}
                                primaryBtnIcon='search'
                                primaryBtnText='Search'
                                primaryBtnVariant="outline-primary" 
                                secondaryBtnClick={this.handleFilterFormClose}
                                secondaryBtnIcon='clear-filter'
                                secondaryBtnText='Clear'
                                secondaryBtnVariant="outline-primary" 
                            />
                        </div>
                    </div>
                    <h5 style={{marginLeft:'5px'}}>Booking List</h5>
                    <div className="table-content">
                        <Table
                            columns={columns}
                            data={reminderList.result && reminderList.result.length ? reminderList.result : []}                   
                        />
                    </div>
                    <Pagination
                        currentPage={reminderList.page ? reminderList.page : this.state.pageNumber}
                        getNextPage={this.getNextPage}
                        getPrevPage={this.getPrevPage}
                        totalPage = {reminderList.totalPages}
                        setClickedPage= {this.setClickedPage}
                        currentPageSize={this.state.pageSize}
                        setPageSize={this.setPageSize}
                    />
                    {
                        isAddNewReminderModalOpen && 
                        <AddReminder
                            isAddNewReminderModalOpen={isAddNewReminderModalOpen}
                            handleModalClose={this.handleModalClose}
                            handleSaveAddNew={this.handleSaveAddNew}
                            isUpdate={isUpdate}
                            selectedRow={selectedRow}
                            handleConfirm={this.handleConfirm}
                            vehicleList={vehicleList}
                            selectedCustomer={selectedCustomer}
                            handleCreateJobcard={this.handleCreateJobcard}
                            handleBookingReminder={this.handleBookingReminder}
                            tenantUserExpireStatus={tenantUserExpireStatus}
                            tenantUser={tenantUser}
                            // bookingVehicleDetails={bookingVehicleDetails}
                            // isBookingUsingVehicle={isBookingUsingVehicle}
                        />
                    }
                </div>
            </div>
        )
    }
}


CalendarListView.propTypes = {
    isAddNewReminderModalOpen: PropTypes.bool,
    reminderListError: PropTypes.any,
    reminderList: PropTypes.any,
    isReminderListLoading: PropTypes.any,
    reminderSuccess: PropTypes.any,
    reportPeriodList: PropTypes.any,
    objectStatusList: PropTypes.any,
    vehicleList: PropTypes.any,
    selectedCustomer: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    reminderList: selectors.makeSelectReminderList(),
    isReminderListLoading: selectors.makeSelectReminderLoading(),
    isAddNewReminderModalOpen: selectors.makeSelectReminderPopupOpen(),
    reminderListError: selectors.makeSelectReminderError(),
    reminderSuccess: selectors.makeSelectReminderSucess(),
    tenantUser: makeSelectTenantUser(),
    reportPeriodList: makeSelectReportPeriodSuccess(),
    objectStatusList:makeSelectObjectStatusList(),
    
    // vehicleList: makeSelectVehicleList(),
    // selectedCustomer: makeSelectSelectedCustomerSuccess(),
    tenantUserExpireStatus: makeSelectTenantUserExpireStatus()
    // bookingVehicleDetails:selectors.makeSelectBookingVehicleDetails(),
    // isBookingUsingVehicle:selectors.makeSelectIsBookingUsingVehicle()
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default withRouter(compose(withConnect)(CalendarListView));
