import { 
  LOAD_BOOKING_LIST_ERROR,
  LOAD_BOOKING_LIST_LOADING,
  LOAD_BOOKING_LIST_REQUEST,
  LOAD_BOOKING_LIST_SUCCESS,
  LOAD_SEARCHED_VEHICLE_REQUEST,
  SAVE_BOOKING_DETAILS_SUCCESS,
  SAVE_CUSTOMER_MODAL_OPEN_STATUS,
  SAVE_IS_BOOKING_MODAL_OPEN_STATUS, 
  SAVE_IS_NEW_BOOKING_USING_VEHICLE, 
  SAVE_NEW_BOOKING_VEHICLE_DETAILS, 
  SEND_ADD_NEW_BOOKING_REQUEST,
  SEND_BOOKING_DELETE_REQUEST,
  SEND_BOOKING_REMINDER_REQUEST,
  SEND_BOOKING_SUCCESS,
  SEND_BOOKING_UPDATE_REQUEST,
  SEND_NEW_CUSTOMER_REQUEST,
  SEND_NEW_CUSTOMER_SUCCESS,
} from '../constant/bookingConstant';
    
export function saveIsBookingModalOpenStatus(open){
  return {
    type: SAVE_IS_BOOKING_MODAL_OPEN_STATUS,
    open,
  }
};

export function sendAddNewBookingRequest(data){
  return {
    type: SEND_ADD_NEW_BOOKING_REQUEST,
    payload: data,
  }
};

export function loadBookingListRequest(data){
  return {
    type: LOAD_BOOKING_LIST_REQUEST,
    payload: data,
  }
};

export function loadBookingListLoading(data){
  return {
    type: LOAD_BOOKING_LIST_LOADING,
    payload: data,
  }
};

export function loadBookingListError(data){
  return {
    type: LOAD_BOOKING_LIST_ERROR,
    payload: data,
  }
};

export function loadBookingListSuccess(data){
  return {
    type: LOAD_BOOKING_LIST_SUCCESS,
    payload: data,
  }
};

export function sendBookingSuccess(data){
  return {
    type: SEND_BOOKING_SUCCESS,
    payload: data,
  }
};

export function sendBookingDeleteRequest(data){
  return {
    type: SEND_BOOKING_DELETE_REQUEST,
    payload: data,
  }
};

export function sendBookingUpdateRequest(data){
  return {
    type: SEND_BOOKING_UPDATE_REQUEST,
    payload: data,
  }
};

export function loadSearchedVehicleRequest(data){
  return {
    type: LOAD_SEARCHED_VEHICLE_REQUEST,
    payload: data,
  }
};

export function saveSelectedBookingSuccess(data){
  return {
    type: SAVE_BOOKING_DETAILS_SUCCESS,
    payload: data,
  }
};

export function saveIsBookingUsingVehicle(data){
  return {
    type: SAVE_IS_NEW_BOOKING_USING_VEHICLE,
    payload: data,
  }
};

export function saveBookingVehicleDetails(data){
  return {
    type: SAVE_NEW_BOOKING_VEHICLE_DETAILS,
    payload: data,
  }
};


    
export function sendBookingReminderRequest(data) {
  return {
    type: SEND_BOOKING_REMINDER_REQUEST,
    payload: data,
  }
};

export function sendNewCustomerRequest(data){
  return {
    type: SEND_NEW_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function sendNewCustomerSuccess(data){
  return {
    type: SEND_NEW_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function saveCustomerModalOpenStatus(open){
  return {
    type: SAVE_CUSTOMER_MODAL_OPEN_STATUS,
    open,
  }
};