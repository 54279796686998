import { createSelector } from 'reselect';

const selectReminderDomain = state => state.reminder;

const makeSelectReminderPopupOpen =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('isAddNewReminderModalOpen') : false
  );

const makeSelectReminderList =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('reminderList') : []
  );

const makeSelectReminderLoading =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('isReminderListLoading') : false
  );

const makeSelectReminderError =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('reminderListError') : ''
  );

const makeSelectReminderSucess =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('reminderSuccess') : ''
  );

const makeSelectSelectedReminderSucess =() =>
  createSelector(
    selectReminderDomain,
    reminderState => 
      reminderState ? reminderState.get('selectedReminder') : ''
  );

const makeSelectIsReminderUsingVehicle =() =>
    createSelector(
      selectReminderDomain,
      reminderState => 
        reminderState ? reminderState.get('isReminderUsingVehicle') : false
    );
  
const makeSelectReminderVehicleDetails =() =>
    createSelector(
      selectReminderDomain,
      reminderState => 
        reminderState ? reminderState.get('reminderVehicleDetails') : ''
    );
    
const makeSelectUnconfirmedReminderDetails =() =>
    createSelector(
      selectReminderDomain,
      reminderState => 
        reminderState ? reminderState.get('unConfirmedReminder') : ''
    );

export {
    makeSelectReminderPopupOpen,
    makeSelectReminderList,
    makeSelectReminderLoading,
    makeSelectReminderError,
    makeSelectReminderSucess,
    makeSelectSelectedReminderSucess,
    makeSelectIsReminderUsingVehicle,
    makeSelectReminderVehicleDetails,
    makeSelectUnconfirmedReminderDetails

}