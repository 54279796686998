import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../layout/form/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import ButtonGroup from '../../../layout/form/ButtonGroup';
import TextField from '../../../layout/form/TextField';
import SelectField from '../../../layout/form/SelectField';
// import common from '../../../common';

export class AddProductCategory extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            description:'',
            typeId: '',
            initial:true
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        if(prevProps.selectedRow && prevProps.selectedRow.id && prevState.initial){
            return {
                description:prevProps.selectedRow.description,
                typeId: prevProps.selectedRow.typeId,
                initial:false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeProductType = (id) => {
        this.setState({
            typeId: id
        })
    }
    
  
    render() {
        const {
            isAddNewProductCategoryModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            productTypeList
        } = this.props;

        return (
            <div>
                <Modal
                    show ={isAddNewProductCategoryModalOpen} 
                    onHide={handleModalClose}
                    title="Product Category Information"
                    width='40%'
                    body={
                        <div>
                            {/* <h5>Basic Data</h5> */}
                            <form style={{marginTop:"5px"}}>
                                    <div className="col-sm">
                                        <TextField
                                            text="Description"
                                            controlId="description"
                                            placeholder="Description"
                                            type="text"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.description}
                                            required
                                            //maxLength={common.maxLengths.titleName}
                                            // disabled={selectedRow && selectedRow.id ? true: false}
                                        />
                                    </div>
                                    <div className="col-sm">
                                        <SelectField                                            
                                            text="Change Type"
                                            controlId="productType"
                                            onChange={this.handleChangeProductType}
                                            options={productTypeList}
                                            selectedValue={this.state.typeId}
                                            required
                                        />
                                    </div>
                            </form>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                            />
                        </div>
                    }
                />
        </div>
        )
    }
}

AddProductCategory.propTypes = {
    isAddNewProductCategoryModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
    selectedRow: PropTypes.any,
    productTypeList: PropTypes.any
}

export default AddProductCategory;