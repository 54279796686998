import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './NavBar.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";

function CustomMenu({
    settingMenuClose,
    userDisplayAccess
}) {

    return (
        <div className='setting_menu'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={settingMenuClose} >
                <ui5-icon class="samples-margin" name="decline" id="decline" ></ui5-icon>
            </div>
            <h5>Master Data Management</h5>
            <div className='row'>
                <div className='col-sm-4'>
                    <li className='setting_text'>
                        <Link to='./productCategory'>Product Category</Link>
                    </li>
                </div>
                <div className='col-sm-4'>
                    <li className='setting_text'>
                        <Link to='./products'>Products</Link>
                    </li>
                </div>
                <div className='col-sm-4'>
                    <li className='setting_text'>
                        <Link to='./services'>Services</Link>
                    </li>
                </div>
                {/* <div className='col-sm'>
                <li className='setting_text'>
                    <Link onClick={redirectMake} to='./make'>Makes</Link>
                </li>
                <li className='setting_text'>
                    <Link onClick={redirectRepair} to='./repair'>Repair Codes</Link>
                </li>
                <li className='setting_text'>
                    <Link to='./srvschedule'>Service Schedules</Link>
                </li> */}
                {/* <li className='setting_text'>
                    <Link to='./screen'>Screen Configuration</Link>
                </li> */}
                {/* </div> */}
                {/* <div className='col-sm'>
                <li className='setting_text'>
                    <Link onClick={redirectModel} to='./model'>Models</Link>
                </li>
                <li className='setting_text'>
                    <Link to='./jobcardtemplates'>Jobcard Templates</Link>
                </li>
            </div> */}
            </div>
            <h5>Templates</h5>
            <div className="row">
                <div className="col-sm-4">
                    <li className='setting_text'>
                        <Link to='./emailTemplates'>Email Templates</Link>
                    </li>
                </div>
                <div className="col-sm-4">
                    <li className='setting_text'>
                        <Link to='./smsTemplates'>SMS Templates</Link>
                    </li>
                </div>
            </div>
            <h5>User Management</h5>
            <div className="row">
                <div className="col-sm">
                    <li className='setting_text'>
                        <Link to='./userRoles'>User Roles</Link>
                    </li>
                </div>
                <div className="col-sm">
                    {userDisplayAccess ? <li className='setting_text'>
                        <Link to='./users'>Users</Link>
                    </li> : ''}
                </div>
                <div className="col-sm"></div>
            </div>
            <h5>Integration</h5>
            <div className="row">
                <div className="col-sm-4">
                    <li className='setting_text'>
                        <Link to='./accounting'>Accounting</Link>
                    </li>
                </div>
                {/* <div className="col-sm">
                <li className='setting_text'>
                    <Link to='./accSystemIntegration'>Accounting System Integration</Link>
                </li>
            </div> */}
            </div>
            {/* <h5>Communication Management</h5>
        <li className='setting_text'>
            <Link to='./notificationType'>Customer Notifications</Link>
        </li> */}
            {/* <h5>Accounting System Integration</h5>
        <div className='row'>
            <div className='col-sm'>
                <li className='setting_text'>
                    <Link to='./accounting-mapping'>Master data mapping</Link>
                </li>
            </div>
            <div className='col-sm'>
                <li className='setting_text'>
                    <Link>Data synchronization</Link>
                </li>
            </div>
        </div> */}
            <h5>Settings</h5>
            <div className="row">
                <div className="col-sm-4">
                    <li className='setting_text'>
                        <Link to='./profile'>Company Profile</Link>
                    </li>
                </div>
                <div className="col-sm-4">
                    <li className='setting_text'>
                        <Link to='./appBilling'>Billing Management</Link>
                    </li>
                </div>
            </div>
        </div>
    )
}

CustomMenu.propTypes = {
    isSideBarStatus: PropTypes.bool,
}

export default CustomMenu;