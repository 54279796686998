import Cookies from 'js-cookie';
import Resizer from "react-image-file-resizer";

const common =
{
    userRole: [
        { id: 'admin', description: 'Admin User' },
        { id: 'general', description: 'General User' }],
    status: [
        { id: 0, description: 'Inactive' },
        { id: 1, description: 'Active' }
    ],
    discountType: [
        { id: '0', value: '0', description: 'Discount Percentage' },
        { id: '1', value: '1', description: 'Discount Value' }
    ],
    headers: {
        'Content-Type': 'application/json',
        'authorization': sessionStorage.getItem("accessToken")
    },
    emailObjectType: [
        { id: 'BOOKING', description: 'Booking' },
        { id: 'VEHICLE', description: 'Service Reminder' },
        { id: 'REGISTRATION', description: 'Registration' },
        { id: 'SURVEY', description: 'Survey' },
        { id: 'PURCHASEORDER', description: 'Purchase Order' },
        { id: 'JOBCARD_IMAGE', description: 'Job Card Image' },
        { id: 'QUOTATION_IMAGE', description: 'Quotation Image' }
    ],
    smsObjectType: [
        { id: 'BOOKING', description: 'Booking' },
        { id: 'VEHICLE', description: 'Service Reminder' },
        { id: 'REGISTRATION', description: 'Registration' }
    ],
    maxLengths: {
        vehicleTypeDescription: 50,
        makeName: 40,
        modelName: 60,
        partId: 16,
        partDescription: 40,
        partUnitPrice: 10,
        partQuantity: 10,
        repairCodeId: 10,
        repairDescription: 250,
        repairUnitPrice: 10,
        repairQuantity: 8,
        customerFirstName: 50,
        customerLastName: 30,
        customerEmail: 60,
        customerMobile: 20,
        customerPhone: 20,
        customerMiddleName: 30,
        customerFax: 60,
        customerCustomField1: 30,
        customerCustomField2: 30,
        customerDisplayName: 50,
        customerBusinessName: 50,
        streetNo: 10,
        tenantStreetNo: 16,
        streetName: 25,
        cityName: 30,
        postCode: 10,
        region: 15,
        vehicleRegNo: 10,
        vehicleColor: 30,
        vehicleTyreSize: 20,
        vehicleCustomField: 50,
        numberOfCylinders: 11,
        lastServiceMileage: 11,
        modelVariant: 40,
        serviceDueMileage: 11,
        vehicleIdentificationNo: 50,
        engineNo: 50,
        businessName: 60,
        email: 45,
        phone: 12,
        mobile: 12,
        licenseNo: 30,
        fax: 50,
        brn: 15,
        jobCardTemplateName: 50,
        userEmail: 250,
        userFirstName: 150,
        userLastName: 150,
        userId: 15,
        userMobile: 50,
        quotaionEmail: 100,
        titleName: 20,
        currencyCode: 3,
        currencySymbol: 3,
        taxRate: 10,
        bankAccountName: 50,
        nameOfTheBank: 40,
        bSB: 6,
        account: 15,
        serviceId: 10,
        serviceSchedulesDescription: 100,
        buildYear: 4,
        estimatedEffort: 8,
        supplierName: 50,
        supplierContactName: 50,
        supplierEmail: 60,
        supplierPhone: 20,
        supplierMobile: 20,
        supplierFax: 60,
        supplierCustomField1: 30,
        supplierCustomField2: 30,
        referenceNo: 15,
        jobCardDescription: 100,
        jobCardUnitPrice: 10,
        jobCardEstimatedQuantity: 10,
        sku: 16,
        productDescription: 250,
        productUnitPrice: 10,
        productQuantity: 10,
        emailSubject: 400,
        smsTemplateName: 45,
        unitPrice: 10,
    },
    objectId: {
        jobCard: 'JOBCARD',
        invoice: 'INVOICE',
        parts: 'PARTS',
        repair: 'REPAIR',
        customer: 'CUSTOMER',
        vehicle: 'VEHICLE',
        jobCardTemplate: 'JOBCARD_TEMPLATE',
        user: 'USER',
        booking: 'BOOKING',
        serviceShedule: 'SERVICE_SCHEDULE',
        supplier: 'SUPPLIER',
        purchasing: 'PURCHASEORDER',
        products: 'PRODUCTS',
        employee: 'EMPLOYEE',
        timesheet: 'TIMESHEET',
        tenant: 'TENANT',
        quotation: 'QUOTATION',
        customerNotification: 'NOTIFICATION',
        emailTemplate: 'EMAIL_TEMPLATE',
        smsTemplate: 'SMS_TEMPLATE',
        userRole: 'USERROLE',
        stockDocument: 'STOCK_DOCUMENT'
    },
    operationId: {
        create: 'CREATE',
        modify: 'MODIFY',
        delete: 'DELETE',
        approve: 'APPROVE',
        download: 'EXPORT',
        display: 'DISPLAY'
    },
    userStatus: {
        active: "10",
        inActive: "50"
    },
    error: "Unexpected error occurred",
    serviceRemindersMessage: "Service reminders was schedule to sent",
    invoiceEmailMessage: "Email was schedule to sent",
    quotationEmailMessage: "Email was schedule to sent",
    purchasingEmailMessage: "Email was schedule to sent",
    purchasingAddAndPrintError: "Record successfully created.Can't print !",
    purchasingAddAndEmailError: "Record successfully created.Can't send email !",
    dateFormat: "YYYY-MM-DD",
    dateTimeFormat: "YYYY-MM-DD HH:mm",
    timeFormat: 'HH:mm',
    bookingRemindersMessage: "Booking reminders was schedule to sent",
    groupBy: [
        { id: 1, description: 'Invoice No' },
        { id: 2, description: 'Date' },
        { id: 3, description: 'Month' },
        { id: 4, description: 'Year' }
    ],
    groupBySurvey: [
        { id: 1, description: 'Customer' },
        { id: 2, description: 'Year' },
        { id: 3, description: 'Month' }
    ],
    groupByChanalBooking: [
        { id: 1, description: 'Year' },
        { id: 2, description: 'Month' }
    ],
    rating: [
        { id: 1, description: '1' },
        { id: 2, description: '2' },
        { id: 3, description: '3' },
        { id: 4, description: '4' },
        { id: 5, description: '5' }
    ],
    massages: {
        emailMassage: "Please enter customer email address",
        requiredFieldMassage: "Missing required Field",
        requiredFieldMassageProfile: "You have to enter all required data for the successful operation of the system",
        customerDeleteMassage: "This customer can't delete",
        supplierDeleteMassage: "This supplier can't delete",
        supplierEmailMassage: "Please enter supplier email address",
        passwordLengthMassage: "The password should be a minimum of 8 characters",
        userInactive: "Sorry you can't change your own status. Contact your system administrator",
        invoiceAmountMassages: "Invoice total should not be negative",
        existProject: "This project has already been used"
    },
    deleteMassages: [
        { id: 6001, description: 'This Customer has Vehicles' },
        { id: 6002, description: 'This Customer has Invoice' },
        { id: 6003, description: 'This Customer has Quotation' },
        { id: 6004, description: 'This Customer has Jobcard' },
        { id: 6005, description: 'This Vehicle has Jobcard' },
        { id: 6006, description: 'This Vehicle has Invoice' },
        { id: 6007, description: 'This Vehicle has Quotation' },
        { id: 6009, description: 'This Jobcard has Quotation' },
        { id: 6010, description: 'This Jobcard has Invoice' },
    ],
    lineChartDetails: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        chartColor_1: '#ff6384',
        chartColor_2: '#36a2eb',
        chartColor_3: '#FFCD56',
        chartColor_4: '#2EFD4A',
        chartColor_5: '#ec0032',
        viewOption: [
            { id: 1, description: 'Last 3 Years' },
            { id: 2, description: 'Last 5 Years' },
        ],
        timeViewOption: [
            { id: 1, description: 'Monthly' },
            { id: 2, description: 'Yearly' },
        ]
    },
    pieChartDetails: {
        labels: ['5 Star', '4 Star', '3 Star', '2 Star', '1 Star'],
        backgroundColor: [
            '#2EFD4A',
            '#36a2eb',
            '#ffff00',
            '#f5a423',
            '#ec0032'
        ],
    },
    graphicalCalender: {
        confirmedColor: '#36a2eb',
        unConfirmColor: '#ff6384',
        monthView: 'month',
        weekView: 'week',
        dayView: 'day'
    },
    tenantDefault: {
        model: "COMMON_MODEL",
    },
    reminder: {
        confirmedColor: '#ec0032',
        unConfirmColor: '#2EFD4A',
        monthView: 'month',
        weekView: 'week',
        dayView: 'day'
    },
    productType: {
        product: 1,
        service: 2
    },
    accounting: {
        quickBooks: "10",
        xero: "20",
        myob: "30"
    },
    month: [
        { id: 1, description: '1' },
        { id: 2, description: '2' },
        { id: 3, description: '3' },
        { id: 4, description: '4' },
        { id: 5, description: '5' },
        { id: 6, description: '6' },
        { id: 7, description: '7' },
        { id: 8, description: '8' },
        { id: 9, description: '9' },
        { id: 10, description: '10' },
        { id: 11, description: '11' },
        { id: 12, description: '12' }
    ],
    stockDocument: {
        supplierId: 0,
        purchaseOrderId: 0,
        jobCardId: 0,
        vehicleId: 0,
        vehicleRegNo: null,
        object: null
    },
    parameters: {
        stockDocument: {
            DocumentNo: null,
            DocumentType: null,
            DocumentDate: null,
            HeaderText: null,
            DocumentItems: [],
            UserId: null,
            ReferenceNo: null,
            ReferenceObject: null,
            SupplierId: null
        }
    },
    day: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    imageType: {
        tenantLogo: 'TENANT_LOGO',
        tenantBanner: 'TENANT_BANNER',
        userLogo: 'USER_PROFILE'
    }
}
export default common
export function refeshTokenset(accessToken) {
    // console.log('refeshTokenset')
    if (accessToken === null) {
        return
    } else
        sessionStorage.setItem('accessToken', accessToken);
}
export function invalidToken() {
    // console.log('invalidToken')
    Cookies.remove("user")
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
}
export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatNumber(number) {
    // Remove any non-digit characters from the number
    // const cleaned = ('' + number).replace(/\D/g, '');

    // Add a space after every third character
    let formatted = '';
    for (let i = 0; i < number.length; i++) {
        if (i > 0 && i % 3 === 0) {
            formatted += ' ';
        }
        formatted += number.charAt(i);
    }

    return formatted;
}


export const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

export function calculateTotal(tax, item, isPriceInclusiveTax, discountType, discountTypeValue) {
    const taxRate = (tax / 100)
    if (item.length === 0) {
        const parms = {
            subTotal: 0,
            tax: 0,
            total: 0,
            gstCalValue: 0,
            discountValue: 0
        }
        return parms;
    }
    var newSubtotal = 0
    var taxValue = 0
    var discount = 0
    for (let i = 0; i < item.length; i++) {
        const element = item[i];
        newSubtotal = newSubtotal + (element.subTotal ? element.subTotal : 0)
    }
    if (newSubtotal === 0) {
        const parms = {
            subTotal: 0,
            tax: 0,
            total: 0,
            gstCalValue: 0,
            discountValue: 0
        }
        return parms;
    }
    if (isPriceInclusiveTax) {
        const exclusiveTaxPrice = (newSubtotal * 100 / (100 + taxRate * 100))
        if (discountType === '0') {
            discount = exclusiveTaxPrice * (discountTypeValue / 100)
        } else if (discountType === '1') {
            discount = parseInt(discountTypeValue)
        }
        const gstCalValue_ = exclusiveTaxPrice - discount
        taxValue = gstCalValue_ * (taxRate)
        const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
        const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
        const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
        const total_ = Math.round(((exclusiveTaxPrice - discount_ + taxValue_) + Number.EPSILON) * 100) / 100
        const parms = {
            subTotal: newSubtotal_,
            discountValue: discount_,
            tax: taxValue_,
            total: total_,
            gstCalValue: Math.round((gstCalValue_ + Number.EPSILON) * 100) / 100
        }
        return parms;
    } else if (!isPriceInclusiveTax) {
        if (discountType === '0') {
            discount = newSubtotal * (discountTypeValue / 100)
        } else if (discountType === '1') {
            discount = parseInt(discountTypeValue)
        }
        const gstCalValue_ = newSubtotal - discount
        taxValue = gstCalValue_ * (taxRate)
        const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
        const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
        const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
        const total_ = newSubtotal_ - discount_ + taxValue_
        const parms = {
            subTotal: newSubtotal_,
            discountValue: discount_,
            tax: taxValue_,
            total: total_,
            gstCalValue: Math.round((gstCalValue_ + Number.EPSILON) * 100) / 100
        }
        return parms;
    }
}
export function calculateQuotationTotal(type, typeTotal, isPriceInclusiveTax, repairsValue, partsValue, serviceValue, taxRate, subTotal) {
    var total = subTotal
    var taxvalue = 0
    var totalValue = 0
    var exclusiveTaxPrice = 0
    if (type === "repair") {
        total = typeTotal + partsValue + serviceValue
    } else if (type === "part") {
        total = typeTotal + repairsValue + serviceValue
    } else if (type === "service") {
        total = typeTotal + repairsValue + partsValue
    } else if (type === "tax") {
        total = serviceValue + repairsValue + partsValue
    }
    if (isPriceInclusiveTax) {
        exclusiveTaxPrice = (total * 100 / (100 + taxRate))
        taxvalue = exclusiveTaxPrice * (taxRate / 100)
    }
    else if (!isPriceInclusiveTax) {
        exclusiveTaxPrice = total
        taxvalue = total * (taxRate / 100)
    }
    const total_ = Math.round((total + Number.EPSILON) * 100) / 100
    const taxValue_ = Math.round((taxvalue + Number.EPSILON) * 100) / 100
    totalValue = Math.round(((exclusiveTaxPrice + taxvalue) + Number.EPSILON) * 100) / 100
    const parms = {
        subTotal: total_,
        tax: taxValue_,
        total: totalValue,
        gstCalValue: exclusiveTaxPrice
    }
    return parms;
}
export function getUserAccess(list) {
    const projectCreate = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.create))
    const projectDelete = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.delete))
    const projectModify = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.modify))
    const timesheetCreate = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.create))
    const timesheetDelete = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.delete))
    const timesheetModify = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.modify))
    const timesheetApprove = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.approve))
    const timesheetDownload = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.download))
    const userCreate = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.create))
    const userDelete = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.delete))
    const userModify = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.modify))
    const userDisplay = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.display))
    const employeeCreate = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.create))
    const employeeDelete = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.delete))
    const employeeModify = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.modify))
    const accountIntergrte = list.filter(element => (element.objectId === common.objectId.intergrted && element.operationId === common.operationId.create))
    const tenantModify = list.filter(element => (element.objectId === common.objectId.tenant && element.operationId === common.operationId.modify))
    const jobCardCreate = list.filter(element => (element.objectId === common.objectId.jobCard && element.operationId === common.operationId.create))
    const jobCardDelete = list.filter(element => (element.objectId === common.objectId.jobCard && element.operationId === common.operationId.delete))
    const jobCardModify = list.filter(element => (element.objectId === common.objectId.jobCard && element.operationId === common.operationId.modify))
    const invoiceCreate = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.create))
    const invoiceDelete = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.delete))
    const invoiceModify = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.modify))
    const vehicleCreate = list.filter(element => (element.objectId === common.objectId.vehicle && element.operationId === common.operationId.create))
    const vehicleDelete = list.filter(element => (element.objectId === common.objectId.vehicle && element.operationId === common.operationId.delete))
    const vehicleModify = list.filter(element => (element.objectId === common.objectId.vehicle && element.operationId === common.operationId.modify))
    const customerCreate = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.create))
    const customerDelete = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.delete))
    const customerModify = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.modify))
    const quotationCreate = list.filter(element => (element.objectId === common.objectId.quotation && element.operationId === common.operationId.create))
    const quotationDelete = list.filter(element => (element.objectId === common.objectId.quotation && element.operationId === common.operationId.delete))
    const quotationModify = list.filter(element => (element.objectId === common.objectId.quotation && element.operationId === common.operationId.modify))
    const customerNotificationCreate = list.filter(element => (element.objectId === common.objectId.customerNotification && element.operationId === common.operationId.create))
    const customerNotificationDelete = list.filter(element => (element.objectId === common.objectId.customerNotification && element.operationId === common.operationId.delete))
    const customerNotificationModify = list.filter(element => (element.objectId === common.objectId.customerNotification && element.operationId === common.operationId.modify))
    const jobCardTemplateCreate = list.filter(element => (element.objectId === common.objectId.jobCardTemplate && element.operationId === common.operationId.create))
    const jobCardTemplateDelete = list.filter(element => (element.objectId === common.objectId.jobCardTemplate && element.operationId === common.operationId.delete))
    const jobCardTemplateModify = list.filter(element => (element.objectId === common.objectId.jobCardTemplate && element.operationId === common.operationId.modify))
    const emailTemplateCreate = list.filter(element => (element.objectId === common.objectId.emailTemplate && element.operationId === common.operationId.create))
    const emailTemplateDelete = list.filter(element => (element.objectId === common.objectId.emailTemplate && element.operationId === common.operationId.delete))
    const emailTemplateModify = list.filter(element => (element.objectId === common.objectId.emailTemplate && element.operationId === common.operationId.modify))
    const smsTemplateCreate = list.filter(element => (element.objectId === common.objectId.smsTemplate && element.operationId === common.operationId.create))
    const smsTemplateDelete = list.filter(element => (element.objectId === common.objectId.smsTemplate && element.operationId === common.operationId.delete))
    const smsTemplateModify = list.filter(element => (element.objectId === common.objectId.smsTemplate && element.operationId === common.operationId.modify))
    const supplierCreate = list.filter(element => (element.objectId === common.objectId.supplier && element.operationId === common.operationId.create))
    const supplierDelete = list.filter(element => (element.objectId === common.objectId.supplier && element.operationId === common.operationId.delete))
    const supplierModify = list.filter(element => (element.objectId === common.objectId.supplier && element.operationId === common.operationId.modify))
    const purchaseOrderCreate = list.filter(element => (element.objectId === common.objectId.purchasing && element.operationId === common.operationId.create))
    const purchaseOrderDelete = list.filter(element => (element.objectId === common.objectId.purchasing && element.operationId === common.operationId.delete))
    const purchaseOrderModify = list.filter(element => (element.objectId === common.objectId.purchasing && element.operationId === common.operationId.modify))
    const userRoleCreate = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.create))
    const userRoleDelete = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.delete))
    const userRoleModify = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.modify))
    const stockDocumentCreate = list.filter(element => (element.objectId === common.objectId.stockDocument && element.operationId === common.operationId.create))
    const stockDocumentDelete = list.filter(element => (element.objectId === common.objectId.stockDocument && element.operationId === common.operationId.delete))
    const stockDocumentModify = list.filter(element => (element.objectId === common.objectId.stockDocument && element.operationId === common.operationId.modify))


    const accessList = {
        createProject: projectCreate && projectCreate.length > 0 && projectCreate[0].accessStatus,
        deleteProject: projectDelete && projectDelete.length > 0 && projectDelete[0].accessStatus,
        modifyProject: projectModify && projectModify.length > 0 && projectModify[0].accessStatus,
        createTimesheet: timesheetCreate && timesheetCreate.length > 0 && timesheetCreate[0].accessStatus,
        deleteTimesheet: timesheetDelete && timesheetDelete.length > 0 && timesheetDelete[0].accessStatus,
        modifyTimesheet: timesheetModify && timesheetModify.length > 0 && timesheetModify[0].accessStatus,
        approveTimesheet: timesheetApprove && timesheetApprove.length > 0 && timesheetApprove[0].accessStatus,
        downloadTimesheet: timesheetDownload && timesheetDownload.length > 0 && timesheetDownload[0].accessStatus,
        createUser: userCreate && userCreate.length > 0 && userCreate[0].accessStatus,
        deleteUser: userDelete && userDelete.length > 0 && userDelete[0].accessStatus,
        modifyUser: userModify && userModify.length > 0 && userModify[0].accessStatus,
        displayUser: userDisplay && userDisplay.length > 0 && userDisplay[0].accessStatus,
        createEmployee: employeeCreate && employeeCreate.length > 0 && employeeCreate[0].accessStatus,
        deleteEmployee: employeeDelete && employeeDelete.length > 0 && employeeDelete[0].accessStatus,
        modifyEmployee: employeeModify && employeeModify.length > 0 && employeeModify[0].accessStatus,
        accountIntergrte: accountIntergrte && accountIntergrte.length > 0 && accountIntergrte[0].accessStatus,
        modifyTenant: tenantModify && tenantModify.length > 0 && tenantModify[0].accessStatus,
        createJobcard: jobCardCreate && jobCardCreate.length > 0 && jobCardCreate[0].accessStatus,
        deleteJobcard: jobCardDelete && jobCardDelete.length > 0 && jobCardDelete[0].accessStatus,
        modifyJobcard: jobCardModify && jobCardModify.length > 0 && jobCardModify[0].accessStatus,
        createInvoice: invoiceCreate && invoiceCreate.length > 0 && invoiceCreate[0].accessStatus,
        deleteInvoice: invoiceDelete && invoiceDelete.length > 0 && invoiceDelete[0].accessStatus,
        modifyInvoice: invoiceModify && invoiceModify.length > 0 && invoiceModify[0].accessStatus,
        createVehicle: vehicleCreate && vehicleCreate.length > 0 && vehicleCreate[0].accessStatus,
        deleteVehicle: vehicleDelete && vehicleDelete.length > 0 && vehicleDelete[0].accessStatus,
        modifyVehicle: vehicleModify && vehicleModify.length > 0 && vehicleModify[0].accessStatus,
        createCustomer: customerCreate && customerCreate.length > 0 && customerCreate[0].accessStatus,
        deleteCustomer: customerDelete && customerDelete.length > 0 && customerDelete[0].accessStatus,
        modifyCustomer: customerModify && customerModify.length > 0 && customerModify[0].accessStatus,
        createQuotation: quotationCreate && quotationCreate.length > 0 && quotationCreate[0].accessStatus,
        deleteQuotation: quotationDelete && quotationDelete.length > 0 && quotationDelete[0].accessStatus,
        modifyQuotation: quotationModify && quotationModify.length > 0 && quotationModify[0].accessStatus,
        createCustomerNotification: customerNotificationCreate && customerNotificationCreate.length > 0 && customerNotificationCreate[0].accessStatus,
        deleteCustomerNotification: customerNotificationDelete && customerNotificationDelete.length > 0 && customerNotificationDelete[0].accessStatus,
        modifyCustomerNotification: customerNotificationModify && customerNotificationModify.length > 0 && customerNotificationModify[0].accessStatus,
        createJobcardTemplate: jobCardTemplateCreate && jobCardTemplateCreate.length > 0 && jobCardTemplateCreate[0].accessStatus,
        deleteJobcardTemplate: jobCardTemplateDelete && jobCardTemplateDelete.length > 0 && jobCardTemplateDelete[0].accessStatus,
        modifyJobcardTemplate: jobCardTemplateModify && jobCardTemplateModify.length > 0 && jobCardTemplateModify[0].accessStatus,
        createEmailTemplate: emailTemplateCreate && emailTemplateCreate.length > 0 && emailTemplateCreate[0].accessStatus,
        deleteEmailTemplate: emailTemplateDelete && emailTemplateDelete.length > 0 && emailTemplateDelete[0].accessStatus,
        modifyEmailTemplate: emailTemplateModify && emailTemplateModify.length > 0 && emailTemplateModify[0].accessStatus,
        createSMSTemplate: smsTemplateCreate && smsTemplateCreate.length > 0 && smsTemplateCreate[0].accessStatus,
        deleteSMSTemplate: smsTemplateDelete && smsTemplateDelete.length > 0 && smsTemplateDelete[0].accessStatus,
        modifySMSTemplate: smsTemplateModify && smsTemplateModify.length > 0 && smsTemplateModify[0].accessStatus,
        createSupplier: supplierCreate && supplierCreate.length > 0 && supplierCreate[0].accessStatus,
        deleteSupplier: supplierDelete && supplierDelete.length > 0 && supplierDelete[0].accessStatus,
        modifySupplier: supplierModify && supplierModify.length > 0 && supplierModify[0].accessStatus,
        createPurchaseOrder: purchaseOrderCreate && purchaseOrderCreate.length > 0 && purchaseOrderCreate[0].accessStatus,
        deletePurchaseOrder: purchaseOrderDelete && purchaseOrderDelete.length > 0 && purchaseOrderDelete[0].accessStatus,
        modifyPurchaseOrder: purchaseOrderModify && purchaseOrderModify.length > 0 && purchaseOrderModify[0].accessStatus,
        createUserRole: userRoleCreate && userRoleCreate.length > 0 && userRoleCreate[0].accessStatus,
        deleteUserRole: userRoleDelete && userRoleDelete.length > 0 && userRoleDelete[0].accessStatus,
        modifyUserRole: userRoleModify && userRoleModify.length > 0 && userRoleModify[0].accessStatus,
        createStockDocument: stockDocumentCreate && stockDocumentCreate.length > 0 && stockDocumentCreate[0].accessStatus,
        deleteStockDocument: stockDocumentDelete && stockDocumentDelete.length > 0 && stockDocumentDelete[0].accessStatus,
        modifyStockDocument: stockDocumentModify && stockDocumentModify.length > 0 && stockDocumentModify[0].accessStatus,
    }
    return accessList;
}

// export const resizeFile = (file) =>
//     new Promise((resolve) => {
//         Resizer.imageFileResizer(
//             file,
//             300,
//             300,
//             "JPEG",
//             100,
//             0,
//             (uri) => {
//                 resolve(uri);
//             },
//             "file"
//         );
//     });