import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewBookingRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
      return axios.post(baseURL+'bookings', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
      })
}

export function getBookingList(parameters){
  return axios.get(baseURL+'bookings?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&FromDate='+parameters.fromDate+'&ToDate='+parameters.toDate+'&RegNo='+parameters.regNo+'&CustomerName='+parameters.customerName, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function deleteBookingRequest(id){
  return axios.delete(baseURL+'bookings/'+id, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function updateBookingRequest(data){
  const parameters = JSON.stringify(data)
  //console.log(parameters)
  return axios.patch(baseURL+'bookings/'+data.id, parameters, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}

export function bookingReminderRequest(id){
  return axios.post(baseURL+'bookings/bookingReminders/'+id,null, {
      headers: {
          'Content-Type': 'application/json',
          'authorization' : sessionStorage.getItem("accessToken")
      }
  })
}