import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getItemCategoryList(){
    return axios.get(baseURL+'itemCategory', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

// export function getObjectStatusList(){
//     return axios.get(baseURL+'objectStatus', {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization' : sessionStorage.getItem("accessToken")
//         }
//     })
// }

// export function getUOMList(){
//     return axios.get(baseURL+'unitOfMeasure', {
//         headers: {
//             'Content-Type': 'application/json',
//             'authorization' : sessionStorage.getItem("accessToken")
//         }
//     })
// }

export function addNewItemRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'masterItems', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function getItemList(parameters){
    return axios.get(baseURL+'masterItems?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&itemId='+parameters.itemId+'&categoryId='+parameters.categoryId+'&description='+parameters.description+'&status='+parameters.status+'&commonSearch='+parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateItemRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'masterItems/'+data.itemId, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteItemRequest(id){
    return axios.delete(baseURL+'masterItems/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}