import axios from 'axios'
// import common from '../common'

const baseURL = process.env.REACT_APP_API_URL

export function addNewCustomerRequest(data) {
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.post(baseURL + 'customers', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getCustomerList(parameters) {
    return axios.get(baseURL + 'customers?pageSize=' + parameters.pageSize + '&pageNumber=' + parameters.pageNumber + '&name=' + parameters.name + '&city=' + parameters.city + '&email=' + parameters.email + '&mobile=' + parameters.mobile + '&commonSearch=' + parameters.commonSearch, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function updateCustomerRequest(data) {
    const parameters = JSON.stringify(data)
    //console.log(parameters)
    return axios.patch(baseURL + 'customers/' + data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteCustomerRequest(id) {
    return axios.delete(baseURL + 'customers/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedCustomerRequest(id) {
    return axios.get(baseURL + 'customers/' + id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}

export function sendCustomerNotificationRequest(data) {
    const parameters = JSON.stringify(data)
    return axios.post(baseURL + 'customers/notification', parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}


export function getCustomerCreateSummary() {
    return axios.get(baseURL + 'customers/customerCreateSummary', {
        headers: {
            'Content-Type': 'application/json',
            'authorization': sessionStorage.getItem("accessToken")
        }
    })
}