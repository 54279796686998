import { fromJS } from 'immutable';
import {
  LOAD_PART_CATEGORY_LIST_SUCCESS,
  LOAD_PART_LIST_ERROR,
  LOAD_PART_LIST_LOADING,
  LOAD_PART_LIST_SUCCESS,
  SAVE_IS_PART_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_PART_ERROR,
  SEND_ADD_NEW_PART_LOADING,
  SEND_ADD_NEW_PART_SUCCESS,
  SEND_DELETE_PART_ERROR,
  SEND_DELETE_PART_LOADING,
  SEND_DELETE_PART_SUCCESS,
  SEND_UPDATE_PART_ERROR,
  SEND_UPDATE_PART_LOADING,
  SEND_UPDATE_PART_SUCCESS,
} from '../constant/partConstant';

export const initialState = fromJS({
    isAddNewPartModalOpen:false,
    isAddNewPartLoading:false,
    addNewPartSuccess:'',
    addNewPartError:'',
    partList:[],
    isPartListLoading: false,
    getPartListError:'',
    partCategoryList:[],
    objectStatusList:[],
    uomList:[],
    isUpdatePartLoading:false,
    updatePartSuccess:'',
    updatePartError:'',
    isDeletePartLoading:false,
    deletePartSuccess:'',
    deletePartError:''
});

function partReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_PART_MODAL_OPEN_STATUS:
      return state.set('isAddNewPartModalOpen', action.open)
    case SEND_ADD_NEW_PART_LOADING:
      return state.set('isAddNewPartLoading', action.payload)
    case SEND_ADD_NEW_PART_SUCCESS:
      return state.set('addNewPartSuccess', action.payload)
    case SEND_ADD_NEW_PART_ERROR:
      return state.set('addNewPartError', action.payload)
    case LOAD_PART_LIST_SUCCESS:
      return state.set('partList', action.payload)
    case LOAD_PART_LIST_LOADING:
      return state.set('isPartListLoading', action.payload)
    case LOAD_PART_LIST_ERROR:
      return state.set('getPartListError', action.payload)
    case LOAD_PART_CATEGORY_LIST_SUCCESS:
      return state.set('partCategoryList', action.payload)
    case SEND_UPDATE_PART_LOADING:
      return state.set('isUpdatePartLoading', action.payload)
    case SEND_UPDATE_PART_SUCCESS:
      return state.set('updatePartSuccess', action.payload)
    case SEND_UPDATE_PART_ERROR:
      return state.set('updatePartError', action.payload)
    case SEND_DELETE_PART_LOADING:
      return state.set('isDeletePartLoading', action.payload)
    case SEND_DELETE_PART_SUCCESS:
      return state.set('deletePartSuccess', action.payload)
    case SEND_DELETE_PART_ERROR:
      return state.set('deletePartError', action.payload)
    default:
      return state;
  }
}

export default partReducer;
