import { put, call } from 'redux-saga/effects';
import { addStockDocumentRequest, getSelectedStockDocumentRequest, getStockDocumentList, getStockDocumentProductReport, getStockDocumentTypeList, printStockDocumentByIdRequest } from '../service/stockDocumentService';
import * as actions from '../../store/action/stockDocumentAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* loadStockDocumentTypeRequest() {
  try {
    const response = yield call(getStockDocumentTypeList);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      yield put(actions.loadStockDocumentTypeSuccess(response.data.result.result))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    //console.log(error)
  }
}

export function* loadStockDocumentListRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    yield put(actions.loadStockDocumentListLoading(true));
    const response = yield call(getStockDocumentList, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadStockDocumentListSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadStockDocumentListLoading(false));
      const massage = common.error
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadStockDocumentListLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentListLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}


export function* loadSelectedStockDocumentRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    yield put(actions.loadStockDocumentListLoading(true));
    const response = yield call(getSelectedStockDocumentRequest, data.payload);
    if (response && response.status && response.status === 200) {
      const stockDocument = response.data
      yield put(actions.loadSelectedStockDocumentSuccess(stockDocument))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadStockDocumentListLoading(false));
      const massage = common.error
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.newAccessToken) {
      refeshTokenset(response.data.newAccessToken)
    }
    yield put(actions.loadStockDocumentListLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentListLoading(false));
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}


export function* sendAddStockDocumentRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    const response = yield call(addStockDocumentRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      const massage = response.data.message
      yield put(actions.sendStockDocumentSuccess(massage))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = response.data.message
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
  } catch (error) {
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}


export function* loadPrintbyIdRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    yield put(actions.loadStockDocumentListLoading(true));
    const response = yield call(printStockDocumentByIdRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const pdf = response.data.result.byteCode
      yield put(actions.loadStockDocumentPrintSuccess(pdf))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadStockDocumentListLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentListLoading(false));
    //console.log(error.response)
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}


export function* sendAddAndPrintStockDocumentRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    yield put(actions.loadStockDocumentListLoading(true));
    const response = yield call(addStockDocumentRequest, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 201) {
      const orderId = response.data.result.id
      const printResponse = yield call(printStockDocumentByIdRequest, orderId)
      if (printResponse && printResponse.data && printResponse.data.statusCode && printResponse.data.statusCode === 200) {
        const pdf = printResponse.data.result.byteCode
        yield put(actions.loadStockDocumentPrintSuccess(pdf))
      } else {
        const massage = common.purchasingAddAndPrintError
        yield put(actions.loadStockDocumentListError(massage))
      }
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadStockDocumentListLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentListLoading(false));
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}


export function* loadStockDocumentProductReportRequest(data) {
  yield put(actions.loadStockDocumentProductReportError(null))
  try {
    yield put(actions.loadStockDocumentProductReportLoading(true));
    const response = yield call(getStockDocumentProductReport, data.payload);
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const projectList = response.data.result
      yield put(actions.loadStockDocumentProductReportSuccess(projectList))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      yield put(actions.loadStockDocumentProductReportLoading(false));
      const massage = common.error
      yield put(actions.loadStockDocumentProductReportError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadStockDocumentProductReportLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentProductReportLoading(false));
    console.log(error)
    const massage = common.error
    yield put(actions.loadStockDocumentProductReportError(massage))
  }
}


export function* loadPrintStockDocumentRequest(data) {
  yield put(actions.loadStockDocumentListError(null))
  try {
    yield put(actions.loadStockDocumentListLoading(true));
    const response = yield call(printStockDocumentByIdRequest, data.payload)
    if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
      const pdf = response.data.result.byteCode
      yield put(actions.loadStockDocumentPrintSuccess(pdf))
    }
    else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
      yield put(saveLoginUser(false))
      invalidToken()
    }
    else {
      const massage = common.error
      yield put(actions.loadStockDocumentListError(massage))
    }
    if (response && response.data && response.data.result && response.data.result.newAccessToken) {
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadStockDocumentListLoading(false));
  } catch (error) {
    yield put(actions.loadStockDocumentListLoading(false));
    const massage = common.error
    yield put(actions.loadStockDocumentListError(massage))
  }
}
