import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import './form.css';

class Cards extends Component {

    render(){       
        const { 
            icon,
            title,
            cardContentText,
            cardContentData,
            detailField1_Text,
            detailField1_Data,
            detailField2_Text,
            detailField2_Data
        } =this.props;
        return(
            <div>
                <Card className="card" >
                    <Card.Body>
                    <div>
                        <div className="row">
                            <div className="col-sm-2">
                                <ui5-icon class="samples-margin" name={icon} id="card-icon"></ui5-icon>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm">
                                <h5 style ={{textAlign:'center'}}>{title}</h5>
                                {cardContentText  ? 
                                    <div className="card-top">
                                        <span>{cardContentText}</span>
                                        <span>{cardContentData}</span>
                                    </div> : ''}
                            </div>
                            <Card.Text>
                            </Card.Text>
                        </div>
                        {cardContentText  ? <div style={{borderTop:'3px solid #f5a423'}}></div>
                            :
                            <div className="dot"><span></span></div>}
                        {detailField1_Text ? 
                            <div className="card-bottom">
                                <span>{detailField1_Text}</span>
                                <span>{detailField1_Data}</span>
                            </div> : ''}
                        {detailField2_Text ? 
                            <div className="card-bottom">
                                <span>{detailField2_Text}</span>
                                <span>{detailField2_Data}</span>
                            </div> : ''}
                    </div>
                    <div>
                        
                    </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

Cards.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.any,
    cardContentText: PropTypes.any,
    cardContentData: PropTypes.any,
    detailField1_Text: PropTypes.any,
    detailField1_Data: PropTypes.any,
    detailField2_Text: PropTypes.any,
    detailField2_Data: PropTypes.any,
}

export default Cards;