import { fromJS } from 'immutable';
import {
  LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS,
  LOAD_STOCK_DOCUMENT_LIST_ERROR,
  LOAD_STOCK_DOCUMENT_LIST_LOADING,
  LOAD_STOCK_DOCUMENT_LIST_SUCCESS,
  LOAD_STOCK_DOCUMENT_PRINT_SUCCESS,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS,
  LOAD_STOCK_DOCUMENT_TYPE_SUCCESS,
  SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS, SAVE_IS_STOCK_REPORT_OPEN_STATUS, SAVE_STOCK_DOCUMENT_DETAILS, SEND_STOCK_DOCUMENT_SUCCESS
} from '../constant/stockDocumentConstant';
import common from '../../component/common';

export const initialState = fromJS({
  isStockDocumentOpen: false,
  stockDocumentDetails: common.stockDocument,
  stockDocumentTypeList: [],
  stockDocumentList: [],
  isStockDocumentListLoading: false,
  stockDocumentListError: null,
  stockDocument: {},
  stockDocumentSuccess: null,
  stockDocumentPrint: null,
  isStockDocumentProductReportLoading: false,
  stockDocumentProductReportError: null,
  stockDocumentProductReport: {},
  isStockReportOpen: false
});

function stockDocumentReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS:
      return state.set('isStockDocumentOpen', action.open)
    case SAVE_STOCK_DOCUMENT_DETAILS:
      return state.set('stockDocumentDetails', action.payload)
    case LOAD_STOCK_DOCUMENT_TYPE_SUCCESS:
      return state.set('stockDocumentTypeList', action.payload)
    case LOAD_STOCK_DOCUMENT_LIST_SUCCESS:
      return state.set('stockDocumentList', action.payload)
    case LOAD_STOCK_DOCUMENT_LIST_LOADING:
      return state.set('isStockDocumentListLoading', action.payload)
    case LOAD_STOCK_DOCUMENT_LIST_ERROR:
      return state.set('stockDocumentListError', action.payload)
    case LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS:
      return state.set('stockDocument', action.payload)
    case SEND_STOCK_DOCUMENT_SUCCESS:
      return state.set('stockDocumentSuccess', action.payload)
    case LOAD_STOCK_DOCUMENT_PRINT_SUCCESS:
      return state.set('stockDocumentPrint', action.payload)
    case LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING:
      return state.set('isStockDocumentProductReportLoading', action.payload)
    case LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR:
      return state.set('stockDocumentProductReportError', action.payload)
    case LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS:
      return state.set('stockDocumentProductReport', action.payload)
    case SAVE_IS_STOCK_REPORT_OPEN_STATUS:
      return state.set('isStockReportOpen', action.open)
    default:
      return state;
  }
}

export default stockDocumentReducer;
