import { fromJS } from 'immutable';
import {
  SAVE_IS_USERS_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_USER_LOADING,
  SEND_ADD_NEW_USER_SUCCESS,
  SEND_ADD_NEW_USER_ERROR,
  LOAD_USER_LIST_SUCCESS,
  LOAD_SELECTED_USER_SUCCESS,
  LOAD_USER_ROLE_LIST_SUCCESS,
  LOAD_USER_ACCESS_SUCCESS
} from '../constant/usersConstant';

export const initialState = fromJS({
  isAddNewUsersModalOpen: false,
  loading: false,
  success: '',
  error: '',
  userList: [],
  selectedUser: {},
  roleList: [],
  userAccess: {},
});

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_USERS_MODAL_OPEN_STATUS:
      return state.set('isAddNewUsersModalOpen', action.open);
    case SEND_ADD_NEW_USER_LOADING:
      return state.set('loading', action.payload);
    case SEND_ADD_NEW_USER_SUCCESS:
      return state.set('success', action.payload);
    case SEND_ADD_NEW_USER_ERROR:
      return state.set('error', action.payload);
    case LOAD_USER_LIST_SUCCESS:
      return state.set('userList', action.payload);
    case LOAD_SELECTED_USER_SUCCESS:
      return state.set('selectedUser', action.payload);
    case LOAD_USER_ROLE_LIST_SUCCESS:
      return state.set('roleList', action.payload);
    case LOAD_USER_ACCESS_SUCCESS:
      return state.set('userAccess', action.payload);
    default:
      return state;
  }
}

export default usersReducer;
