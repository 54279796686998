import { createSelector } from 'reselect';

const selectAccountProductCategoryDomain = state => state.accountProductCategory;

const makeSelectAccountProductCategory = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('accountProductCategoryList') : []
  );

const makeSelectAccountProductCategoryLoading = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('isAccountProductCategoryLoading') : false
  );

const makeSelectAccountProductCategoryError = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('accountProductCategoryError') : ''
  );

const makeSelectIsLoadAccountProductCategory = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('isAccountProductCategoryList') : false
  );

const makeSelectMappingProductCategory = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('mappingProductCategoryList') : []
  );

const makeSelectMappingProductCategorySuccess = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('mappingProductCategory') : false
  );

const makeSelectAccountCustomer = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('accountCustomerList') : []
  );

const makeSelectIsLoadAccountCustomer = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('isAccountCustomerList') : false
  );

const makeSelectMappingCustomer = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('mappingCustomerList') : []
  );

const makeSelectMappingAccountList = () =>
  createSelector(
    selectAccountProductCategoryDomain,
    accountingState =>
      accountingState ? accountingState.get('mappingAccountList') : []
  );

export {
  makeSelectAccountProductCategory,
  makeSelectAccountProductCategoryError,
  makeSelectAccountProductCategoryLoading,
  makeSelectIsLoadAccountProductCategory,
  makeSelectMappingProductCategory,
  makeSelectMappingProductCategorySuccess,
  makeSelectAccountCustomer,
  makeSelectIsLoadAccountCustomer,
  makeSelectMappingCustomer,
  makeSelectMappingAccountList
}
