import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function getEmailTemplateList(parameters){
    return axios.get(baseURL+'emailTemplates?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&Name='+parameters.name, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function addNewEmailTemplateRequest(data){
    const parameters = JSON.stringify(data)
    //console.log(parameters)
      return axios.post(baseURL+'emailTemplates', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function updateEmailTemplateRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'emailTemplates/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteEmailTemplateRequest(id){
    return axios.delete(baseURL+'emailTemplates/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedEmailTemplateRequest(id){
    return axios.get(baseURL+'emailTemplates/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}