import {
  LOAD_A2A_ACCOUNT_REQUEST,
  LOAD_A2A_ACCOUNT_SUCCESS,
  LOAD_A2A_CUSTOMER_REQUEST,
  LOAD_A2A_CUSTOMER_SUCCESS,
  LOAD_A2A_PRODUCT_CATEGORY_REQUEST,
  LOAD_A2A_PRODUCT_CATEGORY_SUCCESS,
  LOAD_ACCOUNT_CUSTOMER_REQUEST,
  LOAD_ACCOUNT_CUSTOMER_SUCCESS,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST,
  LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS,
  SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
  SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY,
  SEND_A2A_ACCOUNT_REQUEST,
  SEND_A2A_CUSTOMER_REQUEST,
  SEND_A2A_PRODUCT_CATEGORY_REQUEST,
  SEND_A2A_PRODUCT_CATEGORY_SUCCESS,
} from '../constant/accountProductCategoryConstant';


export function loadAccountProductCategoryRequest(data) {
  return {
    type: LOAD_ACCOUNT_PRODUCT_CATEGORY_REQUEST,
    payload: data,
  }
};

export function loadAccountProductCategoryLoading(data) {
  return {
    type: LOAD_ACCOUNT_PRODUCT_CATEGORY_LOADING,
    payload: data,
  }
};

export function loadAccountProductCategorySuccess(data) {
  return {
    type: LOAD_ACCOUNT_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  }
};

export function loadAccountProductCategoryError(data) {
  return {
    type: LOAD_ACCOUNT_PRODUCT_CATEGORY_ERROR,
    payload: data,
  }
};

export function saveIsAccountProductCategory(data) {
  return {
    type: SAVE_IS_LOAD_ACCOUNT_PRODUCT_CATEGORY,
    payload: data,
  }
};

export function loadMappingProductCategoryRequest(data) {
  return {
    type: LOAD_A2A_PRODUCT_CATEGORY_REQUEST,
    payload: data,
  }
};

export function loadMappingProductCategorySuccess(data) {
  return {
    type: LOAD_A2A_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  }
};

export function sendMappingProductCategoryRequest(data) {
  return {
    type: SEND_A2A_PRODUCT_CATEGORY_REQUEST,
    payload: data,
  }
};

export function sendMappingProductCategorySuccess(data) {
  return {
    type: SEND_A2A_PRODUCT_CATEGORY_SUCCESS,
    payload: data,
  }
};

export function loadAccountCustomerRequest(data) {
  return {
    type: LOAD_ACCOUNT_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function loadAccountCustomerSuccess(data) {
  return {
    type: LOAD_ACCOUNT_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function saveIsAccountCustomerStatus(data) {
  return {
    type: SAVE_IS_LOAD_ACCOUNT_CUSTOMER,
    payload: data,
  }
};

export function loadMappingCustomerRequest(data) {
  return {
    type: LOAD_A2A_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function loadMappingCustomerSuccess(data) {
  return {
    type: LOAD_A2A_CUSTOMER_SUCCESS,
    payload: data,
  }
};

export function sendMappingCustomerRequest(data) {
  return {
    type: SEND_A2A_CUSTOMER_REQUEST,
    payload: data,
  }
};

export function loadMappingAccountRequest(data) {
  return {
    type: LOAD_A2A_ACCOUNT_REQUEST,
    payload: data,
  }
};

export function loadMappingAccountSuccess(data) {
  return {
    type: LOAD_A2A_ACCOUNT_SUCCESS,
    payload: data,
  }
};

export function sendMappingAccountRequest(data) {
  return {
    type: SEND_A2A_ACCOUNT_REQUEST,
    payload: data,
  }
};
