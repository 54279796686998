import { 
  SAVE_IS_PRODUCT_MODAL_OPEN_STATUS,
  LOAD_PRODUCT_LIST_REQUEST,
  LOAD_PRODUCT_LIST_LOADING,
  LOAD_PRODUCT_LIST_SUCCESS,
  LOAD_PRODUCT_LIST_ERROR,
  SEND_ADD_NEW_PRODUCT_REQUEST,
  SEND_ADD_NEW_PRODUCT_LOADING,
  SEND_ADD_NEW_PRODUCT_SUCCESS,
  SEND_ADD_NEW_PRODUCT_ERROR,
  // LOAD_PRODUCT_CATEGORY_LIST_REQUEST,
  // LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
  SEND_UPDATE_PRODUCT_REQUEST,
  SEND_UPDATE_PRODUCT_LOADING,
  SEND_UPDATE_PRODUCT_SUCCESS,
  SEND_UPDATE_PRODUCT_ERROR,
  SEND_DELETE_PRODUCT_REQUEST,
  SEND_DELETE_PRODUCT_LOADING,
  SEND_DELETE_PRODUCT_SUCCESS,
  SEND_DELETE_PRODUCT_ERROR,
  LOAD_PRODUCT_TYPE_LIST_REQUEST,
  LOAD_PRODUCT_TYPE_LIST_SUCCESS,
  LOAD_SELECTED_PRODUCT_REQUEST,
  LOAD_SELECTED_PRODUCT_SUCCESS,
  LOAD_BALANCE_STOCK_PRINT_REQUEST,
  LOAD_BALANCE_STOCK_PRINT_SUCCESS,
  LOAD_ALL_PRODUCTS_REQUEST,
  LOAD_ALL_PRODUCTS_SUCCESS,
  LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST,
  LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS,
  SEND_ALTERNATIVE_PRODUCT_REQUEST,
} from '../constant/productConstant';

export function saveIsProductModalOpenStatus(open){
return {
  type: SAVE_IS_PRODUCT_MODAL_OPEN_STATUS,
  open,
}
};

export function loadProductListRequest(data) {
return {
  type: LOAD_PRODUCT_LIST_REQUEST,
  payload: data,
}
};

export function loadProductListLoading(data) {
return {
  type: LOAD_PRODUCT_LIST_LOADING,
  payload: data,
}
};

export function loadProductListSuccess(data) {
return {
  type: LOAD_PRODUCT_LIST_SUCCESS,
  payload: data,
}
};

export function loadProductListError(data) {
return {
  type: LOAD_PRODUCT_LIST_ERROR,
  payload: data,
}
};

export function sendAddNewProductRequest(data) {
return {
  type: SEND_ADD_NEW_PRODUCT_REQUEST,
  payload: data,
}
};

export function sendAddNewProductLoading(data) {
return {
  type: SEND_ADD_NEW_PRODUCT_LOADING,
  payload: data,
}
};

export function sendAddNewProductSuccess(data) {
return {
  type: SEND_ADD_NEW_PRODUCT_SUCCESS,
  payload: data,
}
};

export function sendAddNewProductError(data) {
return {
  type: SEND_ADD_NEW_PRODUCT_ERROR,
  payload: data,
}
};

// export function loadProductCategoryListRequest() {
//   return {
//     type: LOAD_PRODUCT_CATEGORY_LIST_REQUEST
//   }
// };

// export function loadProductCategoryListSuccess(data) {
//   return {
//     type: LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
//     payload: data,
//   }
// };

export function sendUpdateProductRequest(data) {
return {
  type: SEND_UPDATE_PRODUCT_REQUEST,
  payload: data,
}
};

export function sendUpdateProductLoading(data) {
return {
  type: SEND_UPDATE_PRODUCT_LOADING,
  payload: data,
}
};

export function sendUpdateProductSuccess(data) {
return {
  type: SEND_UPDATE_PRODUCT_SUCCESS,
  payload: data,
}
};

export function sendUpdateProductError(data) {
return {
  type: SEND_UPDATE_PRODUCT_ERROR,
  payload: data,
}
};


export function sendDeleteProductRequest(data) {
return {
  type: SEND_DELETE_PRODUCT_REQUEST,
  payload: data,
}
};

export function sendDeleteProductLoading(data) {
return {
  type: SEND_DELETE_PRODUCT_LOADING,
  payload: data,
}
};

export function sendDeleteProductSuccess(data) {
return {
  type: SEND_DELETE_PRODUCT_SUCCESS,
  payload: data,
}
};

export function sendDeleteProductError(data) {
return {
  type: SEND_DELETE_PRODUCT_ERROR,
  payload: data,
}
};

export function loadProductTypeListRequest() {
return {
  type: LOAD_PRODUCT_TYPE_LIST_REQUEST
}
};

export function loadProductTypeListSuccess(data) {
return {
  type: LOAD_PRODUCT_TYPE_LIST_SUCCESS,
  payload: data,
}
};

export function loadSelectedProductRequest(data) {
return {
  type: LOAD_SELECTED_PRODUCT_REQUEST,
  payload: data,
}
};

export function loadSelectedProductSuccess(data) {
return {
  type: LOAD_SELECTED_PRODUCT_SUCCESS,
  payload: data,
}
};

export function loadBalanceStockPrintRequest(data) {
return {
  type: LOAD_BALANCE_STOCK_PRINT_REQUEST,
  payload: data,
}
};

export function loadBalanceStockPrintSuccess(data) {
return {
  type: LOAD_BALANCE_STOCK_PRINT_SUCCESS,
  payload: data,
}
};

export function loadAllProductsRequest(data) {
return {
  type: LOAD_ALL_PRODUCTS_REQUEST,
  payload: data,
}
};

export function loadAllProductsSuccess(data) {
return {
  type: LOAD_ALL_PRODUCTS_SUCCESS,
  payload: data,
}
};

export function loadAlternativeProductListRequest(data) {
return {
  type: LOAD_ALTERNATIVE_PRODUCT_LIST_REQUEST,
  payload: data,
}
};

export function loadAlternativeProductListSuccess(data) {
return {
  type: LOAD_ALTERNATIVE_PRODUCT_LIST_SUCCESS,
  payload: data,
}
};

export function sendAlternativeProductRequest(data) {
return {
  type: SEND_ALTERNATIVE_PRODUCT_REQUEST,
  payload: data,
}
};