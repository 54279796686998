import {
  LOAD_SELECTED_STOCK_DOCUMENT_REQUEST,
  LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS,
  LOAD_STOCK_DOCUMENT_LIST_ERROR,
  LOAD_STOCK_DOCUMENT_LIST_LOADING,
  LOAD_STOCK_DOCUMENT_LIST_REQUEST,
  LOAD_STOCK_DOCUMENT_LIST_SUCCESS,
  LOAD_STOCK_DOCUMENT_PRINT_REQUEST,
  LOAD_STOCK_DOCUMENT_PRINT_SUCCESS,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST,
  LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS,
  LOAD_STOCK_DOCUMENT_TYPE_REQUEST,
  LOAD_STOCK_DOCUMENT_TYPE_SUCCESS,
  SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS,
  SAVE_IS_STOCK_REPORT_OPEN_STATUS,
  SAVE_STOCK_DOCUMENT_DETAILS,
  SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST,
  SEND_STOCK_DOCUMENT_PRINT_REQUEST,
  SEND_STOCK_DOCUMENT_SUCCESS
} from '../constant/stockDocumentConstant';

export function saveIsStockDocumentOpenStatus(open) {
  return {
    type: SAVE_IS_STOCK_DOCUMENT_OPEN_STATUS,
    open,
  }
};

export function saveStockDocumentDetails(data) {
  return {
    type: SAVE_STOCK_DOCUMENT_DETAILS,
    payload: data,
  }
};

export function loadStockDocumentTypeRequest() {
  return {
    type: LOAD_STOCK_DOCUMENT_TYPE_REQUEST,
  }
};

export function loadStockDocumentTypeSuccess(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_TYPE_SUCCESS,
    payload: data,
  }
};


export function loadStockDocumentListRequest(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_LIST_REQUEST,
    payload: data,
  }
};

export function loadStockDocumentListLoading(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_LIST_LOADING,
    payload: data,
  }
};

export function loadStockDocumentListSuccess(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_LIST_SUCCESS,
    payload: data,
  }
};

export function loadStockDocumentListError(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_LIST_ERROR,
    payload: data,
  }
};

export function loadSelectedStockDocumentRequest(data) {
  return {
    type: LOAD_SELECTED_STOCK_DOCUMENT_REQUEST,
    payload: data,
  }
};

export function loadSelectedStockDocumentSuccess(data) {
  return {
    type: LOAD_SELECTED_STOCK_DOCUMENT_SUCCESS,
    payload: data,
  }
};

export function sendAnyTypeStockDocumentRequest(data) {
  return {
    type: SEND_ANY_TYPE_STOCK_DOCUMENT_REQUEST,
    payload: data,
  }
};

export function sendStockDocumentSuccess(data) {
  return {
    type: SEND_STOCK_DOCUMENT_SUCCESS,
    payload: data,
  }
};

export function sendStockDocumentPrintRequest(data) {
  return {
    type: SEND_STOCK_DOCUMENT_PRINT_REQUEST,
    payload: data,
  }
};

export function loadStockDocumentPrintSuccess(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRINT_SUCCESS,
    payload: data,
  }
};


export function loadStockDocumentProductReportRequest(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_REQUEST,
    payload: data,
  }
};

export function loadStockDocumentProductReportLoading(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_LOADING,
    payload: data,
  }
};

export function loadStockDocumentProductReportSuccess(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_SUCCESS,
    payload: data,
  }
};

export function loadStockDocumentProductReportError(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRODUCT_REPORT_ERROR,
    payload: data,
  }
};

export function saveIsStockReportOpenStatus(open) {
  return {
    type: SAVE_IS_STOCK_REPORT_OPEN_STATUS,
    open,
  }
};

export function loadStockDocumentPrintRequest(data) {
  return {
    type: LOAD_STOCK_DOCUMENT_PRINT_REQUEST,
    payload: data,
  }
};
