import { put, call } from 'redux-saga/effects';
import {
  addNewProductCategoryRequest,
  getProductCategoryList,
  updateProductCategoryRequest,
  deleteProductCategoryRequest
} from '../service/productCategoryService';
import * as actions from '../../store/action/productCategoryAction';
import common, { invalidToken, refeshTokenset } from '../common';
import { saveLoginUser } from '../../store/action/authAction';


export function* sendAddNewProductCategoryRequest(data){
  //console.log('sendAddNewProductCategoryRequest')
  yield put(actions.loadProductCategoryListError(''))
  try{
    // yield put(actions.loadProductCategoryListLoading(true));
    const response = yield call(addNewProductCategoryRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 201 ){
      //console.log(response.data.message)
      const massage = response.data.message
      yield put(actions.sendProductCategorySuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else {
      const massage = response.data.message
      yield put(actions.loadProductCategoryListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendAddNewProductCategoryLoading(false));
  }catch(error){
    // yield put(actions.sendAddNewProductCategoryLoading(false));
    //console.log(error.response.data.detail)
    const massage  =common.error
    yield put(actions.loadProductCategoryListError(massage))
  }
}

export function* loadProductCategoryListRequest(data){
  //console.log('loadProductCategoryListRequest')
  yield put(actions.loadProductCategoryListError(''))
  try{
    yield put(actions.loadProductCategoryListLoading(true));
    const response = yield call(getProductCategoryList,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result.result)
      const list = response.data.result
      yield put(actions.loadProductCategoryListSuccess(list))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadProductCategoryListLoading(false));
      const massage  =common.error
      yield put(actions.loadProductCategoryListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadProductCategoryListLoading(false));
  }catch(error){
    yield put(actions.loadProductCategoryListLoading(false));
    //console.log(error)
    const massage  =common.error
    yield put(actions.loadProductCategoryListError(massage))
  }
}

export function* sendUpdateProductCategoryRequest(data){
  //console.log('sendUpdateProductCategoryRequest')
  yield put(actions.loadProductCategoryListError(''))
  try{
    // yield put(actions.sendUpdateProductCategoryLoading(true));
    const response = yield call(updateProductCategoryRequest,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendProductCategorySuccess(massage))
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      const massage = response.data.message
      yield put(actions.loadProductCategoryListError(massage))
    } 
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    // yield put(actions.sendUpdateProductCategoryLoading(false));
  }catch(error){
    // yield put(actions.sendUpdateProductCategoryLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadProductCategoryListError(massage))
  }
}

export function* sendDeleteProductCategoryRequest(data){
  //console.log('sendDeleteProductCategoryRequest')
  yield put(actions.loadProductCategoryListError(''))
  try{
    yield put(actions.loadProductCategoryListLoading(true));
    const response = yield call(deleteProductCategoryRequest,data.payload);
    if(response && response.status && response.status === 200 ){
      //console.log(response.data.result)
      const massage = response.data.message
      yield put(actions.sendProductCategorySuccess(massage)) 
    }
    else if(response && response.data && response.data.statusCode && response.data.statusCode === 403 ){
      yield put(saveLoginUser(false))
      invalidToken()
    } 
    else{
      yield put(actions.loadProductCategoryListLoading(false));
      const massage  =common.error
      yield put(actions.loadProductCategoryListError(massage))
    }
    if(response && response.data && response.data.result && response.data.result.newAccessToken){
      refeshTokenset(response.data.result.newAccessToken)
    }
    yield put(actions.loadProductCategoryListLoading(false));
  }catch(error){
    yield put(actions.loadProductCategoryListLoading(false));
    //console.log(error.response)
    const massage  =common.error
    yield put(actions.loadProductCategoryListError(massage))
  }
}
