const PATH = 'app/ProductCategory';

export const SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS`;
export const LOAD_PRODUCT_CATEGORY_LIST_REQUEST = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_REQUEST`;
export const LOAD_PRODUCT_CATEGORY_LIST_ERROR = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_ERROR`;
export const LOAD_PRODUCT_CATEGORY_LIST_LOADING = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_LOADING`;
export const LOAD_PRODUCT_CATEGORY_LIST_SUCCESS = `${PATH}LOAD_PRODUCT_CATEGORY_LIST_SUCCESS`;
export const SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST = `${PATH}SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST`;
export const SEND_PRODUCT_CATEGORY_SUCCESS = `${PATH}SEND_PRODUCT_CATEGORY_SUCCESS`;
export const SEND_UPDATE_PRODUCT_CATEGORY_REQUEST = `${PATH}SEND_UPDATE_PRODUCT_CATEGORY_REQUEST`;
export const SEND_DELETE_PRODUCT_CATEGORY_REQUEST = `${PATH}SEND_DELETE_PRODUCT_CATEGORY_REQUEST`;