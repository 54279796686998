import { 
    LOAD_PRODUCT_CATEGORY_LIST_ERROR,
    LOAD_PRODUCT_CATEGORY_LIST_LOADING,
    LOAD_PRODUCT_CATEGORY_LIST_REQUEST,
    LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
    SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS,
    SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST,
    SEND_PRODUCT_CATEGORY_SUCCESS,
    SEND_DELETE_PRODUCT_CATEGORY_REQUEST,
    SEND_UPDATE_PRODUCT_CATEGORY_REQUEST,
    } from '../constant/productCategoryConstant';
    
    export function saveIsProductCategoryModalOpenStatus(open){
      return {
        type: SAVE_IS_PRODUCT_CATEGORY_MODAL_OPEN_STATUS,
        open,
      }
    };
  
    export function loadProductCategoryListRequest(data) {
      return {
        type: LOAD_PRODUCT_CATEGORY_LIST_REQUEST,
        payload: data,
      }
    };
    
    export function loadProductCategoryListLoading(data) {
      return {
        type: LOAD_PRODUCT_CATEGORY_LIST_LOADING,
        payload: data,
      }
    };
      
    export function loadProductCategoryListSuccess(data) {
      return {
        type: LOAD_PRODUCT_CATEGORY_LIST_SUCCESS,
        payload: data,
      }
    };
    
    export function loadProductCategoryListError(data) {
      return {
        type: LOAD_PRODUCT_CATEGORY_LIST_ERROR,
        payload: data,
      }
    };
  
    export function sendAddNewProductCategoryRequest(data) {
      return {
        type: SEND_ADD_NEW_PRODUCT_CATEGORY_REQUEST,
        payload: data,
      }
    };
    
    export function sendProductCategorySuccess(data) {
      return {
        type: SEND_PRODUCT_CATEGORY_SUCCESS,
        payload: data,
      }
    };
    
    export function sendUpdateProductCategoryRequest(data) {
      return {
        type: SEND_UPDATE_PRODUCT_CATEGORY_REQUEST,
        payload: data,
      }
    };
    
    export function sendDeleteProductCategoryRequest(data) {
      return {
        type: SEND_DELETE_PRODUCT_CATEGORY_REQUEST,
        payload: data,
      }
    };
    
    