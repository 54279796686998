import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import './pages.css'
import Cards from '../layout/form/Cards'
import LineChart from '../layout/form/LineChart'
import PieChart from '../layout/form/PieChart';
import * as selectors from '../../store/selector/dashboardSelector';
// import * as actions from '../../store/action/dashboardAction';
import Loader from '../layout/form/Loader';
import common, { numberWithCommas } from '../common';
import TextField from '../layout/form/TextField';
// import NavigationBar from '../layout/toolBar/NavBar';
// import Sales from './Sales';

const today = new Date()
const currentYear = today.getFullYear()
const currentMonth = today.getMonth()+1

function setMonthDataList(list,year){
    var monthData = []
    if(year === currentYear && !list){
        monthData = []
    }
    else if(year === currentYear){
        for (let i = 1; i <= currentMonth; i++) {
            const searchMonth = list.data.find(element => element.month === i)
            if(searchMonth){
                monthData.push(searchMonth.value)
            }else
                monthData.push(0)
        }
    }else if(list){
        for (let i = 1; i <= 12; i++) {
            const searchMonth = list.data.find(element => element.month === i)
            if(searchMonth){
                monthData.push(searchMonth.value)
            }else
                monthData.push(0)
        }
    }
    return monthData
}

function setQuarter(list){
    let quarter = list.reduce((acc, curr, i) => {
        let index = Math.floor(i/3)
        acc[index] = acc[index] + curr;
        return acc
    }, Array(4).fill(0));

    var thisQuarter = 0
    const value = currentMonth
    if(value === 3){
        thisQuarter = quarter[0]
    }else if(value === 6){
        thisQuarter = quarter[1]
    }else if(value === 9){
        thisQuarter = quarter[2]
    }else if(value === 12){
        thisQuarter = quarter[3]
    }else if(parseInt(value/3) === 0){
        thisQuarter = quarter[0]
    }else if(parseInt(value/3) === 1){
        thisQuarter = quarter[1]
    }else if(parseInt(value/3) === 2){
        thisQuarter = quarter[2]
    }else if(parseInt(value/3) === 3){
        thisQuarter = quarter[3]
    }
    return thisQuarter
}

function setRatingData(list){
    var data =[]
    const star1 = list.find(e => e.ratingId === 1)
    const star2 = list.find(e => e.ratingId === 2)
    const star3 = list.find(e => e.ratingId === 3)
    const star4 = list.find(e => e.ratingId === 4)
    const star5 = list.find(e => e.ratingId === 5)
    if(star5 && star5.id){
        data.push(star5.points)
    }else{
        data.push(0)
    }
    if(star4 && star4.id){
        data.push(star4.points)
    }else{
        data.push(0)
    }
    if(star3 && star3.id){
        data.push(star3.points)
    }else{
        data.push(0)
    }
    if(star2 && star2.id){
        data.push(star2.points)
    }else{
        data.push(0)
    }
    if(star1 && star1.id){
        data.push(star1.points)
    }else{
        data.push(0)
    }
    return data
}
  
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data :{
                labels: common.lineChartDetails.labels,
                datasets:[]
            },
            ratingSummaryData:{
                labels: common.pieChartDetails.labels,
                datasets: [
                  {
                    data: [],
                    backgroundColor: common.pieChartDetails.backgroundColor,
                    hoverOffset: 4
                    },
                ],
            },
            yearPeriod:1,
            isYearPeriodChange:false,
            salesThisMonth:0,
            salesThisYear:0,
            salesThisQuarter:0,
            initial:true
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { salesSummary,ratingSummary } = prevProps;
        const { initial } = prevState;
        if(
            salesSummary && salesSummary.length && salesSummary.length > 0 &&
            ratingSummary && ratingSummary.length >=0  && initial
            ){
            var totalSalesThisYear = 0
            const currentYearData = salesSummary.find(element => (element.year === currentYear))
            const previousYearData = salesSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = salesSummary.find(element => (element.year === currentYear - 2))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear-1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear-2)

            var totalSalesThisMonth = {}
            var totalSalesThisQuarter = 0
            if(currentYearSummary && currentYearSummary.length > 0 ){
                for (let i = 0; i < currentYearSummary.length; i++) {
                    totalSalesThisYear = totalSalesThisYear + currentYearSummary[i]
                }
                totalSalesThisMonth = currentYearData.data.find(element => element.month === currentMonth)
                totalSalesThisQuarter = setQuarter(currentYearSummary)
            }
            

            var rating = []
            if(ratingSummary.length > 0){
                rating = setRatingData(ratingSummary)
            }
            return{
                initial:false,
                data :{
                    labels: common.lineChartDetails.labels,
                    datasets:[
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        },{
                            label: currentYear-1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        },{
                            label: currentYear-2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        },
                    ]
                },
                salesThisYear:numberWithCommas(parseFloat(totalSalesThisYear ? totalSalesThisYear : 0).toFixed(2)),
                salesThisMonth:numberWithCommas(parseFloat(totalSalesThisMonth && totalSalesThisMonth.value ? totalSalesThisMonth.value : 0).toFixed(2)),
                salesThisQuarter:numberWithCommas(parseFloat(totalSalesThisQuarter ? totalSalesThisQuarter : 0).toFixed(2)),
                ratingSummaryData : {
                    labels: common.pieChartDetails.labels,
                    datasets: [
                    {
                        data: rating,
                        backgroundColor: common.pieChartDetails.backgroundColor,
                        hoverOffset: 4
                        },
                    ],
                }
            }
        }
        return null
    }

    handleChange = (e) => {
        if(e.target.value === ''){
            this.setState({
                [e.target.id]: 1,
                isYearPeriodChange:true
            })
        }else{
            this.setState({
                [e.target.id]: parseInt(e.target.value),
                isYearPeriodChange:true
            })
        }
    }

    // componentDidMount (){
    //     this.props.dispatch(actions.loadDashboardRequest())
    // }

    componentDidUpdate (prevProps, prevState){
        const { salesSummary } = this.props;
        if(salesSummary && salesSummary.length && salesSummary.length > 0 && this.state.isYearPeriodChange && this.state.yearPeriod === 2){
            const currentYearData = salesSummary.find(element => (element.year === currentYear))
            const previousYearData = salesSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = salesSummary.find(element => (element.year === currentYear - 2))
            const lastThreeYearData = salesSummary.find(element => (element.year === currentYear - 3))
            const lastFourYearData = salesSummary.find(element => (element.year === currentYear - 4))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear-1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear-2)
            const lastThreeYearSummary = setMonthDataList(lastThreeYearData, currentYear-3)
            const lastFourYearSummary = setMonthDataList(lastFourYearData, currentYear-4)


            this.setState({
                isYearPeriodChange:false,
                data :{
                    labels: common.lineChartDetails.labels,
                    datasets:[
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        },{
                            label: currentYear-1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        },{
                            label: currentYear-2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        },{
                            label: currentYear-3,
                            data: lastThreeYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_4,
                            borderColor: common.lineChartDetails.chartColor_4,
                        },{
                            label: currentYear-4,
                            data: lastFourYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_5,
                            borderColor: common.lineChartDetails.chartColor_5,
                        },
                    ]
                },
            })
        }
        else if(salesSummary && salesSummary.length && salesSummary.length > 0 && this.state.isYearPeriodChange && this.state.yearPeriod === 1){
            const currentYearData = salesSummary.find(element => (element.year === currentYear))
            const previousYearData = salesSummary.find(element => (element.year === currentYear - 1))
            const lastTwoYearData = salesSummary.find(element => (element.year === currentYear - 2))
            const currentYearSummary = setMonthDataList(currentYearData, currentYear)
            const previousYearSummary = setMonthDataList(previousYearData, currentYear-1)
            const lastTwoYearSummary = setMonthDataList(lastTwoYearData, currentYear-2)
            this.setState({
                isYearPeriodChange:false,
                data :{
                    labels: common.lineChartDetails.labels,
                    datasets:[
                        {
                            label: currentYear,
                            data: currentYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_1,
                            borderColor: common.lineChartDetails.chartColor_1,
                        },{
                            label: currentYear-1,
                            data: previousYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_2,
                            borderColor: common.lineChartDetails.chartColor_2,
                        },{
                            label: currentYear-2,
                            data: lastTwoYearSummary,
                            fill: false,
                            backgroundColor: common.lineChartDetails.chartColor_3,
                            borderColor: common.lineChartDetails.chartColor_3,
                        },
                    ]
                }
            })
        }else if (this.state.isYearPeriodChange){
            this.setState({
                isYearPeriodChange:false,
            })
        }
    }

    render() {
        const { 
            dashboadDetails,
            isDashboardLoading,
            purchaseOrderSummary
        } = this.props;
        return (
            <div>
                {(isDashboardLoading || 
                        (dashboadDetails && dashboadDetails.id && dashboadDetails.id === 0)) ? 
                        <Loader show={isDashboardLoading}/> :''}
            
            <div className="dashBoard">
                <div className="card-list">
                        <div className="col-sm">
                            <Cards
                                icon="payment-approval"
                                title="Sales"
                                cardContentText="This Year"
                                cardContentData={this.state.salesThisYear}
                                detailField1_Text="This Month"
                                detailField1_Data={this.state.salesThisMonth}
                                detailField2_Text="This Quarter"
                                detailField2_Data={this.state.salesThisQuarter}
                                
                            />
                        </div>
                        <div className="col-sm">
                            <Cards
                                icon="customer"
                                title="Customers"
                                cardContentText="Total"
                                cardContentData={numberWithCommas(dashboadDetails.customersTotal ? dashboadDetails.customersTotal : 0)}
                                detailField1_Text="This Month"
                                detailField1_Data={numberWithCommas(dashboadDetails.customersThisMonth ? dashboadDetails.customersThisMonth : 0)}
                                detailField2_Text="This Year"
                                detailField2_Data={numberWithCommas(dashboadDetails.customersThisYear ? dashboadDetails.customersThisYear : 0)}
                            />
                        </div>
                        {/* <div className="col-sm">
                            <Cards
                                icon="vehicle-repair"
                                title="Vehicles"
                                cardContentText="Total"
                                cardContentData={numberWithCommas(dashboadDetails.vehiclesTotal ? dashboadDetails.vehiclesTotal : 0)}
                                detailField1_Text="This Month"
                                detailField1_Data={numberWithCommas(dashboadDetails.vehiclesThisMonth ? dashboadDetails.vehiclesThisMonth : 0)}
                                detailField2_Text="This Year"
                                detailField2_Data={numberWithCommas(dashboadDetails.vehiclesThisYear ? dashboadDetails.vehiclesThisYear : 0)}
                            />
                        </div> */}
                        <div className="col-sm">
                            <Cards
                                // icon="electrocardiogram"
                                // title="Customer Heart Beat"
                                icon="customer-order-entry"
                                title="Purchases"
                                cardContentText="This Year"
                                cardContentData={numberWithCommas(purchaseOrderSummary.purchasingThisYear ? purchaseOrderSummary.purchasingThisYear.toFixed(2) : 0)}
                                detailField1_Text="This Month"
                                detailField1_Data={numberWithCommas(purchaseOrderSummary.purchasingThisMonth ? purchaseOrderSummary.purchasingThisMonth.toFixed(2) : 0)}
                                detailField2_Text="This Quarter"
                                detailField2_Data={numberWithCommas(purchaseOrderSummary.purchasingQuarter ? purchaseOrderSummary.purchasingQuarter.toFixed(2) : 0)}
                            />
                        </div>
                </div>
                {/* <h1 className="dashboard">dashboard</h1> */}
                <div className="chart-view">
                        <div className="chart-content">
                            <span className="aligncenter"><h5>Sales Comparison</h5></span>
                            <span className="alignright">
                                <TextField
                                    controlId="yearPeriod"
                                    type="text"
                                    as="select"
                                    onChange={this.handleChange}
                                    value={this.state.yearPeriod}
                                    options={common.lineChartDetails.viewOption}
                                />
                            </span>
                            <LineChart
                                data={this.state.data}
                                height="100px"
                            />
                        </div>
                        <div className="pieChart-content">
                            <h5>Customer Ratings</h5>
                            <PieChart
                                data={this.state.ratingSummaryData}
                            />
                        </div>
                </div>
                
            </div>
            </div>
        )
    }
}

Dashboard.propTypes = {
    dashboadDetails: PropTypes.any,
    isDashboardLoading: PropTypes.any,
    salesSummary: PropTypes.any,
    ratingSummary: PropTypes.any,
    purchaseOrderSummary: PropTypes.any,

  }
  
  const mapStateToProps = createStructuredSelector({
    dashboadDetails:  selectors.makeSelectDashboardDetails(),
    isDashboardLoading: selectors.makeSelectDashboardLoading(),
    salesSummary: selectors.makeSelectSalesSummaryDetails(),
    ratingSummary: selectors.makeSelectRatingSummaryDetails(),
    purchaseOrderSummary: selectors.makeSelectPurchaseOrderSummaryDetails(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  
  
  export default (compose(withConnect)(Dashboard));
